exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".dialog_detail{max-width:440px;padding:17px 5px 0 12px}.dialog_detail .checkinfo-detail{width:355px}.dialog_detail .notice{position:absolute;top:-6px;left:61px;font-size:14px;color:#000;height:48px;display:flex;flex-wrap:wrap;align-items:center}.dialog_detail .notice span{font-size:12px}.dialog_detail .notice .pack-color{color:#666}.dialog_detail .detail-content{position:relative;margin:4px 0 4px 35px}.dialog_detail .detail-content .fn-icon{color:#0097cf;margin-left:10px;margin-bottom:2px}.dialog_detail .detail-content .fn-icon:hover{color:#378cbe}.dialog_detail .detail-content em{position:absolute;left:-10px;top:0;color:#888}.dialog_detail .checkinfo-list{display:inline-block;overflow:hidden;color:#888;max-width:320px;padding-right:18px;white-space:normal;word-break:break-all}.dialog_detail .notice_title{color:#f12222}.dialog_detail .notice_list{color:#333;line-height:18px;margin-bottom:4px}.dialog_detail .hideList{display:none}.dialog_detail .tlp-type{margin-left:-40px}.dialog_detail .fn-icon-arrow-db{position:absolute;top:26px;right:0}.dialog_detail .reason .fn-icon-arrow-db{top:6px}.dialog_detail .height_0{height:18px}.dialog_detail .height_1{display:inline}.detail_info.hide-overflow-x .fn-show_info__text{overflow-x:hidden}.detail_info .fn-show_info__content{display:flex;flex-direction:column}.detail_info .fn-show_info__content .fn-show_info__icon{width:100%}", ""]);

// exports
exports.locals = {
	"prefixCls": "dialog_detail",
	"dialog_detail": "dialog_detail",
	"checkinfo-detail": "checkinfo-detail",
	"notice": "notice",
	"pack-color": "pack-color",
	"detail-content": "detail-content",
	"fn-icon": "fn-icon",
	"checkinfo-list": "checkinfo-list",
	"notice_title": "notice_title",
	"notice_list": "notice_list",
	"hideList": "hideList",
	"tlp-type": "tlp-type",
	"fn-icon-arrow-db": "fn-icon-arrow-db",
	"reason": "reason",
	"height_0": "height_0",
	"height_1": "height_1",
	"detail_info": "detail_info",
	"hide-overflow-x": "hide-overflow-x",
	"fn-show_info__text": "fn-show_info__text",
	"fn-show_info__content": "fn-show_info__content",
	"fn-show_info__icon": "fn-show_info__icon"
};