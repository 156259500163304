/**
 * Created by JANY on 2016/12/2.
 */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Core from './utils/core';
// 加载map sdk
const LoadMap = ComponsedComponent =>
  class extends Component {
    constructor(props) {
      super(props);
      if (!window.AMap) {
        const mapSrc = {
          name: this.props.name,
          src: this.getSrc(),
        };
        Core.insert(mapSrc);
      }
      this.handleRef = ref => {
        this.ref = ref && ref.ref ? ref.ref : ref;
      };
    }

    static propTypes = {
      name: PropTypes.string,
      src: PropTypes.string,
    };
    static defaultProps = {
      name: 'amap',
      src: '//webapi.amap.com/maps?v=1.4.14&key=87de26b763dae78146a78a3fcd091061&callback=init&plugin=AMap.Driving,AMap.Scale,AMap.ToolBar',
      // 78c76c06755c4f5b6218cc56a449c847   2833ccbcf0425892ddd2f50e71efbc0d
    };
    getSrc = () => {
      const groupId = +window.group_id;
      const keyPool = [
        '78c76c06755c4f5b6218cc56a449c847',
        '87de26b763dae78146a78a3fcd091061',
        'd229b743c92a5c229d409782bf19d656',
        'c1c628040e99b2b71b4674538403c4ec',
        'bd59350178a5eedf32b1678b516680f9',
        'cd19e78fb554fa5b7cac9f12ed76eeb9',
      ];
      window._AMapSecurityConfig = {
        serviceHost: `${window.location.origin}/_AMapService/key${groupId % 6}`,
      };

      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//webapi.amap.com/loader.js';
      head.appendChild(script);

      const key = keyPool[groupId % 6];
      const src = `//webapi.amap.com/maps?v=1.4.14&key=${key}&callback=init&plugin=AMap.Driving,AMap.Scale,AMap.ToolBar`;
      return src;
    };
    // componentWillUnmount() {
    //   // 卸载sdk
    //   Core.remove('amap')
    //   window.AMap = null
    // }
    render() {
      return (
        <ComponsedComponent
          {...this.props}
          ref={r => {
            this.handleRef(r);
          }}
        />
      );
    }
  };
export default LoadMap;
