/**
 * Created by JANY on 2017/05/08.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { prefixCls } from './index.scss';

export default class StaticTable extends PureComponent {
  static displayName = 'StaticTable';

  constructor(props) {
    super(props);
    this.tableStyle = {
      table: { width: '', height: '', fontSize: '18px' },
      theader: { tr: {}, th: {} },
      tbody: [{ tr: {}, td: {}, p: {} }],
      tfooter: { tr: {}, th: {} },
    };
  }
  static propTypes = {
    style: PropTypes.object,
    classname: PropTypes.string,
    header: PropTypes.object,
    data: PropTypes.array,
    total: PropTypes.object,
    tableStyle: PropTypes.object,
    headerStyle: PropTypes.object,
    bodyStyle: PropTypes.object,
    tableId: PropTypes.string,
    isAutoRowHeight: PropTypes.bool,
    isGroupHeader: PropTypes.bool,
    groupHeader: PropTypes.array,
    popName: PropTypes.string,
    tableAlign: PropTypes.array,
  };
  static defaultProps = {
    tableId: 'print_table',
  };

  render() {
    const {
      classname,
      header,
      data,
      total,
      tableStyle,
      tableId,
      isAutoRowHeight,
      isGroupHeader,
      groupHeader,
      headerStyle,
      bodyStyle,
      popName,
      tableAlign,
    } = this.props;
    console.log('StaticTable', this.props);
    const tableAlignHtml = {};
    const headerKeys = Object.keys(header);

    const classes = classnames({
      [prefixCls]: true,
      [classname]: classname,
    });
    const contentStyle = isAutoRowHeight
      ? ''
      : {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        };

    const { table: tableSt, theader: theaderSt, tbody: tbodySt, tfooter: tfooterSt } = tableStyle;
    const isBatch = popName.indexOf('batch_print_table') > -1;

    tableAlign && tableAlign !== 'default' && (tableAlignHtml.textAlign = tableAlign);

    return (
      <table cellPadding="0" cellSpacing="0" id={tableId} className={classes} style={{ ...tableSt }}>
        <thead>
          {isGroupHeader && (
            <tr style={{ ...theaderSt.tr, ...headerStyle }}>
              {groupHeader.map(groupItem => (
                <th key={groupItem.titleKey} colSpan={groupItem.groupHeaders.length}>
                  {groupItem.title}
                </th>
              ))}
            </tr>
          )}
          <tr style={{ ...theaderSt.tr, ...headerStyle }}>
            {headerKeys.map(columnKey => (
              <th key={columnKey} style={{ ...theaderSt.th[columnKey] }}>
                {header[columnKey].title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} style={{ ...tbodySt[index].tr, ...bodyStyle }}>
              {headerKeys.map(columnKey => (
                <td key={`${index}_${columnKey}`} style={{ ...tbodySt[index].td }}>
                  <div style={{ ...contentStyle, ...tbodySt[index].p[columnKey], ...tableAlignHtml }}>
                    {columnKey === 'reference' ? index + 1 : row[columnKey]}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {isBatch && (
            <tr className="sum-row" style={{ ...tfooterSt.tr, ...headerStyle }}>
              {headerKeys.map(columnKey => (
                <td key={`1__${columnKey}`} style={{ ...tfooterSt.th }}>
                  <div style={{ ...{ textAlign: 'center' }, ...tableAlignHtml }}>{total[columnKey]}</div>
                </td>
              ))}
            </tr>
          )}
          <tr style={{ ...tfooterSt.tr, ...headerStyle }}>
            {headerKeys.map(columnKey => (
              <th key={columnKey} style={{ ...tfooterSt.th[columnKey], ...tableAlignHtml }}>
                {total[columnKey]}
              </th>
            ))}
          </tr>
        </tfoot>
      </table>
    );
  }
}
