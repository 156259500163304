import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { formatDeppProptotype } from 'utils';
import TableCell from './TableCell';
import classnames from 'classnames';
import { isInOtherPropsArray } from './../utils';
import FormulaPicker from 'components/utilcomponents/FormulaPicker';

export default class FormulaPickerCell extends TableCell {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  handleConfirm = (rowIndex, columnKey, val, obj) => {
    this.props.onConfirm?.(rowIndex, columnKey, val, obj, { oriParams: [rowIndex, columnKey, val, obj] });
  };

  handleOutcomeChange = (rowIndex, columnKey, item, type) => {
    this.props.onOutcomeChange?.(rowIndex, columnKey, item, type, { oriParams: [rowIndex, columnKey, item, type] });
  };

  handleChange = (rowIndex, columnKey, value) => {
    this.props.onChange?.(rowIndex, columnKey, value, { oriParams: [rowIndex, columnKey, value] });
  };

  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    const { data, colIndex } = cellOtherProps;
    const { rowIndex, columnKey, width, height } = cellProps;
    const dataItem = data.getObjectAt(rowIndex);
    const _btnStyle = { width: width - 3, height, fontSize: 12 };

    const rowProps = dataItem.otherProps && dataItem.otherProps[columnKey];
    const value = formatDeppProptotype(columnKey, dataItem);

    const disabled = isInOtherPropsArray(dataItem, columnKey, 'disable');

    const _typoStyle = disabled
      ? { display: 'inline-block', maxWidth: Math.max(width - 7, 14), margin: '0 2px' }
      : { display: 'inline-block', maxWidth: Math.max(width - 56, 14), marginBottom: 0 };

    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <FormulaPicker
          {...columnProps}
          {...rowProps}
          value={value}
          data-path={`${columnKey}_${rowIndex}_${colIndex}`}
          typoStyle={_typoStyle}
          btnStyle={_btnStyle}
          disabled={disabled}
          className={classnames('formula-picker__cell formula-picker__override', columnProps?.className)}
          onConfirm={(val, obj) => this.handleConfirm(rowIndex, columnKey, val, obj)}
          onOutcomeChange={(item, type) => this.handleOutcomeChange(rowIndex, columnKey, item, type)}
          onChange={val => this.handleChange(rowIndex, columnKey, val)}
        />
      </Cell>
    );
  }
}
