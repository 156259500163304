import RcCalendar from 'rc-calendar';
import MonthCalendar from 'rc-calendar/lib/MonthCalendar';
import createPicker from './createPicker';
import wrapPicker from './wrapPicker';
import './style/index.scss';
import './style/input.css';
import './style/time-picker.css';
import RangePicker from './RangePicker';
// import WeekPicker from './WeekPicker';
const DatePicker = wrapPicker(createPicker(RcCalendar), 'date');
console.log(DatePicker);
const MonthPicker = wrapPicker(createPicker(MonthCalendar), 'month');

Object.assign(DatePicker, {
  RangePicker: wrapPicker(RangePicker, 'date'),
  MonthPicker,
  //   WeekPicker: wrapPicker(WeekPicker, 'week'),
});

export default DatePicker;
