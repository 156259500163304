exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-segmented-input{display:inline-flex;align-items:center;vertical-align:middle;height:24px;color:#333}.fn-segmented-input.fn-segmented-input--disabled{color:#999}.fn-segmented-input .fn-input-pure-wrap{width:auto;height:100%}.fn-segmented-input .fn-input-pure-wrap .input-tip{right:16px}.fn-segmented-input .fn-input-pure{width:60px;text-align:center}.fn-segmented-input button{background:none;margin-left:10px;line-height:1;cursor:pointer}.fn-segmented-input button .fn-icon-add-rad{font-size:15px}.fn-segmented-input button .fn-icon-minus-rad{font-size:16px}.fn-segmented-input button:disabled{color:#999;cursor:not-allowed}.fn-segmented-input button:disabled .fn-icon-il{color:#999}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-segmented-input",
	"fn-segmented-input": "fn-segmented-input",
	"fn-segmented-input--disabled": "fn-segmented-input--disabled",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"input-tip": "input-tip",
	"fn-input-pure": "fn-input-pure",
	"fn-icon-add-rad": "fn-icon-add-rad",
	"fn-icon-minus-rad": "fn-icon-minus-rad",
	"fn-icon-il": "fn-icon-il"
};