import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DragList, ListItem, Icon } from 'components';
import { typeIs, EventListener, isset, fetchApi, buttonOpDialog } from 'utils';
import { DYNAMIC_BUTTON_URL } from 'constants';
import { prefixCls } from './contextMenu.scss';
/*
  作用: 在鼠标点击位置，生成下拉菜单
*/
export default class ContextMenu extends PureComponent {
  constructor(props) {
    super(props);
    const {
      left,
      top,
      menuList,
      showKey,
      uniqueKey,
      isShow,
      width,
      height,
      clickInfo = { columnKey: '', shouldMarkItem: false, defaultSelected: [] },
      // 是否标记菜单项 // 默认选中项  // columnKey rowIndex
    } = props;
    const menu = isShow ? this.getListNHandlers(menuList, showKey, uniqueKey) : { listData: [], listHandlers: {} };
    this.state = {
      top,
      left,
      width,
      height,
      isShow: !!isShow,
      clickInfo,
      showSublist: false,
      subList: [],
      ...menu,
    };
    this.subListClick = false;
  }

  static propTypes = {
    // 绝对定位用
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // 绝对定位用
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isShow: PropTypes.bool,
    // 大小
    width: PropTypes.number,
    height: PropTypes.number,
    // 大小
    clickInfo: PropTypes.object,
    menuList: PropTypes.array, // 菜单项
    uniqueKey: PropTypes.string,
    showKey: PropTypes.string,
    // 右键菜单中的search操作
    handleSearchClick: PropTypes.func,
    // 右键菜单中chang操作
    handleSearchChange: PropTypes.func,
    handleListOpationIconClick: PropTypes.func,
    onHide: PropTypes.func,
    searchPlaceHolder: PropTypes.string, // 搜索框提示
  };
  static defaultProps = {
    width: 171,
    height: 220,
    isShow: false,
    uniqueKey: 'key',
    showKey: 'name',
  };

  componentDidMount() {
    this.docClickListenerClick = EventListener.listen(document, 'click', e => {
      if (
        !this.subListClick &&
        this.state.isShow &&
        (!this.clickObj || (this.clickObj && !this.clickObj.contains(e.target))) &&
        (!this._searchInputRef || (this._searchInputRef && !this._searchInputRef._input.contains(e.target)))
      ) {
        this.handleHide();
      }
    });
  }

  componentWillUnmount() {
    this.docClickListenerClick && this.docClickListenerClick.remove();
  }

  componentDidUpdate() {
    this.resetPosition();
  }

  resetPosition = () => {
    if (!this.wrap || !this._vList) return;
    const ul = isset(this._vList, 'child.ul');
    if (!ul) return;
    const state = { ...this.state };
    const height = ul.offsetHeight;
    if (state.top + height > window.innerHeight) {
      state.top -= height;
      this.setState(state);
    }
    if (state.left + state.width > window.innerWidth) {
      state.left -= state.width;
      this.setState(state);
    }
  };
  handleNextHover = () => {
    this.vList() && this.vList().handleNextHover();
  };
  handlePreHover = () => {
    this.vList() && this.vList().handlePreHover();
  };
  handleKeyDown = e => {
    switch (e.keyCode) {
      case 27:
        setTimeout(() => {
          // 加延时为了支持多个esc事件，弹框也支持esc关闭，不加延迟无法获取到selectdrop元素
          this.hideDropList();
        }, 0);
        break;
      case 38:
        // handle Up
        this.handlePreHover();
        break;
      case 40:
        // handle Down
        this.handleNextHover();
        break;
      case 13:
        if (this.vList() && this.vList().getActiveIndex() !== -1) {
          this.vList() && this.vList().handleSelect(undefined, this.vList().getActiveIndex(), this.handleMenuCLick);
          this.handleHide();
        }
        break;
      default:
        break;
    }
  };
  // isSearchInside false 时 筛选值不
  handleSearchKeyDown = e => {
    if (e.keyCode === 13) {
      this.props.handleSearchClick && this.props.handleSearchClick(e, e.target.value);
      this.handleHide();
    }
  };
  getListNHandlers = (menuList, showKey, uniqueKey) => {
    const listData = [];
    const listHandlers = {};
    menuList.forEach(item => {
      listData.push({
        type: item.type,
        [uniqueKey]: item.key,
        [showKey]: this.tict(item.name.icon, item.name.title),
        disabled: item.disabled,
        subList: item.subList,
        dynamic: item.dynamic,
      });
      listHandlers[item.key] = item.handler;
    });
    return { listData, listHandlers };
  };
  tict = (iconType, value) => (
    <p>
      {iconType ? <Icon iconType={iconType} /> : null}
      <span>{value}</span>
    </p>
  );
  // 三级菜单的点击
  handleMenu3CLick = (data = {}) => {
    if (this.state.subListHandler && typeIs(this.state.subListHandler, 'function')) {
      this.state.subListHandler(data);
    }
    this.handleHide();
  };
  handleMenuCLick = async (data = {}, e) => {
    const { key } = data;
    const { listHandlers = {}, clickInfo = {} } = this.state;
    const listHandler = listHandlers[key];
    // 有下一级菜单的显示下一级菜单
    if (data.subList || data.dynamic) {
      let li = e.target;
      while (li.tagName !== 'LI') li = li.parentNode;
      const top = li.offsetTop;
      const { scrollTop } = li.parentNode;
      this.setState({
        isShow: true,
      });
      const subList = data.subList || (await this.dynamicBtn(data));
      this.setState({
        showSublist: true,
        isShow: true,
        subList,
        subListHandler: listHandler,
        subListTop: `${top - scrollTop}px`,
      });
      this.subListClick = true;
      return;
    }
    this.setState({
      showSublist: false,
      subList: [],
    });
    this.subListClick = false;
    if (listHandler && typeIs(listHandler, 'function')) {
      listHandler(clickInfo.columnKey, clickInfo.rowIndex, key);
    }
  };
  dynamicBtn = async btnsProps => {
    if (btnsProps.dynamic) {
      const req = {
        ...btnsProps.dynamic,
      };
      const res = await fetchApi(DYNAMIC_BUTTON_URL, { method: 'POST', body: { req } });
      if (res.errno !== 0) {
        buttonOpDialog(res.res || {});
        return [];
      }
      const tplList = isset(res.res, 'config.children.switch_tpl.children', []);
      return tplList;
    }
    return [];
  };
  handleHide = () => {
    this.clickObj = null;
    this.setState({
      isShow: false,
      showSublist: false,
    });
    this.props.onHide && this.props.onHide(this.state.clickInfo);
    this.keyDownListener.remove();
    window.removeEventListener('mousedown', this.clickHandler);
  };
  // 右键菜单显示操作
  handleShow = (listData, left, top, width, height, clickInfo = {}) => {
    let state = typeIs(left, 'number') && typeIs(top, 'number') ? { isShow: true, left, top } : { isShow: true };
    if (typeIs(left, 'number') && typeIs(top, 'number')) {
      state = Object.assign(state, { width, height });
    }
    if (typeIs(listData, 'array')) {
      const menu = this.getListNHandlers(listData, this.props.showKey, this.props.uniqueKey);
      state = Object.assign(state, { ...menu });
    }
    clickInfo.clickObj && (this.clickObj = clickInfo.clickObj);
    this.setState({ ...state, clickInfo });
    this.keyDownListener = EventListener.listen(document, 'keydown', this.handleKeyDown);
    window.addEventListener('mousedown', this.clickHandler);
  };
  vList = () => this._vList && this._vList.child;
  getIsShow = () => this.state.isShow;
  clickHandler = e => !this.wrap.contains(e.target) && this.handleHide();
  refWrap = r => (this.wrap = r);

  render() {
    const { listData, isShow, left, top, width, subListTop /* height */ } = this.state;
    const { uniqueKey, showKey } = this.props;
    // width, height,
    return isShow ? (
      <div
        className={`${prefixCls}`}
        style={{
          left: `${left + 5}px`,
          top: `${top + 5}px`,
          transform: left - 5 ? 'translateX(0)' : 'translateX(100000px)',
        }}
        ref={this.refWrap}
      >
        <DragList
          data={listData}
          isShowSearch={this.state.clickInfo.isShowSearch}
          searchPlaceHolder={this.state.clickInfo.searchPlaceHolder || this.props.searchPlaceHolder}
          isSearchInside={false}
          defaultSelected={this.state.clickInfo.shouldMarkItem ? this.state.clickInfo.defaultSelected || [] : []}
          isShow={isShow}
          isKeyboard={false}
          searchHeaderIn
          ref={r => (this._vList = r)}
          searchInputRef={r => (this._searchInputRef = r)}
          handleSearchClick={this.props.handleSearchClick}
          handleSearchChange={this.handleSearchChange}
          handleSearchKeyDown={this.handleSearchKeyDown}
          handleIconClick={this.handleListOpationIconClick}
          style={{ width }}
          tableHeader={{ name: 'name' }}
          iconType="check"
          uniqueKey={uniqueKey}
          showKey={showKey}
        >
          <ListItem onClick={this.handleMenuCLick} />
        </DragList>
        {this.state.showSublist && this.state.subList && (
          <DragList
            data={this.state.subList}
            isShow={this.state.showSublist}
            showKey="title"
            uniqueKey="key"
            isKeyboard={false}
            searchHeaderIn
            ref={r => (this._vSubList = r)}
            style={{ width: `${width}px`, height: 'auto', padding: '5px 0', left: '172px', top: subListTop }}
            tableHeader={{ title: 'name' }}
            iconType="check"
          >
            <ListItem onClick={this.handleMenu3CLick} />
          </DragList>
        )}
      </div>
    ) : null;
  }
}
