/**
 * 费用项下拉
 */
import React from 'react';
import classnames from 'classnames';
import Select from 'components/utilcomponents/Select';
import PureInput from 'components/utilcomponents/input/PureInput';
import { buildShowTip } from 'utils/buildTip';

const emptyTip = buildShowTip({ content: '“请选择费用项”表示数值 1 ' });

export default class FeeSelect extends Select {
  static defaultProps = {
    compare: 'key',
    format: 'name',
    filter: 'name',
    header: [{ key: 'name', titleRender: (str, item) => item?.tip ?? str }],
    map: 'key',
    placeholder: '请选择费用项',
    separator: '+',
    data: [],
    multiple: false,
  };

  setFormatFn() {
    this.format = value => value.map(item => item.name).join(this.props.separator);
  }
  renderToggle() {
    const display = this.format(this.reflectValue);
    const { disabled } = this.props;
    const placeholder = disabled ? '1' : this.props.placeholder;
    const className = classnames({ empty: !display, 'fee-select': true, disabled });
    return (
      <PureInput
        ref={this.refInput}
        className={className}
        disabled={disabled}
        title={display}
        value={display || placeholder}
        onFocus={this.open}
        onBlur={this.onBlur}
        readOnly
        onKeyDown={this.onKeyDown}
        {...(display ? {} : emptyTip)}
      >
        {display || placeholder}
      </PureInput>
    );
  }
}
