// 根据格式化省市区
export const formatLevel4Address = data => {
  const province = data.province || '';
  const city = data.city || '';
  const name = data.name || '';
  const district = data.district || data.adname || '';
  const street = data.street || '';
  return {
    province,
    city: name === province ? '' : city,
    district: name === province || name === city ? '' : district,
    street: name === province || name === city || name === district ? '' : street,
  };
};

// 根据省市区规则返回财运通展示的show_val字段
export const getShowVal = params => {
  const { province, city, district, name } = params;
  let value = '';
  switch (name) {
    case province: {
      value = `${province}`; // 如果name等于省，则只展示省级
      break;
    }
    case city: {
      value = `${province}${city}`; // 如果name等于市，则展示省、市二级
      break;
    }
    case district: {
      value = `${province}${city}${district}`; // 如果name等于市，则展示省、市、区三级
      break;
    }
    default: {
      value = name?.indexOf(`${city}${district}`) !== -1 ? name : `${city}${district}${name}`; // 如果name都不等于，则展示市、区、具体地址
    }
  }
  return value;
};
