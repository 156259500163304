exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".digit-range{display:flex;align-items:center;height:34px;position:relative}.digit-range .fn-input-pure-wrap{width:calc(100% - 20px)}.digit-range .to{width:20px;text-align:center}", ""]);

// exports
exports.locals = {
	"prefixCls": "digit-range",
	"digit-range": "digit-range",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"to": "to"
};