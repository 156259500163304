/**
 * Created by cyf on 2017/12/19.
 * 弹窗批量操作 详情展示部分
 */
import PropTypes from 'prop-types';

import React, { PureComponent, Fragment } from 'react';
import { Tips } from 'components';
import { getStrWidth } from 'utils';
import { prefixCls } from './index.scss';

export default class DialogDetail extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      iconStatus: true,
    };
  }
  static propTypes = {
    keys: PropTypes.array,
    canKeys: PropTypes.array,
    succedLength: PropTypes.any,
    opType: PropTypes.any,
    ext: PropTypes.shape({
      specialTips: PropTypes.any,
      customContinueDesc: PropTypes.string, // 自定义的继续提示文案 来替换`是否继续将可${opType}的${realOrderType}进行${opType}？`
      customFailDesc: PropTypes.string, // 自定义的失败提示文案 来替换`以下数据无法${opType},请检查后重新选择!`
      reason: PropTypes.any, // 自定义的原因说明
      noticeTitle: PropTypes.any,
      hideToggle: PropTypes.bool, // 是否显示 toggle 图标
    }),
    realOrderType: PropTypes.any,
    canObj: PropTypes.any,
    obj: PropTypes.any,
    specialCause: PropTypes.array,
    noticeTitle: PropTypes.any,
    failedTips: PropTypes.object, // 每天错误提示后面的 tips
  };
  static defaultProps = {
    keys: [],
    canKeys: [],
    succedLength: 0,
    specialCause: [],
    noticeTitle: '原因说明：',
    ext: {},
    failedTips: {},
  };
  toggleHandle = index => {
    this.setState({
      [index]: !this.state[index],
    });
  };
  renderContent = () => {
    const { opType, realOrderType, ext, canKeys, canObj, keys, obj, specialCause, failedTips } = this.props;
    const specialCauseArr = [];
    specialCause.forEach((item, index) => {
      const ele = (
        <div key={index} className="detail-content">
          <p className="notice_list">
            <em>●</em>
            {item.msg}
          </p>
        </div>
      );
      specialCauseArr.push(ele);
    });
    if (ext.reason) {
      // 这种情况 用于 提示 以下运单XXXX,是否继续xx? 运单号们 （这种情况不需要noticeTitle）
      if (Array.isArray(ext.reason)) {
        const expand = this.state.extReason ? '0' : '1';
        const iconName = this.state.extReason ? 'fn-icon-arrow-db' : 'fn-icon-arrow-dt';
        const hideList = ext.hideList ? 'hideList' : 'detail-content reason';
        const extServerReason = ext.reason.join('， ');
        return (
          <div className={hideList}>
            {ext.typeTitle && <p className="tlp-type">{ext.typeTitle}</p>}
            {extServerReason ? <p className={`checkinfo-list height_${expand}`}>{extServerReason}</p> : null}
            {!ext.hideToggle && (
              <i className={`fn-icon ${iconName}`} onClick={() => this.toggleHandle('extReason')}>
                &nbsp;
              </i>
            )}
          </div>
        );
      }
      // 自定义的原因文案
      return (
        <div className="detail-content">
          <p className="notice_list">{ext.reason}</p>
        </div>
      );
    }
    return (
      <Fragment>
        {canKeys.map((key, index) => {
          // let domStr = document.getElementsByClassName('checkinfo-list')[0].innerHTML
          const iconName = this.state[index] ? 'fn-icon-arrow-db' : 'fn-icon-arrow-dt';
          const expand = this.state[index] ? '0' : '1';
          const domStr = canObj[key].join('; ');
          const listWidth = getStrWidth(domStr, 12, undefined, undefined, undefined);
          let renderIconShow = '';
          if (listWidth > 310) {
            renderIconShow = (
              <i className={`fn-icon ${iconName}`} onClick={() => this.toggleHandle(index)}>
                &nbsp;
              </i>
            );
          }
          return (
            <div key={index} className="detail-content">
              <p className="notice_list">
                <em>●</em>以下{realOrderType}
                {key}，点击[继续]可继续{opType}!
              </p>
              {domStr ? (
                <p className={`checkinfo-list width_${index} height_${expand}`}>{canObj[key].join('; ')}</p>
              ) : null}
              {renderIconShow}
            </div>
          );
        })}
        {keys.map((key, index) => {
          const iconName = this.state[index] ? 'fn-icon-arrow-dt' : 'fn-icon-arrow-db';
          const expand = this.state[index] ? '1' : '0';
          const domStr = obj[key].join('; ');
          const listWidth = getStrWidth(domStr, 12, undefined, undefined, undefined);
          const startStr = obj?.[key]?.noStart || ext.noStart ? '' : '以下';
          let renderIconShow = '';
          if (listWidth > 310) {
            renderIconShow = (
              <i className={`fn-icon ${iconName}`} onClick={() => this.toggleHandle(index)}>
                &nbsp;
              </i>
            );
          }

          let snapTip = null;
          if (failedTips && failedTips[key]) {
            const tipStr = failedTips[key].split('');
            if (tipStr.length > 40) {
              for (let i = 40; i < tipStr.length; i += 40) {
                tipStr[i] += '<br />';
              }
            }
            const curTip = tipStr.join('');
            console.log('tips item =', curTip);
            snapTip = (
              <Tips className="ori-radio" title={curTip} tipsStyle={{ color: '#fff' }} isDom>
                <i className="fn-icon fn-icon-help" />
              </Tips>
            );
          }

          /**
           * ext.noSplice = true
           * 货款发放的错误提示是后端给的完整信息，不再需要后面拼接，“不能XXXXX”
           */
          return (
            <div key={index} className="detail-content">
              {!ext.hideFailTitle && (
                <p className="notice_list">
                  <em>●</em>
                  {startStr}
                  {obj[key].orderType || realOrderType}
                  {key}
                  {ext.noSplice || !opType ? '' : `，${obj[key].opType || `不能${opType}`}`} {snapTip}
                </p>
              )}
              {domStr ? (
                <p className={`checkinfo-list width_${index} height_${expand}`}>{obj[key].join('; ')}</p>
              ) : null}
              {renderIconShow}
            </div>
          );
        })}
        {specialCauseArr}
      </Fragment>
    );
  };
  renderDetail() {
    const { ext = {}, failed_summary } = this.props;
    const noticeTitle = ext.noticeTitle || this.props.noticeTitle;
    const isExitContent =
      (Array.isArray(this.props.specialCause) && this.props.specialCause.length) ||
      !!ext.reason ||
      (Array.isArray(this.props.canKeys) && this.props.canKeys.length) ||
      (Array.isArray(this.props.keys) && this.props.keys.length);
    if (isExitContent) {
      return (
        <div className="checkinfo-detail">
          {!Array.isArray(ext.reason) && !ext.customizeCont && <p className="notice_title">{noticeTitle}</p>}
          {this.renderContent()}
          {failed_summary && <div className="detail-content text-red">{failed_summary}</div>}
          {ext.customizeCont}
        </div>
      );
    }
    return undefined;
  }

  render() {
    // style={{ height: `${height_}px` }}
    const { succedLength, opType, realOrderType, ext = {} } = this.props;
    //    const { iconStatus } = this.state
    //    setTimeout(() => {
    //      // let domStyle = document.getElementsByClassName('checkinfo-list')[0].getBoundingClientRect()
    //      // let listWidth = domStyle.width
    //     // this.listHeight = domStyle.height
    //      let domStr = document.getElementsByClassName('checkinfo-list')[0].innerHTML
    //      let listWidth = getStrWidth(domStr, 12, undefined, undefined, undefined)
    //      if (listWidth > 310) {
    //        this.setState({
    //          hide: 'inline-block',
    //        })
    //      }
    //    }, 200)
    //    // let expand = iconStatus ? '0' : '1'
    return (
      <div className={prefixCls}>
        {
          // eslint-disable-next-line no-nested-ternary
          ext && ext.specialTips && ext.specialTips !== '' ? (
            <p className="notice">{ext.specialTips}</p>
          ) : succedLength ? (
            <p className="notice">
              {ext.customContinueDesc ||
                `以下${realOrderType || '数据'}无法${opType || '操作'}，是否继续操作${opType ? `可${opType}的` : ''}${
                  realOrderType || '数据'
                }？`}
              <span className="pack-color">
                {ext.is_pack
                  ? `点击“继续”，会从包里删除不符合${opType}操作条件的运单，并对包内其他运单操作${opType}`
                  : ''}
              </span>
            </p>
          ) : (
            <p className="notice">
              {ext.customFailDesc || `以下${realOrderType || '数据'}无法${opType || '操作'}，请检查后重新选择!`}
            </p>
          )
        }
        {this.renderDetail()}
      </div>
    );
  }
}
