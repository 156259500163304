/**
 * Created by jany on 2017/09/03.
 */
// TODO 列表显示值 和 判断用值 需要统一到一个方法
import { formatDeppProptotype, typeIs } from 'utils';
import {
  LIST_ARRAY2TEXT_SEPARATE,
  OBJECT_TEXTCELL_GEN_RULE,
  WEIGHT_UNIT_TEXTCELL_GEN_RULE,
  NUMBER_TYPE,
} from 'constants';

export function formatValue(columnKey, rowObj, enumerations = {}, cellclassMap, cellclassGetter, headerCol = {}) {
  let value = formatDeppProptotype(columnKey, rowObj); // 防止深层
  if (NUMBER_TYPE.includes(headerCol.columnType) && (value === undefined || value === '')) {
    value = 0;
  }
  let _value = value;
  const valueType = typeIs(value);
  const uniqueKey = headerCol.uniqueKey || 'key';
  const showKey = headerCol.showKey || 'name';
  if (cellclassGetter && enumerations[`cellclass.${columnKey}`]) {
    const cellclassVal = cellclassMap[columnKey] ? formatDeppProptotype(cellclassMap[columnKey], rowObj) : value;
    const cellclassObj =
      (enumerations[`cellclass.${columnKey}`] || []).filter(item => item[uniqueKey] === cellclassVal)[0] || {};
    cellclassGetter && cellclassGetter(cellclassObj[showKey]);
  }
  const enums = enumerations[headerCol.refEnum];
  // if (enums && headerCol && !headerCol.fetchApi && typeIs(enums, 'array') && headerCol.type !== 'CheckBoxText') {
  if (
    enums &&
    headerCol &&
    typeIs(enums, 'array') &&
    headerCol.type !== 'CheckBoxText' &&
    headerCol.type !== 'Progress'
  ) {
    _value = ''; // 枚举初值，未匹配值都返回空
    if (valueType === 'array' && value.length > 0) {
      try {
        let dicItem = {};
        const sDic = {};
        const subValueType = typeIs(value[0]);
        enums.map(item => (sDic[item[uniqueKey]] = item));
        _value = value.reduce((p, c) => {
          const k = subValueType === 'object' ? c[uniqueKey] : c;
          dicItem = sDic[k] || {};
          if (dicItem[showKey] || dicItem.diff_display) {
            p.push(dicItem.diff_display !== undefined ? dicItem.diff_display : dicItem[showKey] || '');
          }
          return p;
        }, []);
        if (headerCol.type !== 'Link') {
          _value = _value.join(LIST_ARRAY2TEXT_SEPARATE);
        }
      } catch (error) {
        return false;
      }
    } else if (valueType !== 'object') {
      enums.some(item => {
        if (
          item !== undefined &&
          item !== null &&
          item[uniqueKey] !== undefined &&
          item[uniqueKey] !== null &&
          item[uniqueKey] == value
        ) {
          // eslint-disable-line
          _value = item.diff_display !== undefined ? item.diff_display : item[showKey] || '';
          return true;
        }
        return false;
      });
    } else if (valueType === 'object') {
      /**
       * 当field对应的是Object，需要使用具体的key-value进行排序和筛选
       * 例如：针对Progress类型，在途进度使用'状态'进行筛选和排序，如果另外一个field使用具体的'进度值'进行排序和筛选，此时需要确定具体的key-value
       * warning：暂时保持原样返回Object，在具体的筛选和排序中去取值；且下拉筛选会使用枚举的name，
       * @todo 统一取值规范
       * @see /src/components/utilcomponents/tableV2/FixedDataTable/index.js filterIndex
       */
    }
  }
  if (OBJECT_TEXTCELL_GEN_RULE[columnKey] && valueType === 'array' && typeIs(_value[0], 'object')) {
    _value = OBJECT_TEXTCELL_GEN_RULE[columnKey](_value);
  }
  if (WEIGHT_UNIT_TEXTCELL_GEN_RULE[columnKey] && valueType === 'array') {
    _value = WEIGHT_UNIT_TEXTCELL_GEN_RULE[columnKey](_value);
  }
  return _value === undefined || _value === null ? '' : _value;
}
