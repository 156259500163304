import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Form, Input, Checkbox, Button, Spin } from '@gui/web-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Tips } from 'components';

import { prefixCls } from './index.scss';

const InputSearch = Input.Search;
const OrderSet = forwardRef(function OrderSet(props, ref) {
  const containerRef = useRef(null);

  const [form] = Form.useForm();
  // 下拉数据
  const [option, setOption] = useState([]);
  const [search, setSearch] = useState('');
  // 不可编辑数据
  const [hideEleGroup, setHideEleGroup] = useState([]);
  //  默认选中值
  const [defaultSelectedValue, setDefaultSelectedValue] = useState([]);
  // 选中值
  const [selected, setSelected] = useState([]);
  const [commitValue, setCommitValue] = useState({
    state: {
      rightList: [],
      oriRightList: [],
    },
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  // 拖拽结束
  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const updatedOption = [...option];
    const [draggedField] = updatedOption.splice(source.index, 1);
    updatedOption.splice(destination.index, 0, draggedField);
    setOption(updatedOption);
  };
  const getCustomListStyle = () => {
    return {
      height: 'auto',
    };
  };
  const handleSubmit = () => {
    props.handleSubmit();
  };
  //  初始化下拉数据
  const initData = async () => {
    let data = [];
    if (props.getRenderData) {
      try {
        setContentLoading(true);
        data = await props.getRenderData();
        const { rightList = [], leftList = [], hideEle = [] } = data;
        const hideEleKeyGroup = hideEle.map(el => el.key);
        const checkedValueKeyGroup = rightList.map(el => el.key);
        const options = [...rightList, ...leftList];
        setOption(options);
        setHideEleGroup(hideEleKeyGroup);
        setDefaultSelectedValue(checkedValueKeyGroup);
        setSelected([...checkedValueKeyGroup, ...hideEleKeyGroup]);
      } catch (error) {
        console.log(error);
      } finally {
        setContentLoading(false);
      }
    }
  };
  const loadingOn = () => {
    setBtnLoading(true);
  };
  const loadingOff = () => {
    setBtnLoading(false);
  };
  const handleSearch = value => {
    setSearch(value);
  };
  // 组件对外暴露的方法和数据
  useImperativeHandle(
    ref,
    () => ({
      ...commitValue,
      containerRef,
      initData,
      loadingOn,
      loadingOff,
    }),
    [commitValue],
  );
  useEffect(() => {
    const updateSelect = [];
    // 排序
    option.forEach(op => {
      if (selected.includes(op?.key)) {
        updateSelect.push(op.key);
      }
    });

    setCommitValue({
      state: {
        rightList: updateSelect,
        oriRightList: [...defaultSelectedValue],
      },
    });
  }, [selected, defaultSelectedValue, option]);
  return (
    <Spin loading={contentLoading}>
      <div className={`${prefixCls}`} ref={containerRef}>
        <Form layout="horizontal" form={form} wrapperCol={{ span: 24 }}>
          <Form.Item>
            <InputSearch placeholder="搜索" onChange={handleSearch} />
          </Form.Item>
          <div style={{ height: '270px', overflow: 'auto' }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div>
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getCustomListStyle(snapshot.isDraggingOver)}
                    >
                      {/* <Form layout="horizontal" form={form} wrapperCol={{ span: 24 }}> */}

                      <div>
                        <Form.Item>
                          <Checkbox.Group value={selected} onChange={setSelected}>
                            {option
                              .filter(item => item?.title?.toLowerCase()?.includes(search?.trim()?.toLowerCase()))
                              .map((item, index) => {
                                return (
                                  <Draggable key={item.key} draggableId={`${item.key}_index`} index={index}>
                                    {subProvided => (
                                      <div
                                        {...subProvided.draggableProps}
                                        ref={subProvided.innerRef}
                                        className={`${prefixCls}__container`}
                                        style={{ ...subProvided.draggableProps.style, marginBottom: 10 }}
                                      >
                                        <Tips
                                          title={hideEleGroup.includes(item.key) ? '系统默认项不可修改' : ''}
                                          className="tips"
                                        >
                                          <div className="row">
                                            <Checkbox
                                              value={item.key}
                                              disabled={hideEleGroup.includes(item.key)}
                                              key={item.key}
                                            >
                                              {item?.title}
                                            </Checkbox>
                                            <div>
                                              <div className="node-drag" {...subProvided.dragHandleProps}>
                                                <img
                                                  className="icon-drag"
                                                  alt="drag"
                                                  src="https://static.g7cdn.com/fe-cdn/icon-drag_16.png"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </Tips>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                          </Checkbox.Group>
                        </Form.Item>

                        {provided.placeholder}
                      </div>
                      {/* </Form> */}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className="footer-container">
            <div />
            <Button onClick={handleSubmit} type="primary" loading={btnLoading}>
              保存
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
});
export default OrderSet;
