exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".checkbox-tips .fn-icon.fn-icon-help{color:#3c8bdc;margin-right:3px;margin-left:3px;position:relative;top:4px;font-weight:400;font-size:16px}", ""]);

// exports
exports.locals = {
	"checkbox-tips": "checkbox-tips",
	"fn-icon": "fn-icon",
	"fn-icon-help": "fn-icon-help"
};