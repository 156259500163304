import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { Icon, Tips } from 'components';
import { deleteProps, formatDeppProptotype, typeIs } from 'utils';
import { prefixCls } from './index.scss';
// isValueObject: 是否显示数据中的object, 或者为react element
// 默认显示所有的列， 否则显示header中指定的字段，header格式为Object
// 支持列为Object形式, key的定义为 A,B的形式
// checkbox radio span
// tableHeader 格式： { key: {title:'', type:'', props: {style: {width:''}}},
// key1:{title:'', type:'', props: {style: {width:''}}}
// !!!!!!!!!!!!!!!! props 变更 header -> tableHeader 注意同步其他使用处
export default class ListItem extends Component {
  constructor(prop) {
    super(prop);
    this.state = {
      isActive: !!prop.isActive, // 激活
      isSelected: !!prop.isSelected, // 选中
    };
    prop.getRef && prop.getRef(this);
  }

  static displayName = 'ListItem';
  static propTypes = {
    data: PropTypes.any,
    classname: PropTypes.string,
    getDom: PropTypes.func,
    itemIconType: PropTypes.string,
    iconType: PropTypes.string,
    isShowIcon: PropTypes.bool,
    uniqueKey: PropTypes.string,
    showKey: PropTypes.string,
    type: PropTypes.string,
    tableHeader: PropTypes.object,
    rowMaxWidth: PropTypes.object,
    handleIconClick: PropTypes.func,
    index: PropTypes.any,
    id: PropTypes.any,
    onMove: PropTypes.func,
    onDrop: PropTypes.func,
    disabled: PropTypes.bool,
    dragAble: PropTypes.bool,
    isActive: PropTypes.bool,
    isSelected: PropTypes.bool,
    getRef: PropTypes.func,
    color: PropTypes.string,
    tips: PropTypes.string,
    handleItemDragAble: PropTypes.func,
  };
  static defaultProps = {
    data: [],
    uniqueKey: 'key',
    rowMaxWidth: {},
    isActive: false,
    isSelected: false,
    dragAble: true,
    handleItemDragAble: () => true,
  };

  UNSAFE_componentWillReceiveProps(np) {
    if (np.isActive !== this.props.isActive || np.isActive !== this.state.isActive) {
      this.setState({ isActive: np.isActive });
    }
    if (np.isSelected !== this.props.isSelected || np.isSelected !== this.state.isSelected) {
      this.setState({ isSelected: np.isSelected });
    }
    np.getRef && np.getRef(this);
  }

  shouldComponentUpdate(np, ns) {
    // tableHeader 的实时修改 不支持更新
    if (
      ns.isActive === this.state.isActive &&
      ns.isSelected === this.state.isSelected &&
      np.isActive === this.props.isActive &&
      np.isSelected === this.props.isSelected &&
      np.color === this.props.color &&
      np.disabled === this.props.disabled &&
      np.dragAble === this.props.dragAble &&
      np.data === this.props.data &&
      np.data[np.uniqueKey] === this.props.data[this.props.uniqueKey] &&
      np.data[np.uniqueKey] !== undefined &&
      np.rowMaxWidth !== this.props.rowMaxWidth
    ) {
      return false;
    }
    return true;
  }

  setSelected = v => {
    if (this.state.isSelected === v) return;
    this.setState({ isSelected: v });
  };
  setActive = v => {
    if (this.state.isActive === v) return;
    this.setState({ isActive: v });
  };
  renderContent = prop => {
    const { data: content, uniqueKey, showKey, tableHeader, rowMaxWidth, color, disabled, tags = [], dragAble } = prop;
    if (typeIs(content, 'string')) return content;
    if (!typeIs(content, 'object')) return null;
    // console.log('Object.entries(tableHeader)', Object.entries(tableHeader));
    return Object.entries(tableHeader).map(([key, item]) => {
      if (key === uniqueKey && uniqueKey !== showKey) return undefined;
      if (item.type === 'checkbox') {
        return (
          <span
            key={key}
            style={{ color }}
            className={classnames('checkbox_span', {
              [`${prefixCls}__item`]: !this.isMultiColumn,
              [`${prefixCls}__item_m`]: this.isMultiColumn,
            })}
          >
            <label
              className={classnames('fn-checkbox-v2', {
                'fn-checkbox-v2__checked': this.state.isSelected,
                'fn-checkbox-v2__disabled': disabled,
              })}
            >
              <span className="check-mark" />
              <input type="checkbox" readOnly disabled={disabled} checked={this.state.isSelected} />
            </label>
          </span>
        );
      }
      if (item.type === 'custom') {
        // console.log('item', item);
        const renderContent = item?.renderElement(prop) || null;
        const customClickHandler = e => {
          // 阻止默认行为和冒泡
          e.stopPropagation();
          item.handleClick(e, item);
        };
        return React.cloneElement(renderContent, {
          onClick: customClickHandler,
        });
      }
      let value = content[key] || formatDeppProptotype(key, content, ',') || '';
      if (value === null) value = ''; // 防止某列数据位null导致span不渲染的错位问题
      const style = rowMaxWidth[key] ? { width: rowMaxWidth[key] + 22, color } : { color };
      if (!typeIs(value, 'object')) {
        return (
          <span
            key={key}
            title={`${value}`.replace(/[(<span style="color:red;">)|(</span>)]/gi, '')}
            style={style}
            className={`${prefixCls}__item ${key}`}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        );
      } else if (typeIs(value, 'object') && React.isValidElement(value)) {
        return (
          <span key={key} style={style} className={`${prefixCls}__item`}>
            {value}
          </span>
        );
      }
      return undefined;
    });
  };

  render() {
    const {
      data,
      uniqueKey,
      showKey,
      itemIconType,
      iconType,
      classname,
      type,
      handleIconClick,
      tableHeader,
      rowMaxWidth,
      getDom,
      disabled,
      dragAble,
      color,
      isShowIcon,
      tips,
      ...props
    } = this.props;
    const _props = deleteProps(
      props,
      'id',
      'index',
      'onMove',
      'onDrop',
      'isActive',
      'isSelected',
      'getRef',
      'selectedList',
      'handleItemDragAble',
    );
    const liContent = this.renderContent({
      data,
      uniqueKey,
      showKey,
      tableHeader,
      rowMaxWidth,
      color,
      disabled,
    });
    return liContent && liContent.length
      ? (tips && (
          <Tips title={tips} style={{ width: '100%' }}>
            <li
              {..._props}
              ref={getDom}
              className={classnames({
                [prefixCls]: true,
                [classname]: true,
                [`${prefixCls}--selected`]: this.state.isSelected,
                [`${prefixCls}--active`]: this.state.isActive,
                [`${prefixCls}--disabled`]: disabled || !dragAble,
                [`${prefixCls}-${type}`]: type,
              })}
            >
              {isShowIcon && iconType && <Icon classname={`${prefixCls}_content_icon`} iconType={iconType} />}
              {liContent}
              {isShowIcon && itemIconType && (
                <Icon classname={`${prefixCls}_icon`} iconType={itemIconType} onClick={handleIconClick} />
              )}
            </li>
          </Tips>
        )) || (
          <li
            {..._props}
            ref={getDom}
            className={classnames({
              [prefixCls]: true,
              [classname]: true,
              [`${prefixCls}--selected`]: this.state.isSelected,
              [`${prefixCls}--active`]: this.state.isActive,
              [`${prefixCls}--disabled`]: disabled || !dragAble,
              [`${prefixCls}-${type}`]: type,
            })}
          >
            {isShowIcon && iconType && <Icon classname={`${prefixCls}_content_icon`} iconType={iconType} />}
            {liContent}
            {isShowIcon && itemIconType && (
              <Icon classname={`${prefixCls}_icon`} iconType={itemIconType} onClick={handleIconClick} />
            )}
          </li>
        )
      : null;
  }
}
