import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { ListItem, InputIcon, Icon } from 'components';
import {
  isEmptyObj,
  arrSearch,
  typeIs,
  animateClass,
  mergeObjectToArrayNonExistent,
  emptyFunction,
  deleteProps,
  getStrWidth,
  fromJS,
  isset, // arrayEqual
} from 'utils';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { prefixCls } from './index.scss';
import { dragableBaseOpation as DBaseOpation } from './dragableBaseOpation';
import ItemsDragLayer from './ItemsDragLayer';
// 功能： 组件内筛选 拖拽排序 表都定义 多列显示 键盘上下回车操作
// 核心更新监听： data defaultSelected
@DragDropContext(HTML5Backend)
class DragList extends Component {
  constructor(prop) {
    super(prop);
    let { data = [], defaultSelected = [] } = prop;
    const { uniqueKey, initFilterKey, selectedAllKey } = prop;
    data = typeIs(data, 'array') ? data : [];
    data = data.filter(
      item => item && item[uniqueKey] !== selectedAllKey && !(initFilterKey && item[initFilterKey] === false),
    );
    defaultSelected = defaultSelected === null || defaultSelected === undefined ? [] : defaultSelected;
    this.state = {
      data,
      allData: data,
      dataLen: data.length,
      isShow: true, // !!prop.isShow,
      searchStr: prop.searchStr,
      isSearching: false,
      // newIn: prop.newIn,
      isShowSearch: !!prop.isShowSearch,
      isMoving: false,
    };
    this.tableHeader = prop.tableHeader || {};
    this.headerStyle = prop.headerStyle || {};
    this.rowMaxWidth = {};
    this.isMultiColumn =
      (this.tableHeader && !isEmptyObj(this.tableHeader) && Object.keys(this.tableHeader).length > 1) ||
      (isEmptyObj(this.tableHeader) && data.length && Object.keys(data[0]).length > 2);
    // 是否显示每一列数据的边线
    this.showSpanBorder = this.props.showSpanBorder || false;
    this.listItems = {};
    this.selectedList = defaultSelected;
    this.activeIndex = prop.defActiveIndex;
    this.liHeight = 0;
  }

  static propTypes = {
    children: PropTypes.any,
    uniqueKey: PropTypes.string.isRequired,
    showKey: PropTypes.string, // 仅用于 listitem 中当 showkey === uniqueKey 时 任然渲染uniqueKey字段
    data: PropTypes.array.isRequired, // 数据
    isMultiple: PropTypes.bool,
    isKeyboard: PropTypes.bool,
    classname: PropTypes.string,
    iconType: PropTypes.string,
    isShowSearch: PropTypes.bool, // 是否显示筛选
    searchInputRef: PropTypes.func, // 筛选框ref
    searchKey: PropTypes.string, // 查询key
    searchStr: PropTypes.string, // 初始查询内容
    isRed: PropTypes.bool, // 是否飘红
    defaultSelected: PropTypes.array,
    tableHeader: PropTypes.object, // 头部字段
    headerStyle: PropTypes.object, // 头部样式
    autoColWidth: PropTypes.bool,
    showHeader: PropTypes.bool,
    isShow: PropTypes.bool,
    isTodoSelect: PropTypes.bool, // 是否为TODO 选项
    handleIconClick: PropTypes.func,
    handleSearchChange: PropTypes.func,
    handleSearchClick: PropTypes.func,
    handleSearchKeyDown: PropTypes.func,
    handleBlur: PropTypes.func,
    isShowTips: PropTypes.bool, // 键盘上下提示
    isShowNoDataTips: PropTypes.bool, // 无数据提示
    isSearchInside: PropTypes.bool, // 组件内查询
    isShowCheckColumn: PropTypes.bool, // 是否显示勾选列
    handleDoubleClick: PropTypes.func, // 双击
    direction: PropTypes.string, // 方向
    dragable: PropTypes.bool, // 是否可拖拽
    childrenType: PropTypes.string,
    handleDraged: PropTypes.func,
    showSpanBorder: PropTypes.bool,
    onMouseLeave: PropTypes.func,
    initFilterKey: PropTypes.string, // 数据过滤
    defActiveIndex: PropTypes.number, // 默认选中项 // 则默认选中第一行
    style: PropTypes.object, // 样式
    selectAllInside: PropTypes.bool, // 内部 全选项
    selectedAllKey: PropTypes.string, // 全部选项 key值
    searchHeaderIn: PropTypes.bool, // 筛选框在ul内
    searchPlaceHolder: PropTypes.string, // 筛选框在ul内
    getScrollToIndex: PropTypes.func, // 要滚动到的idnex
    newInMarkRed: PropTypes.bool, // 最新加入的标红
    newIn: PropTypes.array, // 最新加的
    keepFilter: PropTypes.bool, // 数据变更后是否保留筛选
    isShowIcon: PropTypes.bool, // 是否显示 icon
    isNeedRightClear: PropTypes.bool, // 是否显示 icon
    handleItemDragAble: PropTypes.func, // 判断当前列是否可拖拽
    stickyRightIndex: PropTypes.number, // 判断拖拽范围
    stickyIndex: PropTypes.number,
    customRender: PropTypes.object,
  };
  static defaultProps = {
    isRed: true,
    isShow: false,
    isShowTips: false,
    isShowNoDataTips: false,
    defaultSelected: [],
    direction: 'left_down',
    isShowSearch: false,
    autoColWidth: true,
    dragable: false,
    childrenType: 'ListItem',
    defActiveIndex: -1,
    tableHeader: {},
    searchStr: '',
    selectAllInside: false,
    selectedAllKey: '_NULL_',
    isNeedRightClear: false,
    handleItemDragAble: () => true,
  };

  componentDidMount() {
    this.handleSelectAllInside();
  }

  componentDidUpdate() {
    this.handleSelectAllInside();
    if (this.props.getScrollToIndex && this.props.getScrollToIndex()) {
      this.handleSroll(this.props.getScrollToIndex());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { data, defaultSelected } = nextProps;
    const { tableHeader, headerStyle, uniqueKey, initFilterKey, selectedAllKey, keepFilter } = nextProps;
    const newState = {};
    if (nextProps.searchStr !== this.props.searchStr) {
      // this.handleSearchChange(undefined, nextProps.searchStr)
      newState.searchStr = nextProps.searchStr;
    }
    // if (data !== this.props.data && arrayEqual(data, this.props.data, uniqueKey)) {
    // TODO 此处 需再验证下 数据变化 而searchStr未变的情况
    if (data !== this.props.data) {
      this.tableHeader = tableHeader || {};
      this.headerStyle = headerStyle || {};
      this.rowMaxWidth = {};
      this.isMultiColumn =
        (!isEmptyObj(this.tableHeader) && Object.keys(this.tableHeader).length > 1) ||
        (isEmptyObj(this.tableHeader) && data.length && Object.keys(data[0]).length > 2);
      data = data.filter(
        item => item[uniqueKey] !== selectedAllKey && !(initFilterKey && item[initFilterKey] === false),
      );
      this.setState({ dataLen: data.length, data, allData: data });
      // TODO arrayEqual 判断是否影响别处 如筛选
      // !arrayEqual(data, this.props.data, nextProps.uniqueKey) && this.handleSearchChange(undefined, newState.searchStr || this.state.searchStr, data)
      keepFilter && this.handleSearchChange(undefined, newState.searchStr || this.state.searchStr, data);
      // console.log(arrayEqual(data, this.props.data, nextProps.uniqueKey))
      // this.handleSearchChange(undefined, nextProps.searchStr, data)
    }
    defaultSelected = defaultSelected === null || defaultSelected === undefined ? [] : defaultSelected;
    if (defaultSelected !== false) {
      this.selectedList = defaultSelected;
    }
    if (nextProps.isShow !== this.props.isShow) {
      newState.isShow = nextProps.isShow;
    }
    // if (nextProps.newIn !== this.props.newIn) {
    //   newState.newIn = nextProps.newIn
    // }
    if (nextProps.isShowSearch !== this.props.isShowSearch) {
      newState.isShowSearch = !!nextProps.isShowSearch;
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
    this.resetActiveIndex();
  }

  selectItem = (index, v) => {
    this.listItems[index] && this.listItems[index].setSelected(v);
  };
  selectAll = async (status, position = 'default') => {
    // 当前可见的全部
    const { uniqueKey } = this.props;
    const { initFilterKey } = this.props;
    let checkedData = [];
    if (status) {
      checkedData = this.state.data
        .filter(
          item => item[uniqueKey] !== this.props.selectedAllKey && !(initFilterKey && item[initFilterKey] === false),
        )
        .map(item => (item.oldIndex !== undefined ? this.state.allData[item.oldIndex] : item));
    }
    const tagsData = checkedData.find(el => {
      return Array.isArray(el.tags) && el.tags.length !== 0;
    });
    const shouldPreventCommonClick = await this.getCustomRenderEvent(this.props.customRender, tagsData || {});
    if (shouldPreventCommonClick && position === 'left') {
      return false;
    }
    for (let i = 0; i < this.state.data.length; i++) {
      this.selectItem(i, status);
    }
    this.selectedList = checkedData;
    if (this.props.selectAllInside) this.selectItem(this.props.selectedAllKey, status);
    return checkedData;
  };
  // active 只有一个 index === -1 则为复位全部，
  // 否则 设置某个index 为active，其他都为非active
  activeItem = (index, v = true) => {
    if (this.activeIndex !== -1 && (index === -1 || this.activeIndex !== index)) {
      // 复位
      this.listItems[this.activeIndex] && this.listItems[this.activeIndex].setActive(false);
    }
    this.listItems[index] && this.listItems[index].setActive(v);
    this.activeIndex = index;
  };
  // 仅一个全部选项时 选中全部
  handleSelectAllInside = () => {
    if (
      this.props.selectAllInside &&
      this.selectedList.length !== this.state.data.length &&
      this.selectedList.length === 1 &&
      this.selectedList[0][this.props.uniqueKey] === this.props.selectedAllKey
    ) {
      this.selectAll(true);
    }
  };
  getSelected = () => this.selectedList;
  getActiveIndex = () => this.activeIndex;
  handleResetState = () => {
    this.selectAll(false);
    this.activeItem(this.state.dataLen > 0 ? this.props.defActiveIndex : -1, false);
    this.selectedList = [];
  };
  handleShow = callback => {
    !this.state.isShow &&
      this.setState({ isShow: true }, () => {
        this.ul && animateClass(this.ul, 'slide-up-enter slide-up-enter-active', callback);
      });
  };
  handleHide = callback => {
    let showDirection = 'slide-up-leave slide-up-leave-active';
    if (this.props.direction.indexOf('up') !== -1) {
      showDirection = 'slide-down-leave slide-down-leave-active';
    }
    this.state.isShow &&
      animateClass(this.ul, showDirection, () => {
        callback && callback();
        !this.isLeave && this.setState({ isShow: false });
      });
  };
  toggleDisplay = () => {
    this.state.isShow ? this.handleHide() : this.handleShow();
  };
  getSearchInputIcon = () => this._searchInputIcon;
  onMove = (dragIndex, hoverIndex, selectedList) => {
    const { allData } = this.state;
    const { uniqueKey, stickyIndex, stickyRightIndex } = this.props;
    const selectKeys = selectedList.map(item => item[uniqueKey]);
    const notSelectData = allData.filter(item => !selectKeys.includes(item[uniqueKey]));
    let _hoverIndex = hoverIndex > notSelectData.length ? notSelectData.length : hoverIndex;
    let _dragIndex = dragIndex;
    if (typeIs(stickyIndex, 'number')) {
      if (_hoverIndex <= stickyIndex) {
        _hoverIndex = stickyIndex + 1;
        _dragIndex = stickyIndex + 1;
      }
    }
    if (typeIs(stickyRightIndex, 'number')) {
      if (_hoverIndex >= stickyRightIndex) {
        _hoverIndex = stickyRightIndex - 1;
        _dragIndex = stickyRightIndex - 1;
      }
    }
    const dragCard = allData[_dragIndex]; // 临时替代占位的一个项（多个同时拖拽时放下后是一堆）
    const _data = [].concat(notSelectData);
    const _allData = [].concat(notSelectData);
    _data.splice(_hoverIndex, 0, dragCard);
    _allData.splice(_hoverIndex, 0, ...selectedList);
    // 移动过程中 设置样式 无hover效果 -> dnd的bug,hover错位
    this.setState({ allData: _allData, data: _data, isMoving: true });
  };
  onDrop = (dragIndex, dropIndex, dragList, newDragedItems) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const data = this.state.allData;
    // this.setState({ data, newIn: [...this.state.newIn, ...newDragedItems] })
    this.setState({ data, isMoving: false });
    this.props.handleDraged && this.props.handleDraged(data, dragIndex, dropIndex, newDragedItems);
  };
  /**
   * 选中函数, next 表示外部函数, 当我触发了li.onclick之后, 触发的外部callback, 由父组件传进来的
   * 其本身是绑定在opation上面的函数
   *
   * @param  {[type]}   e     [description]
   * @param  {[number]}   index [索引]
   * @param  {Function} next  [description]
   * @return {[type]}         [description]
   */
  handleSelect = (e, newIndex, next) => {
    clearTimeout(this.onRowClickTimeOutID);
    if (this.props.handleDoubleClick) {
      this.onRowClickTimeOutID = setTimeout(() => {
        this.handleSelectOp(e, newIndex, next);
      }, 200);
    } else {
      this.handleSelectOp(e, newIndex, next);
    }
  };
  handleSelectOp = (e, newIndex, next) => {
    if (this.props.isTodoSelect) {
      this.props.handleIconClick();
      return;
    }
    const { uniqueKey, isMultiple, initFilterKey } = this.props;
    const { allData = [], data = [] } = this.state;
    if (newIndex === -1) {
      next && next(newIndex, e);
      return;
    } else if (newIndex === this.props.selectedAllKey) {
      // 全选toggle
      this.selectAll(
        this.selectedList.length !==
          this.state.data.filter(
            item => item[uniqueKey] !== this.props.selectedAllKey && !(initFilterKey && item[initFilterKey] === false),
          ).length,
      );
      // this.state.data.filter(item => item[uniqueKey] !== this.props.selectedAllKey).length)
      next && next(this.selectedList, e);
      return;
    }
    const index = data[newIndex] && !typeIs(data[newIndex].oldIndex, 'undefined') ? data[newIndex].oldIndex : newIndex;
    const selectedData = allData[index] || {};
    if (!isMultiple) {
      // TODO 单选
      const isSelected =
        this.selectedList.length === 0 ||
        this.selectedList[0][this.props.uniqueKey] === selectedData[this.props.uniqueKey];
      this.selectItem(newIndex, isSelected);
      this.selectedList = [selectedData];
      next && next(selectedData, e);
      return;
    }
    // TODO 多选
    // const selectedList = mergeObjectToArrayNonExistent(this.selectedList, selectedData, uniqueKey)
    // 多选选中具体项时 过滤空key值的项目
    const selectedList = this.mergeToSelected(this.selectedList, selectedData, uniqueKey);
    next && next(selectedList, e);
    // 当前勾选项
    this.selectItem(newIndex, selectedList.length > this.selectedList.length);
    // 全部勾选项
    this.selectItem(
      this.props.selectedAllKey,
      selectedList.length ===
        this.state.data.filter(
          item => item[uniqueKey] !== this.props.selectedAllKey && !(initFilterKey && item[initFilterKey] === false),
        ).length,
      // selectedList.length === this.state.data.filter(item => item[uniqueKey] !== this.props.selectedAllKey).length
    );
    this.selectedList = selectedList;
  };
  // 加入“全部”选项 逻辑
  // 此处 需要过滤 空uniqueKey的项item[uniqueKey] && item[uniqueKey] !== this.props.selectedAllKey
  // 以使新录入的空unqueKey项目可以插入（手动输入 搜索时用）
  mergeToSelected = (selectedList = [], selectedData, uniqueKey) =>
    mergeObjectToArrayNonExistent(
      selectedList.filter(item => item[uniqueKey] !== '' && item[uniqueKey] !== this.props.selectedAllKey),
      selectedData,
      uniqueKey,
    );
  getLiHeight = () => {
    if (this.liHeight || !this.ul || !this.ul.children[0]) return this.liHeight;
    return (this.liHeight = this.liHeight ? this.liHeight : this.ul.children[0].offsetHeight);
  };

  handleSroll(index) {
    if (!this.ul) return;
    if (index === 0 || index === -1 || !this.ul.children[0]) {
      this.ul.scrollTop = 0;
      return;
    }
    const liHeight = this.getLiHeight();
    // this.liHeight = this.liHeight ? this.liHeight : this.ul.children[0].offsetHeight
    const ulHeight = this.ul.offsetHeight; // 可视区域的高度
    const scroll = liHeight * (index + 1);
    if (scroll >= ulHeight) {
      this.ul.scrollTop = scroll - ulHeight;
    }
  }

  handleFirstHover() {
    const activeIndex = this.state.dataLen > 0 ? this.props.defActiveIndex : -1;
    this.activeItem(activeIndex);
    // this.handleSroll(activeIndex)
  }

  handleNextHover() {
    let activeIndex;
    if (this.activeIndex >= this.state.dataLen - 1) {
      activeIndex = 0;
    } else {
      activeIndex = this.activeIndex + 1;
    }
    this.activeItem(activeIndex);
    this.handleSroll(activeIndex);
  }

  handlePreHover() {
    let activeIndex;
    if (this.activeIndex <= 0) {
      activeIndex = this.state.dataLen - 1;
    } else {
      activeIndex = this.activeIndex - 1;
    }
    this.activeItem(activeIndex);
    this.handleSroll(activeIndex);
  }

  handleMouseLeaveByUl = () => {
    // this.resetActiveIndex()
    this.props.onMouseLeave && this.props.onMouseLeave();
  };
  // selectdrop 不用的事件
  // handleSearchChange={handleSearchChange}
  // handleSearchClick={this.handleSearchClick}
  // handleBlur={this.handleSearchBlur}
  handleSearchChange = (e, str, allData = this.state.allData) => {
    const searchStr = e ? e.target.value : str;
    const { handleSearchChange, searchKey, isRed, isSearchInside } = this.props;
    let data = allData;
    if (this.searchTimer) clearTimeout(this.searchTimer);
    if (isSearchInside) {
      // 内部筛选
      let isSearching = true;
      if (searchStr === '') {
        isSearching = false;
        data = data.slice(0, 100); // 数据过多卡顿问题
        this.searchTimer = setTimeout(() => this.setState({ data: allData }), 500);
      } else {
        data = arrSearch(searchStr, allData, searchKey, isRed, ',');
      }
      this.setState({ searchStr, data, isSearching, dataLen: data.length });
    } else {
      this.searchStr = searchStr;
      handleSearchChange && handleSearchChange(e, searchStr, this);
    }
  };

  /**
   * 模仿鼠标移入
   * @param  {[number]} index 索引
   * @return {[type]}
   */
  imitationMouseEnter(index) {
    this.activeItem(index);
  }

  resetActiveIndex() {
    this.activeItem(this.state.dataLen > 0 ? this.props.defActiveIndex : -1);
  }

  resetSelect() {
    this.selectedList = [];
    this.selectAll(false);
  }

  handleDoubleClick = (...rest) => {
    // this.handleSelect(...rest)
    clearTimeout(this.onRowClickTimeOutID);
    this.props.handleDoubleClick && this.props.handleDoubleClick(...rest);
  };
  getMaxStrLength = (content, header) => {
    if (!this.ul || !this.props.autoColWidth) return;
    // if (!this.props.autoColWidth) return
    let maxLength = 0;
    let strWidth = 0;
    const showKeyList =
      header !== undefined && !isEmptyObj(header) ? Object.keys(header || {}) : Object.keys(content || {});
    showKeyList.forEach(key => {
      maxLength = this.rowMaxWidth[key] || 0;
      const splitKey = key.split(',') || [];
      let value = content[key] || '';
      if (splitKey.length > 1) value = fromJS(content).getIn(splitKey);
      if (value === null) value = ''; // 防止某列数据位null导致span不渲染的错位问题
      if (key === this.props.uniqueKey) strWidth = 0;
      strWidth = getStrWidth(value, 14, this.ul, r => (this.strWidthObj = r), this.strWidthObj);
      this.rowMaxWidth[key] = maxLength > strWidth || strWidth === 0 ? maxLength : strWidth;
    });
  };
  getListItemHeader = (content, header = this.tableHeader, index, itemData) => {
    const showKeyList =
      header !== undefined && !isEmptyObj(header) ? Object.keys(header || {}) : Object.keys(content || {});
    const tableHeader = this.props.isShowCheckColumn ? { baseOpationCheckColumn: { title: '', type: 'checkbox' } } : {};
    showKeyList.forEach(key => {
      tableHeader[key] = { title: header[key] || '', type: '' };
    });
    // 更改tableHeader的值增加渲染项目
    if (typeof this.props.customRender === 'object' && Object.keys(this.props.customRender).length > 0) {
      const tags = content?.tags || [];
      tags.forEach(key => {
        if (this.props?.customRender?.[key]) {
          tableHeader[key] = {
            type: 'custom',
            ...this.props.customRender[key],
          };
        }
      });
    }
    if (this.props.isNeedRightClear) {
      tableHeader.rightClear = {
        type: 'custom',
        renderElement: () => {
          // console.log('item', item);
          return (
            <span
              style={{
                position: 'absolute',
                right: '3px',
                width: '16px',
                height: '16px',
                fontSize: '16px',
                borderRadius: '99px',
                textAlign: 'center',
                lineHeight: '16px',
                top: '50%',
                cursor: 'pointer',
                transform: 'translateY(-50%)',
              }}
            >
              <Icon iconType="icon-close" />
            </span>
          );
        },
        handleClick: e => {
          return new Promise(resolve => {
            this.handleDoubleClick(e, index, itemData);
            resolve({
              // 多个标签的情况下是否阻断其他事件执行
              shouldExecuteOther: false,
              // 是否阻止默认点击事件执行【原有的公共事件】
              shouldPreventCommonClick: false,
            });
          });
        },
      };
    }
    // console.log('tableHeader', tableHeader);
    return tableHeader;
  };
  // 查看数据的tags中的事件是否有匹配
  getCustomRenderEvent = async (customRender, item) => {
    if (
      !customRender ||
      (typeof this.props.customRender === 'object' && Object.keys(this.props.customRender).length === 0) ||
      !item.tags ||
      (Array.isArray(item.tags) && item.tags.length === 0)
    ) {
      return false;
    }
    const funcsArray = item?.tags?.map(key => customRender[key]?.handleClick).filter(Boolean) || [];
    if (!funcsArray.length) {
      return false;
    }
    const shouldPreventCommonClick = await this.runFuncs(funcsArray, item);
    return shouldPreventCommonClick;
  };
  // 执行绑定的事件
  async runFuncs(funcs, data) {
    // 是否阻断原有的handleClick事件
    let lastResult = true;
    for (const func of funcs) {
      const { shouldExecuteNext = true, shouldPreventCommonClick = false } = await func(data);
      lastResult = lastResult && shouldPreventCommonClick;
      if (!shouldExecuteNext) {
        break;
      }
    }
    return lastResult;
  }
  renderList() {
    const { children, uniqueKey, showKey, iconType, dragable, childrenType, initFilterKey, selectedAllKey, newIn } =
      this.props;
    const { data, isSearching, isShowSearch, allData } = this.state;
    if ((children && !React.isValidElement(children)) || !data || !typeIs(data, 'array')) return null;
    // 列表的内容可以定制... 不传的话 采用 默认
    let element = dragable && !isSearching ? React.createElement(DBaseOpation) : <ListItem />;
    if (typeof children !== 'undefined' && children) {
      if (isset(children, 'type.name') === 'ListItem' || childrenType === 'ListItem') {
        element =
          dragable && !isSearching ? (
            React.createElement(DBaseOpation, { ...children.props })
          ) : (
            <ListItem {...children.props} />
          );
      } else {
        element = children;
      }
    }
    this.listItems = {};
    // console.log('ListItemData', data);
    const listItems = data.map((iitem, iindex) => {
      let index = iindex;
      const item = { ...iitem };
      // 排除 无内容项、initFilterKey不符合的项、'全部'项(后端兼容客户端 先保留全部选项 前端做排除)
      if (
        typeof item === 'undefined' ||
        !item ||
        !typeIs(item, 'object') ||
        (initFilterKey && item[initFilterKey] === false) ||
        item[uniqueKey] === selectedAllKey
      ) {
        return undefined;
      }
      if (this.props.isSearchInside && !typeIs(item.oldIndex, 'undefined')) {
        index = item.oldIndex; // 原始index //  allData[index] 事件接口等 回传的item需为原始值
      }
      const disabled = (typeIs(item.disabled, 'boolean') && item.disabled) || false;
      const itemDragAble = typeIs(item.dragAble, 'boolean') ? item.dragAble : true;
      // 某行最大宽度
      this.getMaxStrLength(allData[index], this.tableHeader);
      const { selectedList } = this;
      return React.cloneElement(element, {
        index,
        id: item[uniqueKey],
        key: index,
        data: item,
        type: item.type,
        color: newIn && newIn.find(it => it[uniqueKey] === item[uniqueKey]) ? '#0103ff' : item.color,
        tableHeader: this.getListItemHeader(item, this.tableHeader, iindex, allData[index]), // this.tableHeader,
        rowMaxWidth: this.rowMaxWidth,
        getDom: r => (this[`li${index}`] = r),
        getRef: r => (this.listItems[iindex] = r),
        onMove: this.onMove,
        onDrop: this.onDrop,
        handleItemDragAble: this.props.handleItemDragAble,
        tips: item?.tips || '',
        onClick: disabled
          ? emptyFunction
          : async e => {
              const shouldPreventCommonClick = await this.getCustomRenderEvent(this.props.customRender, item);
              if (!shouldPreventCommonClick) {
                this.handleSelect(e, iindex, element.props.onClick);
              }
            },
        onDoubleClick: disabled
          ? emptyFunction
          : async e => {
              const shouldPreventCommonClick = await this.getCustomRenderEvent(this.props.customRender, item);
              if (!shouldPreventCommonClick) {
                this.handleDoubleClick(e, iindex, allData[index], element.props.onClick);
              }
            },
        handleIconClick: () => this.props.handleIconClick && this.props.handleIconClick(allData[index], index),
        onMouseEnter: (...args) =>
          element.props.onMouseEnter && element.props.onMouseEnter(this.state.allData[index], ...args),
        isSelected: !!selectedList.find(obj => obj[uniqueKey] === item[uniqueKey]),
        selectedList: () => this.selectedList, // 多项拖拽用
        classname: classnames([element.props.className], [item.className], {
          showsearch_li: iindex === 0 && isShowSearch,
        }),
        itemIconType: item.itemIconType || iconType,
        iconType: item.iconType,
        isShowIcon: item.isShowIcon === undefined ? true : item.isShowIcon,
        disabled,
        dragAble: itemDragAble,
        uniqueKey,
        showKey,
      });
    });
    // 下拉全选项
    if (this.props.selectAllInside && listItems.filter(item => item).length > 1) {
      const selectAllItem = { [uniqueKey]: selectedAllKey, [Object.keys(this.tableHeader)[0]]: '全部' };
      const selectAllEle = <ListItem {...children.props} />;
      const isSelectedAll =
        this.selectedList.length ===
          this.state.data.filter(
            item => item[uniqueKey] !== this.props.selectedAllKey && !(initFilterKey && item[initFilterKey] === false),
          ).length ||
        (this.selectedList.length === 1 && this.selectedList[0][uniqueKey] === selectedAllKey);
      listItems.unshift(
        React.cloneElement(selectAllEle, {
          index: selectedAllKey,
          id: selectedAllKey,
          key: selectedAllKey,
          data: selectAllItem,
          tableHeader: this.getListItemHeader(selectAllItem, this.tableHeader),
          rowMaxWidth: this.rowMaxWidth,
          getDom: r => (this[`li${selectedAllKey}`] = r),
          getRef: r => (this.listItems[selectedAllKey] = r),
          onClick: e => this.handleSelect(e, selectedAllKey, selectAllEle.props.onClick),
          // onDoubleClick: e => this.handleDoubleClick(e, selectedAllKey, selectAllEle.props.onClick),
          handleIconClick: () =>
            this.props.handleIconClick && this.props.handleIconClick(selectAllItem, selectedAllKey),
          onMouseEnter: () => selectAllEle.props.onMouseEnter && selectAllEle.props.onMouseEnter(selectAllEle),
          isSelected: isSelectedAll,
          classname: classnames([selectAllEle.props.className], {
            showsearch_li: selectedAllKey === 0 && isShowSearch,
          }),
          itemIconType: iconType,
          disabled: false,
          uniqueKey,
          showKey,
        }),
      );
    }
    return listItems;
  }

  /* 特殊的header */
  handleSearchClick = e => {
    this.props.handleSearchClick &&
      this.props.handleSearchClick(e, this.props.isSearchInside ? this.state.searchStr : this.searchStr);
  };
  renderHeader = list => {
    const { isShowTips, isShowNoDataTips, handleBlur, children, showHeader, autoColWidth } = this.props;
    const { isShowSearch, searchStr, data } = this.state;
    let tipHeader = null;
    let noDataTipHeader = null;
    let searchHeader = null;
    let titleHeader = null;
    if (isShowTips && !isEmptyObj(data)) {
      tipHeader = (
        <div className="head_tip_content">
          请使用键盘&quot;
          <Icon iconType="icon-arrow-mup" />
          <Icon iconType="icon-arrow-mdown" />
          &quot;键来选择
        </div>
      );
    }
    // 过滤后剩余的数据为空的情况下，也显示“noDataTipsHeader”
    if (isShowNoDataTips && (isEmptyObj(data) || list.filter(x => x !== undefined).length === 0)) {
      noDataTipHeader = '无相关数据';
    }
    if (0 && isShowSearch) {
      searchHeader = React.createElement(InputIcon, {
        ref: r => {
          this._searchInputIcon = r;
          this.props.searchInputRef && this.props.searchInputRef(r);
        },
        handleBlur,
        onChange: (...args) => setTimeout(this.handleSearchChange(...args), 0),
        style: { width: '100%' },
        defaultValue: searchStr,
        onKeyDown: this.props.handleSearchKeyDown,
        iconProps: { iconType: 'icon-search', onClick: this.handleSearchClick },
        onClick: e => this.handleSelect(e, -1, children && children.props && children.props.onClick),
      });
    }
    if (showHeader && !isEmptyObj(this.tableHeader) && !isEmptyObj(data)) {
      titleHeader = this.props.isShowCheckColumn
        ? [
            <span
              style={this.headerStyle.baseOpationCheckColumn}
              className="checkbox_span baseOpationCheckColumn"
              key="baseOpationCheckColumn"
            />,
          ]
        : [];
      titleHeader = titleHeader.concat(
        Object.entries(this.tableHeader).map(([key, value]) => {
          let headerSt = {};
          if (!isEmptyObj(this.headerStyle) && this.headerStyle[key]) {
            headerSt = this.headerStyle[key];
          }
          if (autoColWidth) {
            headerSt = Object.assign(headerSt, { width: this.rowMaxWidth[key] + 20 });
          }
          return (
            <span
              title={value}
              style={headerSt}
              className={classnames({ 'fn-menu-opation__item': true, [key]: true })}
              key={key}
            >
              {value}
            </span>
          );
        }),
      );
    }
    const headers = {
      tipHeader: {
        content: tipHeader,
        cls: classnames({
          [`${prefixCls}__default`]: isShowTips,
          [`${prefixCls}__header`]: true,
        }),
      },
      noDataTipHeader: {
        content: noDataTipHeader,
        cls: classnames({
          [`${prefixCls}__default`]: isShowTips,
          [`${prefixCls}__header`]: true,
        }),
      },
      titleHeader: {
        content: titleHeader,
        cls: classnames({
          [`${prefixCls}__table-header`]: true,
        }),
      },
      searchHeader: {
        content: searchHeader,
        cls: classnames({
          [`${prefixCls}__default`]: isShowTips,
          [`${prefixCls}__search`]: isShowSearch,
          [`${prefixCls}__header`]: true,
        }),
      },
    };
    return Object.keys(headers).map((hkey, index) => {
      const header = headers[hkey];
      return (
        header.content && (
          <li key={index} className={header.cls}>
            {header.content}
          </li>
        )
      );
    });
  };

  render() {
    const { classname, isShowNoDataTips, style, children, searchHeaderIn, dragable, ...props } = this.props;
    const { data = {}, isSearching, isShowSearch } = this.state;
    if (!this.state.isShow || (isEmptyObj(data) && !isShowNoDataTips && !isShowSearch)) return null;
    const classes = classnames({
      [prefixCls]: true,
      [`${prefixCls}_m`]: this.isMultiColumn,
      [`${prefixCls}_none_border`]: this.showSpanBorder,
      'no-hover': this.state.isMoving,
      [classname]: classname,
    });
    const _props = deleteProps(
      props,
      'data',
      'isKeyboard',
      'children',
      'isMultiple',
      'showKey',
      'uniqueKey',
      'iconType',
      'searchKey',
      'defaultSelected',
      'showHeader',
      'handleIconClick',
      'isTodoSelect',
      'handleSearchChange',
      'handleSearchClick',
      'handleSearchKeyDown',
      'filterCallback',
      'isRed',
      'handleBlur',
      'isShowSearch',
      'isSearchInside',
      'isShowTips',
      'isShow',
      'handleDoubleClick',
      'childrenType',
      'handleDraged',
      'isShowCheckColumn',
      'tableHeader',
      'onMouseLeave',
      'showSpanBorder',
      'initFilterKey',
      'defActiveIndex',
      'autoColWidth',
      'headerStyle',
      'searchStr',
      'selectAllInside',
      'searchInputRef',
      'searchPlaceHolder',
      'getScrollToIndex',
      'newInMarkRed',
      'newIn',
      'keepFilter',
      'selectedAllKey',
      'isNeedRightClear',
      'handleItemDragAble',
      'stickyRightIndex',
      'stickyIndex',
      'customRender',
    );
    // 当data为空时，不显示header
    this.getMaxStrLength(this.tableHeader, this.tableHeader);
    // 获取this.rowMaxWidth[iindex] 用于renderHeader
    const list = this.renderList();
    let searchHeader = null;
    if (this.props.isShowSearch) {
      searchHeader = React.createElement(InputIcon, {
        ref: r => {
          this._searchInputIcon = r;
          this.props.searchInputRef && this.props.searchInputRef(r);
        },
        handleBlur: this.props.handleBlur,
        onChange: (...args) => setTimeout(this.handleSearchChange(...args), 0),
        style: { width: '100%' },
        defaultValue: this.state.searchStr,
        onKeyDown: this.props.handleSearchKeyDown,
        placeholder: this.props.searchPlaceHolder,
        iconProps: { iconType: 'icon-search', onClick: this.handleSearchClick },
        inputProps: { placeholder: '请输入...' },
        onClick: e => this.handleSelect(e, -1, children && children.props && children.props.onClick),
      });
    }
    return (
      <div>
        {!searchHeaderIn && searchHeader && <div className="fn-opation__search fn-opation__header">{searchHeader}</div>}
        <ul
          {..._props}
          style={style}
          className={classes}
          onMouseLeave={this.handleMouseLeaveByUl}
          ref={r => (this.ul = r)}
        >
          {searchHeaderIn && searchHeader && (
            <div className="fn-opation__search fn-opation__header">{searchHeader}</div>
          )}
          {(!isEmptyObj(data) || isShowNoDataTips || isSearching || isShowSearch) && this.renderHeader(list)}
          {dragable && <ItemsDragLayer style={{ ...style, maxHeight: 66, overflow: 'hidden' }} />}
          {list}
        </ul>
      </div>
    );
  }
}
export default DragList;
const BaseList = DragList;
export { BaseList };
