var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-color-select.active .toggle-icon{-webkit-transform:rotate(180deg);transform:rotate(180deg)}.fn-color-select>.toggle-icon{position:absolute;margin:0;top:50%;right:5px;width:10px;height:6px;margin-top:-3px;background:url(" + escape(require("images/small-arrow-down.png")) + ") 50% no-repeat;background-size:7px auto}.fn-color-select .bg-color{display:inline-block;height:20px;width:calc(100% - 20px);position:relative;top:-22px;left:2px}.fn-color-select__menu{max-width:900px}.fn-color-select__menu .color-box{width:180px}.fn-color-select__menu .color-box span{float:left;display:inline-block;height:26px;width:26px;margin:5px}.fn-color-select__menu .color-box span.checked,.fn-color-select__menu .color-box span.highlight,.fn-color-select__menu .color-box span.highlight.checked{border:1px solid #abdbfa}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-color-select",
	"menuClass": "fn-color-select__menu",
	"fn-color-select": "fn-color-select",
	"active": "active",
	"toggle-icon": "toggle-icon",
	"bg-color": "bg-color",
	"fn-color-select__menu": "fn-color-select__menu",
	"color-box": "color-box",
	"highlight": "highlight",
	"checked": "checked"
};