import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragLayer } from 'react-dnd';
import { ListItem } from 'components';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100000,
  left: 0,
  top: 0,
  // width: '100%',
  // height: '100%',
};
const getFieldStyle = isDragging => {
  const style = {
    // width: 300,
    // maxWidth: 300,
  };
  style.opacity = isDragging ? 0.5 : 1;
  return style;
};
const getItemStyles = props => {
  const { currentOffset, style } = props;
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    ...style,
    transform,
    WebkitTransform: transform,
  };
};
@DragLayer(monitor => ({
  dragItem: monitor.getItem(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
}))
export default class ItemsDragLayer extends Component {
  static propTypes = {
    style: PropTypes.object,
    dragItem: PropTypes.object,
    initialOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    currentOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    isDragging: PropTypes.bool,
  };

  render() {
    const { dragItem, isDragging } = this.props;
    if (!isDragging || (dragItem && !dragItem.dragItemProps)) return null;
    const itemProps = dragItem.dragItemProps;
    const { uniqueKey } = itemProps;
    const getItemProps = (item, index) => ({
      index,
      id: item[uniqueKey],
      key: index,
      data: item,
      type: item.type,
      // color: undefined,
      // classname: undefined,
      tableHeader: itemProps.tableHeader,
      rowMaxWidth: itemProps.rowMaxWidth,
      isSelected: itemProps.isSelected,
      uniqueKey,
      showKey: itemProps.showKey,
      iconType: itemProps.iconType,
    });
    const list = dragItem.selectedList.slice(0, 3);
    return (
      <div style={layerStyles}>
        <div style={getItemStyles(this.props)}>
          <div style={getFieldStyle(this.props.isDragging)}>
            <div>
              {list.map((item, index) => (
                <ListItem key={item.id} {...getItemProps(item, index)} data={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
