import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { CardForm } from 'components';
import classnames from 'classnames';
import { EventListener, isEmptyObj, getComVal, fromJS, throttle, addClass, getIsNewTableStyle } from 'utils';
import onElementResize from 'element-resize-event';
import { dataStrippingConfig } from 'utils/dataStripping/dataStrippingConfig';
import { EMPTY_VALS_ALL, NOT_EMPTY_VALS_ALL } from 'constants';
import { dataAnalyticTrack, datHelper, getDatTitle } from '@/utils/dataAnalytic';
import { prefixCls, clsTabsBtnFontSize, clsTabsBtnMinWidth, clsTabsToggleBtnWidth } from './index.scss';
// 字数小于10个不变字体版
// const lenMap = {
//   6: { paddingLeft: 30 },
//   7: { paddingLeft: 10 },
//   8: { paddingLeft: 10 },
//   11: { fontSize: 12 },
//   12: { fontSize: 12 },
// }
// 字数大于5 变12号字体版
const lenMap = {
  6: { fontSize: 12 },
  7: { paddingLeft: 25, fontSize: 12 },
  8: { paddingLeft: 25, fontSize: 12 },
  9: { paddingLeft: 15, fontSize: 12 },
  10: { paddingLeft: 15, fontSize: 12 },
  11: { fontSize: 12 },
  12: { fontSize: 12 },
};
export default class Filter extends PureComponent {
  constructor(prop) {
    super(prop);
    this.isEmptyFilter = false;
    this.tabsKeys = Object.keys(prop.tabs);
    this.isMouseIn = false;
    this.activeIndexNum = 0;
    this.firstLineWidth = 0;
    this.tabWidthArr = [];
    const _state = {
      isShowDescribe: prop.isShowDescribe || false,
      category: prop.category || '',
      activeIndex: prop.activeIndex || this.tabsKeys[0],
      isShowTabsTogBtn: false,
      isTabsExpanded: false,
      tabsRowCount: 1,
      tabsKeys: this.tabsKeys,
      textareaHeight: prop.isTextareaSingleLine ? 28 : 42,
      specialVal: [...EMPTY_VALS_ALL, ...NOT_EMPTY_VALS_ALL].reduce((pre, key) => ({ ...pre, [key]: 1 }), {}),
    };
    if (prop.tabGroups && prop.tabGroups.length) {
      _state.tabsKeys = prop.tabGroups.find(item => this.isGroupActive(item, _state.activeIndex)).tabs;
    }
    this.state = { ..._state, filter: this.formatFilter(prop.filter, prop.filterValue, _state, prop) };

    datHelper(() => {
      this.datContext = {
        lastActiveIndex: _state.activeIndex,
      };
      const tab = prop.tabs?.[_state.activeIndex];
      const title = getDatTitle({ prefixSys: false });
      const name = tab?.dict_config_title ?? tab?.title ?? `默认页签`;
      dataAnalyticTrack([`打开页签【${title}-${name}】`, `打开页签统计`, `【${title}-${name}】-页签打开次数`]);
    });
  }

  static propTypes = {
    filter: PropTypes.object,
    filterValue: PropTypes.object,
    tabs: PropTypes.object,
    tabGroups: PropTypes.array,
    activeIndex: PropTypes.string,
    handleChangeTabs: PropTypes.func,
    handleEvents: PropTypes.object,
    textAreaKey: PropTypes.string, // 一个聚合筛选时的key值
    isTextareaSingleLine: PropTypes.bool, // 个人设置 批量筛选是否单行
    circleTips: PropTypes.func,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { filter, activeIndex, filterValue, tabs } = nextProps;
    const newState = {};
    if (activeIndex !== this.props.activeIndex) {
      newState.activeIndex = activeIndex;
    }
    if (tabs !== this.props.tabs) {
      this.tabsKeys = Object.keys(tabs);
      newState.tabsKeys = this.tabsKeys;
    }
    if (nextProps.tabGroups && nextProps.tabGroups.length) {
      const activeIndexNew = newState.activeIndex || this.state.activeIndex;
      newState.tabsKeys = nextProps.tabGroups.find(item => this.isGroupActive(item, activeIndexNew)).tabs;
    }
    if (this.props.isTextareaSingleLine !== nextProps.isTextareaSingleLine && nextProps.isTextareaSingleLine) {
      // 多行变单行时 更新 textareaHeight
      newState.textareaHeight = 28;
    }

    // 解决某些场景值改变了 如Input 未派发更新问题
    // if (filter !== this.props.filter || filterValue !== this.props.filterValue) {
    this.setState({ filter: this.formatFilter(filter, filterValue, { ...this.state, ...newState }, nextProps) });
    // }

    if (!isEmptyObj(newState)) {
      datHelper(() => {
        const { lastActiveIndex } = this.datContext;
        if (lastActiveIndex !== newState.activeIndex && newState.activeIndex) {
          const tab = nextProps.tabs?.[newState.activeIndex];
          this.datContext.lastActiveIndex = newState.activeIndex;
          const title = getDatTitle({ prefixSys: false });
          const name = tab?.dict_config_title ?? tab?.title ?? `默认页签`;
          dataAnalyticTrack([`打开页签【${title}-${name}】`, `打开页签统计`, `【${title}-${name}】-页签打开次数`]);
        }
      });
      this.setState(newState);
    }
  }

  componentDidMount() {
    this.clickListen = EventListener.listen(window, 'click', () => {
      if (!this.isMouseIn && this.state.isTabsExpanded) {
        this.setState({ isTabsExpanded: false });
      }
    });
    this.vHeaderTabsContainer && onElementResize(this.vHeaderTabsContainer, this.setTabsLeft);
    this.bindFilterResize();
    setTimeout(() => {
      this.setTabsLeft();
      this.setDom();
    });
  }

  componentDidUpdate() {
    this.bindFilterResize(); // didmount是筛选还没有时，需要再确认绑定
    setTimeout(this.setDom);
  }

  componentWillUnmount() {
    this.clickListen.remove();
  }

  bindFilterResize = () => {
    if (!this.submitResizeBinded && this._cardForm && this.filterContainer) {
      this.submitResizeBinded = true;
      onElementResize(this.filterContainer.querySelector('.fn-filter__panel .fn-filter'), throttle(this.setDom, 50));
    }
  };
  setSubmitBtn = () => {
    const submitBtn =
      this.filterContainer && this.filterContainer.querySelector('.fn-filter__panel .fn-filter .submit');
    if (!submitBtn) return;
    const lastItem = submitBtn.parentNode.querySelector('.fn-card__form__card-item:nth-last-child(2)');
    const firstItem = submitBtn.parentNode.querySelector('.fn-card__form__card-item:first-child');
    const submitItem = submitBtn.querySelector('.fn-btn-icon'); // submitBtnRect = submitBtn.getBoundingClientRect(),
    const lastItemRect = lastItem.getBoundingClientRect();
    // window.innerWidth - lastItemRect.right > 66 最后一个筛选后边能放下
    // submitBtn.offsetTop - lastItem.offsetTop > 5 查询按钮和最后一个筛选不在一行
    // 数值为按钮宽度+ margin-left 10 + 1px
    if (!submitItem) return;
    submitItem.style.paddingLeft = 0;
    if (lastItem.offsetTop - firstItem.offsetTop < 5) {
      // 一行筛选
      submitItem.style.paddingLeft = 0;
    } else if (lastItem.offsetTop - firstItem.offsetTop > 5) {
      // 多行筛选
      if (submitBtn.offsetTop - lastItem.offsetTop > 5) {
        // 不在同一行
        // submitItem.style.paddingLeft = window.innerWidth - submitBtnRect.right > 70 ? '70px' : 0
        submitItem.style.paddingLeft = '70px';
      } else if (submitBtn.offsetTop - lastItem.offsetTop < 5) {
        // 在同一行
        submitItem.style.paddingLeft = window.innerWidth - lastItemRect.right < 221 ? 0 : '70px';
      }
    }
  };
  setDom = () => {
    this.setSubmitBtn();
    if (!this._cardForm || !this.filterContainer) return;
    if (!this.hasMinHeight && !this.props.isTextareaSingleLine) {
      // 筛选更新后，高度等重新调整
      const textarea = this.filterContainer.querySelector(
        '.fn-filter__panel .fn-filter .cardform_group_filter_first_column textarea',
      );
      if (textarea) {
        this.hasMinHeight = true;
      }
    }
    if (!this.props.isTextareaSingleLine) {
      const textarea = this.filterContainer.querySelector(
        '.fn-filter__panel .fn-filter .cardform_group_filter_first_column textarea',
      );
      if (textarea) {
        textarea.style.height = `${this._cardForm.body.offsetHeight - 20}px`;
        // 延时防止样式替换时产生高度闪动
        this.hasMinHeight &&
          setTimeout(() => {
            addClass(textarea.parentNode.parentNode.parentNode, 'textarea_fit_content');
          }, 350);
      }
    }
  };
  formatFilter = (filter = {}, ifilterValue, state = this.state, prop) => {
    const _filter = { ...filter };
    const filterValue = !isEmptyObj(ifilterValue) ? ifilterValue : {};
    const dateFilterRangeUnlimited =
      window.company_setting.date_filter_range_unlimited && window.company_setting.date_filter_range_unlimited.checked;
    let type;

    // 全平台下发 重置按钮
    let fixFilterCheckLength = 2; // 必须有一个查询条件和查询按钮，少于这个长度，即可判定为空

    // 配置了更多设置按钮
    if (filter.filterMore) fixFilterCheckLength++;
    // 配置了保存筛选按钮
    if (filter.saveQuery) fixFilterCheckLength++;
    // 配置了重置筛选按钮
    if (filter.resetQuery) fixFilterCheckLength++;

    this.isEmptyFilter = isEmptyObj(filter) || Object.keys(filter).length < fixFilterCheckLength;
    if (this.isEmptyFilter) return {};
    const _textareaKey = _filter.assemble_TextArea_value ? 'assemble_TextArea_value' : prop.textAreaKey;
    if (_textareaKey && _filter[_textareaKey]) {
      // 初次加载或还未渲染筛选时 不设置高度
      _filter[_textareaKey].otherProps.style = prop.isTextareaSingleLine
        ? { height: undefined, minHeight: 28 }
        : { height: '100%', minHeight: 42 };
    }
    // 修改于formDataToCardForm
    let data = fromJS(_filter);
    if (!isEmptyObj(_filter)) {
      // 使用data的key排除不在组件范围的值
      Object.keys(_filter).forEach(key => {
        type = data.getIn([key, 'type']);
        if (type) {
          const path = dataStrippingConfig[type] || dataStrippingConfig.default;
          if (filterValue[key] !== undefined && !state.specialVal[filterValue[key]]) {
            data = data.setIn([key, ...path], filterValue[key]);
          }
          if (data.getIn([key, 'otherProps', 'tips'])) {
            // 有tip的自动加上tipsclassname： search_filter_tip
            data = data.setIn([key, 'otherProps', 'tipsclassname'], 'search_filter_tip');
          }
          const labelStr = data.getIn([key, 'labelProps', 'children']);
          if (labelStr && Object.keys(lenMap).includes(`${labelStr.length}`)) {
            data = data.setIn([key, 'labelProps', 'style'], { ...lenMap[labelStr.length] });
          }
          if (
            type === 'RangeTimePicker' &&
            !dateFilterRangeUnlimited &&
            !data.getIn([key, 'otherProps', 'disableType'])
          ) {
            data = data.setIn([key, 'otherProps', 'disableType'], 940);
          }
        }
      });
    }
    return data.toJS();
  };
  setTabsLeft = () => {
    let { tabsLeft, isShowTabsTogBtn } = this.state;
    const { tabs } = this.props;
    const { vHeaderTabsContainer = {} } = this;
    if (!vHeaderTabsContainer) return; // 当vHeaderTabsContainer为null时，下一条语句会报错
    const tabsCWidth = vHeaderTabsContainer.offsetWidth;
    const clsTabsBtnFontSizeN = parseInt(clsTabsBtnFontSize, 10);
    const clsTabsBtnMinWidthN = parseInt(clsTabsBtnMinWidth, 10);
    const clsTabsToggleBtnWidthN = parseInt(clsTabsToggleBtnWidth, 10);
    this.firstLineWidth = tabsCWidth - clsTabsToggleBtnWidthN;
    let totalWidth = 0;
    let overflowIndex = 0;
    this.tabWidthArr = this.tabsKeys.map((key, index) => {
      const sWidth = tabs[key]?.title?.length;
      const strWidth = clsTabsBtnFontSizeN * sWidth + 40; // 28
      totalWidth += strWidth < clsTabsBtnMinWidthN ? clsTabsBtnMinWidthN : strWidth;
      // 不在范围内 调整left值
      if (totalWidth > this.firstLineWidth) {
        isShowTabsTogBtn = true;
        if (overflowIndex === 0) {
          overflowIndex = index;
        }
        if (this.activeIndexNum < overflowIndex) {
          tabsLeft = 0;
        }
        if (this.activeIndexNum === index) {
          tabsLeft = totalWidth - this.firstLineWidth;
        }
      }
      return totalWidth;
    });
    this.setState({ tabsLeft, isShowTabsTogBtn });
  };
  // 查询按钮 设置loading状态
  setSearching = () => {
    this._cardForm && this._cardForm.setComProps('submit', 'loading', true);
  };
  // 查询按钮 恢复查询状态
  resetSearching = () => {
    this._cardForm && this._cardForm.setComProps('submit', 'loading', false);
  };
  // 获取当前筛选条件 2017.3.3 jany
  getSearchFilter = () => {
    if (this._cardForm) {
      return this._cardForm.getStateDataForm() || {};
    }
    return {};
  };
  // 设置当前筛选条件 可以直接传全量filter 自动过滤非筛选项的值 2017.3.3 jany
  setSearchFilter = data => {
    if (this._cardForm) {
      return this._cardForm.setStateDataForm(data);
    }
    return false;
  };
  setSearchFilterValue = filterValue => {
    this.setState({ filter: this.formatFilter(this.props.filter, filterValue, this.state, this.props) });
  };
  handleChangeTabs = (key, index) => {
    const { activeIndex } = this.state;
    const { tabWidthArr } = this;
    const { firstLineWidth } = this;
    const setState = s => this.setState(s);
    const propsHandleChangeTabs = () => this.props.handleChangeTabs && this.props.handleChangeTabs(key);
    const handler = () => {
      if (activeIndex === key) return;
      const tabsLeft = tabWidthArr[index] > firstLineWidth ? tabWidthArr[index] - firstLineWidth + 5 : 0;
      const { tabGroups } = this.props;
      setState({ activeIndex: key, tabsLeft });
      propsHandleChangeTabs();
      if (tabGroups && tabGroups.length) {
        this.lastGroupActiveTab = this.lastGroupActiveTab || {};
        const groupIndex = tabGroups.findIndex(item => item.tabs.includes(key));
        this.lastGroupActiveTab[groupIndex] = key;
      }
    };
    return handler;
  };
  handleChangeTabGroup = index => {
    this.lastGroupActiveTab = this.lastGroupActiveTab || {};
    const key = this.lastGroupActiveTab[index] || this.props.tabGroups[index].tabs[0];
    const tabIndex = this.props.tabGroups[index].tabs.indexOf(this.lastGroupActiveTab[index]);
    this.lastGroupActiveTab[index] = key;
    this.handleChangeTabs(key, tabIndex)();
  };
  // 顶部tabs
  dropCaretClick = () => {
    this.setState({ isTabsExpanded: !this.state.isTabsExpanded });
  };
  dropCaretMouseOut = () => (this.isMouseIn = false);
  dropCaretMouseIn = () => (this.isMouseIn = true);
  cardFormRefGetter = r => (this._cardForm = r);

  isGroupActive = (item, activeIndex) => item.tabs.includes(activeIndex);

  render() {
    const { tabs = {}, textAreaKey, tabGroups } = this.props;
    const { filter, isShowDescribe, category, activeIndex } = this.state;
    const isShowFilter = !this.isEmptyFilter;
    const isShowTabs = this.tabsKeys.length > 1;
    const filterPanelCls = `${prefixCls}__panel`;
    const isNewTableStyle = getIsNewTableStyle();
    const hideFilterMoreBtnClass = !isNewTableStyle ? `${prefixCls}__hide-filter-more-btn` : '';
    // filter没有，tabs 少于两个则不显示
    if (isShowFilter) {
      // 有textareakey 无聚合 则只有唯一textarea
      const isTaAss = filter.assemble_TextArea_value !== undefined;
      if (isTaAss || textAreaKey) {
        if (isTaAss) {
          const exactKey = `${getComVal(filter.assemble_TextArea)}._exact_`;
          const exactValue = getComVal(filter[exactKey]); // props 里的filterValue 不一定更新了
          filter.assemble_TextArea.classname = `${filter.assemble_TextArea.classname}${
            exactValue ? ' exact_checked' : ''
          }`;
        }
      }
      // 表格说明 弹框
      if (isShowDescribe) {
        filter.filter_table_describe = {
          type: 'TableDescribe',
          eventNames: ['onClick'],
          otherProps: {
            category,
            tab: activeIndex,
          },
        };
      }
    }
    const { isShowTabsTogBtn, isTabsExpanded, tabsKeys } = this.state;
    return isShowFilter || isShowTabs ? (
      <div ref={r => (this.filterContainer = r)} className={filterPanelCls}>
        {isShowTabs ? (
          <Fragment>
            {tabGroups.length > 1 && (
              <div className="filter__tabs--w filter__tabs-level1">
                <div className="filter__tabs">
                  <ul className="clearfix" style={{ left: `-${this.state.tabsLeft}px` }}>
                    {tabGroups.map((item, index) => (
                      <li
                        key={index}
                        className={classnames({
                          current: this.isGroupActive(item, activeIndex),
                          hasLevel: tabsKeys.length > 1,
                        })}
                        onClick={() => this.handleChangeTabGroup(index)}
                      >
                        <a href="#">{item.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {tabsKeys.length > 1 && (
              <div className="filter__tabs--w filter__tabs-level2" ref={r => (this.vHeaderTabsContainer = r)}>
                <div className="filter__tabs">
                  <ul className="clearfix" style={{ left: `-${this.state.tabsLeft}px` }}>
                    {tabsKeys.map((key, index) => {
                      if (key === activeIndex) this.activeIndexNum = index;
                      return (
                        <li
                          key={key}
                          className={classnames({ current: key === activeIndex })}
                          onClick={this.handleChangeTabs(key, index)}
                        >
                          <a href="#">{tabs[key].title}</a>
                        </li>
                      );
                    })}
                  </ul>
                  {this.props.circleTips && this.props.circleTips()}
                </div>
                {isShowTabsTogBtn ? (
                  <div
                    className={`drop-caret ${isShowTabsTogBtn ? '' : 'hide'}`}
                    onClick={this.dropCaretClick}
                    onMouseLeave={this.dropCaretMouseOut}
                    onMouseEnter={this.dropCaretMouseIn}
                  >
                    <i className="fn-icon fn-icon-arrow-down" />
                  </div>
                ) : null}
                {isShowTabsTogBtn && isTabsExpanded ? (
                  <div className={`drop_quickmenu ${isTabsExpanded ? '' : 'hide'}`}>
                    <ul>
                      {this.tabsKeys.map((key, index) => (
                        <li
                          key={key}
                          className={classnames({ check: key === activeIndex })}
                          onClick={this.handleChangeTabs(key, index)}
                        >
                          <i className="fn-icon fn-icon-scheck" />
                          <span>{tabs[key].title}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            )}
          </Fragment>
        ) : null}
        {isShowFilter ? (
          <CardForm
            isHeader={false}
            handleEvents={this.props.handleEvents}
            ref={this.cardFormRefGetter}
            data={filter}
            classname={`${prefixCls} ${hideFilterMoreBtnClass}`}
          />
        ) : null}
      </div>
    ) : null;
  }
}
