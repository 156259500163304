/* eslint-disable */
/**
 * Created by JANY on 2016/12/2.
 */
// import React, { Component, PropTypes } from 'react'
// 实例化信息窗体
function MapInfoWin(title, content, offset = [0, -25]) {
  const info = [];
  info.push('<div><b>' + title + '</b></div>');
  info.push(content);
  return new AMap.InfoWindow({
    offset: new AMap.Pixel(...offset),
    content: info.join('<br/>'),
  });
}

export default MapInfoWin;
