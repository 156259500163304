exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".switch_set .switch_block .switch_set_open{color:#0103ff}", ""]);

// exports
exports.locals = {
	"switch_set": "switch_set",
	"switch_block": "switch_block",
	"switch_set_open": "switch_set_open"
};