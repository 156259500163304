exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-textarea{border:1px solid rgba(34,36,38,.2);font-size:12px;background:#fff;padding:2px 5px;transition:all 0s ease-in;border-radius:2px;width:140px;height:60px;box-sizing:border-box;resize:auto}.fn-textarea:-ms-input-placeholder{color:#ccc!important}.fn-textarea:focus{background:#e2f7ff}.fn-textarea:focus,.fn-textarea:hover{border:1px solid #0097cf}.fn-textarea--error,.fn-textarea--error:focus{background:rgba(255,0,0,.05);border:1px solid #ff4040}.fn-textarea--disabled{background-color:#f2f9ff;border-color:#c1c1c1;cursor:no-drop}.fn-textarea--disabled:focus{background-color:#f3f3f3;border-color:#c1c1c1}.fn-textarea--active{border:1px solid rgba(45,183,245,.5);box-shadow:0 0 2px 1px rgba(45,183,245,.5)}.fn-textarea__text{background-color:#fff;border:1px solid #fff;cursor:default}.fn-textarea_wrap{font-size:0}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-textarea",
	"fn-textarea": "fn-textarea",
	"fn-textarea--error": "fn-textarea--error",
	"fn-textarea--disabled": "fn-textarea--disabled",
	"fn-textarea--active": "fn-textarea--active",
	"fn-textarea__text": "fn-textarea__text",
	"fn-textarea_wrap": "fn-textarea_wrap"
};