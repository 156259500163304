exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-new-typeset-dialog{padding:16px 24px;box-shadow:0 2px 8px rgba(0,0,0,.12);border-radius:5px;border:none}.fn-new-typeset-dialog .fn-dialog__title{background-color:#fff;box-shadow:none;height:36px;line-height:36px;padding:0;font-size:16px;margin-bottom:16px}.fn-new-typeset-dialog .fn-dialog__center{padding:0}.fn-new-typeset-dialog .fn-dialog__bottom{background-color:#fff;box-shadow:none}.fn-new-typeset-dialog .fn-btn--primary{background-color:#532fe6;background-image:none;border-radius:3px}.fn-new-typeset-dialog .fn-btn--default{color:#1d2129!important}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-new-typeset-dialog",
	"fn-new-typeset-dialog": "fn-new-typeset-dialog",
	"fn-dialog__title": "fn-dialog__title",
	"fn-dialog__center": "fn-dialog__center",
	"fn-dialog__bottom": "fn-dialog__bottom",
	"fn-btn--primary": "fn-btn--primary",
	"fn-btn--default": "fn-btn--default"
};