import React from 'react';
import { Gallery, Modal } from '@gui/web-react';
import { prefixCls } from './modal.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { defaultProps, getGalleryConfig } from './utils';
import EnhanceGallery from './enhanceGallery';

const getModalConfig = props => {
  const { modalProps, onClose } = props;
  const localModalProp = _.assign(defaultProps.modalProps, modalProps);

  const { onCancel, onOk } = localModalProp;

  const handleClose = () => {
    onClose && onClose();
  };

  const handleOk = (...param) => {
    const okPromise = onOk && onOk(...param);
    if (okPromise && okPromise.then) okPromise.then(handleClose);
  };

  const handleCancel = (...param) => {
    onCancel && onCancel(...param);
    handleClose();
  };

  return {
    ...localModalProp,
    onOk: handleOk,
    onCancel: handleCancel,
  };
};

const ModalView = props => {
  const { images, defaultIndex, visible } = props; // visible 提升
  const localModalProp = getModalConfig(props);
  const localGalleryProp = getGalleryConfig(props);

  return (
    <Modal {...localModalProp} className={classnames(prefixCls, localModalProp.className)} visible={visible}>
      <Gallery {...localGalleryProp} items={images} startIndex={defaultIndex} />
    </Modal>
  );
};

/**
 * 直接使用Modal方法打开，此时visible是非受控的
 */
export const showModalView = props => {
  const { onClose, onDeleteSucceed, renderTopSlot } = props;

  let isDeleteSucceed = false; // 是否成功删除图片
  const modalColse = function () {
    onClose?.();
    // 关闭了弹窗时，如有删除图片后回调
    if (isDeleteSucceed) {
      onDeleteSucceed?.();
    }
  };

  // 点击删除按钮，删除成功后打标
  const deleteSucceed = function () {
    isDeleteSucceed = true;
  };

  // 重写 onClose 方法，并在modalColse 执行外部传入的 onClose
  const localModalProp = getModalConfig({ ...props, onClose: modalColse });

  const content = (
    <>
      {renderTopSlot && renderTopSlot()}
      <EnhanceGallery {...props} deleteSucceed={() => deleteSucceed()} />
    </>
  );

  return Modal.info({
    ...localModalProp,
    className: classnames(prefixCls, localModalProp.className),
    content,
  });
};
// TODO: useViewModal
export const useViewModal = showModalView;

ModalView.show = showModalView;

export default ModalView;
