exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".task .empty-comp .fn-input-pure{color:#fff}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-data-list",
	"task": "task",
	"empty-comp": "empty-comp",
	"fn-input-pure": "fn-input-pure"
};