exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-time-peroid{display:inline-flex;align-items:center;border:1px solid rgba(34,36,38,.2);width:140px}.fn-time-peroid:focus{background:#e2f7ff}.fn-time-peroid:focus,.fn-time-peroid:hover{border:1px solid #0097cf}.fn-time-peroid[disabled]{color:#333;background-color:#f2f9ff;border-color:#c1c1c1;cursor:no-drop}.fn-time-peroid .ant-time-picker{width:calc(50% - 4px)}.fn-time-peroid .ant-time-picker-input{border:none;height:22px;text-align:center;padding:0;font-size:12px}.fn-time-peroid .ant-time-picker-icon{display:none}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-time-peroid",
	"fn-time-peroid": "fn-time-peroid",
	"ant-time-picker": "ant-time-picker",
	"ant-time-picker-input": "ant-time-picker-input",
	"ant-time-picker-icon": "ant-time-picker-icon"
};