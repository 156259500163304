/**
 * Created by JANY on 2016/12/12.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Icon } from 'components';
import { per2num, typeIs } from 'utils';
import { DivDrager } from './../Dragers';
import { prefixCls } from './index.scss';

const defaultDragWidth = 8;
export default class AdjustableDiv extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      leftWidth: this.props.additionStyle.leftWidth,
      rightWidth: this.props.additionStyle.rightWidth,
      dragWidth: this.props.additionStyle.dragWidth,
      onCloseLeftWidth: this.props.additionStyle.leftWidth,
      containerHeight: '100%',
      // 不能拖动
      disableDrag: false,
    };
    this.containerWidth = document.body.clientWidth - 178;
  }

  static propTypes = {
    classname: PropTypes.string,
    // 预定义宽度
    additionStyle: PropTypes.shape({
      leftWidth: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
      rightWidth: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
      dragWidth: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
      display: PropTypes.string,
    }),
    // 左、右模块  拖拽区域（有默认div块）
    left: PropTypes.node,
    drager: PropTypes.element,
    right: PropTypes.node,
    toggleLeft: PropTypes.bool,
    changeCallback: PropTypes.func,
    // 当前页面的pageKey, 用来判断是否隐藏
    maxLeftWidth: PropTypes.number,
    minLeftWidth: PropTypes.number,
    // onLeftExtend: PropTypes.func,
  };
  static defaultProps = {
    additionStyle: {
      leftWidth: '10%',
      rightWidth: '90%',
      dragWidth: '8px', // 需带上单位 兼容百分比
    },
    classname: `${prefixCls}`,
    drager: <DivDrager style={{ width: '8px' }} />,
    toggleLeft: false,
  };
  dragData = {
    startX: 0,
    isMouseDown: false,
    containerWidth: 800,
  };
  resetData = {
    leftWidth: '10%',
    rightWidth: '90%',
    dragWidth: '20px', // 需带上单位 兼容百分比
    display: 'block',
  };

  componentDidMount() {
    // tab隐藏的情况， 会导致左屏宽度为0
    if (this.dragerContainer.offsetWidth !== 0) {
      this.containerWidth = this.dragerContainer.offsetWidth || 0;
    }
    this.applyStyle(this.parseWidth(undefined, this.state));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.additionStyle !== this.props.additionStyle) {
      this.applyStyle(this.parseWidth(undefined, nextProps.additionStyle));
    }
  }

  disableDrag = state => {
    this.setState({ disableDrag: state });
  };
  onDragStyle = (styles, isDrag = false) => {
    this.applyStyle(styles, isDrag);
  };
  changeWidthCallback = (stateStyle, isDrag = false) => {
    if (!this.dragerContainer) return;
    // let leftWidth = stateStyle.leftWidth
    // let rightWidth = stateStyle.rightWidth
    // leftWidth = leftWidth.toString().indexOf('%') !== -1 ? per2num(leftWidth) * this.containerWidth : leftWidth
    // rightWidth = rightWidth.toString().indexOf('%') !== -1 ? per2num(rightWidth) * this.containerWidth : rightWidth
    const { leftWidth, rightWidth } = stateStyle;
    this.props.changeCallback({ leftWidth, rightWidth }, isDrag);
  };
  applyStyle = (stateStyle, isDrag = false) => {
    this.setState(stateStyle);
    if (typeof this.props.changeCallback === 'function') {
      this.changeWidthCallback(stateStyle, isDrag);
    }
  };
  formatWidth = (width, defaultWidth = 0) => {
    const rwidth =
      typeIs(width, 'number') || !width
        ? width
        : Number(`${width.indexOf('%') !== -1 ? per2num(width) * this.containerWidth : width}`.replace('px', ''));
    return Number.isNaN(rwidth) ? defaultWidth : rwidth;
  };
  parseWidth = (currX, widths) => {
    let { leftWidth, rightWidth, dragWidth } = widths;
    leftWidth = this.formatWidth(leftWidth);
    // 左屏最大宽度
    if (this.props.maxLeftWidth && +leftWidth > +this.props.maxLeftWidth) {
      leftWidth = this.props.maxLeftWidth;
    }
    if (this.props.minLeftWidth && +leftWidth < +this.props.minLeftWidth) {
      leftWidth = this.props.minLeftWidth;
    }
    rightWidth = this.formatWidth(rightWidth);
    dragWidth = this.formatWidth(dragWidth, defaultDragWidth);
    if (currX !== undefined) {
      const X = currX || 0;
      const oX = this.dragData.startX;
      const diff = X - oX;
      if ((diff >= 0 && rightWidth - dragWidth - diff >= 0) || (diff <= 0 && leftWidth + diff >= 0)) {
        leftWidth += diff;
        rightWidth -= diff;
      }
      this.dragData.startX = X;
    }
    return { leftWidth, rightWidth, dragWidth };
  };
  startDrag = e => {
    const event = e || window.event;
    // event.preventDefault()
    this.dragData.startX = event.clientX || 0;
    this.dragData.isMouseDown = true;
  };
  onDrag = e => {
    if (this.state.disableDrag) return false;
    if (!this.dragData.isMouseDown) return false;
    const event = e || window.event;
    const currX = event.clientX;
    const { leftWidth, rightWidth } = this.parseWidth(currX, this.state);
    this.onDragStyle({ leftWidth, rightWidth }, true);
  };
  finishDrag = e => {
    const event = e || window.event;
    // event.preventDefault()
    this.dragData.startX = event.clientX;
    this.dragData.isMouseDown = false;
  };
  // 恢复左边
  resumeLeft = () => {
    // this.props.onLeftExtend()
    const styles = { leftWidth: this.state.onCloseLeftWidth, rightWidth: this.state.onCloseRightWidth };
    this.applyStyle(styles);
  };
  // 关闭左边
  closeLeft = () => {
    this.setState({
      onCloseLeftWidth: this.state.leftWidth,
      onCloseRightWidth: this.state.rightWidth,
    });
    // let styles = { leftWidth: 0, rightWidth: '100%' }
    const styles = { leftWidth: 0, rightWidth: this.containerWidth };
    this.applyStyle(styles);
  };
  // 关闭右边
  closeRight = () => {
    this.setState({
      onCloseLeftWidth: this.state.leftWidth,
      onCloseRightWidth: this.state.rightWidth,
    });
    // let styles = { leftWidth: '100%', rightWidth: 0 }
    const styles = { leftWidth: this.containerWidth, rightWidth: 0 };
    this.applyStyle(styles);
  };

  render() {
    const { left, drager, right, classname } = this.props;
    const { leftWidth, rightWidth, dragWidth } = this.state;
    const divDisplay = this.props.additionStyle.display ? this.props.additionStyle.display : true;
    const isLeftShow = `${leftWidth}`.replace(/(px|%)/, '') !== '0';
    const isRightShow = `${rightWidth}`.replace(/(px|%)/, '') !== '0';
    const showLeftExtender = this.props.toggleLeft && !isLeftShow;
    const relLeftWidth = leftWidth;
    return (
      <div
        ref={div => (this.dragerContainer = div)}
        className={classnames(classname)}
        style={{ height: this.state.containerHeight, display: divDisplay }}
        onMouseUp={this.finishDrag}
        onMouseMove={this.onDrag}
      >
        <div
          style={{
            width: relLeftWidth,
            minWidth: relLeftWidth,
            opacity: isLeftShow ? '1' : '0',
            border: isLeftShow ? undefined : 'none',
          }}
          className="left-div"
        >
          {left}
        </div>
        <div style={{ width: rightWidth }} className="right-div">
          {React.cloneElement(drager, {
            style: {
              display: isLeftShow && isRightShow ? 'block' : 'none',
              width: dragWidth,
            },
            onMouseDown: this.startDrag,
          })}
          <div className="drag-extend" style={{ display: showLeftExtender ? 'block' : 'none' }}>
            <span>
              <Icon iconType="icon-extend-right" onClick={this.resumeLeft} />
            </span>
          </div>
          <div style={{ paddingLeft: !isLeftShow ? 0 : dragWidth }} className="right-content">
            {right}
          </div>
        </div>
      </div>
    );
  }
}
