import ReactDOM from 'react-dom';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { prefixCls } from './popover.scss';

let popover;

const cloneWithoutStyle = target => {
  const ele = target.cloneNode(true);
  ele.removeChild(ele.firstChild);
  ele.style.position = 'absolute';
  ele.firstChild.style.maxHeight = '';
  ele.style.left = '';
  ele.style.right = '';
  ele.style.top = '';
  ele.style.bottom = '';
  ele.style.width = 'auto';
  ele.style.height = 'auto';
  return ele;
};
const div = document.createElement('div');
const arrow = document.createElement('div');
const container = document.createElement('div');
class Content extends PureComponent {
  state = {};

  preContainerSize = {};

  componentDidUpdate = () => this.setPosition();

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  setPosition = () => {
    if (!this.state.content) return;
    const containerClone = document.body.appendChild(cloneWithoutStyle(div));
    const { top, left, width, height } = this.state.targetRect;
    const wrap = document.documentElement;
    const { scrollTop } = wrap;
    const { scrollLeft } = wrap;
    const containerSize = { width: containerClone.offsetWidth + 2, height: containerClone.offsetHeight + 2 };
    const { preContainerSize } = this;

    if (preContainerSize.height !== containerSize.height) container.style.maxHeight = '';

    this.preContainerSize = containerSize;
    document.body.removeChild(containerClone);

    const arrowRectWidth = arrow.offsetWidth;
    const centerArrow = `-${arrowRectWidth / 2}px`;
    const arrowHeight = Math.sqrt(Math.pow(arrowRectWidth, 2) / 2);
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const { leftMargin = 5, rightMargin = 5, topMargin = 5, bottomMargin = 5 } = this.state;
    const leftSize = left - scrollLeft - arrowHeight - leftMargin;
    const rightSize = windowWidth - leftSize - arrowHeight * 2 - rightMargin;
    const upSize = top - scrollTop - arrowHeight - topMargin;
    const downSize = windowHeight - upSize - arrowHeight * 2 - bottomMargin;
    const hCenter = left + Math.ceil(width / 2) - scrollLeft;
    const leftPosition = hCenter - containerSize.width / 2;
    const rightPosition = windowWidth - leftPosition - containerSize.width;
    const vCenter = top + Math.ceil(height / 2) - scrollTop;
    const topPosition = vCenter - containerSize.height / 2;
    const bottomPosition = windowHeight - topPosition - containerSize.height;
    let { dir } = this.state;
    if (!dir) {
      if (upSize >= containerSize.height) {
        dir = 'up';
      } else if (downSize >= containerSize.height) {
        dir = 'down';
      } else if (leftSize >= containerSize.width) {
        dir = 'left';
      } else if (rightSize >= containerSize.width) {
        dir = 'right';
      } else if (downSize > upSize) {
        dir = 'down';
      }
      // if ((dir === 'up' || dir === 'down') && topPosition > 0 && bottomPosition > 0) {
      //   if (leftPosition < leftMargin && rightSize >= containerSize.width) {
      //     dir = 'right'
      //   } else if (rightPosition < rightMargin && leftSize >= containerSize.width) {
      //     dir = 'left'
      //   }
      // }
    }

    Object.assign(arrow.style, { left: '', top: '', right: '', bottom: '' });
    if (dir === 'left' || dir === 'right') {
      arrow.style.transform = dir === 'right' ? 'rotate(-135deg)' : 'rotate(45deg)';
      arrow.style.left = dir === 'right' ? centerArrow : '';
      arrow.style.right = dir === 'left' ? centerArrow : '';
      container.style.maxHeight = `${windowHeight - topMargin - rightMargin}px`;
      div.style.left = dir === 'right' ? `${left - scrollLeft + arrowHeight + width}px` : '';
      div.style.right = dir === 'left' ? `${windowWidth - left + arrowHeight + scrollLeft}px` : '';
      if (topPosition >= topMargin && bottomPosition >= bottomMargin) {
        div.style.top = `${topPosition}px`;
        div.style.bottom = '';
        arrow.style.top = `${vCenter - topPosition - arrowHeight}px`;
      } else if (topPosition < topMargin) {
        div.style.top = `${topMargin}px`;
        div.style.bottom = '';
        arrow.style.top = `${Math.max(vCenter - topMargin - arrowHeight, 5)}px`;
      } else {
        div.style.top = '';
        div.style.bottom = `${bottomMargin}px`;
        arrow.style.bottom = `${Math.max(windowHeight - vCenter - bottomMargin - arrowHeight, 5)}px`;
      }
    }
    if (dir === 'up' || dir === 'down') {
      arrow.style.transform = dir === 'down' ? 'rotate(-45deg)' : 'rotate(135deg)';
      arrow.style.top = dir === 'down' ? centerArrow : '';
      arrow.style.bottom = dir === 'up' ? centerArrow : '';
      container.style.maxHeight =
        dir === 'down'
          ? `${windowHeight - top - arrowHeight - height - bottomMargin + scrollTop}px`
          : `${top - scrollTop - arrowHeight - topMargin}px`;
      container.style.maxWidth = `${windowWidth - leftMargin - rightMargin}px`;
      div.style.top = dir === 'down' ? `${top - scrollTop + arrowHeight + height}px` : '';
      div.style.bottom = dir === 'up' ? `${windowHeight - top + arrowHeight + scrollTop}px` : '';
      if (leftPosition >= leftMargin && rightPosition >= rightMargin) {
        div.style.left = `${leftPosition}px`;
        div.style.right = '';
        arrow.style.left = `${hCenter - leftPosition - arrowHeight}px`;
      } else if (leftPosition < leftMargin) {
        div.style.left = `${leftMargin}px`;
        div.style.right = '';
        arrow.style.left = `${Math.max(hCenter - leftMargin - arrowHeight, 5)}px`;
      } else {
        div.style.left = '';
        div.style.right = `${rightMargin}px`;
        arrow.style.right = `${Math.max(windowWidth - hCenter - rightMargin - arrowHeight, 5)}px`;
      }
    }
  };

  show = ({
    targetRect,
    content,
    topMargin,
    bottomMargin,
    leftMargin,
    rightMargin,
    dir,
    onClose,
    onMouseEnter,
    onMouseLeave,
  }) => {
    this.setState(
      {
        targetRect,
        content,
        topMargin,
        bottomMargin,
        leftMargin,
        rightMargin,
        dir,
        onClose,
        hasError: false,
        onMouseEnter,
        onMouseLeave,
      },
      () => {
        // 针对当此的 弹窗层 添加事件
        div.addEventListener('mouseenter', this.state.onMouseEnter);
        div.addEventListener('mouseleave', this.state.onMouseLeave);
      },
    );
  };

  hide = () => {
    div.removeEventListener('mouseenter', this.state.onMouseEnter);
    div.removeEventListener('mouseleave', this.state.onMouseLeave);
    this.onClose();
    this.setState({ content: null, onClose: null });
  };

  onClose = () => this.state.onClose && this.state.onClose();

  render() {
    return this.state.hasError ? <span /> : this.state.content || null;
  }
}

arrow.className = `${prefixCls}-arrow`;
container.className = `${prefixCls}-content`;
div.className = prefixCls;
div.appendChild(arrow);
div.appendChild(container);
div.style.display = 'none';
document.body.appendChild(div);
ReactDOM.render(<Content ref={r => (popover = r)} />, container);

export default {
  tipElement: div,
  hide() {
    popover && popover.hide();
    div.style.display = 'none';
  },
  update: () => popover && popover.setPosition(),
  show({
    className,
    style,
    type,
    background = '',
    border = '',
    color = '',
    targetRect,
    content,
    topMargin,
    bottomMargin,
    leftMargin,
    rightMargin,
    dir,
    onClose,
    ...rest
  }) {
    div.style = '';
    Object.assign(div.style, style, { background, borderColor: border });
    Object.assign(arrow.style, { background, borderColor: border });
    Object.assign(container.style, { color });
    div.className = classnames(prefixCls, className, { [`${prefixCls}--${type}`]: type });
    div.style.display = 'block';
    popover && popover.onClose();
    popover &&
      popover.show({ targetRect, content, topMargin, bottomMargin, leftMargin, rightMargin, dir, onClose, ...rest });
    clearTimeout(window.popoverTimer); // 为了清除Tooltip里的延时处理
  },
};
