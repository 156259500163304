/**
 * Created by JANY on 2017/3/1.
 */
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
// import { deleteProps } from 'utils'
import { Sign } from 'components';
import { LIST_ARRAY2TEXT_SEPARATE } from 'constants';
import TableCell from './TableCell';

const cellContentStyle = {
  display: 'flex',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '2px 4px',
  height: '22px',
};
export default class IconCell extends TableCell {
  getIcons = (icons, width) => {
    const saveWidth = width - 4; // paddingLeft 4px
    const content = [];
    let num = Math.floor(saveWidth / 24); // 可以安全放下icon 的个数
    const remainder = saveWidth % 24; // 余数
    if (num < icons.length) {
      if (remainder < 10) num--; // 如果 余数 放不下 。。。 icon -1
      content[num] = <span key="...">...</span>;
    }
    icons.forEach((val, index) => {
      if (index < num) {
        content[index] = <Sign alt={val} size="small" key={val} />;
      }
    });
    return content;
  };

  render() {
    const { cellProps, cellOtherProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data } = cellOtherProps;
    const { rowIndex, columnKey, width } = cellProps;
    // 包含在enumerations 里的字段会执行格式化 否则 只取字段原值 // formatDeppProptotype
    const defaultValue = data.getShowObjectAt(rowIndex)[columnKey];
    const icons = `${defaultValue}`.split(LIST_ARRAY2TEXT_SEPARATE);
    Reflect.deleteProperty(cellContentStyle, 'textOverflow'); // 干掉 textOverflow
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <div style={{ ...cellContentStyle, width, padding: '1px 0 0 4px' }} className="tableCellorderState">
          {this.getIcons(icons, width)}
        </div>
      </Cell>
    );
  }
}
