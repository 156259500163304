import { MapShow } from 'components/utilcomponents/mapAddon/Amap';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// @gui/web-react这个版本的Message有bug，应该是 className 的前缀 arco 替换 gui 没完全
// 此处暂用 showInfo 代替
import { AutoComplete, Button, Dropdown, Form, Input, Menu, Modal, Spin, Tabs, Tag, Tooltip } from '@gui/web-react';
import { IconClose, IconPlusCircle } from '@gui/web-react/icon';
import { CHECK, ERROR } from 'constants';
import { POI_API } from 'constants/poiApi';
import qs from 'qs';
import { showInfo } from 'utils';
import { fetchNewVega } from 'utils/fetchApi';
import './box.scss';
import {
  cyt2poiStyle,
  formatAddr,
  getIconBySource,
  getLocFromStr,
  noop,
  showAddr,
  PolygonStyle,
  CircleStyle,
  MARKER_TYPE,
  CIRCLE_TYPE,
  POLYGON_TYPE,
  getOffsetPaths,
} from './utils';

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

export default class MapBox extends Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    config: PropTypes.object,
  };

  constructor(prop) {
    super(prop);

    const options = this.props.config;
    const currentPOI = options.currentPOI ?? null;

    this.state = {
      loading: true,
      sugLoading: false,
      searchLoading: false,
      isResultPanelVisible: false, // 搜索结果面板是否显示
      isDetailPanelVisible: false, // poi 详情面板是否显示
      sugList: [],
      hasSearchResult: false, // 存在搜索结果
      originPOI: currentPOI, // 存储原始 poi 地址信息
      currentPOI, // 当前选择的 poi 地址对象
      searchKeyword: '',
      searchKeywordLast: '', // 发起搜索时记录下来，搜索结果页翻页时使用
      gaodeResultList: [], // 高德 poi 搜索结果
      storeResultList: [], // 地址库搜索结果
      aiResultList: [], // 智能推荐搜索结果
      tempFavoriteList: [],
      currentFavoritePOI: null,
      isFavoriteVisible: false,
      gaodeSearchParams: {
        pageSize: 10,
        pageNum: 1,
        totalCount: 0, // 接口返回的 totalCount 不可靠，逻辑中不使用此字段
      },
      storeSearchParams: {
        pageSize: 10,
        pageNum: 1,
        totalCount: 0,
      },
      aiSearchParams: {
        pageSize: 10,
        pageNum: 1,
        totalCount: 0,
      },
      gaodeSearchLoading: false,
      storeSearchLoading: false,
      aiSearchLoading: false,
      currentTabKey: 'gaode',
      tooltip: {
        visible: false,
        content: '',
      },
      oid: currentPOI?.oid,
      aliasName: currentPOI?.aliasName ?? '', // 别名
      aliasNameNew: currentPOI?.aliasName ?? '', // 新别名
      radius: currentPOI?.radius ?? 0, // 半径
      graphType: MARKER_TYPE, // 默认点类型
    };

    this.config = {
      appName: options.appName,
      groupId: options.groupId,
      companyId: options.companyId,
      isCreate: Boolean(options.isCreate),
      isModify: Boolean(options.isModify),
      poi: options.defaultValue ? cyt2poiStyle(options.defaultValue) : null, // 地址选择时的已选地址(会展示到搜索框)
    };
    // 本页面临时添加到收藏的 oid 列表，每收藏一个就追加一个 oid
    // 接口不提供是否收藏的字段
    this._map = null; // 地图实例
    this._markers = []; // 所有 marker 实例
    this._gaodeMarkers = [];
    this._storeMarkers = [];
    this._aiMarkers = [];
    this._currentSelectedMarker = null;
    this._clickedMarker = null; // 点选地图产生的 marker

    this._mouseTool = null; // 鼠标工具插件
    this._mouseToolEvents = [];
    this._editor = null; // 绘制圆和多边形的编辑器
    this._overlayEditing = null; // 当前编辑的点、圆和多边形
    this._form = null;
  }

  componentDidMount() {
    const { currentPOI } = this.state;
    const { poi } = this.config;
    if (poi?.location && poi?.name) {
      this.set(
        {
          searchKeyword: poi.name,
          currentPOI: {
            name: poi.name,
            location: poi.location,
          },
        },
        () => {
          this.mapTryToLocation();
          this.handleSearch();
        },
      );
    }

    if (currentPOI) {
      this.showPOIDetail(currentPOI);
      this.editPOIOverlay(currentPOI);
    }
  }

  set = (state, cb = noop) => {
    this.setState(state, cb);
  };

  // 1. 地图就绪
  // 2. 当前poi数据被设置
  // 同时满足时设置地图中心点
  mapTryToLocation = () => {
    const { currentPOI } = this.state;
    if (this._map && currentPOI) {
      const loc = getLocFromStr(currentPOI.location);
      this._map.setCenter([loc[0], loc[1]]);
    }
  };

  handleMapLoaded = mapInstance => {
    const { isCreate, isModify } = this.config;

    this._map = mapInstance;
    this.set({ loading: false });
    this.mapTryToLocation();

    window.AMap.plugin(['AMap.Geocoder', 'AMap.MouseTool', 'AMap.CircleEditor', 'AMap.PolyEditor'], () => {
      this._mouseTool = new window.AMap.MouseTool(this._map);
      this._geoCoder = new window.AMap.Geocoder();

      if (!isCreate && !isModify) {
        // 选择地址时,默认切换成绘制点模式
        this.drawMarker();
      }
    });
  };

  markerGetAddress = lnglat => {
    this._geoCoder.getAddress(lnglat, (status, res) => {
      if (status === 'complete' && res.info === 'OK' && res.regeocode) {
        this.makePOIDataToShowDetail(lnglat, res.regeocode);
      }
    });
  };

  makePOIDataToShowDetail = (lnglat, regeocode) => {
    if (regeocode.addressComponent) {
      const data = regeocode.addressComponent;
      const location = `${lnglat.getLng()},${lnglat.getLat()}`;
      const name = data.township;
      const address = regeocode.formattedAddress;
      const adcode = data.adcode;
      const province = data.province;
      const city = data.city;
      const district = data.district;
      const street = data.street;
      this.showPOIDetail({
        name,
        address,
        aliasName: '',
        adcode,
        province,
        city,
        district,
        street,
        location,
        source: '2',
      });
    }
  };

  handleMarkerClick = marker => {
    this.set({ graphType: 0 });
    this.highlightMarker(marker);
    this.showPOIDetail(marker.getExtData());
  };
  highlightMarker = marker => {
    this.cancelHighlightMarker();
    const poi = marker.getExtData();
    this._currentSelectedMarker = marker;
    // 确保 marker label 位置正确
    setTimeout(() => {
      marker.setLabel({
        content: showAddr(poi),
        direction: 'top',
      });
    }, 200);
  };
  // 清理当前高亮的marker、点选的marker
  cancelHighlightMarker = () => {
    if (this._currentSelectedMarker) {
      this._currentSelectedMarker.setLabel({ content: '' });
      this._currentSelectedMarker = null;
    }
    this.clearClickMarker();
  };

  // 清理地图点选的marker
  clearClickMarker = () => {
    if (this._clickedMarker) {
      this._clickedMarker.setMap(null);
      this._clickedMarker = null;
    }
  };

  // 点击列表中的poi，尝试高亮对应的marker并定位地图
  tryToHighlightMarker = poi => {
    const marker = this._markers.find(item => {
      const extData = item.getExtData();
      return poi.oid === extData.oid;
    });
    this._map.setFitView(this._markers);
    if (marker) {
      const loc = getLocFromStr(marker.getExtData().location);
      this._map.setCenter(loc);
      this.highlightMarker(marker);
    }
  };

  // 清理指定类型的marker集合
  clearMarkers = markerListKey => {
    this[markerListKey].forEach(marker => marker.setMap(null));
    this[markerListKey] = [];
  };

  // 清理绘制的点,圆,多边形
  clearDrawedOverlay = () => {
    const events = this._mouseToolEvents;

    events.forEach(({ eventName, handler }) => {
      this._mouseTool.off(eventName, handler);
    });

    this._mouseTool?.close(true);
    this._editor?.close?.();
    this._editor = null;
    this._overlayEditing?.marker?.setMap(null);
    this._overlayEditing?.setMap(null);
    this._overlayEditing = null;
    this.set({
      tooltip: { visible: false, content: '' },
    });
  };

  drawMarkers = (poiList, markerListKey) => {
    if (this[markerListKey].length) {
      this.clearMarkers(markerListKey);
    }
    const markers = poiList.map(poi => {
      const loc = getLocFromStr(poi.location);
      const icon = new window.AMap.Icon({
        size: [25, 32],
        imageSize: [25, 32],
        image: getIconBySource(poi.source),
      });
      const marker = new window.AMap.Marker({
        map: this._map,
        position: loc,
        icon,
        extData: poi,
        offset: new window.AMap.Pixel(-12.5, -29),
      });

      return marker;
    });

    this[markerListKey] = markers;
    this._markers = [...this._markers, ...this[markerListKey]];
  };

  // 绘制点
  drawMarker = () => {
    this.set({
      tooltip: { visible: true, content: '请在地图上通过鼠标绘制点' },
    });

    let marker;
    const map = this._map;

    map.setDefaultCursor('pointer');

    // 监听绘制完成事件
    const onDraw = e => {
      marker?.setMap(null);
      marker = e.obj;
      marker.on('dragend', () => {
        this.markerGetAddress(marker.getPosition());
      });

      this.set({
        radius: 0,
        tooltip: { visible: false, content: '' },
      });
      this.markerGetAddress(marker.getPosition());
      this._overlayEditing = marker;
    };

    this._mouseTool.marker({ draggable: true });
    this._mouseTool.on('draw', onDraw);
    this._mouseToolEvents.push({ eventName: 'draw', handler: onDraw });
  };

  // 绘制圆
  drawCircle = () => {
    this.set({
      tooltip: { visible: true, content: '请在地图上通过鼠标绘制圆形区域' },
    });

    const map = this._map;

    map.setDefaultCursor('pointer');

    // 监听绘制完成事件
    const onDraw = e => {
      const marker = e.obj;
      const circle = new window.AMap.Circle({
        ...CircleStyle,
        map,
        center: marker.getPosition(),
        radius: 2000,
      });

      marker.on('dragging', () => {
        circle.setCenter(marker.getPosition());
      });

      marker.on('dragend', () => {
        this.markerGetAddress(marker.getPosition());
      });

      this.set({
        radius: 2000,
        tooltip: { visible: false, content: '' },
      });
      this.markerGetAddress(marker.getPosition());
      this._overlayEditing = circle;
      this._mouseTool.close();
    };

    this._mouseTool.marker({ draggable: true });
    this._mouseTool.on('draw', onDraw);
    this._mouseToolEvents.push({ eventName: 'draw', handler: onDraw });
  };

  // 绘制多边形
  drawPolygon = () => {
    this.set({
      tooltip: {
        visible: true,
        content: '请在地图上通过鼠标绘制多边形区域，单击左键继续绘制，双击左键完成绘制',
      },
    });

    const map = this._map;

    map.setDefaultCursor('crosshair');

    // 监听绘制事件
    const onDraw = e => {
      const polygon = e.obj; // 获取绘制的多边形覆盖物对象
      const center = polygon.getBounds().getCenter();
      const marker = new window.AMap.Marker({
        map,
        position: center,
        draggable: true,
      });
      const editor = new window.AMap.PolyEditor(this._map, polygon);

      editor.on('adjust', () => {
        const ctr = polygon.getBounds().getCenter();

        marker.setPosition(ctr);
        this.markerGetAddress(ctr);
      });
      editor.open(); // 打开编辑功能

      marker.on('dragging', () => {
        const paths = getOffsetPaths(polygon, marker.getPosition());

        polygon.setPath(paths);
      });

      marker.on('dragend', () => {
        this.markerGetAddress(marker.getPosition());
      });

      map.setDefaultCursor('pointer');
      map.add(polygon);

      this.set({
        radius: 0,
        tooltip: { visible: false, content: '' },
      });
      this.markerGetAddress(polygon.getBounds().getCenter());
      this._editor = editor;
      this._overlayEditing = polygon;
      this._mouseTool.close();
      polygon.marker = marker; // 多边形特殊点，需要单独清理点
    };

    this._mouseTool.polygon(PolygonStyle);
    this._mouseTool.on('draw', onDraw);
    this._mouseToolEvents.push({ eventName: 'draw', handler: onDraw });
  };

  // --- 搜索面板 ---
  getSugIcon = source => {
    const src = getIconBySource(source);
    return <img height={20} src={src} alt="" />;
  };

  handleSearch = async str => {
    const { searchKeyword } = this.state;
    const keyword = str || searchKeyword;
    if (keyword) {
      this.sugClear();
      this.set(
        {
          searchLoading: true,
          searchKeywordLast: keyword,
        },
        this.searchAllSource,
      );
    }

    this.clearDrawedOverlay();
  };

  // 目前的交互要求，需要一次性查询所有类型的地址列表，并绘制marker在地图上
  searchAllSource = () => {
    const { isCreate, isModify } = this.config;
    if (!isCreate && !isModify) {
      this.searchBySource('0', 1);
    }
    // 暂时不搜索平台智能推荐的数据
    // this.searchBySource('1', 1);
    this.searchBySource('2', 1);
  };

  searchBySource = (source, pageNum) => {
    const { groupId, appName } = this.config;
    const { searchKeyword, searchKeywordLast } = this.state;
    // 优先使用上次搜索时记忆的关键字
    // 因为在展示搜索结果的过程中，用户可能会修改输入框的文字，但并不进行搜索
    const keyword = searchKeywordLast || searchKeyword;
    const sourceMap = {
      0: {
        paramsKey: 'storeSearchParams',
        dataKey: 'storeResultList',
        responseKey: 'tenant',
        loadingKey: 'storeSearchLoading',
        markerListKey: '_storeMarkers',
      },
      1: {
        paramsKey: 'aiSearchParams',
        dataKey: 'aiResultList',
        responseKey: 'platform',
        loadingKey: 'aiSearchLoading',
        markerListKey: '_aiMarkers',
      },
      2: {
        paramsKey: 'gaodeSearchParams',
        dataKey: 'gaodeResultList',
        responseKey: 'gaode',
        loadingKey: 'gaodeSearchLoading',
        markerListKey: '_gaodeMarkers',
      },
    };
    const keyNames = sourceMap[source];
    const currentParams = this.state[keyNames.paramsKey];
    const params = {
      appName,
      tenantId: groupId,
      keyWord: keyword,
      pageSize: currentParams.pageSize,
      pageNum,
      source,
    };
    this.set({
      [keyNames.loadingKey]: true,
    });
    const option = {
      method: 'POST',
      body: { ...params },
    };
    fetchNewVega(POI_API.search, option)
      .then(res => {
        const result = res[keyNames.responseKey];
        this.set(
          {
            [keyNames.dataKey]: result.data || [],
            [keyNames.paramsKey]: result.pageInfo,
            isResultPanelVisible: true,
            hasSearchResult: true,
          },
          () => {
            this.drawMarkers(result.data || [], keyNames.markerListKey);
          },
        );
      })
      .finally(() => {
        this.set({
          [keyNames.loadingKey]: false,
          searchLoading: false,
        });
      });
  };

  // 搜索自动完成, 输入时
  handleSugInput = str => {
    this.set({ searchKeyword: str });
    if (str?.trim() !== '') {
      this.handleSugFetch(str);
    }
  };

  handleSugFetch = _.debounce(async keyword => {
    const { groupId, appName } = this.config;
    this.set({ sugLoading: true });
    const option = {
      method: 'GET',
    };
    const params = {
      keyword,
      sliceNum: 10,
      tenantId: groupId,
      appName,
    };
    let url = '';
    try {
      url = `${POI_API.sug}?${qs.stringify(params)}`;
    } catch (error) {
      this.set({ sugLoading: false });
      return showInfo(ERROR, '请勿输入特殊字符');
    }
    const res = await fetchNewVega(url, option).finally(() => {
      this.set({ sugLoading: false });
    });
    if (res && res.length) {
      this.set({ sugList: res });
    }
  }, 500);

  // 搜索自动完成, 选中时
  handleSugSelect = (val, item) => {
    const fullAddr = formatAddr(item.extra);
    this.set({
      searchKeyword: fullAddr,
    });
    this.clearDrawedOverlay();
    this.handleSearch(fullAddr);
  };

  handleSugClear = () => {
    this.set({
      searchKeyword: '',
      hasSearchResult: false,
    });
    this.sugClear();
  };

  sugClear = () => {
    this.set({
      sugList: [],
      isResultPanelVisible: false,
      isDetailPanelVisible: false,
      hasSearchResult: false,
      currentTabKey: 'gaode',
    });
    // 仅在用户点击清空按钮时，才清理地图 marker
    this.clearMap();
  };

  // --- 详情卡片面板 ---
  // 展示 poi 详情面板
  showPOIDetail = data => {
    this.set({
      isResultPanelVisible: false,
      isDetailPanelVisible: true,
      currentPOI: data,
    });
  };

  // 编辑覆盖物
  editPOIOverlay = data => {
    const { graphType, radius = 2000 } = data;
    const map = this._map;

    map?.clearMap();

    switch (graphType) {
      case MARKER_TYPE:
        {
          const loc = getLocFromStr(data.location);
          const icon = new window.AMap.Icon({
            size: [25, 32],
            imageSize: [25, 32],
            image: getIconBySource(data.source),
          });
          const marker = new window.AMap.Marker({
            map,
            position: loc,
            icon,
            extData: data,
            offset: new window.AMap.Pixel(-12.5, -29),
            draggable: true,
          });

          marker.on('dragend', () => {
            this.markerGetAddress(marker.getPosition());
          });
        }
        break;

      case CIRCLE_TYPE:
        {
          const position = getLocFromStr(data.location);
          const marker = new window.AMap.Marker({
            map,
            position,
            draggable: true,
          });
          const circle = new window.AMap.Circle({
            ...CircleStyle,
            map,
            center: position,
            radius,
          });

          marker.on('dragging', () => {
            circle.setCenter(marker.getPosition());
          });

          marker.on('dragend', () => {
            this.markerGetAddress(marker.getPosition());
          });

          this._overlayEditing = circle;
        }
        break;

      case POLYGON_TYPE:
        {
          const polygon = new window.AMap.Polygon({
            ...PolygonStyle,
            map: this._map,
            path: data.latlngs
              .split(';')
              .slice(0, -1) // 移除最后一个重复的点
              .map(coord => coord.split(',')),
          });
          const center = polygon.getBounds().getCenter();
          const marker = new window.AMap.Marker({
            map,
            position: center,
            draggable: true,
          });
          const editor = new window.AMap.PolyEditor(this._map, polygon);

          editor.on('adjust', () => {
            const ctr = polygon.getBounds().getCenter();

            marker.setPosition(ctr);
            this.markerGetAddress(ctr);
          });
          editor.open(); // 打开编辑功能

          marker.on('dragging', () => {
            const paths = getOffsetPaths(polygon, marker.getPosition());

            polygon.setPath(paths);
          });

          marker.on('dragend', () => {
            this.markerGetAddress(marker.getPosition());
          });

          this.markerGetAddress(polygon.getBounds().getCenter());
          this._editor = editor;
          this._overlayEditing = polygon;
          this._mouseTool.close();
        }
        break;
      default:
    }

    this.set({ graphType });
  };
  // --- 搜索结果 tab 面板 ---

  clearMap = () => {
    this.cancelHighlightMarker();
    this._markers.forEach(marker => marker.setMap(null));
    if (this._clickedMarker) {
      this._clickedMarker.setMap(null);
    }
  };

  handleConfirm = async data => {
    const { isCreate } = this.config;
    this.props.onConfirm?.({ ...data });
    // 非新建，且存在 oid 时计入统计
    if (!isCreate && data.oid) {
      // 统计点击确定次数
      const option = {
        method: 'get',
      };
      const parmas = {
        oid: data.oid,
      };
      const url = `${POI_API.select}?${qs.stringify(parmas)}`;
      fetchNewVega(url, option);
    }
  };

  copyText = (e, text) => {
    e.stopPropagation();
    copy(text);
    showInfo(CHECK, '复制成功');
  };

  handleResultItemClick = data => {
    this.showPOIDetail(data);
    this.tryToHighlightMarker(data);
    // 通过输入地址新增常用点时只能新增点
    this.clearDrawedOverlay();
    this.set({ graphType: 0 });
    this.drawMarker();
  };

  renderSearchBox = () => {
    const { searchKeyword, sugLoading, searchLoading, sugList, hasSearchResult } = this.state;

    return (
      <div className="poi-selector__search">
        <div className="poi-selector__search--input">
          <AutoComplete
            value={searchKeyword}
            onSelect={this.handleSugSelect}
            allowClear={false}
            placeholder="搜索地点、常用地址"
            onSearch={this.handleSugInput}
            filterOption={false}
            loading={sugLoading}
            renderFormat={formatAddr}
          >
            {sugList.length
              ? sugList.map(item => (
                  <AutoComplete.Option key={`sug_item_${item.oid}`} value={formatAddr(item)} extra={item}>
                    <span className="poi-select__sug-item">
                      {this.getSugIcon(item.source)}
                      {formatAddr(item)}
                    </span>
                  </AutoComplete.Option>
                ))
              : null}
          </AutoComplete>
          {hasSearchResult ? <IconClose className="poi-selector__clear" onClick={this.handleSugClear} /> : null}
        </div>
        <Button type="primary" shape="square" loading={searchLoading} onClick={() => this.handleSearch()}>
          搜索
        </Button>
      </div>
    );
  };

  showFavoritePanel = (e, data) => {
    e.stopPropagation();
    this.set({
      currentFavoritePOI: { ...data },
      isFavoriteVisible: true,
    });
  };

  renderFavoriteButton = (data, size = 'small') => {
    const { tempFavoriteList } = this.state;
    // 已收藏的展示但禁用收藏按钮
    const isInFavorite = tempFavoriteList.includes(data.oid);
    // 来自地址库的不展示收藏按钮
    const isFromStore = String(data.source) === '0';

    if (!isFromStore) {
      if (!isInFavorite) {
        return (
          <Button type="default" size={size} shape="square" onClick={e => this.showFavoritePanel(e, data)}>
            收藏
          </Button>
        );
      } else {
        return (
          <Tag color="green" onClick={e => e.stopPropagation()}>
            已收藏
          </Tag>
        );
      }
    }
    return null;
  };

  renderResultItem = (data, type) => {
    const { isCreate } = this.config;
    // 为确保唯一性，使用 地址类型+oid 区分
    const k = `result_item_${type}_${data.oid}`;

    return (
      <div className="poi-selector__result-item" key={k} onClick={() => this.handleResultItemClick(data)}>
        <div className="poi-type">
          <img height={40} src={getIconBySource(data.source)} alt="" />
        </div>
        <div className="poi-info">
          <div className="poi-info-title">{showAddr(data)}</div>
          <div className="poi-info-detail">
            {data.address || '--'}
            <span
              className="btn-copy"
              onClick={e => {
                this.copyText(e, data.address);
              }}
            >
              复制
            </span>
          </div>
          <div className="poi-info-location">
            {data.location || '--'}
            <span
              className="btn-copy"
              onClick={e => {
                this.copyText(e, data.location);
              }}
            >
              复制
            </span>
          </div>
        </div>
        {isCreate ? (
          <Button size="mini" onClick={() => this.handleResultItemClick(data)}>
            新增
          </Button>
        ) : (
          this.renderFavoriteButton(data, 'mini')
        )}
      </div>
    );
  };

  renderResultPanel = () => {
    const { isCreate } = this.config;
    const { currentTabKey } = this.state;
    const { gaodeResultList, storeResultList, aiResultList } = this.state;
    const { gaodeSearchParams, storeSearchParams, aiSearchParams } = this.state;
    const { gaodeSearchLoading, storeSearchLoading, aiSearchLoading } = this.state;
    const gaodeEmpty = gaodeResultList.length === 0 && !gaodeSearchLoading;
    const storeEmpty = storeResultList.length === 0 && !storeSearchLoading;
    const aiEmpty = aiResultList.length === 0 && !aiSearchLoading;

    const handleTabChange = k => this.set({ currentTabKey: k });

    return (
      <div className="poi-selector__result">
        <Tabs defaultActiveTab={currentTabKey} onChange={handleTabChange}>
          <TabPane key="gaode" title="高德">
            <Spin loading={gaodeSearchLoading} block>
              <div className="box-body">{gaodeResultList.map(item => this.renderResultItem(item, 'gaode'))}</div>
              {gaodeEmpty ? <div className="box-empty">暂无数据</div> : null}
              <div className="box-pagination">
                <Button
                  disabled={gaodeSearchParams.pageNum === 1}
                  type="default"
                  size="mini"
                  onClick={() => this.searchBySource('2', --gaodeSearchParams.pageNum)}
                >
                  上一页
                </Button>
                <Button
                  disabled={gaodeEmpty || gaodeResultList.length < gaodeSearchParams.pageSize}
                  type="default"
                  size="mini"
                  onClick={() => this.searchBySource('2', ++gaodeSearchParams.pageNum)}
                >
                  下一页
                </Button>
              </div>
            </Spin>
          </TabPane>

          {!isCreate ? (
            <TabPane key="store" title="地址库">
              <Spin loading={storeSearchLoading} block>
                <div className="box-body">{storeResultList.map(item => this.renderResultItem(item, 'store'))}</div>
                {storeEmpty ? <div className="box-empty">暂无数据</div> : null}
                <div className="box-pagination">
                  <Button
                    disabled={storeSearchParams.pageNum === 1}
                    type="default"
                    size="mini"
                    onClick={() => this.searchBySource('0', --storeSearchParams.pageNum)}
                  >
                    上一页
                  </Button>
                  <Button
                    disabled={storeEmpty || storeResultList.length < storeSearchParams.pageSize}
                    type="default"
                    size="mini"
                    onClick={() => this.searchBySource('0', ++storeSearchParams.pageNum)}
                  >
                    下一页
                  </Button>
                </div>
              </Spin>
            </TabPane>
          ) : null}

          {/* <TabPane key="ai" title="智能地址">
            <Spin loading={aiSearchLoading} block>
              <div className="box-body">{aiResultList.map(item => this.renderResultItem(item, 'ai'))}</div>
              {aiEmpty ? <div className="box-empty">暂无数据</div> : null}
              <div className="box-pagination">
                <Button
                  disabled={aiSearchParams.pageNum === 1}
                  type="default"
                  size="mini"
                  onClick={() => this.searchBySource('1', --aiSearchParams.pageNum)}
                >
                  上一页
                </Button>
                <Button
                  disabled={aiEmpty || aiResultList.length < aiSearchParams.pageSize}
                  type="default"
                  size="mini"
                  onClick={() => this.searchBySource('1', ++aiSearchParams.pageNum)}
                >
                  下一页
                </Button>
              </div>
            </Spin>
          </TabPane> */}
        </Tabs>
      </div>
    );
  };

  handleBackToResult = () => {
    const { hasSearchResult } = this.state;
    this.set({
      currentPOI: null,
      isDetailPanelVisible: false,
      isResultPanelVisible: hasSearchResult,
      radius: 0,
    });
    this.cancelHighlightMarker();
    this.clearDrawedOverlay();
  };

  handleCreate = type => {
    this.handleBackToResult();

    switch (+type) {
      case MARKER_TYPE:
        this.drawMarker();
        break;
      case CIRCLE_TYPE:
        this.drawCircle();
        break;
      case POLYGON_TYPE:
        this.drawPolygon();
        break;
      default:
    }

    this.set({ graphType: +type });
  };

  renderCreateButton = () => {
    const { tooltip } = this.state;

    return (
      <Dropdown
        trigger="click"
        triggerProps={{ autoAlignPopupWidth: true }}
        droplist={
          <Menu className="poi-selector__create-type" onClickMenuItem={this.handleCreate}>
            <Menu.Item key={MARKER_TYPE}>点</Menu.Item>
            <Menu.Item key={CIRCLE_TYPE}>圆</Menu.Item>
            <Menu.Item key={POLYGON_TYPE}>多边形</Menu.Item>
          </Menu>
        }
      >
        <Tooltip
          position="right"
          content={tooltip.content}
          popupVisible={tooltip.visible}
          getPopupContainer={node => node.parentNode}
        >
          <Button className="poi-selector__create-btn" type="primary" icon={<IconPlusCircle />}>
            新增
          </Button>
        </Tooltip>
      </Dropdown>
    );
  };

  renderDetail = () => {
    const { originPOI, currentPOI, oid, aliasName, aliasNameNew, radius, favoriteLoading, graphType } = this.state;
    const { isCreate, isModify, groupId, companyId, appName } = this.config;
    let formData = { oid, aliasName, aliasNameNew, radius };

    const onFormChange = (v, vs) => {
      formData = vs;

      this.set({ ...formData });
    };

    const doCreate = async () => {
      const valid = await this._form.validate().catch(() => {});

      if (!valid) {
        return;
      }

      this.set({ favoriteLoading: true });

      const option = {
        method: 'POST',
        body: {
          ...originPOI,
          ...currentPOI,
          ...formData,
          tenantId: groupId,
          groupId,
          companyId,
          appName,
          graphType,
          latlngs: graphType === POLYGON_TYPE ? getPolygenPath() : currentPOI.location,
        },
      };
      const res = await fetchNewVega(POI_API.favorite, option).finally(() => {
        this.set({ favoriteLoading: false });
      });

      if (res) {
        showInfo(CHECK, res.msg ?? '新增成功');

        this.handleConfirm(currentPOI);
      }
    };

    const getPolygenPath = () => {
      const latlngs = this._overlayEditing?.getPath?.().map(lngLat => lngLat.toString()) ?? [];

      // 大数据要求首尾点相同
      latlngs.push(latlngs[0]);

      return latlngs.join(';');
    };

    // 修改点类型为圆
    const transToCircle = () => {
      this.set({ graphType: CIRCLE_TYPE, radius: 2000 });
      this._form?.setFieldsValue({ radius: 2000 });

      this.editPOIOverlay({ ...currentPOI, graphType: CIRCLE_TYPE, radius: 2000 });
    };

    return (
      <div className="poi-selector__detail" style={isModify ? { top: 10 } : {}}>
        <div className="detail-banner">
          {!isModify && (
            <div className="btn-back" onClick={this.handleBackToResult}>
              &lt; 返回
            </div>
          )}
          <div className="icon-source">
            <img height={50} src={getIconBySource(currentPOI.source)} alt="" />
          </div>
        </div>

        <Form
          ref={curRef => (this._form = curRef)}
          initialValues={formData}
          autoComplete="off"
          size="mini"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 17,
          }}
          onValuesChange={onFormChange}
        >
          <FormItem label="地址名：">
            <span>{currentPOI.name || '--'}</span>
          </FormItem>

          {(isCreate || isModify) && (
            <FormItem
              label="地址别名："
              field={isModify ? 'aliasNameNew' : 'aliasName'}
              rules={[{ required: true, message: '请输入自定义别名' }]}
            >
              <Input placeholder="请输入自定义别名，多个别名可用逗号分割" />
            </FormItem>
          )}

          {graphType === CIRCLE_TYPE && (
            <FormItem
              label="半径："
              field="radius"
              rules={[{ required: true, type: 'number', min: 1, max: 99999, message: '请输入1~99999之间的整数' }]}
              normalize={value => {
                if (value) {
                  const r = String(value).replace(/\D/g, '');

                  this._overlayEditing?.setRadius?.(r);

                  return r;
                }

                return value;
              }}
            >
              <Input suffix="米" maxLength="5" style={{ width: 150 }} />
            </FormItem>
          )}

          <FormItem label="详细地址：">
            <span>
              {currentPOI.address}
              <span
                className="btn-copy"
                onClick={e => {
                  this.copyText(e, currentPOI.address);
                }}
              >
                复制
              </span>
            </span>
          </FormItem>

          <FormItem label="经纬度：">
            <span>
              {currentPOI.location}
              <span
                className="btn-copy"
                onClick={e => {
                  this.copyText(e, currentPOI.location);
                }}
              >
                复制
              </span>
            </span>
          </FormItem>

          <FormItem label="类型：">
            <span>{currentPOI.poiType || '--'}</span>
          </FormItem>

          <div className="btn-box">
            {isModify && graphType === MARKER_TYPE && (
              <Button status="warning" onClick={() => transToCircle()}>
                修改点类型为圆
              </Button>
            )}
            {isCreate || isModify ? (
              <Button type="primary" onClick={() => doCreate(currentPOI)} loading={favoriteLoading}>
                确认
              </Button>
            ) : (
              [
                this.renderFavoriteButton(currentPOI, 'default'),
                <Button type="primary" onClick={() => this.handleConfirm(currentPOI)}>
                  确定
                </Button>,
              ]
            )}
          </div>
        </Form>
      </div>
    );
  };

  renderFavoritePanel = () => {
    const { isFavoriteVisible, favoriteLoading, currentFavoritePOI, graphType } = this.state;
    const { isCreate, groupId, companyId, appName } = this.config;
    const formData = { name: currentFavoritePOI?.aliasName };
    let _form = null;

    const handleFavorite = async () => {
      const { tempFavoriteList } = this.state;
      const valid = await _form.validate().catch(() => {});
      if (!valid) {
        return;
      }
      this.set({ favoriteLoading: true });

      const option = {
        method: 'POST',
        body: {
          ...currentFavoritePOI,
          tenantId: groupId,
          groupId,
          companyId,
          appName,
          aliasName: formData.name,
          graphType,
          latlngs: graphType === POLYGON_TYPE ? this._overlayEditing.getPath() : currentFavoritePOI.location,
        },
      };
      const res = await fetchNewVega(POI_API.favorite, option).finally(() => {
        this.set({ favoriteLoading: false });
      });

      if (res) {
        this.set({
          tempFavoriteList: [...tempFavoriteList, currentFavoritePOI.oid],
          isFavoriteVisible: false,
        });
        if (isCreate) {
          showInfo(CHECK, '新增成功');
          this.handleConfirm(currentFavoritePOI);
        } else {
          showInfo(CHECK, '收藏成功');
        }
      }
    };

    const handleValueChange = v => {
      this.set({
        currentFavoritePOI: { ...this.state.currentFavoritePOI, aliasName: v.name },
      });
    };

    return (
      <Modal
        title={`${isCreate ? '新增' : '收藏'}地址`}
        visible={isFavoriteVisible}
        okButtonProps={{
          disabled: favoriteLoading,
        }}
        onCancel={() => {
          this.set({ isFavoriteVisible: false });
        }}
        onOk={handleFavorite}
      >
        <Form
          ref={curRef => (_form = curRef)}
          initialValues={formData}
          onValuesChange={handleValueChange}
          autoComplete="off"
        >
          <FormItem label="地址别名" field="name" rules={[{ required: true, message: '请输入自定义别名' }]}>
            <Input placeholder="请输入自定义别名，多个别名可用逗号分割" />
          </FormItem>
        </Form>

        <div>
          <p>添加别名有助于您在日常工作中使用的便捷性。</p>
        </div>
      </Modal>
    );
  };

  render() {
    const { loading, isResultPanelVisible, isDetailPanelVisible } = this.state;
    const { isCreate, isModify } = this.config;

    return (
      <div className="poi-selector__wrapper">
        {loading ? (
          <div className="poi-selector__loading">
            <Spin />
          </div>
        ) : null}
        <MapShow width="100%" height="100%" onMapLoaded={this.handleMapLoaded} />
        {!isModify && this.renderSearchBox()}
        {isResultPanelVisible ? this.renderResultPanel() : null}
        {isDetailPanelVisible ? this.renderDetail() : null}
        {isCreate && this.renderCreateButton()}
        {this.renderFavoritePanel()}
      </div>
    );
  }
}
