var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes rotate-loading{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes rotate-loading{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.fn-loader__loader{position:absolute;top:0;right:0;bottom:0;left:0;z-index:5;width:100%;height:100%;background-color:transparent}.fn-loader__loader .loading-icon{top:35%;left:50%;margin-left:-36px;width:72px;height:96px}.fn-loader__loader0 .loading-icon{background:url(" + escape(require("images/loadingGif/loading.gif")) + ") 0 0 no-repeat}.fn-loader__loader1 .loading-icon{background:url(" + escape(require("images/loadingGif/loading-dbl-circle.gif")) + ") 0 -20px no-repeat;width:85px;height:80px}.fn-loader__loader2 .loading-icon{background:url(" + escape(require("images/loadingGif/loading-five-circle.gif")) + ") 0 0 no-repeat;width:110px;height:110px}.fn-loader__loader3 .loading-icon{background:url(" + escape(require("images/loadingGif/loading-hourglass.gif")) + ") 0 0 no-repeat;width:100px}.fn-loader__loader4 .loading-icon{background:url(" + escape(require("images/loadingGif/loading-ring.gif")) + ") 0 0 no-repeat;width:72px;height:110px}.fn-loader__content{position:relative;width:100%;height:100%}.fn-loader_whitebg{background:#fff}.icon-loading{display:inline-block;width:1em;height:1em;border-radius:100%;-webkit-animation-fill-mode:both;animation-fill-mode:both;border:1px solid;border-bottom-color:transparent;-webkit-animation:rotate-loading .75s 0s linear infinite;animation:rotate-loading .75s 0s linear infinite}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-loader",
	"fn-loader__loader": "fn-loader__loader",
	"loading-icon": "loading-icon",
	"fn-loader__loader0": "fn-loader__loader0",
	"fn-loader__loader1": "fn-loader__loader1",
	"fn-loader__loader2": "fn-loader__loader2",
	"fn-loader__loader3": "fn-loader__loader3",
	"fn-loader__loader4": "fn-loader__loader4",
	"fn-loader__content": "fn-loader__content",
	"fn-loader_whitebg": "fn-loader_whitebg",
	"icon-loading": "icon-loading",
	"rotate-loading": "rotate-loading"
};