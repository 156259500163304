exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".upload-attachment .text{font-family:Microsoft YaHei;font-size:12px;line-height:16px}.upload-attachment .text .fn-label{margin-right:10px;color:#333}.upload-attachment .text .label-remark{color:#f37100}.upload-attachment .text .loading-mark{display:inline-block;margin-left:6px;color:#0097cf}.upload-attachment .attachment-content{display:inline-flex;padding-left:60px}.upload-attachment .attachment-content .upload-select{line-height:36px;height:36px}.upload-attachment .attachment-content .upload-select .fn-icon{margin-right:6px;font-size:14px;width:14px;height:14px}.upload-attachment .attachment-content .upload-select>span a{color:#0097cf}.upload-attachment .attachment-content .file-list-item{line-height:36px;height:36px}.upload-attachment .attachment-content .file-list-item .fn-icon{top:4px}", ""]);

// exports
exports.locals = {
	"uploadAttachmentClass": "upload-attachment",
	"upload-attachment": "upload-attachment",
	"text": "text",
	"fn-label": "fn-label",
	"label-remark": "label-remark",
	"loading-mark": "loading-mark",
	"attachment-content": "attachment-content",
	"upload-select": "upload-select",
	"fn-icon": "fn-icon",
	"file-list-item": "file-list-item"
};