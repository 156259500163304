/**
 * Created by Aceh on 2017/01/01.
 */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { prefixCls } from './index.scss';
import { deleteProps, typeIs } from 'utils';
import { renderCompChild } from '../../../utilcomponents/mixins/renderCompChild';
import Icon from '../../../utilcomponents/icon/Icon';

@renderCompChild
export default class Label extends PureComponent {
  static defaultProps = {
    hasClass: true,
    isRequired: false,
    disabled: false,
    showColon: false,
    showSpark: false,
  };
  static propTypes = {
    classname: PropTypes.string,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.any,
    hasClass: PropTypes.bool,
    valiType: PropTypes.string,
    showColon: PropTypes.bool, // 默认不显示冒号
    showSpark: PropTypes.bool, // 默认必填只变红， 不显示小星星
    isIcon: PropTypes.bool, // label是否为一个icon(现在不支持 icon 和  Name的混用)
    iconType: PropTypes.string, // 为label图标
    tips: PropTypes.string, // label上的提示
  };
  constructor(prop) {
    super(prop);
    this.state = {
      children: prop.children,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.children !== this.state.children) {
      this.setState({ children: nextProps.children });
    }
  }
  // 点击事件
  handleClick = () => {
    if (this.props.disabled) return null;
    this.props.onClick && this.props.onClick();
  };

  render() {
    const { classname, isRequired, showColon, disabled, hasClass, ...rest } = this.props;
    const _showColon = typeIs(showColon, 'string') ? showColon === 'true' : showColon;
    const classes = classnames(classname, {
      [prefixCls]: hasClass || isRequired || disabled,
      [`${prefixCls}--required`]: isRequired,
      [`${prefixCls}--disabled`]: disabled,
    });
    const _props = deleteProps(
      rest,
      'getPaths',
      'showSpark',
      'isIcon',
      'iconType',
      'tips',
      'valiType',
      'showColon',
      'showcolon',
    );
    let { children } = this.state;
    if (this.props.isIcon) {
      children = <Icon iconType={this.props.iconType} />;
    }
    if (typeIs(children, 'array')) {
      children = children.join('，');
    }
    if (_.isFunction(this.props.customChildren)) {
      children = this.props.customChildren();
    }
    return (
      <label {..._props} className={classes} onClick={() => this.handleClick()}>
        {isRequired && this.props.showSpark && <i>*</i>}
        {this.renderCompChild(this.props, children)}
        {_showColon && <span>：</span>}
      </label>
    );
  }
}
