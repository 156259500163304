import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { WIN_MIN_WIDTH, CO_MIN_ITEM_WIDTH, CO_MAX_ITEM_WIDTH } from 'constants';
/*
 */
const ResizeItemWidth = ComposedComponent =>
  class extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {};
    }

    static defaultProps = {
      specialColumn: {
        route: 2, // 特殊的列， 比如路由占两个位置
      },
    };
    static propTypes = {
      specialColumn: PropTypes.object,
    };
    static childContextTypes = {
      handleResize: PropTypes.func,
      resizeWidth: PropTypes.func,
      hanldeResizeTableWidth: PropTypes.func,
    };
    getChildContext = () => ({
      handleResize: this.handleResize,
      resizeWidth: this.resizeWidth,
      hanldeResizeTableWidth: this.hanldeResizeTableWidth,
    });
    // widthAttr, 最大和最小值自定义从外部传
    handleResize = (cardWrap, reqData, widthAttr) => {
      if (!cardWrap) return;
      let coMaxWidth = CO_MAX_ITEM_WIDTH;
      let coMinWidth = CO_MIN_ITEM_WIDTH;
      if (widthAttr !== undefined) {
        coMaxWidth = widthAttr.maxWidth || coMaxWidth;
        coMinWidth = widthAttr.minWidth || coMinWidth;
      }
      let width = cardWrap ? cardWrap.offsetWidth : WIN_MIN_WIDTH;
      let item = Object.keys(reqData).length;
      if (width < WIN_MIN_WIDTH - 30) width = WIN_MIN_WIDTH - 30;
      Object.keys(this.props.specialColumn).forEach(key => {
        if (reqData[key]) item += this.props.specialColumn[key] - 1;
      });
      let minWidth = width / item > coMaxWidth ? coMaxWidth : width / item;
      minWidth = minWidth < coMinWidth ? coMinWidth : minWidth;
      return { itemWidth: minWidth - 2, winWidth: width };
    };
    // mergeToLast : 是否将剩余的宽度给最后一个元素
    resizeWidth = (reqData, winWidth, itemWidth, mergeToLast = false, openMerge = true) => {
      let tmpWidth = 0;
      const newReqData = reqData;
      const totalCount = Object.keys(reqData).length;
      let count = 0;
      Object.keys(reqData).forEach(key => {
        let width = itemWidth;
        const style = {};
        if (this.props.specialColumn[key]) width *= this.props.specialColumn[key];
        tmpWidth += width;
        count += 1;
        // 最后剩余的宽度放不下一个元素, 或者最后一个元素
        if (openMerge && (winWidth - tmpWidth < itemWidth - 50 || (mergeToLast && count === totalCount))) {
          width += winWidth - tmpWidth - 6 - 10; // 将滚动条的宽度考虑进去
          tmpWidth = 0;
          style.borderRight = 'none';
        }
        // if (key === 'expected_release_time') {
        //   width += 96
        // }
        style.width = width;
        newReqData[key].style = style;
      });
      return newReqData;
    };
    // 将table的宽度
    hanldeResizeTableWidth = (wrapWidth, header, specialColumn) => {
      const newHeader = header;
      let totalWidth = 0;
      let column = 0;
      Object.keys(newHeader).forEach(key => {
        const item = newHeader[key];
        if (item.display === 'show') {
          totalWidth += item.width;
          column += 1;
        }
      });
      // 列少的情况且有特殊列， 宽度给特殊列
      if (specialColumn && totalWidth < wrapWidth) {
        newHeader[specialColumn].width = wrapWidth - (totalWidth - newHeader[specialColumn].width);
        return newHeader;
      }
      // 列少的情况  均分
      if (!specialColumn && totalWidth < wrapWidth) {
        const commonWidth = parseFloat(wrapWidth / column);
        Object.keys(newHeader).forEach(key => {
          newHeader[key].width = commonWidth;
        });
      }
      return newHeader;
    };

    getWrappedInstance() {
      return this.wrappedInstance;
    }

    render() {
      return <ComposedComponent {...this.props} {...this.state} ref={r => (this.getWrappedInstance = r)} />;
    }
  };
export default ResizeItemWidth;
