exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".popup_wrap{position:absolute;right:0;top:0;width:100%;height:100%;z-index:1024}.popup_wrap_hide,.popup_wrap_hide_modal{position:absolute;top:0;z-index:-1;opacity:0}body>.popup_wrap{z-index:1034}", ""]);

// exports
exports.locals = {
	"prefixCls": "popup_wrap",
	"popup_wrap": "popup_wrap",
	"popup_wrap_hide": "popup_wrap_hide",
	"popup_wrap_hide_modal": "popup_wrap_hide_modal"
};