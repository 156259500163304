/**
 * Created by xiaofeilong on 2017/01/01.
 */
// 引入源
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { PopUp, UploadImgCamera, Icon } from 'components';
import {
  hasPermission,
  showInfo,
  viewImage,
  fetchApi as fetch,
  sleep,
  genImageURL,
  asynUploadImg,
  isset,
  compressImg,
} from 'utils';
import { ERROR } from 'constants';
import './index.scss';

export default class UploadImgText extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    labelText: PropTypes.string,
    photoProps: PropTypes.object,
    tips: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    fileType: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    maxNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    selectFileShow: PropTypes.bool,
    compress: PropTypes.bool,
    cameraShow: PropTypes.bool,
    maxSize: PropTypes.number, // 图片最大不超过？M 单位 M
    maxSizePre: PropTypes.number, // 单张图片上传最大不超过(单位M)
    isAsyn: PropTypes.bool, // 是否支持图片是一张一张上传的。默认为false
    asynReq: PropTypes.object, // 单个图片上传时向后端返回的参数
    asynUrl: PropTypes.string, // 单个图片上传时的接口
    defaultImage: PropTypes.object, // 图片的默认参数
    isRequired: PropTypes.bool, // 是否必填，只是文字显示为红色
    needOriginName: PropTypes.bool, // 是否需要返回图片原始名称
    needReceiveProps: PropTypes.bool, // 是否需要UNSAFE_componentWillReceiveProps处理
    beforeUpload: PropTypes.func,
  };
  static defaultProps = {
    labelText: '图片上传',
    selectFileShow: true,
    cameraShow: false,
    maxSize: 5,
    maxNum: 3,
    isAsyn: false,
    imgMethod: 'interface',
    compress: true,
    asynUrl: '/Basic/Image/uploadImages',
    loading: false,
    needReceiveProps: false,
  };

  constructor(props) {
    super(props);
    const { cameraShow } = props;
    const currPhotoProps = this.props.photoProps || {};
    const photoNum = 'photoProps' in this.props ? Object.keys(currPhotoProps) : [];
    this.state = {
      photoNum, // 存放图片索引
      photoImg: currPhotoProps || {}, // 存放图片详情
      photoProps: {}, // 存放新的图片对象
      // eslint-disable-next-line react/no-unused-state
      asynPhotoImg: {}, // 存放单个照片上传时的数据
      imgError: false, // 图片读取是否有误,
      showCameraUpload: cameraShow && hasPermission('upload_photo'),
      // eslint-disable-next-line react/no-unused-state
      loading: false,
      isDragging: false,
      dragEnterCount: 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.needReceiveProps && nextProps.photoProps !== this.state.photoImg) {
      const photoNum = Object.keys(nextProps.photoProps);
      this.setState({
        photoNum,
        photoImg: nextProps.photoProps,
      });
    }
  }

  removeEle = (arr, key) => {
    const index = arr.indexOf(key);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  asynSend = async _img => {
    const { asynUrl } = this.props;
    const _formData = new FormData();
    if (['/Basic/Image/uploadImage', '/Basic/Image/uploadUcmmImage'].includes(asynUrl)) {
      _formData.append('file[]', _img.formdata);
    } else {
      _formData.append('img[]', _img.formdata);
    }
    const req = this.props.asynReq;
    req.t = new Date().valueOf();
    _formData.append('req', JSON.stringify(req));
    const conf = {
      method: 'POST',
      body: _formData,
      credentials: 'include',
    };
    const res = await fetch(asynUrl, conf);
    if (res.errno) {
      res.errmsg && showInfo(ERROR, res.errmsg);
      return false;
    }
    const imageInfo = res.res.image_info;
    const img = (imageInfo && imageInfo.img && imageInfo.img[0]) || imageInfo;
    if (this.props.needOriginName) {
      img.origin_name = _img.origin_name || _img.name;
    }
    return img;
  };

  // 需求增加lrz url的方式, 此时的theImg是图片(绝对)地址
  handleChange = async (_file, isByDrag) => {
    console.log(_file);
    const _this = this;
    const { lrzUrl } = _file;
    const { photoNum } = this.state;
    const { maxSize, maxSizePre, compress, maxNum, asynUrl, asynReq, beforeUpload } = this.props;
    let files = [];
    if (isByDrag) {
      files = _file;
    } else if (lrzUrl) {
      files = [lrzUrl];
    } else {
      files = [..._this.myTextInput.files];
    }
    // TODO: 支持Promise回调
    if (beforeUpload && !beforeUpload()) return;
    if (photoNum.length + files.length > maxNum) {
      _this.myTextInput.value = '';
      return showInfo(ERROR, `最多上传${maxNum}张图片`);
    }
    for (let i = 0; i < files.length; i++) {
      if (i > 0) await sleep(200);
      const theImg = files[i];
      const realSize = lrzUrl ? 0 : theImg.size / 1024 / 1024;
      // eslint-disable-next-line no-nested-ternary
      const quality = lrzUrl ? 0.1 : realSize > 2 ? (2 / realSize) * 0.2 + 0.6 : 0.8;
      const rst = await compressImg(theImg, { quality });
      const file = compress ? rst.file : theImg;
      const size = file.size / 1024 / 1024;
      console.log(`压缩系数：${quality},原大小：${realSize}M,压缩后大小：${size}M,压缩率：${size / realSize / 0.01}%`);
      const _timestamp = Date.now();
      const _name = _timestamp;
      const _img = {
        src: rst.base64,
        formdata: file,
        name: _name,
      };
      if (maxSizePre && +maxSizePre <= +size) {
        return showInfo(ERROR, `单张图片大小不超过${maxSizePre}M`);
      }
      if (this.props.needOriginName) {
        _img.origin_name = theImg.name;
      }
      const onChangeParam = this.props.isAsyn
        ? await asynUploadImg({ _img, asynUrl, asynReq, needOriginName: this.props.needOriginName })
        : _name;
      const photoImg = {
        ...this.state.photoImg,
        [_name]: this.props.isAsyn ? { ..._img, asny: onChangeParam } : _img,
      };
      const sizeSum = Object.values(photoImg).reduce((a, b) => a + (b.formdata ? b.formdata.size : 0), 0);
      if (sizeSum > maxSize * 1024 * 1024) {
        _this.myTextInput.value = '';
        return showInfo(ERROR, `图片总大小压缩后不能大于${maxSize}M`);
      }
      if (!onChangeParam) continue;
      _this.setState({
        photoNum: [...this.state.photoNum, _name],
        photoImg: {
          ...this.state.photoImg,
          [_name]: this.props.isAsyn ? { ..._img, asny: onChangeParam } : _img,
        },
        photoProps: { ...this.state.photoProps, [_name]: _img },
      });
      this.props.onChange && this.props.onChange(onChangeParam, 'add', lrzUrl);
    }
    if (_this.myTextInput) {
      _this.myTextInput.value = '';
    }
  };
  handleRemove = event => {
    const _this = this;
    const _name = event.target.getAttribute('data-name');
    const _obj = Object.assign({}, _this.state.photoImg);
    const onChangeParam = this.props.isAsyn ? _obj[_name].asny || _obj[_name] : _name;
    const _objProps = Object.assign({}, _this.state.photoProps);
    delete _obj[_name];
    if (_objProps[_name]) {
      delete _objProps[_name];
    }
    _this.setState({
      photoImg: _obj,
      photoNum: this.state.photoNum.filter(item => `${item}` !== _name),
      photoProps: _objProps,
    });
    this.props.onChange && this.props.onChange(onChangeParam, 'del');
  };
  handleClick = () => {
    this.myTextInput.click();
  };
  // 图片预览弹框
  handleView = src => {
    viewImage({ src, isShow: true, isModal: true }, this.state.photoImg);
  };
  // 关闭查看弹框
  // dialogViewClose = () => dialogRef && dialogRef.handleHide()
  handleCamera = async rst => {
    console.log('handleCamera =', rst);
    const { maxNum, asynUrl, asynReq } = this.props;
    const { photoNum } = this.state;
    const files = [...this.myTextInput.files];
    if (photoNum.length + files.length >= maxNum) {
      return showInfo(ERROR, `最多上传${maxNum}张图片`);
    }
    const _name = rst.name;

    const _img = {
      src: rst.src,
      formdata: rst.formdata,
      // formdata: file,
      name: _name,
    };
    const onChangeParam = this.props.isAsyn ? await asynUploadImg({ _img, asynUrl, asynReq }) : _name;

    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      photoNum: [...this.state.photoNum, _name],
      // eslint-disable-next-line react/no-access-state-in-setstate
      photoImg: { ...this.state.photoImg, [_name]: this.props.isAsyn ? { ..._img, asny: onChangeParam } : _img },
      // eslint-disable-next-line react/no-access-state-in-setstate
      photoProps: { ...this.state.photoProps, [_name]: _img },
    });

    this.props.onChange && this.props.onChange(onChangeParam, 'add');
  };
  handleOpenCamera = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ loading: true });
    const that = this;
    new PopUp(UploadImgCamera, {
      upLoadCb: this.handleCamera,
      isModal: true,
      cb: () => {
        that.setState({ loading: false });
      },
    }).show();
  };
  clearAllImgs = proxy => {
    const { callBack } = proxy;
    this.setState(
      {
        photoNum: [],
        photoImg: {},
        photoProps: {},
      },
      () => callBack && callBack(),
    );
  };
  imgError = event => {
    const { defaultImage } = this.props;
    if (!isset(this.props, 'defaultImage')) return false;
    const _name = event.target.getAttribute('data-name');
    // eslint-disable-next-line react/no-access-state-in-setstate
    const _obj = Object.assign({}, this.state.photoImg);
    // eslint-disable-next-line react/no-access-state-in-setstate
    const _objProps = Object.assign({}, this.state.photoProps);
    // eslint-disable-next-line react/no-access-state-in-setstate
    let _photoNum = this.state.photoNum;
    if (defaultImage) {
      if (_obj[_name].src !== defaultImage[_name].src) {
        _obj[_name] = defaultImage[_name];
      }
    } else {
      delete _obj[_name];
      if (_objProps[_name]) {
        delete _objProps[_name];
      }
      _photoNum = _photoNum.filter(item => `${item}` !== _name);
    }
    this.setState({
      photoImg: _obj,
      photoNum: _photoNum,
      photoProps: _objProps,
    });
  };

  handleDragEnter = event => {
    event.preventDefault();
    this.setDragEnterCount(this.state.dragEnterCount + 1);
  };

  handleDragLeave = event => {
    event.preventDefault();
    /**
     * 拖拽到子元素身上时，将在父元素身上触发 dragleave 和 dragenter
     * 记录拖拽的触发次数，dragenter 时 +1, dragleave 时 -1
     * 当 dragEnterCount等于0时，表示鼠标已经离开当前节点，则拖动状态被取消。
     * 参考：https://github.com/arco-design/arco-design/issues/210
     */
    if (this.state.dragEnterCount === 0) {
      this.setIsDragging(false);
    } else {
      this.setDragEnterCount(this.state.dragEnterCount - 1);
    }
  };

  handleDrop = event => {
    event.preventDefault();
    if (!this.props.disabled) {
      this.handleChange(event.dataTransfer.files, true);
    }
  };

  handleDragOver = event => {
    event.preventDefault();
    if (!this.props.disabled && !this.state.isDragging) {
      this.setIsDragging(true);
    }
  };

  setDragEnterCount = n => this.setState({ dragEnterCount: n });

  setIsDragging = v => {
    this.setState({ isDragging: v });
    this.setDragEnterCount(0);
  };

  render() {
    const { labelText, isRequired, tips, maxNum, name, className } = this.props;
    const { showCameraUpload } = this.state;
    const num = this.state.photoNum.length;
    const { imgError } = this.state;
    let buttonHide = '';
    let imgHide = '';
    if (num >= maxNum) {
      buttonHide = 'hide';
    }
    if (imgError) {
      buttonHide = '';
      imgHide = 'hide';
    }
    const type = this.props.fileType || 'truck';
    const _disabled = this.props.disabled || false;
    let _html = '';
    if (!_disabled) {
      _html = (
        <div className="transform-fileupload-wrap">
          {this.props.selectFileShow ? (
            <div
              className={`${buttonHide} transform-fileupload`}
              onDragEnter={this.handleDragEnter}
              onDragLeave={this.handleDragLeave}
              onDrop={this.handleDrop}
              onDragOver={this.handleDragOver}
              onClick={this.handleClick}
            >
              <Icon iconType="icon-upload1" />
              <span className="icon-title">点击/拖拽上传</span>
              <span className="upload-img-tip">(最多{maxNum}张)</span>
            </div>
          ) : null}

          {
            //     showCameraUpload ? <div className={`${buttonHide} transform-fileupload`} onClick={this.handleOpenCamera}>
            //   <Icon iconType="icon-photo" />
            //   <span className="icon-title" >拍照上传</span>
            //   <span className="upload-img-tip" >(最多{maxNum}张)</span>
            //   {this.state.loading && <div className="loading">
            //     <Icon key="icon_spinner" iconType="icon-spinner" />
            //   </div>}
            // </div> : null,
          }
        </div>
      );
    }

    const wrapClass = `div-wrap ${className} ${name}`;

    return (
      <div className={wrapClass}>
        {(labelText || tips) && (
          <div className="label-text">
            <p>
              <em className={`${isRequired ? 'red-txt' : ''}`}>{labelText}</em>
              <span>{tips}</span>
            </p>
          </div>
        )}
        <div className="imgs-area">
          {_html}
          <div className="img-btn-wrap clearfix" ref={r => (this.imgWrap = r)}>
            {this.state.photoNum.reduce((prev, item, index) => {
              const _item = item;
              const _photoImg = this.state.photoImg[_item];
              let img = null;
              let _viewSrc = '';
              if (_photoImg.src) {
                img = (
                  <img
                    alt=""
                    width="100%"
                    src={_photoImg.src}
                    data-name={this.state.photoImg[_item].name}
                    onError={this.imgError}
                  />
                );
                _viewSrc = _photoImg.src;
              } else {
                const photoSrc = genImageURL({ type, ..._photoImg });
                img = <img alt="" width="100%" src={photoSrc} />;
                _viewSrc = photoSrc;
              }
              let _delButton = (
                <i
                  className="fn-icon fn-icon-del"
                  ref={r => (this.imgDelete = r)}
                  data-name={this.state.photoImg[_item].name}
                  onClick={this.handleRemove}
                />
              );
              if (_disabled) {
                _delButton = '';
              }
              prev.push(
                <div className={`${imgHide} img-block`} key={index}>
                  {img}
                  <div className="img-control" onClick={() => (_delButton ? null : this.handleView(_viewSrc))}>
                    <i
                      className="fn-icon fn-icon-ps-show"
                      onClick={() => (_delButton ? this.handleView(_viewSrc) : null)}
                    />
                    {_delButton}
                  </div>
                </div>,
              );
              return prev;
            }, [])}
          </div>
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            multiple={maxNum - num > 1}
            data-name="photo"
            ref={r => (this.myTextInput = r)}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}
