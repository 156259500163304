/**
 * Created by JANY on 2017/10/10.
 */
import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { isEmptyObj } from 'utils';
import { AddrSug } from 'components';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';

const defaultAddrObj = {
  show_val: '',
  province: '',
  city: '',
  district: '',
  adcode: '',
  poi: '',
};
export default class AddrSugCell extends TableCell {
  static propTypes = {
    handleSelected: PropTypes.func,
    handleChange: PropTypes.func,
    onBlur: PropTypes.func,
    mode: PropTypes.string,
    placeholder: PropTypes.string,
    mapIcon: PropTypes.string,
    dropListWidth: PropTypes.number,
    disabled: PropTypes.bool,
    blurCheck: PropTypes.bool,
  };
  handleSelected = data => {
    const { columnKey, rowIndex } = this.props;
    this.props.handleSelected && this.props.handleSelected(rowIndex, columnKey, data);
  };
  handleChange = data => {
    const { columnKey, rowIndex } = this.props;
    this.props.handleChange && this.props.handleChange(rowIndex, columnKey, data);
  };

  onBlur = (selectedPoiData, fieldName, e) => {
    const { columnKey, rowIndex } = this.props;
    const proxy = { selectedPoiData, fieldName, e };
    this.props.onBlur && this.props.onBlur(rowIndex, columnKey, proxy);
  };

  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, tableKey } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const { placeholder, mapIcon, dropListWidth, disabled, mode, blurCheck, fullName = false } = columnProps;
    let defaultValue = data.getShowObjectAt(rowIndex)[columnKey];
    if (isEmptyObj(defaultValue)) {
      defaultValue = defaultAddrObj;
    }
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <AddrSug
          dataPath={`${tableKey}_${columnKey}_${rowIndex}`}
          handleSelected={this.handleSelected}
          onChange={this.handleChange}
          disabled={disabled || isInOtherPropsArray(data.getObjectAt(rowIndex), columnKey, 'disable')}
          mode={mode || 'address'}
          mapIcon={mapIcon || ''}
          fullName={!!fullName}
          dropListWidth={dropListWidth || 220}
          placeholder={placeholder || ''}
          defaultValue={defaultValue}
          blurCheck={blurCheck}
          onBlur={this.onBlur}
        />
      </Cell>
    );
  }
}
