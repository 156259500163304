/**
 * Created by Aceh on 2017/01/01.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Tips, Badge } from 'components';
import { deleteProps } from 'utils';
import { prefixCls } from './index.scss';

const tmsPrefix = 'fn';
export default class Icon extends PureComponent {
  static defaultProps = {
    classname: '',
    disabled: false,
    mode: 'icon', // icon word img (svg ，文字，图片)
    content: '', // 文字模式对应内容
    cls: '', // 颜色样式
    isBadge: false,
    badgeCount: 0,
    badgeClass: '',
  };
  static propTypes = {
    classname: PropTypes.string,
    iconType: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    onClick: PropTypes.func,
    tips: PropTypes.any,
    isHtmlTips: PropTypes.bool,
    mode: PropTypes.string,
    content: PropTypes.string,
    tipsProps: PropTypes.object,
    cls: PropTypes.string,
    tipsCls: PropTypes.string,
    tipsStyle: PropTypes.object,
    tipsDir: PropTypes.string,
    tipsType: PropTypes.string,
    isBadge: PropTypes.bool, // 是否在icon右上角有徽章标识
    badgeCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 徽章标识上的数字
    badgeClass: PropTypes.string,
  };

  render() {
    const {
      classname,
      iconType,
      disabled,
      type,
      mode,
      content,
      tips,
      isHtmlTips,
      cls,
      tipsCls,
      tipsDir,
      tipsProps,
      tipsStyle,
      tipsType,
      isBadge,
      badgeCount,
      badgeClass,
      ...rest
    } = this.props;
    const _props = deleteProps(rest, 'getPaths');
    let classes = classnames({
      [prefixCls]: true,
      [classname]: classname,
      [`${tmsPrefix}-${iconType}`]: iconType,
      [`${prefixCls}--disabled`]: disabled,
      [`${prefixCls}--${type}`]: type,
    });
    let iconObj = <i {..._props} className={classes} onClick={this.onClick} />;
    switch (mode) {
      case 'icon':
        iconObj = <i {..._props} className={classes} onClick={this.onClick} />;
        break;
      case 'word':
        // type = _word_icon
        classes = classnames({
          [classname]: classname,
          [`${prefixCls}_word_icon`]: true,
          [`${cls}`]: true,
          [`${prefixCls}--disabled`]: disabled,
        });
        iconObj = (
          <span {..._props} className={classes} onClick={this.onClick}>
            {content}
          </span>
        );
        break;
      case 'img':
        // TODO 未实现
        iconObj = <i {..._props} className={classes} onClick={this.onClick} />;
        break;
      default:
        iconObj = <i {..._props} className={classes} onClick={this.onClick} />;
        break;
    }
    if (tips !== undefined && tips !== '') {
      let title = tips;
      if (isHtmlTips) {
        const html = { __html: tips };
        title = <span dangerouslySetInnerHTML={html} />;
      }
      iconObj = (
        <Tips
          title={title}
          className={tipsCls}
          tipOnContent
          dir={tipsDir}
          type={tipsType}
          {...tipsStyle}
          {...tipsProps}
        >
          {iconObj}
        </Tips>
      );
    }
    if (!isBadge) {
      return iconObj;
    }
    return (
      <Badge className={badgeClass} count={+badgeCount}>
        {iconObj}
      </Badge>
    );
  }

  onClick = e => {
    e.target && !e.target.value && (e.target.value = this.props.iconType);
    this.props.onClick && this.props.onClick(e);
  };
}
