exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-lazy-render{width:100%;height:100%;background-color:rgba(0,0,0,.3)}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-lazy-render",
	"fn-lazy-render": "fn-lazy-render"
};