/**
 * Created by JANY on 2016/12/2.
 */
import PropTypes from 'prop-types';

import React, { Component } from 'react';

const Plugin = plugins => ComponsedComponent =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ist: {},
      };
    }

    static childContextTypes = {
      pluginCb: PropTypes.func,
    };
    getChildContext = () => ({ pluginCb: this._getPlugins });
    // 获取已注册组件的实例
    _getPlugins(map) {
      const istObj = {};
      plugins.map(plugin =>
        map.plugin(`AMap.${plugin.name}`, () => {
          const p = new window.AMap[plugin.name](plugin.cfg);
          istObj[plugin.name] = p;
        }),
      );
      this.setState({
        ist: istObj,
      });
    }

    render() {
      return <ComponsedComponent {...this.props} plugin={this.state.ist} />;
    }
  };
Plugin.contextTypes = {
  pluginCb: PropTypes.func,
};
Plugin.propTypes = {
  pluginCb: PropTypes.func,
};
Plugin.defaultProps = {};
Plugin.loadPlugin = (plugins, cb) => {
  const p = plugins.filter(item => !window.AMap[item.split('.')[1]]);
  if (p.length) {
    window.AMap.plugin(p, () => cb && cb());
  } else {
    cb && cb();
  }
};
export default Plugin;
