import * as React from 'react';
import * as moment from 'moment';
import { omit, interopDefault, generateShowHourMinuteSecond } from '../../DatePicker/utils';
import RcTimePicker from 'rc-time-picker/lib/TimePicker';
import classNames from 'classnames';
import Icon from '../../icon/Icon';
import './index.scss';

class TimePicker extends React.Component {
  static defaultProps = {
    align: {
      offset: [0, -2],
    },
    disabled: false,
    disabledHours: undefined,
    disabledMinutes: undefined,
    disabledSeconds: undefined,
    hideDisabledOptions: false,
    placement: 'bottomLeft',
    transitionName: 'slide-up',
    focusOnOpen: true,
  };

  updateState(nextProps) {
    if ('value' in nextProps) {
      return { value: nextProps.value };
    }
    return null;
  }

  timePickerRef;

  constructor(props) {
    super(props);
    const value = props.value || props.defaultValue;
    if (value && !interopDefault(moment).isMoment(value)) {
      throw new Error('The value/defaultValue of TimePicker must be a moment object');
    }
    this.state = {
      value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const state = this.updateState(nextProps);
    state && this.setState(state);
  }

  handleChange = value => {
    if (!('value' in this.props)) {
      this.setState({ value });
    }
    const { onChange, format = 'HH:mm:ss' } = this.props;
    if (onChange) {
      onChange(value, (value && value.format(format)) || '');
    }
  };

  handleOpenClose = ({ open }) => {
    const { onOpenChange } = this.props;
    if (onOpenChange) {
      onOpenChange(open);
    }
  };

  saveTimePicker = timePickerRef => {
    this.timePickerRef = timePickerRef;
  };

  focus() {
    this.timePickerRef.focus();
  }

  blur() {
    this.timePickerRef.blur();
  }

  getDefaultFormat() {
    const { format, use12Hours } = this.props;
    if (format) {
      return format;
    } else if (use12Hours) {
      return 'h:mm:ss a';
    }
    return 'HH:mm:ss';
  }

  getAllowClear() {
    const { allowClear, allowEmpty } = this.props;
    if ('allowClear' in this.props) {
      return allowClear;
    }
    return allowEmpty;
  }

  renderInputIcon(prefixCls) {
    const { suffixIcon } = this.props;
    const clockIcon = (suffixIcon &&
      React.isValidElement(suffixIcon) &&
      React.cloneElement(suffixIcon, {
        className: classNames(suffixIcon.props.className, `${prefixCls}-clock-icon`),
      })) || <Icon iconType="icon-time" className={`${prefixCls}-clock-icon`} />;

    return <span className={`${prefixCls}-icon`}>{clockIcon}</span>;
  }

  renderClearIcon(prefixCls) {
    // const { clearIcon } = this.props

    const clearIconPrefixCls = `${prefixCls}-clear`;

    // if (clearIcon && React.isValidElement(clearIcon)) {
    //   return React.cloneElement(clearIcon, {
    //     className: classNames(clearIcon.props.className, clearIconPrefixCls),
    //   })
    // }
    return (
      <span className={`${prefixCls}-icon`}>
        <Icon type="icon-error-o" classname={clearIconPrefixCls} theme="filled" />
      </span>
    );
  }

  getDefaultLocale = () => {
    const defaultLocale = {
      placeholder: '请选择时间',
      ...this.props.locale,
    };
    return defaultLocale;
  };

  render() {
    const { getPopupContainer, prefixCls: customizePrefixCls, className, addon, placeholder, ...props } = this.props;
    const locale = this.getDefaultLocale();
    const { size } = props;
    const pickerProps = omit(props, ['defaultValue', 'suffixIcon', 'allowEmpty', 'allowClear']);

    const format = this.getDefaultFormat();
    const prefixCls = 'ant-time-picker';
    const pickerClassName = classNames(className, {
      [`${prefixCls}-${size}`]: !!size,
    });

    const pickerAddon = panel => (addon ? <div className={`${prefixCls}-panel-addon`}>{addon(panel)}</div> : null);

    return (
      <RcTimePicker
        {...generateShowHourMinuteSecond(format)}
        {...pickerProps}
        allowEmpty={this.getAllowClear()}
        prefixCls={prefixCls}
        getPopupContainer={getPopupContainer}
        ref={this.saveTimePicker}
        format={format}
        className={pickerClassName}
        value={this.state.value}
        placeholder={placeholder === undefined ? locale.placeholder : placeholder}
        onChange={this.handleChange}
        onOpen={this.handleOpenClose}
        onClose={this.handleOpenClose}
        addon={pickerAddon}
        inputIcon={this.renderInputIcon(prefixCls)}
        clearIcon={this.renderClearIcon(prefixCls)}
      />
    );
  }
}

export default TimePicker;
