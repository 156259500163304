export function formatDate(value, format) {
  let fmt = format;
  if (!value) {
    return '';
  }
  if (Array.isArray(format)) {
    fmt = format[0];
  }
  return value.format(fmt);
}

export function omit(obj, fields) {
  const shallowCopy = {
    ...obj,
  };
  for (let i = 0; i < fields.length; i++) {
    const key = fields[i];
    delete shallowCopy[key];
  }
  return shallowCopy;
}
export function interopDefault(m) {
  return m.default || m;
}
export function getDataOrAriaProps(props) {
  return Object.keys(props).reduce((prev, key) => {
    const cur = prev;
    if (
      (key.substr(0, 5) === 'data-' || key.substr(0, 5) === 'aria-' || key === 'role') &&
      key.substr(0, 7) !== 'data-__'
    ) {
      cur[key] = props[key];
    }
    return cur;
  }, {});
}
export function getPrefixCls(suffixCls, customizePrefixCls) {
  if (customizePrefixCls) return customizePrefixCls;

  return `ant-${suffixCls}`;
}
export function generateShowHourMinuteSecond(format) {
  // Ref: http://momentjs.com/docs/#/parsing/string-format/
  return {
    showHour: format.indexOf('H') > -1 || format.indexOf('h') > -1 || format.indexOf('k') > -1,
    showMinute: format.indexOf('m') > -1,
    showSecond: format.indexOf('s') > -1,
  };
}
