/**
 * Created by jany on 2017/06/16.
 */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { prefixCls } from './index.scss';
import { emptyFunction, typeIs, EventListener, throttle } from 'utils';

export default class LazyRender extends Component {
  constructor(props) {
    super(props);
    this.state = { isShow: !!props.isShow };
    this.direction = props.direction;
    this.poiProps = { style: { width: 'auto' } };
  }

  static propTypes = {
    isShow: PropTypes.bool,
    getDomNode: PropTypes.func.isRequired, // 相对于谁的位置
    getContentNode: PropTypes.func, // 内容节点
    direction: PropTypes.string, // left right  left_up right_up left_down right_down left-middle right-middle
    autoPosition: PropTypes.bool,
    isAutoHeight: PropTypes.bool,
    contentData: PropTypes.any,
    children: PropTypes.any,
    scrollHide: PropTypes.bool, // 滚动是否隐藏
    autoHide: PropTypes.bool, // 点击空处是否隐藏
    autoColWidth: PropTypes.bool, // 内容宽度自适应
    onOpen: PropTypes.func, // 展开回调
    onClose: PropTypes.func, // 收起回调
    onDirectionChange: PropTypes.func, // 展开方向变化回调
  };
  static defaultProps = {
    direction: 'left_down',
    getContentNode: emptyFunction,
    autoPosition: true,
    autoColWidth: false,
    scrollHide: true,
    autoHide: true,
    contentData: [],
  };

  componentDidUpdate() {
    // let dataChanged = Object.entries(this.props.contentData).length !== Object.entries(preProps.contentData).length
    if (this.state.isShow) {
      setTimeout(() => {
        // 此处不更新 则引起：下拉内容不更新
        // console.log('lazyrender didupdate', this.poiProps.style.width, 'dataChanged', dataChanged)
        this.setContent(true);
      });
    }
  }

  componentWillUnmount() {
    this.removeEvent();
    this.container && document.body.removeChild(this.container);
    this.container = null;
    // this.remove()
  }

  appendMaskIntoDoc() {
    if (!this.container || !this.props.children) return;
    // console.log('lazyrender update', this.poiProps.style.width)
    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      <div className={`${prefixCls} ${this.poiProps.animateCls || ''}`}>
        {React.cloneElement(this.props.children, { style: { ...this.poiProps.style } })}
      </div>,
      this.container,
    );
  }

  setContent = (dataChanged = false) => {
    if (!this.container || !this.props.children) return;
    if (dataChanged) {
      // 数据变更时 渲染变更后的数据再重新定位 位置和尺寸
      this.poiProps.style.width = 'auto';
      this.appendMaskIntoDoc();
    }
    this.setPosition();
    this.appendMaskIntoDoc();
    if (this.direction !== this.props.direction) {
      this.props.onDirectionChange && this.props.onDirectionChange(this.direction);
    }
  };
  bindEvent = () => {
    this.scrollListener = EventListener.listen(
      window,
      'resize',
      throttle(() => {
        this.setContent();
      }, 100),
    );
    this.resizeListener = EventListener.capture(
      document,
      'scroll',
      throttle(e => {
        if (
          this.state.isShow &&
          this.container &&
          this.props.getDomNode() &&
          !this.container.contains(e.target) &&
          !this.props.getDomNode().contains(e.target)
        ) {
          if (this.props.autoHide && this.props.scrollHide) {
            this.handleHide();
          } else if (this.state.isShow) {
            this.setContent();
          }
        }
      }, 100),
    );
    this.docClickListener = EventListener.capture(document, 'click', e => {
      if (
        this.state.isShow &&
        this.container &&
        this.props.getDomNode() &&
        this.props.autoHide &&
        !this.container.contains(e.target) &&
        !this.props.getDomNode().contains(e.target)
      ) {
        this.handleHide();
      }
    });
  };
  removeEvent = () => {
    this.scrollListener && this.scrollListener.remove();
    this.resizeListener && this.resizeListener.remove();
    this.docClickListener && this.docClickListener.remove();
  };
  handleShow = () => {
    // if (this.state.isShow && this.container) return
    // 权限- 数据权限里 dropListAutoHide false时，
    // selectdrop 通过isShow 改变droplist的显隐
    if (this.container) return;
    this.setState({ isShow: true });
    this.container = document.createElement('div');
    document.body.appendChild(this.container);
    this.setContent(true);
    this.bindEvent();
    this.props.onOpen && this.props.onOpen(this);
  };
  handleHide = () => {
    if (!this.state.isShow && !this.container) return;
    this.container && document.body.removeChild(this.container);
    this.remove();
    this.container = null;
    this.setState({ isShow: false });
    this.removeEvent();
    this.props.onClose && this.props.onClose(this);
  };
  toggleDisplay = () => (!this.state.isShow ? this.handleShow() : this.handleHide());
  remove = () => this.container && ReactDOM.unmountComponentAtNode(this.container);
  positionToStyle = position => {
    const style = { left: position.left, right: 0, top: position.top, bottom: 0 };
    style.right = window.innerWidth - position.right;
    style.bottom = window.innerHeight - position.bottom;
    return style;
  };
  setPosition = () => {
    const realDOM = this.props.getDomNode() || { getBoundingClientRect: emptyFunction };
    const contentDOM = this.props.getContentNode() || { getBoundingClientRect: emptyFunction };
    const { direction = 'left_down', autoPosition } = this.props;
    let targetPosition = realDOM.getBoundingClientRect() || {};
    let contentPosition = contentDOM.getBoundingClientRect() || {};
    const { offsetWidth: targetWidth = 0, offsetHeight: targetHeight = 0 } = realDOM;
    const { offsetWidth: contentWidth = 0, offsetHeight: contentHeight = 0 } = contentDOM;
    const isFullScr = window.innerWidth === contentWidth;
    targetPosition = {
      right: targetPosition.right,
      left: targetPosition.left,
      top: targetPosition.top,
      bottom: targetPosition.bottom,
    };
    contentPosition = {
      right: contentPosition.right || 0,
      left: contentPosition.left || 0,
      top: contentPosition.top || 0,
      bottom: contentPosition.bottom || 0,
    };
    // 左下位置
    const isSmall = contentWidth < targetWidth;
    let style = {
      // TODO 先全部去除 document.body.scrollTop
      // top: `${targetPosition.top + document.body.scrollTop + targetHeight}px`,
      top: `${targetPosition.top + targetHeight}px`,
      width: isSmall || isFullScr ? targetWidth : contentWidth, // `${targetWidth}px`,
      left: `${targetPosition.left}px`,
    };
    if (!this.props.isAutoHeight) {
      style.height = `${contentHeight}px`;
    }
    if (!contentWidth || contentWidth === 0) {
      // style 最终样式
      this.poiProps = {
        isSmall,
        contentWidth,
        targetWidth,
        contentStyle: style,
        animateCls: '',
        style: this.getContentStyle(contentWidth, targetWidth, style),
      };
      return;
    }
    contentPosition = {
      ...contentPosition,
      ...targetPosition,
      // left 同target
      right: targetPosition.left + contentWidth,
      top: targetPosition.bottom,
      bottom: targetPosition.bottom + contentHeight,
    };
    // left-middle right-middle
    // 左侧居中 // 右侧居中
    if (direction.indexOf('middle') !== -1) {
      const middleHeight = (contentHeight - targetHeight) / 2;
      style.top = `${targetPosition.top - middleHeight}px`;
      // style.left = `${targetPosition.left}px`
      if (direction.indexOf('right') !== -1) {
        // 右
        style.left = `${targetPosition.right}px`;
        contentPosition = {
          ...contentPosition,
          // left 同target left
          left: targetPosition.right,
          right: targetPosition.right + contentWidth,
          top: targetPosition.top - middleHeight,
          bottom: targetPosition.bottom + middleHeight,
        };
      } else {
        delete style.left;
        style.right = `${window.innerWidth - targetPosition.left}px`;
        contentPosition = {
          ...contentPosition,
          // right 同target right
          left: targetPosition.right,
          right: targetPosition.right - contentWidth,
          top: targetPosition.top - middleHeight,
          bottom: targetPosition.bottom + middleHeight,
        };
      }
    } else {
      // 右下位置
      if (direction.indexOf('right') !== -1) {
        delete style.left;
        style.right = `${window.innerWidth - targetPosition.right}px`;
        contentPosition = {
          ...contentPosition,
          // right 同target
          left: targetPosition.right - contentWidth,
          right: targetPosition.right,
          // bottom: targetPosition.bottom + contentHeight,
        };
      }
      // 上位置
      if (direction.indexOf('up') !== -1) {
        style.top = `${targetPosition.top - contentHeight}px`;
        contentPosition = {
          ...contentPosition,
          // right 同target qwewq
          top: targetPosition.top - contentHeight,
          bottom: targetPosition.top,
        };
      }
    }
    const contentStyle = this.positionToStyle(contentPosition); // right bottom
    if (autoPosition && direction.indexOf('middle') === -1) {
      const { top, left, bottom, right } = contentStyle;
      if (typeIs(top, 'number') && top < 0) {
        if (!(top + 1 >= -1 && top + 1 < 1) || top < -2) {
          // 改为向下
          delete style.bottom;
          style.top = `${targetPosition.top + targetHeight}px`;
          // 如果向下显示不完全 则显示为向上，改变下拉框高度
          if (parseInt(targetPosition.bottom + contentHeight, 10) > window.innerHeight) {
            const realHeight = targetPosition.top;
            style.top = '-1px';
            style.height = `${realHeight + 1}px`;
          }
        }
        return;
      }
      if (typeIs(bottom, 'number') && bottom < 0) {
        if (!(bottom + 1 >= -1 && bottom + 1 < 1) || bottom < -2) {
          // 改为向上
          delete style.bottom;
          style.top = `${targetPosition.top - contentHeight}px`;
          // 如果向上显示不完全 则显示为向下，改变下拉框高度
          if (parseInt(style.top, 10) < 0) {
            const realHeight = window.innerHeight - targetPosition.bottom;
            style.top = `${targetPosition.top + targetHeight}px`;
            style.height = `${realHeight + 1}px`;
          }
        }
      }
      // TODO 逻辑待优化
      if (typeIs(left, 'number') && left < 0) {
        console.log('leftout');
        if (window.innerWidth < contentWidth + targetPosition.left && !isFullScr) {
          // 改为居中
          delete style.left;
          style.right = `${(window.innerWidth - contentWidth) / 2}px`;
        } else {
          // 改为左对齐
          delete style.right;
          style.left = `${targetPosition.left}px`;
        }
      }
      if (typeIs(right, 'number') && right < 0) {
        console.log('rightout');
        if (targetPosition.right < contentWidth && !isFullScr) {
          // 改为居中
          delete style.left;
          style.right = `${(window.innerWidth - contentWidth) / 2}px`;
        } else {
          // 改为右对齐
          delete style.left;
          style.right = `${window.innerWidth - targetPosition.right}px`;
        }
      }
      // 内容超出屏幕
      if (window.innerWidth <= style.width) {
        // 内容宽度大于窗口
        delete style.left;
        style.right = `${10}px`;
        style = Object.assign(style, { minWidth: 'initial', width: window.innerWidth - 20 });
      }
      style.maxHeight = window.innerHeight - +style.top.replace('px', '');
    }
    // style 最终样式
    this.poiProps = {
      isSmall,
      contentWidth,
      targetWidth,
      contentStyle: style,
      animateCls: '',
      style: this.getContentStyle(contentWidth, targetWidth, style),
    };
  };
  getContentStyle = (contentWidth, targetWidth, style) => {
    let _style = {};
    if (this.props.autoColWidth) {
      // eslint-disable-next-line no-nested-ternary
      const width = (contentWidth === 0 ? 'auto' : contentWidth < targetWidth ? targetWidth : contentWidth) || 'auto';
      _style = {
        ...(this.props.children.props.style || {}),
        ...style,
        width,
        // opacity: 1,
      };
    } else {
      const childStyle = this.props.children.props.style;
      if (childStyle?.width) {
        _style = {
          ...(childStyle || {}),
          ...style,
          width: childStyle.width,
        };
      } else {
        _style = {
          ...(childStyle || {}),
          ...style,
        };
      }
    }
    return _style;
  };

  render() {
    return null;
  }
}
