import React from 'react';
import { formatDeppProptotype } from 'utils';
import { Select } from 'components';
import Cell from './Cell';
import TableCell from './TableCell';

export default class SelectDropCell extends TableCell {
  handleSelected = data => {
    const { columnKey, rowIndex } = this.props;
    this.props.handleSelected && this.props.handleSelected(rowIndex, columnKey, data);
  };

  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    const { data, colIndex } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const enumData = columnProps.data;
    const dataItem = data.getObjectAt(rowIndex);
    const defaultValue = formatDeppProptotype(columnKey, dataItem);

    const rowProps = dataItem.otherProps && dataItem.otherProps[columnKey];
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <Select
          {...columnProps}
          {...rowProps}
          data-path={`${columnKey}_${rowIndex}_${colIndex}`}
          value={defaultValue === undefined || defaultValue === null ? [] : defaultValue}
          onChange={this.handleSelected}
          data={enumData}
        />
      </Cell>
    );
  }
}
