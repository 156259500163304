exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-page{display:inline-block}.fn-page .fn-btn__group{margin-right:4px;display:none}.fn-page .fn-btn__group .fn-btn{padding:5px 7px;min-width:16px;height:24px}.fn-page .fn-select{margin:0 4px;position:relative;width:auto}.fn-page .control__page{width:34px;display:inline-block;text-align:center;position:relative;top:1px}.fn-page .control__page .fn-icon{cursor:pointer;margin:0 0 0 5px;color:#0097cf;width:12px}.fn-page .control__page .fn-icon.disabled{color:#ccc;pointer-events:none}.fn-page .jump__page{display:inline-block;position:relative;padding:1px 7px 0;top:2px;line-height:22px;margin-left:5px}.fn-page .jump__page:after,.fn-page .jump__page:before{content:\"\";position:absolute;width:1px;height:18px;top:4px;background:#ccc}.fn-page .jump__page:before{left:0}.fn-page .jump__page:after{right:0}.fn-page .jump__page .fn-input{position:relative;top:-1px;width:30px;margin:0 3px;text-align:center}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-page",
	"fn-page": "fn-page",
	"fn-btn__group": "fn-btn__group",
	"fn-btn": "fn-btn",
	"fn-select": "fn-select",
	"control__page": "control__page",
	"fn-icon": "fn-icon",
	"disabled": "disabled",
	"jump__page": "jump__page",
	"fn-input": "fn-input"
};