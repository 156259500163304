import React, { Component } from 'react';
import { Cascader as ArcoCascader, Spin } from '@gui/web-react';
import { prefixCls, prefixMenuCls } from './index.scss';
import { fetchApi as fetch } from 'utils';

export default class Cascader extends Component {
  static defaultProps = {
    size: 'small',
  };

  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      loading: false,
      value: props.value ?? props.defaultValue ?? [],
    };
    if (props.initFetchApi) {
      this.loadMore(this.state.value, this.state.value?.length ? this.state.value?.length : 1, true);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.options !== nextProps.options) {
      this.setState({ options: nextProps.options });
    }
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.setState({ value: nextProps.defaultValue });
      // this.loadMore(nextProps.defaultValue, nextProps.defaultValue?.length ? nextProps.defaultValue?.length : 1, true);
    }
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  filter = async (keyword, pathValue, level) => {
    const { fetchApi } = this.props;

    if (!fetchApi) return;
    if (!keyword) keyword = pathValue?.length ? pathValue[pathValue.length - 1] : '';

    const req = { ...fetchApi.para, path: pathValue, level };
    const conf = { method: 'POST', body: { req } };
    if (fetchApi.queryKey) {
      const query = req[fetchApi.queryKey] || {};
      fetchApi.key && (query[fetchApi.key] = keyword) && (req[fetchApi.queryKey] = query);
    } else if (fetchApi.key) {
      req[fetchApi.key] = keyword;
    }
    if (fetchApi.paraCallback) {
      const paraRes = fetchApi.paraCallback(req, fetchApi.key, keyword, pathValue);
      if (!paraRes) return;
      conf.body.req = paraRes;
    }

    if (this.props.minFetchLen && keyword.length < +this.props.minFetchLen) return;
    return fetch(fetchApi.url, conf).then(res => {
      const data = res?.res;
      return data;
    });
  };

  loadMore = (pathValue, level, init) => {
    if (init) this.setState({ loading: true });
    const promiseFn = this.filter('', pathValue, level)?.then?.(options => {
      if (init) {
        this.setState({
          options: [...options],
        });
      }
      return options;
    });
    promiseFn?.finally?.(e => this.setState({ loading: false }));
    return promiseFn;
  };

  onChange = (...rest) => {
    this.props.onChange?.(...rest);
    this.props.handleSelected?.(...rest);
  };

  onClick = async (...rest) => {
    if (!this.state.options?.length) {
      this.loadMore([], 1, true);
    }
    this.props.onClick?.(...rest);
  };

  render() {
    const { size, fetchApi, ...otherProps } = this.props;
    return (
      <ArcoCascader
        {...otherProps}
        value={this.state.value}
        loading={this.state.loading}
        options={this.state.options}
        className={`${prefixCls}`}
        size={size}
        arrowIcon={<i className="input-icon toggle-icon" />}
        dropdownMenuClassName={`${prefixMenuCls}`}
        onChange={this.onChange}
        onClick={this.onClick}
        loadMore={fetchApi ? this.loadMore : undefined}
        dropdownRender={menu => {
          return this.state.loading ? (
            <div
              style={{
                height: 100,
                width: 200,
                textAlign: 'center',
                lineHeight: '100px',
              }}
            >
              <Spin />
            </div>
          ) : (
            menu
          );
        }}
      />
    );
  }
}
