import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { deleteProps, getPageQueryKey, EventListener } from 'utils';
import { Provider } from 'react-redux';
import store from 'store';
import { ShowInfo } from 'components';
import { INFO, EMIT_EVENT } from 'constants';
import { prefixCls } from './index.scss';

const POPCLASS = {
  PopTip: '_tips', // _max
  ShowInfo: '_info', // _max
  MapDialog: '_dialog',
  PopupTypeset: '_dialog',
  StaticTable: '_hide',
};
const popArr = []; // 存储页面上的打开的popup
const popArrNew = []; // 存储页面上的打开的popup
let popDialog = null; // 编辑类页面的提示保存弹框
let keydownListener = null; // pop
function removeByValue(arr, val) {
  // 删除数组中指定项
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === val) {
      arr.splice(i, 1);
      return;
    }
  }
}

export default class PopUp {
  constructor(Component, props = {}) {
    const popClass = POPCLASS[Component.displayName] || props.popClass;
    const modalCls = props.isModal ? '_modal' : '';
    const defaultProps = {
      isModal: false, // 是否全屏 否则当前页面
      readyShow: undefined,
      readyClose: undefined,
      popName: undefined,
      popPageKey: getPageQueryKey(),
      popClassname: popClass ? `${prefixCls}${popClass}${modalCls} ${prefixCls}` : `${prefixCls}`,
      escBind: true,
    };
    if (props.popName !== undefined) {
      const lastPop = popArrNew.find(item => item.container === document.getElementById(props.popName));
      if (lastPop) {
        lastPop.Component = Component;
        lastPop.props = Object.assign(defaultProps, props);
        lastPop.isContainerMax = Component.name === 'PopTip';
        return lastPop;
      }
    }
    this.defaultProps = defaultProps;
    this.Component = Component;
    this.props = Object.assign(this.defaultProps, props);
    this.container = null;
    this.isContainerMax = Component.name === 'PopTip';
    this.isEdited = () => false;
    this.props.escBind && this.bindKeydown();
  }

  static propTypes = {
    isModal: PropTypes.bool,
    popName: PropTypes.string,
    readyClose: PropTypes.func,
    readyShow: PropTypes.func,
    popClassname: PropTypes.string,
    onClose: PropTypes.func,
    togglePopWidth: PropTypes.func,
    escBind: PropTypes.bool,
  };
  closePopUp = popName => {
    // 关闭当前页面的侧拉 目前根据业务需求简单处理 只考虑当前页面只有一个侧拉的情况  业务页面：平台账户->交易记录->统计
    const container = document.getElementById(popName);
    this.removePopUp(container);
  };
  getIsEdited = that => {
    that.isEdited && (this.isEdited = that.isEdited);
  };
  editedDialog = (popup, newPopInstance) => {
    popDialog = new PopUp(ShowInfo, {
      classname: '',
      isShow: true,
      type: INFO,
      content: '当前页面数据未保存，是否离开？',
      title: '提示',
      closable: true,
      maskClosable: true,
      simpleMode: {
        confirm: thisDialog => {
          thisDialog.handleHide();
          popDialog = null;
          if (newPopInstance) {
            // 替换
            newPopInstance.removePopContent();
            newPopInstance.mountComponent(true);
          } else {
            // 直接关闭
            this.removePopUp(popup);
          }
        },
        cancel: thisDialog => {
          thisDialog.handleHide();
          popDialog = null;
        },
      },
    });
    return popDialog;
  };
  show = () => {
    if (!popDialog && this.isEdited()) {
      this.editedDialog(this.container, this).show();
    } else {
      this.removePopContent();
      if (typeof this.props.readyShow === 'function') {
        this.props.readyShow(() => {
          this.mountComponent();
        });
      } else {
        this.mountComponent();
      }
    }
    return this;
  };
  // 关闭 带回调
  close = callback => {
    popDialog = null;
    if (typeof this.props.readyClose === 'function') {
      this.props.readyClose(() => {
        this.props.onClose && this.props.onClose();
        popArr.length && this.removePopUp(this.container);
      });
    } else {
      const aaa = this.props.onClose && this.props.onClose();
      popArr.length && this.removePopUp(this.container);
    }
    callback && typeof callback === 'function' && callback();
  };
  togglePopWidth = (max, callback) => {
    // target 暂为bool
    let containerClass = !this.isContainerMax ? `${prefixCls}_max ${prefixCls}` : `${prefixCls}`;
    if (typeof max !== 'undefined') {
      containerClass = max ? `${prefixCls}_max ${prefixCls}` : `${prefixCls}`;
      this.container.style.width = '100%'; // slider初始化改变了popup宽度为auto，最大化时需设置为100%,否则width为0
    }
    this.container.className = containerClass;
    // popup宽度变化时候的回调
    // if (this.props.togglePopWidth && typeof this.props.togglePopWidth === 'function') this.props.togglePopWidth()
    callback && typeof callback === 'function' && callback();
  };
  getParentNode = () => {
    let parentNode = document.body;
    if (!this.props.isModal) {
      const tab = document.querySelector('.r_indexpage_main > .tabs-active-content');
      const modalPage = document.querySelector('.modal-page--active');
      parentNode = modalPage || tab || parentNode;
    }
    return parentNode;
  };
  // 相同的侧拉只创建一个pop div
  // isChecked 复用同名pop时 检验是否有改动 true为已经校验过
  createSingleContainer = isChecked => {
    let container;
    if (!isChecked) {
      window.emt && window.emt.emit(EMIT_EVENT.DOCUMENT_ESC_KEYDOWN, this);
    } else {
      if (popDialog) {
        return;
      }
      const popUps = document.querySelectorAll('.tabs-active-content .popup_wrap');
      container = document.getElementById(this.props.popName);
      container.id = this.props.popName;
      // 当页面有多个侧拉切换列表侧拉时需要销毁当前页面上的其它侧拉，
      // 解决侧拉上面有侧拉的情况跟换列表时会被上面的盖住的问题
      if (popUps.length > 1) {
        const otherPopUp = popArr;
        removeByValue(otherPopUp, container);
        for (let i = 0; i < otherPopUp.length; i++) {
          // 此处立即执行remove  而animateClass延迟后移除时将报错
          this.removePopUp(otherPopUp[i]);
        }
      }
      this.removePopContent(container, '.slide__drager');
      // slider && ReactDOM.unmountComponentAtNode(container.querySelector('.slide__drager')) // 销毁侧拉内容
      // container.innerHTML = ''
    }
    return container;
  };
  appendContainer = container => {
    const parentNode = this.getParentNode();
    parentNode.appendChild(container);
  };
  getContainer = isChecked => {
    let container;
    if (this.props.popName === undefined || !document.getElementById(this.props.popName)) {
      // 侧拉里是一个列表页
      container = document.createElement('div');
      container.className = this.props.popClassname;
      this.props.popName && (container.id = this.props.popName);
      popArr.push(container); // 存储popup
      popArrNew.push(this);
    } else {
      container = this.createSingleContainer(isChecked);
    }
    container && this.appendContainer(container);
    // 此处 因getContainer 会被多次 且异步执行
    // 而getContainer 又只是在container为空时才需要执行 故此
    !this.container && (this.container = container);
    return container;
  };
  removePopContent = (container = this.container) => {
    if (container) {
      // let slider = container.querySelector(compSelector || '.slide__drager')
      ReactDOM.unmountComponentAtNode(container); // 销毁侧拉内容
      container.innerHTML = ''; // eslint-disable-line
    }
    return true;
  };
  removePopUp = container => {
    if (!container) return false;
    try {
      ReactDOM.unmountComponentAtNode(container);
    } catch (err) {
      console.log(err);
    }
    container.parentNode && container.parentNode.removeChild(container);
    removeByValue(popArr, container);
    removeByValue(popArrNew, this);
    window.emt && window.emt.off(EMIT_EVENT.DOCUMENT_ESC_KEYDOWN, this.escClose);
    return true;
  };
  mountComponent = isChecked => {
    if (this.container) {
      this._mountComponent();
    } else if (this.getContainer(isChecked)) {
      this._mountComponent();
    }
  };
  _mountComponent = () => {
    let props = deleteProps(this.props, 'readyClose', 'onClose', 'readyShow', 'popClassname', 'popPageKey');
    props = Object.assign(props, {
      close: this.close,
      togglePopWidth: this.togglePopWidth,
      popContainer: this.container,
      getIsEdited: this.getIsEdited,
    });
    let popComponent = <this.Component {...props} />;
    if (React.isValidElement(this.Component)) {
      popComponent = React.cloneElement(this.Component, props);
    }
    ReactDOM.render(<Provider store={store}>{popComponent}</Provider>, this.container);
  };
  bindKeydown = () => {
    !keydownListener &&
      (keydownListener = EventListener.listen(document, 'keydown', e => {
        const ev = e || window.event;
        if (ev.keyCode === 27) {
          window.emt && window.emt.emit(EMIT_EVENT.DOCUMENT_ESC_KEYDOWN);
        }
      }));
    this.escClose = this.escClose.bind(this);
    window.emt && window.emt.on(EMIT_EVENT.DOCUMENT_ESC_KEYDOWN, this.escClose);
  };
  escClose = function escClose(newPopInstance) {
    if (!this.props.escBind) return;
    this.props.handleHideCallback && this.props.handleHideCallback();
    if (newPopInstance && document.getElementById(this.props.popName) === this.container) {
      // 复用同一个pop时 container根据popName复用，
      // 检测是否编辑时，定向触发前一个pop实例的关闭相应
      this.checkAndClose(newPopInstance);
    } else {
      if (this.removePopUp(document.querySelectorAll('body > .popup_wrap')[0])) return;
      // 再从本页面开始依次删除popup
      const popUps = document.querySelectorAll('.tabs-active-content .popup_wrap');
      if (!popUps.length) return;
      const lastPopUp = popUps[popUps.length - 1]; // 如果关闭的是侧拉，判断是详情还是编辑
      if (lastPopUp === this.container && document.querySelectorAll('.fn-lazy-render').length === 0) {
        // 下拉组件，打开状态时存在，esc优先关闭它
        this.checkAndClose();
      }
    }
  };
  checkAndClose = newPopInstance => {
    // 传了isEdited说明是编辑的页面,返回ture 说明编辑过了
    if (!popDialog && this.isEdited()) {
      this.editedDialog(this.container, newPopInstance).show();
    } else {
      // 详情页或者弹框直接关闭
      popDialog = null;
      // 复用同一个popcontainer时， 通过emit事件会传入新pop实例
      if (newPopInstance) {
        // 移除监听
        window.emt && window.emt.off(EMIT_EVENT.DOCUMENT_ESC_KEYDOWN, this.escClose);
        newPopInstance.mountComponent(true);
      } else {
        this.removePopUp(this.container);
      }
    }
  };
}
