/**
 * Created by JANY on 2016/12/2.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Core from './utils/core';
import LoadMap from './LoadMap';
import './index.scss';

@LoadMap
class MapShow extends Component {
  constructor(props) {
    super(props);
    const mapId = `amap_${Core.getRandom()}`;
    this.state = {
      mapId,
      // eslint-disable-next-line react/no-unused-state
      currMap: null,
      // eslint-disable-next-line react/no-unused-state
      maker: [],
    };
  }

  static defaultProps = {
    width: 767,
    height: 383,
    showRoadConditions: true,
    // width: 600,
    // height: 200,
    // 缩放比例
    zoom: 10,
    // 中心点
    center: [116.397, 39.908],
    // ...
    maker: [],
  };
  static contextTypes = {
    pluginCb: PropTypes.func,
    serviceCb: PropTypes.func,
    service: PropTypes.object,
  };
  static propTypes = {
    onMapLoaded: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showRoadConditions: PropTypes.bool,
    maker: PropTypes.array,
    service: PropTypes.object,
    zoom: PropTypes.number,
    center: PropTypes.array,
  };

  componentDidMount() {
    if (window.AMap) {
      const { mapId } = this.state;
      const { pluginCb, serviceCb } = this.context;
      // 初始化地图
      const currMap = this.initMap(mapId, this.props);
      // this.setState({ currMap })
      this.currMap = currMap;
      // 初始化插件
      pluginCb && pluginCb.call(this, currMap);
      // 初始化服务
      serviceCb && serviceCb.call(this, currMap);
    } else {
      const inits = Object.assign({}, { winInit: window.init });
      window.init = () => {
        inits.winInit && inits.winInit();
        // 初始化amap
        if (!window.AMap) {
          console.error('AMap is required');
        } else {
          const { mapId } = this.state;
          const { pluginCb, serviceCb } = this.context;
          // 初始化地图
          const currMap = this.initMap(mapId, this.props);
          // this.setState({ currMap })
          this.currMap = currMap;
          // 初始化定位点
          // const maker = this.initMaker(currMap, this.props.maker)
          // 初始化插件
          pluginCb && pluginCb.call(this, currMap);
          // 初始化服务
          serviceCb && serviceCb.call(this, currMap);
        }
      };
    }
  }

  onMapLoaded = currMap => {
    this.props.onMapLoaded && this.props.onMapLoaded(currMap);
  };
  // 初始化地图
  initMap = (id, cfg) => {
    const _cfg = {};
    // 获取纯cfg
    Object.getOwnPropertyNames(cfg).map(name => {
      if (MapShow.defaultProps[name] !== undefined) {
        _cfg[name] = cfg[name];
      }
      return true;
    });
    if (!window.AMap) return null;
    const aMap = new window.AMap.Map(this.state.mapId, _cfg);
    this.onMapLoaded(aMap);
    return aMap;
  };
  getMap = () => this.currMap;

  handleSetDefaultRoadConditions() {
    this.props.handleSetDefaultRoadConditions();
  }

  render() {
    const { width, height, showRoadConditions, defaultRoadConditionsIcon } = this.props;
    return (
      <div id={this.state.mapId} style={{ width, height }}>
        {showRoadConditions && (
          <div
            className={`${defaultRoadConditionsIcon} road-conditions`}
            onClick={this.handleSetDefaultRoadConditions.bind(this)}
          >
            <i className="fn-icon fn-icon-lukuang" />
          </div>
        )}
      </div>
    );
  }
  // 初始化定位点
  initMaker = (cMap, cfg) => {
    // 设置maker
    const maker = cfg.map(m => {
      const newMaker = new window.AMap.Marker(m);
      newMaker.setMap(cMap);
      return newMaker;
    });
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      maker,
    });
    return maker;
  };

  // 删除定位点
  removeMaker(maker) {
    if (maker) {
      maker.setMap(null);
      // maker = null
    }
  }
}
export default MapShow;
