/**
 * 弹窗批量操作 详情展示部分
 */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { getStrWidth } from 'utils';

export default class ShowList extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      icon: 'fn-icon-arrow-db',
      inline: { height: '18px', marginRight: '20px' },
    };
  }
  static propTypes = {
    list: PropTypes.array,
    style: PropTypes.object,
  };
  toggleHandle = () => {
    const icon = this.state.icon === 'fn-icon-arrow-db' ? 'fn-icon-arrow-dt' : 'fn-icon-arrow-db';
    const inline =
      icon === 'fn-icon-arrow-db'
        ? { height: '18px', marginRight: '20px' }
        : { maxHeight: '100px', overflowY: 'scroll' };
    this.setState({ icon, inline });
  };
  render() {
    const domStr = this.props.list.join('; ');
    const listWidth = getStrWidth(domStr, 12, undefined, undefined, undefined);
    return (
      <div className="detail-content" style={this.props.style}>
        <p className="checkinfo-list" style={this.state.inline}>
          {this.props.list.join('; ')}
          {listWidth > 310 && (
            <i className={`fn-icon ${this.state.icon}`} style={{ top: '8px' }} onClick={this.toggleHandle} />
          )}
        </p>
      </div>
    );
  }
}
