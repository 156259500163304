import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { PureTreeSelect } from 'components';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';

export default class PureTreeSelectCell extends TableCell {
  static propTypes = {
    onChange: PropTypes.func,
  };
  onChange = val => {
    this.props.onChange && this.props.onChange(this.props.rowIndex, this.props.columnKey, val);
  };

  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, tableKey, colIndex } = cellOtherProps;
    const { rowIndex, columnKey, width, height } = cellProps;
    const { compare, data: treeData, enumData, flatTreeData, format, nodeFormat, filter } = columnProps;
    const value = data.getShowObjectAt(rowIndex)[columnKey];
    const style = { width: width - 3, height: height - 2 };
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <PureTreeSelect
          data-path={`${tableKey}_${columnKey}_${rowIndex}_${colIndex}`}
          data={treeData || enumData}
          inputWrapStyle={style}
          flatTreeData={flatTreeData}
          value={value}
          format={format}
          disabled={isInOtherPropsArray(data.getObjectAt(rowIndex), columnKey, 'disable')}
          compare={compare}
          nodeFormat={nodeFormat}
          filter={filter}
          onChange={this.onChange}
        />
      </Cell>
    );
  }
}
