/* eslint-disable no-shadow */
/**
 * Created by jany on 2017/09/03.
 */
import { AsyncTask } from 'components';

// 不可用 ...   只能用Object.assign()
export function preLoadStore(executeData, store, cb) {
  const task = new AsyncTask(exData => {
    // eslint-disable-line
    const {
      d,
      addrow,
      hfData,
      selectes,
      header,
      headerKeys,
      enumerations,
      cellclassMap,
      isPureText,
      weightUnit,
      noThousandSep,
      colorRule,
    } = exData;
    const showingRows = {};
    // const moment = importScripts('https://momentjs.com/downloads/moment.min.js') // eslint-disable-line
    // console.log(moment().format('YYYY-MM-DD'))
    const NUMBER_TYPE = ['long', 'integer', 'short', 'double', 'float', 'currency'];
    const LIST_ARRAY2TEXT_SEPARATE = '，';
    // 重量单位
    const ORDER_W_UNIT_ENMU = {
      T: '吨',
      KG: '千克',
      kg: '千克',
      Kg: '千克',
      G: '克',
      g: '克',
    };
    const getCurrWeightUnit = () => weightUnit || 'KG';
    const getOrderPriceUnitType = () => {
      const CURR_WEIGHT_UNIT = getCurrWeightUnit();
      return {
        per_num: '件', // 每件
        per_v: '方', // 每方
        per_w: `${ORDER_W_UNIT_ENMU[CURR_WEIGHT_UNIT]}`, //  每重量(千克或者吨）
        per_s: '套',
      };
    };
    const UNIT_PRICT_TIMES = {
      KG: 1,
      T: 1000,
      // G: 0.001,
    };
    // const OBJECT_TEXTCELL_GEN_RULE = {
    //   g_unit_price_disp: (value) => value.map(item => {
    //     const unit = getOrderPriceUnitType()[item.unit_p_unit] || '',
    //       CURR_WEIGHT_UNIT = getCurrWeightUnit(),
    //       times = (item.unit_p_unit === 'per_w') ? (UNIT_PRICT_TIMES[CURR_WEIGHT_UNIT] || 1) : 1,
    //       price = item.unit_p * times
    //     return `${price}元/${unit}`
    //   }).join(','),
    // }

    const UNIT_PRICE_PRE_LOADER = value => {
      let allEm = true;
      const unitShow = value.map(item => {
        const unit = getOrderPriceUnitType()[item.unit_p_unit] || '';
        const CURR_WEIGHT_UNIT = getCurrWeightUnit();
        const times = item.unit_p_unit === 'per_w' ? UNIT_PRICT_TIMES[CURR_WEIGHT_UNIT] || 1 : 1;
        const price = CURR_WEIGHT_UNIT === 'KG' ? item.unit_p : +(item.unit_p * times).toFixed(6);
        if (+price === 0) return '';
        allEm = false;
        return `${price}元/${unit}`;
      });
      return allEm ? '' : unitShow.join(',');
    };
    const WEIGHT_UNIT_PRICE_PRE_LOADER = value => {
      let allEm = true;
      const unitShow = value.map(item => {
        const unit = getOrderPriceUnitType().per_w || '';
        const CURR_WEIGHT_UNIT = getCurrWeightUnit();
        const times = UNIT_PRICT_TIMES[CURR_WEIGHT_UNIT] || 1;
        const price = CURR_WEIGHT_UNIT === 'KG' ? item : +(item * times).toFixed(6);
        if (+price === 0) return '';
        allEm = false;
        return `${price}元/${unit}`;
      });
      return allEm ? '' : unitShow.join(',');
    };
    const OBJECT_TEXTCELL_GEN_RULE = {
      // 单价显示
      g_unit_price_disp: value => UNIT_PRICE_PRE_LOADER(value),
    };

    const WEIGHT_UNIT_TEXTCELL_GEN_RULE = {
      g_weight_unit_price: value => WEIGHT_UNIT_PRICE_PRE_LOADER(value),
    };

    function typeIs(object, checkType = false) {
      const type = Object.prototype.toString.call(object).slice(8, -1).toLowerCase();
      if (!checkType) {
        return type;
      }
      return type === checkType.toLowerCase();
    }

    function isEmptyObj(obj) {
      return !(typeIs(obj, 'object') && obj !== null && Object.entries(obj).length > 0);
    }

    function thousandSep(numString = '') {
      return `${numString}`.replace(/\d+/, s => s.replace(/(\d)(?=(\d{3})+$)/g, '$1,'));
    }

    function formatDeppProptotype(paths, obj = {}, spliter = '.') {
      let _value = obj;
      let path = `${paths}`;
      if (path.includes(spliter)) {
        path = path.split(spliter);
        for (let i = 0, len = path.length; i < len; i++) {
          if (typeIs(_value, 'object')) {
            _value = _value[path[i]];
          }
        }
        // TODO 兼容列表中 total不规范的数据 找出有问题的total后可去除_value[path.join(spliter)]
        return _value || obj[path.join(spliter)] || '';
      }
      return obj[path];
    }

    const formatOpList = {
      // 固定位数
      round: (val, f) => {
        if (f && (typeIs(val, 'number') || typeIs(val, 'string')) && !Number.isNaN(Number(val))) {
          return Number(val).toFixed(f); // Math.round(val * Math.pow(10, f)) / Math.pow(10, f),
          // return Number(val) * f
        }
        return val;
      },
      // 乘数
      multiples: (val, f) => {
        if (f && (typeIs(val, 'number') || typeIs(val, 'string')) && !Number.isNaN(Number(val))) {
          return Number(val) * f;
        }
        return val;
      },
      // 除数
      division: (val, f) => {
        if (f && (typeIs(val, 'number') || typeIs(val, 'string')) && !Number.isNaN(Number(val))) {
          return Number(val) / f;
        }
        return val;
      },
      // 零值抹去 return 非number
      zero2null: (val, f) => {
        if (f && !Number.isNaN(Number(val)) && Number(val) === 0) return '';
        return val;
      },
      // 末位零去除
      trim_zero: (val, f) => {
        if (f && (typeIs(val, 'number') || typeIs(val, 'string'))) {
          const numVal = Number(val);
          return Number.isNaN(numVal) ? val : numVal;
        }
        return val;
      },
      // 数字千分位 return 非number
      thousands_sep: (val, f) => {
        if (f && val !== undefined) {
          return thousandSep(val);
        }
        return val;
      },
      // 单位显示显示
      unit: (val, f) => (val ? `${val}${f}` : ''),
      // 日期格式化 return 非number
      datetime: val => val, // (val, f) => moment(val).format(f), // TODO 补充逻辑
      // 合并 return 非number
      join: (val, f) => {
        if (typeIs(val, 'array')) return val.filter(i => i !== '' && i !== undefined).join(f);
        return val;
        // if (typeIs(value[0], 'object') && OBJECT_TEXTCELL_GEN_RULE[columnKey]) {
        //   value = OBJECT_TEXTCELL_GEN_RULE[columnKey](value)
        // } else {
        //   value = value.join(LIST_ARRAY2TEXT_SEPARATE) // 逗号分隔
        // }
      },
    };
    // 数字
    const _numberFormater = [
      'round',
      'multiples',
      'division',
      'zero2null', // 返回非数字
      'trim_zero',
      'thousands_sep', // 返回非数字
      'unit', // 单位
    ];
    // 数组
    const _arrayFormater = ['join'];
    // 时间 日期
    const _dateFormater = ['datetime'];
    // const colorData = [
    //   { background: '#BE2F88', color: '#fff' },
    //   { background: '#6A7689', color: '#fff' },
    //   { background: '#FF9A43', color: '#000' },
    //   { background: '#4051B4', color: '#fff' },
    //   { background: '#795549', color: '#fff' },
    //   { background: '#ABB8C3', color: '#000' },
    //   { background: '#CDAA8C', color: '#000' },
    //   { background: '#D01D1B', color: '#fff' },
    //   { background: '#009588', color: '#fff' },
    //   { background: '#9C28AF', color: '#fff' },
    //   { background: '#76DDD4', color: '#000' },
    //   { background: '#FFA099', color: '#000' },
    //   { background: '#FFC00A', color: '#000' },
    //   { background: '#8BC34A', color: '#000' },
    //   { background: '#CDDB3A', color: '#000' },
    // ]
    const ROW_COLOR_CLS = {
      '#BE2F88': 'row_color_BE2F88',
      '#6A7689': 'row_color_6A7689',
      '#FF9A43': 'row_color_FF9A43',
      '#4051B4': 'row_color_4051B4',
      '#795549': 'row_color_795549',
      '#ABB8C3': 'row_color_ABB8C3',
      '#CDAA8C': 'row_color_CDAA8C',
      '#D01D1B': 'row_color_D01D1B',
      '#009588': 'row_color_009588',
      '#9C28AF': 'row_color_9C28AF',
      '#76DDD4': 'row_color_76DDD4',
      '#FFA099': 'row_color_FFA099',
      '#FFC00A': 'row_color_FFC00A',
      '#8BC34A': 'row_color_8BC34A',
      '#CDDB3A': 'row_color_CDDB3A',
    };
    const ROW_BACK_COLOR = 'row-background-color';
    const dataFormater = function (
      ilistVal,
      iformat = {},
      ext = { exceptKeys: noThousandSep ? ['thousands_sep'] : undefined },
    ) {
      let listVal = ilistVal;
      if (isEmptyObj(iformat)) return ilistVal;
      const format = Object.assign({}, iformat);
      if (ext.exceptKeys) ext.exceptKeys.forEach(k => delete format[k]);
      const formatKeys = Object.keys(format);
      const listValType = typeIs(listVal);
      if (listValType === 'number' || listValType === 'string') {
        const dateFormater = formatKeys.filter(item => _dateFormater.includes(item));
        if (listValType === 'string' && listVal.split(',').length > 0 && dateFormater.length > 0) {
          // 日期时间
          return dateFormater.reduce((p, c) => {
            const formatOpType = c;
            const formatVal = format[c];
            if (p === '') return p; // TODO 异常情况返回
            return p.map(item => (item === '' ? item : formatOpList[formatOpType](item, formatVal))).join(',');
          }, listVal.split(','));
        }
        const numberFormaters = formatKeys.filter(item => _numberFormater.includes(item));
        if (!numberFormaters.length) return ilistVal;
        listVal = parseFloat(listVal, 10);
        // 非数字 直接返回 转换后 是数字的 与原字符串比较 有小数点去除尾零比较
        const hasDot = /\./.test(`${listVal}`) || /\./.test(`${ilistVal}`);
        const isScience = /e/.test(`${listVal}`) || /E/.test(`${ilistVal}`);
        if (
          Number.isNaN(listVal) ||
          !typeIs(listVal, 'number') ||
          (!hasDot && `${listVal}` !== `${ilistVal}`) ||
          (hasDot &&
            !isScience &&
            `${listVal}`.toLowerCase() !== `${ilistVal}`.replace(/0*0$/, '').toLowerCase() &&
            `${listVal}`.toLowerCase() !== `${ilistVal}`.replace(/\.0*0$/, '').toLowerCase())
        )
          return ilistVal;
        // 数字
        return numberFormaters.reduce((p, c) => {
          if (p === '') return p; // TODO 异常情况返回d
          return formatOpList[c](p, format[c]);
        }, listVal);
      } else if (listValType === 'array') {
        // 数组数据 sdf
        return formatKeys
          .filter(item => _arrayFormater.includes(item))
          .reduce((p, c) => {
            const formatOpType = c;
            const formatVal = format[c];
            if (p === '') return p; // TODO 异常情况返回
            return formatOpList[formatOpType](
              p.map(item => dataFormater(item, format)),
              formatVal,
            );
          }, listVal);
      }
    };

    function formatValue(columnKey, rowObj, enumerations = {}, cellclassMap, cellclassGetter, headerCol) {
      // eslint-disable-line
      let value = formatDeppProptotype(columnKey, rowObj); // 防止深层
      if (NUMBER_TYPE.includes(headerCol.columnType) && (value === undefined || value === '')) {
        value = 0;
      }
      let _value = value;
      const valueType = typeIs(value);
      const uniqueKey = headerCol.uniqueKey || 'key';
      const showKey = headerCol.showKey || 'name';
      if (cellclassGetter && enumerations[`cellclass.${columnKey}`]) {
        const cellclassVal = cellclassMap[columnKey] ? formatDeppProptotype(cellclassMap[columnKey], rowObj) : value;
        const cellclassObj =
          (enumerations[`cellclass.${columnKey}`] || []).filter(item => item[uniqueKey] === cellclassVal)[0] || {};
        cellclassGetter && cellclassGetter(cellclassObj[showKey]);
      }
      const enums = enumerations[headerCol.refEnum];
      // if (enums && headerCol && !headerCol.fetchApi && typeIs(enums, 'array') && headerCol.type !== 'CheckBoxText') {
      if (
        enums &&
        headerCol &&
        typeIs(enums, 'array') &&
        headerCol.type !== 'CheckBoxText' &&
        headerCol.type !== 'Progress'
      ) {
        _value = ''; // 枚举初值，未匹配值都返回空
        if (valueType === 'array' && value.length > 0) {
          try {
            let dicItem = {};
            const sDic = {};
            const subValueType = typeIs(value[0]);
            enums.map(item => (sDic[item[uniqueKey]] = item));
            _value = value
              .reduce((p, c) => {
                const k = subValueType === 'object' ? c[uniqueKey] : c;
                dicItem = sDic[k] || {};
                if (dicItem[showKey] || dicItem.diff_display) {
                  p.push(dicItem.diff_display !== undefined ? dicItem.diff_display : dicItem[showKey] || '');
                }
                return p;
              }, [])
              .join(LIST_ARRAY2TEXT_SEPARATE);
          } catch (error) {
            return false;
          }
        } else if (valueType !== 'object') {
          enums.some(item => {
            if (
              item !== undefined &&
              item !== null &&
              item[uniqueKey] !== undefined &&
              item[uniqueKey] !== null &&
              item[uniqueKey] == value
            ) {
              // eslint-disable-line
              _value = item.diff_display !== undefined ? item.diff_display : item[showKey] || '';
              return true;
            }
            return false;
          });
        }
      }
      if (OBJECT_TEXTCELL_GEN_RULE[columnKey] && valueType === 'array' && typeIs(_value[0], 'object')) {
        _value = OBJECT_TEXTCELL_GEN_RULE[columnKey](_value);
      }
      if (WEIGHT_UNIT_TEXTCELL_GEN_RULE[columnKey] && valueType === 'array') {
        _value = WEIGHT_UNIT_TEXTCELL_GEN_RULE[columnKey](_value);
      }
      return _value === undefined || _value === null ? '' : _value;
    }

    function matchColorRule(value, rule) {
      return rule.type === 'range'
        ? (rule.value[0] === '' && +value <= +rule.value[1]) ||
            (rule.value[1] === '' && +value > +rule.value[0]) ||
            (+value > +rule.value[0] && +value <= +rule.value[1])
        : !!rule.value.find(v => v.name === value || (/^无需/.test(v.name) && value === '')); // 兼容枚举的diff_display
    }

    // function getRowStyle({ colorRule: _colorRule, dataItem }) {
    //   const newDataItem = dataItem
    //   if (!_colorRule) return dataItem
    //   _colorRule.every(item => {
    //     const columnKey = item.key,
    //       value = newDataItem[columnKey]
    //     if (value !== undefined && matchColorRule(newDataItem[columnKey], item)) {
    //       newDataItem.otherProps = newDataItem.otherProps || {}
    //       newDataItem.otherProps.style = newDataItem.otherProps.style || {}
    //       newDataItem.otherProps.style[ROW_BACK_COLOR] = Object.assign({}, colorData.find(x => x.background === item.color)) // 行数据中加style
    //       return false
    //     }
    //     return true
    //   })
    //   return newDataItem
    // }
    function getRowStyleCls({ colorRule: _colorRule, dataItem }) {
      const newDataItem = dataItem;
      if (!_colorRule) return dataItem;
      _colorRule.every(item => {
        const columnKey = item.key;
        const value = newDataItem[columnKey];
        if (value !== undefined && matchColorRule(newDataItem[columnKey], item)) {
          newDataItem.otherProps = newDataItem.otherProps || {};
          newDataItem.otherProps.cellclass = newDataItem.otherProps.cellclass || {};
          newDataItem.otherProps.cellclass[ROW_BACK_COLOR] = ROW_COLOR_CLS[item.color]; // 行数据中加style
          return false;
        }
        return true;
      });
      return newDataItem;
    }

    function loopData({ d, addrow, hfData, selectes, header, headerKeys, enumerations, cellclassMap, colorRule }) {
      // eslint-disable-line
      const getter = (i, dataItem) => {
        if (addrow.includes(i)) {
          return hfData[i] || {};
        }
        let newDataItem = {};
        if (dataItem) {
          newDataItem.otherProps = dataItem.otherProps || {};
          headerKeys.forEach(columnKey => {
            if (columnKey === 'reference') {
              newDataItem[columnKey] = i + 1;
            } else if (columnKey === 'checkbox') {
              showingRows[i] = { checkbox: true };
              newDataItem[columnKey] = { checked: selectes.includes(i) };
            } else if (columnKey === 'operate') {
              newDataItem[columnKey] = newDataItem.otherProps.operate;
            } else {
              // 数据列
              const headerCol = header[columnKey] || {};
              const { format } = headerCol;
              let cellclass;
              let value = formatValue(
                columnKey,
                dataItem,
                enumerations || {},
                cellclassMap,
                cellclassMap
                  ? cls => {
                      cellclass = cls;
                    }
                  : undefined,
                headerCol,
              );
              value = value === undefined || value === null ? '' : value;
              // checkbox 特殊处理
              if (`${headerCol.type}` === 'CheckBox') {
                showingRows[i] = { [columnKey]: true };
                newDataItem[columnKey] = { text: '', checked: dataFormater(value, format || []) };
              } else if (`${headerCol.type}` === 'CheckBoxText') {
                showingRows[i] = { [columnKey]: true };
                const val = dataFormater(value, format || []);
                newDataItem[columnKey] = typeIs(val, 'object')
                  ? Object.assign({ text: '', checked: false }, val)
                  : { text: val, checked: false };
              } else {
                value = dataFormater(value, format || []);
                if (isPureText && headerCol.columnType === 'object' && typeIs(value, 'array')) {
                  value = value.map((v, k) => k + 1).join(' ');
                }
                newDataItem[columnKey] = value;
              }
              // cellclass 是枚举里有key为cellclass.columnKey的值， 若配置了cellclassMap，则所取枚举值为map指定的列值
              cellclass && (newDataItem[`cellclass.${columnKey}`] = cellclass);
            }
          });
          // 添加是否底色属性
          // newDataItem = getRowStyle({ dataItem: newDataItem, colorRule })
          newDataItem = getRowStyleCls({ dataItem: newDataItem, colorRule });
        }
        return newDataItem;
      };
      const _cache = {};
      const { length } = Object.keys(d);
      addrow.forEach(key => (_cache[key] = getter(key)));
      for (let i = 0; i < length; i++) {
        _cache[i] = getter(i, d[i]);
      }
      return _cache;
    }

    const allCache = loopData({
      d,
      addrow,
      hfData,
      selectes,
      header,
      headerKeys,
      enumerations,
      cellclassMap,
      colorRule,
    });
    return { dataCache: allCache, showingRows };
  });
  task
    .execute(executeData)
    .then(res => {
      console.log('preLoaded');
      setTimeout(() => {
        store.setAllShow(res.dataCache);
        store.fastUpShow();
        store.genShowImmutable();
        cb && cb(res);
      });
    })
    .catch(err => console.log(err));
}
