import { format2ApiQueryFilter, mergeEnum } from 'utils';
import { setHeader, setSort } from 'components/commoncomponents/listOperation/commonOp.js';

const getQueryWithFilter = ({ filter, header, query, sort }) => {
  const rowFilter = format2ApiQueryFilter({
    searchFilter: filter,
    header,
    initLogicIndex: 500,
    orderNumScan: false,
  });
  const rowFilterQuery = {
    ...rowFilter.query,
    ...query,
  };
  let res = {};
  res.query = rowFilterQuery;
  if (rowFilter.filter) {
    res.filter = rowFilter.filter;
  }
  if (rowFilter.addition) {
    res = { ...res, ...rowFilter.addition };
  }
  res.sort = sort;
  return res;
};

const dict2array = (dt, headerCol) =>
  Object.keys(dt).reduce((newArr, key) => {
    newArr.push({ [headerCol.uniqueKey || 'key']: key, [headerCol.showKey || 'display']: dt[key].display, ...dt[key] });
    return newArr;
  }, []);
const formateEnum = (enumDict, header) => {
  const newEnumDict = enumDict;
  Object.keys(enumDict).forEach(key => {
    const headerKey = Object.keys(header).filter(innerKey => header[innerKey].refEnum === key) || [];
    const headerCol = header[headerKey[0]] || {};
    if (enumDict[key]) {
      newEnumDict[key] = dict2array(enumDict[key], headerCol);
    }
  });
  return newEnumDict;
};
const mergeSugEnum = ({ enumerations, header, sugEnum }) => {
  const { enumerations: newEnum } = mergeEnum(enumerations, sugEnum || {}, {}, {}, header);
  return newEnum;
};

// 列表排序
const tableSetSort = (tableInfo, callback) => {
  setSort({ tableInfo }, { callback });
};
// 列表设置
const tableSetHeader = (tableInfo, callback) => {
  setHeader({ tableInfo }, { callback });
};
export { getQueryWithFilter, formateEnum, mergeSugEnum, tableSetSort, tableSetHeader };
