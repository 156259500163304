exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-input{border:1px solid rgba(34,36,38,.2);height:24px;font-size:12px;background:#fff;padding:3px 5px;border-radius:2px;width:140px;color:#000;cursor:text}.fn-input:hover{border:1px solid #0097cf;background:#fff}.fn-input:focus{background:#e2f7ff;border:1px solid #0097cf}.fn-input--error,.fn-input--error:focus{background:#fff5f5;border:1px solid #ff4040}.fn-input--active{border:1px solid rgba(45,183,245,.5);box-shadow:0 0 2px 1px rgba(45,183,245,.5)}.fn-input__co{border:0 none;border-bottom:1px solid #ccc}.fn-input__co:focus{border:0 none;background:#fff9db;box-shadow:inset 0 -2px 0 0 #feb72b}.fn-input__co:hover{border:none;border-bottom:1px solid #ccc}.fn-input__co--disabled,.fn-input__co--disabled:focus{background-color:#f2f9ff;border:0 none;border-bottom:1px solid #c1c1c1;cursor:no-drop}.fn-input__co.fn-input--error,.fn-input__co.fn-input--error:focus{background:#fff5f5;border-bottom:1px solid #ff4040}.fn-input--disabled{background-color:#f2f9ff;border-color:#c1c1c1;cursor:no-drop}.fn-input--disabled:focus{background-color:#f2f9ff;border-color:#c1c1c1;box-shadow:inset 0 -1px 0 0 #c1c1c1}.fn-input__text{background-color:#fff;border:1px solid #fff;cursor:default}.fn-input[disabled]{color:#333!important;background-color:#f2f9ff!important;border-color:#c1c1c1!important;cursor:no-drop}.baseInput__w{margin:0;padding:0}.baseInput__w,.radio__w{display:inline-block}.radio__w .fn-radio{margin:0 5px}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-input",
	"fn-input": "fn-input",
	"fn-input--error": "fn-input--error",
	"fn-input--active": "fn-input--active",
	"fn-input__co": "fn-input__co",
	"fn-input__co--disabled": "fn-input__co--disabled",
	"fn-input--disabled": "fn-input--disabled",
	"fn-input__text": "fn-input__text",
	"baseInput__w": "baseInput__w",
	"radio__w": "radio__w",
	"fn-radio": "fn-radio"
};