/**
 * Created by jany on 2017/09/03.
 */
import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { CheckBoxV2 } from 'components';
import { typeIs } from 'utils';
import { isInOtherPropsArray, getOtherPropsInfo } from './../utils';
import TableCell from './TableCell';

export default class CheckBoxCell extends TableCell {
  constructor(prop) {
    super(prop);
    this.isDisabled = false;
    this.defaultValue = {};
  }

  static propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    label: PropTypes.string,
    // tips: PropTypes.string,
    direction: PropTypes.string,
    tipDirect: PropTypes.string,
    showDisableCheckbox: PropTypes.bool, // 是否显示禁用的checkbox
    showEmptyCheckbox: PropTypes.bool, // 是否显示空label的checkbox
    // checkboxtext props
    stopPropagation: PropTypes.bool,
    isSelectFooterAll: PropTypes.bool,
  };
  handleChange = e => {
    const { checked } = e.target;
    this.setState({ checked });
    const { rowIndex, columnKey } = this.props;
    this.props.onChange && this.props.onChange(rowIndex, columnKey, checked);
  };
  onKeyDown = e => {
    this.props.onKeyDown && this.props.onKeyDown(e, this.props.rowIndex);
  };

  // 勾选列不触发 checkbox事件 统一到行点击事件
  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, enableOperate = true, colIndex } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const {
      tipDirect = 'top',
      direction = 'left',
      showDisableCheckbox,
      showEmptyCheckbox,
      stopPropagation,
      label,
    } = columnProps;
    const dataItem = data.getShowObjectAt(rowIndex);
    const defaultValue = dataItem[columnKey] || {};
    this.defaultValue = defaultValue; // { text, checked: isSelectFooterAll || checkBoxTextStatus[rowIndex], tips: ''}
    this.isDisabled = isInOtherPropsArray(dataItem, columnKey, 'disable');
    const text = defaultValue.text && typeof defaultValue.text !== 'boolean' ? defaultValue.text : label;
    const emptyText = text === '' || text === '0' || text === undefined || parseFloat(text, 10) === 0;
    // 此处添加判断： 如果labelText 为空，不显示checkbox， 如果想要单独显示checkbox的， 需要使用checkbox
    const lableText = emptyText ? '' : text;
    let extProps = {};
    if (columnKey !== 'checkbox') extProps = { ...extProps, dataPath: `${columnKey}_${rowIndex}_${colIndex}` };
    let checkedValue = defaultValue.checked;
    // 此处处理后台数据返回  “1” 和 "0“的情况
    !typeIs(checkedValue, 'boolean') &&
      checkedValue !== 'true' &&
      checkedValue !== 'false' &&
      (checkedValue = !!+checkedValue);
    // 2全显示 1不显示checkbox 0全不显示
    // const showType = +getOtherPropsInfo(dataItem, columnKey, 'checkboxShowType') || 2
    // if (showType === 0) {
    //   return (<Cell
    //     {...this.props.cellProps}
    //     onContextMenu={this.props.rowContextMenu ? (e) => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
    //   />)
    // }
    const isText = !!((this.isDisabled && !showDisableCheckbox) || !enableOperate || (emptyText && !showEmptyCheckbox));
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        {isText && <span key={Math.random()}>{lableText}</span>}
        {!isText && (
          <CheckBoxV2
            {...extProps}
            stopPropagation={stopPropagation}
            ref={r => (this.checkBox = r)}
            useDefaultCls={false}
            checked={checkedValue}
            label={lableText}
            tips={defaultValue.tips || getOtherPropsInfo(dataItem, columnKey, 'tips')}
            tipDirect={tipDirect}
            direction={direction}
            disabled={this.isDisabled || !enableOperate}
            onClick={this.handleChange}
            onKeyDown={this.onKeyDown}
            classname={columnKey}
          />
        )}
      </Cell>
    );
  }
}
