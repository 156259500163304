import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { Radio, DatePicker } from 'components';
import { DATE_TIME_UNLIMIT, SUPPORT_DATETIME_FORMATS, NORMAL_DT_FORMAT, NORMAL_D_FORMAT } from 'constants';
import { range } from 'utils';
// 推荐在入口文件全局设置 locale 与时区
import 'moment/locale/zh-cn';
import './index.scss';

moment.locale('zh-cn');
const { RangePicker } = DatePicker;
const rangeData = [
  { label: '今天', name: 'rangeType', value: '1' },
  { label: '近一周', name: 'rangeType', value: '2' },
  { label: '近一月', name: 'rangeType', value: '3' },
  { label: '本月', name: 'rangeType', value: '4' },
  { label: '上月', name: 'rangeType', value: '6' },
  { label: '近三月', name: 'rangeType', value: '5' },
  { label: '近六月', name: 'rangeType', value: '7' },
];
/* TODO 完善禁用类型范围
不可选类型：
1: 不可选择历史时间
10/11/  20/21/  30/31/32 /40/41 -> // 格式化为年月日时分秒的 近一天 本天 //近一周 本周 //近一月 本月 近六个月 //近一年 本年
110/111/  120/121/  130/131/132 /140/141 ->   // 格式化为年月日的 -》近一天 本天 //近一周 本周 //近一月 本月 近六个月 //近一年 本年
230/231/232 /240/241 ->   // 格式化为年月的 -》//近一月 本月 近六个月
910  920  930/932/933/936 /940  -> // 时间段 一天 一周 一个月 二个月 三个月 六个月 一年
*/

const disableFunc = {
  1: {
    disabledDate: current => current.valueOf() < moment().startOf('day').valueOf(),
  },
  10: {
    disabledDate: current => {
      console.log(
        current.valueOf() < moment().startOf('day').subtract(1, 'day').valueOf() ||
          current.valueOf() > moment().valueOf(),
      );
      return (
        current.valueOf() < moment().startOf('day').subtract(1, 'day').valueOf() ||
        current.valueOf() > moment().valueOf()
      );
    },
    disabledTime: (ds, t) => {
      console.log(ds, t);
      return t === 'start'
        ? {
            disabledHours: () => [], // range(moment().hour(), 24),
            disabledMinutes: () => [], // range(30, 60),
            disabledSeconds: () => [], // range(30, 60),
          }
        : {
            disabledHours: () => [], // range(moment().hour(), 24),
            disabledMinutes: () => [], // range(30, 60),
            disabledSeconds: () => [], // range(30, 60),
          };
    },
  },
  11: {
    disabledDate: current => {
      console.log(
        current.valueOf() < moment().startOf('day').subtract(1, 'day').valueOf() ||
          current.valueOf() > moment().valueOf(),
      );
      return current.valueOf() < moment().startOf('day').valueOf() || current.valueOf() > moment().valueOf();
    },
    disabledTime: (ds, t) => {
      console.log(ds, t);
      return t === 'start'
        ? {
            disabledHours: () => range(0, moment().hour()),
            disabledMinutes: () => [], // range(30, 60),
            disabledSeconds: () => [], // range(30, 60),
          }
        : {
            disabledHours: () => range(0, moment().hour()),
            disabledMinutes: () => [], // range(30, 60),
            disabledSeconds: () => [], // range(30, 60),
          };
    },
  },
  20: {
    disabledDate: current =>
      current.valueOf() < moment().startOf('day').subtract(1, 'week').valueOf() ||
      current.valueOf() > moment().valueOf(),
    disabledTime: (ds, t) =>
      t === 'start'
        ? {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => [],
          }
        : {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => [],
          },
  },
  21: {
    disabledDate: current =>
      current.valueOf() < moment().startOf('week').valueOf() || current.valueOf() > moment().valueOf(),
    disabledTime: (ds, t) =>
      t === 'start'
        ? {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => [],
          }
        : {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => [],
          },
  },
  // 只能选当前之前的日期
  10001: {
    disabledDate: current => current.valueOf() > moment().endOf('day').valueOf(),
  },
};
export default class RangeTimePicker extends Component {
  constructor(props) {
    super(props);
    const { showTime, defaultValue, format = 'YY-MM-DD HH:mm:ss' } = props;
    let _format = format;
    if (showTime) {
      _format = format.split(' ');
      _format[1] = 'HH:mm:ss';
      _format = _format.join(' ');
    }
    //  今天 近一周 近一月 本月 上月 近三个月 近六个月
    this._defaultRanges = {
      1: [moment().startOf('day'), moment().endOf('day')].map(item => item.format(_format)).join(','),
      2: [moment().startOf('day').subtract(6, 'day'), moment().endOf('day')]
        .map(item => item.format(_format))
        .join(','),
      3: [moment().startOf('day').subtract(1, 'day').subtract(1, 'month').add(1, 'day'), moment().endOf('day')]
        .map(item => item.format(_format))
        .join(','),
      4: [moment().startOf('month'), moment().endOf('day')].map(item => item.format(_format)).join(','),
      5: [moment().startOf('day').subtract(1, 'day').subtract(3, 'month').add(1, 'day'), moment().endOf('day')]
        .map(item => item.format(_format))
        .join(','),
      6: [moment().add(-1, 'months').startOf('month'), moment().add(-1, 'months').endOf('month')]
        .map(item => item.format(_format))
        .join(','),
      7: [moment().startOf('day').subtract(1, 'day').subtract(6, 'month').add(1, 'day'), moment().endOf('day')]
        .map(item => item.format(_format))
        .join(','),
    };
    const _rangeValue = `${defaultValue || ''}`
      .split(',')
      .map(item => (item ? moment(item, SUPPORT_DATETIME_FORMATS).format(_format) : ''))
      .join();
    this.state = {
      defaultValue,
      defaultRangeValue: Object.keys(this._defaultRanges).filter(key => this._defaultRanges[key] === _rangeValue)[0],
      format: _format,
      showTime,
    };
    this.onOpenValue = defaultValue;
    this.currValue = defaultValue;
  }

  static propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // TODO 临时 array
    firstSelectTime: PropTypes.array,
    disabled: PropTypes.bool,
    showTime: PropTypes.any,
    onChange: PropTypes.func,
    style: PropTypes.object,
    format: PropTypes.any,
    forceFormat: PropTypes.bool,
    allowClear: PropTypes.bool,
    disableType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ftidormat: PropTypes.object,
    showExtFoot: PropTypes.bool,
    multiLine: PropTypes.bool, // 日期用textarea显示，可以换行
  };
  static defaultProps = {
    allowClear: true,
    format: 'YYYY-MM-DD',
    showExtFoot: true,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { format, showTime } = nextProps;
    let _format = format;
    if (showTime) {
      _format = format.split(' ');
      _format[1] = 'HH:mm:ss';
      _format = _format.join(' ');
    }
    // 未查到对应使用处 暂留
    if (nextProps.format !== this.props.format) {
      this.setState({ format: nextProps.format });
    }
    if (_format !== this.state.format) {
      this.setState({ format: _format });
    }
    if (nextProps.defaultValue !== this.props.defaultValue || nextProps.defaultValue !== this.state.defaultValue) {
      const _rangeValue = `${nextProps.defaultValue || ''}`
        .split(',')
        .map(item => (item ? moment(item, SUPPORT_DATETIME_FORMATS).format(_format) : ''))
        .join();
      this.setState({
        defaultValue: nextProps.defaultValue,
        defaultRangeValue: Object.keys(this._defaultRanges).filter(key => this._defaultRanges[key] === _rangeValue)[0],
      });
    }
  }

  // 展开/收起
  onOpenChange = isOpen => {
    let val = this.currValue || ''; // this.state.defaultValue
    val &&
      (val = `${val}`
        .split(',')
        .map(item =>
          item
            ? moment(item, SUPPORT_DATETIME_FORMATS).format(this.state.showTime ? NORMAL_DT_FORMAT : NORMAL_D_FORMAT)
            : '',
        )
        .join(','));
    if (!isOpen && this.onOpenValue !== val) {
      this.props.onChange && this.props.onChange(val);
    } else {
      if (this.props.defaultSelectValue && !this.currValue) {
        this.setState({
          defaultValue: this.props.defaultSelectValue,
        });
        this.currValue = this.props.defaultSelectValue;
      }
      this.onOpenValue = val;
    }
  };
  // 点击某个extraFooter时,更新组件值
  updateTimeByFooter = value => {
    const defaultValue = this._defaultRanges[value || ''] || ''; // 当前选择的range
    this.currValue = defaultValue;
    this.setState({
      defaultValue,
      defaultRangeValue: value, // 当前选中的Radio
    });
  };
  // 时间值改变的回调
  onTimeChange = dates => {
    let defaultValue;
    const { format } = this.state;
    if (dates && dates.length) {
      defaultValue = dates.map(item => moment(item, SUPPORT_DATETIME_FORMATS).format(format)).join(',');
    } else if (dates.length === 0) {
      defaultValue = ''; // 清除 操作
      if (this.props.allowClear) {
        this.props.onChange(defaultValue);
      }
    } else {
      defaultValue = '';
    }
    this.currValue = defaultValue;
    this.setState({
      defaultValue,
      defaultRangeValue: 'undefined', // 清空之前选中的range
    });
    // 清除操作,立即拉取数据
    // 不能用 this.state.defaultValue,因为setState异步
    // showTime = true的只有在点击确定时，触发回调。
    // if (this.props.allowClear && defaultValue !== null && !this.props.showTime) {
    //   this.props.onChange(dates.map(item => moment(item, SUPPORT_DATETIME_FORMATS).format(NORMAL_DT_FORMAT)).join(','))
    // }
  };
  getExtraFooter = defaultRangeValue => () =>
    (
      <Radio
        classname="range-time-picker__footer"
        value={defaultRangeValue}
        data={rangeData}
        onChange={this.updateTimeByFooter}
      />
    );

  // onOk={this.onOkCallBack} // 点击确定不做处理 统一到收起下拉时处理
  render() {
    const { style, disabled, allowClear, forceFormat = true, disableType, showExtFoot, placeholder } = this.props;
    let { defaultRangeValue } = this.state;
    const { defaultValue = '', format, showTime } = this.state;
    this.currValue = defaultValue;
    const dataList =
      defaultValue && defaultValue !== DATE_TIME_UNLIMIT
        ? defaultValue.split(',').map(item => moment(item, SUPPORT_DATETIME_FORMATS))
        : [];
    // defaultRangeValue = defaultRangeValue || '3' // extraFooter默认选中近一个月 (有默认值是一年的会默认选中一个月， 所以不应该有默认选中值)
    if (!defaultValue || defaultValue === DATE_TIME_UNLIMIT) {
      defaultRangeValue = 'undefined';
    }
    // 选择日期后的默认时分秒
    const showTimeOption =
      // eslint-disable-next-line no-nested-ternary
      showTime && showTime.defaultValue
        ? showTime
        : showTime || forceFormat
        ? {
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
          }
        : false;
    const disableFuncs = disableFunc[disableType] || {};
    const others = {};
    if (placeholder) {
      others.placeholder = placeholder;
    }
    return (
      <RangePicker
        className="date-time-picker"
        disabled={disabled}
        style={{ width: '140px', ...style }}
        renderExtraFooter={showExtFoot ? this.getExtraFooter(defaultRangeValue) : null}
        value={dataList}
        format={format}
        showTime={showTimeOption}
        onChange={this.onTimeChange}
        onOpenChange={this.onOpenChange}
        allowClear={allowClear}
        disabledDate={disableFuncs.disabledDate}
        disabledTime={disableFuncs.disabledTime}
        {...others}
      />
    );
  }
}
