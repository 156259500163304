/**
 * Created by JANY on 2017/1/15.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { throttle } from 'utils';
import classnames from 'classnames';
import { TreeSelect, SelectDrop } from 'components';
import fetchData from 'components/commoncomponents/fetch/fetchData';
import './index.scss';

@fetchData
export default class OrgSug extends Component {
  constructor(prop) {
    super(prop);
    this.initFetchProps(prop);
    this.state = {
      data: prop.data || [],
    };
  }

  static propTypes = {
    classname: PropTypes.string,
    fieldName: PropTypes.string,
    disabled: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    defaultSelected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    mode: PropTypes.string,
    scope: PropTypes.string, // self group self_nothis group_nothis
    company_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // 要忽视的id
    ignore_com_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleSelected: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    isMultiple: PropTypes.bool,
    selectFirstLine: PropTypes.bool,
    showKey: PropTypes.string,
    uniqueKey: PropTypes.string,
    reqData: PropTypes.array,
    type: PropTypes.string,
    fetchApi: PropTypes.object,
    orgFields: PropTypes.array,
    // 提示信息
    tips: PropTypes.any,
    tipsCls: PropTypes.string,
  };
  static defaultProps = {
    classname: '',
    fieldName: '',
    disabled: false,
    mode: 'list', // list tree
    scope: 'self', // self group self_nothis group_nothis
    company_id: '', // 公司id
    fetchApi: {},
  };
  static contextTypes = {
    getData: PropTypes.func,
  };
  // UNSAFE_componentWillMount() {
  //   // const { getData } = this.context
  //   // getData('', this.fetchApi)
  // }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { reqData } = nextProps;
    if (reqData !== undefined) {
      // data = reqData
      // if (this.props.shouldFilter) data = reqData
      this.setState({ data: reqData });
    }
    this.initFetchProps(nextProps);
  }
  initFetchProps = prop => {
    let { scope } = prop;
    const { mode, company_id: companyId, ignore_com_id: ignoreComId, type, is_group_all = true } = prop;
    scope = scope.toString();
    const orgTypes = scope.split('_')[2] || '1,2,3,4,5';
    this.fetchApi = {
      url: '/Basic/Org/orgSug',
      para: {
        type,
        company_id: companyId,
        ignore_com_id: ignoreComId,
        need_list: mode === 'list',
        // scope : self group self_nothis group_nothis   group_nothis_1,2,3,4,5
        is_group_all,
        delete_self: scope.indexOf('nothis') !== -1,
        org_types: orgTypes.split(',').map(item => +item), // 1总部 2职能组织 3网点 4货站 5.分拨中心
        org_fields: prop.orgFields,
      },
      key: 'search',
    };
    Object.assign(this.fetchApi, prop.fetchApi);
  };
  handleDSelected = (...rest) => {
    this.props.handleSelected && this.props.handleSelected(...rest);
  };
  handleDChange = (...rest) => {
    this.props.onChange && this.props.onChange(...rest);
  };
  handleDFocus = (...rest) => {
    this.props.onFocus && this.props.onFocus(...rest);
  };
  handleTreeChange = (...rest) => {
    this.props.onChange && this.props.onChange(...rest);
  };

  render() {
    // 地址框 adr
    const {
      classname,
      mode,
      data,
      fieldName,
      defaultSelected,
      disabled,
      isMultiple,
      tips,
      tipsCls,
      showKey = 'name',
      uniqueKey = 'key',
      selectFirstLine,
      ..._props
    } = this.props;
    const classes = classnames('map-search-input-wrapper', classname);
    if (mode === 'list') {
      const selectDropProps = {
        data: this.state.data || [],
        showHeader: false,
        defaultSelected,
        className: classes,
        showKey,
        uniqueKey,
        selectFirstLine,
        tableHeader: { short_name: '组织名称' },
        disabled,
        isMultiple,
        selectOnly: true,
        isKeyboard: true,
        handleSelected: this.handleDSelected,
        onFocus: this.handleDFocus,
        onChange: throttle(this.handleDChange, 300),
        tips,
        tipsclassname: tipsCls,
        ..._props,
        fetchApi: this.fetchApi,
      };
      return <SelectDrop {...selectDropProps} />;
    }
    const treeSelectProps = {
      fieldName, // 字段名
      treeData: data, // 树形数据
      value: defaultSelected, // 选中值
      uniqueKey: data.uniqueKey,
      showKey: data.showKey,
      disabled,
      multiple: true, // 多选
      treeCheckable: true, // 可选
      showArrow: true, // 下拉箭头
      allowClear: true, // 可以清除
      labelInValue: false, // label包住选中项
      defaultOpen: false, // 默认展开
      open: true, // 是否展开
      treeLine: true, // 竖线
      treeDefaultExpandAll: true,
      showSearch: true,
      treeNodeFilterProp: data.showKey,
      treeNodeLabelProp: data.showKey, // 下拉选中项在输入框中lable的值对应的字段
      showCheckedStrategy: TreeSelect.SHOW_ALL,
      searchPlaceholder: '请输入关键字',
      placeholder: '搜索',
      notFoundContent: '无信息',
      style: { width: '100px' },
      onChange: throttle(this.handleTreeChange, 300),
    };
    return (
      <div className={classname}>
        <TreeSelect {...treeSelectProps} />
      </div>
    );
  }
}
