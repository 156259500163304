import { colorData, ROW_COLOR_CLS, ROW_BACK_COLOR } from 'constants';
/**
 * @DateTime 2017-10-23
 * @desc:    {[数据是否匹配单挑规则]}
 * @param    {[type]}        value [值]
 * @param    {[type]}        rule  [规则]
 * @return   {[type]}              [是否匹配上]
 */
const matchColorRule = function (value, rule) {
  return rule.type === 'range'
    ? (rule.value[0] === '' && +value <= +rule.value[1]) ||
        (rule.value[1] === '' && +value > +rule.value[0]) ||
        (+value > +rule.value[0] && +value <= +rule.value[1])
    : !!rule.value.find(v => v.name === value || (/^无需/.test(v.name) && value === '')); // 兼容枚举的diff_display
};
const getRowStyle = function ({ colorRule, dataItem }) {
  const newDataItem = dataItem;
  if (!colorRule) return dataItem;
  colorRule.every(item => {
    const columnKey = item.key;
    const value = newDataItem[columnKey];
    if (value !== undefined && matchColorRule(newDataItem[columnKey], item)) {
      newDataItem.otherProps = newDataItem.otherProps || {};
      newDataItem.otherProps.style = newDataItem.otherProps.style || {};
      newDataItem.otherProps.style[ROW_BACK_COLOR] = { ...colorData.find(x => x.background === item.color) }; // 行数据中加style
      return false;
    }
    return true;
  });
  return newDataItem;
};
const getRowStyleCls = function ({ colorRule, dataItem }) {
  const newDataItem = dataItem;
  if (!colorRule) return dataItem;
  colorRule.every(item => {
    const columnKey = item.key;
    const value = newDataItem[columnKey];
    if (value !== undefined && matchColorRule(newDataItem[columnKey], item)) {
      newDataItem.otherProps = newDataItem.otherProps || {};
      newDataItem.otherProps.cellclass = newDataItem.otherProps.cellclass || {};
      newDataItem.otherProps.cellclass[ROW_BACK_COLOR] = ROW_COLOR_CLS[item.color]; // 行数据中加style
      return false;
    }
    return true;
  });
  return newDataItem;
};
export { matchColorRule, getRowStyle, getRowStyleCls };
