import React, { useCallback, useLayoutEffect, useRef } from 'react';
import _ from 'lodash';
/**
 * 列宽调整组件
 * 该组件用于表格的列宽调整，严重依赖于fixed-data-table-2(包括样式，内部状态等)
 */
const ResizeComponent = props => {
  const { height = 58, onResize, anchors, wrapper } = props;
  const style = {
    height,
    zIndex: 10, // 原始Column的zIndex为1，这里为了防止被覆盖
  };

  const anchorRef = useRef(null); // 被拖拽的元素
  const cursorRef = useRef(null); // 新增的锚点元素
  const isResizing = useRef(false); // 是否正在调整列宽
  const pos = useRef({ x: 0, y: 0 }); // 鼠标位置

  const _suppressEvent = useCallback((/* object */ event) => {
    event.preventDefault();
    event.stopPropagation();
  });

  const initResizeEvent = () => {
    let _animationFrameID;
    let isLeftMoving; // 是否正在左移
    const deltaPos = { x: 0, y: 0 };
    let destroy = () => {};

    const _didMouseMove = () => {
      onResize({ ...deltaPos }, anchorRef.current);
      window.cancelAnimationFrame(_animationFrameID);
      _animationFrameID = null;
      deltaPos.x = 0;
      deltaPos.y = 0;
    };

    const _onMouseMove = e => {
      if (isResizing.current && cursorRef.current) {
        const cursorRect = cursorRef.current.getBoundingClientRect();
        const isNearBoundary = e.clientX >= cursorRect.x; // 是否接近边界（通过锚点元素的位置来判断）
        isLeftMoving = e.clientX <= pos.current.x;
        // 右移并且接近边界时，开始动态更新宽度
        if (!_animationFrameID && !isLeftMoving && isNearBoundary) {
          _animationFrameID = window.requestAnimationFrame(_didMouseMove);
        } else {
          // 左移或者右移未接近边界时，暂时不更新，可能会导致表格抖动
          window.cancelAnimationFrame(_animationFrameID);
          _animationFrameID = null;
        }
        deltaPos.x = e.clientX - pos.current.x + deltaPos.x;
        deltaPos.y = e.clientY - pos.current.y + deltaPos.x;
        pos.current = {
          x: Math.min(window.screen.width - 2, e.clientX), // 当鼠标移动到屏幕边缘时，记录鼠标位置为：screen.width - 2，则可以无限拖
          y: Math.min(window.screen.height - 2, e.clientY),
        };
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const _onMouseUp = e => {
      if (isResizing.current) {
        isResizing.current = false;
        anchorRef.current = null;
        pos.current = { x: 0, y: 0 };
        deltaPos.x = 0;
        deltaPos.y = 0;
        isLeftMoving = null;
      }
      destroy();
    };

    destroy = () => {
      window.cancelAnimationFrame(_animationFrameID);
      document.removeEventListener('mousemove', _onMouseMove);
      document.removeEventListener('mouseup', _onMouseUp);
    };

    document.addEventListener('mousemove', _onMouseMove);
    document.addEventListener('mouseup', _onMouseUp);

    return destroy;
  };

  useLayoutEffect(() => {
    let unmounts;
    try {
      // 可以为多个列设置
      unmounts =
        anchors?.map(anchor => {
          const destroy = [];

          const container = wrapper ?? document;
          // 类名需要转义下，防止有特殊字符。这里是假设每个表格的Key 都是唯一的，因此只会存在唯一的一个元素
          let anchorEl = container.querySelector(
            `.table-header-Column.table-column-${_.escapeRegExp(
              anchor,
            )} .fixedDataTableCellLayout_columnResizerContainer`,
          );

          const _onColumnResizerMouseDown = e => {
            isResizing.current = true;
            anchorRef.current = anchor;

            pos.current = {
              x: e.clientX,
              y: e.clientY,
            };

            initResizeEvent();
            e.preventDefault(); // 阻止默认事件，避免拖动时选择文字
            // _suppressEvent(e);
          };

          if (anchorEl) {
            anchorEl.addEventListener('mousedown', _onColumnResizerMouseDown);
            destroy.push(() => {
              anchorEl?.removeEventListener('mousedown', _onColumnResizerMouseDown);
              anchorEl = null;
            });
            return destroy;
          }
          return null;
        }) ?? [];
    } catch (error) {
      /* do nothing */
    }

    return () => {
      _.forEach(_.flatten(unmounts), fn => fn?.());
    };
  });

  return (
    <div
      className={'fixedDataTableCellLayout_columnResizerContainer fixedDataTableCellLayout_resizeComponent'}
      style={{ ...style, pointerEvents: 'none' }}
      ref={cursorRef}
    >
      <div
        className={`fixedDataTableCellLayout_columnResizerKnob public_fixedDataTableCell_columnResizerKnob`}
        style={{ ...style, visibility: 'hidden', pointerEvents: 'none' }}
      />
    </div>
  );
};

export default ResizeComponent;
