/*
created by  EnLai  2016/12/07
*/
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';

import { Switch, Label, Generator } from 'components';

import './index.scss';

class SwitchSet extends PureComponent {
  static propTypes = {
    isSwitch: PropTypes.bool, // 是否有开关
    text: PropTypes.string, // 开关后面的文字， 如果为空 则不显示label
    isOn: PropTypes.bool, // 开关状态
    field: PropTypes.string, // 字段名， 针对后面所有组件定义的key
    others: PropTypes.object, // 开关后面的所有其它组件
    onClick: PropTypes.func,
    handleEvents: PropTypes.object,
    getPaths: PropTypes.func,
  };
  static contextTypes = {
    setData: PropTypes.func,
    getPath: PropTypes.func,
    onLockChange: PropTypes.func,
    onSelcEmpty: PropTypes.func,
  };
  constructor(prop) {
    super(prop);
    this.state = {
      switch_state: prop.isOn ? 'switch_set_open' : 'switch_set_close',
    };
    this.events = {
      onChange: this.handleEvents,
      handleSelected: this.handleEvents,
      onBlur: this.handleEvents,
      onFocus: this.handleEvents,
      onClick: this.handleEvents,
      handleAddTodo: this.handleEvents,
      handleSubtractTodo: this.handleEvents,
    };
  }
  UNSAFE_componentWillReceiveProps(np) {
    if (np.isOn !== this.props.isOn) {
      this.setState({
        switch_state: np.isOn ? 'switch_set_open' : 'switch_set_close',
      });
    }
  }

  handleEvents = (key, value, ...rest) => {
    const type = rest[rest.length - 2];
    const eventName = rest[rest.length - 1] || '';
    const paths = this.context.getPath ? this.props.getPaths() : '';
    this.context.setData && this.context.setData(key, value, paths, eventName, type);
    if (key === 'lock') {
      this.context.onLockChange && this.context.onLockChange(key, value, paths, eventName, type);
    } else if (key === 'selc_value' && value.length === 0) {
      this.context.onSelcEmpty && this.context.onSelcEmpty(paths);
    }
  };

  switchClick = on => {
    console.log(`switch is ${on}`);
    const ssc = on ? 'switch_set_open' : 'switch_set_close'; // ssc--->switch state class
    this.setState({
      switch_state: ssc,
    });
    const paths = this.context.getPath ? this.props.getPaths() : '';
    this.context.getPath && this.context.getPath(paths);
    // let keyName = this.props.field
    // let path = this.props.getPaths()
    this.props.onClick && this.props.onClick(on);
  };

  render() {
    const { isSwitch, text, isOn, field, others } = this.props;
    return (
      <div className={`switch_set switch_set_${field}`}>
        <div className="switch_block">
          {isSwitch && <Switch checked={isOn} classname={`switch_${field}`} onClick={this.switchClick} />}
          {text && <Label classname={`label_${field} ${this.state.switch_state}`}>{text}</Label>}
        </div>
        {Generator.render(others, { classname: this.state.switch_state, events: this.events })}
      </div>
    );
  }
}

export default SwitchSet;
