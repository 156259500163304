/**
 * Created by jany on 2017/05/02.
 */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { camelToDash } from 'utils';

export const importantStyle = ComponsedComponent =>
  class extends Component {
    constructor(props) {
      super(props);
      this.handleRef = ref => {
        this.ref = ref;
        this.element = ReactDOM.findDOMNode(ref); // eslint-disable-line
      };
    }

    static propTypes = {
      style: PropTypes.object,
    };
    // public
    getRef() {
      return this.ref;
    }

    componentDidMount() {
      this.setImportantStyles();
    }

    componentDidUpdate() {
      this.setImportantStyles();
    }

    setImportantStyles() {
      if (!this.props.style) return;
      Object.keys(this.props.style).forEach(key => {
        const value = this.props.style[key];
        if (value.indexOf('!important') === -1) return;
        const dashedKey = camelToDash(key);
        this.element.style.setProperty(dashedKey, value.replace(/\s*!important/g, ''), 'important');
      });
    }

    render() {
      return <ComponsedComponent ref={this.handleRef} {...this.props} />;
    }
  };
