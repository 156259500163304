import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AdjustableDiv, ButtonIcon, Icon, Table, Page, MenuBtn, Load, ScanSug } from 'components';
import { fetchApi, showInfo, proxyListPage, emitter, formatCamelCase, mergeEnum, formatHeader } from 'utils';
import bem from 'utils/bem';
import OrderButtons from 'components/commoncomponents/OrderButtons';
import { ERROR } from 'constants';
import { handleGetModule } from 'operation';
import actions from 'actions';
import { prefixCls } from './style.scss';
import { getQueryWithFilter, formateEnum, mergeSugEnum, tableSetHeader, tableSetSort } from './utils';

const dragger = <div className={`${prefixCls}__dragger table-slider`} />;
const additionStyle = [
  { rightWidth: '0%', leftWidth: '100%', dragWidth: 6 },
  { rightWidth: '50%', leftWidth: '50%', dragWidth: 6 },
  { rightWidth: '100%', leftWidth: '0%', dragWidth: 6 },
];
const cls = bem(prefixCls);
class TablePicker extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    afterFetch: PropTypes.func,
    // 挑单 & 取消挑单 钩子 函数
    beforePick: PropTypes.func,
    beforeUnpick: PropTypes.func,
    afterPick: PropTypes.func,
    afterUnpick: PropTypes.func,
    // 数据 格式化函数
    formatter: PropTypes.func,
    unformatter: PropTypes.func,
    // 左右屏顶部 自定义内容
    leftExtra: PropTypes.element,
    rightExtra: PropTypes.element,
    // 处理用户输入回调
    onTableChange: PropTypes.func,
    // 不可编辑 即左右屏详情页场景
    disabled: PropTypes.bool,
    // 左右屏数据源 url & req
    fetchUrl: PropTypes.string,
    fetchReq: PropTypes.object,
    // 右屏默认选择数据
    defaultSelected: PropTypes.array,
    // 选中数据(受控属性)
    selected: PropTypes.array,
    // 左右屏数据主键(会在查询条件中使用)
    uniqueKey: PropTypes.string,
    // 右屏提交按钮点击回调 & 显示文案
    onSubmit: PropTypes.func,
    submitButtonProps: PropTypes.object,
    // 右屏顶部sug配置
    sugApi: PropTypes.object,
    showSug: PropTypes.bool,
    searchIcon: PropTypes.string,
    // 右屏顶部按钮点击事件
    onRightButtnClick: PropTypes.func,
    // 最大挑单数量(默认1000)
    maxNum: PropTypes.number,
    // 左右屏宽度设置
    widthConfig: PropTypes.object.isRequired,
    leftTableContextMenu: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    rightTableContextMenu: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  };

  static defaultProps = {
    uniqueKey: 'id',
    defaultSelected: [],
    searchIcon: 'icon-qrcode',
    maxNum: 1000,
    submitButtonProps: {
      children: '保存',
      iconType: 'icon-save',
      type: 'primary',
    },
    widthConfig: { rightWidth: '50%', leftWidth: '50%' },
  };
  sort = {};
  constructor(props) {
    super(props);
    this.state = {
      extend: props.disabled ? 2 : 1,
      // left table config
      left: {},
      // right table config
      right: {},
      //
      loading: false,
      submitting: false,
      widths: this.getWidthConfig(),
    };
  }
  componentDidMount() {
    this.fetch({
      needRight: true,
      fetchMode: 'all',
    });
    this.emitter = emitter.getSpance('batch');
  }
  UNSAFE_componentWillReceiveProps(np) {
    const isChangeDisabled = np.disabled !== this.props.disabled;
    const isChangeFetchReq = np.fetchReq !== this.props.fetchReq;
    const isChangeSelected = np.selected !== this.props.selected;
    if (isChangeFetchReq || isChangeSelected) {
      setTimeout(() => {
        this.fetch({
          needRight: true,
          fetchMode: 'all',
        });
      });
    }
    if (isChangeDisabled) {
      this.setState({
        extend: np.disabled ? 2 : 1,
      });
    }
  }
  set loading(val) {
    this.setState({
      loading: val,
    });
  }
  get req() {
    return this.state.lastReq;
  }
  // 挑单数据
  get rightData() {
    return this.getRightData();
  }
  set rightData(list) {
    this.rightTable && this.rightTable.resetData(list);
  }
  get leftData() {
    return this.getLeftData();
  }
  set leftData(list) {
    this.leftTable && this.leftTable.resetData(list);
  }

  get leftSelectedIndexs() {
    return this.leftTable.getStateSelectes();
  }
  get rightSelectedIndexs() {
    return this.rightTable.getStateSelectes();
  }

  get leftHeader() {
    return this.state.left.header;
  }
  get rightHeader() {
    return this.state.right.header;
  }

  get tableInfo() {
    return this.props.fetchReq;
  }

  get leftTable() {
    return this.leftTableWrap ? this.leftTableWrap.wrappedInstance : null;
  }
  get rightTable() {
    return this.rightTableWrap ? this.rightTableWrap.wrappedInstance : null;
  }

  get leftSelectedTotal() {
    return this.leftTable.getSelectedTotalRow();
  }
  get rightSelectedTotal() {
    return this.rightTable.getSelectedTotalRow();
  }

  get leftTotal() {
    return this.leftTable.getTotalData();
  }
  get rightTotal() {
    return this.rightTable.getTotalData();
  }
  getLeftShowData = selects => this.leftTable.getShowingData(selects);
  getRightShowData = selects => this.rightTable.getShowingData(selects);

  getWidthConfig = () => {
    const widths = _.cloneDeep(additionStyle);
    const { widthConfig = {} } = this.props;
    widths[1].leftWidth = widthConfig.leftWidth;
    widths[1].rightWidth = widthConfig.rightWidth;
    return widths;
  };

  fetch = async ({ needRight, leftQuery, pageNum, pageSize, fetchMode = 'body' } = {}) => {
    this.setState({ loading: true });
    const { fetchUrl, fetchReq, uniqueKey, defaultSelected, selected, formatter } = this.props;
    const req = _.cloneDeep({
      ...fetchReq,
      fetch_mode: fetchMode,
      need_left: 1,
      need_right: needRight ? 1 : 0,
    });
    if (leftQuery) {
      _.set(req, 'left', leftQuery);
    }
    const curPageSize = pageSize || this.state.left.page_size || 100;
    const curPageNum = pageNum || this.state.left.page_num || 1;
    const selects = selected || (this.rightData ? this.rightData.map(item => item[uniqueKey]) : defaultSelected);
    _.set(req, ['left', 'page_size'], curPageSize);
    _.set(req, ['left', 'page_num'], curPageNum);
    _.set(req, ['left', 'query', 0], {
      _logic: 'NOT',
      [uniqueKey]: selects,
    });
    _.set(req, ['right', 'page_size'], 1000);
    _.set(req, ['right', 'query', uniqueKey], selects.length ? selects : -1);
    let res = await fetchApi(fetchUrl, {
      method: 'POST',
      body: {
        req,
      },
    });
    if (this.props.afterFetch) {
      res = this.props.afterFetch(res) || res;
    }
    this.setState({ loading: false });
    if (res.errno !== 0) {
      showInfo(ERROR, res.errmsg);
      return;
    }
    const { left, right } = this.state;
    // 右屏数据格式化
    if (needRight && formatter && res.res.right.data.length) {
      res.res.right.data = res.right.data.map(formatter);
    }
    // 防止右侧数据丢失
    right.data = this.rightData || [];
    const leftEnumerations = res.res.left.enumerations;
    const leftEnumapi = res.res.left.enumapi;
    const rightEnumerations = needRight ? res.res.right.enumerations : this.state.enumerations;
    const rightEnumapi = needRight ? res.res.right.enumapi : {};
    const { enumerations, enumapi } = mergeEnum(
      leftEnumerations || {},
      rightEnumerations || {},
      leftEnumapi || {},
      rightEnumapi || {},
      res.res.left.header,
    );
    const curLeft = {
      ...left,
      ...res.res.left,
      header: fetchMode === 'body' ? left.header : res.res.left.header,
      page_size: curPageSize,
      page_num: curPageNum,
    };
    const curRight = needRight ? res.res.right : right;
    curLeft.header = formatHeader(curLeft.header, enumerations, enumapi);
    curRight.header = formatHeader(curRight.header, enumerations, enumapi);
    this.setState({
      enumerations,
      left: curLeft,
      right: curRight,
      lastReq: req,
    });
  };
  getLeftData = () => {
    if (this.state.left.data) {
      if (this.leftTable) {
        return this.leftTable.getStateCache();
      }
      return this.state.left.data;
    }
    return null;
  };
  getRightData = () => {
    if (this.state.right.data) {
      if (this.rightTable) {
        return this.rightTable.getStateCache();
      }
      return this.state.right.data;
    }
    return null;
  };
  onLeftExtend = () => {
    const { extend } = this.state;
    this.setState({
      extend: extend === 0 ? 1 : extend - 1,
    });
  };
  onRightExtend = () => {
    const { extend } = this.state;
    this.setState({
      extend: extend === 2 ? 1 : extend + 1,
    });
  };
  /** 挑单 start */
  pick = async (indexs, isSug, enumerations) => {
    if (this.props.disabled) {
      return;
    }
    const { formatter, beforePick, afterPick, uniqueKey, maxNum } = this.props;
    let data = this.leftData;
    const picks = this.rightData;
    // 选中的左屏数据
    let selectedData = [];
    let unselectedData = [];
    if (isSug) {
      const inLeftIndex = data.findIndex(item => item[uniqueKey] === indexs[0][uniqueKey]);
      if (inLeftIndex > -1) {
        indexs = [inLeftIndex]; // eslint-disable-line
      } else {
        data = [...data.concat(indexs)];
        const curIndexs = [];
        indexs.forEach((item, i) => {
          curIndexs.push(data.length - 1 - i);
        });
        indexs = curIndexs; // eslint-disable-line
      }
    }
    if (!indexs) {
      selectedData = data;
      unselectedData = [];
    } else {
      data.forEach((item, i) => {
        if (indexs.includes(i)) {
          selectedData.push(item);
        } else {
          unselectedData.push(item);
        }
      });
    }
    if (maxNum > 0 && selectedData.length + picks.length > maxNum) {
      showInfo(`最大挑单数量不能超过${maxNum}条！`);
      return;
    }
    // 过滤右屏中已经存在的数据
    if (isSug) {
      const rightMap = {};
      picks.forEach(item => {
        rightMap[item[uniqueKey]] = true;
      });
      selectedData = selectedData.filter(item => !rightMap[item[uniqueKey]]);
    }

    if (beforePick && !(await beforePick({ indexs, data: selectedData, picks }))) {
      return;
    }
    if (formatter) {
      selectedData = selectedData.map(formatter);
    }
    if (isSug) {
      const sugEnum = formateEnum(enumerations, this.state.right.header);
      const rightEnum = mergeSugEnum({
        enumerations: this.state.enumerations,
        header: this.state.right.header,
        sugEnum,
      });
      const leftEnum = mergeSugEnum({ enumerations: this.state.enumerations, header: this.state.left.header, sugEnum });
      this.rightTable.resetData(picks.concat(selectedData), rightEnum);
      this.leftTable.resetData(unselectedData, leftEnum);
    } else {
      this.rightData = picks.concat(selectedData);
      this.leftData = unselectedData;
    }
    if (afterPick) {
      setTimeout(() => {
        afterPick();
      }, 200);
    }
  };
  unpick = async indexs => {
    if (this.props.disabled) {
      return;
    }
    const { unformatter, beforeUnpick, afterUnpick } = this.props;
    const data = this.rightData;
    const picks = this.leftData;
    // 选中的数据
    let selectedData = [];
    let unselectedData = [];
    if (!indexs) {
      selectedData = data;
      unselectedData = [];
    } else {
      data.forEach((item, i) => {
        if (indexs.includes(i)) {
          selectedData.push(item);
        } else {
          unselectedData.push(item);
        }
      });
    }
    if (beforeUnpick && !(await beforeUnpick({ indexs, data: selectedData, picks: data }))) {
      return;
    }
    if (unformatter) {
      selectedData = selectedData.map(unformatter);
    }
    this.leftData = picks.concat(selectedData);
    this.rightData = unselectedData;
    if (afterUnpick) {
      setTimeout(() => {
        afterUnpick();
      }, 200);
    }
  };
  onPick = () => {
    const indexs = this.leftTable.getStateSelectes();
    if (indexs.length) {
      this.pick(indexs);
    }
  };
  onUnpick = () => {
    const indexs = this.rightTable.getStateSelectes();
    if (indexs.length) {
      this.unpick(indexs);
    }
  };
  onAllPick = () => {
    this.pick();
  };
  onAllUnpick = () => {
    this.unpick();
  };
  onDoublePick = index => {
    this.pick([index]);
  };
  onDoubleUnpick = index => {
    this.unpick([index]);
  };
  onSugPick = (data, otherParam) => {
    if (!data) {
      return;
    }
    this.pick([data], true, otherParam.enumDict);
  };
  /** 挑单 end */
  onFilter = () => {
    const filter = this.leftTable ? this.leftTable._filter : {};
    const query = _.get(this.props.fetchReq, ['left', 'query'], {});
    const { header } = this.state.left;
    const leftQuery = getQueryWithFilter({ filter, query, header, sort: this.sort });
    this.fetch({
      leftQuery,
      pageNum: 1,
      fetchMode: 'body',
    });
  };
  onSort = (col, sort, fetch = true) => {
    if (!fetch) {
      return;
    }
    this.sort = sort;
    this.onFilter();
  };
  onLeftTurnPage = (pageSize, fix, _pageNum) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const pageNum = Number.isInteger(fix) ? this.state.left.pageNum + fix : _pageNum;
    const { left } = this.state;
    this.setState({
      left: {
        ...left,
        page_zie: pageSize,
        page_num: pageNum,
      },
    });
    this.fetch({
      pageNum,
      pageSize,
      fetchMode: 'body',
    });
  };
  onLeftConfig = ({ key } = {}) => {
    const { category, tab } = this.tableInfo;
    switch (key) {
      case 'set_header':
        tableSetHeader({ category, tab: `${tab}_left` }, () => this.fetch({ fetchMode: 'all', needRight: false }));
        break;
      case 'set_sort':
        tableSetSort({ category, tab: `${tab}_left` }, () => this.fetch({ fetchMode: 'all', needRight: false }));
        break;
      default:
        break;
    }
  };
  onRightConfig = ({ key } = {}) => {
    const { category, tab } = this.tableInfo;
    switch (key) {
      case 'set_header':
        tableSetHeader({ category, tab: `${tab}_right` }, () => this.fetch({ fetchMode: 'all', needRight: true }));
        break;
      case 'set_sort':
        tableSetSort({ category, tab: `${tab}_right` }, () => this.fetch({ fetchMode: 'all', needRight: true }));
        break;
      default:
        break;
    }
  };

  onTableChange = async (rowIndex, columnKey, value, row) => {
    if (this.props.onTableChange) {
      // 可以通过返回patchs来更新数据
      // patchs: [{ rowIndex, columnKey, value }]
      const patchs = await this.props.onTableChange({
        rowIndex,
        columnKey,
        value,
        row,
        data: this.rightData,
      });
      // 以下代码为了性能 依赖 了 table 内部逻辑
      if (patchs && patchs.length) {
        const { header } = this.state.right;
        // 重新计算 更新合计
        const needUpdateTotalCols = [];
        patchs.forEach(item => {
          this.rightTable._updateCache(item.rowIndex, item.columnKey, item.value); // 更新缓存数据
          const colInfo = header[item.columnKey];
          if (colInfo.summable) {
            needUpdateTotalCols.push(item.columnKey);
          }
        });
        const selects = this.rightTable.getStateSelectes();
        if (needUpdateTotalCols.length && selects.length > 0) {
          const footerRow = this.rightTable.getFooterRowData(this.rightTable.props, this.rightTable.state, true);
          setTimeout(() => {
            this.rightTable._updateCacheRow('footerSelect', footerRow.footerSelect, 'show');
            this.rightTable.setState({ footerSelect: footerRow.footerSelect, footerTotal: footerRow.footerTotal });
          });
        } else {
          this.rightTable.forceUpdate();
        }
      }
    }
  };

  oLinkClick = type => (rowIndex, column, data, obj) => {
    const { category, tab } = this.tableInfo;
    const newKey = `${category}_${column}`;
    const tableData = type === 'left' ? this.leftData : this.rightData;
    const that = type === 'left' ? this.leftTable : this.rightTable;
    const filterQuery = type === 'left' ? this.leftTable._filter : this.rightTable._filter;
    const { header } = this.state[type];
    const listPage = proxyListPage(tableData, {
      category,
      tab,
      type: 'tablePicker',
      refresh: this.fetch,
      filterQuery,
      header,
    });
    handleGetModule(newKey, 'batch', this.emitter, () => {
      this.emitter.emit(formatCamelCase(newKey), listPage, rowIndex, column, data, obj);
    });
  };
  onLeftLinkClick = this.oLinkClick('left');
  onRightLinkClick = this.oLinkClick('right');

  onRightButtnClick = (selected, _key, subList) => {
    let key = _key;
    if (subList && subList.length && !selected) return false;
    if (subList && subList.length && selected) {
      key = selected.key;
    }
    switch (key) {
      case 'set_header':
      case 'set_sort':
        this.onRightConfig({ key });
        break;
      case 'pick_refresh':
        this.fetch({
          needRight: true,
        });
        break;
      default:
        if (this.props.onRightButtnClick) {
          this.props.onRightButtnClick({
            key,
            data: this.rightData,
            selects: this.rightTable.getStateSelectes(),
            header: this.rightHeader,
            total: this.rightTotal,
            selectedTotal: this.rightSelectedTotal,
          });
        }
        break;
    }
  };

  saveTableStyle = type => body => {
    const batchTableInfo = this.props.batchTableInfo || {};
    const tableFilter = batchTableInfo.tableFilter || {};
    const table = type === 'left' ? this.leftTable : this.rightTable;
    const { tab, category } = this.tableInfo;
    if (typeof body === 'string') {
      table.handleSaveTableStyle();
      return;
    }
    const tabVal = `${tab}_${type}`;
    actions.listpageServer.setTable(category, tabVal, body, table);
  };
  leftSaveTableStyle = this.saveTableStyle('left');
  rightSaveTableStyle = this.saveTableStyle('right');

  onSubmit = async () => {
    if (this.props.onSubmit) {
      this.setState({
        submitting: true,
      });
      await this.props.onSubmit(this.rightData);
      this.setState({
        submitting: false,
      });
    }
  };
  renderLeft = () => {
    const { leftTableContextMenu } = this.props;
    const { extend, left, enumerations } = this.state;
    const { page_size, page_num, total = {}, buttons = {} } = left;
    const isShowContextMenu = !!leftTableContextMenu;
    return (
      <div className={cls('left')}>
        <div className={cls('left-title')}>
          <Icon iconType="icon-refresh" classname={cls('left-refresh')} onClick={this.fetch} />
          <div className={cls('left-extra')}>{this.props.leftExtra}</div>
          <div className={cls('left-tools')}>
            <Icon iconType="icon-arrow-r" tips="选中行进右屏" onClick={this.onPick} />
            <Icon iconType="icon-next" tips="所有行进右屏" onClick={this.onAllPick} />
            <Icon
              iconType="icon-extend-right"
              tips={extend === 0 ? '恢复左右屏' : '左屏铺满'}
              onClick={this.onLeftExtend}
            />
          </div>
        </div>
        <div className={cls('left-table')}>
          <Table
            ref={ref => (this.leftTableWrap = ref)}
            entryKeyBatchSearch
            enableQuickFilter
            onlyViewportColumn
            markFrontOnly
            isRowDbClick
            preLoadStore
            sort={left.sort}
            header={left.header}
            data={left.data}
            total={total}
            enumerations={enumerations}
            handleRowDbClick={this.onDoublePick}
            handleSearchFilter={this.onFilter}
            handleQuickFilter={this.onFilter}
            handleHeaderSortable={this.onSort}
            handleLinkClick={this.onLeftLinkClick}
            handleSaveTableStyle={this.leftSaveTableStyle}
            rowRightMenu={leftTableContextMenu}
            {...left._table}
            isShowRowContextMenu={isShowContextMenu}
          />
        </div>
        <div className={cls('left-footer')}>
          <Page
            prevPage={this.onLeftTurnPage}
            nextPage={this.onLeftTurnPage}
            pageSize={page_size || 100}
            pageTotal={total.count}
            pageNum={page_num || 1}
            toggle
            className={cls('left-page')}
          />
          {buttons.config && (
            <MenuBtn
              subList={buttons.config.sublist}
              buttonProps={{ iconType: 'icon-setting' }}
              classname={cls('left-config')}
              style={{ width: 165 }}
              display
              onClick={this.onLeftConfig}
            />
          )}
        </div>
      </div>
    );
  };
  renderRight = () => {
    const { submitButtonProps, sugApi, uniqueKey, showSug, searchIcon, disabled, orderType, rightTableContextMenu } =
      this.props;
    const { extend, right, submitting, enumerations } = this.state;
    const { buttons = {} } = right;
    const isShowContextMenu = !!rightTableContextMenu;
    return (
      <div className={cls('right')}>
        <div className={cls('right-title')}>
          {!disabled && (
            <div className={cls('right-tools')}>
              <Icon
                iconType="icon-extend-left"
                tips={extend === 2 ? '恢复左右屏' : '右屏铺满'}
                onClick={this.onRightExtend}
              />
              <Icon iconType="icon-previous" tips="所有行进右屏" onClick={this.onAllUnpick} />
              <Icon iconType="icon-arrow-l" tips="选中行进右屏" onClick={this.onUnpick} />
            </div>
          )}
          {showSug && !disabled && (
            <ScanSug
              className={cls('right-sug')}
              inputIconType={searchIcon}
              uniqueKey={uniqueKey}
              fetchApi={sugApi}
              isScan={false}
              orderSugInfo={this.onSugPick}
              filterTips={orderType}
            />
          )}
          <div className={cls('right-extra')}>
            {disabled && (
              <OrderButtons data={buttons} className={cls('right-buttons')} handleClick={this.onRightButtnClick} />
            )}
            {this.props.rightExtra}
          </div>
          {!disabled && !!submitButtonProps && (
            <ButtonIcon {...submitButtonProps} loading={submitting} onClick={this.onSubmit} />
          )}
        </div>
        <div className={cls('right-table')}>
          <Table
            ref={ref => (this.rightTableWrap = ref)}
            sort={right.sort}
            header={right.header}
            data={right.data}
            isRowDbClick
            enumerations={enumerations}
            handleRowDbClick={this.onDoubleUnpick}
            handleSelectDropSelect={this.onTableChange}
            handleBlur={this.onTableChange}
            handleLinkClick={this.onRightLinkClick}
            handleSaveTableStyle={this.rightSaveTableStyle}
            frontFilter
            frontSort
            rowRightMenu={rightTableContextMenu}
            {...right._table}
            isShowRowContextMenu={isShowContextMenu}
          />
        </div>
        <div className={cls('right-footer')}>
          {!disabled && buttons.config && (
            <MenuBtn
              subList={buttons.config.sublist}
              buttonProps={{ iconType: 'icon-setting' }}
              classname={cls('right-config')}
              style={{ width: 165 }}
              display
              onClick={this.onRightConfig}
            />
          )}
        </div>
      </div>
    );
  };
  render() {
    const { className, disabled, style } = this.props;
    const { extend, loading, widths } = this.state;
    const adjustStyle = widths[extend];
    return (
      <Load spinning={loading} className={`${className} ${cls('', { disabled })}`} style={style}>
        <AdjustableDiv
          drager={dragger}
          additionStyle={adjustStyle}
          left={this.renderLeft()}
          right={this.renderRight()}
        />
      </Load>
    );
  }
}

export default TablePicker;
