exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-dropdown__menu{position:absolute;z-index:2000;background:#fff;border-top:1px solid #ccc;box-shadow:0 2px 6px 0 rgba(0,0,0,.2);border-radius:2px;max-width:100vw}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-dropdown__menu",
	"fn-dropdown__menu": "fn-dropdown__menu"
};