exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".drag-div{width:8px;height:100%;float:left;border:1px solid #333;cursor:e-resize;opacity:0}", ""]);

// exports
exports.locals = {
	"drag-div": "drag-div"
};