exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".link{position:relative}.link .gui-link{font-size:12px}", ""]);

// exports
exports.locals = {
	"prefixCls": "link",
	"link": "link",
	"gui-link": "gui-link"
};