/**
 * Created by Aceh on 2017/01/01.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classname from 'classnames';
import { prefixCls } from './index.scss';

window.tmp_loading = 0;
export default class Loading extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { spinning: props.spinning };
  }

  static defaultProps = {
    autoStop: true,
    maxTime: 10000,
  };

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    spinning: PropTypes.bool, // true -> loading
    style: PropTypes.object,
    autoStop: PropTypes.bool,
    maxTime: PropTypes.number,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.spinning !== this.props.spinning) {
      // window.tmp_loading++
      this.setStatus(nextProps.spinning);
    }
  }

  // 10、15s 自动去除loading状态
  longTimeAutoStop = () => {
    this.clearAutoStop();
    this.timer = setTimeout(() => {
      this.setState({ spinning: false });
      // recordLog({ message: `加载遮罩loading 超时：${Date()}` })
    }, this.props.maxTime || 10000);
  };
  clearAutoStop = () => this.timer && clearTimeout(this.timer);
  setStatus = spinning => {
    if (spinning) {
      this.props.autoStop && this.longTimeAutoStop();
    } else {
      this.clearAutoStop();
    }
    this.setState({ spinning });
  };

  render() {
    // window.tmp_loading > 1000 && (window.tmp_loading = 0)
    return (
      <div style={{ height: '100%', ...this.props.style }}>
        <div
          className={classname('loader', `${prefixCls}__loader`, `${prefixCls}__loader${window.tmp_loading % 2}`, {
            hide: !this.state.spinning,
            [`${this.props.className}_loader`]: this.props.className,
          })}
        >
          <div className="loading-icon line-spin-fade-loader" />
        </div>
        <div
          className={classname(`${prefixCls}__content`, {
            [this.props.className]: this.props.className,
            [`${prefixCls}_whitebg`]: this.state.spinning,
          })}
          ref={this.props.contentRef}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
