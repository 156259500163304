var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-checkbox-pure{background:#fff;padding:0;width:13px;height:13px;border:1px solid #8ab0c5;border-radius:2px;-webkit-appearance:none;cursor:pointer;vertical-align:middle}.fn-checkbox-pure:focus,.fn-checkbox-pure:hover{outline:0;border:1px solid #0097cf}.fn-checkbox-pure:checked{border:0;background:#0097cf url(" + escape(require("images/checkmark.svg?url")) + ") 50% no-repeat;background-size:85%}.fn-checkbox-pure[disabled]{cursor:not-allowed;border:1px solid #ddd;background:#f8f8f8}.fn-checkbox-pure[disabled]:focus,.fn-checkbox-pure[disabled]:hover{border:1px solid #ddd}.fn-checkbox-pure[disabled]:checked{background:#f8f8f8 url(" + escape(require("images/checkmarkgray.svg?url")) + ") 50% no-repeat;background-size:85%}.fn-checkbox-label{font-size:14px;height:14px;line-height:1;vertical-align:middle}.fn-checkbox-label .fn-checkbox-pure{margin:0 4px;vertical-align:-1.5px}.fn-switch-pure{width:24px;height:14px;background:#ddd;border-radius:200px;-webkit-appearance:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;outline:none;position:relative;cursor:pointer}.fn-switch-pure:focus{outline-style:none}.fn-switch-pure:before{content:\"\";position:absolute;top:1px;bottom:1px;left:1px;width:12px;border-radius:50%;background-color:#fff}.fn-switch-pure:checked{background:#0097cf}.fn-switch-pure:checked:before{left:auto;right:1px}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-checkbox-pure",
	"switchCls": "fn-switch-pure",
	"fn-checkbox-pure": "fn-checkbox-pure",
	"fn-checkbox-label": "fn-checkbox-label",
	"fn-switch-pure": "fn-switch-pure"
};