import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactDOM, { findDOMNode } from 'react-dom';
import { throttle, iterateParents, scrollable } from 'utils';
import { prefixCls } from './index.scss';

const cloneWithoutStyle = target => {
  const ele = target.cloneNode(true);
  ele.style.maxHeight = '';
  ele.style.left = '';
  ele.style.right = '';
  ele.style.top = '';
  ele.style.bottom = '';
  return ele;
};

export default class Dropdown extends Component {
  static propTypes = {
    menu: PropTypes.object, // 弹出内容
    direction: PropTypes.string, // 弹出方向 auto
    children: PropTypes.any,
    disabled: PropTypes.bool,
    alignWidth: PropTypes.bool, // 宽度对齐,
    overlap: PropTypes.bool, // 可覆盖子元素
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    maxHeight: PropTypes.number,
  };

  static defaultProps = {
    direction: 'auto',
    maxHeight: 300,
  };

  preMenuSize = {};

  componentDidUpdate = () => this.opened && this.props.menu && this.appendToContainer(this.setPosition);

  componentWillUnmount = () => this.opened && this.close();

  appendToContainer = cb => ReactDOM.unstable_renderSubtreeIntoContainer(this, this.props.menu, this.container, cb);

  setPosition = () => {
    if (!this.container) return;
    const { alignWidth, overlap, maxHeight } = this.props;
    const menu = this.container;
    const menuClone = document.body.appendChild(cloneWithoutStyle(menu));
    // eslint-disable-next-line react/no-find-dom-node
    const ele = findDOMNode(this);
    const position = ele.getBoundingClientRect();
    const { top } = position;
    const { left } = position;
    const wrap = document.documentElement;
    const { scrollTop } = wrap;
    const { scrollLeft } = wrap;
    const width = ele.offsetWidth;
    const height = ele.offsetHeight;
    const style = { top: top + scrollTop + (overlap ? 0 : height), left };
    const menuSize = { width: menuClone.offsetWidth, height: menuClone.offsetHeight };
    const { preMenuSize } = this;

    if (preMenuSize.height !== menuSize.height) menu.style.maxHeight = '';

    this.preMenuSize = menuSize;
    document.body.removeChild(menuClone);

    let { direction } = this.props;

    if (direction === 'auto') {
      let vDir = 'down';
      let hDir = 'left';
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const leftSize = left - scrollLeft + width;
      const rightSize = windowWidth - leftSize + width;
      const upSize = (overlap ? height : 0) + top - 2;
      const downSize = windowHeight - top - (overlap ? 0 : height) - 2;

      if (downSize < menuSize.height && downSize < maxHeight && upSize > downSize) {
        vDir = 'up';
        menu.style.maxHeight = `${Math.min(upSize, maxHeight)}px`;
        menu.style.bottom = `${downSize + (overlap ? 0 : height)}px`;
        menu.style.top = '';
      } else {
        menu.style.maxHeight = `${Math.min(downSize, maxHeight)}px`;
        menu.style.top = `${style.top}px`;
        menu.style.bottom = '';
      }
      if (!alignWidth && rightSize < menuSize.width) {
        if (leftSize >= menuSize.width) {
          hDir = 'right';
          menu.style.right = `${windowWidth - leftSize}px`;
          menu.style.left = '';
        } else {
          menu.style.right = 0;
          menu.style.left = '';
        }
      } else {
        menu.style.left = `${style.left}px`;
        menu.style.right = '';
      }
      direction = `${hDir}_${vDir}`;
    }

    alignWidth && (menu.style.width = `${ele.offsetWidth}px`);
    menu.style.minWidth = `${ele.offsetWidth}px`;
  };

  positionHandler = throttle(this.setPosition, 50);

  clickHandler = e => !this.container.contains(e.target) && !this.ele.contains(e.target) && this.close();

  off = () => {
    window.removeEventListener('mousedown', this.clickHandler);
    window.removeEventListener('resize', this.positionHandler);
    iterateParents(
      this.ele,
      parent => scrollable(parent) && parent.removeEventListener('scroll', this.positionHandler),
    );
  };

  toggle = () => !this.props.disabled && (this.opened ? this.close() : this.open());

  open() {
    if (this.opened || this.props.disabled) return;
    this.container = document.createElement('div');
    this.container.className = prefixCls;
    // eslint-disable-next-line react/no-find-dom-node
    this.ele = findDOMNode(this);
    document.body.appendChild(this.container);
    this.opened = true;
    this.props.menu &&
      this.appendToContainer(() => {
        this.props.onOpen && this.props.onOpen(this);
        this.setPosition();
      });
    window.addEventListener('mousedown', this.clickHandler);
    window.addEventListener('resize', this.positionHandler);
    iterateParents(this.ele, parent => scrollable(parent) && parent.addEventListener('scroll', this.positionHandler));
  }

  close() {
    this.container && ReactDOM.unmountComponentAtNode(this.container);
    this.container && document.body.removeChild(this.container);
    this.container = null;
    this.opened = false;
    this.off();
    this.props.onClose && this.props.onClose(this);
  }

  render = () => this.props.children;
}
