exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-range-input{display:inline-block;vertical-align:middle;height:24px;color:#333}.fn-range-input.fn-range-input--disabled{color:#999}.fn-range-input .fn-input{width:80px;text-align:center}.fn-range-input .fn-icon-help{margin-left:4px;color:#3c8bdc}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-range-input",
	"fn-range-input": "fn-range-input",
	"fn-range-input--disabled": "fn-range-input--disabled",
	"fn-input": "fn-input",
	"fn-icon-help": "fn-icon-help"
};