import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Dropdown, Icon, PureInput, CheckBox, Button, Flex, Tips } from 'components';
import { fetchApi } from 'utils';
import _ from 'lodash';
import { prefixCls } from './index.scss';

class ButtonDrop extends PureComponent {
  static propTypes = {
    showCheckBox: PropTypes.bool, // 显示 多选框
    data: PropTypes.array,
    showSeachInput: PropTypes.bool,
    showHead: PropTypes.bool, // 显示head
    onChange: PropTypes.func.isRequired, // 参数为：value，value变化，点击的rowData
    minWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    sugApi: PropTypes.string, // sug 接口 api
    sugReq: PropTypes.func, // 组装req body
    sugRes: PropTypes.func, // 处理返回数据
    headData: PropTypes.array, // key,desc
    firstLoad: PropTypes.bool, // 是否首次fetch
    filter: PropTypes.string,
    comparer: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    multiple: PropTypes.bool, // 多选
    children: PropTypes.object,
    iconType: PropTypes.string,
    label: PropTypes.string,
    widths: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    allRow: PropTypes.object, // 是否有全部
    hasBorder: PropTypes.bool, // 是否有border
    disabled: PropTypes.bool,
    tips: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };
  static defaultProps = {
    showCheckBox: true,
    showSeachInput: true,
    minWidth: 50,
    value: null,
    filter: 'key',
    headData: null,
    multiple: true,
    firstLoad: true,
    style: {},
    data: [],
    sugRes: res => res.res && Object.values(res.res),
    disabled: false,
    tips: '',
  };

  constructor(props) {
    super(props);
    let { value } = props;
    const { multiple } = props;
    if (multiple && multiple !== 'false') {
      if (!Array.isArray(value)) {
        value = [];
        console.log('value is not an array');
      }
    }
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      drop: {},
      search: '',
      open: false,
      data: props.data,
      value: value || '',
    };
    this.checkAll = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({
        // eslint-disable-next-line no-nested-ternary
        value: _.isEqual(nextProps.value, []) ? (nextProps.multiple ? [] : '') : nextProps.value,
      });
    }
  }

  _onSearch = (search = '') => {
    this.searcher && clearTimeout(this.searcher);
    const { sugApi, sugReq, sugRes } = this.props;
    this.setState({
      search,
    });
    this.searcher = setTimeout(() => {
      fetchApi(sugApi, { method: 'POST', body: sugReq(search) })
        .then(res => sugRes(res))
        .then(data => this.setState({ data }));
    }, 200);
  };
  _onChange = (row, checked) => {
    // e && e.stopPropagation()
    const { filter, onChange, multiple } = this.props;
    let { value } = this.state;
    const theValue = { key: row[filter], checked };
    if (multiple && multiple !== 'false') {
      if (checked) {
        value = [...value, row[filter]];
      } else {
        const index = value.findIndex(v => v === row[filter]);
        value.splice(index, 1);
        value = [...value];
      }
    } else if (checked) {
      value = row[filter];
    } else {
      value = '';
    }
    this.setState({ value });
    onChange(value, theValue, row);
  };
  _isChecked = theValue => {
    const { multiple } = this.props;
    const { value } = this.state;
    if (multiple && multiple !== 'false') {
      if (!value.length) {
        return false;
      }
      for (let i = 0; i < value.length; i++) {
        if (String(value[i]) === String(theValue)) {
          return true;
        }
        if (i === value.length - 1) {
          return false;
        }
      }
    }
    return theValue === value;
  };
  _getWidth = (i, m) => {
    const { widths, width } = this.props;
    if (widths && widths[i]) {
      return widths[i];
    }
    return (width - 30) / (m - 1);
  };
  onOpen = () => {
    const { data, search } = this.state;
    if ((!data || !data.length) && this.props.sugApi) {
      this._onSearch(search);
    }
    this.props.onClick && this.props.onClick(data);
    this.setState({ open: true });
  };
  select = () => this.input.input.select();
  renderMenu = () => {
    let { minWidth } = this.props;
    const {
      showSeachInput,
      maxHeight,
      headData,
      filter,
      showCheckBox,
      showHead,
      allRow,
      hasBorder,
      disabled,
      sugApi,
      multiple,
    } = this.props;
    if (disabled) {
      return null;
    }
    const { data } = this.state;
    const checkAll = allRow && this._isChecked(allRow[filter]);
    if (!data || !data.length) {
      return <div />;
    }
    minWidth = this.box && this.box.offsetWidth > minWidth ? this.box.offsetWidth : minWidth;
    const { Item } = Flex;
    const checkbox = showCheckBox && multiple && multiple !== 'false';
    return (
      <Flex style={{ maxHeight, minWidth }} align="stretch" dir="column" className={`${prefixCls}-menu`}>
        {sugApi && showSeachInput && (
          <Flex align="stretch" className="search-box">
            <Icon iconType="icon-search" />
            <Item
              Component={PureInput}
              getRef={ref => (this.input = ref)}
              value={this.state.search}
              onClick={() => this.state.search && this.select()}
              onChange={e => this._onSearch(e.target.value)}
            />
          </Flex>
        )}
        <table className={`menu-table ${hasBorder && 'border'}`}>
          {showHead && headData && (
            <thead>
              <tr onClick={e => this._onChange(allRow, !checkAll, e)}>
                {checkbox && (
                  <th className="drop-button-check-column">
                    <CheckBox
                      checked={checkAll}
                      onClick={(checked, e) => {
                        e.stopPropagation();
                        this._onChange(allRow, checked);
                      }}
                    />
                  </th>
                )}
                {headData.map(v => (
                  <th key={v.key} style={{ minWidth: 100 }}>
                    {v.desc}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {!showHead && allRow && (
              <tr onClick={e => this._onChange(allRow, !checkAll, e)}>
                {checkbox && (
                  <td className="drop-button-check-column">
                    <CheckBox
                      checked={checkAll}
                      onClick={(checked, e) => {
                        e.stopPropagation();
                        this._onChange(allRow, checked);
                      }}
                    />
                  </td>
                )}
                {headData ? (
                  headData.map(vv => <td key={vv.key}>{allRow[vv.key]}</td>)
                ) : (
                  <td key={allRow[filter]}>{allRow[filter]}</td>
                )}
              </tr>
            )}
            {data.map((v, i) => {
              const checkedNow = checkAll || this._isChecked(v[filter]);
              return (
                <tr key={i} className={checkedNow && 'active'} onClick={() => this._onChange(v, !checkedNow)}>
                  {checkbox && (
                    <td className="drop-button-check-column">
                      <CheckBox
                        checked={checkedNow}
                        onClick={(checked, e) => {
                          e.stopPropagation();
                          this._onChange(v, checked);
                        }}
                      />
                    </td>
                  )}
                  {headData ? headData.map(vv => <td key={vv.key}>{v[vv.key]}</td>) : <td key={i}>{v[filter]}</td>}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Flex>
    );
  };

  render() {
    const { iconType, label, className, style, multiple, tips } = this.props;
    const { value } = this.state;
    const checked = multiple && multiple !== 'false' ? value && value.length : value;
    let flex = (
      <Flex
        align="center"
        justify="center"
        Component={Button}
        classname={`${prefixCls} ${className} drop-any ${checked ? 'checked' : ''} ${this.state.open ? 'active' : ''} ${
          this.props.disabled ? 'disabled' : ''
        }`}
        height={28}
        onClick={() => (this.state.open ? this.drop.close() : this.drop.open())}
        style={style}
        getRef={ref => (this.box = ref)}
      >
        {iconType && <Icon style={{ marginRight: 6 }} iconType={iconType} />}
        <label className="drop-label">{label}</label>
      </Flex>
    );
    tips && (flex = <Tips title={tips}>{flex}</Tips>);
    return (
      <Dropdown
        ref={drop => (this.drop = drop)}
        menu={this.renderMenu()}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.onOpen()}
      >
        {flex}
      </Dropdown>
    );
  }
}
export default ButtonDrop;
