import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import bem from 'utils/bem';
import { prefixCls } from './index.scss';

const cls = bem(prefixCls);
/**
 * 自适应大小 布局组件
 * 核心逻辑：通过内部不可见iframe contentWindow 的onresize 事件来更新宽度/高度
 */
class AutoSize extends React.PureComponent {
  static defaultProps = {
    throttle: 300,
  };
  static propTypes = {
    width: PropTypes.bool,
    height: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.func,
  };
  state = {};
  componentDidMount() {
    this.initWatch();
  }
  initWatch = () => {
    // 节流，防止更新太频繁
    this.watch.contentWindow.onresize = _.throttle(this.updateSize, this.props.throttle);
    this.updateSize();
  };
  updateSize = () => {
    const { width, height } = this.props;
    if ((width || height) && this.watch) {
      const state = {};
      if (width) {
        state.width = this.watch.clientWidth;
      }
      if (height) {
        state.height = this.watch.clientHeight;
      }
      this.setState(state);
    }
  };
  render() {
    const { width, height } = this.state || {};
    const { className, style } = this.props;
    const inited = width || height;
    return (
      <div className={`${cls('')} ${className}`} style={style}>
        {inited ? this.props.children(this.state) : null}
        <iframe className={cls('watch')} ref={ref => (this.watch = ref)} title="watch" />
      </div>
    );
  }
}

export default AutoSize;
