/**
 * 内容超出容器宽度，展示省略号，并启用Tooltip。
 * 目前仅支持单行文本。
 */
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Tooltip } from '@gui/web-react';
import { tooltipPrefixCls } from './index.scss';
import classnames from 'classnames';
import bem from 'utils/bem';
import PropTypes from 'prop-types';

const cls = bem(tooltipPrefixCls);

const AutoTooltip = props => {
  const { content, tooltipProps, className } = props;

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const parentRef = useRef();
  const childRef = useRef();

  useLayoutEffect(() => {
    if (!parentRef.current || !childRef.current) return;
    const { height: ph, width: pw } = parentRef.current.getBoundingClientRect();
    const { height: ch, width: cw } = childRef.current.getBoundingClientRect();
    if (!ph || !ch || !pw || !cw) return; // 元素内容为空，不展示tooltip
    if (ch > ph || cw > pw) setTooltipVisible(true); // 内容超出容器，显示Tooltip
  }, [content]);

  return (
    <div
      className={classnames(cls('wrapper'), { [cls('wrapper', 'show-tooltip')]: tooltipVisible }, className)}
      ref={parentRef}
    >
      {tooltipVisible ? (
        <Tooltip position={'top'} {...tooltipProps} content={content}>
          <span className={cls('content', 'show-tooltip')}>{props.children}</span>
        </Tooltip>
      ) : (
        <span ref={childRef} className={cls('content')}>
          {/* 包裹在内层，用于测量内容宽度 */}
          {props.children}
        </span>
      )}
    </div>
  );
};

AutoTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltipProps: PropTypes.shape({}),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default AutoTooltip;
