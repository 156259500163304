exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-switch{width:22px;height:12px;border:1px solid #dfdfdf;background-color:#ccc;box-shadow:inset 0 0 0 0 #dfdfdf;border-radius:9px;background-clip:content-box;-webkit-appearance:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;outline:none;position:relative;cursor:pointer}.fn-switch:focus{outline-style:none}.fn-switch:before{content:\"\";width:10px;height:10px;position:absolute;top:-1px;left:0;border-radius:9px;background-color:#fff;box-shadow:0 1px 3px rgba(0,0,0,.4)}.fn-switch:checked{border-color:#6171da;box-shadow:inset 0 0 0 16px #6171da;background-color:#6171da}.fn-switch:checked:before{left:10px}.fn-switch-animbg{transition:background-color .4s ease}.fn-switch-animbg:checked{box-shadow:inset 0 0 0 0 #dfdfdf;transition:border-color .4s,background-color .4s ease}.fn-switch-animbg{transition:border .4s cubic-bezier(0,0,0,1),box-shadow .4s cubic-bezier(0,0,0,1);outline:none}.fn-switch-animbg:before{transition:left .3s}.fn-switch-animbg:checked{box-shadow:inset 0 0 0 16px #6171da;background-color:#6171da;transition:border .4s ease,box-shadow .4s ease,background-color 1.2s ease}.fn-switch-animbg:checked:before{transition:left .3s}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-switch",
	"fn-switch": "fn-switch",
	"fn-switch-animbg": "fn-switch-animbg"
};