exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-input-wrap{display:inline-block;position:relative}.fn-input-wrap .fn-icon{color:#999;cursor:pointer;height:22px;width:22px;text-align:center;position:absolute;top:1px;right:1px;line-height:22px;z-index:2;background:#fff}.fn-input-wrap .fn-icon.fn-icon-precent,.fn-input-wrap .fn-icon.fn-icon-thousand{background:none}.fn-input-wrap .fn-input{padding-right:24px}.fn-input-wrap .price-snapshot-icon{background:none;color:#0097cf}.fn-input-wrap--icon-outer .fn-input{width:118px}.fn-input-wrap--icon-outer .fn-icon{position:static}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-input-wrap",
	"fn-input-wrap": "fn-input-wrap",
	"fn-icon": "fn-icon",
	"fn-icon-precent": "fn-icon-precent",
	"fn-icon-thousand": "fn-icon-thousand",
	"fn-input": "fn-input",
	"price-snapshot-icon": "price-snapshot-icon",
	"fn-input-wrap--icon-outer": "fn-input-wrap--icon-outer"
};