/**
 * Created by JANY on 2017/10/10.
 */
import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { DateTimePicker } from 'components';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';

import { typeIs } from 'utils';

export default class DateTimeCell extends TableCell {
  static propTypes = {
    onChange: PropTypes.func,
    showTime: PropTypes.bool,
  };

  render() {
    const { cellProps, cellOtherProps, columnProps, onChange } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data } = cellOtherProps;
    const { rowIndex, columnKey, width } = cellProps;
    const { showTime = true, placeholder = '', format, forceFormat = false } = columnProps;
    const defaultValue = data.getShowObjectAt(rowIndex)[columnKey];
    const showFormat = format && typeIs(format, 'string') ? format : undefined;
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <DateTimePicker
          onChange={val => onChange(rowIndex, columnKey, val)}
          defaultValue={defaultValue}
          style={{ width }}
          disabled={isInOtherPropsArray(data.getObjectAt(rowIndex), columnKey, 'disable')}
          showTime={showTime}
          format={showFormat}
          forceFormat={forceFormat}
          placeholder={placeholder}
        />
      </Cell>
    );
  }
}
