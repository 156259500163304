exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-modal-viewer.gui-modal-simple .gui-modal-header{padding:16px 0;margin-bottom:0}.fn-modal-viewer .gui-gimage-preview-mask{background-color:rgba(0,0,0,.3)}.fn-modal-viewer .gui-gimage-preview-toolbar.is-bottom{bottom:20px}.fn-modal-viewer .gui-gallery-thumbnails-container{display:flex;align-items:center}.gui-modal-wrapper{z-index:1030}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-modal-viewer",
	"fn-modal-viewer": "fn-modal-viewer",
	"gui-modal-simple": "gui-modal-simple",
	"gui-modal-header": "gui-modal-header",
	"gui-gimage-preview-mask": "gui-gimage-preview-mask",
	"gui-gimage-preview-toolbar": "gui-gimage-preview-toolbar",
	"is-bottom": "is-bottom",
	"gui-gallery-thumbnails-container": "gui-gallery-thumbnails-container",
	"gui-modal-wrapper": "gui-modal-wrapper"
};