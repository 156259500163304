/**
 * Created by wangnaihe on 2017/9/21.
 */
import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { Component } from 'react';
import { AddrSug, G7AddrSug } from 'components';
import { shallowCompareIgnoreFunc } from 'utils';

export default class Address extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    handleSelected: PropTypes.func,
    needSelect: PropTypes.bool,
    value: PropTypes.any,
    className: PropTypes.string,
    g7Map: PropTypes.bool, // 是否开启G7地址库
  };
  static defaultProps = {
    g7Map: false,
  };

  constructor(prop) {
    super(prop);
    const { g7Map } = prop;
    const addressLibrary = window.company_setting?.address_library?.values; // G7地址库系统设置
    this.state = {
      isOpenG7Map: g7Map && !!addressLibrary,
    };
  }

  shouldComponentUpdate = shallowCompareIgnoreFunc;

  customValidity = async () => {
    const { needSelect, value, customValidity } = this.props;
    if (needSelect && value && value.show_val && !value.province) {
      return '请从下拉选择';
    } else if (customValidity) {
      return await customValidity(value);
    }
    return '';
  };
  handleSelected = (...params) => {
    // Address组件承接来自运单以及价格计算等多个入口，存在onSelect不一致情况，所以做兼容处理
    const { onSelect, handleSelected } = this.props;
    if (_.isFunction(onSelect)) {
      onSelect(...params);
    }
    if (_.isFunction(handleSelected)) {
      handleSelected(...params);
    }
  };

  focus = () => this.address && this.address.focusInput();

  refAddress = r => (this.address = r);

  render() {
    const { g7Map, needSelect, value, onSelect, className, customValidity, ...rest } = this.props; // eslint-disable-line
    const { isOpenG7Map } = this.state;
    return isOpenG7Map ? (
      <G7AddrSug
        {...rest}
        dataPath={rest['data-path']}
        classname={className}
        ref={this.refAddress}
        defaultValue={value}
        handleSelected={this.handleSelected}
        customValidity={this.customValidity}
      />
    ) : (
      <AddrSug
        {...rest}
        dataPath={rest['data-path']}
        classname={className}
        ref={this.refAddress}
        defaultValue={value}
        handleSelected={this.handleSelected}
        customValidity={this.customValidity}
      />
    );
  }
}
