import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { shallowCompareIgnoreFunc } from 'utils';
import { prefixCls } from './index.scss';

export default class PureCheckbox extends Component {
  static propTypes = {
    checked: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    checked: false,
  };

  shouldComponentUpdate = shallowCompareIgnoreFunc;

  onChange = e => this.props.onChange && this.props.onChange(e);

  render() {
    const { className, ...rest } = this.props;
    const classes = classnames({ [prefixCls]: true, [className]: className });
    return <input type="radio" className={classes} {...rest} onChange={this.onChange} />;
  }
}
