exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-input-multi .baseInput__w{width:calc(100% - 56px)}.fn-input-multi .baseInput__w .fn-input{width:100%}.fn-input-multi ul{display:flex;width:100%;flex-wrap:nowrap;justify-content:space-between;overflow:hidden}.fn-input-multi ul li{position:relative}.fn-input-multi ul li span{display:inline-block;width:50px;font-size:12px;padding:0 0 0 3px}.fn-input-multi ul .fn-icon{color:#999;cursor:pointer;height:22px;width:22px;text-align:center;position:absolute;top:1px;right:58px;line-height:22px;z-index:2;background:#fff}.fn-input-multi ul .input-tip--invalid{width:12px;height:12px}.fn-input-multi input::-webkit-inner-spin-button,.fn-input-multi input::-webkit-outer-spin-button{-webkit-appearance:none}.fn-input-multi input[type=number]{-moz-appearance:textfield}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-input-multi",
	"fn-input-multi": "fn-input-multi",
	"baseInput__w": "baseInput__w",
	"fn-input": "fn-input",
	"fn-icon": "fn-icon",
	"input-tip--invalid": "input-tip--invalid"
};