exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-progress{display:inline-flex;position:relative;width:100%;justify-content:space-between;align-items:center}.fn-progress:hover .fn-progress-tip{display:flex}.fn-progress-chart{display:inline-block;width:100%;height:12px;background-color:var(--trailColor);border-radius:2px}.fn-progress-chart-line{width:var(--progress);height:100%;background-color:var(--strokeColor);border-radius:inherit}.fn-progress-chart-line.active{transition:width .2s linear}.fn-progress-text{width:30px;text-align:center;left:50%;margin-left:-15px}.fn-progress-text,.fn-progress-tip{font-size:12px;color:#fff;position:absolute}.fn-progress-tip{width:-webkit-max-content;width:-moz-max-content;width:max-content;border-radius:4px;display:none;align-items:center;justify-content:center;left:calc(100% + 10px);background-color:rgba(0,0,0,.6);padding:2px 6px}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-progress",
	"fn-progress": "fn-progress",
	"fn-progress-tip": "fn-progress-tip",
	"fn-progress-chart": "fn-progress-chart",
	"fn-progress-chart-line": "fn-progress-chart-line",
	"active": "active",
	"fn-progress-text": "fn-progress-text"
};