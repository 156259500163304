import { eachTree } from '../../../utils/helper';

export const getDisplayFormula = (formula, { variables = [], functions = [], markFunction = true } = {}) => {
  let content = formula;

  if (Array.isArray(variables) && variables.length) {
    const varMap = {};

    eachTree(variables, item => {
      if (item.value) {
        varMap[item.value] = item.label;
      }
    });
    const vars = Object.keys(varMap).sort((a, b) => b.length - a.length);

    // 标记变量
    vars.forEach(v => {
      let from = 0;
      let idx = -1;
      // eslint-disable-next-line no-bitwise, no-cond-assign
      while (~(idx = content.indexOf(v, from))) {
        content = content.replace(v, varMap[v]);
        from = idx + varMap[v];
      }
    });
  }

  if (Array.isArray(functions) && functions.length && markFunction) {
    const funMap = {};

    eachTree(functions.map(item => ({ ...item, children: item.items })) ?? [], item => {
      if (item.name) {
        funMap[item.name] = item.alias || item.name;
      }
    });

    // 标记方法调用
    content = content.replace(/([A-Z]+)\s*\(/g, (_, func, pos) => {
      return funMap[func] ? `${funMap[func]}(` : _;
    });
  }

  return content;
};
