exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".popover{position:absolute;z-index:1500;display:block;font-family:Microsoft YaHei,\\\\5FAE\\8F6F\\96C5\\9ED1,SimSun,宋体,sans-serif;font-style:normal;font-weight:400;letter-spacing:normal;line-break:auto;line-height:1.5;text-align:start;text-decoration:none;text-shadow:none;text-transform:none;white-space:normal;word-break:normal;word-spacing:normal;font-size:.75rem;word-wrap:break-word;background-color:#131e27;background-clip:padding-box;border:1px solid rgba(0,0,0,.2);border-radius:.3rem}.popover-content{padding:6px;width:100%;height:100%;border-radius:inherit;position:relative;z-index:2;overflow:auto;white-space:nowrap;color:#fff}.popover-arrow{padding:4px;border:1px solid rgba(0,0,0,.25);display:inline-block;position:absolute;border-bottom-color:transparent!important;border-left-color:transparent!important;background-color:#131e27;z-index:1}.popover--info{background:#fff;border:1px solid #78aadd!important;box-shadow:0 3px 4px 0 hsla(60,2%,65%,.6);border-radius:4px}.popover--info .popover-arrow{border-color:#78aadd;background:#fff}.popover--info .popover-content{padding:4px 12px;font-size:14px;color:#333}.popover--warn{background:#fff5e3;border:1px solid #f37100!important}.popover--warn .popover-arrow{border-color:#f37100;background:#fff5e3}.popover--warn .popover-content{color:#000}.popover--error{line-height:1;color:#333;white-space:nowrap;background:#ffd0cb;border:1px solid #f87070;border-radius:2px}.popover--error .popover-arrow{background:#ffd0cb;border-color:#f87070}.popover--error .popover-content{padding:4px 6px;color:#333}", ""]);

// exports
exports.locals = {
	"prefixCls": "popover",
	"popover": "popover",
	"popover-content": "popover-content",
	"popover-arrow": "popover-arrow",
	"popover--info": "popover--info",
	"popover--warn": "popover--warn",
	"popover--error": "popover--error"
};