/**
 * Created by JANY on 2017/1/10.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { TreeSelect, SelectDrop } from 'components';
import { typeIs } from 'utils';
import { prefixCls } from './index.scss';

export default class TSelectDrop extends Component {
  constructor(prop) {
    super(prop);
    const { isTreeShow, defaultValue = {}, uniqueKey } = prop;
    const { defaultSelected = [], treeDefaultSelected } = defaultValue;
    const initSelected = typeIs(defaultSelected, 'array') ? defaultSelected[0] : defaultSelected;
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isTreeShow,
      defaultSelected,
      treeDefaultSelected,
    };
    this.handleDSelected({ [uniqueKey]: initSelected });
    this.isTreeShow = isTreeShow;
  }

  static propTypes = {
    classname: PropTypes.string,
    style: PropTypes.object,
    fieldName: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    isTreeShow: PropTypes.bool,
    threeState: PropTypes.bool,
    defaultValue: PropTypes.object,
    data: PropTypes.array,
    treeData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    selectDropKey: PropTypes.string,
    treeSelectKey: PropTypes.string,
    showKey: PropTypes.string,
    uniqueKey: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dropwidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    twidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    treeShowCondition: PropTypes.object,
    handleSelected: PropTypes.func,
    onClick: PropTypes.func,
    treeSelected: PropTypes.func,
  };
  static defaultProps = {
    classname: '',
    isTreeShow: false,
    threeState: true,
    dropwidth: 115,
  };
  handleDSelected = selectVal => {
    const { uniqueKey, treeShowCondition } = this.props;
    const { key, value } = treeShowCondition;
    const treeShowKey = `${selectVal[key] || ''}`;
    const selectedKey = `${selectVal[uniqueKey] || ''}`;
    if (treeShowKey.toString() === value.toString()) {
      // 选择指定值 切换到树形下拉
      // eslint-disable-next-line react/no-unused-state
      this.setState({ isTreeShow: true }, () => {
        document.querySelector('.rc-tree-select-selection__rendered input').focus();
      });
      this.isTreeShow = true;
    } else {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ isTreeShow: false });
      this.isTreeShow = false;
    }
    this.setState({ defaultSelected: selectedKey });
    this.props.handleSelected &&
      this.props.handleSelected(
        { [this.props.treeSelectKey]: this.state.treeDefaultSelected, [this.props.selectDropKey]: selectedKey },
        selectVal,
        this.props.fieldName,
      );
  };
  handleDClick = (inputVal, fieldName, ...args) => {
    const { key, value } = this.props.treeShowCondition;
    const selectVal = args[args.length - 1][0] || {}; // selectdrop 最后一个为数组形式完整值
    const selectedKey = `${selectVal[key] || ''}`;
    // 选择自定义时 切换到树形下拉
    // eslint-disable-next-line react/no-unused-state
    this.setState({ isTreeShow: selectedKey === value });
    this.isTreeShow = selectedKey === value;
  };
  onTChange = checkedVal => {
    this.setState({ treeDefaultSelected: checkedVal });
    this.props.treeSelected &&
      this.props.treeSelected(
        { [this.props.treeSelectKey]: checkedVal, [this.props.selectDropKey]: this.state.defaultSelected },
        checkedVal,
        this.props.fieldName,
      );
  };
  // sd ----------------------------------
  // 树形下拉显隐改变
  tDropdownVisibleChange = isTreeShow => {
    if (!isTreeShow && !(this._dRef && this._dRef.isMouseIn)) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ isTreeShow });
      this.isTreeShow = isTreeShow;
    } else if (isTreeShow) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ isTreeShow });
      this.isTreeShow = isTreeShow;
    }
    // 树形组件用 必须返回
    return true;
  };

  render() {
    const {
      classname,
      style,
      fieldName,
      data,
      disabled,
      threeState,
      treeData,
      showKey,
      uniqueKey,
      readOnly,
      dropwidth,
    } = this.props;
    const tshowKey = treeData.showKey;
    const tuniqueKey = treeData.uniqueKey;
    const classes = classnames(prefixCls, classname);
    const { defaultSelected, treeDefaultSelected } = this.state;
    const selectDropProps = {
      fieldName,
      disabled,
      data,
      defaultSelected,
      showKey,
      uniqueKey,
      readOnly,
      tableHeader: { name: '' },
      showHeader: false,
      isMultiple: false,
      isKeyboard: true,
      dropListAutoHide: !this.isTreeShow,
      isShow: this.isTreeShow,
      // inputIconType: 'icon-arrow-sdown',
      menuListStyle: { width: dropwidth },
      autoColWidth: false,
      handleSelected: this.handleDSelected,
      handleClick: this.handleDClick,
      ref: r => (this._dRef = r && r.ref ? r.ref : r),
    };
    const treeSelectProps = {
      fieldName, // 字段名
      treeData: treeData.data, // 树形数据
      value: treeDefaultSelected, // 选中值
      disabled,
      uniqueKey: tuniqueKey,
      showKey: tshowKey,
      threeState, // 三态勾选
      multiple: true, // 多选
      treeCheckable: true, // 可选
      showArrow: true, // 下拉箭头
      allowClear: true, // 可以清除
      labelInValue: false, // label包住选中项
      defaultOpen: false, // 默认展开
      open: this.isTreeShow, // 是否展开
      treeLine: true, // 竖线
      treeDefaultExpandAll: true,
      showSearch: false,
      treeNodeFilterProp: tshowKey,
      treeNodeLabelProp: tshowKey, // 下拉选中项在输入框中lable的值对应的字段
      showCheckedStrategy: TreeSelect.SHOW_ALL,
      searchPlaceholder: '',
      placeholder: '',
      notFoundContent: '',
      onChange: this.onTChange,
      onDropdownVisibleChange: this.tDropdownVisibleChange,
    };
    const { key: treeShowKey, value: treeShowvalue } = this.props.treeShowCondition;
    return (
      <div className={classes} style={style}>
        <SelectDrop {...selectDropProps} />
        {(`${defaultSelected}` === `${treeShowvalue}` ||
          (defaultSelected[0] && `${defaultSelected[0][treeShowKey]}` === `${treeShowvalue}`)) && (
          <TreeSelect {...treeSelectProps} />
        )}
      </div>
    );
  }
}
