import React, { Component } from 'react';
import PropTypes from 'prop-types';
import reactComposition from 'react-composition';
import classnames from 'classnames';
import { validateTips } from 'constants';
import { shallowCompareIgnoreFunc, hideTip, showTip } from 'utils';
import './index.scss';

export default class PureTextarea extends Component {
  static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    required: PropTypes.bool,
    pattern: PropTypes.string,
    customValidity: PropTypes.func,
    children: PropTypes.any,
    frozen: PropTypes.bool,
    showTitleTips: PropTypes.bool,
    showCount: PropTypes.bool,
    hideResize: PropTypes.bool,
    inputWrapStyle: PropTypes.object,
  };

  static defaultProps = {
    value: '',
    hideResize: false,
  };
  isPureInput = true;

  state = {};

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pattern, value } = nextProps;
    const validityChange = this.props.required !== nextProps.required || this.props.pattern !== pattern;
    if ((this.props.value !== value || validityChange) && this.state.tip && !this.focused) {
      setTimeout(this.checkValidity, 50);
    }
  }

  shouldComponentUpdate = shallowCompareIgnoreFunc;

  handleChange = e => {
    const { target } = e;
    const { value } = target;
    const maxLength = this.props.maxLength || 200;
    if (value.length > maxLength || this.props.frozen) return e.preventDefault();
    if (e.reactComposition.composition === false) {
      this.composition = false;
      this.props.onChange(e, value);
      target.dataset.isEdited = 1;
    } else {
      this.composition = true;
    }
    this.setState({ value, tip: '' });
  };

  handleFocus = e => {
    this.focused = true;
    e.target.select();
    this.props.onFocus && this.props.onFocus(e);
    setTimeout(() => this.tipIcon && this.showTip({ target: this.tipIcon }), 100);
  };

  handleBlur = e => {
    this.focused = false;
    this.props.onBlur && this.props.onBlur(e);
    setTimeout(this.checkValidity, 30);
    hideTip();
  };

  checkValidity = async () => {
    if (!this.input) return '';
    const { validity } = this.input;
    let tip = '';
    if (this.props.required && !this.input.value) {
      tip = '必填';
    } else if (validity.patternMismatch) {
      tip = validateTips[this.props.pattern];
    } else if (this.props.customValidity) {
      tip = await this.props.customValidity(this.input.value);
      this.input.setCustomValidity(tip);
    }
    this.setState({ tip });
    return tip;
  };

  showTip = e => showTip(e.target, { content: <span>{this.state.tip}</span>, className: 'popover--error' });

  setTip = tip => this.setState({ tip }, () => this.input.setCustomValidity(tip));

  refInput = r => (this.input = r);

  refTipIcon = r => (this.tipIcon = r);

  focus = () => this.input.focus();

  blur = () => this.input.blur();

  compositionOnChange = reactComposition({ onChange: this.handleChange });

  render() {
    const {
      onChange,
      className,
      value = '',
      onFocus,
      onBlur,
      customValidity,
      children,
      frozen,
      showTitleTips,
      showCount,
      maxLength,
      hideResize,
      inputWrapStyle,
      ...rest
    } = this.props; // eslint-disable-line
    const { tip } = this.state;
    const wrapClass = classnames({ 'fn-input-pure-wrap': true, [className]: className });
    const classes = classnames({ 'fn-input-pure': true, invalid: tip, field: true, 'hide-resize': hideResize });
    const displayValue = (this.composition ? this.state.value : value) || '';
    return (
      <span className={wrapClass} style={inputWrapStyle}>
        <textarea
          ref={this.refInput}
          className={classes}
          value={displayValue}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          maxLength={maxLength}
          {...this.compositionOnChange}
          {...rest}
        />
        {children}
        {showCount && (
          <span className="fn-input-pure__count">
            {displayValue.length}
            {maxLength ? `/${maxLength}` : ''}
          </span>
        )}
        {tip && (
          <i
            className="input-tip input-tip--invalid fn-icon fn-icon-warn-o"
            ref={this.refTipIcon}
            onMouseEnter={this.showTip}
            onMouseLeave={hideTip}
          />
        )}
      </span>
    );
  }
}
