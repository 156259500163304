import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Form, Select, Radio } from '@gui/web-react';
import { Icon } from 'components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { prefixCls } from './index.scss';

const { Option } = Select;
const RadioGroup = Radio.Group;
const OrderSet = forwardRef(function OrderSet(props, ref) {
  const { leftList = [], rightList = [] } = props;
  const optionObj = _.keyBy(leftList, 'key');

  const [form] = Form.useForm();
  const [selectAbleOptions, setSelectAbleOptions] = useState(props?.data || []);
  const [commitValue, setCommitValue] = useState({
    state: {
      rightList: [],
    },
  });
  console.log('selectAbleOptions', selectAbleOptions);
  const formFieldsValue = Form.useWatch('fields', form);
  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    const formFields = form.getFields()?.fields;
    const updatedFields = [...formFields];
    const [draggedField] = updatedFields.splice(source.index, 1);
    updatedFields.splice(destination.index, 0, draggedField);
    form.setFieldsValue({
      fields: updatedFields,
    });
  };
  const getRouteCustomListStyle = isDraggingOver => {
    const num = form.getFields()?.fields?.length;
    return {
      height: isDraggingOver ? 48 * num : 'auto',
    };
  };
  const getXorData = (allOptions, chooseGroup) => {
    return _.xorBy(allOptions, chooseGroup, 'sortKey');
  };
  const getShowSelectOptions = () => {
    const formFields = form.getFields()?.fields?.filter(el => el.sortKey);
    const options = getXorData(leftList, formFields);
    setSelectAbleOptions(options);
  };
  const getChooseOptionClassName = sortKey => {
    const option = leftList.find(el => el?.sortKey === sortKey);
    if (option !== undefined) {
      return option.className;
    }
    return 'normal';
  };
  const addChooseOptions = op => {
    console.log('op', op);
    if (op?.sortKey) {
      return [optionObj[op.sortKey]];
    }
    return [];
  };
  useImperativeHandle(
    ref,
    () => {
      return commitValue;
    },
    [commitValue],
  );
  useEffect(() => {
    console.log('formChange');
    const formFields = form.getFields()?.fields;
    setCommitValue({
      state: {
        rightList: formFields,
      },
    });
    getShowSelectOptions();
  }, [formFieldsValue]);
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div className={`${prefixCls}`}>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getRouteCustomListStyle(snapshot.isDraggingOver, 3)}
            >
              <Form
                layout="horizontal"
                form={form}
                initialValues={{
                  fields: [...rightList],
                }}
              >
                <Form.List field="fields">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <div>
                          {fields.map((item, index) => {
                            return (
                              <Draggable key={`${item.field}`} draggableId={`${item.field}`} index={index}>
                                {subProvided => (
                                  <div
                                    {...subProvided.draggableProps}
                                    ref={subProvided.innerRef}
                                    className={`${prefixCls}__container`}
                                    style={{ ...subProvided.draggableProps.style, marginBottom: 16 }}
                                  >
                                    <div>
                                      <div className="node-drag" {...subProvided.dragHandleProps}>
                                        <img
                                          className="icon-drag"
                                          alt="drag"
                                          src="https://static.g7cdn.com/fe-cdn/icon-drag_16.png"
                                        />
                                      </div>
                                      <Form.Item label="" required field={`${item.field}.sortKey`}>
                                        <Select
                                          placeholder="请选择"
                                          style={{ width: 154 }}
                                          allowClear
                                          showSearch={{
                                            retainInputValue: true,
                                          }}
                                          filterOption={(inputValue, option) =>
                                            option.props.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                                            option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                          }
                                          onChange={value => {
                                            getShowSelectOptions();
                                            console.log('getShowSelectOptions', value);
                                          }}
                                          className={`${prefixCls}__${getChooseOptionClassName(
                                            form.getFields().fields?.[index]?.sortKey,
                                          )}`}
                                        >
                                          {[
                                            ...selectAbleOptions,
                                            ...addChooseOptions(form.getFields().fields?.[index]),
                                          ].map(option => (
                                            <Option
                                              key={option.sortKey}
                                              value={option.sortKey}
                                              className={`${prefixCls}__${option?.className || 'normal'}`}
                                            >
                                              {option.title}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </div>
                                    <div style={{ width: 180 }}>
                                      <Form.Item label="" required field={`${item.field}.sortType`}>
                                        <RadioGroup
                                          type="button"
                                          name="lang"
                                          style={{ marginRight: 20, marginBottom: 20 }}
                                        >
                                          <Radio value="asc">升序</Radio>
                                          <Radio value="desc">降序</Radio>
                                        </RadioGroup>
                                      </Form.Item>
                                      <div
                                        style={{ color: '#4E5969', cursor: 'pointer' }}
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      >
                                        <Icon iconType="icon-close" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                        <div
                          className="add-btn"
                          onClick={() => {
                            add({
                              sortKey: '',
                              sortType: 'asc',
                            });
                          }}
                        >
                          <Icon iconType="icon-add" />
                          添加条件
                        </div>
                      </>
                    );
                  }}
                </Form.List>
              </Form>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});
export default OrderSet;
