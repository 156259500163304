exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-input-pure{border:1px solid rgba(34,36,38,.2);font-size:12px;background:#fff;padding:3px 5px;outline:0;border-radius:2px;color:#000;cursor:text;width:100%;height:100%;resize:auto}.fn-input-pure:focus{background:#e2f7ff}.fn-input-pure:focus,.fn-input-pure:hover{border:1px solid #0097cf}.fn-input-pure.hide-resize{resize:hide}.fn-input-pure.invalid,.fn-input-pure.invalid:focus{background:#fff5f5;border:1px solid #ff4040}.fn-input-pure[disabled]{color:#333;background-color:#f2f9ff;border-color:#c1c1c1;cursor:no-drop}.fn-input-pure-wrap{width:140px;height:24px;position:relative;display:inline-block;vertical-align:middle}.fn-input-pure-wrap .input-icon{position:absolute;top:50%;margin-top:-7px;right:5px;width:auto;height:auto;color:#0097cf;font-size:14px;line-height:14px}.fn-input-pure-wrap .input-icon.fn-icon-yingyewangdian{font-size:13px}.fn-input-pure-wrap .input-icon+.input-tip{right:22px}.fn-input-pure__count{font-size:12px;color:#999;position:absolute;bottom:0;right:10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-input-pure",
	"fn-input-pure": "fn-input-pure",
	"hide-resize": "hide-resize",
	"invalid": "invalid",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"input-icon": "input-icon",
	"fn-icon-yingyewangdian": "fn-icon-yingyewangdian",
	"input-tip": "input-tip",
	"fn-input-pure__count": "fn-input-pure__count"
};