import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { CheckDrop } from 'components';
import { getCheckDropContent } from 'pages/SettingController/set/tool';
import TableCell from './TableCell';

export default class CheckDropCell extends TableCell {
  static propTypes = {
    onClick: PropTypes.func,
  };

  handleClick = data => {
    const { columnKey, rowIndex, onClick } = this.props;

    if (typeof onClick === 'function') {
      onClick(rowIndex, columnKey, data);
    }
  };

  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    const { checkDropClass = '', checkDropHeader = {}, enumData = {} } = columnProps;
    const { data, colIndex } = cellOtherProps;
    const { rowIndex, columnKey, width, height } = cellProps;
    const inputStyle = { width: width - 3, height: height - 2 };
    const dataItem = data.getShowObjectAt(rowIndex) ?? {};
    const cellValue = dataItem[columnKey] ?? {};

    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <CheckDrop
          inputStyle={inputStyle}
          isDropdown
          sectionClass={checkDropClass}
          header={checkDropHeader ?? {}}
          content={getCheckDropContent({ saveData: cellValue, enumData })}
          data-path={`${columnKey}_${rowIndex}_${colIndex}`}
          onClick={this.handleClick}
        />
      </Cell>
    );
  }
}
