exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SelectAddr{background:#fff;box-shadow:0 2px 6px 0 rgba(0,0,0,.2);border-radius:2px}.SelectAddr .ant-tabs-nav-wrap{background:#f6f7f8}.SelectAddr .ant-tabs-bar{position:relative;margin-bottom:0!important;box-shadow:0 2px 4px 0 rgba(0,0,0,.15);border-radius:2px 2px 0 0}.SelectAddr tbody tr td:nth-of-type(2){border-right:1px solid #ccc;border-left:1px solid #ccc}", ""]);

// exports
exports.locals = {
	"prefixCls": "SelectAddr",
	"SelectAddr": "SelectAddr",
	"ant-tabs-nav-wrap": "ant-tabs-nav-wrap",
	"ant-tabs-bar": "ant-tabs-bar"
};