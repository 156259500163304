import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { formatDeppProptotype } from 'utils';
import { PureInput } from 'components';
import { isInOtherPropsArray } from './../utils';
import TableCell from './TableCell';
export default class InputCell extends TableCell {
  static propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func,
    format: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };
  onKeyDown = e => {
    this.props.onKeyDown(e, this.props.rowIndex, this.props.cellProps.columnKey);
  };

  onFocus = e => {
    this.props.onFocus(e, this.props.rowIndex, this.props.cellProps.columnKey);
  };

  onBlur = e => {
    this.props.onBlur(this.props.rowIndex, this.props.cellProps.columnKey);
  };

  onChange = e => {
    this.props.onChange(this.props.rowIndex, this.props.cellProps.columnKey, e.target.value);
  };
  customValidity = val => {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    const { data } = cellOtherProps;
    const { rowIndex } = cellProps;
    const { customValidity } = columnProps;
    const dataItem = data.getObjectAt(rowIndex);
    return customValidity(val, dataItem);
  };
  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    const { data, colIndex } = cellOtherProps;
    const { customValidity } = columnProps;
    const { rowIndex, columnKey, width, height } = cellProps;
    const dataItem = data.getObjectAt(rowIndex);
    const value = formatDeppProptotype(columnKey, dataItem);
    const style = { width: width - 3, height: height - 2 };
    const rowProps = dataItem.otherProps && dataItem.otherProps[columnKey];
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <PureInput
          style={style}
          pattern={columnProps.pattern}
          required={columnProps.required}
          data-path={`${columnKey}_${rowIndex}_${colIndex}`}
          customValidity={customValidity && this.customValidity}
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          disabled={isInOtherPropsArray(dataItem, columnKey, 'disable')}
          onClick={e => e.stopPropagation()}
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          placeholder={columnProps.placeholder || ''}
          {...rowProps}
        />
      </Cell>
    );
  }
}
