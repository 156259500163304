exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ant-calendar-picker--error,.ant-calendar-picker--error:focus,pure-time--error,pure-time--error:focus{background:rgba(255,0,0,.05);border:1px solid #ff4040}.ant-calendar-picker .ant-time-picker,.ant-calendar-picker .ant-time-picker-input,pure-time .ant-time-picker,pure-time .ant-time-picker-input{width:140px}", ""]);

// exports
exports.locals = {
	"prefixCls": "pure-time",
	"ant-calendar-picker--error": "ant-calendar-picker--error",
	"ant-time-picker-input": "ant-time-picker-input",
	"ant-calendar-picker": "ant-calendar-picker",
	"ant-time-picker": "ant-time-picker"
};