/**
 * Created by Aceh on 2017/01/01.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { fadeIn, hide } from 'constants/animation';
import { animateClass } from 'utils';
import { Icon } from 'components';
import { prefixCls } from './index.scss';
// function hasClass(elem, cls = '') {
//   if (cls.replace(/\s/g, '').length === 0) return false // 当cls没有参数时，返回false
//   return new RegExp(` ${cls} `).test(` ${elem.className} `)
// }
export default class ModalDialog extends PureComponent {
  static defaultProps = {
    maskClosable: false,
    closable: true,
    autoDestroy: true,
    autoCloseTime: 0,
    isDrag: true, // 是否可拖拽 默认可以
    autoFocus: true,
  };
  static displayName = 'ModalDialog';

  constructor(prop) {
    super(prop);
    this.state = {
      isShow: prop.isShow,
      max: prop.max,
    };
  }

  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
    classname: PropTypes.string,
    style: PropTypes.object,
    isShow: PropTypes.bool,
    closable: PropTypes.bool,
    showMax: PropTypes.bool,
    maskClosable: PropTypes.bool,
    titleStyle: PropTypes.object,
    contentStyle: PropTypes.object,
    bottomStyle: PropTypes.object,
    handleHideCallback: PropTypes.func,
    handleShowCallback: PropTypes.func,
    autoDestroy: PropTypes.bool,
    autoCloseTime: PropTypes.number, // 弹框自动关闭的时间(单位：ms)
    close: PropTypes.func,
    dragerContainer: PropTypes.any,
    isDrag: PropTypes.bool,
    closableCallback: PropTypes.func,
    autoFocus: PropTypes.bool, // 是否自动获取焦点
    max: PropTypes.bool, // 是否全屏
    wrapClassName: PropTypes.string,
    wrapStyle: PropTypes.object,
  };

  componentDidMount() {
    const { autoCloseTime } = this.props;
    this.setFocusOnce = !this.props.autoFocus; // 焦点只获取一次
    if (this.state.isShow) {
      this.handleShow();
      if (this.props.autoFocus) {
        setTimeout(() => this.setFocus(), 0);
      }
    }
    if (!this.props.isDrag) {
      this.title.style.cursor = 'auto';
    }
    // 弹框自动关闭
    if (autoCloseTime && +autoCloseTime > 0) {
      setTimeout(this.handleHide, autoCloseTime);
    }
  }

  componentDidUpdate() {
    // 先遍历input，如果没有合适的获取焦点的元素再遍历textarea，如果还没有焦点给到button元素
    if (!this.setFocusOnce && this.state.isShow) {
      this.setFocus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isShow !== this.props.isShow) {
      nextProps.isShow ? this.handleShow() : this.handleHide();
    }
  }

  toggleMax = () => {
    this.setState({ max: !this.state.max });
  };

  setFocus = () => {
    const inputFocus = this.setInputFocus(); // 是否有input获取了焦点
    let textareaFocus = false; // 是否有textarea获取了焦点
    let buttonFocus = false; // 是否让btn获取焦点
    if (!inputFocus) {
      textareaFocus = this.setTextareaFocus();
    }
    if (!inputFocus && !textareaFocus) {
      buttonFocus = true;
    }
    this.handleButtonFocus(buttonFocus);
  };
  setInputFocus = () => {
    if (!this.content) {
      return false;
    }
    let inputs = this.content.querySelectorAll('input');
    inputs = Array.from(inputs);
    let inputFocus = false;
    inputs.forEach(item => {
      if (
        !inputFocus &&
        !item.hasAttribute('disabled') &&
        // && !(item.hasAttribute('readonly'))  注释掉原因： 开单代收发放弹框，会员号
        !(item.getAttribute('type') === 'checkbox') &&
        !(item.getAttribute('type') === 'radio') &&
        !(item.getAttribute('value') !== '') &&
        !(item.getAttribute('type') === 'file') &&
        !(item.getAttribute('type') === 'hidden') &&
        !(item.dataset.fieldType === 'Select') &&
        !(item.dataset.fieldType === 'DataList') &&
        !(item.dataset.fieldType === 'TreeSelect')
      ) {
        item.focus();
        inputFocus = true;
        this.setFocusOnce = true;
      }
    });
    return inputFocus;
  };
  setTextareaFocus = () => {
    if (!this.content) {
      return false;
    }
    let textareas = this.content.querySelectorAll('textarea');
    textareas = Array.from(textareas);
    let textareaFocus = false;
    textareas.forEach(item => {
      if (!textareaFocus && !item.hasAttribute('disabled')) {
        item.focus();
        textareaFocus = true;
        this.setFocusOnce = true;
      }
    });
    return textareaFocus;
  };
  handleButtonFocus = hasFocus => {
    if (!hasFocus || !this.dialog) {
      return;
    }
    let buttons = this.dialog.querySelectorAll('button');
    buttons = Array.from(buttons);
    if (hasFocus && buttons.length) {
      // 如果input和textarea都没有获取焦点 btn获取焦点
      buttons[0].focus();
      this.setFocusOnce = true;
    }
    const len = buttons.length;
    if (len === 1) return;
    buttons.forEach((item, index, arr) => {
      const each = item;
      each.onkeydown = e => {
        const ev = e || window.event;
        if (ev.keyCode === 39) {
          if (index < len - 1) {
            // 右键时的边界值
            each.blur();
            arr[index + 1].focus();
          }
        }
        if (ev.keyCode === 37) {
          if (index > 0) {
            // 左键时的边界值
            each.blur();
            arr[index - 1].focus();
          }
        }
      };
    });
  };
  handleShow = () => {
    !this.state.isShow &&
      this.setState({ isShow: true }, () => {
        animateClass(this.dialog, fadeIn);
        this.props.handleShowCallback && this.props.handleShowCallback();
      });
  };
  handleIconClose = () => {
    // 点叉叉号有校验的不能直接关闭
    if (this.props.closableCallback) {
      this.props.closableCallback(this.handleHide);
    } else {
      this.handleHide();
    }
  };
  handleHide = (forceCallClose = true) => {
    // this.state.isShow && animateClass(this.dialog, fadeOut, () => {
    //   this.setState({ isShow: false })
    //   this.props.handleHideCallback && this.props.handleHideCallback()
    //   if (this.props.autoDestroy && typeof this.props.close === 'function') {
    //     this.props.close()
    //   }
    // })
    setTimeout(() => {
      if (this.state.isShow) {
        // 关闭弹框的动画放在popup类里面统一做，为了解决编辑类侧拉关闭时需要提示弹框的需求
        this.setState({ isShow: false });
        this.props.handleHideCallback && this.props.handleHideCallback();
        if (forceCallClose && this.props.autoDestroy && typeof this.props.close === 'function') {
          this.props.close();
        }
      }
    }, 0);
  };
  handleClick = ev => {
    const e = ev || window.event;
    if (e.target === this.dialog) {
      this.props.maskClosable && this.handleHide();
    }
  };
  handleMouseDown = eve => {
    if (!this.props.isDrag || this.state.max) {
      return;
    }
    const e = eve || window.event;
    document.documentElement.style.userSelect = 'none';
    const moveBox = this.dialogContent;
    const initLeft = parseInt(Number(moveBox.style.left.replace('px', '')), 10);
    const initTop = parseInt(Number(moveBox.style.top.replace('px', '')), 10);
    const left = e.clientX - initLeft;
    const top = e.clientY - initTop;
    const maskWidth = this.dialog.offsetWidth;
    const maskHeight = this.dialog.offsetHeight;
    const dialogLeft = this.dialogContent.offsetLeft;
    const dialogTop = this.dialogContent.offsetTop;
    const dialogWidth = this.dialogContent.offsetWidth;
    const dialogHeight = this.dialogContent.offsetHeight;
    let moveX;
    let moveY;
    document.onmousemove = even => {
      const ev = even || window.evnet;
      moveX = ev.clientX - left;
      moveY = ev.clientY - top;
      moveBox.style.left = `${moveX}px`;
      moveBox.style.top = `${moveY}px`;
      if (moveBox.offsetLeft < 0) {
        // 左边界
        moveBox.style.left = `${-(dialogLeft - initLeft)}px`;
      }
      if (moveBox.offsetLeft > maskWidth - dialogWidth) {
        // 右边界
        moveBox.style.left = `${maskWidth - dialogWidth - (dialogLeft - initLeft)}px`;
      }
      if (moveBox.offsetTop < 0) {
        // 上边界
        moveBox.style.top = `${-(dialogTop - initTop)}px`;
      }
      if (moveBox.offsetTop > maskHeight - dialogHeight) {
        // 下边界
        moveBox.style.top = `${maskHeight - dialogHeight - (dialogTop - initTop)}px`;
      }
    };
    document.onmouseup = () => {
      document.documentElement.style.userSelect = 'text';
      document.onmousemove = null;
      document.onmouseup = null;
    };
  };

  renderIcon() {
    if (!this.props.closable) {
      return null;
    }
    const iclass = classnames(`${prefixCls}__close`);
    return <Icon iconType="icon-close" classname={iclass} onClick={this.handleIconClose} />;
    // return ( // 换成icon组件
    //   <i
    //     className={classnames('fn-icon', 'fn-icon-close', `${prefixCls}__close`)}
    //     onClick={this.handleHide}
    //   />
    // )
  }

  render() {
    const {
      style,
      title,
      bottom,
      content,
      classname,
      titleStyle,
      contentStyle,
      showMax,
      bottomStyle,
      wrapClassName,
      wrapStyle,
    } = this.props;
    const classes = classnames(`${prefixCls}__content`, classname, { [`${prefixCls}__content--max`]: this.state.max });
    const dialogClasses = classnames(
      {
        [prefixCls]: true,
        [hide]: !this.state.isShow,
      },
      wrapClassName,
    );
    return (
      <div onClick={this.handleClick} className={dialogClasses} ref={r => (this.dialog = r)} style={wrapStyle}>
        <div className={classes} style={style} ref={r => (this.dialogContent = r)}>
          {this.renderIcon()}
          {showMax && (
            <Icon
              classname={`${prefixCls}__max`}
              iconType={`fn-icon fn-icon-zoom-${this.state.max ? 'in' : 'out'}`}
              onClick={this.toggleMax}
            />
          )}
          {title ? (
            <p
              className={`${prefixCls}__title`}
              style={titleStyle}
              ref={r => (this.title = r)}
              onMouseDown={this.handleMouseDown}
            >
              {title}
            </p>
          ) : null}
          <div className={`${prefixCls}__center`} style={contentStyle} ref={r => (this.content = r)}>
            {content}
          </div>
          {!bottom ? null : (
            <div className={`${prefixCls}__bottom`} style={bottomStyle}>
              {bottom}
            </div>
          )}
        </div>
        <div className={`${prefixCls}__linehr`} />
      </div>
    );
  }
}
