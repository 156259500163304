import React from 'react';
import PropTypes from 'prop-types';
import { prefixCls } from './index.scss';
import { Link as GuiLink } from '@gui/web-react';

const Link = ({ children, className, prefix, suffix, ...otherProps }) => (
  <span className={`${prefixCls} ${className}`}>
    {prefix}
    <GuiLink {...otherProps}>{children}</GuiLink>
    {suffix}
  </span>
);
Link.propTypes = {
  count: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
};
export default Link;
