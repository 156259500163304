import * as React from 'react';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import { getPrefixCls, omit } from '../DatePicker/utils';

const PresetColorTypes = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime',
];
const PresetColorRegex = new RegExp(`^(${PresetColorTypes.join('|')})(-inverse)?$`);

class Tag extends React.Component {
  static defaultProps = {
    closable: false,
  };
  constructor(props) {
    super(props);
    this.state = this.updateState(props) || {};
  }
  updateState(nextProps) {
    let state;
    if ('visible' in nextProps) {
      state.visible = nextProps.visible;
    }
    return state;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const state = this.updateState(nextProps);
    state && this.setState(state);
  }

  setVisible(visible, e) {
    const { onClose, afterClose } = this.props;
    if (onClose) {
      onClose(e);
    }
    if (afterClose && !onClose) {
      // next version remove.
      afterClose();
    }
    if (e.defaultPrevented) {
      return;
    }
    if (!('visible' in this.props)) {
      this.setState({ visible });
    }
  }

  handleIconClick = e => {
    this.setVisible(false, e);
  };

  isPresetColor(color) {
    if (!color) {
      return false;
    }
    return PresetColorRegex.test(color);
  }

  getTagStyle() {
    const { color, style } = this.props;
    const isPresetColor = this.isPresetColor(color);
    return {
      backgroundColor: color && !isPresetColor ? color : undefined,
      ...style,
    };
  }

  getTagClassName() {
    const { prefixCls: customizePrefixCls = 'ant-tag', className, color } = this.props;
    const { visible } = this.state;
    const isPresetColor = this.isPresetColor(color);
    const prefixCls = getPrefixCls('tag', customizePrefixCls);
    return classNames(
      prefixCls,
      {
        [`${prefixCls}-${color}`]: isPresetColor,
        [`${prefixCls}-has-color`]: color && !isPresetColor,
        [`${prefixCls}-hidden`]: !visible,
      },
      className,
    );
  }

  renderCloseIcon() {
    const { closable } = this.props;
    return closable ? <Icon type="close" onClick={this.handleIconClick} /> : null;
  }

  render() {
    const { prefixCls: customizePrefixCls, children, ...otherProps } = this.props;
    const divProps = omit(otherProps, ['onClose', 'afterClose', 'color', 'visible', 'closable']);
    return (
      <div {...divProps} className={this.getTagClassName()} style={this.getTagStyle()}>
        {children}
        {this.renderCloseIcon()}
      </div>
    );
  }
}

export default Tag;
