import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isEqual, isString } from 'lodash';
import { FormulaPicker as GuiFormulaPicker, Button, Typography } from '@gui/web-react';
import { IconEdit } from '@gui/web-react/icon';
import classnames from 'classnames';
import { getDisplayFormula } from './util';

export default class FormulaPicker extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  constructor(props) {
    super(props);
    const { value, fixed, trans } = props;
    const fVal = isString(value) ? { formula: value, trans, fixed } : value ?? { formula: '' };
    this.state = { value: fVal, _value: value, _fixed: fixed, _trans: trans }; // 在本地缓存一份 state，统一成 { formula, trans, fixed }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, fixed, trans } = nextProps;

    const fVal = isString(value) ? { formula: value, trans, fixed } : value ?? { formula: '' };

    if (
      !isEqual(value, this.state._value) ||
      !isEqual(trans, this.state._trans) ||
      !isEqual(fixed, this.state._fixed)
    ) {
      this.setState({ value: fVal, _value: value, _fixed: fixed, _trans: trans });
    }
  }

  handleConfirm = (val, obj) => {
    this.setState(
      old => ({ ...old, value: { ...old.value, ...obj } }),
      () => {
        this.props.onChange?.(this.state.value, { oriParams: [val, obj] });
        this.props.onConfirm?.(val, obj);
      },
    );
  };

  handleOutcomeChange = (item, type) => {
    this.setState(
      old => ({ ...old, value: { ...old.value, [type]: item?.value } }),
      () => {
        this.props.onChange?.(this.state.value, { oriParams: [item, type] });
        this.props.onOutcomeChange?.(item, type);
      },
    );
  };

  _getDisplayValue = () => {
    const { variables = [], functions = [], markFunction } = this.props;
    const content = this.state.value?.formula ?? '';
    return getDisplayFormula(content, { variables, functions, markFunction });
  };

  render() {
    const props = this.props;
    const { trigger, disabled, typoStyle, btnStyle, ...otherProps } = props;
    const _btnStyle = btnStyle ?? { fontSize: 12, maxWidth: '100%' };
    const _typoStyle = typoStyle ?? {
      display: 'inline-block',
      maxWidth: disabled ? '100%' : 'calc(100% - 20px)',
      marginBottom: 0,
    };
    const { value } = this.state;

    return props.disabled ? (
      <Typography.Text ellipsis={{ cssEllipsis: true, showTooltip: true }} style={_typoStyle} type="primary">
        {this._getDisplayValue()}
      </Typography.Text>
    ) : (
      <GuiFormulaPicker
        {...otherProps}
        className={classnames('formula-picker__field formula-picker__override', props?.className)}
        value={value.formula}
        onConfirm={(val, obj) => this.handleConfirm(val, obj)}
        onOutcomeChange={(item, type) => this.handleOutcomeChange(item, type)}
      >
        {({ onClick, ...other }) =>
          trigger ? (
            trigger?.({ onClick, ...other, display: this._getDisplayValue() })
          ) : (
            <Button type="text" onClick={onClick} style={_btnStyle}>
              <Typography.Text ellipsis={{ cssEllipsis: true, showTooltip: true }} style={_typoStyle} type="primary">
                {this._getDisplayValue()}
              </Typography.Text>

              <IconEdit style={{ marginLeft: 8 }} />
            </Button>
          )
        }
      </GuiFormulaPicker>
    );
  }
}
