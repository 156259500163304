/**
 * Created by jany on 2017/09/03.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { CheckBoxGroup } from 'components';
import TableCell from './TableCell';

export default class CheckBoxGroupCell extends TableCell {
  constructor(prop) {
    super(prop);
    this.isDisabled = false;
    this.defaultValue = {};
  }

  static propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
  };
  handleChange = (e = []) => {
    const checked = e;
    this.setState({ checked });
    const { rowIndex, columnKey } = this.props;
    this.props.onChange && this.props.onChange(rowIndex, columnKey, checked);
  };

  // 勾选列不触发 checkbox事件 统一到行点击事件
  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    const { data } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const { enumData } = columnProps;
    const dataItem = data.getOriObjectAt(rowIndex);
    const defaultValue = dataItem[columnKey] || {};
    const checked = defaultValue || [];
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        {/* checked={checked} */}
        <CheckBoxGroup data={enumData} checked={checked} onChange={this.handleChange} />
      </Cell>
    );
  }
}
