/**
 * Created by JANY on 2017/2/27.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getPageQueryKey } from 'utils';

export const shouldCompUpdate = pageKey => ComponsedComponent => {
  pageKey = pageKey || getPageQueryKey(); // eslint-disable-line
  return class extends Component {
    static propTypes = {
      shouldTabUpdate: PropTypes.bool,
      // pageShowCount: PropTypes.number,
    };

    shouldComponentUpdate(nextProps) {
      const { shouldTabUpdate = true /* , pageShowCount = 1 */ } = nextProps;
      // console.log('shouldTabUpdate', pageKey === getPageQueryKey() && (shouldTabUpdate || pageShowCount === 1), pageKey, pageShowCount, shouldTabUpdate)
      return pageKey === getPageQueryKey() && shouldTabUpdate /* || pageShowCount === 1 */;
    }

    render() {
      return <ComponsedComponent {...this.props} />;
    }
  };
};
