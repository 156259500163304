exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cmm-autoSize{width:100%;height:100%;overflow:hidden;position:relative;float:left}.cmm-autoSize__watch{width:100%;height:100%;position:absolute;left:0;top:0;visibility:hidden;margin:0;padding:0;border:0}", ""]);

// exports
exports.locals = {
	"prefixCls": "cmm-autoSize",
	"cmm-autoSize": "cmm-autoSize",
	"cmm-autoSize__watch": "cmm-autoSize__watch"
};