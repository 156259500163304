const CDN_PATH = 'https://static.g7cdn.com/fe-cdn/cyt/map';

export const noop = () => {};

// todo，地址如何格式化
export const formatAddr = ({ name }) => {
  return name;
};

// todo, 显示地址规则
export const showAddr = data => {
  return data.aliasName || data.name;
};

// poi数据的location字段格式化为经纬度数组
export const getLocFromStr = str => {
  return str?.split(',') || [0, 0];
};

// 不同来源的图标
const iconSource = {
  0: `${CDN_PATH}/marker_store.png`, // 租户私有地址库
  1: `${CDN_PATH}/marker_ai.png`, // 平台挖掘、推荐地址
  2: `${CDN_PATH}/marker_gaode.png`, // 高德 poi
};
export const getIconBySource = source => iconSource[source] || '';

// 转换地址库数据结构为财运通 poi 格式
export const poi2cytStyle = data => {
  const {
    province = '',
    city = '',
    district = '',
    adcode = '',
    location = '',
    aliasName = '',
    name = '',
    street,
  } = data;
  return {
    aliasName,
    name,
    province,
    city,
    district,
    street,
    adcode,
    poi: location,
  };
};

// 转换财运通地址数据格式为地址库格式
export const cyt2poiStyle = data => {
  const { show_val = '', province = '', city = '', district = '', adcode = '', poi = '' } = data;
  return {
    name: show_val,
    province,
    city,
    district,
    adcode,
    location: poi,
  };
};

// 多边形属性配置
export const PolygonStyle = {
  bubble: true,
  fillColor: '#CE0000',
  fillOpacity: 0.3,
  strokeWeight: 2,
  strokeColor: '#A60000',
  strokeOpacity: 0.7,
  isPolyEditor: true,
  zIndex: 30,
};

// 圆形属性配置
export const CircleStyle = {
  bubble: true,
  strokeOpacity: 0.7,
  strokeColor: '#A60000',
  strokeWeight: 2,
  strokeStyle: 'solid',
  fillColor: '#CE0000',
  fillOpacity: 0.3,
  zIndex: 30,
};

export const MARKER_TYPE = 0;
export const CIRCLE_TYPE = 1;
export const POLYGON_TYPE = 2;

// 多边形拖拽后偏移计算
export function getOffsetPaths(polygon, new_center) {
  const polygon_center = polygon.getBounds().getCenter();
  // 计算被拖拽的距离
  const old_lng = polygon_center.getLng();
  const old_lat = polygon_center.getLat();

  const new_lng = new_center.getLng();
  const new_lat = new_center.getLat();

  const offset_lng = new_lng - old_lng;
  const offset_lat = new_lat - old_lat;

  return polygon.getPath().map(lnglat => [lnglat.getLng() + offset_lng, lnglat.getLat() + offset_lat]);
}
