exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-sortSetPopup{border:1px solid #e5e6eb;border-radius:4px}.fn-sortSetPopup .fn-dialog__title{background:#f7f8fa;box-shadow:none;height:46px;line-height:46px}.fn-sortSetPopup .fn-dialog__center{padding:10px 20px 30px;max-height:400px;overflow:auto}.fn-sortSetPopup .fn-dialog__bottom{background-color:transparent;box-shadow:none}.fn-sortSetPopup .fn-btn--primary{background-color:#532fe6;background-image:none}.fn-sortSetPopup .fn-btn--default{color:#4e5969!important}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-sortSetPopup",
	"fn-sortSetPopup": "fn-sortSetPopup",
	"fn-dialog__title": "fn-dialog__title",
	"fn-dialog__center": "fn-dialog__center",
	"fn-dialog__bottom": "fn-dialog__bottom",
	"fn-btn--primary": "fn-btn--primary",
	"fn-btn--default": "fn-btn--default"
};