/**
 * Created by JANY on 2017/10/10.
 */
import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { Cascader } from 'components';
import TableCell from './TableCell';

export default class CascaderCell extends TableCell {
  static propTypes = {
    onChange: PropTypes.func,
    handleSelected: PropTypes.func,
  };
  onChange = (...rest) => {
    this.props.onChange?.(this.props.rowIndex, this.props.columnKey, ...rest);
    this.props.handleSelected?.(this.props.rowIndex, this.props.columnKey, ...rest);
  };

  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, tableKey, colIndex } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const { showKey, uniqueKey, options = [], fetchApi, defaultValue } = columnProps;
    const showValue = data.getShowObjectAt(rowIndex)[columnKey];
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <Cascader
          options={options}
          defaultValue={defaultValue}
          fetchApi={fetchApi}
          onChange={this.onChange}
          size="mini"
        >
          <span>{showValue}</span>
        </Cascader>
      </Cell>
    );
  }
}
