/**
 * Created by JANY on 2016/12/2.
 */
import PropTypes from 'prop-types';

import React, { Component } from 'react';

const Service = services => ComponsedComponent =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ist: {},
      };
    }

    static childContextTypes = {
      serviceCb: PropTypes.func,
    };
    getChildContext = () => ({ serviceCb: this._getService });
    getService = () => {
      if (this.state.ist) {
        return this.getService();
      }
      return this.state.ist;
    };
    render() {
      return <ComponsedComponent {...this.props} service={this.state.ist} />;
    }

    // 获取已注册服务的实例
    _getService() {
      const istObj = {};
      services.map(service => {
        window.AMap.service &&
          window.AMap.service([`AMap.${service.name}`], () => {
            istObj[service.name] = new window.AMap[service.name](service.cfg);
          });
        return true;
      });
      this.setState({
        ist: istObj,
      });
      return this.state.ist;
    }
  };
Service.contextTypes = {
  serviceCb: PropTypes.func,
};
Service.childContextTypes = {
  serviceCb: PropTypes.func,
};
Service.defaultProps = {};
export default Service;
