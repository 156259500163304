exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".auto-tooltip__content--show-tooltip,.auto-tooltip__wrapper{height:100%;width:100%}.auto-tooltip__content--show-tooltip,.auto-tooltip__wrapper--show-tooltip{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.auto-tooltip__content,.auto-tooltip__content--show-tooltip{display:inline-block;height:100%;white-space:nowrap}", ""]);

// exports
exports.locals = {
	"tooltipPrefixCls": "auto-tooltip",
	"auto-tooltip__wrapper": "auto-tooltip__wrapper",
	"auto-tooltip__content--show-tooltip": "auto-tooltip__content--show-tooltip",
	"auto-tooltip__wrapper--show-tooltip": "auto-tooltip__wrapper--show-tooltip",
	"auto-tooltip__content": "auto-tooltip__content"
};