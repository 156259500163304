/*
  给组件添加属性
*/
import React, { Component } from 'react';
import Tips from '../../utilcomponents/tips/Tooltip';

export const renderCompChild = ComponsedComponent => {
  ComponsedComponent.prototype.renderCompChild = (prop, child) => {
    // eslint-disable-line
    const addTips = newChild => {
      const { tips } = prop;
      if (tips !== undefined && tips !== '') {
        return <Tips title={tips}>{newChild}</Tips>;
      }
      return newChild;
    };
    const addFocusStatus = newChild => {
      return newChild;
    };

    let newChild = child;
    // 添加提示
    newChild = addTips(newChild);
    // 测试
    newChild = addFocusStatus(newChild);
    return newChild;
  };
  return class extends Component {
    // 添加tips
    render() {
      return <ComponsedComponent {...this.props} />;
    }
  };
};
