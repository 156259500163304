/**
 * Created by JANY on 2017/10/10.
 */
import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import TableCell from './TableCell';
import { TableContext } from '../utils';

export default class CustomizeCell extends TableCell {
  static propTypes = {
    cellContentGetter: PropTypes.func,
    handleEvents: PropTypes.func,
  };
  static contextType = TableContext;

  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { cellContentGetter } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const { stopClickPropagation = true } = columnProps;
    return (
      <Cell
        {...this.props.cellProps}
        onClick={stopClickPropagation ? e => e.stopPropagation() : undefined}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        {cellContentGetter &&
          cellContentGetter(this.props, cellProps, cellOtherProps, columnProps, { cellEvents: this.context })}
      </Cell>
    );
  }
}
