exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-image-viewer .fn-dialog__center{min-width:470px;display:flex;align-items:center;justify-content:center;overflow:auto;position:relative}.fn-image-viewer .fn-dialog__center>img{visibility:hidden}.fn-image-viewer__wrap{position:absolute;width:100%;height:100%;left:0;top:0;white-space:nowrap;text-align:center}.fn-image-viewer__wrap:before{content:\"\";display:inline-block;height:100%;width:0;vertical-align:middle}.fn-image-viewer .gray{color:gray;border:none!important;background:none!important}.fn-image-viewer .fn-icon-arrow-l{margin-right:20px}.fn-image-viewer .fn-icon-arrow-r{margin-left:20px}.fn-image-viewer img{max-height:calc(100vh - 225px);max-width:calc(100vw - 220px)}.fn-image-viewer .fn-dialog__title{margin-bottom:1px}.fn-image-viewer .fn-dialog__bottom{position:relative;margin-top:1px}.fn-image-viewer .fn-dialog__bottom button a,.fn-image-viewer .fn-dialog__bottom button a:hover{color:#fff}.fn-image-viewer .fn-dialog__bottom button a:visited{text-decoration:none;color:#fff}.fn-image-viewer__toolbar{position:absolute;left:50%;margin-left:-54px;margin-top:5px}.fn-image-viewer__toolbar .fn-icon-il{padding:0 11px;font-size:14px;color:#0097cf}.fn-image-viewer__toolbar .fn-icon-il:hover{cursor:pointer}.fn-image-viewer .fn-dialog__center .fn-icon-arrow-l,.fn-image-viewer .fn-dialog__center .fn-icon-arrow-r{z-index:99;font-size:22px}.fn-image-viewer .videoView{height:500px}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-image-viewer",
	"fn-image-viewer": "fn-image-viewer",
	"fn-dialog__center": "fn-dialog__center",
	"fn-image-viewer__wrap": "fn-image-viewer__wrap",
	"gray": "gray",
	"fn-icon-arrow-l": "fn-icon-arrow-l",
	"fn-icon-arrow-r": "fn-icon-arrow-r",
	"fn-dialog__title": "fn-dialog__title",
	"fn-dialog__bottom": "fn-dialog__bottom",
	"fn-image-viewer__toolbar": "fn-image-viewer__toolbar",
	"fn-icon-il": "fn-icon-il",
	"videoView": "videoView"
};