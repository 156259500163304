exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-btn-icon{display:inline-block}.fn-btn-icon--loading button{min-width:75px}.fn-btn-icon__center{text-align:center}.fn-btn-icon__left .fn-icon{margin-right:6px}.fn-btn-icon__left .fn-icon-setting{top:0}.fn-btn-icon__right .fn-icon{margin-left:6px}.fn-btn-icon__both .fn-icon:first-of-type{padding-right:6px}.fn-btn-icon__both .fn-icon:nth-of-type(2n){padding-left:6px}.fn-btn-icon__hide{opacity:0;display:none}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-btn-icon",
	"fn-btn-icon": "fn-btn-icon",
	"fn-btn-icon--loading": "fn-btn-icon--loading",
	"fn-btn-icon__center": "fn-btn-icon__center",
	"fn-btn-icon__left": "fn-btn-icon__left",
	"fn-icon": "fn-icon",
	"fn-icon-setting": "fn-icon-setting",
	"fn-btn-icon__right": "fn-btn-icon__right",
	"fn-btn-icon__both": "fn-btn-icon__both",
	"fn-btn-icon__hide": "fn-btn-icon__hide"
};