exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-FilterMorePopup{--primary-6:83 47 230;--primary-5:181 154 245;--color-primary-light-3:#b59af5;background-color:#fff;width:242px;height:372px;border-radius:4px;padding:8px 12px;box-shadow:0 1px 7px 0 rgba(0,0,0,.2)}.fn-FilterMorePopup .gui-checkbox-group{width:100%}.fn-FilterMorePopup__container{display:flex;align-items:center;justify-content:space-between;margin-bottom:0;--color-fill-2:242 243 245}.fn-FilterMorePopup__container>div{display:flex;flex-wrap:nowrap;align-items:center}.fn-FilterMorePopup__container .gui-form-item{margin-bottom:0}.fn-FilterMorePopup__container .row{width:100%;display:flex;justify-content:space-between}.fn-FilterMorePopup__container .tips{width:100%}.fn-FilterMorePopup__container .node-drag{width:9px;text-align:center;margin-right:21px}.fn-FilterMorePopup__container .node-drag .icon-drag{width:9px}.fn-FilterMorePopup__container .node-drag .icon-drag:hover{width:10px}.fn-FilterMorePopup .footer-container{display:flex;margin-top:10px;justify-content:space-between;align-items:center}.fn-FilterMorePopup .gui-btn-size-default{height:auto}.fn-FilterMorePopup .add-btn{color:#532fe6;font-size:12px;padding-left:30px;display:inline-block;width:120px;cursor:pointer}.fn-FilterMorePopup .add-btn i{font-weight:700;margin-right:3px}.fn-FilterMorePopup__hide_key{background-color:#f2f3f5!important}.fn-FilterMorePopup .gui-checkbox-disabled .gui-checkbox-mask-icon{color:#fff}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-FilterMorePopup",
	"fn-FilterMorePopup": "fn-FilterMorePopup",
	"gui-checkbox-group": "gui-checkbox-group",
	"fn-FilterMorePopup__container": "fn-FilterMorePopup__container",
	"gui-form-item": "gui-form-item",
	"row": "row",
	"tips": "tips",
	"node-drag": "node-drag",
	"icon-drag": "icon-drag",
	"footer-container": "footer-container",
	"gui-btn-size-default": "gui-btn-size-default",
	"add-btn": "add-btn",
	"fn-FilterMorePopup__hide_key": "fn-FilterMorePopup__hide_key",
	"gui-checkbox-disabled": "gui-checkbox-disabled",
	"gui-checkbox-mask-icon": "gui-checkbox-mask-icon"
};