import * as React from 'react';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import classNames from 'classnames';
import * as moment from 'moment';
import zhCN from './zh-CN';
import { getPrefixCls, generateShowHourMinuteSecond, interopDefault } from './utils';

const DEFAULT_FORMAT = {
  date: 'YYYY-MM-DD',
  dateTime: 'YYYY-MM-DD HH:mm:ss',
  week: 'gggg-wo',
  month: 'YYYY-MM',
};

const LOCALE_FORMAT_MAPPING = {
  date: 'dateFormat',
  dateTime: 'dateTimeFormat',
  week: 'weekFormat',
  month: 'monthFormat',
};

function getColumns({ showHour, showMinute, showSecond, use12Hours }) {
  let column = 0;
  if (showHour) {
    column += 1;
  }
  if (showMinute) {
    column += 1;
  }
  if (showSecond) {
    column += 1;
  }
  if (use12Hours) {
    column += 1;
  }
  return column;
}

function checkValidate(value, propName) {
  const values = Array.isArray(value) ? value : [value];
  values.forEach(val => {
    if (!val) return undefined;
  });
}

export default function wrapPicker(Picker, pickerType) {
  class PickerWrapper extends React.Component {
    static defaultProps = {
      transitionName: 'slide-up',
      popupStyle: {},
      onChange() {},
      onOk() {},
      onOpenChange() {},
      locale: {},
    };

    updateState({ value, defaultValue }) {
      checkValidate(defaultValue, 'defaultValue');
      checkValidate(value, 'value');
      return {};
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState(this.updateState(nextProps));
    }
    // Since we need call `getDerivedStateFromProps` for check. Need leave an empty `state` here.
    state = {
      defaultOpenValue: interopDefault(moment)(),
    };

    picker;

    componentDidMount() {
      const { autoFocus, disabled } = this.props;
      if (autoFocus && !disabled) {
        this.focus();
      }
    }

    handleOpenChange = open => {
      const { onOpenChange, showTime } = this.props;
      onOpenChange(open);
      if (open && showTime) {
        this.setState({ defaultOpenValue: interopDefault(moment)() });
      }
    };

    handleFocus = e => {
      const { onFocus } = this.props;
      if (onFocus) {
        onFocus(e);
      }
    };

    handleBlur = e => {
      const { onBlur } = this.props;
      if (onBlur) {
        onBlur(e);
      }
    };

    handleMouseEnter = e => {
      const { onMouseEnter } = this.props;
      if (onMouseEnter) {
        onMouseEnter(e);
      }
    };

    handleMouseLeave = e => {
      const { onMouseLeave } = this.props;
      if (onMouseLeave) {
        onMouseLeave(e);
      }
    };

    focus() {
      this.picker.focus();
    }

    blur() {
      this.picker.blur();
    }

    savePicker = node => {
      this.picker = node;
    };

    getDefaultLocale = () => {
      const result = {
        ...zhCN,
        ...this.props.locale,
      };
      result.lang = {
        ...result.lang,
        ...(this.props.locale || {}).lang,
      };
      return result;
    };

    render() {
      const { format, showTime } = this.props;
      const { defaultOpenValue } = this.state;
      const mergedPickerType = showTime ? `${pickerType}Time` : pickerType;
      const locale = this.getDefaultLocale();
      const localeCode = 'zh-cn';
      const mergedFormat =
        format || locale[LOCALE_FORMAT_MAPPING[mergedPickerType]] || DEFAULT_FORMAT[mergedPickerType];

      const {
        prefixCls: customizePrefixCls,
        inputPrefixCls: customizeInputPrefixCls,
        getCalendarContainer,
        size,
        disabled,
      } = this.props;
      const getPopupContainer = getCalendarContainer;
      const prefixCls = getPrefixCls('calendar', customizePrefixCls);
      const inputPrefixCls = getPrefixCls('input', customizeInputPrefixCls);
      const pickerClass = classNames(`${prefixCls}-picker`, {
        [`${prefixCls}-picker-${size}`]: !!size,
      });
      const pickerInputClass = classNames(`${prefixCls}-picker-input`, inputPrefixCls, {
        [`${inputPrefixCls}-lg`]: size === 'large',
        [`${inputPrefixCls}-sm`]: size === 'small',
        [`${inputPrefixCls}-disabled`]: disabled,
      });

      const timeFormat = (showTime && showTime.format) || 'HH:mm:ss';
      const rcTimePickerProps = {
        ...generateShowHourMinuteSecond(timeFormat),
        format: timeFormat,
        use12Hours: showTime && showTime.use12Hours,
        defaultOpenValue,
      };
      const columns = getColumns(rcTimePickerProps);
      const timePickerCls = `${prefixCls}-time-picker-column-${columns}`;
      const timePicker = showTime ? (
        <TimePickerPanel
          {...rcTimePickerProps}
          {...showTime}
          prefixCls={`${prefixCls}-time-picker`}
          className={timePickerCls}
          placeholder={locale.timePickerLocale.placeholder}
          transitionName="slide-up"
        />
      ) : null;

      return (
        <Picker
          {...this.props}
          getCalendarContainer={getPopupContainer}
          format={mergedFormat}
          ref={this.savePicker}
          pickerClass={pickerClass}
          pickerInputClass={pickerInputClass}
          locale={locale}
          localeCode={localeCode}
          timePicker={timePicker}
          onOpenChange={this.handleOpenChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        />
      );
    }
  }
  return PickerWrapper;
}
