/**
 * Created by liujie on 2017/09/03.
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
// PureComponent必须 保持滚动时不render,
// 不必自己写shouldComponentUpdate
// 可编辑类表格 大多用 liststore的oridata
// TODO 梳理cell 所属的全部props,单独列出来， 其他都为内容的props
export default class TableCell extends PureComponent {
  constructor(props) {
    super(props);
    props.refGetter && props.refGetter(props.rowIndex, props.columnKey, this);
  }

  static propTypes = {
    refGetter: PropTypes.func,
    rowContextMenu: PropTypes.func,
    cellclassMap: PropTypes.object,
    cellProps: PropTypes.object, // 底层Cell组件props height width  // columnKey rowIndex
    cellOtherProps: PropTypes.object, // 自建cell组件外围属性
    columnProps: PropTypes.object, // 自建cell的 内容组件属性
    contentProps: PropTypes.object,
    rowIndex: PropTypes.number,
    colIndex: PropTypes.number,
    columnKey: PropTypes.string,
    data: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    tableKey: PropTypes.string, // 所属table之key
    enableOperate: PropTypes.bool, // 全局性的控制table的列不可操作
    isPureText: PropTypes.bool, // 全局性的控制table 只显示textcell
    // uncommon
    isAutoRowHeight: PropTypes.bool,
    colClass: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    cellUpdateFlag: PropTypes.bool,
    adaptiveCols: PropTypes.array,
  };

  render() {
    return null;
  }
}
