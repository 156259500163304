import PropTypes from 'prop-types';
import React from 'react';
import { isEmptyObj } from 'utils';
import TableCell from './TableCell';

export default class FooterOperater extends TableCell {
  static propTypes = {
    rowIndex: PropTypes.number,
    data: PropTypes.object,
    columnKey: PropTypes.string,
    onClick: PropTypes.func,
    operates: PropTypes.object,
  };

  render() {
    const { operates } = this.props;
    return operates.map((item = {}, index) => (
      <span
        className="under-line"
        key={index}
        onClick={e => {
          if (isEmptyObj(item)) {
            e.stopPropagation();
            return;
          }
          this.props.onClick('total', item.key, 'operate', e);
        }}
        style={{ color: '#0097cf', cursor: 'pointer' }}
      >
        [{item.title}]
      </span>
    ));
  }
}
