exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-todo-drop{position:relative}.fn-todo-drop__input{display:inline-block;position:relative}.fn-todo-drop__icon{font-size:12px;position:absolute;top:0;line-height:25px;right:5px;cursor:pointer}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-todo-drop",
	"fn-todo-drop": "fn-todo-drop",
	"fn-todo-drop__input": "fn-todo-drop__input",
	"fn-todo-drop__icon": "fn-todo-drop__icon"
};