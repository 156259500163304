/**
 * Created by Aceh on 2017/01/01.
 */
export default function NormalTitle(title, canToggle = true) {
  return {
    [canToggle ? 'cardFormToggler' : 'cardFormTicon']: {
      type: 'Icon',
      classname: '',
      eventNames: ['onClick'],
      labelProps: null,
      otherProps: {
        iconType: 'icon-carry-out',
      },
    },
    titleName: {
      type: 'Label',
      classname: '',
      labelProps: null,
      otherProps: {
        hasClass: false,
        children: title,
      },
    },
  };
}
