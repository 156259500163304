exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-radio-pure{width:14px;height:14px;background:#fff;border:1px solid #8ab0c5;border-radius:100%;-webkit-appearance:none}.fn-radio-pure:checked,.fn-radio-pure:hover{border-color:#0097cf}.fn-radio-pure:checked:before{content:\"\";display:block;width:50%;height:50%;border-radius:50%;margin-top:25%;margin-left:25%;background:#0097cf}.fn-radio-pure:disabled{border:1px solid #ddd}.fn-radio-pure:disabled:checked:before{background:#ddd}.fn-radio-label{font-size:14px;height:14px;line-height:1;vertical-align:middle}.fn-radio-label .fn-radio-pure{margin:0 4px;vertical-align:-1.5px}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-radio-pure",
	"fn-radio-pure": "fn-radio-pure",
	"fn-radio-label": "fn-radio-label"
};