import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import keycode from 'keycode';
import { Dropdown, PureInput, PureTextarea, Button, PureCheckbox as Checkbox } from 'components';
import { throttle, shallowCompareIgnoreFuncExcept, sequentiallyFetish, highlightStr } from 'utils';
import { prefixCls, menuPrefixCls } from './index.scss';
import _ from 'lodash';

export const colRender = (item, col, key, str, keyword, highlight) =>
  key === highlight ? highlightStr(str, keyword) : str;

export const ALL = '_NULL_';

export default class Select extends Component {
  static defaultProps = {
    compare: 'key',
    format: 'name',
    filter: 'name',
    header: ['name'],
    groupBy: 'group',
    showGroup: false,
    map: 'key',
    placeholder: '-请选择-',
    multiple: false,
    showIcon: true,
    autoActiveFirstOption: true,
    showSelectAll: true,
    selectTitle: '',
    selectAllFont: '全部',
    collapseAll: '全部',
    hiddenFlag: false,
    after: null,
    clearInexistenceValue: false,
  };

  static propTypes = {
    value: PropTypes.any,
    valueOfAll: PropTypes.string,
    className: PropTypes.string,
    menuClassName: PropTypes.string,
    showIcon: PropTypes.bool, // 是否显示下拉图标
    style: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    clear: PropTypes.bool,
    alignWidth: PropTypes.bool, // 宽度对齐
    header: PropTypes.array, // ['name'...] || [{key:'name', title:'名称'}...]
    data: PropTypes.array, // [{key:'11', name:'111'}...]
    compare: PropTypes.any, // 'key' || (v1, v2) => v1.key === v2.key
    map: PropTypes.any, // 'key' || reflectValue => reflectValue.map(item => item.key)
    format: PropTypes.any, // 'name' || reflectValue => reflectValue.map(item => item.name).join(',')
    filter: PropTypes.any, // 'name' || keyword => fetch(...).then(res => this.setState({ sug: res.data }))
    highlight: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onCancel: PropTypes.func,
    onInput: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    customValidity: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onCursor: PropTypes.func,
    reflect: PropTypes.func,
    shortcuts: PropTypes.object,
    autoActiveFirstOption: PropTypes.bool,
    notFilterOnOpenWhenHasData: PropTypes.bool,
    filterOnInit: PropTypes.bool,
    'data-path': PropTypes.string,
    children: PropTypes.any,
    silent: PropTypes.bool, // 静默模式
    collapseAll: PropTypes.any,
    selectAllFont: PropTypes.string, // 选择全部时的文案
    showSelectAll: PropTypes.bool, // 是否显示全选
    selectTitle: PropTypes.string, // 选择框上title
    multiLine: PropTypes.bool, // 多行显示
    pattern: PropTypes.string,
    showInputTitleTips: PropTypes.bool,
    optionRemovable: PropTypes.func,
    onRemoveOption: PropTypes.func,
    optionDisabled: PropTypes.func,
    inputWrapStyle: PropTypes.object,
    hiddenFlag: PropTypes.bool,
    after: PropTypes.func,
    customRenderMenu: PropTypes.func,
    showGroup: PropTypes.bool,
    groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]), // 分组配置
    customTrigger: PropTypes.bool, // 自定义触发器，会向外抛出 onClick 事件
    clearInexistenceValue: PropTypes.bool, // 是否清除 props 传入 value 中在sug接口返回中不存在的数据
    reflectFilter: PropTypes.func, // 自定义 reflectValue 过滤器 item => typeof item !== 'string' && item !== null;
  };

  static highlight = highlightStr;

  static colRender = colRender;

  static ALL = ALL;

  constructor(props) {
    super(props);
    this.setMapFn(props);
    this.setCompareFn(props);
    this.setFormatFn(props);
    this.setFilterFn(props);
    this.state = {
      keyword: '',
      active: false,
      cursorIndex: -1,
      // eslint-disable-next-line react/no-unused-state
      header: props.header,
      onInputStatus: false,
      inputHiddenFlag: false,
    };
    props.filterOnInit && this.filter('');
    this.isFilterChange = false; //  对 reflectValue 进行过滤后，reflectValue 值是否发生变更
  }

  listenDel = true;
  listenCtrlA = true;
  listenCtrlD = true;

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value && nextProps.multiple && this.state.active) {
      this.setState({ value: nextProps.value });
    }
    if (this.props.compare !== nextProps.compare) {
      this.setCompareFn(nextProps);
    }
    if (this.props.map !== nextProps.map) {
      this.setMapFn(nextProps);
    }
    if (this.props.format !== nextProps.format) {
      this.setFormatFn(nextProps);
    }
    if (this.props.filter !== nextProps.filter) {
      this.setFilterFn(nextProps);
    }
    if (this.props.data !== nextProps.data && nextProps.data && nextProps.data.length) {
      this.useStateData = false;
    }
  }

  shouldComponentUpdate = shallowCompareIgnoreFuncExcept({ format: 1 });

  setCompareFn(props) {
    const { compare } = props;
    const compareType = typeof compare;
    if (compareType === 'string') {
      this.compare = (v1, v2) => (v1 && v1[compare] !== undefined ? v1[compare] == v2[compare] : v1 == v2[compare]); // eslint-disable-line
    } else if (compareType === 'function') {
      this.compare = compare;
    } else {
      this.compare = () => false;
    }
  }

  setMapFn(props) {
    const { map } = props;
    const mapType = typeof map;
    if (mapType === 'string') {
      this.map = item => (typeof item === 'object' ? item[map] : item);
    } else if (mapType === 'function') {
      this.map = map;
    } else {
      this.map = item => item;
    }
  }

  setFormatFn(props) {
    const { format } = props;
    if (typeof format === 'string') {
      this.format = value =>
        value
          .map(item => item && item[format])
          .filter(item => item)
          .join(',');
    } else if (typeof format === 'function') {
      this.format = format;
    } else {
      this.format = () => '';
    }
  }

  setFilterFn(props) {
    const { filter, customRenderMenu } = props;
    const filterType = typeof filter;
    this.isStringFilter = filterType === 'string';
    if (filterType === 'function') {
      if (this.filter && typeof this.props.filter === 'function') return;
      const filterFn = async keyword => {
        // 在自定义Menu的情况下需要在filter请求接口设置loading
        customRenderMenu && this.loading(true);
        const data = await this.props.filter(keyword, this);
        customRenderMenu && this.loading(false);
        this.useStateData = Array.isArray(data);
        this.useStateData && this.setState({ data });
      };
      const asyncFilter = throttle(filterFn, 150);
      this.filter = (keyword, immediate) => {
        this.setState({ keyword });
        immediate ? filterFn(keyword) : asyncFilter(keyword);
      };
      this.isFnFilter = true;
    } else if (filterType === 'string') {
      this.filter = keyword => this.setState({ keyword });
    } else {
      this.filter = () => null;
    }
  }

  fetch = (...args) => {
    const fetch = sequentiallyFetish();
    this.fetch = (...args1) => {
      this.loading(true);
      const promise = fetch(...args1);
      promise.then(() => this.loading(false));
      return promise;
    };
    return this.fetch(...args);
  };

  loading = status => this.setState({ loading: status });

  customValidity = async () => {
    const { value, required, multiple, customValidity } = this.props;
    if (required && ((!value && value !== 0) || (multiple && !value.length))) {
      return '必填';
    } else if (customValidity) {
      return await customValidity(value);
    }
    return '';
  };

  reflect = (value, data) => value.map(v1 => data.find(v2 => this.compare(v1, v2)) || v1);

  isSelected = (v2, value) => value.some(v1 => this.compare(v1, v2));

  isSelectedAllOptions = () =>
    this.reflectValue.length >= this.data.length &&
    this.data.every(v2 => this.reflectValue.some(v1 => this.compare(v1, v2)));

  focus = () => this.input && this.input.focus();

  blur = () => this.input && this.input.blur();

  open = () => {
    const keyword = this.props.multiple ? '' : this.display;
    const value = this.props.multiple ? this.props.value : undefined;
    if (this.state.active) return;
    if (this.isFnFilter && (!this.props.notFilterOnOpenWhenHasData || !this.data.length)) this.filter(keyword, true);
    this.setCursor(this.props.autoActiveFirstOption && (!this.reflectValue || !this.reflectValue.length) ? 0 : -1);
    this.setState({ active: true, keyword, value }, () => this.drop.open());
    this.needAsyncSelect = false;
    this.input && delete this.input.dataset.isSelect;
    this.input && delete this.input.dataset.userInput;
    this.isFilterChange = false;
    this.setState({ onInputStatus: true });
  };

  handleClick = e => {
    !this.props.customTrigger && this.open();
    this.props.customTrigger && this.props.onClick?.();
  };

  close = () => this.drop && this.drop.close();

  cancel = () => {
    this.input && delete this.input.dataset.isSelect;
    this.props.onCancel && this.props.onCancel();
  };

  toggle = e => {
    if (!this.state.active) {
      this.focus();
      e.preventDefault();
    }
  };

  onClose = () => {
    if ((this.input.dataset.isSelect || this.isFilterChange) && this.props.multiple) {
      this.onChange(this.isValueOfAllSelected ? [ALL] : this.reflectValue.map(this.map));
    }
    if (!this.props.multiple && this.isFilterChange) {
      this.onChange('');
    }
    this.props.onClose && this.props.onClose();
    this.setState({ active: false, keyword: '' });
    this.setState({ onInputStatus: true });
  };

  onChange = value => {
    this.input && (this.input.dataset.isEdited = '1');
    this.props.onChange && this.props.onChange(value);
    this.setState({ onInputStatus: true, inputHiddenFlag: true });
  };

  onSelect(v2, e, forceRemove) {
    const { reflectValue } = this;
    const { multiple } = this.props;
    if (multiple) {
      const index = reflectValue.findIndex(v1 => this.compare(v1, v2));
      const newValue = [...reflectValue];
      index !== -1 || forceRemove ? newValue.splice(index, 1) : newValue.push(v2);
      const { valueOfAll } = this.props;
      const value =
        valueOfAll && newValue.length === this.data.length && !this.isFnFilter
          ? [valueOfAll]
          : newValue.filter(item => item !== valueOfAll);
      console.log(value);
      this.props.onSelect && this.props.onSelect(value);
      this.setState({ value, inputHiddenFlag: true });
      e && e.preventDefault();
    } else {
      this.close();
      this.onChange([v2].map(this.map)[0]);
      this.needAsyncSelect = false;
      this.setState({ inputHiddenFlag: true });
    }
    this.input && (this.input.dataset.isSelect = '1');
  }

  onSelectAll = e => {
    e && e.preventDefault();
    this.isSelectedAll ? this.unSelectAll() : this.selectAll();
  };

  onClear = () => this.onChange(this.props.multiple ? [] : '');

  onFocus = e => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
      // onFocus 里面有setState异步操作，先延迟看看
      setTimeout(() => {
        !this.state.active && !this.props.customTrigger && this.open();
      }, 300);
    } else {
      !this.state.active && !this.props.customTrigger && this.open();
    }
  };

  onBlur = e => {
    this.props.onBlur && this.props.onBlur(e);
    // 因为展开下拉的时候有 300 ms 的延迟，所以这里延迟 300 ms 关闭下拉，否则快速移动 tab 会导致下拉无法关闭
    setTimeout(() => {
      this.close();
    }, 300);
  };

  onInput = e => {
    this.filter(e.target.value);
    this.props.onInput && this.props.onInput(e);
    this.input && (this.input.dataset.userInput = e.target.value);
    !e.target.value && !this.props.multiple && this.listenDel && this.reflectValue.length && this.remove();
    this.state.cursorIndex === -1 && this.props.autoActiveFirstOption && this.setCursor(0);
    this.setState({ inputHiddenFlag: true });
  };

  onKeyDown = e => {
    this.state.active && this.dealShortcuts(e);
    const { cursorIndex, loading } = this.state;
    const realData = this.realData || [];
    const len = realData.length;
    const lastIndex = this.allInOptions ? len : len - 1;
    const cur = cursorIndex > lastIndex ? lastIndex : cursorIndex;
    this.setState({ inputHiddenFlag: true });
    this.props.onKeyDown && this.props.onKeyDown(e, this.fetch, loading, cur);
  };

  onRemoveOption = (e, option, i) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onRemoveOption(option, i);
  };

  onClickDisabledOption = e => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  dealShortcuts = e => {
    const { cursorIndex, keyword, loading } = this.state;
    const { shortcuts, filter, multiple, showSelectAll } = this.props;
    const realData = this.realData || [];
    const { data } = this;
    const { reflectValue } = this;
    const len = realData.length;
    const lastIndex = this.allInOptions ? len : len - 1;
    const cur = cursorIndex > lastIndex ? lastIndex : cursorIndex;

    switch (e.keyCode) {
      case 65: // ctrl a
        if (!loading && e.ctrlKey && this.listenCtrlA && multiple && showSelectAll) {
          this.selectAll();
          e.preventDefault();
        }
        break;
      case 68: // ctrl d
        if (e.ctrlKey && this.listenCtrlD && multiple && showSelectAll) {
          this.unSelectAll();
          e.preventDefault();
        }
        break;
      case 38: // up
        !loading && this.setCursor(cur <= 0 ? lastIndex : cur - 1, true);
        e.preventDefault();
        break;
      case 40: // down
        !loading && this.setCursor(cur >= lastIndex ? 0 : cur + 1, true);
        e.preventDefault();
        break;
      case 39: {
        // right
        const start = e.target.selectionStart;
        const end = e.target.selectionEnd;
        if (end - start !== e.target.value.length && start !== e.target.value.length) break;
        !loading &&
          cur > -1 &&
          (cur === 0 && this.allInOptions
            ? this.onSelectAll()
            : this.onSelect(realData[this.allInOptions ? cur - 1 : cur]));
        e.preventDefault();
        break;
      }
      case 13: {
        // enter
        this.needAsyncSelect = loading && !multiple && cursorIndex === 0;
        !loading &&
          cur > -1 &&
          (cur === 0 && this.allInOptions
            ? this.onSelectAll()
            : this.onSelect(realData[this.allInOptions ? cur - 1 : cur]));
        e.preventDefault();
        break;
      }
      case 27: // esc
        this.input.blur();
        break;
      case 8: // delete
        this.listenDel && (!filter || !keyword) && reflectValue.length && this.remove();
        break;
      default:
        break;
    }

    if (shortcuts) {
      const key = keycode(e.keyCode);
      if (!shortcuts[key]) return;
      e.preventDefault();
      const find = data.find(v1 => this.compare(shortcuts[key], v1));
      if (find) this.onSelect(find);
    }
  };

  selectAll = () => {
    this.setState({ inputHiddenFlag: true });
    if (this.props.valueOfAll && this.data.length === this.realData.length && !this.state.keyword) {
      this.setState({ value: [this.props.valueOfAll] });
    } else {
      // const value = this.reflectValue
      // const newValue = [...value]
      // this.data.forEach(v2 => !value.find(v1 => this.compare(v1, v2)) && newValue.push(v2))
      this.setState({ value: this.realData });
    }
    this.input && (this.input.dataset.isSelect = '1');
  };

  unSelectAll = () => {
    if (this.props.valueOfAll && this.data.length === this.realData.length && !this.state.keyword) {
      this.setState({ value: [] });
    } else {
      // const value = this.reflectValue
      // const newValue = [...value]
      // this.data.forEach(v2 => {
      //   const index = newValue.findIndex(v1 => this.compare(v1, v2))
      //   index !== -1 && newValue.splice(index, 1)
      // })
      this.setState({ value: [] });
    }
    this.input && (this.input.dataset.isSelect = '1');
  };

  remove = () => {
    const { multiple } = this.props;
    const { reflectValue } = this;
    multiple ? this.onSelect(reflectValue[reflectValue.length - 1], null, true) : this.onChange('');
  };

  setCursor = (cursorIndex, scrollIntoView) => {
    if (!scrollIntoView && this.scrolling) return;
    const curFocus = this.tableWrap && this.tableWrap.querySelector('tbody tr.focus');
    const newFocus = this.tableWrap && this.tableWrap.querySelector(`tbody tr:nth-child(${cursorIndex + 1})`);
    curFocus && curFocus.classList.remove('focus');
    newFocus && newFocus.classList.add('focus');
    this.state.cursorIndex = cursorIndex;
    this.props?.onCursor && this.props?.onCursor(cursorIndex);
    if (scrollIntoView && newFocus) {
      this.scrolling = true;
      this.scrollIntoView();
      clearTimeout(this.tid);
      this.tid = setTimeout(() => (this.scrolling = false), 100);
    }
  };

  scrollIntoView = () => this.tableWrap && this.tableWrap.querySelector('tbody tr.focus').scrollIntoView(false);

  refInput = input => (this.input = input && input.input);

  refTableWrap = tableWrap => (this.tableWrap = tableWrap);

  refDrop = drop => (this.drop = drop);

  renderToggle() {
    const {
      disabled,
      placeholder,
      filter,
      multiple,
      clear,
      showIcon,
      className,
      children,
      collapseAll,
      multiLine,
      onMouseEnter,
      onMouseLeave,
      style,
      pattern,
      recordScan,
      maxLength,
      inputWrapStyle,
      onAddIconClick,
      suffix,
      hiddenFlag,
      defaultValue,
      readOnly,
    } = this.props;
    const { keyword, active, onInputStatus, inputHiddenFlag } = this.state;
    const { reflectValue } = this;
    const title = this.format(reflectValue);
    const allFont = this.props.selectAllFont;
    const display =
      (this.isValueOfAllSelected || (this.isSelectedAll && reflectValue.length > 1)) && !active && collapseAll
        ? allFont
        : title;
    const InputC = multiLine ? PureTextarea : PureInput;
    const displayLine = multiLine ? (
      <p className="display-text-m">{display}</p>
    ) : (
      <span className="display-text">{display}</span>
    );
    this.display = display;
    // eslint-disable-next-line no-nested-ternary
    const hidden_field_display = hiddenFlag
      ? _.get(window.company_setting, 'task_hidden_field_display.value') === 'star'
        ? 'star-hidden'
        : 'null-hidden'
      : false;
    // eslint-disable-next-line no-nested-ternary
    const displayValue = inputHiddenFlag ? (active && filter ? keyword : display) : defaultValue;
    // 修复displayValue为对象时，展示问题
    // eslint-disable-next-line no-nested-ternary
    const value = displayValue
      ? typeof displayValue === 'object' && displayValue !== null
        ? this.format([displayValue])
        : displayValue
      : display;
    return (
      <InputC
        ref={this.refInput}
        style={style}
        data-field-type="Select"
        data-path={this.props['data-path']}
        className={classnames({
          [prefixCls]: true,
          active,
          multiple,
          disabled,
          [hidden_field_display]: hidden_field_display,
          [className]: className,
        })}
        customValidity={this.customValidity}
        disabled={disabled}
        title={title}
        placeholder={disabled ? '' : placeholder}
        value={value}
        onKeyDown={this.onKeyDown}
        onFocus={this.onFocus}
        onClick={this.handleClick}
        onBlur={this.onBlur}
        frozen={!filter || !active}
        onChange={this.onInput}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        showTitleTips={this.props.showInputTitleTips}
        recordEdited={false}
        recordScan={recordScan}
        pattern={pattern}
        maxLength={maxLength}
        wrapStyle={inputWrapStyle}
        suffix={suffix}
        onAddIconClick={onAddIconClick}
        readOnly={readOnly}
      >
        {multiple && active && displayLine}
        {showIcon && <i className="input-icon toggle-icon" onMouseDown={this.toggle} />}
        {clear && !!reflectValue.length && !disabled && (
          <i className="input-icon fn-icon fn-icon-error-o" onClick={this.onClear} />
        )}
        {children}
      </InputC>
    );
  }

  renderMenu = () => {
    const {
      multiple,
      filter,
      highlight,
      header,
      headerTip,
      emptyTip = '',
      silent,
      menuClassName,
      showSelectAll,
      format,
      onRemoveOption,
      optionRemovable,
      optionDisabled,
      selectTitle,
      customRenderMenu,
      groupBy,
      showGroup,
    } = this.props;
    const { keyword, cursorIndex, loading } = this.state;
    const { data } = this;
    const needFilter = this.isStringFilter && this.input.dataset.userInput;
    const highlightCol = (needFilter || this.isFnFilter) && (highlight || (this.isStringFilter ? filter : format));
    const realData = (this.realData = needFilter
      ? data.filter(item => item[filter] && `${item[filter]}`.includes(keyword))
      : data);
    const hasData = !!this.realData.length;
    const hasHeader = !!header[0]?.title || header[0]?.render;
    const allInOptions = (this.allInOptions = multiple && showSelectAll && this.realData.length > 1 && !hasHeader);
    const lastIndex = allInOptions ? realData.length : realData.length - 1;

    // 自定义menu部分
    if (customRenderMenu && _.isFunction(customRenderMenu)) {
      const customRenderMenuClass = classnames({
        [menuClassName]: menuClassName,
        [menuPrefixCls]: menuPrefixCls,
        'has-data': hasData,
      });
      return (
        <div className={customRenderMenuClass} ref={this.refTableWrap}>
          {customRenderMenu({ realData, onSelect: this.onSelect, loading })}
        </div>
      );
    }

    const getGroupTitle = (() => {
      let groupTitle; // TODO: 暂时不支持排序，需要外部处理好排序。目前仅支持按中文名称分组
      return item => {
        // TODO: 支持自定义函数返回分组名称
        const curGroupTitle = showGroup && item[groupBy] !== groupTitle ? item[groupBy] : null;
        groupTitle = item[groupBy];
        return curGroupTitle;
      };
    })();

    const renderGroupTitle = title => {
      return (
        showGroup &&
        !_.isNil(title) && (
          <tr
            className={`${menuPrefixCls}__group-title`}
            onMouseDown={e => this.onClickDisabledOption(e)}
            onClick={e => this.onClickDisabledOption(e)}
          >
            {multiple && <td className="checkbox-col" />}
            <td>{title}</td>
          </tr>
        )
      );
    };

    const options = realData.map((item, index) => {
      const checked = this.isValueOfAllSelected || this.isSelected(item, this.reflectValue);
      const optionIndex = allInOptions ? index + 1 : index;
      const focus = optionIndex === cursorIndex || (cursorIndex > lastIndex && index === lastIndex);
      const disCheck = optionDisabled ? optionDisabled(item, checked) : false;
      if (!checked) this.isSelectedAll = false;

      const curGroupTitle = getGroupTitle(item);

      return (
        <Fragment key={index}>
          {renderGroupTitle(curGroupTitle)}
          <tr
            className={classnames({ checked, focus, 'is-disabled': item.disabled })}
            key={index}
            onMouseDown={e => (disCheck ? this.onClickDisabledOption(e) : this.onSelect(item, e))}
          >
            {multiple && (
              <td className="checkbox-col">
                <Checkbox disabled={disCheck} checked={checked} />
              </td>
            )}
            {header.map((col, i) => {
              const key = col.key || col;
              const render = col.render || colRender;
              const str = item[key];
              const result = render(item, col, key, str, keyword, highlightCol);
              const showRemoveIcon =
                onRemoveOption && i === header.length - 1 && (!optionRemovable || optionRemovable(item, index));
              const tdTitleRender = col.titleRender || (() => str);
              const tdTitle = tdTitleRender(str, item);
              return (
                <td style={col.style} title={tdTitle} className={col.className} key={i}>
                  {result}
                  {showRemoveIcon && (
                    <i className="fn-icon-il fn-icon-close" onMouseDown={e => this.onRemoveOption(e, item, index)} />
                  )}
                </td>
              );
            })}
          </tr>
        </Fragment>
      );
    });
    const selectAllClassName = allInOptions && classnames({ checked: this.isSelectedAll, focus: cursorIndex === 0 });
    const menuClass = classnames({
      [menuClassName]: menuClassName,
      [menuPrefixCls]: menuPrefixCls,
      'has-data': hasData,
      'is-multiple': multiple,
      'is-group': showGroup,
    });

    if (silent && !hasData) return <div />;

    const allFont = this.props.selectAllFont;

    return (
      <div className={menuClass} ref={this.refTableWrap}>
        <section>
          {headerTip ? (
            <div className="header-tip">
              <i className="fn-icon fn-icon-info-o" />
              {headerTip}
            </div>
          ) : (
            ''
          )}
          <table data-col={header.length}>
            {hasHeader && hasData && (
              <thead>
                <tr>
                  {multiple && (
                    <th className="checkbox-col">
                      {showSelectAll && <Checkbox checked={this.isSelectedAll} onMouseDown={this.onSelectAll} />}
                      {!showSelectAll && selectTitle}
                    </th>
                  )}
                  {header.map((h, i) => {
                    const title = _.isFunction(h.title) ? h.title() : h.title;
                    return (
                      title && (
                        <th style={h.style} colSpan={h.colSpan} className={h.className} key={i}>
                          {title}
                        </th>
                      )
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {!loading && this.dataSource && !hasData && (
                <tr className="empty-tip">
                  {emptyTip ? (
                    <td>
                      <i className="fn-icon fn-icon-info-o" />
                      {emptyTip}
                    </td>
                  ) : (
                    <td>无相关数据</td>
                  )}
                </tr>
              )}
              {allInOptions && (
                <tr colSpan={header.length} className={selectAllClassName} onMouseDown={this.onSelectAll}>
                  <td className="checkbox-col">
                    <Checkbox checked={this.isSelectedAll} />
                  </td>
                  <td>{allFont}</td>
                </tr>
              )}
              {options}
            </tbody>
          </table>
        </section>
        {multiple && hasData && (
          <footer>
            <Button>确定</Button> <Button onMouseDown={this.cancel}>取消</Button>
          </footer>
        )}
      </div>
    );
  };

  render() {
    const { disabled, alignWidth, multiple, valueOfAll, onOpen, after, clearInexistenceValue, reflectFilter } =
      this.props;
    const { active, keyword } = this.state;
    const value = multiple && active ? this.state.value : this.props.value;
    const reflect = this.props.reflect || this.reflect;
    // eslint-disable-next-line no-nested-ternary
    const trustValue = multiple ? value || [] : value || value === 0 ? [value] : [];
    // 没有筛选值keyword时，勾选全部才会传valueOfAll； 带接口的筛选手动勾全部才会传valueOfAll
    const isValueOfAllSelected = valueOfAll && valueOfAll === trustValue[0] && !keyword;
    this.isValueOfAllSelected = isValueOfAllSelected;
    this.dataSource = this.useStateData ? this.state.data : this.props.data;
    this.data = this.dataSource || [];
    this.reflectValue = isValueOfAllSelected ? this.data : reflect(trustValue, this.data);
    if (clearInexistenceValue && typeof reflectFilter === 'function') {
      const filterReflectValue = this.reflectValue.filter(reflectFilter);
      if (filterReflectValue.length !== this.reflectValue.length) {
        this.isFilterChange = true;
        this.reflectValue = filterReflectValue;
      }
    }
    this.isSelectedAll =
      multiple &&
      // eslint-disable-next-line no-nested-ternary
      (active
        ? !valueOfAll || !this.isFnFilter || isValueOfAllSelected || keyword
        : valueOfAll
        ? isValueOfAllSelected
        : !this.isFnFilter && this.isSelectedAllOptions());
    return (
      <Dropdown
        ref={this.refDrop}
        menu={active ? this.renderMenu() : null}
        disabled={disabled}
        onClose={this.onClose}
        onOpen={onOpen}
        alignWidth={alignWidth}
        maxHeight={335}
      >
        {this.renderToggle()}
        {after && after()}
      </Dropdown>
    );
  }
}
