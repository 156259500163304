import React, { useEffect, useState } from 'react';
import { Table, Column } from 'fixed-data-table-2';
import { TABLE_DEFAULT_WIDTH, TABLE_HEADER_HEIGHT, MULTI_TABLE_FAKE_SCROLLER_WIDTH } from 'constants';
import _ from 'lodash';

const MultiTable = ({
  scrollLeft,
  scrollTop,
  onScrollEnd,
  rowHeightGetter,
  rowClassNameGetter,
  onRowDoubleClick,
  onRowClick,
  onColumnReorderEndCallback,
  onColumnResizeEndCallback,
  isColumnResizing,
  scrollToRow,
  scrollToColumn,
  headerHeight: tableHeaderHeight,
  rowKeyGetter,
  rowHeight,
  width,
  state,
  header,
  containerHeight,
  updateState,
  renderColumns,
  columnWidths,
  hideColumn = [],
}) => {
  const [multiHeaders, setMultiHeaders] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [isMultiTable, setIsMultiTable] = useState(false);
  // 获取表格深度
  const getAllHeaderRowsCount = (columns, childrenColumnName) => {
    let length = 0;
    if (columns && columns.length > 0) {
      columns.forEach(column => {
        const depth = getAllHeaderRowsCount(column[childrenColumnName], childrenColumnName) + 1;
        length = Math.max(depth, length);
      });
    }
    return length;
  };

  const getInternalColumns = rows => {
    return [...rows];
  };
  // 所有子项宽度累加
  const getWidthSum = rows => {
    const sum = rows.reduce((p, c) => {
      const key = c.id;
      const colWidth = columnWidths[key] || header[key]?.width || TABLE_DEFAULT_WIDTH;
      return p + parseInt(colWidth, 10);
    }, 0);
    return sum;
  };
  // 平铺所有子集
  const getFlattenColumns = (columnsGroup, childrenColumnName) => {
    const rows = [];
    function travel(columns) {
      if (columns && columns.length > 0) {
        columns.forEach(column => {
          if (!column[childrenColumnName]) {
            rows.push({ ...column, key: column.key || column.id });
          } else {
            travel(column[childrenColumnName]);
          }
        });
      }
    }
    travel(columnsGroup);
    return rows;
  };
  //  获取多级渲染结构
  const getMultiHeaders = (columnsGroup, rowCount, childrenColumnName) => {
    const rows = [];
    const specialColumn = {};
    const resolveColumn = (column, current, isSet = false) => {
      const col = { ...column };
      const children = col[childrenColumnName];
      col.isRenderHeader = false;
      col.isRoot = !!children;
      col.rowSpan = children ? 1 : rowCount - current;
      if (children) {
        const allChild = getFlattenColumns(children, childrenColumnName);
        col.colSpan = getWidthSum(allChild);
        specialColumn[col.id] = col.colSpan;
      } else {
        col.colSpan = getWidthSum([col]);
      }
      if (current === 0) {
        col.isRenderHeader = true;
        return col;
      }
      if (children) {
        col.isRenderHeader = true;
        const result = [];
        if (isSet) {
          return col;
        }
        let stack = [...children.slice()];
        children.forEach(item => {
          const elColumn = resolveColumn(item, current, true);
          elColumn.isRenderHeader = true;
          result.push(elColumn);
          if (item[childrenColumnName]) {
            stack = stack.concat(item[childrenColumnName]);
          }
        });
        return result;
      }
      return col;
    };
    const travel = (columns, current = 0) => {
      rows[current] = rows[current] || [];
      columns.forEach(col => {
        const column = resolveColumn(col, current);
        const children = col[childrenColumnName];
        if (current === 0) {
          rows[current].push(column);
        } else if (children) {
          rows[current] = [...rows[current], ...column];
        } else {
          rows[current].push(column);
        }
      });
      rows[current] = getInternalColumns(rows[current]);
      if (current < rowCount - 1) {
        travel(rows[current], current + 1);
      }
    };
    travel(columnsGroup);
    return [rows, specialColumn];
  };
  // 构建关系树
  const transformData = (data, hideColumns = []) => {
    const cloneData = _.cloneDeep(data);
    const output = [];
    let isMultiHeader = false;
    function findOrAddNode(path) {
      let current = output;
      for (let i = path.length - 1; i >= 0; i--) {
        const found = current.find(n => n.id === path[i]);
        if (!found) {
          const newNode = { id: path[i], children: [] };
          current.push(newNode);
          current = newNode.children;
        } else {
          current = found.children;
        }
      }
      return current;
    }
    Object.keys(cloneData).forEach(key => {
      if (!hideColumns.includes(key)) {
        const item = cloneData[key];
        if (item?.ancestors?.length) {
          isMultiHeader = true;
          const path = item.ancestors?.reverse();
          findOrAddNode(path).push({ id: key });
        } else {
          output.push({ id: key });
        }
      }
    });
    if (isMultiHeader) {
      output.push({
        id: 'blank_cell',
      });
    }
    return [output, isMultiHeader];
  };
  const renderMultiTable = (multiTableGroup = []) => {
    if (!isMultiTable) {
      return null;
    }
    // 删除最后一个数组
    const newMultiTableGroup = [...multiTableGroup];
    newMultiTableGroup.pop();
    const publicProps = {
      scrollLeft,
      scrollTop,
      onScrollEnd,
      rowHeightGetter,
      rowClassNameGetter,
      onRowDoubleClick,
      onRowClick,
      onColumnReorderEndCallback,
      onColumnResizeEndCallback,
      isColumnResizing,
      scrollToRow,
      scrollToColumn,
      rowKeyGetter,
      rowHeight,
      width,
      headerHeight: tableHeaderHeight,
    };
    return newMultiTableGroup.map((row, index) => {
      const cellGroupWrapperHeight = index * containerHeight;
      const count = getFlattenColumns(row, 'children') + index;
      return (
        <Table
          {...publicProps}
          rowsCount={0}
          className={`header-table header-table-level-${multiTableGroup.length - 1 - index}`}
          isColumnResizing={false}
          showScrollbarX={false}
          showScrollbarY={false}
          height={TABLE_HEADER_HEIGHT}
          headerHeight={TABLE_HEADER_HEIGHT}
          cellGroupWrapperHeight={cellGroupWrapperHeight}
          key={row + count}
          onHorizontalScroll={value => updateState({ scrollLeft: value })}
          touchScrollEnabled
        >
          {renderColumns(row, false, index)}
          <Column columnKey="fake scrollbar" width={MULTI_TABLE_FAKE_SCROLLER_WIDTH} />
        </Table>
      );
    });
  };
  // 通过关系的标注来实现拖拽时宽度的更改
  const createHeaderRelationship = data => {
    const output = {};

    function getLeafChildrenIds(item) {
      if (!item.children) {
        return [];
      }

      const ids = [];
      item.children.forEach(child => {
        if (!child.children) {
          ids.push(child.id);
        } else {
          ids.push(...getLeafChildrenIds(child));
        }
      });

      return ids;
    }

    function helper(item, parents) {
      const { id } = item;
      const leafChildrenIds = getLeafChildrenIds(item);

      if (output[id]) {
        output[id].parent = parents[parents.length - 1] || null;
        output[id].allParents = output[id].allParents.concat(parents);
        output[id].children = output[id].children.concat(leafChildrenIds);
      } else {
        output[id] = {
          children: leafChildrenIds,
          parent: parents[parents.length - 1] || null,
          allParents: parents,
        };
      }

      if (item.children) {
        item.children.forEach(child => {
          helper(child, parents.concat(id));
        });
      }
    }

    data.forEach(item => {
      helper(item, []);
    });

    return output;
  };

  const getShowColumns = data => {
    const { deleteColumn = [] } = state;
    const columns = getFlattenColumns(data, 'children')?.map(el => {
      return el.id;
    });
    // 获取差集
    return _.xor(hideColumn, deleteColumn, columns);
  };
  const getShowColumnsWidth = columns => {
    let combineWidth = 0;
    columns.forEach(key => {
      if (key !== 'blank_cell') {
        const columnWidth = columnWidths[key] || header[key]?.width || TABLE_DEFAULT_WIDTH;
        combineWidth += columnWidth;
      }
    });
    return combineWidth;
  };

  const initTable = () => {
    const [tableData, isMultiHeader] = transformData(header, hideColumn);
    setIsMultiTable(isMultiHeader);
    const AllHeaderRowsCount = getAllHeaderRowsCount(tableData, 'children');
    const [multiHeadersValue, specialColumn] = getMultiHeaders(tableData, AllHeaderRowsCount, 'children');
    const headerRelationship = createHeaderRelationship(tableData);

    const headerKeys = [...multiHeadersValue].pop().map(el => {
      return el.id;
    });
    setStandardData(tableData);
    setMultiHeaders(multiHeadersValue);
    updateState({
      headerRelationship,
      columnWidths: {
        ...columnWidths,
        ...specialColumn,
      },
      headerKeys,
      tableHeaderRelationShip: tableData,
      isMultiTable: isMultiHeader,
      multiTableDeep: multiHeadersValue.length,
    });
  };

  const getShowColumnWidth = row => {
    const currentCol = getShowColumns(row, 'children');
    const showColumnWidth = getShowColumnsWidth(currentCol);
    return showColumnWidth;
  };

  useEffect(() => {
    initTable();
  }, [header, hideColumn]);

  useEffect(() => {
    const showColumnWidth = getShowColumnWidth(standardData);
    updateState({
      showColumnWidth,
    });
  }, [columnWidths, hideColumn]);

  return <>{renderMultiTable(multiHeaders)}</>;
};

export default MultiTable;
