import React from 'react';
import { Tips, Progress } from 'components';
import Cell from './Cell';
import TableCell from './TableCell';

/**
 * 同表格主题色保持一致
 * @see /src/components/utilcomponents/tableV2/FixedDataTable/index.scss
 */
const COLOR_MAP = {
  red: '#F12222',
  green: '#14AA65',
  gray: '#4E6276',
};

/**
 * @description 表格内进度条控件，需要与后端约定进度条数据结构
 * @example
 * {
 *      color: 'red', //  red, green, gray
 *      progress: 0, // 进度值
 *      tips: 'this is tooltips',
 *      status: 1, // 1 failed, 2 success, 3 nothing
 *      type: 'Progress'
 * }
 */
export default class ProgressCell extends TableCell {
  renderProgress() {
    const { cellProps, cellOtherProps } = this.props;
    const { data } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const dataItem = data.getObjectAt(rowIndex);
    const progressRes = dataItem[columnKey] || {};
    const { color, progress = 0, tips, status } = progressRes;
    const style = {
      borderRadius: 0,
      marginLeft: 6,
      marginRight: 6,
    };
    const tipsStyle = {
      width: '100%',
    };
    const strokeColor = COLOR_MAP[color] || color;
    // TODO: kill magic number
    const isNothing = status === 3;
    return (
      <Tips className={`progress-cell_tip_${columnKey}`} title={tips} style={tipsStyle}>
        <Progress percent={progress} style={style} strokeColor={strokeColor} showInfo={!isNothing} />
      </Tips>
    );
  }
  render() {
    const { cellProps } = this.props;
    const { rowIndex, columnKey } = cellProps;
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        {this.renderProgress()}
      </Cell>
    );
  }
}
