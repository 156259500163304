/**
 * Created by JANY on 2016/12/2.
 */
import LoadMap from './LoadMap';
import Plugin from './Plugin';
import Service from './Service';
import AddrSug from './AddrSug';
import MapShow from './MapShow';
import MapInfoWin from './MapInfoWin';
import DriveDistance from './DriveDistance';
import POISelector from './POISelector';

const Amap = {};
Amap.LoadMap = LoadMap;
Amap.Plugin = Plugin;
Amap.Service = Service;
Amap.AddrSug = AddrSug;
Amap.MapShow = MapShow;
Amap.MapInfoWin = MapInfoWin;
Amap.DriveDistance = DriveDistance;
Amap.POISelector = POISelector;
export default Amap;
// export { DriveDistance }
export { LoadMap, Plugin, Service, AddrSug, MapShow, MapInfoWin, DriveDistance, POISelector };
