/**
 * Created by jany on 2017/03/30.
 * 编辑 zgz  2017/03/31.
 */
/* eslint-disable */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SelectDrop } from 'components';
import { createTip } from 'utils/checkInfo';
import { WARN, INFO, ERROR } from 'constants';
import { abordFetch, typeIs, switchQrNum, showInfo } from 'utils';
import fetchApi from 'utils/fetchApi';
import _ from 'lodash';
import classnames from 'classnames';
import URI from 'urijs';
import { withPopUpI18n, defaultI18n } from '@/utils/i18n/context';
import './index.scss';
let starTime = '',
  endTime = '';
class ScanSug extends Component {
  constructor(prop) {
    super(prop);
    let isScan = prop.isScan;
    if (isScan === 'false') isScan = false;
    this.state = {
      valueby: '',
      SelectDateArry: [],
      isNotScanSug: prop.isNotScanSug, // 是否启用扫码枪判断
      gunInFlag: isScan, // gunInFlag进行扫码行为，包含判断和开关两种行为
      isScan, // isScan为是否默认开启用扫码枪(不用进行扫码枪判断，默认进行扫码)
      isSlient: true,
    };
  }

  static propTypes = {
    className: PropTypes.string,
    inputIconType: PropTypes.string,
    OrderSugInfo: PropTypes.func, // 外部获取sug数据的函数
    isScan: PropTypes.bool,
    uniqueKey: PropTypes.string,
    filterTips: PropTypes.string,
    fetchApi: PropTypes.object,
    isNotScanSug: PropTypes.bool,
    i18n: PropTypes.object,
  };
  static defaultProps = {
    className: '',
    isScan: false, // 扫码开关默认关闭
    isNotScanSug: false, // 默认进行扫码判断
    i18n: defaultI18n,
  };

  formateSugEnum = (item, header, enumDict) => {
    const newItem = item;
    Object.keys(header).forEach(key => {
      if (enumDict[key]) {
        newItem[`${key}_display`] = _.get(enumDict[key], [newItem[key], 'display'], '');
      } else if (header[key].ref_enum && enumDict[header[key].ref_enum]) {
        newItem[`${key}_display`] = _.get(enumDict[header[key].ref_enum], [newItem[key], 'display'], '');
      }
    });
    return newItem;
  };
  formateSugHeader = (header, enumDict) => {
    const newHeader = {};
    Object.keys(header).forEach(key => {
      if (enumDict[key] || (header[key].ref_enum && enumDict[header[key].ref_enum])) {
        newHeader[`${key}_display`] = header[key].title;
      } else {
        newHeader[key] = header[key].title;
      }
    });
    return newHeader;
  };
  // 后端返回数据后，下拉中需要显示的枚举值进行格式化，否则显示不正确。
  formateSugData = data => {
    const resData = data.res || {},
      sugData = resData.data || [];
    const header = resData.header || {};
    const newData = [];
    const enumDict = resData.enum || {};
    this.currEnum = enumDict;
    if (data.errno == 27) {
      showInfo(ERROR, data.errmsg);
    }
    sugData.forEach(item => {
      const newItem = item;
      if (!newItem.otherProps) newItem.otherProps = {};
      newItem.otherProps.disable = item.disable;
      newData.push(this.formateSugEnum(newItem, header, enumDict));
    });
    return {
      reqData: newData,
      tableHeader: this.formateSugHeader(header, enumDict),
    };
  };
  paraFormate = (para, key, value, checkSameReq = true) => {
    // 防止重复请求
    if (checkSameReq && this.lastValue === value) {
      console.log('same value', value);
      return false;
    }
    this.lastValue = value;
    const newpara = Object.assign({}, para);
    const wrapInstance = this.SelectDropScan;
    newpara.is_scan = wrapInstance.select.input.dataset.isScan || 0;
    newpara.query = newpara.query || {};
    newpara.query[key] = value;
    return newpara;
  };
  // 有自定义请求接口的， 走自定义接口, 否则走此接口
  // 扫码和非扫码都走此接口
  getFetchApiPara = isScan => {
    const api = this.props.fetchApi || {
      url: '/Table/Suggest/orderSug',
      para: {
        category: 'Order',
        tab: 'order_sug',
        page_num: 1,
        page_size: 10,
        query: [],
        is_scan: 0,
      },
      enableTrim: true,
      key: 'order_num',
    };
    if (isScan && api.para) {
      api.para.is_scan = 1;
    } else {
      api.para && (api.para.is_scan = 0);
    }
    return Object.assign({}, api);
  };
  // 默认选中后执行
  dealSelectData = (selectDataArry = []) => {
    const otherParam = {
      filterTips: this.getFilterTips(),
      is_scan: this.state.gunInFlag ? 1 : 0,
      enumDict: this.currEnum,
    };
    this.props.orderSugInfo && this.props.orderSugInfo(selectDataArry[0], otherParam);
    setTimeout(() => {
      this.clearSugState();
    }, 10);
  };
  clearSugState = () => {
    const wrapInstance = this.SelectDropScan;
    wrapInstance && wrapInstance.handleResetState(true);
    if (wrapInstance && wrapInstance.select) {
      wrapInstance.select.close();
      wrapInstance.select.input.value = '';
      delete wrapInstance.select.input.dataset.isSelect;
      delete wrapInstance.select.input.dataset.userInput;
      wrapInstance.select.blur();
      wrapInstance.select.focus();
    }
  };
  handleOrderSug = (...args) => {
    if (!args[2].isSelectClick && args[0] === undefined) return;
    this.dealSelectData(args);
    this.setState({ isSlient: true });
  };
  // 以下实现扫码枪部分
  sugFetchApi = () => {
    let defaultVal = this.state.valueby;
    // 扫码在不同的页面中传递的tab值不同， 所以不走默认配置
    const api = this.getFetchApiPara(true);
    const url = api.url || '/Table/Suggest/orderSug';
    const reqPara = api.para || {};
    // 判断 defaultVal是否为扫描的二维码，二维码中带链接，需要取qr参数
    if (defaultVal.indexOf('http') !== -1) {
      const queryInfo = URI.parseQuery(URI(defaultVal).query());
      if (queryInfo && queryInfo.qr) {
        defaultVal = queryInfo.qr;
      }
    }
    // console.log('befor', defaultVal)
    // 中文状态下， 扫码为中文分割符的时候进行替换
    defaultVal = switchQrNum(defaultVal);
    // console.log('after', defaultVal)
    if (reqPara.query === undefined || !typeIs(reqPara.query, 'object')) reqPara.query = {};
    reqPara.query.order_num = defaultVal;
    reqPara.order_num = defaultVal;
    const option = {
      method: 'POST',
      body: { req: reqPara },
    };
    if (this.lastReq) abordFetch(this.lastReq).abort();
    const req = fetchApi(url, option);
    this.lastReq = req;
    // 保存最后一次请求的value值，防止发出多次相同请求(记录原始值)
    this.lastValue = this.state.valueby;
    this.lastFetchTime = new Date().getTime();
    req.then(res => {
      if (res.errno === 0) {
        const formateData = this.formateSugData(res || {});
        const selectDataArry = formateData.reqData || [];
        // const selectDataArry = res.res.data
        if (res.res.enum) {
          this.currEnum = res.res.enum || {};
        }
        // 扫码出现多条，不处理
        if (selectDataArry.length > 1) {
          createTip('运单重复，请手工输入运单号', INFO).show();
          return;
        }
        if (selectDataArry.length === 0) {
          selectDataArry[0] = {};
        }
        this.dealSelectData(selectDataArry);
        // 默认开启的情况，扫码状态依旧开启
        this.setState({
          gunInFlag: this.state.isScan ? this.state.isScan : false,
        });
        // 重置gun标志为人工输入状态
      } else {
        createTip(res.errmsg, WARN).show();
      }
    });
  };
  //
  scanSug = () => {
    // 值相同的情况， 只发送一次请求
    const cTime = new Date().getTime();
    const interval = 30; // 防短时多次触发
    if (cTime - this.lastFetchTime < interval) {
      console.log('submit too fast');
      return;
    }
    setTimeout(() => {
      if (this.lastValue !== undefined && this.lastValue === this.state.valueby) {
        console.log('same value');
        return;
      }
      this.sugFetchApi();
    }, 200);
  };
  isScanCheck = defaultVal => {
    let flag = this.state.isScan;
    if (defaultVal.length < 3) {
      this.lastValue = undefined;
      this.lastFetchTime = undefined;
      this.setState({
        gunInFlag: this.state.isScan || false,
      });
    }
    // 当输入达到4位时开始搜索，关闭slient
    this.setState({ valueby: defaultVal, isSlient: defaultVal.length < 4 });
    switch (defaultVal.length) {
      case 0:
      case 1:
        starTime = new Date().getTime();
        flag = true;
        break;
      case 2:
        flag = true;
        break;
      case 3:
        endTime = new Date().getTime();
        if (endTime - starTime < 50) {
          this.setState({ gunInFlag: true });
          flag = true;
        } else {
          this.setState({ gunInFlag: false });
          flag = flag || false;
        }
        break;
      default:
        flag = this.state.isScan || this.state.gunInFlag;
    }
    return flag;
  };
  handleChange = valueby => {
    const defaultVal = valueby.trim();
    this.isScanCheck(defaultVal);
    // 扫码开启控制的情况以及长度小于10的情况不予处理
    if ((this.state.isScan && defaultVal.length !== 0) || (this.state.gunInFlag && defaultVal.length !== 0)) {
      this.scanSug();
    }
  };
  onKeyBoardEnter = (e, fetch, loading, cur) => {
    // 禁止掉精确搜索
    // console.log(e.keyCode)
    if (e.keyCode !== 13) return;
    // 已经执行了select内部的回车选中
    // console.log(loading)
    // console.log(cur)
    if (!loading && cur > -1) return;
    // 下拉的请求 cancel掉
    // this.SelectDropScan && this.SelectDropScan.handleResetState(true)
    const value = (e.target.value || '').trim();
    if (!value || (this.lastEnterValue && this.lastEnterValue === value)) return;
    this.lastEnterValue = value;
    const fetchPara = this.getFetchApiPara();
    // 此处为精确搜索
    const conf = { method: 'POST', body: { req: fetchPara.para } };
    const paraRes = this.paraFormate(fetchPara.para, fetchPara.key, value, false);
    if (!paraRes) return;
    conf.body.req = { ...paraRes, is_exact: 1 };
    const req = fetchApi(fetchPara.url || '/Table/Suggest/orderSug', conf);
    req.then(res => {
      if (res.errno === 0) {
        if (res.res.data && res.res.data.length === 0) {
          console.log('未查询到可操作的');
          // createTip(`未查询到可操作的${(this.props.filterTips || '').replace('号', '') || '运单'}：${value}`, WARN).show()
          // this.clearSugState()
          return;
        }
        this.lastEnterValue = undefined;
        const formateData = this.formateSugData(res || {});
        const selectDataArry = formateData.reqData || [];
        // const selectDataArry = res.res.data || []
        if (res.res.enum) {
          this.currEnum = res.res.enum || {};
        }
        this.dealSelectData(selectDataArry);
      } else {
        if (res.errno === 27) {
          showInfo(ERROR, res.errmsg);
        }
        // createTip(res.errmsg, WARN).show()
        // this.clearSugState()
        console.log(res.errmsg);
      }
    });
  };
  getFilterTips = () => {
    const orderDict = this.props.i18n.get('order', '运单');
    return (this.props.filterTips || '运单号') === '运单号'
      ? `输入${this.props.filterTips || `${orderDict}号`}或直接扫码`
      : `输入${this.props.filterTips || `${orderDict}号`}`;
  };

  getUniqueKey = () => this.props.uniqueKey || 'od_link_id';

  render() {
    const { className, inputIconType } = this.props;
    const filterTips = this.getFilterTips();
    const codeFetchApi = this.getFetchApiPara(false);
    codeFetchApi.paraCallback = this.paraFormate;
    codeFetchApi.formateFunc = this.formateSugData;
    const uniqueKey = this.getUniqueKey();
    // 不启用扫码枪sug的， 不传入onChange方法（此处selectDrop有bug， 会导致input的value来回清空）
    const changeFunc = this.state.isNotScanSug ? undefined : this.handleChange;
    return (
      <SelectDrop
        blurCheck
        enableTrim
        silent={this.state.isSlient}
        minFetchLen={3}
        ref={x => (this.SelectDropScan = x)}
        onChange={changeFunc}
        fetchApi={codeFetchApi}
        handleSelected={this.handleOrderSug}
        selectFirstLine
        key="qr_code"
        isKeyboard
        recordScan={true}
        inputIconType={inputIconType}
        uniqueKey={uniqueKey}
        className={classnames('in-bk', className)}
        showKey="order_number"
        placeholder={filterTips}
        isNotScanSug={this.state.isNotScanSug}
        timeInterval={300}
        onInputKeyDown={this.onKeyBoardEnter}
      />
    );
  }
}
export default withPopUpI18n(ScanSug);
