/**
 * Created by cyf on 2017/10/17.
 */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Dropdown, PureInput } from 'components';
import { prefixCls, menuClass } from './index.scss';

export default class SelectDropColor extends PureComponent {
  static defaultProps = {
    placeholder: '-请选择-',
  };
  static propTypes = {
    value: PropTypes.any,
    colorData: PropTypes.array, // 颜色合计
    className: PropTypes.string,
    style: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    clear: PropTypes.bool,
    alignWidth: PropTypes.bool, // 宽度对齐
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClose: PropTypes.func,
    autoActiveFirstOption: PropTypes.bool,
    'data-path': PropTypes.string,
    customValidity: PropTypes.func, // 颜色校验
  };

  constructor(props) {
    super(props);
    this.state = { active: false, cursorIndex: -1 };
    this.listenDel = true;
  }

  validateRequired = () => {
    const { value } = this.props;
    return value ? '' : '必填';
  };
  focus = () => this.input && this.input.focus();
  blur = () => this.input && this.input.blur();
  open = () => {
    if (this.state.active) return;
    this.setState({ active: true }, () => this.drop.open());
    this.input && delete this.input.input.dataset.isSelect;
  };
  close = () => this.drop && this.drop.close();
  toggle = e => {
    if (!this.state.active) {
      this.focus();
      e.preventDefault();
    }
  };
  onOpen = () => {
    this.bindKeyBoardEvent();
    this.setCursor(this.props.autoActiveFirstOption ? 0 : -1);
  };
  onClose = () => {
    this.props.onClose && this.props.onClose();
    // eslint-disable-next-line react/no-unused-state
    this.setState({ active: false, keyword: '' }, this.offKeyBoardEvent);
  };
  onChange = value => this.props.onChange && this.props.onChange(value);

  onSelect(val) {
    this.close();
    this.onChange(val && val.background);
  }

  onBlur = () => {
    this.props.onBlur && this.props.onBlur();
    this.state.active && this.close();
  };
  onKeyDown = e => {
    const { cursorIndex } = this.state;
    const colorData = this.props.colorData || [];
    const len = colorData.length;
    const lastIndex = len - 1;
    const cur = cursorIndex > lastIndex ? lastIndex : cursorIndex;
    if (len <= 0) return;
    switch (e.keyCode) {
      case 38: // up
        this.setCursor(cur <= 0 ? lastIndex : cur - 1, true);
        e.preventDefault();
        break;
      case 40: // down
        this.setCursor(cur >= lastIndex ? 0 : cur + 1, true);
        e.preventDefault();
        break;
      case 13: // enter
        cur > -1 && this.onSelect(colorData[cur]);
        e.preventDefault();
        break;
      case 27: // esc
        this.input.blur();
        break;
      case 8: // delete
        this.listenDel && this.onSelect();
        break;
      default:
        break;
    }
  };
  setCursor = cursorIndex => {
    const curHighlight = this.tableWrap && this.tableWrap.querySelector('.color-box span.highlight');
    const newHighlight =
      this.tableWrap && this.tableWrap.querySelector(`.color-box span:nth-child(${cursorIndex + 1})`);
    curHighlight && curHighlight.classList.remove('highlight');
    newHighlight && newHighlight.classList.add('highlight');
    this.state.cursorIndex = cursorIndex;
  };
  bindKeyBoardEvent = () => this.input.input.addEventListener('keydown', this.onKeyDown, false);
  offKeyBoardEvent = () => this.input.input.removeEventListener('keydown', this.onKeyDown, false);
  refInput = input => (this.input = input);
  refTableWrap = tableWrap => (this.tableWrap = tableWrap);
  refDrop = drop => (this.drop = drop);

  renderToggleContent() {
    const { disabled, placeholder, required, className, value, customValidity } = this.props;
    const { active } = this.state;
    // const customValidity = required ? this.validateRequired : undefined
    return (
      <PureInput
        ref={this.refInput}
        data-path={this.props['data-path']}
        key="2"
        className={classnames({ [prefixCls]: true, active, disabled, [className]: className })}
        // customValidity={required ? this.validateRequired : customValidity}
        disabled={disabled}
        value={value}
        placeholder={disabled || value ? '' : placeholder}
        onFocus={this.open}
        onClick={this.open}
        onBlur={this.onBlur}
        readOnly
        customValidity={required ? this.validateRequired : customValidity}
      >
        <span className="bg-color" style={{ background: value }} onMouseDown={this.toggle} />
        <span className="toggle-icon" onMouseDown={this.toggle} />
      </PureInput>
    );
  }

  render() {
    const { disabled, alignWidth, value, colorData } = this.props;
    const { active, cursorIndex } = this.state;
    const lastIndex = colorData.length - 1;
    const menu = active ? (
      <div className={menuClass} ref={this.refTableWrap}>
        <div className="color-box">
          {colorData.map((item, index) => {
            const checked = value === item.background;
            const highlight = index === cursorIndex || (cursorIndex > lastIndex && index === lastIndex);
            return (
              <span
                className={classnames({ checked, highlight })}
                style={item}
                key={index}
                onMouseOver={() => this.setCursor(index)}
                onMouseDown={() => this.onSelect(item)}
              />
            );
          })}
        </div>
      </div>
    ) : null;
    return (
      <Dropdown
        ref={this.refDrop}
        menu={menu}
        disabled={disabled}
        onClose={this.onClose}
        onOpen={this.onOpen}
        alignWidth={alignWidth}
      >
        {this.renderToggleContent()}
      </Dropdown>
    );
  }
}
