import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Select, Tips, Icon } from 'components';
import {
  typeIs,
  stringEq,
  mergeObjectToArrayNonExistent,
  createTip,
  formatDeppProptotype,
  shallowCompareIgnoreFunc,
  fetish as fetch,
} from 'utils';
import { ERROR, SELECT_ALL_KEY, EMPTY_OPTION_DISPLAY } from 'constants';
import _ from 'lodash';
import './index.scss';

const historyIconType = { 'icon-arrow-down': 1, 'icon-arrow-sdown': 1 };
export default class SelectDrop extends Component {
  static propTypes = {
    fieldName: PropTypes.string,
    className: PropTypes.string,
    clear: PropTypes.bool,
    menuClassName: PropTypes.string,
    data: PropTypes.array,
    style: PropTypes.object,
    isMultiple: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    isFocus: PropTypes.bool,
    readOnly: PropTypes.bool, // 同disabled
    selectOnly: PropTypes.bool, // 只可点选，不可输入
    onChange: PropTypes.func,
    inputIconType: PropTypes.string,
    inputIconTips: PropTypes.string,
    uniqueKey: PropTypes.string,
    showKey: PropTypes.string,
    selectFirstLine: PropTypes.bool, // 是否默认选中第一条
    handleSelected: PropTypes.func, // 选中后的回调
    defaultSelected: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
    isShowTips: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    handleKeyDown: PropTypes.func,
    handleClick: PropTypes.func,
    handleIconClick: PropTypes.func,
    handleSearchChange: PropTypes.func,
    handleSearchClick: PropTypes.func,
    placeholder: PropTypes.string,
    tableHeader: PropTypes.object, // 显示的字段
    showHeader: PropTypes.bool, // tableHeader使用来做显示还是用来做显示数据的过滤
    fetchApi: PropTypes.object,
    initFilterKey: PropTypes.string, // 对初试数据data、更新后的数据data 进行数据过滤(共用filKey);
    valiType: PropTypes.string, // 字段校验样式类型
    filterKey: PropTypes.string,
    dataPath: PropTypes.string, // 光标路径
    formKey: PropTypes.oneOfType([
      // 结果字段  CARDFORM_FORMKEY_DEFAULT_ALL -> 全部字段
      PropTypes.array,
      PropTypes.string,
    ]),
    blurCheck: PropTypes.bool,
    checkTips: PropTypes.string,
    minFetchLen: PropTypes.number,
    // isNotScanSug: PropTypes.bool, // scansug标志 触发
    initFetchApi: PropTypes.bool, // fetch模式 初始请求api
    watchInitFetchApi: PropTypes.bool, // 每次变更数据是否再次请求枚举接口
    noSEmptyVal: PropTypes.any, // 不从下拉选择 直接输入时 uniqueKey 保存值 空或者 key名
    noOCTS: PropTypes.bool, // 无外部onchange时 修改输入框值触发handleselected noOnChangeTriggerSelected
    selectedAllKey: PropTypes.string, // 全部选项 key值
    selectedAllIn1: PropTypes.bool, // 全选时只传一个全部的key值
    selectedAllIn1Show: PropTypes.bool, // 全选时只显示一个全部项
    onInputFocus: PropTypes.func, // input focus 的回调
    formatUnmatched: PropTypes.bool, // 为匹配的key 是否格式化
    onInputKeyDown: PropTypes.func, // input 的keydown 回调,
    iconTrigInputClick: PropTypes.bool,
    map: PropTypes.bool,
    silent: PropTypes.bool,
    notFilterOnOpenWhenHasData: PropTypes.bool,
    showSelectAll: PropTypes.bool,
    multiLine: PropTypes.bool,
    timeInterval: PropTypes.any,
    enableTrim: PropTypes.bool, // fetchApi不为空是使用，发请求前对keyword进行trim
    tips: PropTypes.any, // tips
    tipsclassname: PropTypes.any, // tips 样式
    tipsDir: PropTypes.string, // tips 展开方向
    tipsType: PropTypes.string,
    pattern: PropTypes.string,
    showInputTitleTips: PropTypes.any,
    selectedOnlyKey: PropTypes.bool, // 只返回选中的key
    allowClear: PropTypes.bool, // 能否删除
    selectedOnlyKeyStructure: PropTypes.bool, // 只返会选中的key，但是会保留原先的结构
    matchKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]), // 前端模糊匹配 的key 数组。（当没有fetchApi 时， 前端过滤使用）
    valueOfEmpty: PropTypes.any,
    // 输入最大长度
    maxLength: PropTypes.number,
    optionDisabled: PropTypes.func,
    inputWrapStyle: PropTypes.object,
    fetchOnFocus: PropTypes.bool,
    customValidity: PropTypes.func,
  };
  static defaultProps = {
    data: [],
    disabled: false,
    showHeader: true,
    fieldName: '',
    selectFirstLine: true,
    formKey: 'key',
    showKey: 'name',
    uniqueKey: 'key',
    blurCheck: false, // 离开时是否校验
    checkTips: '请从下拉中选择数据',
    isShowTips: false,
    // isNotScanSug: true, // 默认不开启扫码
    initFetchApi: false,
    watchInitFetchApi: false,
    noSEmptyVal: false, // 不从下拉选择 直接输入时 uniqueKey 保存值  when not Selected what to give to uniqueKey
    noOCTS: false, // 无外部onchange 触发handleselected
    selectedAllKey: SELECT_ALL_KEY, // 全部选项 key值
    selectedAllIn1: false,
    placeholder: '',
    allowClear: true,
    selectedAllIn1Show: true,
    enableTrim: false,
    fetchOnFocus: false,
  };

  constructor(prop) {
    super(prop);
    let { defaultSelected, filterKey } = prop;
    const {
      data,
      uniqueKey,
      isMultiple,
      formatUnmatched,
      tableHeader,
      tableHeaderTip,
      tableEmptyTip,
      initFetchApi,
      fetchApi,
    } = prop;
    if (!typeIs(filterKey, 'string') || !filterKey || filterKey === 'null') filterKey = uniqueKey;
    defaultSelected = this.formatDefaultVal(defaultSelected, data, filterKey, isMultiple, formatUnmatched);
    this.state = {
      data: this.parseData(data || [], prop, !fetchApi || (data && data.length)),
      defaultSelected,
      header: Array.isArray(tableHeader) ? tableHeader : this.parseHeader(tableHeader),
      // eslint-disable-next-line react/no-unused-state
      headerTip: tableHeaderTip,
      emptyTip: tableEmptyTip,
    };
    if (initFetchApi) {
      this.filter('', { fetch }, true);
    }
  }

  componentDidMount() {
    if (this.props.isFocus) this.select.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { defaultSelected, data, filterKey, uniqueKey, isMultiple, formatUnmatched, tableHeader, watchInitFetchApi } =
      nextProps;
    const realFilterKey = !typeIs(filterKey, 'string') || !filterKey || filterKey === 'null' ? uniqueKey : filterKey;
    if (this.props.defaultSelected !== defaultSelected) {
      this.setState(
        {
          defaultSelected: this.formatDefaultVal(defaultSelected, data, realFilterKey, isMultiple, formatUnmatched),
        },
        () => {
          if (watchInitFetchApi) {
            this.filter('', { fetch }, true);
          }
        },
      );
    }
    if (!(this.props.fetchApi && defaultSelected) && !_.isEqual(this.props.data, data)) {
      this.setState({ data: this.parseData(data || [], nextProps, true) });
    }
    if (this.props.allowClear !== nextProps.allowClear && this.select) {
      this.select.listenDel = nextProps.allowClear;
    }
    if (this.props.tableHeader !== tableHeader) {
      this.setState({ header: Array.isArray(tableHeader) ? tableHeader : this.parseHeader(tableHeader, nextProps) });
    }
  }

  shouldComponentUpdate = shallowCompareIgnoreFunc;
  focus = () => this.select.focus();
  parseHeader = (tableHeader, props = this.props) => {
    const { showHeader, showKey } = props;
    const tableHeaderArr =
      tableHeader &&
      Object.entries(tableHeader).map(([key, val]) => {
        if (typeof val === 'object' && showHeader) {
          return { key, ...val };
        }
        return showHeader ? { key, title: val } : key;
      });
    const header = tableHeaderArr && tableHeaderArr.length ? tableHeaderArr : [showKey];
    this.state && this.setState({ header });
    return header;
  };
  parseData = (data, { selectedAllKey, initFilterKey, uniqueKey, showKey, valueOfEmpty }, addEmptyItem) => {
    const realData = (data || []).filter(
      item =>
        item &&
        (!initFilterKey || item[initFilterKey] === undefined || item[initFilterKey]) &&
        item[uniqueKey] !== selectedAllKey,
    );
    addEmptyItem &&
      valueOfEmpty !== undefined &&
      realData.unshift({ [uniqueKey]: valueOfEmpty, [showKey]: EMPTY_OPTION_DISPLAY });
    return realData;
  };
  formatDefaultVal = (oriDefaultSelected, data = [], key, isMulti = false, formatUnmatched = true) => {
    let defaultSelected = oriDefaultSelected;
    const { showKey } = this.props;
    const vType = typeIs(defaultSelected);
    if (!typeIs(data, 'array')) data = []; // eslint-disable-line
    if (this.state && typeIs(this.state.defaultSelected, 'object') && this.state.defaultSelected[showKey]) {
      data = [
        ...data,
        ...(data.some(d => stringEq(d[key], this.state.defaultSelected[key])) ? [] : [this.state.defaultSelected]),
      ]; // eslint-disable-line
    }
    if (this.state && typeIs(this.state.defaultSelected, 'array')) {
      data = [
        ...data,
        ...this.state.defaultSelected.filter(
          item => typeIs(item, 'object') && item[showKey] && !data.some(d => stringEq(d[key], item[key])),
        ),
      ]; // eslint-disable-line
    }
    if (vType === 'string' || vType === 'number') {
      if (defaultSelected === this.props.selectedAllKey) {
        defaultSelected = [defaultSelected];
      } else if (defaultSelected && data.length) {
        defaultSelected = data.filter(item => stringEq(item[key], defaultSelected)) || [];
        // eslint-disable-next-line no-nested-ternary
        defaultSelected = defaultSelected.length
          ? defaultSelected
          : formatUnmatched
          ? [{ [key]: oriDefaultSelected.toString(), [showKey]: oriDefaultSelected.toString() }]
          : [];
      } else if (defaultSelected) {
        defaultSelected = [{ [key]: defaultSelected.toString() }];
      } else {
        defaultSelected = [];
      }
    } else if (vType === 'array' && defaultSelected.length > 0) {
      const ivType = typeIs(defaultSelected[0]);
      if (ivType === 'string' || ivType === 'number') {
        if (defaultSelected[0] === this.props.selectedAllKey) {
          return defaultSelected;
        } else if (defaultSelected && data.length) {
          const matchedDataItem = {};
          data.every(item => {
            let tmpKey;
            if (isMulti) {
              defaultSelected.some(ditem => {
                if (ditem === undefined || ditem === null) {
                  return false;
                }
                if (stringEq(item[key], ditem.toString())) {
                  tmpKey = item[key];
                  return true;
                }
                return false;
              });
              tmpKey && (matchedDataItem[tmpKey] = item);
              return true;
            }
            if (stringEq(item[key], defaultSelected[0].toString())) {
              tmpKey = item[key];
              tmpKey && (matchedDataItem[tmpKey] = item);
              return false;
            }
            return true;
          });
          const defaultSelected2 = [];
          defaultSelected.forEach(item => {
            item !== undefined &&
              item !== null &&
              defaultSelected2.push(matchedDataItem[item] ? matchedDataItem[item] : item);
          });
          defaultSelected = defaultSelected2;
          // eslint-disable-next-line no-nested-ternary
          defaultSelected = defaultSelected.length
            ? defaultSelected
            : formatUnmatched
            ? [{ [key]: oriDefaultSelected.toString(), [showKey]: oriDefaultSelected.toString() }]
            : [];
        } else if (defaultSelected) {
          if (isMulti) {
            defaultSelected = defaultSelected.map(item => ({ [key]: item }));
          } else {
            defaultSelected = [{ [key]: defaultSelected.toString() }];
          }
        } else {
          defaultSelected = [];
        }
      }
    }
    return defaultSelected;
  };
  filter = (keyWord, select, isInitFetch) => {
    let kw = keyWord;
    const { fetchApi, matchKeys, data: dataSource, fetchOnFocus, showKey } = this.props;
    if (!fetchApi && matchKeys && dataSource.length) {
      return dataSource.filter(item => matchKeys.some(k => item[k] && item[k].includes(kw)));
    }

    // 某些场景 需要获取焦点时请求数据，之后前端过滤
    if (fetchOnFocus) {
      if (!this.need_fetch) {
        const keys = matchKeys && matchKeys.length ? matchKeys : [showKey];
        return (this.state.data || []).filter(item => keys.some(k => item[k] && item[k].includes(kw)));
      }
      kw = '';
      this.need_fetch = false;
    }

    if (!fetchApi) return;
    const keyword = kw;
    const req = { ...fetchApi.para };
    const conf = { method: 'POST', body: { req } };
    if (fetchApi.queryKey) {
      const query = req[fetchApi.queryKey] || {};
      fetchApi.key && (query[fetchApi.key] = keyword) && (req[fetchApi.queryKey] = query);
    } else if (fetchApi.key) {
      req[fetchApi.key] = keyword;
    }
    if (fetchApi.paraCallback) {
      const paraRes = fetchApi.paraCallback(req, fetchApi.key, keyword, select);
      if (!paraRes) return;
      conf.body.req = paraRes;
    }
    if (isInitFetch) {
      const { defaultSelected } = this.state;
      const { uniqueKey } = this.props;
      let ids = [];
      if (typeIs(defaultSelected, 'object')) {
        ids[0] = defaultSelected[uniqueKey];
      } else if (typeIs(defaultSelected, 'array')) {
        ids = defaultSelected.map(item => (typeIs(item, 'object') ? item[uniqueKey] : item));
      } else if (defaultSelected) {
        ids[0] = defaultSelected;
      }
      conf.body.req.ids = ids;
    }
    if (this.props.minFetchLen && keyword.length < +this.props.minFetchLen) return;
    select.fetch(fetchApi.url, conf).then(res => {
      let data = res;
      let { header } = this.state;
      if (typeIs(fetchApi.formateFunc, 'function')) {
        const resData = fetchApi.formateFunc(res, fetchApi);
        data = resData.reqData;
        resData.tableHeader && (header = this.parseHeader(resData.tableHeader));
      } else if (fetchApi.data_key) data = formatDeppProptotype(fetchApi.data_key, res, ',');
      else {
        data = res.res;
      }
      this.setState({ data: this.parseData(data, this.props, true), header });
      if (isInitFetch) {
        let { defaultSelected, filterKey } = this.props;
        const { uniqueKey, isMultiple, formatUnmatched } = this.props;
        if (!typeIs(filterKey, 'string') || !filterKey || filterKey === 'null') filterKey = uniqueKey;
        defaultSelected = this.formatDefaultVal(defaultSelected, data, filterKey, isMultiple, formatUnmatched);
        this.setState({ defaultSelected });
      }
    });
  };
  filterWrap = (keyword, select, isInitFetch) => {
    if (this.props.timeInterval) {
      let newKeyWord = keyword;
      this.props.enableTrim && (newKeyWord = newKeyWord.trim());
      if (this.keyword === newKeyWord) {
        return;
      }
      this.keyword = newKeyWord;
      return this.filter(this.keyword, select, isInitFetch);
    }
    return this.filter(keyword, select, isInitFetch);
  };
  // Public
  getValue = () => this.state.defaultSelected;
  handleResetState = resetData => {
    this.setState({ defaultSelected: [] });
    resetData && this.setState({ data: [] });
  };
  // isScanCheck = (e) => {
  //   let value = e.target.value || ''
  //   let res = false
  //   // if (!this.props.isNotScanSug && value.length < 5) {
  //   if (!this.props.isNotScanSug) {
  //     if (this.props.onChange) res = this.props.onChange(value, true)
  //     return res
  //   }
  //   return false
  // }
  // 下拉获取焦点时展开
  handleInputFocus = e => {
    if (this.props.fetchOnFocus) {
      this.need_fetch = true;
    }
    this.props.onFocus && this.props.onFocus(e);
    this.props.onInputFocus && this.props.onInputFocus(e);
  };
  handleSelect = (value, propsHandle = true) => {
    const { selectedAllIn1, selectedAllKey, uniqueKey, selectedOnlyKey, selectedOnlyKeyStructure, isMultiple } =
      this.props;
    let realValue = value;
    if (value && selectedAllIn1 && selectedAllKey === value[0]) realValue = [{ [uniqueKey]: value[0] }];
    if (value === '' || value === undefined) realValue = [];
    this.setState({ defaultSelected: realValue });
    // let data = this.mergeSelectToData(this.state.data, realValue, uniqueKey, this.props.showKey)
    // this.setState({ defaultSelected: realValue, data })
    if (!isMultiple && typeIs(realValue, 'array')) {
      realValue = realValue[0] || '';
    }
    const val = v => (v[uniqueKey] !== undefined ? v[uniqueKey] : v);
    // selectedOnlyKey 只返回选中的key
    if (selectedOnlyKey && realValue) {
      // eslint-disable-next-line no-nested-ternary
      realValue = isMultiple ? realValue.map(val) : realValue[0] ? realValue[0][uniqueKey] : realValue[uniqueKey];
    } else if (selectedOnlyKeyStructure && realValue) {
      // selectedOnlyKeyStructure,保留结构
      realValue = typeIs(realValue, 'array')
        ? realValue.map(v => ({ [uniqueKey]: v[uniqueKey] }))
        : { [uniqueKey]: realValue[uniqueKey] };
    }
    propsHandle && this.props.handleSelected && this.props.handleSelected(realValue, this.props.fieldName, this);
    return value;
  };
  /**
   * handleBlur处理的一件事情, 令下面的ul隐藏
   *
   * 单选的时候, 直接隐藏就可以了, 做延迟是为了和多选保持动画一致
   *
   * 而多选的时候, 则需要判断是不是点击li, 如果是点击了li 则不隐藏, 所以有了参数isSelectClick来判断不是点击的li
   * 因为在点击li的时候, 发现它们触发事件的顺序, li.onclick永远a都比input.onblur慢, 所以就做了延迟
   *
   */
  handleBlur = e => {
    if (this.props.fetchOnFocus) {
      this.need_fetch = false;
    }
    let newValue = this.state.defaultSelected;
    if (!this.props.isMultiple) {
      const { isSelect } = e.target.dataset;
      const isInput = e.target.dataset.userInput;
      newValue = newValue === 0 || (newValue && !Array.isArray(newValue)) ? [newValue] : newValue || [];
      if (this.select) {
        if (
          this.props.blurCheck &&
          !isSelect &&
          isInput &&
          (newValue.length === 0 ||
            `${newValue[0][this.props.uniqueKey]}` === `${this.props.noSEmptyVal ? this.props.noSEmptyVal : ''}` ||
            newValue[0][this.props.uniqueKey] === undefined)
        ) {
          createTip(this.props.checkTips, ERROR, this.select.focus()).show();
          // cardForm中 blurcheck后 保证清空的值会回传到cardForm 需要有对应外传的事件
          // 无onBlur 则需要通过handleOpationclick 外传
          this.handleSelect('', !this.props.onBlur || false);
          this.props.onBlur && this.props.onBlur('', this.props.fieldName, this);
          return;
        }
        if (!isSelect && isInput) newValue = { [this.props.showKey]: isInput };
        this.props.onBlur && this.props.onBlur(newValue, this.props.fieldName, this);
      }
      return;
    }
    this.props.onBlur && this.props.onBlur(newValue, this.props.fieldName, this);
  };
  // inputItem === false 时 删去selectValues里的 空uniqueKey 项
  mergeToSelected = (selectValues, inputItem, uniqueKey, addOnly = true) =>
    inputItem === false
      ? selectValues.filter(item => item[uniqueKey])
      : mergeObjectToArrayNonExistent(selectValues, inputItem, uniqueKey, addOnly) || [];
  handleChange = e => {
    // let isScan = this.isScanCheck(e)
    const inputValue = e.target.value.trim();
    if (!this.props.isMultiple) {
      // 单选inputValue 为空时 即为清除选中值，
      // 没有onChange时需要 通过handleSelected 从外部改变props的选中值 // TODO fetchApi 时 若无onchang会有问题
      const inputItem = inputValue
        ? [
            {
              [this.props.showKey]: inputValue,
              [this.props.uniqueKey]:
                // eslint-disable-next-line no-nested-ternary
                this.props.blurCheck || this.props.noSEmptyVal
                  ? this.props.noSEmptyVal
                    ? this.props.noSEmptyVal
                    : ''
                  : inputValue,
            },
          ]
        : [];
      if (this.props.onChange) {
        this.props.onChange(inputValue, this.props.fieldName, e, inputItem);
      } else {
        this.handleSelect(inputItem, !inputValue || this.props.noOCTS);
      }
    } else {
      const inputItem = inputValue ? { [this.props.showKey]: inputValue, [this.props.uniqueKey]: '' } : false;
      const selectValues = this.mergeToSelected(this.state.defaultSelected, inputItem, this.props.uniqueKey, true);
      // let selectValues = mergeObjectToArrayNonExistent(this.state.selectValues, inputItem, this.props.uniqueKey, true) || []
      if (this.props.onChange) {
        this.handleSelect(selectValues, false);
        // 多选的onchange 使用第二个参数 回传选中值
        // 历史原因 此处传值 甚是混乱
        this.props.onChange(inputValue, selectValues);
      } else {
        this.handleSelect(selectValues, !inputValue || this.props.noOCTS);
      }
    }
  };
  handleInputIconClick = (e, ...rest) => {
    // 不是所有的图标都需要触发input的click动作
    // disabled 不响应icon的点击事件
    const { disabled, handleIconClick } = this.props;
    if (disabled || e.target.hasAttribute('disabled') || e.target.className.includes('disabled')) {
      e.stopPropagation && e.stopPropagation();
      return false;
    }
    handleIconClick && handleIconClick(this.state.defaultSelected, ...rest);
  };
  refSelect = ref => {
    if (!this.props.allowClear && ref) ref.listenDel = false; // eslint-disable-line
    this.select = ref;
  };

  render() {
    const {
      isMultiple,
      inputIconType,
      menuClassName,
      silent,
      readOnly,
      disabled,
      selectOnly,
      showKey,
      uniqueKey,
      fetchApi,
      valiType,
      selectFirstLine,
      dataPath,
      className,
      placeholder,
      selectedAllIn1,
      selectedAllKey,
      selectedAllIn1Show,
      notFilterOnOpenWhenHasData,
      onInputKeyDown,
      showSelectAll,
      multiLine,
      required,
      style,
      pattern,
      tips,
      tipsclassname,
      tipsDir,
      tipsType,
      matchKeys,
      inputIconTips,
      maxLength,
      optionDisabled,
      inputWrapStyle,
      customValidity,
      after,
      onAddIconClick,
      onRemoveOption,
      optionRemovable,
      clear,
    } = this.props;

    const { header, defaultSelected, data, headerTip, emptyTip } = this.state;
    const isSelectAll =
      selectedAllIn1 &&
      isMultiple &&
      (selectedAllKey === defaultSelected ||
        (Array.isArray(defaultSelected) && defaultSelected[0] && selectedAllKey === defaultSelected[0][uniqueKey]));
    let value = !isMultiple && Array.isArray(defaultSelected) ? defaultSelected[0] : defaultSelected;
    value = isSelectAll ? [selectedAllKey] : value;
    // eslint-disable-next-line no-nested-ternary
    const filter = fetchApi || matchKeys ? this.filterWrap : readOnly ? false : showKey;
    const classNames = classnames({ [`fn-field--${valiType}`]: valiType, [className]: className });
    const customIcon = inputIconType && !historyIconType[inputIconType];
    let content = (
      <Select
        ref={this.refSelect}
        style={style}
        className={classNames}
        clear={clear}
        menuClassName={menuClassName}
        data-path={dataPath}
        multiple={isMultiple}
        showSelectAll={showSelectAll}
        multiLine={multiLine}
        compare={uniqueKey}
        format={showKey}
        header={header}
        headerTip={headerTip}
        emptyTip={emptyTip}
        onKeyDown={onInputKeyDown}
        autoActiveFirstOption={selectFirstLine}
        notFilterOnOpenWhenHasData={notFilterOnOpenWhenHasData}
        collapseAll={!!selectedAllIn1Show && (selectedAllIn1 || !fetchApi)}
        map={false}
        silent={silent}
        disabled={disabled}
        showIcon={inputIconType !== '' && !customIcon}
        placeholder={placeholder}
        filter={filter}
        highlight={showKey}
        value={value}
        valueOfAll={selectedAllIn1 ? selectedAllKey : undefined}
        data={data}
        onChange={this.handleSelect}
        onInput={!selectOnly ? this.handleChange : undefined}
        onFocus={this.handleInputFocus}
        onBlur={this.handleBlur}
        required={required}
        pattern={pattern}
        recordScan={this.props.recordScan}
        showInputTitleTips={this.props.showInputTitleTips}
        maxLength={maxLength}
        optionDisabled={optionDisabled}
        inputWrapStyle={inputWrapStyle}
        customValidity={customValidity}
        after={after}
        onAddIconClick={onAddIconClick}
        onRemoveOption={onRemoveOption}
        optionRemovable={optionRemovable}
      >
        {customIcon && (
          <Icon
            classname="input-icon"
            iconType={inputIconType}
            tips={inputIconTips}
            onClick={this.handleInputIconClick}
          />
        )}
      </Select>
    );
    if (tips !== undefined && tips !== '') {
      content = (
        <Tips title={tips} className={tipsclassname} dir={tipsDir} type={tipsType}>
          {content}
        </Tips>
      );
    }
    return content;
  }
}
