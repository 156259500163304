/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { ModalDialog, Button } from 'components';
import { CHECK, ERROR, INFO } from 'constants';
import { confirm, post, showInfo, genImageURL, genMediaURL } from 'utils';
import { prefixCls } from './index.scss';
import _ from 'lodash';

export default class ImageViewer extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    src: PropTypes.string,
    showDeleteButton: PropTypes.bool,
    showDownloadButton: PropTypes.bool,
    deleteReq: PropTypes.object,
    close: PropTypes.func,
    onDelete: PropTypes.func,
    type: PropTypes.string,
    name: PropTypes.string,
    path: PropTypes.string,
    download: PropTypes.string,
    extraDel: PropTypes.func,
  };

  static defaultProps = {
    title: '图片预览',
    viewType: 'img',
  };

  state = { rotate: 0, index: 0 };

  UNSAFE_componentWillMount() {
    let index = 0;
    if (this.props.img_list && this.props.img_list.length > 1) {
      this.props.img_list.map((item, i) => {
        if (this.props.name === item.name) {
          index = i;
        }
        return null;
      });
      this.setState({
        index,
      });
    }
  }
  scale = shrink => {
    const scale = this.state.scale || 1;
    if (shrink) {
      scale > 0.1 && this.setState({ scale: scale - 0.1 });
    } else {
      this.setState({ scale: scale + 0.1 });
    }
  };

  rotate = () => {
    const rotate = this.state.rotate || 0;
    this.setState({ rotate: rotate === -270 ? 0 : rotate - 90 });
  };

  deleteImage = async () => {
    if (!(await confirm(INFO, '您确定要删除照片吗？'))) return false;
    this.setState({ loading: true });
    if (this.props.extraDel) {
      await this.props.extraDel(this.props.deleteReq, this);
      this && this.setState({ loading: false });
      return;
    }
    const res = await post('Order/Order/deletePicture', this.props.deleteReq);
    this.setState({ loading: false });
    if (res.errno !== 0) {
      res.msg && showInfo(ERROR, res.msg);
      return;
    }
    this.modal.handleHide();
    showInfo(CHECK, '删除成功！');
    if (this.props.onDelete) setTimeout(() => this.props.onDelete(res), 1000);
  };
  // 获取视频地址
  getOssVideo = () => {
    return genMediaURL(_.pick(this.props, ['ossMediaKey', 'ossMediaPath', 'path', 'type']));
  };
  getSrc() {
    const { index } = this.state;
    if (this.props.src) return this.props.src;
    return genImageURL(this.props.img_list && this.props.img_list.length > 1 ? this.props.img_list[index] : this.props);
  }

  onImageLoad = e => {
    const { width, height } = e.target;
    this.setState({ width, height });
  };

  leftImg = () => {
    const { length } = this.props.img_list;
    let currentIndex = this.state.index;
    if (this.state.index === 0) {
      currentIndex = length - 1;
    } else {
      currentIndex -= 1;
    }
    this.setState({
      index: currentIndex,
    });
    return genImageURL(
      this.props.img_list && this.props.img_list.length > 1 ? this.props.img_list[currentIndex] : this.props,
    );
  };

  rightImg = () => {
    const { length } = this.props.img_list;
    let currentIndex = this.state.index;
    if (this.state.index === length - 1) {
      currentIndex = 0;
    } else {
      currentIndex += 1;
    }
    this.setState({
      index: currentIndex,
    });
    return genImageURL(
      this.props.img_list && this.props.img_list.length > 1 ? this.props.img_list[currentIndex] : this.props,
    );
  };
  render() {
    const { showDeleteButton, showDownloadButton, close, title, download, viewType } = this.props;
    const { scale, rotate, loading, width, height, index } = this.state;
    const viewTitle = viewType === 'img' ? title : '视频播放';
    const style = { zoom: scale, transform: `${rotate ? `rotate(${rotate}deg)` : ''}` };
    const src = this.getSrc();
    const videoSrc = this.getOssVideo();
    const content = (
      <Fragment>
        {this.props.img_list && this.props.img_list.length > 1 ? (
          this.state.index === 0 ? (
            <div className="fn-icon fn-icon-arrow-l disabled gray" />
          ) : (
            <div className="fn-icon fn-icon-arrow-l" onClick={this.leftImg} />
          )
        ) : null}

        <img alt="图片预览" src={src} />
        <div className={`${prefixCls}__wrap`}>
          <img alt="图片预览" style={style} src={src} onLoad={this.onImageLoad} data-index={index} />
        </div>
        {this.props.img_list && this.props.img_list.length > 1 ? (
          this.state.index === this.props.img_list.length - 1 ? (
            <div className="fn-icon fn-icon-arrow-r  disabled gray" />
          ) : (
            <div className="fn-icon fn-icon-arrow-r" onClick={this.rightImg} />
          )
        ) : null}
      </Fragment>
    );
    const contentVideo = (
      <Fragment>
        {this.props.img_list && this.props.img_list.length > 1 ? (
          this.state.index === 0 ? (
            <div className="fn-icon fn-icon-arrow-l disabled gray" />
          ) : (
            <div className="fn-icon fn-icon-arrow-l" onClick={this.leftImg} />
          )
        ) : null}
        <video className="videoView" alt="视频预览" src={videoSrc} controls onLoad={this.onImageLoad} />
        {this.props.img_list && this.props.img_list.length > 1 ? (
          this.state.index === this.props.img_list.length - 1 ? (
            <div className="fn-icon fn-icon-arrow-r  disabled gray" />
          ) : (
            <div className="fn-icon fn-icon-arrow-r" onClick={this.rightImg} />
          )
        ) : null}
      </Fragment>
    );
    const bottom = (
      <Fragment>
        <span className={`${prefixCls}__toolbar`}>
          <i className="fn-icon-il fn-icon-xuanzhuan" onClick={this.rotate} />
          <i className="fn-icon-il fn-icon-fangda" onClick={() => this.scale()} />
          <i className="fn-icon-il fn-icon-suoxiao" onClick={() => this.scale(true)} />
        </span>
        {showDownloadButton && (
          <Button type="primary">
            <a href={src} download={download}>
              下载图片
            </a>
          </Button>
        )}
        {showDeleteButton && (
          <Button type="primary" onClick={this.deleteImage} loading={loading}>
            删除照片
          </Button>
        )}
        <Button onClick={() => this.modal.handleHide()}>关闭</Button>
      </Fragment>
    );
    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        classname={prefixCls}
        content={viewType === 'img' ? content : contentVideo}
        bottom={bottom}
        showMax
        isShow
        isModal
        maskClosable
        close={close}
        title={viewTitle}
      />
    );
  }
}
