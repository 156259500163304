import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import { PureInput, Icon, Select, FormulaPicker } from 'components';
import { Button } from '@gui/web-react';
import { postJAVA } from 'utils';
import FeeSelect from './FeeSelect';
import { prefixCls } from './index.scss';
const feeTip = (
  <span>
    根据公式中具体数值进行加减乘除，当配置除时，
    <br />
    分子不存在或为0时，当前项不参与计算
  </span>
);
const operatorMap = { add: '+', minus: '-', except: '÷', ride: 'x' };
const defaultBase = { operator: 'add', ratio: 1 };

export default class Formula extends PureComponent {
  static propTypes = {
    value: PropTypes.array,
    leftValue: PropTypes.bool,
    leftData: PropTypes.array,
    leftPlaceholder: PropTypes.string,
    baseLabel: PropTypes.string,
    inputBase: PropTypes.string, // 费用项不是下拉而是输入框
    onChange: PropTypes.func,
    data: PropTypes.array,
    placeholder: PropTypes.string,
    separator: PropTypes.string,
    className: PropTypes.string,
    units: PropTypes.array, // 单位： { key, name, type }
    disabled: PropTypes.bool,
    style: PropTypes.object,
    tip: PropTypes.bool,
    getData: PropTypes.func,
    separatorList: PropTypes.array,
    formulaPickerProps: PropTypes.object,
  };

  static defaultProps = {
    units: [],
    separatorList: ['+', '-'], // 所有合集，支持+-*/
    multiple: true,
    formulaPickerProps: {
      visible: false,
      validateUrl: '/priceSet/operatePriceSave',
    },
  };

  static feeTip = feeTip;

  constructor(props) {
    super(props);
    this.dict = {};
    props.data &&
      props.data.forEach(item => {
        this.dict[item.key] = item;
      });
  }

  set(key, val, index) {
    const origin = this.getFormula();
    const value = [...origin];
    value[index] = { ...value[index], [key]: val };
    this.onChange(value);
  }

  toggleOperator(item, index) {
    const { operator } = item;

    if (['add', 'minus'].includes(operator)) {
      this.set('operator', operator === 'add' ? 'minus' : 'add', index);
    }

    if (['ride', 'except'].includes(operator)) {
      this.set('operator', item.operator === 'ride' ? 'except' : 'ride', index);
    }
  }
  add(operator) {
    if (this.props.inputBase) {
      const origin = this.props.value || defaultBase;
      this.onChange({ ...origin, operator, value: '' });
      return;
    }
    const origin = this.getFormula();
    const value = [...origin, { operator, ratio: 1, itemList: [] }];
    this.onChange(value);
  }

  remove(index) {
    const origin = this.getFormula();
    const value = [...origin];
    value.splice(index, 1);
    this.onChange(value);
  }

  getFormula() {
    const { value, leftValue } = this.props;
    return (leftValue ? (value || {}).formula : value) || [];
  }

  onChange = formula => {
    const value = this.props.leftValue ? { ...this.props.value, formula } : formula;
    this.props.onChange && this.props.onChange(value);
  };

  onChangeLeft = left => {
    this.props.onChange && this.props.onChange({ ...this.props.value, left });
  };

  setBase = (key, val) => {
    const origin = this.props.value || defaultBase;
    this.onChange({ ...origin, [key]: val });
  };
  getItemText = (itemList, formulaEnum) => {
    const obj2 = {};
    formulaEnum.forEach(item => {
      obj2[item.key] = item.name;
    });
    if (typeof itemList === 'object') {
      return itemList.reduce((prev, curr) => (prev ? `${prev}+${obj2[curr]}` : obj2[curr]), '');
    }
    return obj2[itemList];
  };
  handleSelected = (val, index, data) => {
    this.set('itemList', val, index);
  };
  renderInputBase() {
    const { disabled, value, placeholder, baseLabel } = this.props;
    const formula = value || defaultBase;
    return (
      <span className={`${prefixCls}__item`} disabled={disabled}>
        ({baseLabel}
        {(formula.value || formula.value === '') && (
          <Fragment>
            &nbsp;{operatorMap[formula.operator]}&nbsp;
            <PureInput
              className={`${prefixCls}__ratio`}
              placeholder={placeholder}
              value={formula.value}
              disabled={disabled}
              onChange={e => this.setBase('value', e.target.value)}
            >
              {formula.value}
            </PureInput>
          </Fragment>
        )}
        )<span> x </span>
        <PureInput
          className={`${prefixCls}__ratio`}
          value={formula.ratio}
          disabled={disabled}
          onChange={e => this.setBase('ratio', e.target.value)}
        >
          {formula.ratio}
        </PureInput>
        <span className={`${prefixCls}__toolbar`}>
          <i className="fn-icon fn-icon-error-o" onClick={() => this.setBase('value', 0)} />
        </span>
      </span>
    );
  }
  // 高级公式编辑完成后的回调
  handleFormulaPickerConfirm(data) {
    const value = data.formula;
    this.props?.onChange?.([{ advanced_formula_detail: value }]);
  }
  // 高级公式校验
  onValidate = async formula => {
    const { validateUrl, validateUrlParams } = this.props?.formulaPickerProps || {};
    const _url = validateUrl || '/cmm-pricesys/priceSet/validateFormula';
    try {
      const res = await postJAVA(_url, {
        formula,
        ...validateUrlParams,
      });
      if (!res || +res.errno !== 0) {
        return res?.errmsg ?? '远程校验失败';
      }
      return true;
    } catch (e) {
      return '远程校验失败';
    }
  };

  render() {
    const {
      className,
      disabled,
      data = [],
      value,
      style,
      placeholder,
      separator,
      leftValue,
      leftPlaceholder,
      leftData,
      inputBase,
      tip,
      getData,
      units,
      separatorList,
      multiple,
      formulaPickerProps,
    } = this.props;
    const formulaPickerValue = value?.[0]?.advanced_formula_detail || '';
    const { visible, variables, functions } = formulaPickerProps || {};
    const classes = classnames({
      [className]: className,
      [prefixCls]: true,
      [`${prefixCls}--disabled`]: disabled,
    });
    const formula = this.getFormula();
    const left = leftValue && (value || {}).left;
    return (
      <div className={classes} style={style}>
        {!formulaPickerValue && (
          <>
            {leftValue && (
              <span className={`${prefixCls}__item`} disabled={disabled}>
                <FeeSelect
                  placeholder={leftPlaceholder}
                  data={leftData}
                  disabled={disabled}
                  value={left}
                  onChange={this.onChangeLeft}
                />
                &nbsp;=&nbsp;
              </span>
            )}
            {inputBase && this.renderInputBase()}
            {!inputBase &&
              formula.map((item, index) => {
                // 只能匹配类型一致的单位 或者 无字段
                const _units = units.filter(u =>
                  item.itemList.every(k => this.dict[k] && this.dict[k].type === u.type),
                );
                if (item.unit && !_units.some(u => u.key === item.unit)) {
                  this.set('unit', '', index);
                }
                return (
                  <span key={index} className={`${prefixCls}__item`} disabled={disabled}>
                    {(index > 0 ||
                      item.operator === 'minus' ||
                      item.operator === 'ride' ||
                      item.operator === 'except') && (
                      <button
                        className={`${prefixCls}__operator ${item.operator}`}
                        onClick={() => !disabled && this.toggleOperator(item, index)}
                      >
                        &nbsp;{operatorMap[item.operator]}&nbsp;
                      </button>
                    )}
                    (
                    <FeeSelect
                      placeholder={placeholder}
                      separator={separator}
                      data={getData ? getData(index) : data}
                      disabled={disabled}
                      multiple={multiple}
                      value={item.itemList}
                      onChange={val => this.set('itemList', val, index)}
                    />
                    )<span> x </span>
                    <PureInput
                      className={`${prefixCls}__ratio`}
                      value={item.ratio}
                      disabled={disabled}
                      onChange={e => this.set('ratio', e.target.value, index)}
                    >
                      {item.ratio}
                    </PureInput>
                    {_units.length > 0 && (
                      <Select
                        className={`${prefixCls}__unit`}
                        data={_units}
                        value={item.unit}
                        disabled={disabled}
                        onChange={val => this.set('unit', val, index)}
                        showIcon={false}
                        placeholder="单位"
                      />
                    )}
                    <span className={`${prefixCls}__toolbar`}>
                      <i className="fn-icon fn-icon-error-o" onClick={() => this.remove(index)} />
                    </span>
                  </span>
                );
              })}
            <span>
              {separatorList.includes('+') ? (
                <button
                  className={`${prefixCls}__icon ${prefixCls}__add`}
                  disabled={disabled}
                  onClick={() => this.add('add')}
                >
                  +
                </button>
              ) : (
                ''
              )}
              {separatorList.includes('-') ? (
                <button
                  className={`${prefixCls}__icon ${prefixCls}__minus`}
                  disabled={disabled}
                  onClick={() => this.add('minus')}
                >
                  -
                </button>
              ) : (
                ''
              )}
              {separatorList.includes('x') ? (
                <button
                  className={`${prefixCls}__icon ${prefixCls}__ride`}
                  disabled={disabled}
                  onClick={() => this.add('ride')}
                >
                  x
                </button>
              ) : (
                ''
              )}
              {separatorList.includes('÷') ? (
                <button
                  className={`${prefixCls}__icon ${prefixCls}__except`}
                  disabled={disabled}
                  onClick={() => this.add('except')}
                >
                  ÷
                </button>
              ) : (
                ''
              )}
              {tip && <Icon iconType="icon-help" tips={tip} />}
            </span>
          </>
        )}
        {visible && (
          <FormulaPicker
            value={formulaPickerValue}
            onConfirm={(val, obj) => this.handleFormulaPickerConfirm(obj)}
            onValidate={this.onValidate}
            mergeBuiltInFuntions={false}
            showTrans={false}
            showFixed={false}
            showOutcome
            markFunction
            showOperator
            trigger={({ onClick, display }) => {
              return (
                <>
                  <span>{display}</span>
                  <Button type="primary" size="mini" onClick={onClick} style={{ marginLeft: 10 }}>
                    高级公式
                  </Button>
                </>
              );
            }}
            functions={functions}
            variables={variables}
          />
        )}
      </div>
    );
  }
}
