/**
 * tooltip.js
 * author: sumizu
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import { showTip } from 'utils';
import Popover from './popover';

export default class Tooltip extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    classname: PropTypes.string, // 为了兼容历史Tips组件的属性，勿用
    popoverClassName: PropTypes.string, // 单独传弹出层的class 不会再把className传过去，如遇bug请修改属性名
    background: PropTypes.string,
    border: PropTypes.string,
    color: PropTypes.string,
    content: PropTypes.any,
    title: PropTypes.any,
    renderContent: PropTypes.func,
    // position: PropTypes.oneOf(['top-left', 'top', 'top-right', 'left', 'right', 'bottom-left', 'bottom', 'bottom-right']),
    dir: PropTypes.oneOf(['up', 'down', 'left', 'right']), // 取缔position 最好不传，会默认自适应，如想强制某个方向时传
    type: PropTypes.oneOf(['warn', 'info', 'error']), // 不传为默认的黑色
    style: PropTypes.object,
    trigger: PropTypes.oneOf(['click', 'hover']),
    mouseEnterDelay: PropTypes.number,
    tipOnContent: PropTypes.bool, // tips位置以内容为准
    tipsStyle: PropTypes.object,
    asyncContent: PropTypes.func,
    onShowTips: PropTypes.func,
    isDom: PropTypes.bool,
    popupHoverStay: PropTypes.bool, // 鼠标移入弹出框的话，弹出框会保留而不销毁
  };
  static defaultProps = {
    position: 'top',
    trigger: 'hover',
    mouseEnterDelay: 50 / 1000,
    isDom: false,
    popupHoverStay: true, // 默认支持 鼠标可移入弹出框
  };

  constructor(props) {
    super(props);
    this.show = false;
    this.isPopupHover = false;
    this.isContainerHover = false;
  }

  componentWillUnmount() {
    this.onMouseLeave();
  }

  handleShow = async () => {
    const {
      renderContent,
      asyncContent,
      title,
      popoverClassName,
      tipsStyle,
      dir,
      background,
      border,
      color,
      type,
      trigger,
    } = this.props;
    let content;
    if (title) {
      content = this.props.isDom ? <div dangerouslySetInnerHTML={{ __html: title }} /> : title;
    } else {
      // eslint-disable-next-line no-nested-ternary
      content = asyncContent ? await asyncContent() : renderContent ? renderContent() : this.props.content;
    }
    if (!content || !this.show) return;
    // eslint-disable-next-line react/no-find-dom-node
    let el = findDOMNode(this);
    if (this.props.tipOnContent && el.children.length === 1) {
      el = el.children[0];
    }
    showTip(el, {
      triggerType: trigger,
      content,
      style: tipsStyle,
      className: popoverClassName,
      dir,
      background,
      border,
      color,
      type,
      onClose: this.onClose,
      onMouseEnter: () => {
        this.isPopupHover = true;
      },
      onMouseLeave: () => {
        this.isPopupHover = false;
        this.hidePopover();
      },
    });
    this.showing = true;
    this.props.onShowTips && this.props.onShowTips();
  };

  handleHide = () => {
    Popover.hide();
  };
  onClose = () => {
    clearTimeout(window.popoverTimer);
    this.showing = false;
    this.show = false;
  };
  onMouseEnter = () => {
    const { mouseEnterDelay } = this.props;
    this.show = true;
    this.isContainerHover = true; // 鼠标容器悬停
    if (mouseEnterDelay && mouseEnterDelay > 0) {
      window.popoverTimer && clearTimeout(window.popoverTimer);
      window.popoverTimer = setTimeout(() => this.handleShow(), mouseEnterDelay * 1000);
    } else {
      this.handleShow();
    }
  };
  onMouseLeave = () => {
    clearTimeout(window.popoverTimer);
    this.show = false;
    this.isContainerHover = false; // 鼠标移出容器
    this.hidePopover();
  };

  hidePopover = () => {
    this.hideTimer && clearTimeout(this.hideTimer);
    if (this.showing) {
      this.hideTimer = setTimeout(() => {
        if (this.isContainerHover) return; // 鼠标容器悬停
        if (this.isPopupHover && this.props.popupHoverStay) return; // 鼠标Popover悬停
        Popover.hide();
      }, 100); // 延迟 100ms 允许鼠标移动到 Popover 组件
    }
  };

  parseToDom = str => <div dangerouslySetInnerHTML={str} />;

  render() {
    const { children, className, classname, trigger, style } = this.props;
    const props = {};
    if (trigger === 'hover') {
      props.onMouseEnter = this.onMouseEnter;
      props.onMouseLeave = this.onMouseLeave;
    } else {
      props.onClick = this.onMouseEnter;
    }
    const newStyle = Object.assign({ display: 'inline-block' }, style);
    return (
      <span {...props} className={classname || className} style={newStyle}>
        {children}
      </span>
    );
  }
}
