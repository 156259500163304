exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".badge{position:relative}.badge .count{position:absolute;right:-11px;top:-5px;font-size:10px;width:12px;height:12px;border-radius:50%;text-align:center;line-height:12px;background:#f04134;color:#fff}", ""]);

// exports
exports.locals = {
	"prefixCls": "badge",
	"badge": "badge",
	"count": "count"
};