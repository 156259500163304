exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-menu-btn{display:inline-block;position:relative}.fn-menu-btn .fn-btn-icon:hover .fn-btn--default,.fn-menu-btn .fn-btn-icon:hover .fn-icon{color:#fff}.fn-menu-btn .fn-btn-icon--disabled:hover .fn-icon{color:#8dbcde}.fn-menu-btn__hide{opacity:0;display:none}.fn-menu-btn_table_operates{width:100%}.fn-menu-btn_table_operates .fn-btn{min-width:0;width:100%;border:none;box-shadow:none;background:none;height:24px;padding:0;line-height:24px;font-size:12px;font-weight:400;vertical-align:middle;text-decoration:none;white-space:nowrap;cursor:pointer}.fn-menu-btn_table_operates .fn-btn--default:focus .fn-icon,.fn-menu-btn_table_operates .fn-btn--default:hover .fn-icon{color:#0097cf}.fn-menu-btn_table_operates .fn-btn-icon{width:100%}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-menu-btn",
	"fn-menu-btn": "fn-menu-btn",
	"fn-btn-icon": "fn-btn-icon",
	"fn-btn--default": "fn-btn--default",
	"fn-icon": "fn-icon",
	"fn-btn-icon--disabled": "fn-btn-icon--disabled",
	"fn-menu-btn__hide": "fn-menu-btn__hide",
	"fn-menu-btn_table_operates": "fn-menu-btn_table_operates",
	"fn-btn": "fn-btn"
};