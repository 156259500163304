/**
 * Created by wangnaihe on 2017/8/30.
 */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import keycode from 'keycode';
import { PureInput } from 'components';

const funcKeyMap = { Meta: 'win', Alt: 'alt', Control: 'ctrl', Shift: 'shift' };
const funcKeys = {
  'left command': 'win',
  'right command': 'win',
  alt: 'alt',
  ctrl: 'ctrl',
  shift: 'shift',
  win: 'win',
};

const displayKeys = { 'left command': 'win', 'right command': 'win' };

export const displayMap = { f1: 'F1', f2: 'F2', f3: 'F3', f4: 'F4', f5: 'F5', f6: 'F6', f7: 'F7', f8: 'F8', f9: 'F9' };

export const format = val =>
  val && val.split
    ? val
        .split('+')
        .map(v => displayMap[v] || v)
        .join('+')
    : '';

export default class Shortcuts extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    value: '',
  };

  static format = format;

  onKeyDown = e => {
    e.preventDefault();
    const keys = [];
    const key = keycode(e.keyCode);
    e.altKey && keys.push(funcKeyMap.Alt);
    e.ctrlKey && keys.push(funcKeyMap.Control);
    e.metaKey && keys.push(funcKeyMap.Meta);
    e.shiftKey && keys.push(funcKeyMap.Shift);
    !funcKeys[key] && keys.push(displayKeys[key] || key);
    this.props.onChange && this.props.onChange(keys.join('+'));
  };

  render() {
    return <PureInput {...this.props} value={format(this.props.value)} readOnly onKeyDown={this.onKeyDown} />;
  }
}
