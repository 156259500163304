/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Table, Column, Cell, ColumnGroup } from 'fixed-data-table-2';
import {
  isEmptyObj,
  typeIs,
  range,
  getStrWidth,
  throttle,
  isset,
  getSplit,
  format2ApiQueryFilter,
  filterArrayPropByKey,
  filterListColumnByKeyList,
  tableDataCheck,
  addClass,
  delClass,
  formatDeppProptotype,
  setDeppProptotypes,
  getFilterType,
  getPageFontSize,
  getTableRowHeight,
} from 'utils';
import _ from 'lodash';
import { PopUp, Dimensions, ContextMenu, Input, Icon } from 'components';
import {
  TABLE_FOOTER_SELECTED_HEIGHT,
  TABLE_HEADER_HEIGHT_FILTERROW,
  TABLE_HEADER_HEIGHT,
  DATE_TYPE,
  SORTTYPES,
  SPECIAL_DEFAULT_WIDTH,
  TABLE_DEFAULT_WIDTH,
  MIN_HEADER_WIDTH,
  LIST_ROWCOUNT_UNIT,
  TABLE_FOOTER_NORMAL_HEIGHT,
  TABLE_HEADER_RIGHTMENU_SIZE,
  TABLE_HEADER_QUICKFILTER_SIZE,
  TABLE_ROW_RIGHTMENU_SIZE,
  SELECT_ALL_KEY,
  EMPTY_VAL_MAP,
  HEADER_TYPE_TO_EMPTY,
  BATCH_QUERY_TYPE,
  TABLE_HEADER_DEFAULT_PROPS,
  MULTI_LINE_HEIGHT,
  FILTER_DIFF_SHOW_KEY,
  EMPTY_VALS_ALL,
  NOT_EMPTY_VALS_ALL,
  HEADER_TYPE_TO_NOT_EMPTY,
  EMPTY_OPTION_DISPLAY,
  LISTSHOWSTORE_ADDROWS,
  COMPANY_SETTING,
  SPECIAL_HEADER_KEYS, // SUPPORT_DATETIME_FORMATS
  MULTI_TABLE_FAKE_SCROLLER_WIDTH,
} from 'constants';
// import 'fixed-data-table/dist/fixed-data-table-base.css'
// import 'fixed-data-table/dist/fixed-data-table-style.css'
import 'fixed-data-table/dist/fixed-data-table.css';

import classname from 'classnames';
import { isInOtherPropsArray, diffDate, feFieldsCmpFunc, feCmpFunc, TableContext } from './../utils';
import * as TableCell from '../FixedDataTableCell';
import {
  ListStore,
  formatValue,
  preLoadStore,
  getSumVal,
  dataFormater,
  QuickFilterDialog,
  getFormulaVal,
} from '../helper';
import { prefixCls } from './index.scss';
import StaticTable from './../StaticTable';
import { getRowStyleCls } from '../helper/cellColor';
import { eachRangeSelection } from '../../../../operation/listOperation/Salary/utils';
import MultiTable from './multiTable';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';
import dataAnalyticTrackMap from '@/constants/dataAnalyticTrackMap';
import ResizeComponent from './ResizeComponent';

!window.coyperStatus && (window.coyperStatus = {});
const strMatch = (isource = '', ifilterBy = '', exact = false) => {
  if (exact && ifilterBy !== '') return isource === ifilterBy;
  return !(ifilterBy === '' && isource !== '') && isource.toLowerCase().indexOf(ifilterBy.toLowerCase()) !== -1;
  // return exact ? source === filterBy : source.indexOf(filterBy) !== -1
};
const strUnMatch = (isource = '', ifilterBy = '', exact = false) => {
  if (exact && ifilterBy !== '') return isource !== ifilterBy;
  return (ifilterBy === '' && isource !== '') || isource.toLowerCase().indexOf(ifilterBy.toLowerCase()) === -1;
  // return exact ? source === filterBy : source.indexOf(filterBy) !== -1
};
const numMatcher = logic => {
  // const fn = (a, b) => eval(`a ${logic} b`)  // eslint-disable-line
  const fn = (a, b) => {
    const acb = {
      gt: a > b,
      lt: a < b,
      egt: a >= b,
      elt: a <= b,
      eq: a === b,
      neq: a !== b,
    };
    return acb[logic];
  };
  return (val, fb) => {
    console.log(val, fb);
    return !Number.isNaN(Number(fb)) && !Number.isNaN(Number(val)) && fn(Number(val), Number(fb));
  };
};
// 9 13 39    38 37 40  tab entry right   up left down
const directionMap = { 9: 1, 13: 1, 39: 1, 37: -1, 38: -10, 40: 10 };
// TODO selectes 适当时候 替换回对的拼写： selects
// TODO 序号列根据宽度自适应
// TODO 根据rowIndex columnKey 能取到单元格所有信息: value columnProps 等 （columnProps暂时先从header取）
// rowIndex, columnKey e value 的顺序传参
// 通过ref 操作整列是行不通的 在行数多于一屏的时候 并没有实际的那么多行渲染出来
// 应该通过修改liststore 来实现
// headercell: 输入 columnKey listshowstore 即可直接render. 其他内容都在外部store里获取
// !!!!!! header变了时一定要调用生成header
@Dimensions({ elementResize: true })
export default class Tabel extends PureComponent {
  constructor(prop) {
    super(prop);
    window.coyperStatus[prop.tableKey] = {};
    const skinSet = window.psn_setting.skin_set;
    const tableRowHeight = this.getRowHeight();
    this.onRowClickTimeOutID = null; // for 区分单双击
    this.defaultRowHeight = tableRowHeight; // 大小字体行高不同
    this.noThousandSep = isset(skinSet, '__meta.thousandSep', '1') === '2';
    this.dataFormater = (ilistVal, format = {}) =>
      dataFormater(ilistVal, format, { exceptKeys: this.noThousandSep ? ['thousands_sep'] : undefined });
    // this.dataFormater = this.noThousandSep ? (ilistVal, format = {}) => {
    //   const _format = { ...format }
    //   delete _format.thousands_sep
    //   return dataFormater(ilistVal, _format)
    // } : dataFormater
    // this.fixedColumn = ['operate', 'reference', 'checkbox']
    this.currQuickFilter = prop.currQuickFilter || {}; // 'quickFilterAll'
    this.headerCellRef = {};
    this.form = {};
    this.hfData = {};
    this.headerTitle = {};
    this.rightClickInfo = {
      columnKey: '',
      rowIndex: 0,
    };
    this.shouldScrollColunm = false;
    this.shouldScrollRow = false;
    // this._updateFreeze = true // 冻结更新标识
    this._updateTableFilterRow = true; // 筛选行更新标识
    this._searchFilterIndexMaps = {}; // filter 用于前端搜索
    this._frontOnlySortIndexes = null; // 记录筛选后的listStore的indexmap
    this._filter = prop.filter || {}; // filter 用于搜索 为 key : val   input_string  date_string  selectdrop_keyarray
    this.showingHeight = {};
    this.shouldUpdateHeight = true; // 是否更新列表高度
    this.showingRows = {};
    this.useInitData = true; // 使用初始化的 或 新更新到store的state
    this.columnRef = {}; // 列对象
    // 事件处理 -> eventsMap
    // 事件回调参数： rowIndex, columnKey e value 的顺序传参
    this.cellEvents = {
      Input: {
        onChange: throttle(this.onChange(), 50), // throttle(this.handleChange, 50),
        // onChange: this.onChange(), // throttle(this.handleChange, 50),
        onBlur: this.onBlur(),
        onKeyDown: this.handleFilterOnKeyDown,
        onFocus: this.hanldeInputFocus,
      },
      PureInput: {
        onChange: throttle(this.onChange(), 50), // throttle(this.handleChange, 50),
        // onChange: this.onChange(), // throttle(this.handleChange, 50),
        onBlur: this.onBlur(),
        onKeyDown: this.handleKeyDown,
        onFocus: this.hanldeInputFocus,
      },
      DateTime: {
        onChange: this.onChange(), // this.handleChange,
      },
      RangeDate: {
        onChange: this.onChange(), // this.handleChange,
      },
      RangeDateCombo: {
        onChange: this.onChange(), // this.handleChange,
      },
      Button: {
        onClick: this.handleClick,
      },
      CheckBox: {
        onChange: this.onCheckBoxChange(), // this.handleCheckBoxClick,
        onKeyDown: this.handleFilterOnKeyDown,
      },
      CheckBoxGroup: {
        onChange: this.onCheckBoxChange(), // this.handleCheckBoxClick,
        onKeyDown: this.handleFilterOnKeyDown,
      },
      CheckBoxText: {
        onChange: this.onCheckBoxChange(), // this.handleCheckBoxClick,
        onKeyDown: this.handleFilterOnKeyDown,
      },
      CheckDrop: {
        onClick: this.handleCheckDropClick,
      },
      Subtract: {
        onClick: this.handleTodoSubtract,
      },
      Substract: {
        onClick: this.handleTodoSubtract,
      },
      SelectDrop: {
        handleSelected: this.onSelectChange(), // this.handleSpecialSelected,
        // onInputKeyDown: this.handleFilterOnKeyDown, // 与下拉方向按键冲突
        onInputFocus: this.handleSelectDropInputFocus,
        handleSelectDropBlur: this.handleSelectDropBlur,
      },
      Select: {
        handleSelected: this.onSelectChange(), // this.handleSpecialSelected,
        onInputFocus: this.hanldeInputFocus,
      },
      Link: {
        onClick: this.handleLinkClick,
      },
      LinkImg: {
        onClick: this.handleLinkClick,
      },
      AddrSug: {
        onBlur: this.onBlur(),
        handleSelected: this.onSelectChange(), // this.handleSpecialSelected,
        handleChange: this.onSelectChange(), // this.handleSpecialSelected,
      },
      Address: {
        onBlur: this.onBlur(),
        handleSelected: this.onSelectChange(), // this.handleSpecialSelected,
        handleChange: this.onSelectChange(), // this.handleSpecialSelected,
      },
      Ccombiner: {
        handleEvents: this.onCcombinerEvent(), // this.handleSpecialSelected,
        onKeyDown: this.handleFilterOnKeyDown,
      },
      Radio: {
        onChange: this.onRadioChange(), // this.handleRadioChange,
      },
      TreeSelect: {
        onChange: this.onChange(), // this.handleChange,
      },
      PureTreeSelect: {
        onChange: this.onChange(), // this.handleChange,
      },
      Operate: {
        onClick: this.handleOperateClick,
      },
      Customize: {
        handleEvents: this.handleCustomizeEvent,
      },
      RangeInput: {
        onChange: throttle(this.onChange(), 50), // throttle(this.handleChange, 50),
      },
      Formula: {
        onChange: throttle(this.onChange(), 50), // throttle(this.handleChange, 50),
      },
      DataList: {
        onChange: this.onSelectChange(), // this.handleSpecialSelected,
        onInputKeyDown: this.handleFilterOnKeyDown,
      },
      FormulaPicker: {
        onChange: this.onChange(), // this.handleChange,
      },
    };
    this.headerEvents = {};

    const listStore = this.createStore(prop.data);

    const newState = {
      data: prop.data || [],
      enumerations: prop.enumerations,
      selectes: prop.defaultSelectes,
      rowClickSelectes: [],
      // 默认传入的选中的index为原始index
      originSelectes: prop.defaultSelectes,
      sortList: prop.sort || {},
      isShowFilterRow: prop.isShowFilterRow,
      rowHeight: prop.rowHeight ? prop.rowHeight : this.defaultRowHeight,
      selectAll: false,
      columnWidths: prop.fixedColumnWidth || {}, // 列的宽度, 会setState 到这里面, 在这里设置特殊列的宽度
      commonProps: {
        width: prop.columnWidth || 80,
      },
      hideColumn: [],
      total: prop.total || {},
      // tableScrolling: false,
      listStore,
      headerHeight: this.getHeaderHeight(prop),
      footerChecked: {}, // 文本checkbox 列 底部勾选状态
      checkBoxTextSelects: [], // 列勾选选中项， {columKey: []}
      isLoading: false,
      stickyIndex: 0,
      skinSet,
      scrollLeft: prop.scrollLeft,
      headerRelationship: {},
      showColumnWidth: 0,
      multiTableDeep: 1,
      isMultiTable: false,
      isShowAvg: false,
      tableHeaderRelationShip: null,
      // columnSelectes: [], // TODO 列选中
    };
    this.getInitData(prop, newState, this._filter, undefined, false, this.props.initUpdateTotal, this.props.firstSort);
    prop.refGetter && prop.refGetter(this); // TODO 旧版的ref 需要变更
    this.orderNumScan = false;
  }

  static defaultProps = {
    footerHeight: TABLE_FOOTER_SELECTED_HEIGHT,
    filterRowHeight: 40,
    fixedColumnWidth: {}, // 固定列宽度
    headerRightMenu: [],
    rowRightMenu: [],
    defaultSelectes: [],
    rowCountColumn: '',
    // uniqRowCount: true,
    canSaveTableStyle: true,
    canFreeClumn: true,
    isShowTotalRow: true,
    isRowSelect: true,
    isShowFilterRow: true,
    isShowFilterRowIcon: true,
    rowClickable: true,
    enableOperate: true,
    markFrontOnly: true,
    showScrollbarX: true,
    showScrollbarY: true,
    onlyViewportColumn: true,
    preLoadStore: true,
    isRowClickSelect: true,
    entryKeyBatchSearch: true,
    tableKey: `${Date.now()}`,
    tipsTrigger: 'hover',
    firstSort: true,
    // currQuickFilter: {},
    enableDataPath: true,
    initUpdateTotal: false,
  };
  static propTypes = {
    classname: PropTypes.string, // table 最外层的class名
    style: PropTypes.object, // table 最外层的样式
    tableKey: PropTypes.string, // 当前table的key 值
    data: PropTypes.array.isRequired,
    filterRowHeight: PropTypes.number,
    header: PropTypes.object.isRequired, // 表头
    enumerations: PropTypes.object, // 枚举值全量
    total: PropTypes.any, // 全部数据合计（不一定为当前展示数据）
    sort: PropTypes.object, // 排序规则
    filter: PropTypes.object, // 表头初始筛选值
    columnWidth: PropTypes.number, // 指定默认的列宽
    customNoDataDesc: PropTypes.any, // 自定义没有数据提示文案
    defaultSearchTips: PropTypes.any, // 默认不搜索时的提示文案
    colorRule: PropTypes.array, // 当前表格的底色设置规则
    headerRightMenu: PropTypes.array, // 表头右键菜单、与默认菜单合并
    rowRightMenu: PropTypes.oneOfType([PropTypes.func, PropTypes.array]), // 行右键菜单
    headerHeight: PropTypes.number, // 表头高度（包括筛选行，内部通过state根据是否有筛选行动态变化）
    footerHeight: PropTypes.number, // 底部高度
    rowHeight: PropTypes.number, // 行高
    fixedColumnWidth: PropTypes.object, // 固定列宽度
    containerWidth: PropTypes.number, // 引入Dimensions使用
    containerHeight: PropTypes.number, // 引入Dimensions使用
    isGroupHeader: PropTypes.bool, // 是否双表头
    groupHeader: PropTypes.array, // 双表头配置数据
    groupHeaderHeight: PropTypes.number, // 双表头，合并表头行高度
    isOrderNumberColumn: PropTypes.bool, // 是否显示行号
    isSelectColumn: PropTypes.bool, // 是否显示选择列
    isShowContextMenu: PropTypes.bool, // 是否启用表头右键菜单
    isShowRowContextMenu: PropTypes.bool, // 是否启用数据行右键菜单
    isShowTotalRow: PropTypes.bool, // 是否显示合计行
    isShowOperation: PropTypes.bool, // 是否显示操作列
    isShowSubstract: PropTypes.bool, // 是否显示加减列
    isShowSort: PropTypes.bool, // 此表格是否支持排序
    isShowFilterRow: PropTypes.bool, // 是否显示筛选行
    isShowFilterRowIcon: PropTypes.bool, // 是否显示筛选图标
    isRowSelect: PropTypes.bool, // 此表示是否支持行选中
    isRowDbClick: PropTypes.bool, // 此表是否支持行双击操作
    isHeaderDbClick: PropTypes.bool, // 此表是否支持表头双击操作
    isAutoContentSize: PropTypes.bool, // 是否自适应内容宽、高
    isAutoContentWidth: PropTypes.bool, // 是否自适应内容宽度
    isAutoContentHeight: PropTypes.bool, // 是否自适应内容高度
    isAutoRowHeight: PropTypes.bool, // 行高自适应内容
    canSaveTableStyle: PropTypes.bool, // 是否支持保存表格样式
    canFreeClumn: PropTypes.bool, // 是否支持冻结列
    stringAutoSearch: PropTypes.bool, // 单独调用table时 字符串筛选 触发handleSearchFilter回调
    rowCountColumn: PropTypes.string, // 列表数据条数合计值 显示列 route_name/条路由
    uniqRowCount: PropTypes.bool, // 列表数据选中合计的 计数列 去重
    realTimeTotal: PropTypes.bool, // 前端根据筛选实时计算合计
    realTimeSelect: PropTypes.bool, // 前端根据筛选实时显示选中行，所见即所得
    gatherOperates: PropTypes.bool, // 收行操作到齿轮
    selectSingleRow: PropTypes.bool, // 只能选中一行
    cellclassMap: PropTypes.object, // 单元格样式关联值映射 如 标题列某关联 已读未读状态列的值显示对应样式
    rowClickable: PropTypes.bool, // 行是否可点击
    isTotalRowOperate: PropTypes.bool, // 操作列 在合计行显示操作 // TODO 不完全支持操作列所有内容都可同到合计行 合并为操作齿轮的不支持
    entryKeyBatchSearch: PropTypes.bool, // 表头 批量搜索响应回车
    enableQuickFilter: PropTypes.bool, // 表头 快捷搜索是否开启
    noDataTip: PropTypes.bool, // 列表无数据时 是否显示提示文字
    refGetter: PropTypes.func, // 获取ref
    updateDimensions: PropTypes.func, // dimension 引入 同步为父级宽高
    setParentDimensions: PropTypes.func, // dimension 引入 设置父级宽高
    enableOperate: PropTypes.bool, // 当前表格的input checkbox 等是否可以操作， 默认值为true， 可以操作
    isPureText: PropTypes.bool, // 当前表格 只显示为textcell (打印用)
    cellContentGetter: PropTypes.func, // 自定义单元格
    cellStyleGetter: PropTypes.func, // 自定义单元格样式
    cellClassGetter: PropTypes.func, // 自定义单元格样式名
    customizesingleFilter: PropTypes.func, // 自定义单个筛选
    customizeFilter: PropTypes.func, // 自定义筛选
    syncFilter: PropTypes.func, // 同步默认筛选值 等
    showScrollbarX: PropTypes.bool, // 显示滚动条 X
    showScrollbarY: PropTypes.bool, // 显示滚动条 Y
    onlyViewportColumn: PropTypes.bool, // 只加载可视范围能容纳的列
    keepFooterCheckState: PropTypes.bool, // checkboxtextcell 保持底部勾选状态
    noRefEnum: PropTypes.bool, // 表头未使用举值映射
    isPopCopyOn: PropTypes.bool, // 悬浮快捷复制
    psnFontSize: PropTypes.string, // 列表个人设置字体
    isRowClickSelect: PropTypes.bool, // 行点击是否能勾选 绝对控制
    ignoreDefault: PropTypes.bool, // 默认筛选值是否生效
    sortIner: PropTypes.bool, // 全表内部排序
    filterIner: PropTypes.bool, // 全表内部筛选
    preLoadStore: PropTypes.bool, // 缓存异步预加载
    handleSelectDropSelect: PropTypes.func, // 特殊列的selectDrop
    handleCustomizeEvent: PropTypes.func, // 自定义单元格事件
    handleHeaderSortable: PropTypes.func, // 排序
    handleHeaderCheck: PropTypes.func, // 选中
    handleHeaderDoubleClick: PropTypes.func, // 表头双击
    handleChange: PropTypes.func, // 单元格值变化回调
    handleBlur: PropTypes.func, // 单元格blur回调
    handleFocus: PropTypes.func, // 单元格focus回调
    handleKeyDown: PropTypes.func, // 单元格KeyDown回调
    handleLinkClick: PropTypes.func, // 单击Link列(rowIndex, columnKey)
    handleOperateClick: PropTypes.func, // 操作列按钮回调
    handleCheckDropClick: PropTypes.func, // 单击 CheckDrop 列(rowIndex, columnKey)
    handleSelectRow: PropTypes.func, // 选择行
    handleSelectAllRow: PropTypes.func, // 选择所有行
    handleRowClick: PropTypes.func, // 点击行
    handleRowDbClick: PropTypes.func, // 行双击操作
    handleTodoAdd: PropTypes.func, // 加减号顶部
    handleTodoSubtract: PropTypes.func, // 加减号
    handleSearchFilter: PropTypes.func, // 筛选行
    handleQuickFilter: PropTypes.func, // 表头快捷筛选回调
    handleSaveTableStyle: PropTypes.func, // 是否支持保存表格样式
    hanldeAutoWidth: PropTypes.func, // 是适应列宽或者隐藏列或者取消隐藏列后的回调
    handleTopRightIconClick: PropTypes.func, // groupHeader右上角图标点击事件
    headerQuickFilter: PropTypes.array, // 表头快速筛选
    dataUpdater: PropTypes.func, // 可编辑单元格 数据变动时 数据更新方法
    defaultSelectes: PropTypes.array, // 默认选中
    rowKeyGetter: PropTypes.func, // 默认选中
    tipsTrigger: PropTypes.string, // 有tips的情况下，trigger方式
    tableAlign: PropTypes.any,
    headerStyle: PropTypes.object,
    footerStyle: PropTypes.object,
    bodyStyle: PropTypes.object,
    currQuickFilter: PropTypes.object,
    adaptiveCols: PropTypes.array, // 自适应列
    headerFilterKeyMap: PropTypes.array, // 表头筛选时key名要变更的项
    markFrontOnly: PropTypes.bool, // frontOnly的字段是否置灰提示
    isShowPage: PropTypes.bool, // 是否分页
    onScrollEnd: PropTypes.func,
    onVerticalScroll: PropTypes.func, // 纵向滚动回调
    scrollTop: PropTypes.number,
    scrollLeft: PropTypes.number,
    enableDataPath: PropTypes.bool,
    customizeCellAutoWidthFuncMap: PropTypes.object, // 自定义表格单元格宽度
    onFilterVisibleChange: PropTypes.func, // 筛序条件 visible 事件
    rangeSelectable: PropTypes.bool, // 是否启用鼠标框选单元格。单元格会被额外元素包裹，并监听鼠标事件。此时，部分事件可能被禁用
    onRangeSelection: PropTypes.func, // 框选单元格回调
    initUpdateTotal: PropTypes.bool, // 初始化时是否需要更新总条数
  };
  dataUpdaterWraper =
    (eventType, fn) =>
    async (...args) => {
      if (this.props.dataUpdater) {
        const { 0: rowIndex, 1: col, 2: value, 3: key } = args;
        const realEvent = args[args.length - 1];
        if (eventType === 'onCcombinerEvent' && realEvent === 'onChange') {
          fn(...args);
          return;
        }
        const rowData = this.state.listStore.getObjectAt(rowIndex);
        const res = await this.props.dataUpdater({
          rowIndex,
          col,
          value,
          rowData,
          that: this,
          eventType,
          header: this.state.header,
          key,
        });
        // 外部更新逻辑失败失败的， 走内部更新逻辑
        res === false && fn(...args);
      } else {
        fn(...args);
      }
    };
  onChange = () => this.dataUpdaterWraper('onChange', this.handleChange);
  onBlur = () => this.dataUpdaterWraper('onBlur', this.handleBlur);
  onCheckBoxChange = () => this.dataUpdaterWraper('onCheckBoxChange', this.handleCheckBoxClick);
  onSelectChange = () => this.dataUpdaterWraper('onSelectChange', this.handleSpecialSelected);
  onCcombinerEvent = () => this.dataUpdaterWraper('onCcombinerEvent', this.handleSpecialSelected);
  onRadioChange = () => this.dataUpdaterWraper('onRadioChange', this.handleRadioChange);
  isFrontFilter = col => col.filterable === 'front_only' || this.props.frontFilter;
  isFrontSort = col => col.sortable === 'front_only' || (this.props.frontSort && col.sortable === 'true');
  getInitData(
    props,
    state,
    filter = this._filter,
    setStateCallback,
    initAllfilter = false,
    updateTotal = false,
    changeSort = true,
  ) {
    const _filter = filter;
    const specialColumn = {};
    if (props.isOrderNumberColumn) {
      const dwidth = getStrWidth(props.data.length, this.getFontSize()) + 10;
      specialColumn.reference = {
        columnKey: 'reference',
        type: 'Text',
        titleType: props.isShowFilterRowIcon && props.isShowFilterRow ? 'Icon' : undefined,
        filterType: 'None',
        title: props.isShowFilterRowIcon && props.isShowFilterRow ? 'icon-filter' : '序号',
        filterTitle: '筛选',
        sortable: 'false',
        filterable: 'false',
        quickFilter: false,
        sticky: true,
        width: dwidth < 40 ? 40 : dwidth,
        footerTitleTop: '选中',
        footerTitleBottom: '合计',
      };
    }
    if (props.isSelectColumn) {
      specialColumn.checkbox = {
        columnKey: 'checkbox',
        type: 'CheckBox',
        titleType: props.selectSingleRow ? undefined : 'CheckBox',
        filterType: 'None', // headerFilterRowType
        title: props.selectSingleRow ? '' : state.selectAll,
        footerTitle: '',
        sortable: 'false',
        filterable: 'false',
        quickFilter: false,
        sticky: true,
        width: 36,
      };
    }
    if (props.isShowSubstract) {
      specialColumn.substract = {
        columnKey: 'Subtract',
        type: 'Subtract',
        titleType: 'Icon',
        filterType: 'None',
        filterTitle: '',
        title: 'icon-add-rad',
        footerTitle: '',
        sortable: 'false',
        filterable: 'false',
        quickFilter: false,
        sticky: true,
        width: 40,
      };
    }
    if (
      props.isForceShowOperation ||
      (props.isShowOperation &&
        (props.data.length === 0 ||
          props.data.some(item => item.otherProps && item.otherProps.operate && item.otherProps.operate.length > 0)))
    ) {
      specialColumn.operate = {
        columnKey: 'operate', // 拖动宽度等操作key
        type: 'Operate',
        titleType: undefined, // 默认undefined 即文本
        filterType: 'None',
        title: '操作',
        footerTitle: '',
        sortable: 'false',
        filterable: 'false',
        quickFilter: false,
        sticky: true,
        width: 80,
        isResizable: true,
      };
    }
    let _showIndex = -1;
    let isShowAvg = false;
    const hiddenHeader = {};
    let newHeader = {};
    const headerTitle = {};
    const header = {
      ...props.header,
      ...state.header,
    };
    const RESIZE_COLUMN_LIST = []; // [正常列Key，右冻结列Key]，目前支持最后一个列
    const { checkBoxTextSelects, columnWidths } = state;
    Object.entries(specialColumn).forEach(([key, item]) => {
      _showIndex++;
      newHeader[key] = item;
      headerTitle[key] = item.title;
    });
    let stickyIndex = _showIndex;
    Object.keys(header).forEach(key => {
      const _headerCol = header[key];
      if (_headerCol.display === 'show') {
        if (_headerCol?.averageable) {
          isShowAvg = true;
        }
        _showIndex++;
        newHeader[key] = { ...TABLE_HEADER_DEFAULT_PROPS, ..._headerCol };
        props.sortIner && (newHeader[key].sortable = 'front_only');
        props.filterIner && (newHeader[key].filterable = 'front_only');
        headerTitle[key] = _headerCol.title;
        // checkbox 特殊处理
        if (_headerCol.type === 'CheckBox') {
          headerTitle[key] = {
            title: _headerCol.title,
            checked: _headerCol.checked,
          };
        }
        // 设置所有字段的默认筛选值 TODO 有默认筛选值时需要同步到listpage
        if (_filter[key] === undefined && newHeader[key].defaultValue !== undefined && !props.ignoreDefault) {
          _filter[key] = _headerCol.defaultValue; // eslint-disable-line
        }
        if (_headerCol.type === 'CheckBoxText' && !checkBoxTextSelects[key]) {
          checkBoxTextSelects[key] = [];
        }
        if (props.noRefEnum) {
          newHeader[key].refEnum = key;
        } else {
          newHeader[key].refEnum = newHeader[key].refEnum || key;
        }
        if (_headerCol.sticky) {
          stickyIndex = _showIndex;
        }
        if (![false, 'false'].includes(_headerCol.isResizable) && _headerCol.width) {
          columnWidths[key] = _headerCol.width; // 每个列的width是必传的，所以这里应该是始终有值的
          if (_headerCol.stickyRight) {
            RESIZE_COLUMN_LIST[1] = key;
          } else {
            RESIZE_COLUMN_LIST[0] = key;
          }
        }
      } else {
        // TODO：review
        hiddenHeader[key] = { ...TABLE_HEADER_DEFAULT_PROPS, ..._headerCol };
        if (props.noRefEnum) {
          hiddenHeader[key].refEnum = key;
        } else {
          hiddenHeader[key].refEnum = hiddenHeader[key].refEnum || key;
        }
      }
    });
    if (props.customizeFilter) {
      newHeader = props.customizeFilter({
        filterWhere: 'header',
        header: { ...newHeader },
        currFilterValues: () => _filter,
      });
    }
    this.headerTitle = headerTitle;
    this._filter = _filter;
    this.specialColumn = specialColumn;
    this.RESIZE_COLUMN_LIST = RESIZE_COLUMN_LIST;

    // console.log('newHeader', newHeader);
    const _newState = {
      ...state,
      columnWidths: { ...columnWidths },
      hiddenHeader,
      header: newHeader,
      headerKeys: Object.keys(newHeader),
      checkBoxTextSelects,
      stickyIndex,
      isShowAvg,
    };
    // 先筛选 再处理底部合计
    const { footerSelect, footerTotal, total, footerCalculate } = this.getFooterRowData(props, _newState, updateTotal);
    // 重置total
    _newState.total = total;
    this.hfData = {
      headerTitle,
      headerFilter: _filter,
      footerSelect,
      footerTotal,
      footerCalculate,
    };
    if (this.state) {
      this.setState({ ..._newState }, () => {
        this.initFrontFilter(_filter, _newState, state.enumerations, true, initAllfilter || props.filterIner);
        changeSort && this.initFrontSort(_newState, newHeader);
        setStateCallback && setStateCallback();
      });
    } else {
      this.state = _newState;
      this.initFrontFilter(_filter, _newState, state.enumerations, false, initAllfilter || props.filterIner);
      changeSort && this.initFrontSort(_newState, newHeader);
      setStateCallback && setStateCallback();
    }
    props.syncFilter && props.syncFilter({ filter: _filter });
  }

  // 初始化表格
  initTable = () => {
    this.shouldScrollColunm = false;
    this.resetStateSelectes();
    // this._updateFreeze = true
    this._searchFilterIndexMaps = {}; // filter 用于前端搜索
    this._filter = {}; // filter 用于搜索
    this._updateTableFilterRow = true; // 筛选行更新标识
    this.handleShowColumn(); // 显示隐藏列
    this.resetColumnWidth(); // 列表宽度
    // this.resetFixedColumn() // 列表固定列
  };
  initTableData = () => {
    this.shouldScrollColunm = false;
    this.resetStateSelectes();
    // this._updateFreeze = true
    this._searchFilterIndexMaps = {}; // filter 用于前端搜索
    this._filter = {}; // filter 用于搜索
    this._updateTableFilterRow = true; // 筛选行更新标识
  };
  reLoadTable = () => {
    this.setState({ footerChecked: _.cloneDeep(this.state.footerChecked) });
  };
  startLoading = () => {
    !this.state.isLoading && this.setState({ isLoading: true });
  };
  stopLoading = () => {
    this.state.isLoading && this.setState({ isLoading: false });
  };
  // TODO：review
  // v2_格式化表头 包括加入非数据字段 序号 勾选
  // 是否显示序号：isOrderNumberColumn
  // 是否显示勾选：isSelectColumn
  // 是否显示加减：isShowSubstract
  // 是否显示操作列：isShowOperation
  // 特殊列不显示右键菜单， 表头，内容，底部功能一套headerProps
  // v2_返回当前state中data对应的可直接用于单元格的值
  // TODO listShowStore里 只有header里有的字段
  // otherProps 等 非显示字段 需要从data取
  // TODO isPureText 时 非textcell字段的取值问题
  getShowStoreRow = (i, dataItem, columns) => {
    if (LISTSHOWSTORE_ADDROWS.includes(i)) {
      return this.hfData[i] || {};
    }
    let newDataItem = {};
    if (dataItem) {
      newDataItem.otherProps = dataItem.otherProps || {};
      const seachHiddenColumn = columns && columns.length;
      // Check
      const columnKeys = seachHiddenColumn ? columns : this.state.headerKeys;
      columnKeys.forEach(columnKey => {
        // hidden下的直接显示为空
        if (isInOtherPropsArray(dataItem, columnKey, 'hidden')) {
          newDataItem[columnKey] = '';
          return;
        }
        if (columnKey === 'reference') {
          newDataItem[columnKey] = i + 1;
        } else if (columnKey === 'checkbox') {
          this.showingRows[i] = { [columnKey]: true };
          newDataItem[columnKey] = { checked: this.state.selectes.includes(i) };
        } else if (columnKey === 'operate') {
          newDataItem[columnKey] = newDataItem.otherProps.operate;
        } else {
          // 数据列
          // TODO：review
          const headerCol =
            this.state.header[columnKey] || (seachHiddenColumn && this.state.hiddenHeader[columnKey]) || {};
          const format = headerCol.format || {};
          let cellclass;
          let value = formatValue(
            columnKey,
            dataItem,
            this.state.enumerations || {},
            this.props.cellclassMap,
            this.props.cellclassMap
              ? cls => {
                  cellclass = cls;
                }
              : undefined,
            headerCol,
          );
          value = value === undefined || value === null ? '' : value;
          // checkbox 特殊处理
          if (`${headerCol.type}` === 'CheckBox') {
            this.showingRows[i] = { [columnKey]: true };
            newDataItem[columnKey] = { text: headerCol.label || '', checked: this.dataFormater(value, format) };
          } else if (`${headerCol.type}` === 'CheckBoxText') {
            this.showingRows[i] = { [columnKey]: true };
            newDataItem[columnKey] = !typeIs(value, 'object')
              ? { text: this.dataFormater(value, format) || '', checked: false }
              : { text: this.dataFormater(value.text || '', format), checked: value.checked };
          } else {
            value = this.dataFormater(value, format);
            if (this.props.isPureText && headerCol.columnType === 'object' && typeIs(value, 'array')) {
              value = value.map((v, k) => k + 1).join(' ');
            }
            newDataItem[columnKey] = value;
          }
          // cellclass 是枚举里有key为cellclass.columnKey的值， 若配置了cellclassMap，则所取枚举值为map指定的列值
          cellclass && (newDataItem[`cellclass.${columnKey}`] = cellclass);
        }
      });
      // 添加是否底色属性
      newDataItem = getRowStyleCls({ dataItem: newDataItem, colorRule: this.props.colorRule });
    }
    return newDataItem;
  };
  getStoreRow = initData => index => {
    const data = this.useInitData ? initData : this.state.data;
    if (data) {
      const obj = data[index] || {};
      // if (!obj) {
      //   recordLog({ message: `Table返回了空的数据：${data}, 请求的index为：${index}, initData: ${initData}` })
      // }
      // obj = obj || {}
      obj.reference = index + 1; // 索引行
      return obj;
    }
  };
  // 此方法中 不setState
  createStore = (data = [], preLoad = this.props.preLoadStore) => {
    this.useInitData = true;
    // 回车后，前端搜索都执行为后端搜索，需要清空前端搜索存储的临时index
    this._searchFilterIndexMaps = {};
    if (!data)
      return new ListStore({ size: 0, oriRowObjGetter: this.getStoreRow({}), showRowObjGetter: this.getShowStoreRow });
    const size = data.length;
    const listStore = new ListStore({
      size,
      oriRowObjGetter: this.getStoreRow(data),
      showRowObjGetter: this.getShowStoreRow,
    });
    if (preLoad && size > 50) {
      setTimeout(() => {
        const header = {};
        // 删除function
        Object.keys(this.state.header || {}).forEach(k => {
          header[k] = this.state.header[k];
          if (header[k].fetchApi && header[k].fetchApi.paraCallback) {
            header[k] = {
              ...this.state.header[k],
              fetchApi: {
                ...this.state.header[k].fetchApi,
                paraCallback: undefined,
              },
            };
          }
        });
        const executeData = {
          d: data.map(item => {
            _.set(item, ['otherProps', 'extRender'], {});
            return item;
          }),
          addrow: LISTSHOWSTORE_ADDROWS,
          hfData: this.hfData || {},
          selectes: this.state.selectes || [],
          header,
          headerKeys: this.state.headerKeys || {},
          enumerations: this.state.enumerations || {},
          cellclassMap: this.props.cellclassMap || {},
          isPureText: this.props.isPureText,
          weightUnit: (window[COMPANY_SETTING].weight_unit && window[COMPANY_SETTING].weight_unit.value) || 'KG',
          noThousandSep: this.noThousandSep,
          // 颜色设置规则
          colorRule: this.props.colorRule,
        };
        preLoadStore(executeData, listStore, res => {
          if (res && res.showingRows) {
            this.showingRows = { ...res.showingRows, ...this.showingRows };
          }
        });
      }, 500);
    }
    return listStore;
  };
  getState = () => this.state;
  // !!!!!!  默认取当前显示行的  !!!!!!
  // 返回当前state中选中的运单 默认原始索引
  // ori = true 原始数据索引 false map后的索引
  getStateSelectes = (ori = false) => {
    if (!this.state.selectes) return [];
    return ori ? this.state.listStore.getOriSelected(this.state.selectes) : this.state.selectes;
  };
  // 当前显示的选中值-》false
  getStateSelecteRows = () => {
    if (!this.state.selectes) return [];
    const dataList = [];
    const allList = this.state.listStore.getAll();
    this.state.selectes.forEach(index => {
      dataList.push(allList[index]);
    });
    return dataList;
  };
  // 返回当前state中合计信息
  getTotalData = () => (this.state.total ? this.state.total : {});
  // 获取选中行的 指定key值的数据对象数组; key不传或为null 则返回选中的index
  getSelectesKey = key => {
    const tmpList = this.getStateSelectes();
    if (typeIs(key, 'undefined') || key === null) return tmpList;
    const selectes = this.getTableList(tmpList) || [];
    if (Array.isArray(key)) {
      return filterListColumnByKeyList(selectes, key);
    }
    return filterArrayPropByKey(selectes, key);
  };
  // props 传入的初始数据
  getOriData = () => this.props.data || [];
  // 当前显示的 数据行数
  getShowingDataSize = () => this.state.listStore.getSize();
  // ori true 返回全部原始数据， false 返回带筛选排序排序的当前可见行数据
  getStateCache = (ori = false) => this.state.listStore.getAll(ori);
  // 返回带筛选排序的当前可见行数据， 等同于 getStateCache(false) 或 getTableList
  getFilterStateCache = () => this.state.listStore.getAll();
  getIsMultiTable = () => this.state.isMultiTable;
  getTableHeaderRelationShip = () => this.state.tableHeaderRelationShip;
  // validateDict : 验证信息{ key: [validate] }
  // 此方法取的所有数据-显示的、未显示的， 并校验
  getTableData = (validate = false, validateDict, checkDisable = true) => {
    const data = this.state.listStore.getAll(true);
    if (!validate) return { data };
    const res = tableDataCheck(data, this.state.header, validateDict, checkDisable);
    res.data = data;
    // 数据验证且验证不通过，返回false
    return res;
  };
  // 根据index 获取table中数据
  // index为筛选排序后的新index）
  // -- 不传indexList同getStateCache(false) 或 getFilterStateCache()
  getTableList = indexList => {
    const dataList = [];
    const allList = this.state.listStore.getAll();
    if (indexList) {
      indexList.forEach(index => {
        dataList.push({ ...allList[index], rowIndex: index });
      });
    }
    return dataList;
  };
  // 转换为原始数据的index
  showIndexToOri = i => (this.state.listStore ? this.state.listStore.showIndexToOri(i) : i);
  resetCoyperStatus = () => delete window.coyperStatus[this.props.tableKey];
  resetStateSelectes = () =>
    this.setState({ selectes: this.props.defaultSelectes, rowClickSelectes: [], selectAll: false });
  resetColumnWidth = () => this.setState({ columnWidths: this.props.fixedColumnWidth || {} });
  // resetFixedColumn = () => this.setState({ fixedColumn: defaultFixedColumn.concat(this.props.fixedColumn ? this.props.fixedColumn : []) })
  // 外部调用 设置filter中的字段
  setFilterField = (columnKey, value, shouldRender = false) => {
    this._filter[columnKey] = value;
    this._updateCache('headerFilter', columnKey, value, 'show');
    // 开启刷新 并在表头显示字段的才刷新
    if (shouldRender) {
      this.setState({ sortList: { ...this.state.sortList } });
      if (this.state.headerKeys.includes(columnKey)) {
        // 表头显示字段同时执行刷选
        const filterBy = `${value}`.trim();
        this._filterByString({ filterBy, columnKey, state: this.state, filter: this._filter }, undefined, false);
      }
    }
  };
  // 设置整个filter中的字段
  setSearchFilter = filter => {
    this._filter = filter;
    this._updateCacheRow('headerFilter', filter, 'show');
    this.setState({ sortList: { ...this.state.sortList } });
  };
  /* *********************** 修改header中的某个属性 ******************************** */
  changeHeaderProps = (paraKey, value, keyList = this.state.headerKeys, header = this.state.header) => {
    const newHeader = { ...header };
    keyList.forEach(key => {
      if (newHeader[key]) {
        newHeader[key][paraKey] = value;
      }
    });
    this.getInitData(
      this.props,
      { ...this.state, header: newHeader, listStore: this.createStore(this.props.data) },
      this._filter,
    );
  };
  /* *********************** 修改header显示值 ******************************** */
  // listStore 不用更新的 {h: {key: value}, h: {key: value}}
  setHeaderValues = rows => {
    const { listStore } = this.state;
    Object.entries(rows).forEach(([rowIndex, values]) => {
      const oldRow = listStore.getShowObjectAt(rowIndex);
      this._updateCacheRow(rowIndex, { ...oldRow, ...values }, 'show');
    });
    this.setState({ sortList: { ...this.state.sortList } });
  };
  getSearchFilter = () => ({ ...this._filter });
  // 外部调用 获取筛选条件
  getApiSearchFilter = prop =>
    format2ApiQueryFilter({ searchFilter: this._filter, header: this.state.header, ...prop });
  // 获取表格枚举值
  getTableEnum = key => {
    const { enumerations } = this.state;
    if (key) {
      const headerCol = this.state.header[key] || {};
      return enumerations[headerCol.refEnum] || {};
    }
    return enumerations;
  };
  getColumnType = columnKey => {
    if (isEmptyObj(this.state.header) || isEmptyObj(this.state.header[columnKey])) return;
    return this.state.header[columnKey].type;
  };
  // 只获取变化后的值
  getDiffForm = () => this.form;
  // 清空 form
  clearDiffForm = () => {
    this.form = {};
  };
  // 更新table中的数据
  // 此处调用this.getInitData时，会：
  // 1.根据当前筛选重新执行筛选数据
  // 2.根据列类型，重新计算合计行（如输入框列）(默认更新数据，即更新total指给，不更新合计可resetTotal=false)
  // 3.重新排序
  // 4.重新设置勾选列的状态
  resetData = (data, enumerations, resetTotal = true) => {
    const newListStore = this.createStore(data);
    const newState = {
      ...this.state,
      data,
      enumerations: { ...this.state.enumerations, ...enumerations },
      listStore: newListStore,
      selectes: this.props.defaultSelectes,
      originSelectes: this.props.defaultSelectes,
      rowClickSelectes: [],
      selectAll: false,
    };
    this.getInitData(
      this.props,
      newState,
      this._filter,
      () => {
        const checkBoxTextSelects = this.state.checkBoxTextSelects || {};
        const footerChecked = { ...this.state.footerChecked };
        const checkboxTextCols = Object.keys(checkBoxTextSelects || {}); // 有checkboxtext 勾选的列
        if (checkboxTextCols.length && this.props.enableOperate) {
          const footerCheckKeys = Object.keys(footerChecked); // 底部checkboxtext 勾选的列
          const size = data.length;
          checkboxTextCols.forEach(columnKey => {
            if (this.props.keepFooterCheckState) {
              // 底部勾选上的key  重置数据时 是否要保留原来的底部全选状态
              if (footerCheckKeys.includes(columnKey)) {
                for (let rowIndex = 0; rowIndex < size; rowIndex++) {
                  // 遍历所有行
                  const rowValue = data[rowIndex];
                  let cellValue = formatDeppProptotype(columnKey, rowValue);
                  if (!typeIs(cellValue, 'object')) {
                    cellValue = { text: cellValue, checked: !isInOtherPropsArray(rowValue, columnKey, 'disable') };
                  }
                  if (!this.checkBoxTextSelects[columnKey][rowIndex]) {
                    this.checkBoxTextSelects[columnKey][rowIndex] = true;
                    data[rowIndex] = setDeppProptotypes(columnKey, rowValue, cellValue); // eslint-disable-line
                  }
                }
              }
            } else {
              const checkedAllofCurrColumn = [];
              const colCheckBoxTextSelects = checkBoxTextSelects[columnKey] || [];
              for (let rowIndex = 0; rowIndex < size; rowIndex++) {
                const rowValue = data[rowIndex];
                if (isInOtherPropsArray(rowValue, columnKey, 'disable')) return;
                let cellValue = formatDeppProptotype(columnKey, rowValue);
                if (!typeIs(cellValue, 'object')) {
                  cellValue = { text: cellValue, checked: false };
                }
                // disable的置为false, 根据每一行的值 生成整列 哪些勾选，哪些不勾选： colCheckBoxTextSelects
                const { checked } = cellValue;
                // 行选中处理
                const index = colCheckBoxTextSelects.indexOf(rowIndex);
                if (checked && index === -1) {
                  // 新选中的 // 行单选时 只能有一个选中项
                  colCheckBoxTextSelects.push(rowIndex);
                } else if (!checked && index !== -1) {
                  // 已选中的
                  colCheckBoxTextSelects.splice(index, 1);
                }
                // 底部合计选中处理 -> 有一个未勾选 且没记录过
                if (checked === false && footerChecked[columnKey]) {
                  footerChecked[columnKey] = false;
                }
                (checked || !cellValue.text) && checkedAllofCurrColumn.push(rowIndex);
              }
              checkedAllofCurrColumn.length === size && (footerChecked[columnKey] = true);
              checkBoxTextSelects[columnKey] = colCheckBoxTextSelects;
            }
          });
        }
        this.setState({ footerChecked, checkBoxTextSelects });
      },
      true,
      resetTotal,
    );
  };
  // 更新合计信息
  resetTotalData = (total, updateTotal = false) => {
    const footerRow = this.getFooterRowData(this.props, { ...this.state, total }, updateTotal);
    this._updateCacheRow('footerTotal', footerRow.footerTotal, 'show');
    this._updateCacheRow('footerCalculate', footerRow.footerCalculate, 'show');
    this.setState({ total });
  };
  // 初始化勾选状态
  // sortRule : 当前排序条件, 两个index值进行比较
  // sortFrontendComFunc = (sortRule, indexA, indexB, getValueFunc) => {
  //   const sortKeys = Object.keys(sortRule)
  //   const { header } = this.state
  //   for (let i = 0; i < sortKeys.length; i++) {
  //     let sortVal = 0
  //     const key = sortKeys[i],
  //       headerCol = header[key]
  //     const columnType = headerCol && headerCol.columnType
  //     const symbol = sortRule[key] === SORTTYPES.ASC ? 1 : -1
  //     let valueA = getValueFunc(indexA, key)
  //     let valueB = getValueFunc(indexB, key)
  //     if (valueA === undefined || valueB === undefined) return 0
  //     // 此处走formate 会导致 科学计数法， 从而排序出错
  //     if (typeIs(valueA, 'array')) valueA = valueA.join(',')
  //     if (typeIs(valueB, 'array')) valueB = valueB.join(',')

  //     return sortVal * symbol
  //   }
  //   return 0
  // }

  // tab键自动定位到某一列, 是tab跳到下一个input
  tabFocusColumn = _.debounce(({ keyCode, target }, rowIndex, colIndex, pos) => {
    if (!this.vTableContainer) return;
    let direction = directionMap[keyCode];
    const rowEle = this.vTableContainer.querySelector(`.public_fixedDataTableRow_main.cls-${rowIndex}-e`);
    const inputsOfCurRow = [...rowEle.querySelectorAll('[data-path]:not([disabled])')];
    const curInputIndex = pos !== undefined ? pos : inputsOfCurRow.indexOf(target);
    const rowCount = this.state.listStore.getSize();
    let nextInputIndex = curInputIndex;
    let newRowIndex = rowIndex;
    if (direction === 1 || direction === -1) {
      nextInputIndex = curInputIndex + direction;
      if (nextInputIndex < 0) {
        direction = -10;
        nextInputIndex = inputsOfCurRow.length - 1;
      }
      if (nextInputIndex > inputsOfCurRow.length - 1) {
        direction = 10;
        nextInputIndex = 0;
      }
    }
    if (direction === 10 || direction === -10) {
      newRowIndex = rowIndex + (direction === 10 ? 1 : -1);
      if (newRowIndex < 0) {
        newRowIndex = rowCount - 1;
      }
      if (newRowIndex > rowCount - 1) {
        newRowIndex = 0;
      }
    }
    const focus = () => {
      const newRowEle =
        newRowIndex === rowIndex
          ? rowEle
          : this.vTableContainer.querySelector(`.public_fixedDataTableRow_main.cls-${newRowIndex}-e`);
      if (!newRowEle) return false;
      const inputsOfNewRow =
        newRowEle === rowEle ? inputsOfCurRow : [...newRowEle.querySelectorAll('[data-path]:not([disabled])')];
      const newEle = inputsOfNewRow[nextInputIndex];
      newEle?.focus();
      return true;
    };
    const newColIndex = inputsOfCurRow[nextInputIndex]
      ? +inputsOfCurRow[nextInputIndex].getAttribute('data-path').split('_').pop() || 0
      : 0;
    this.scrollToRowColumn(
      newRowIndex === 0 ? newRowIndex : newRowIndex > rowIndex ? newRowIndex + 1 : newRowIndex,
      newColIndex,
      () => setTimeout(focus),
    );
  }, 20);

  // 输入框的 focus行为
  hanldeInputFocus = (e, rowIndex, columnKey) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.focus();
    return false;
  };
  handleSelectDropInputFocus = (e, rowIndex, columnKey) => {
    // 把focus方法冒出去 只冒出去了SelectDrop，Select、Input、PureInput 未动
    this.handleSelectFocus(rowIndex, columnKey);
    e.preventDefault();
    e.stopPropagation();
    e.target.focus();
    return false;
  };
  // TODO input selectdrop keydown
  // 输入框的键盘操作
  handleFilterOnKeyDown = (e, rowIndex, colIndex) => {
    const { enableDataPath } = this.props;
    if (enableDataPath && Object.keys(directionMap).includes(`${e.keyCode}`)) {
      // 9 13 37    38 39 40  tab entry left   up right down
      e.preventDefault();
      e.stopPropagation();
      this.tabFocusColumn({ keyCode: e.keyCode, target: e.target }, rowIndex, colIndex);
    }
  };
  handleKeyDown = (e, rowIndex, columnKey) => {
    if (typeof this.props.handleKeyDown === 'function') {
      const oldRowData = this.state.listStore.getObjectAt(rowIndex);
      this.props.handleKeyDown(rowIndex, columnKey, e, oldRowData);
    }
  };
  /* ***************************************************************** */
  handleLinkClick = (viewRowIndex, column, item) => {
    const data = this.state.listStore.getObjectAt(viewRowIndex);
    if (typeof this.props.handleLinkClick === 'function') {
      this.props.handleLinkClick(viewRowIndex, column, data, item);
    }
  };
  handleCheckDropClick = (rowIndex, columnKey, item) => {
    if (typeof this.props.handleCheckDropClick === 'function') {
      this.props.handleCheckDropClick(rowIndex, columnKey, item);
    }
  };
  /* *********************** TODOTable ******************************** */
  handleTodoAdd = e => {
    e.stopPropagation();
    // if (this.props.isAutoSize) {
    //   this.handleResizeTable()
    // }
    this.props.handleTodoAdd && this.props.handleTodoAdd();
  };
  handleTodoSubtract = (e, rowIndex) => {
    e.stopPropagation();
    this.props.handleTodoSubtract && this.props.handleTodoSubtract(rowIndex, this.form[rowIndex]); // 向上传播
    delete this.form[rowIndex]; //  = undefined  // 清空form
    this.state.listStore._updateRowCache(); // 清空缓存
  };
  initFrontSort = (state, header) => {
    const isfrontSort = col =>
      header[col] &&
      header[col].display === 'show' &&
      isset(header, `[${col}].sortable`, 'front_only') === 'front_only';
    const frontSortFields = (Object.keys(state.sortList) || []).filter(x => isfrontSort(x));
    if (!frontSortFields.length) return;
    const { listStore } = state;
    let sortIndexes = listStore.getIndexMap();
    const keys = Object.keys(this._searchFilterIndexMaps);
    if (keys.length !== 0) {
      const filterTmp = this._searchFilterIndexMaps[keys.pop()] || {};
      sortIndexes = filterTmp.filteredIndexes;
    }
    this.sortFrontAllKey({ listStore, sortIndexes, header, sortList: state.sortList, frontSortFields });
  };

  sortFrontAllKey = ({ listStore, header, sortIndexes, sortList, frontSortFields }) => {
    const _sortIndexes = [...sortIndexes]; // listStore.getIndexMap() // 当前可视数据的indexMap
    _sortIndexes.sort((indexA, indexB) =>
      feFieldsCmpFunc({ indexA, indexB, sortFields: frontSortFields, header, listStore, sortList }),
    );
    this._frontOnlySortIndexes = { sortIndexes: _sortIndexes, sortList };
    listStore.updateIndexMap(_sortIndexes);
    // 此处回调的columnKey无意义, 多数回调未使用
    this.props.handleHeaderSortable && this.props.handleHeaderSortable(frontSortFields, sortList, false); // 第三个参数 标记是否走接口
  };
  /*
  initFrontSort = (state, header) => {
    const columnKey = Object.keys(state.sortList)[0]
    const isfrontSort = header[columnKey] && header[columnKey].display === 'show' && isset(header, `[${columnKey}].sortable`, 'front_only') === 'front_only'
    if (isfrontSort) {
      const listStore = state.listStore
      let sortIndexes = listStore.getIndexMap()
      const keys = Object.keys(this._searchFilterIndexMaps)
      if (keys.length !== 0) {
        const filterTmp = this._searchFilterIndexMaps[keys.pop()] || {}
        sortIndexes = filterTmp.filteredIndexes
      }
      this.sortFront({ listStore, sortIndexes, header, sortList: state.sortList, columnKey })
    }
  }
  */
  /* *********************** 表头筛选功能 ******************************** */
  initFrontFilter = (filter, state, enumerations, setState = false, initAllfilter = false) => {
    // 依赖 state enumerations, filter
    const { header } = state;
    Object.keys(filter).forEach(columnKey => {
      const headerCol = header[columnKey];
      let filterBy = filter[columnKey];
      if (
        headerCol &&
        !EMPTY_VALS_ALL.includes(filterBy) &&
        !NOT_EMPTY_VALS_ALL.includes(filterBy) &&
        (this.isFrontFilter(headerCol) || initAllfilter)
      ) {
        if (typeIs(filterBy, 'array') && filterBy.length > 0) {
          // 下拉筛选 取显示文本来筛选
          const selectdropData = enumerations[headerCol.refEnum] || [];
          const enumDic = {};
          const showKey = headerCol.showKey || 'name';
          const uniqueKey = headerCol.uniqueKey || 'key';
          selectdropData.forEach(
            item =>
              (enumDic[item[uniqueKey]] =
                item[FILTER_DIFF_SHOW_KEY] !== undefined ? item[FILTER_DIFF_SHOW_KEY] : item[showKey] || ''),
          );
          if (typeIs(filterBy[0], 'object')) {
            if (filterBy[0][uniqueKey] === SELECT_ALL_KEY) {
              // TODO 下拉全选
              return;
            }
            let subFilterBy;
            filterBy = filterBy.map(x => {
              subFilterBy = x[FILTER_DIFF_SHOW_KEY] !== undefined ? x[FILTER_DIFF_SHOW_KEY] : x[showKey] || '';
              subFilterBy === undefined && x[uniqueKey] !== undefined && (subFilterBy = enumDic[x[uniqueKey]]);
              return subFilterBy !== undefined ? `${subFilterBy}` : '';
            });
          } else {
            if (filterBy[0] === SELECT_ALL_KEY) {
              // TODO 下拉全选
              return;
            }
            filterBy = filterBy.map(x => `${enumDic[x]}`);
          }
        } else {
          filterBy = `${filterBy}`;
        }
        this._filterByString({ filterBy, columnKey, state, filter }, false);
      }
    });
    setState && this.setState({ selectes: this.props.defaultSelectes, rowClickSelectes: [] });
  };
  frontLogicFilter = (filterBy, _rowValue, logic) => {
    let isMatch = false;
    // logic格式如下， filterBy 统一传数组, 值与逻辑数组对应
    // logic[or|and] :[in notin gt lt egt elt eq neq]
    const logicFn = {
      in: strMatch,
      notin: strUnMatch,
      '>': numMatcher('gt'),
      '<': numMatcher('lt'),
      '>=': numMatcher('egt'),
      '<=': numMatcher('elt'),
      '==': numMatcher('eq'),
      '!=': numMatcher('neq'),
    };
    const logicNameMap = { or: 'some', and: 'every' };
    Object.entries(logic).forEach(([logicName, logicItem]) => {
      if (
        filterBy[logicNameMap[logicName]]((ifilterBy, _index) => {
          if (!logicFn) return false;
          if (ifilterBy === '') return true;
          if (typeIs(ifilterBy, 'array'))
            return ifilterBy.length ? ifilterBy.some(filItem => logicFn[logicItem[_index]](_rowValue, filItem)) : true;
          return logicFn[logicItem[_index]](_rowValue, ifilterBy);
        })
      ) {
        isMatch = true;
      }
    });
    return isMatch;
  };
  filterIndex = ({ filteredOriIndexMap, listStore, enumerations, columnKey, header, filter, filterBy, logic }) => {
    let isMatch = false;
    let _rowValue = '';
    const filterByType = typeIs(filterBy);
    const headerCol = header[columnKey] || {};
    const filterType = getFilterType(headerCol, enumerations);
    // isDateType = DATE_TYPE.includes(headerCol.columnType), // 日期类型
    // isNumType = NUMBER_TYPE.includes(headerCol.columnType), // 日期类型
    const isFormatSep = Object.keys(headerCol.format || {}).includes('thousands_sep'); // 分隔符格式化
    const filteredIndexes = []; // filteredIndexes = [...filteredOriIndexMap]
    filteredOriIndexMap.forEach(mapedIndex => {
      const value = listStore.getOriObjectAt(mapedIndex)[columnKey];
      const exact =
        filter[`${columnKey}._exact_`] || (filterType === 'SelectDrop' && !(Array.isArray(value) && value.length > 1));
      isMatch = false;
      _rowValue = listStore.getOriShowObjectAt(mapedIndex)[columnKey] || '';
      if (typeIs(_rowValue, 'object') && headerCol.type === 'CheckBoxText') {
        // 兼容意外情况下出此数据问题 非预定场景
        _rowValue = `${_rowValue.text || ''}`;
      } else if (typeIs(_rowValue, 'object') && (headerCol.type === 'AddrSug' || headerCol.type === 'Address')) {
        // 地址单元格筛选
        _rowValue = `${_rowValue.show_val || ''}`;
      } else if (typeIs(_rowValue, 'object') && headerCol.type === 'Progress') {
        // 兼容progress取值，根据字典将key转成name，匹配下拉选择
        _rowValue = _rowValue.status;
        const enumArr = (enumerations && enumerations[columnKey]) || [];
        const uniqueKey = headerCol.uniqueKey || 'key';
        const showKey = headerCol.showKey || 'name';
        for (let i = 0, len = enumArr.length; i < len; i++) {
          const item = enumArr[i];
          if (item[uniqueKey] === _rowValue) {
            _rowValue = item[showKey];
            break;
          }
        }
      } else {
        _rowValue = `${_rowValue}`; // TODO 统一于此处是否有问题 .toLowerCase()
      }
      // TODO: 在这里添加其他优化, 数字区间和日期
      if (filterByType === 'array') {
        if (logic) {
          isMatch = this.frontLogicFilter(filterBy, isFormatSep ? _rowValue.replace(/,/g, '') : _rowValue, logic);
        } else if (
          filterBy.some(ifilterBy => strMatch(_rowValue, ifilterBy === EMPTY_OPTION_DISPLAY ? '' : ifilterBy, exact))
        ) {
          // 字符串匹配
          isMatch = true;
        } else if (isFormatSep && filterBy.some(ifilterBy => strMatch(_rowValue.replace(/,/g, ''), ifilterBy, exact))) {
          // 带分隔符的匹配
          isMatch = true;
        }
      } else if (EMPTY_VALS_ALL.includes(filterBy) && _rowValue === '') {
        // 空值 非空值筛选逻辑
        isMatch = true;
      } else if (NOT_EMPTY_VALS_ALL.includes(filterBy) && _rowValue !== '') {
        isMatch = true;
      } else if (DATE_TYPE.includes(headerCol.columnType) && diffDate(filterBy, _rowValue)) {
        isMatch = true;
      } else if (strMatch(_rowValue, filterBy, exact)) {
        // 字符串匹配
        isMatch = true;
      } else if (isFormatSep && strMatch(_rowValue.replace(/,/g, ''), filterBy, exact)) {
        // 带分隔符的匹配
        isMatch = true;
      }
      isMatch && filteredIndexes.push(mapedIndex);
    });
    return filteredIndexes;
  };
  _filterByString = (
    { filterBy, logic, columnKey, state, filter },
    setState = true,
    shouldHandleSearchFilter = true,
  ) => {
    const { listStore, header, enumerations } = state;
    const { stringAutoSearch, handleSearchFilter, defaultSelectes, realTimeSelect } = this.props;
    let headerCol = header[columnKey];
    shouldHandleSearchFilter &&
      stringAutoSearch &&
      handleSearchFilter &&
      handleSearchFilter(filter, false, undefined, undefined, false);
    // this._searchFilterIndexMaps => 筛选key的前一次indexmap集
    // searchCols 中间某个值重新筛选时， 其后所有值先依次执行筛选后再执行当前key
    const searchCols = Object.keys(this._searchFilterIndexMaps); // , sColsLen = searchCols.length
    const keyIndex = searchCols.indexOf(columnKey);
    const forwardArr = searchCols.slice(0, keyIndex === -1 ? undefined : keyIndex);
    const lastKey = forwardArr.pop();
    let lastFilteredIndexMap = this._searchFilterIndexMaps[lastKey] || {
      filteredIndexes: listStore.getOriIndexMap(),
      value: filterBy,
    };
    // if (this._frontOnlySortIndexes && this._frontOnlySortIndexes.columnKey && keyIndex === -1) {
    // 新增筛选条件， 直接使用上一次的排序结果， 不用重新排序
    // 有前端排序过的数据 则取排序后的索引 没有则取上一次的索引
    // lastFilteredIndexMap = { filteredIndexes: [...this._frontOnlySortIndexes.sortIndexes], value: filterBy }
    // this._frontOnlySortIndexes = null
    // }
    const backwordArr = searchCols.slice(keyIndex + 1);
    const filterByArr = backwordArr.length === searchCols.length ? [columnKey] : [...backwordArr, columnKey]; // keyIndex===-1时backwordArr为全部key
    const currShowingIndexMap = listStore.getIndexMap(); // 当前可视数据的indexMap
    let { filteredIndexes } = lastFilteredIndexMap;
    filterByArr.forEach(filterByKey => {
      headerCol = header[filterByKey];
      let subFilterBy = filterByKey === columnKey ? filterBy : this._searchFilterIndexMaps[filterByKey].value || '';
      let isFilterByEmpty = false;
      const _logic = (filter && filter._logic) || [];
      _logic.forEach(item => {
        if (item.key === columnKey && item.qfLogicType === 'empty') isFilterByEmpty = true;
      });
      // 筛选条件为下拉选择，且进行空值过滤时，（没有指定空值）显示为空列表
      if (subFilterBy === '' && headerCol.filterType === 'SelectDrop' && isFilterByEmpty) {
        filteredIndexes = [];
        delete this._searchFilterIndexMaps[filterByKey]; // 最新更新的放到最后
        lastFilteredIndexMap = { filteredIndexes: [...filteredIndexes], value: subFilterBy };
      } else if (subFilterBy === '' || (typeIs(subFilterBy, 'array') && !subFilterBy.length)) {
        filteredIndexes = [...lastFilteredIndexMap.filteredIndexes];
        delete this._searchFilterIndexMaps[filterByKey]; // 最新更新的放到最后
        lastFilteredIndexMap = { filteredIndexes: [...filteredIndexes], value: subFilterBy };
      } else {
        // 为处理多列搜索情况， 需要记录每列的筛选后的indexMap, 已有筛选过的indexMap,则需要完整遍历
        const filteredOriIndexMap = lastFilteredIndexMap.filteredIndexes;
        if (BATCH_QUERY_TYPE.includes(headerCol.filterType)) {
          // 批量筛选值 分割为数组
          const _valStr = `${subFilterBy}`;
          const spliter = getSplit(_valStr);
          const _valArr = _valStr.split(spliter) || [];
          // 去除空字符项
          subFilterBy = _valArr.filter(item => item !== ''); // eslint-disable-line
        }
        filteredIndexes = this.filterIndex({
          filteredOriIndexMap,
          listStore,
          enumerations,
          columnKey: filterByKey,
          header,
          filter,
          filterBy: subFilterBy,
          logic,
        });
        delete this._searchFilterIndexMaps[filterByKey]; // 最新更新的放到最后
        this._searchFilterIndexMaps[filterByKey] = { filteredIndexes: [...filteredIndexes], value: subFilterBy };
        lastFilteredIndexMap = { filteredIndexes: [...filteredIndexes], value: subFilterBy };
      }
    });
    // 前端筛选结果是否发生变化， 不发生变化 不用更新
    if (filteredIndexes.toString() !== currShowingIndexMap.toString()) {
      if (this._frontOnlySortIndexes && this._frontOnlySortIndexes.columnKey && keyIndex !== -1) {
        // 排序还在，则筛选后需要重新排序
        const { columnKey: sortKey, sortList } = this._frontOnlySortIndexes;
        this.sortFront({
          listStore: this.state.listStore,
          sortIndexes: filteredIndexes,
          header,
          columnKey: sortKey,
          sortList,
        });
      } else {
        listStore.updateIndexMap(filteredIndexes);
      }
      console.log('now origin select index is ', this.state.originSelectes);
      const selectes = this.state.originSelectes.map(x => listStore._indexMap.indexOf(x)).filter(x => x !== -1);
      const isSelectAll = selectes.length === listStore.getSize();
      this._updateCache('headerTitle', 'checkbox', isSelectAll, 'show');
      // this.handleSelectAll(isSelectAll)
      setState &&
        this.setState({
          selectes: selectes.length || realTimeSelect ? selectes : defaultSelectes,
          rowClickSelectes: [],
          selectAll: isSelectAll,
        });
      setTimeout(() => {
        this._updateTotalRow();
      }, 0);
    }
    // 选中筛选后的结果
    if (headerCol.filterSelectAll) {
      filterBy !== '' && this.handleSelectAll(true);
      filterBy === '' && this.handleSelectAll(false);
    }
  };
  resetQuickFilter = columnKey => {
    this.currQuickFilter[columnKey] = 'quickFilterAll';
    delete this.currQuickFilter[`${columnKey}__dialogFormValue`];
    this._filter._logic = (this._filter._logic || []).filter(item => item.key !== columnKey);
    this.props.syncFilter && this.props.syncFilter({ filter: this._filter });
  };
  // 日期筛选
  handleFilterChangeDate = (value, columnKey) => {
    this.resetQuickFilter(columnKey);
    this._filter = { ...this._filter, [columnKey]: value };
    this._updateCache('headerFilter', columnKey, value, 'show');
    this.props.syncFilter && this.props.syncFilter({ filter: this._filter, updateShow: true });
    if (this.isFrontFilter(this.state.header[columnKey])) {
      this._filterByString({ filterBy: value, columnKey, state: this.state, filter: this._filter });
    } else {
      this.props.handleSearchFilter && this.props.handleSearchFilter(null, true, columnKey, value);
    }
  };
  // 下拉选择 行内筛选 下拉收起触发
  handleFitlerSelected =
    columnKey =>
    (idata = []) => {
      const data = typeIs(idata, 'array') ? idata : [idata];
      const uniqueKey = this.state.header[columnKey].uniqueKey || 'key';
      const showKey = this.state.header[columnKey].showKey || 'name';
      this.resetQuickFilter(columnKey);
      this._filter = { ...this._filter, [columnKey]: data };
      this._updateCache('headerFilter', columnKey, data, 'show');
      let filterBy = '';
      if (this.isFrontFilter(this.state.header[columnKey])) {
        if (typeIs(data, 'array') && data.length && data[0][uniqueKey] !== SELECT_ALL_KEY) {
          filterBy = typeIs(data, 'array')
            ? data.map(x => `${x[FILTER_DIFF_SHOW_KEY] !== undefined ? x[FILTER_DIFF_SHOW_KEY] : x[showKey] || ''}`)
            : [];
        }
        this._filterByString({ filterBy, columnKey, state: this.state, filter: this._filter });
      } else {
        this.props.handleSearchFilter && this.props.handleSearchFilter(null, null, columnKey, data);
      }
      // const filterBy = typeIs(data, 'array') ? data.map(x => `${x[FILTER_DIFF_SHOW_KEY] !== undefined ? x[FILTER_DIFF_SHOW_KEY] : x[showKey] || ''}`) : []
      // this._filterByString({ filterBy, columnKey, state: this.state, filter: this._filter })
    };
  // input textarea 的 keydown TODO 只能前端筛选的字段 交互方式
  _handleFilterChange = (columnKey, preventDefault) => e => {
    if (e.keyCode === 13) {
      const headerCol = this.state.header[columnKey] || {};
      if (this.props.entryKeyBatchSearch) {
        // 前端筛选时，运单号支持扫码批量筛选，需在此放开回车符事件，否则客户需要手动添加分割符
        if (this.isFrontFilter(this.state.header[columnKey]) && headerCol.filterType === 'TextArea') {
          return;
        }
        e.stopPropagation();
        preventDefault && e.preventDefault();
      }
      if (this.isFrontFilter(this.state.header[columnKey])) {
        // 组织架构 前端筛选 回车事件需要响应
        // e.stopPropagation()
        // preventDefault && e.preventDefault()
        this._filterByString({ filterBy: this._filter[columnKey], columnKey, state: this.state, filter: this._filter });
      } else {
        e.stopPropagation();
        preventDefault && e.preventDefault();
        // // 回车后，前端搜索都执行为后端搜索，需要清空前端搜索存储的临时index
        // this._searchFilterIndexMaps = {}
        this.props.handleSearchFilter && this.props.handleSearchFilter(null, null, columnKey, this._filter[columnKey]);
      }
    }
  };
  // input textarea 的 onchang
  handleFilterBlur = columnKey => {
    this.props.syncFilter && this.props.syncFilter({ filter: this._filter, updateShow: false, columnKey });
  };
  // 只对运单号进行判断
  checkIsScan = value => {
    if (value.length <= 1) {
      this.orderNumStm = new Date().getTime();
    }
    if (value.length > 3 && this.orderNumStm) {
      const nowTm = new Date().getTime();
      if (nowTm - this.orderNumStm < 50) {
        this.orderNumScan = true;
        console.log('is scan');
      } else {
        this.orderNumScan = false;
        console.log('is not scan');
      }
      this.orderNumStm = undefined;
    } else {
      this.orderNumScan = false;
    }
  };
  // input textarea 的 onchang
  handleFilterChange = (columnKey, e) => {
    const value = `${e.target.value}`;
    this.checkIsScan(value);
    const filterBy = value;
    this._filter = { ...this._filter, [columnKey]: value };
    // this.resetQuickFilter(columnKey)
    this._updateCache('headerFilter', columnKey, value, 'show');
    // 批量搜索的字段 检测输入为多个时 自动设置精确搜索
    const headerCol = this.state.header[columnKey] || {};
    if (headerCol.filterType === 'TextArea') {
      const spliter = getSplit(value);
      const exactKey = `${columnKey}._exact_`;
      if (this._filter[exactKey] !== !!spliter) {
        this._filter = { ...this._filter, [exactKey]: !!spliter };
        this._updateCache('headerFilter', exactKey, !!spliter, 'show');
        throttle(() => {
          this._filterByString({ filterBy, columnKey, state: this.state, filter: this._filter });
          this.setState({ sortList: { ...this.state.sortList } }); // 筛选值未变化的时候也要更新 精确搜索状态
        }, 150)();
        this.props.syncFilter && this.props.syncFilter({ filter: this._filter, updateShow: true });
      } else {
        throttle(() => this._filterByString({ filterBy, columnKey, state: this.state, filter: this._filter }), 150)();
      }
    } else {
      throttle(() => {
        // this.props.syncFilter && this.props.syncFilter({ filter: { ...this._filter, [columnKey]: value }, updateShow: true })
        this._filterByString({ filterBy, columnKey, state: this.state, filter: this._filter });
      }, 150)();
    }
  };
  /* *********************** 更新store ******************************** */
  // updateType: ori show
  _updateCache = (rowIndex, columnKey, value, updateType = 'ori,show') => {
    if (updateType.indexOf('ori') !== -1 && !LISTSHOWSTORE_ADDROWS.includes(rowIndex)) {
      this.state.listStore.updateCache(rowIndex, columnKey, value); // 更新缓存数据
    }
    updateType.indexOf('show') !== -1 && this.state.listStore.updateShowCache(rowIndex, columnKey, value); // 更新显示缓存数据
  };
  // 外部调用的方法， 更新行数据，同时更新显示
  updateCacheRowWrap = (rowIndex, value) => {
    if (!LISTSHOWSTORE_ADDROWS.includes(rowIndex)) {
      this.state.listStore.updateCacheRow(rowIndex, value);
      this.state.listStore.updateShowCacheRow(rowIndex, this.getShowStoreRow(rowIndex, value));
    }
  };
  _updateCacheRow = (rowIndex, value, updateType = 'ori,show') => {
    if (updateType.indexOf('ori') !== -1 && !LISTSHOWSTORE_ADDROWS.includes(rowIndex)) {
      this.state.listStore.updateCacheRow(rowIndex, value); // 更新缓存数据
    }
    updateType.indexOf('show') !== -1 && this.state.listStore.updateShowCacheRow(rowIndex, value); // 更新显示缓存数据
  };
  // listStore 等数据更新后 更新并重新渲染合计
  _updateTotalRow = () => {
    // 重新计算 更新合计
    const footerRow = this.getFooterRowData(this.props, this.state, true, this.props.realTimeTotal);
    this._updateCacheRow('footerTotal', footerRow.footerTotal, 'show');
    this._updateCacheRow('footerCalculate', footerRow.footerCalculate, 'show');
    this.state.selectes.length > 0 && this._updateCacheRow('footerSelect', footerRow.footerSelect, 'show');
    this.setState({
      footerSelect: footerRow.footerSelect,
      footerTotal: footerRow.footerTotal,
      footerCalculate: footerRow.footerCalculate,
    });
  };
  /* *********************** 给选中行添加类名  ******************************** */
  handleRowClassNameGetter = rowIndex => {
    const size = this.state.listStore.getSize();
    const isLastRow = size - 1 === rowIndex;
    const bsCls = `cls-${rowIndex}-e`;
    if (this.state.selectes.includes(rowIndex) || this.state.selectAll) {
      return `selected-row${isLastRow ? ' last-row' : ''} ${bsCls}`;
    } else if (this.state.rowClickSelectes.includes(rowIndex)) {
      return `click-selected-row${isLastRow ? ' last-row' : ''} ${bsCls}`;
    } else if (isLastRow) {
      return `last-row ${bsCls}`;
    }
    return bsCls;
  };
  /* *********************** 改变列宽 ******************************** */
  handleColumnResizeEndCallback = (iColumnWidth, columnKey, callback) => {
    const { headerRelationship, columnWidths: oldColumnWidths, header } = this.state;
    const minColWidth = this.props.minColWidth !== undefined ? this.props.minColWidth : MIN_HEADER_WIDTH;
    const newColumnWidth = iColumnWidth < minColWidth ? minColWidth : iColumnWidth;
    const originWidth = oldColumnWidths[columnKey] || header[columnKey]?.width || TABLE_DEFAULT_WIDTH;
    const differenceValue = newColumnWidth - originWidth;
    const childrenKeys = headerRelationship[columnKey]?.children || [];
    const allParentsKeys = headerRelationship[columnKey]?.allParents || [];
    const childrenKeysLength = childrenKeys?.length;
    const updateKeys = {
      [columnKey]: newColumnWidth,
    };
    // 同步更新父级宽度
    function updateParentWidth(key, eachValue, childLength = 0) {
      const parentKey = headerRelationship[key]?.parent;
      if (parentKey && parentKey !== columnKey) {
        const oldParentWidth = oldColumnWidths[parentKey] || header[parentKey]?.width || TABLE_DEFAULT_WIDTH;
        const parentChildrenLength = headerRelationship[parentKey]?.children?.length || 0;
        const sumChildLength = childLength + parentChildrenLength;
        const grandParentKey = headerRelationship[parentKey]?.parent?.[0];
        updateKeys[parentKey] = oldParentWidth + eachValue * sumChildLength;
        if (grandParentKey) {
          updateParentWidth(parentKey, eachValue, sumChildLength);
        }
      }
    }
    if (childrenKeysLength) {
      const eachValue = differenceValue / childrenKeysLength;
      childrenKeys.forEach(key => {
        const oldWidth = oldColumnWidths[key] || header[key]?.width || TABLE_DEFAULT_WIDTH;
        updateParentWidth(key, eachValue);
        updateKeys[key] = oldWidth + eachValue;
      });
    }
    if (allParentsKeys.length) {
      allParentsKeys.forEach(key => {
        const oldWidth = oldColumnWidths[key] || header[key]?.width || TABLE_DEFAULT_WIDTH;
        updateKeys[key] = oldWidth + differenceValue;
      });
    }
    // const newColumnWidth = iColumnWidth < MIN_HEADER_WIDTH ? MIN_HEADER_WIDTH : iColumnWidth
    this.shouldUpdateHeight = true; // 列宽调整 高度需要重新计算
    this.isColumnResizing = true;
    this.setState(
      ({ columnWidths }) => ({
        columnWidths: {
          ...columnWidths,
          ...updateKeys,
        },
      }),
      callback,
    );
    this.props.hanldeAutoWidth && this.props.hanldeAutoWidth(columnKey, newColumnWidth);
    this.debounceSaveStyle();
  };
  /* *********************** 改变表格宽度 ******************************** */
  handleResize = (deltaPos, columnKey) => {
    const { x: deltaX = 0 } = deltaPos ?? {};
    const oldWidth = this.state.columnWidths?.[columnKey];
    if (!oldWidth) return; // 该列不存在，或者没有宽度，不能调整
    const iColumnWidth = oldWidth + deltaX;
    this.handleColumnResizeEndCallback(iColumnWidth < 0 ? 0 : iColumnWidth, columnKey, () => {
      const headerKeys = this.state.headerKeys ?? [];
      const columnIndex = headerKeys.findIndex(item => item === columnKey);
      this.scrollToColumn(columnIndex < 0 ? headerKeys.length - 1 : columnIndex);
    });
  };
  /* *********************** 表头拖拽 ******************************** */
  onColumnReorderEndCallback = () => {};
  /* *********************** 选中行和全选 ( 行点击事件) ******************************** */
  handleRowClick = (e, rowIndex) => {
    const { listStore } = this.state;
    clearTimeout(this.onRowClickTimeOutID);
    this.onRowClickTimeOutID = setTimeout(
      () => {
        if (!this.props.isRowSelect) return;
        // 选中是可视状态的索引处理 只有显示合计行才响应选中处理
        if (this.props.isRowClickSelect) {
          (this.props.isRowSelect || this.props.isShowTotalRow || this.props.isSelectColumn) &&
            this.handleSelect(rowIndex);
        } else {
          this.setState({ rowClickSelectes: [rowIndex] });
        }
        this.props.handleRowClick && this.props.handleRowClick(rowIndex, this);
      },
      this.props.isRowDbClick ? 50 : 0,
    );
  };
  // 获取单元格class
  getRangeSelectCellClass = (rowIndex, colIndex) => {
    const { rangeSelectCellClass } = this.state;
    return rangeSelectCellClass?.[rowIndex]?.[colIndex] ?? '';
  };
  _changeRangeSelection = selection => {
    const newState = Object.assign({}, this.state, { ...selection });

    window.cancelAnimationFrame(this.timer);
    const that = this;

    // 有新的值产生了，则重新计算。避免重复计算，用 RAF 包裹下
    this.timer = window.requestAnimationFrame(() => {
      const { currentSelected, rangeSelected } = that.state;
      const rangeSelectCellClass = {};
      eachRangeSelection(rangeSelected, ({ leftTopX, rightBottomX, leftTopY, rightBottomY }) => {
        // 行
        for (let v = leftTopX; v <= rightBottomX; v++) {
          // 列
          for (let h = leftTopY; h <= rightBottomY; h++) {
            const cls = ['range-selected'];
            if (currentSelected && v === currentSelected[0] && h === currentSelected[1])
              cls.push('is-current-selected');
            if (v === leftTopX) cls.push('border-top');
            if (v === rightBottomX) cls.push('border-bottom');
            if (h === leftTopY) cls.push('border-left');
            if (h === rightBottomY) cls.push('border-right');
            rangeSelectCellClass[v] = rangeSelectCellClass[v] ?? {};
            rangeSelectCellClass[v][h] = cls;
          }
        }
      });
      that.setState({ rangeSelectCellClass });
    });

    // 此处频繁触发的 setState 交给 react 去管理
    this.setState(
      old => ({
        ...old,
        ...newState,
      }),
      () => this.props.onRangeSelection?.(this.state.rangeSelected, this.state.currentSelected),
    );
  };
  onRowDoubleClick = (e, rowIndex) => {
    const target = isset(e, 'target', {});
    if (target.dataset.ctype !== 'checkbox') clearTimeout(this.onRowClickTimeOutID);
    // 双击操作屏蔽 input  和  link
    if (target.nodeName === 'INPUT' || target.nodeName === 'A') return;
    if (!this.props.isRowDbClick) return;

    // 双击选中行
    if (this.props.rangeSelectable) {
      const { rangeSelected = [] } = this.state;
      // 暂时不支持 按住ctrl多选行
      // const ctrlKey = e?.ctrlKey || e?.metaKey || false;
      // if (!ctrlKey)
      rangeSelected.length = 0; // 没有按住ctrl键，则清空之前的选择
      const { header, headerKeys, hideColumn = [], deleteColumn = [] } = this.state;

      // 当前显示的列
      const showColumns =
        headerKeys.filter?.(columnKey => {
          // 隐藏列、删除列
          if (
            hideColumn.includes(columnKey) ||
            deleteColumn.includes(columnKey) ||
            (header[columnKey] && header[columnKey].innerShow === 'show')
          )
            return false;
          return true;
        }) ?? [];

      const colIndex = showColumns.length - 1;
      if (rowIndex || rowIndex === 0) {
        // 选中整列
        rangeSelected.push([
          [rowIndex, 0], // 范围选择起点
          [rowIndex, colIndex], // 范围选择终点
        ]);

        this._changeRangeSelection({
          mouseStart: null,
          currentSelected: [rowIndex, 0],
          rangeSelected: [...rangeSelected],
        });
      }
    }
    this.props.handleRowDbClick &&
      this.props.handleRowDbClick(rowIndex, this, this.state.listStore.getObjectAt(rowIndex));
  };
  /** 表头双击 */
  handleHeaderDoubleClick = (e, columnKey, columnProps = {}, value) => {
    if (!this.props.isHeaderDbClick) return;
    // 上级表头选中列
    if (this.props.rangeSelectable) {
      const { rangeSelected = [], data } = this.state;
      // 暂时不支持 按住ctrl多选列
      // const ctrlKey = e?.ctrlKey || e?.metaKey || false;
      // if (!ctrlKey)
      rangeSelected.length = 0; // 没有按住ctrl键，则清空之前的选择
      const { colIndex } = columnProps;
      const rowIndex = data.length - 1;
      if (colIndex || colIndex === 0) {
        // 选中整列
        rangeSelected.push([
          [0, colIndex], // 范围选择起点
          [rowIndex, colIndex], // 范围选择终点
        ]);
        this._changeRangeSelection({
          mouseStart: null,
          currentSelected: [0, colIndex],
          rangeSelected: [...rangeSelected],
        });
      }
    }
    this.props.handleHeaderDoubleClick?.(e, columnKey, columnProps, value);
  };
  // 单元格事件
  onCellMouseDown = (e, rowIndex, colIndex) => {
    // @see https://developer.mozilla.org/zh-CN/docs/Web/API/MouseEvent/button
    if (e?.button !== 0) return; // 只启用鼠标左键事件，不兼容 IE8
    const { rangeSelected = [] } = this.state;
    const ctrlKey = e?.ctrlKey || e?.metaKey || false;
    if (!ctrlKey) rangeSelected.length = 0; // 没有按住ctrl键，则清空之前的选择
    const mouseStart = [rowIndex, colIndex];
    // TODO: 去重
    rangeSelected.push([
      mouseStart, // 范围选择起点
      mouseStart, // 范围选择终点
    ]);

    this._changeRangeSelection({
      mouseStart, // 用来标识鼠标拖动的起点
      currentSelected: mouseStart,
      rangeSelected: [...rangeSelected],
    });
  };
  onCellMouseOver = (e, rowIndex, colIndex) => {
    const { mouseStart, rangeSelected = [] } = this.state;
    const isSameStartCell = mouseStart ? rowIndex === mouseStart[0] && colIndex === mouseStart[1] : false;

    if (mouseStart && !isSameStartCell && rangeSelected.length) {
      const mouseEnd = [rowIndex, colIndex];
      rangeSelected[rangeSelected.length - 1] = [mouseStart, mouseEnd];
      this._changeRangeSelection({
        rangeSelected: [...rangeSelected],
      });
    }
  };
  onCellMouseUp = (e, rowIndex, colIndex) => {
    this._changeRangeSelection({
      mouseStart: null,
    });
  };
  // 取消选择
  clearRangeSelect = () => {
    this._changeRangeSelection({
      rangeSelected: [],
      currentSelected: [],
      mouseStart: null,
    });
  };
  // onScrollStart = () => {
  //   this.state.tableScrolling !== true && this.setState({ tableScrolling: true })
  // }
  // onScrollEnd = () => {
  //   this.state.tableScrolling !== false && this.setState({ tableScrolling: false })
  // }
  /* *********************** CheckBox列事件 ******************************** */
  updateCheckboxCache = (rowIndex, columnKey, checked, isCheckBoxText) => {
    const oldRowData = this.state.listStore.getObjectAt(rowIndex);
    const oldRowShowData = this.state.listStore.getShowObjectAt(rowIndex) || {};
    // 禁用的 不做修改
    if (isInOtherPropsArray(oldRowData, columnKey, 'disable')) return false; // 返回值 作为改行列是否要勾选的结果
    // TODO 勾选 输入等的 显示数据 和 原始数据 需要区分更新
    // 保证 原始数据是未经过format格式化的
    this.showingRows[rowIndex] = { [columnKey]: !this.showingRows[rowIndex] };
    const oriValue = formatDeppProptotype(columnKey, oldRowData);
    let newOriValue = checked;
    isCheckBoxText &&
      (newOriValue = typeIs(oriValue, 'object') ? { text: '', ...oriValue, checked } : { text: oriValue, checked });
    this._updateCache(rowIndex, columnKey, newOriValue, 'ori');
    this._updateCache(rowIndex, columnKey, { ...oldRowShowData[columnKey], checked }, 'show');
    return checked;
  };
  // TODO 可缓存
  getCheckBoxTextCol = (columnKey, onlyShowingData = true) => {
    const colCheckBoxText = [];
    const allRows = this.getStateCache(!onlyShowingData);
    allRows.forEach(rowData => {
      if (!isInOtherPropsArray(rowData, columnKey, 'disable')) {
        colCheckBoxText.push(rowData[columnKey]);
      }
    });
    return colCheckBoxText;
  };
  // TODO key 是否还有用
  handleCheckBoxClick = (rowIndex, columnKey, checked, key) => {
    const oldRowData = this.state.listStore.getObjectAt(rowIndex);
    // , oldRowShowData = this.state.listStore.getShowObjectAt(rowIndex) || {}
    const headerCol = this.state.header[columnKey] || {};
    const columnType = headerCol.type;
    // TODO 勾选 输入等的 显示数据 和 原始数据 需要区分更新
    // 保证 原始数据是未经过format格式化的
    // 默认设定: disable的勾选列 不能进入到此，可不做是否disable判断
    if (columnType === 'CheckBoxText') {
      // TODO 与resetData中处理同部位统一方法
      this.updateCheckboxCache(rowIndex, columnKey, checked, true);
      const checkBoxTextSelects = this.state.checkBoxTextSelects || {};
      const footerChecked = { ...this.state.footerChecked }; // 新的对象 保证setState后能更新组件
      const colCheckBoxTextSelects = checkBoxTextSelects[columnKey] || [];
      // 行选中处理
      const index = colCheckBoxTextSelects.indexOf(rowIndex);
      if (checked && index === -1) {
        // 新选中的 // 行单选时 只能有一个选中项
        colCheckBoxTextSelects.push(rowIndex);
      } else if (!checked && index !== -1) {
        // 已选中的
        colCheckBoxTextSelects.splice(index, 1);
      }
      // 底部合计选中处理
      if (checked === false && footerChecked[columnKey] === true) {
        footerChecked[columnKey] = false;
      } else if (
        checked === true &&
        !footerChecked[columnKey] &&
        colCheckBoxTextSelects.length === this.getCheckBoxTextCol(columnKey).length
      ) {
        // 当前已勾选数 === 全部可操作的勾选框个数
        footerChecked[columnKey] = true;
      }
      checkBoxTextSelects[columnKey] = colCheckBoxTextSelects;
      this.setState({ footerChecked, checkBoxTextSelects });
      this.props.handleChange && this.props.handleChange(rowIndex, columnKey, checked, oldRowData, key, headerCol);
    } else if (columnKey === 'checkbox') {
      // 勾选列 禁用行勾选时， 点击勾选框可勾选
      !this.props.isRowClickSelect && this.handleSelect(rowIndex);
      this.updateCheckboxCache(rowIndex, columnKey, checked, false);
      this.props.handleChange && this.props.handleChange(rowIndex, columnKey, checked, oldRowData, key, headerCol);
    } else {
      // 只有checkbox的 // 系统设置使用 判断数据是否变更
      this.updateCheckboxCache(rowIndex, columnKey, checked, false);
      this.props.handleChange && this.props.handleChange(rowIndex, columnKey, checked, oldRowData, key, headerCol);
      // 当表头配置选择框时，控制是否全选
      if (headerCol.checkable) {
        const _data = this.state.listStore.getAll();
        const { data, header } = this.state;
        const isSelectAll = _data
          .filter(item => !isInOtherPropsArray(item, columnKey, 'disable'))
          .every(item => (_.isObject(item[columnKey]) ? item[columnKey]?.checked : item[columnKey]));
        this._updateCache('headerTitle', 'columnKey', isSelectAll, 'show');
        this.state.listStore.updateShowCache('headerTitle', columnKey, {
          title: headerCol.title,
          checked: isSelectAll,
        });
        this.setState({ data: [...data], header: { ...header } });
      }
    }
  };
  /* *********************** 合计行 ******************************** */
  handleFooterCheckBoxTextClick = (columnKey, checked) => {
    const dataAllKey = range(this.state.listStore.getSize());
    const checkBoxTextSelects = this.state.checkBoxTextSelects || {};
    const footerChecked = { ...this.state.footerChecked }; // 新的对象 保证setState后能更新组件
    // checkBoxTextSelects[columnKey] = checked ? dataAllKey : []
    checkBoxTextSelects[columnKey] = []; // 需要根据是否disable判断
    footerChecked[columnKey] = checked;
    dataAllKey.forEach(rowIndex => {
      if (this.updateCheckboxCache(rowIndex, columnKey, checked, true)) checkBoxTextSelects[columnKey].push(rowIndex);
      this.showingRows[rowIndex] && (this.showingRows[rowIndex].checkbox = !this.showingRows[rowIndex].checkbox);
    });
    this.setState({ footerChecked, checkBoxTextSelects });
  };
  handleSelect = (rowIndex, forceTrue = false) => {
    let selectes = [...this.state.selectes];
    let rowSelectStatus = true;
    let originSelectes = [...this.state.originSelectes];
    const index = selectes.indexOf(rowIndex);
    const { listStore } = this.state;
    if (forceTrue === true) {
      // 只处理勾选的情况
      if (index !== -1) return;
      selectes.push(rowIndex);
      if (listStore._indexMap[rowIndex] !== undefined) {
        originSelectes.push(listStore._indexMap[rowIndex]);
      }
      rowSelectStatus = true;
    } else if (index === -1) {
      // 新选中的 // 行单选时 只能有一个选中项
      selectes = this.props.selectSingleRow ? [rowIndex] : [...selectes, rowIndex];
      if (listStore._indexMap[rowIndex] !== undefined) {
        originSelectes = this.props.selectSingleRow
          ? [listStore._indexMap[rowIndex]]
          : [...originSelectes, listStore._indexMap[rowIndex]];
      }
      rowSelectStatus = true;
    } else {
      // 已选中的
      selectes.splice(index, 1);
      if (listStore._indexMap[rowIndex] !== undefined) {
        const originSelectIndex = originSelectes.indexOf(listStore._indexMap[rowIndex]);
        originSelectes.splice(originSelectIndex, 1);
      }
      rowSelectStatus = false;
    }
    const isSelectAll = selectes.length === this.state.listStore.getSize();
    this._updateCache(rowIndex, 'checkbox', { checked: rowSelectStatus }, 'show');
    this.showingRows[rowIndex] && (this.showingRows[rowIndex].checkbox = !this.showingRows[rowIndex].checkbox);
    this._updateCache('headerTitle', 'checkbox', isSelectAll, 'show');
    // 处理底部选中显示
    const footerRow = this.getFooterRowData(this.props, { ...this.state, selectes });
    selectes.length > 0 && this._updateCacheRow('footerSelect', footerRow.footerSelect, 'show');
    const selectState = { selectes, selectAll: isSelectAll, originSelectes };
    if (this.props.isRowClickSelect && this.state.rowClickSelectes.length > 0) {
      selectState.rowClickSelectes = [];
    }
    this.setState(selectState);
    if (this.props.handleSelectRow) {
      this.props.handleSelectRow(selectes);
    }
  };
  handleSelectAll = defaultSelectAll => {
    const dataAllKey = range(this.state.listStore.getSize());
    let selectAll = !this.state.selectAll;
    const { listStore } = this.state;
    defaultSelectAll !== undefined && (selectAll = defaultSelectAll);
    const selectes = selectAll ? dataAllKey : [];
    // 原始的index
    let originSelectes = [];
    const newSelectIndex = dataAllKey.map(x => listStore._indexMap[x]);
    if (selectAll && listStore._indexMap) {
      originSelectes = [...new Set([...this.state.originSelectes, ...newSelectIndex])];
    }
    if (!selectAll && listStore._indexMap) {
      originSelectes = this.state.originSelectes.filter(x => newSelectIndex.indexOf(x) === -1);
    }
    // 有参数的用参数的全选状态
    dataAllKey.forEach(rowIndex => {
      this._updateCache(rowIndex, 'checkbox', { checked: selectAll }, 'show'); // 更新缓存数据
      this.showingRows[rowIndex] && (this.showingRows[rowIndex].checkbox = !this.showingRows[rowIndex].checkbox);
    });
    this.state.listStore.updateShowCache('headerTitle', 'checkbox', selectAll);
    if (selectAll) {
      // 全选时 才显示、更新底部
      this._updateCacheRow(
        'footerSelect',
        this.getFooterRowData(this.props, { ...this.state, selectes }).footerSelect,
        'show',
      );
    }
    this.setState({ selectAll, selectes, originSelectes });
    this.props.handleSelectAllRow && this.props.handleSelectAllRow(selectes);
  };
  /* *********************** 自定义列事件 ******************************** */
  handleChange = (rowIndex, columnKey, value) => {
    const oldRowData = this.state.listStore.getObjectAt(rowIndex);
    this._updateCache(rowIndex, columnKey, value); // 更新缓存数据
    const headerCol = this.state.header[columnKey] || {};
    if (headerCol.summable) {
      // 重新计算 更新合计
      const footerRow = this.getFooterRowData(this.props, this.state, true);
      this._updateCacheRow('footerTotal', footerRow.footerTotal, 'show');
      this._updateCacheRow('footerCalculate', footerRow.footerCalculate, 'show');
      this.state.selectes.length > 0 && this._updateCacheRow('footerSelect', footerRow.footerSelect, 'show');
      this.setState({
        footerSelect: footerRow.footerSelect,
        footerTotal: footerRow.footerTotal,
        footerCalculate: footerRow.footerCalculate,
      });
    }
    this.props.handleChange && this.props.handleChange(rowIndex, columnKey, value, oldRowData, headerCol);
    // if (this.props.dataUpdater) {
    //   this.props.dataUpdater({ rowIndex, columnKey, value, that: this })
    // } else {
    //   let oldRowData = this.state.listStore.getObjectAt(rowIndex)
    //   this._updateCache(rowIndex, columnKey, value) // 更新缓存数据
    //   let headerCol = this.state.header[columnKey] || {}
    //   if (headerCol.summable) {
    //     // 重新计算 更新合计
    //     let footerRow = this.getFooterRowData(this.props, this.state, true)
    //     this._updateCacheRow('footerTotal', footerRow.footerTotal, 'show')
    //     this.state.selectes.length > 0 && this._updateCacheRow('footerSelect', footerRow.footerSelect, 'show')
    //     this.setState({ footerSelect: footerRow.footerSelect, footerTotal: footerRow.footerTotal })
    //   }
    //   this.props.handleChange && this.props.handleChange(rowIndex, columnKey, value, oldRowData, headerCol)
    // }
  };
  // 可输入单元格 blur
  handleBlur = (rowIndex, columnKey, value) => {
    if (typeof this.props.handleBlur === 'function') {
      const oldRowData = this.state.listStore.getObjectAt(rowIndex);
      this.props.handleBlur(rowIndex, columnKey, value, oldRowData);
    }
    // TODO 使table 刷新 更新底部选中合计 移到 handlechange里
    // if (this.props.isShowTotalRow) {
    //   let selectes = this.state.selectes
    //   let footerRow = this.getFooterRowData(this.props, this.state)
    //   this._updateCacheRow('footerTotal', footerRow.footerTotal, 'show')
    //   this.state.selectes.indexOf(rowIndex) !== -1 && this.setState({ selectes })
    // }
  };
  handleSelectDropBlur = (rowIndex, columnKey, value) => {
    if (typeof this.props.handleSelectDropBlur === 'function') {
      const oldRowData = this.state.listStore.getObjectAt(rowIndex);
      this.props.handleSelectDropBlur(rowIndex, columnKey, value, oldRowData);
    }
  };

  handleSelectFocus = (rowIndex, columnKey, e) => {
    this.props.handleFocus && this.props.handleFocus(rowIndex, columnKey);
  };

  handleClick = (rowIndex, columnKey) => {
    console.log(`rowIndex: ${rowIndex}, columnKey: ${columnKey}`);
  };
  handleRadioChange = (rowIndex, columnKey, value) => {
    this.state.listStore._cache.forEach((item, index) => {
      this.state.listStore._cache[index][columnKey] = index === rowIndex && value;
    });
    const oldRowData = this.state.listStore.getObjectAt(rowIndex);
    this.props.handleChange &&
      this.props.handleChange(rowIndex, columnKey, value, oldRowData, this.state.listStore._cache);
  };
  /* *********************** 表格更新相关 ****************************** */
  /* *********************** 操作列事件 ******************************** */
  handleOperateClick = (rowIndex, key, columnKey, e) => {
    e.stopPropagation();
    if (dataAnalyticTrackMap?.[key]) {
      dataAnalyticTrack([
        dataAnalyticTrackMap[key]?.eventCategory,
        dataAnalyticTrackMap[key]?.eventAction,
        dataAnalyticTrackMap[key]?.eventName,
      ]);
    }
    const data = this.state.listStore.getObjectAt(rowIndex);
    this.props.handleOperateClick && this.props.handleOperateClick(rowIndex, key, columnKey, data);
  };
  /* *********************** 行数据 右键菜单******************************** */
  rowContextMenu = (e, columnKey, rowIndex) => {
    if (this.props.isShowRowContextMenu) {
      e.preventDefault();
      this.rightClickInfo = { columnKey, rowIndex };
      this.contextMenu.handleShow(
        this.rowRightMenu(columnKey, rowIndex),
        e.pageX,
        e.pageY,
        TABLE_ROW_RIGHTMENU_SIZE.width,
        TABLE_ROW_RIGHTMENU_SIZE.height,
        { columnKey, rowIndex, shouldMarkItem: false, isShowSearch: false },
      );
    }
    // 行点击不可选中时， 右键点击也出点选色
    if (!this.props.isRowClickSelect) {
      this.setState({ rowClickSelectes: [rowIndex] });
    }
  };
  /* *********************** 行数据 右键菜单 ******************************** */
  /* *********************** 表头右击功能 ******************************** */
  headerContextMenu = (e, columnKey, rowIndex, colIndex) => {
    // rowIndex 外部调用时用
    if (this.props.isShowContextMenu && !this.props.isGroupHeader) {
      e.preventDefault();
      this.rightClickInfo = { columnKey, rowIndex };
      this.contextMenu.handleShow(
        this.headerRightMenu(columnKey, rowIndex, colIndex),
        e.pageX,
        e.pageY,
        TABLE_HEADER_RIGHTMENU_SIZE.width,
        TABLE_HEADER_RIGHTMENU_SIZE.height,
        { columnKey, shouldMarkItem: false, isShowSearch: true },
      );
    }
  };
  /* *********************** 表头右击功能 ******************************** */
  /* *********************** 表头快速筛选功能 ******************************** */
  headerQuickFilterMenu = (e, columnKey, rowIndex) => {
    e.preventDefault();
    e.stopPropagation();
    this.headerCellRef[columnKey] && addClass(this.headerCellRef[columnKey], 'quick-filter-active');
    this.contextMenu.handleShow(
      this.headerQuickFilter(columnKey, rowIndex),
      e.pageX,
      e.pageY,
      TABLE_HEADER_QUICKFILTER_SIZE.width,
      TABLE_HEADER_QUICKFILTER_SIZE.height,
      {
        columnKey,
        clickObj: e.target,
        shouldMarkItem: true,
        isShowSearch: false,
        defaultSelected: [{ key: this.currQuickFilter[columnKey] || 'quickFilterAll' }],
      },
    );
  };
  quickFilterCustomizeHandle = prop => {
    const { columnKey, key, rowIndex } = prop;
    const header = this.state.header || {};
    const headerCol = header[columnKey] || {};
    const uniqueKey = headerCol.uniqueKey || 'key';
    const showKey = headerCol.showKey || 'name';
    const enumerations = this.state.enumerations || {};
    const { headerFilterKeyMap = {} } = this.props;
    // 移除当前字段的 空值、非空筛选
    new PopUp(QuickFilterDialog, {
      popName: this.props.tableKey,
      columnProps: headerCol,
      dialogFormValue: this.currQuickFilter[`${columnKey}__dialogFormValue`],
      extData: {
        header,
        enumeration: enumerations[headerCol.refEnum],
        customizesingleFilter: this.props.customizesingleFilter,
        filterValues: this._filter,
      },
      handleConfirm: ({ customizeFilters, dialogFormValue }) => {
        this.currQuickFilter[columnKey] = key;
        this.currQuickFilter[`${columnKey}__dialogFormValue`] = dialogFormValue;
        const emptyVal = '';
        const qf = { [columnKey]: '' };
        // 所有的自定义筛选都放到logic下
        const _logic = this._filter._logic || [];
        const mapedKey = headerFilterKeyMap[columnKey];
        let logicFilters = _logic.filter(item =>
          mapedKey ? item.key !== mapedKey && item.key !== columnKey : item.key !== columnKey,
        );
        logicFilters = [...logicFilters, ...customizeFilters];
        this._filter = { ...this._filter, ...qf, _logic: logicFilters };
        this._updateCache('headerFilter', columnKey, emptyVal, 'show');
        // front_only 逻辑
        if (this.isFrontFilter(headerCol)) {
          const logic = { [dialogFormValue.logic1]: [dialogFormValue.op1, dialogFormValue.op2] };
          let filterBy = [dialogFormValue.value1, dialogFormValue.value2];
          if (dialogFormValue.extProps.selectedEnum) {
            const enums = {};
            dialogFormValue.extProps.selectedEnum.forEach(
              item =>
                (enums[item[uniqueKey]] =
                  item[FILTER_DIFF_SHOW_KEY] !== undefined ? item[FILTER_DIFF_SHOW_KEY] : item[showKey] || ''),
            );
            const v1 = dialogFormValue.value1 || [];
            const v2 = dialogFormValue.value2 || [];
            filterBy = [v1.map(v => enums[v]), v2.map(v => enums[v])];
          }
          this._filterByString({ filterBy, logic, columnKey, state: this.state, filter: this._filter });
        } else {
          this.props.handleQuickFilter && this.props.handleQuickFilter(columnKey, rowIndex, key, this._filter);
        }
      },
      handleClearn: () => {},
      handleCancel: () => {},
    }).show();
  };
  quickFilterHandler = (columnKey, rowIndex, key) => {
    if (key === 'quickFilterCustomize') {
      this.quickFilterCustomizeHandle({ columnKey, rowIndex, key });
      return;
    }
    // 自定义可以反复设置
    if (this.currQuickFilter[columnKey] === key && key !== 'quickFilterCustomize') return;
    const header = this.state.header || {};
    const headerCol = header[columnKey] || {};
    const useDefEmptyValue = headerCol.empty_value === null || headerCol.empty_value === undefined;
    const emptyVal = HEADER_TYPE_TO_EMPTY[headerCol.columnType] || '';
    const logicEmptyVal = useDefEmptyValue ? EMPTY_VAL_MAP[emptyVal] : headerCol.empty_value;
    const _logic = this._filter._logic || [];
    const { headerFilterKeyMap = {} } = this.props;
    const mapedKey = headerFilterKeyMap[columnKey];
    const logicFilters = _logic.filter(item =>
      mapedKey ? item.key !== mapedKey && item.key !== columnKey : item.key !== columnKey,
    );
    let notEmptyVal = '';
    let qf;
    // 移除当前字段的 空值、非空筛选,(另需清除正常筛选值和自定义筛选值)
    const filterType = getFilterType(headerCol, this.state.enumerations);
    delete this.currQuickFilter[`${columnKey}__dialogFormValue`];
    switch (key) {
      case 'quickFilterEmpty': {
        qf = { [columnKey]: emptyVal };
        logicFilters.push({
          key: columnKey,
          qfLogicType: 'empty',
          _logic: 'or',
          [columnKey]: logicEmptyVal,
          excludeExist: !(filterType !== 'SelectDrop' || useDefEmptyValue),
        });
        break;
      }
      case 'quickFilterNotEmpty': {
        qf = { [columnKey]: emptyVal };
        notEmptyVal = HEADER_TYPE_TO_NOT_EMPTY[headerCol.columnType] || '';
        logicFilters.push({
          key: columnKey,
          qfLogicType: 'notEmpty',
          _logic: 'not',
          [columnKey]: logicEmptyVal,
          // 有特殊空值的下拉不传exist
          excludeExist: filterType === 'SelectDrop' && !useDefEmptyValue,
        });
        break;
      }
      default: {
        qf = { [columnKey]: '' };
        break;
      }
    }
    this.currQuickFilter[columnKey] = key;
    this._filter = { ...this._filter, ...qf, _logic: logicFilters };
    this._updateCache('headerFilter', columnKey, emptyVal, 'show');
    // front_only 逻辑
    if (this.isFrontFilter(headerCol)) {
      this._filterByString({
        filterBy: notEmptyVal || this._filter[columnKey],
        columnKey,
        state: this.state,
        filter: this._filter,
      });
      this.setState({ sortList: { ...this.state.sortList } }); // 筛选值未变化的时候也要更新 精确搜索状态
    } else {
      this.props.handleQuickFilter && this.props.handleQuickFilter(columnKey, rowIndex, key, this._filter);
    }
  };
  scrollToRowColumn = (index, column, cb) => {
    if (!typeIs(index, 'number') || !typeIs(column, 'number')) return;
    this.shouldScrollColunm = true;
    this.shouldScrollRow = true;
    this.setState(
      {
        scrollToColumn: column,
        scrollToRow: index,
      },
      () => {
        this.shouldScrollColunm = false;
        this.shouldScrollRow = false;
        cb && cb();
      },
    );
  };
  scrollToColumn = (i, cb) => {
    if (!typeIs(i, 'number')) return;
    this.shouldScrollColunm = true;
    this.setState({ scrollToColumn: i }, () => {
      setTimeout(() => {
        this.shouldScrollColunm = false;
        cb && cb();
      }, 0);
    });
  };
  scrollToRow = (i, cb) => {
    if (!typeIs(i, 'number')) return;
    this.shouldScrollRow = true;
    this.setState({ scrollToRow: i }, () => {
      setTimeout(() => {
        this.shouldScrollRow = false;
        cb && cb();
      }, 0);
    });
  };
  handleSearchClick = (e, searchColumn) => {
    const { header } = this.state;
    const headerKeys = Object.keys(header);
    for (let i = 0, len = headerKeys.length; i < len; i++) {
      if (header[headerKeys[i]].title === searchColumn) {
        this.scrollToColumn(i);
        break;
      }
    }
  };
  handleSearchChange = e => {
    this.searchColumn = e.target.value;
  };
  onContextMenuHide = (clickInfo = {}) => {
    // 隐藏右键实线框
    const { tableKey } = this.props;
    if (this.props.isPopCopyOn) {
      const cp = window.coyperStatus[tableKey] || {};
      Object.keys(cp.rightMenuShow || {}).forEach(k => {
        cp.rightMenuShow[k] = false;
        cp.refresher && cp.refresher[k] && cp.refresher[k]();
      });
    }
    // 隐藏右键实线框
    if (this.props.enableQuickFilter) {
      const columnKey = clickInfo.columnKey || '';
      if (columnKey && clickInfo.shouldMarkItem && this.headerCellRef[columnKey]) {
        delClass(this.headerCellRef[columnKey], 'quick-filter-active');
      }
    }
  };
  /* *********************** 表头快速筛选功能 ******************************** */
  // 删除列 TODO 未使用
  handleDeleteColumn = columnKey => {
    this.setState({ deleteColumn: [...this.state.deleteColumn, columnKey] });
  };
  handleHideColumn = columnKey => {
    this.setState({ hideColumn: [...this.state.hideColumn, columnKey] });
    // if (typeof this.props.hanldeAutoWidth === 'function') this.props.hanldeAutoWidth()
  };
  handleShowColumn = () => {
    this.setState({ hideColumn: [] });
    // if (typeof this.props.hanldeAutoWidth === 'function') this.props.hanldeAutoWidth()
  };
  handleAutoWith = columnKey => {
    console.log('handleAutoWith', columnKey);
    const { title } = this.state.header[columnKey] || { title: '' };
    const fontSize = this.getFontSize();
    let len = getStrWidth(title, fontSize);
    if (this.props.customizeCellAutoWidthFuncMap && this.props.customizeCellAutoWidthFuncMap[columnKey]) {
      let maxValue = '';
      let addWidth = 0;
      this.state.data.forEach(item => {
        const { formatValue: customFormatValue, extraWidth } =
          this.props.customizeCellAutoWidthFuncMap[columnKey](item);
        addWidth = extraWidth;
        if (customFormatValue.length > maxValue.length) {
          maxValue = customFormatValue;
        }
      });
      const _len = getStrWidth(maxValue, fontSize) + addWidth;
      if (_len > len && !Number.isNaN(_len)) {
        len = _len;
      }
    } else {
      this.state.data.forEach(item => {
        const value = this.dataFormater(
          formatValue(columnKey, item, this.state.enumerations || {}, null, null, this.state.header[columnKey]) || '',
          this.state.header[columnKey].format,
        );
        const _len = getStrWidth(value, fontSize);
        if (_len > len && !Number.isNaN(_len)) {
          len = _len;
        }
      });
    }
    this.handleColumnResizeEndCallback(len + 35, columnKey);
    // this.setState({ columnWidths: { ...this.state.columnWidths, [columnKey]: len + 30 } });
    if (typeof this.props.hanldeAutoWidth === 'function') this.props.hanldeAutoWidth(columnKey, len + 30);
  };
  handleResetTable = () => {
    this.setState({ hideColumn: [], deleteColumn: [] });
  };
  handleFreezeColumn = columnKey => {
    /** 冻结 解冻 * */
    const { header, headerKeys } = this.state;
    const colIndex = headerKeys.indexOf(columnKey);
    const isSticky = colIndex > this.state.stickyIndex;
    const handleKeys = isSticky ? headerKeys.slice(0, colIndex + 1) : headerKeys; // headerKeys.slice(colIndex)
    const specialColKeys = Object.keys(this.specialColumn);
    if (header[columnKey]) {
      handleKeys.forEach(key => {
        if (!specialColKeys.includes(key)) {
          // 特殊列 不可变更
          header[key].sticky = isSticky;
        }
      });
    }
    const stickyIndex = isSticky ? colIndex : specialColKeys.length - 1;
    this.setState({ header: { ...header }, stickyIndex });
    this.debounceSaveStyle();
  };
  handleFreezeRightColumn = columnKey => {
    /** 冻结 解冻 * */
    const { header } = this.state;
    const isRightSticky = header[columnKey]?.stickyRight;
    const specialColKeys = Object.keys(this.specialColumn);
    if (!specialColKeys.includes(columnKey)) {
      // 特殊列 不可变更
      header[columnKey].stickyRight = !isRightSticky;
    }
    this.setState({ header: { ...header } });
    this.debounceSaveStyle();
  };
  /* *********************** 特殊列SelectDrop事件 ******************************** */
  /* Ccombiner 中 icon 的回调也会走这里 */
  handleSpecialSelected = (rowIndex, columnKey, value, key, oldRow, ...rest) => {
    // 返回信息中添加修改前数据
    const oldRowData = oldRow || this.state.listStore.getObjectAt(rowIndex);
    const { header } = this.state;
    const headerCol = header[columnKey] || {};
    // this._updateForm(rowIndex, columnKey, value)
    // 高能预警： 此处的updateCache会存在问题
    this._updateCache(rowIndex, columnKey, value);
    // 为了中转费支持光标路径， 放开了change事件，change事件不触发外部事件
    rest[rest.length - 1] !== 'onChange' &&
      this.props.handleSelectDropSelect &&
      this.props.handleSelectDropSelect(rowIndex, columnKey, value, oldRowData, key, headerCol, ...rest);
    this.props.onSelectChange && this.props.onSelectChange(rowIndex, columnKey, value, key);
  };
  // 自定义单元格事件
  handleCustomizeEvent = prop => {
    // 返回信息中添加修改前数据
    const { rowIndex, columnKey, value, valColumnKey } = prop;
    const oldRowData = this.state.listStore.getObjectAt(rowIndex);
    const { header } = this.state;
    const headerCol = header[columnKey] || {};
    this._updateCache(rowIndex, valColumnKey, value);
    this.props.handleCustomizeEvent &&
      this.props.handleCustomizeEvent(rowIndex, valColumnKey, value, oldRowData, columnKey, headerCol);
  };
  /* *********************** 表头功能 ******************************** */
  /*                         ********                                  */
  handleHeaderClick = (e, columnKey, columnProps = {}, value) => {
    const extColumnKey = /\._exact_/.test(columnKey) ? '_exact_' : columnKey;
    switch (extColumnKey) {
      case 'reference':
        // 显示图标 且显示筛选行
        this.props.isShowFilterRowIcon && this.props.isShowFilterRow && this.handleIsFilterClick();
        break;
      case 'checkbox':
        // 有该列 就可响应勾选
        this.handleSelectAll();
        break;
      case 'operate':
        break;
      case 'substract':
        // 有该列 就可响应勾选
        this.handleTodoAdd(e);
        break;
      case '_exact_': {
        // 精确搜索勾选
        this._filter = { ...this._filter, [columnKey]: value };
        this._updateCache('headerFilter', columnKey, value, 'show');
        const filterKey = columnKey.replace('._exact_', '');
        let filterBy = this._filter[filterKey] || '';
        filterBy = `${filterBy}`.trim();
        this._filterByString(
          { filterBy, columnKey: filterKey, state: this.state, filter: this._filter },
          undefined,
          false,
        );
        this.props.syncFilter && this.props.syncFilter({ filter: this._filter, updateShow: true });
        break;
      }
      default:
        columnProps.isShowSort && columnProps.sortable && this.handleHeaderSortable(columnKey);
        break;
    }
    // checkbox 特殊处理
    if (columnProps.type === 'CheckBox' && extColumnKey !== 'checkbox') {
      const { header, data } = this.state;
      this.state.listStore.updateShowCache('headerTitle', columnKey, {
        title: header[columnKey].title,
        checked: value,
      });

      data.forEach((item, index) => {
        if (!item?.otherProps?.disable?.includes(columnKey)) {
          // columnProps.checkable 判断应加在上一层判断，如果在这里的话，系统设置->消息设置->通知设置里面点击接受通知会出现怪异行为
          if (columnProps.checkable) {
            this.updateCheckboxCache(index, columnKey, value, false);
          } else {
            this._updateCache(index, columnKey, { checked: value }, 'show');
          }
        }
      });
      this.setState({ data: [...data], header: { ...header } });
      this.props.handleHeaderCheck && this.props.handleHeaderCheck(e, columnKey, columnProps, value);
    }
  };
  /* *********************** 表头筛选行显示切换 ******************************** */
  handleIsFilterClick = () => {
    this.setState(
      {
        isShowFilterRow: !this.state.isShowFilterRow,
        headerHeight: this.getHeaderHeight(this.props, !this.state.isShowFilterRow),
      },
      () => this.props.onFilterVisibleChange?.(this.state.isShowFilterRow), // 筛序条件 visible 事件
    );
  };
  /* *********************** 排序功能 ******************************** */
  sortFront = ({ listStore, header, sortIndexes, sortList, columnKey }) => {
    const headerCol = header[columnKey];
    if (headerCol && this.isFrontSort(headerCol)) {
      if (!sortList[columnKey]) {
        // TODO 此处判断 只支持一个字段的排序
        this._frontOnlySortIndexes = null;
        listStore.updateIndexMap(sortIndexes);
        return;
      }
      const columnType = headerCol && headerCol.columnType;
      const type = headerCol && headerCol.type;
      const _sortIndexes = [...sortIndexes]; // listStore.getIndexMap() // 当前可视数据的indexMap
      _sortIndexes.sort((indexA, indexB) => {
        const valueA = listStore.getOriObjectAt(indexA)[columnKey];
        const valueB = listStore.getOriObjectAt(indexB)[columnKey];
        const showA = listStore.getOriShowObjectAt(indexA)[columnKey];
        const showB = listStore.getOriShowObjectAt(indexB)[columnKey];
        if (!valueA && !valueB) {
          return indexA > indexB ? 1 : -1;
        }
        return feCmpFunc({ oriA: valueA, oriB: valueB, columnKey, headerCol, showA, showB, sortList });
      });
      this._frontOnlySortIndexes = { sortIndexes: _sortIndexes, columnKey, sortList };
      listStore.updateIndexMap(_sortIndexes);
      this.props.handleHeaderSortable && this.props.handleHeaderSortable(columnKey, sortList, false); // 第三个参数 标记是否走接口
    }
  };
  handleHeaderSortable = columnKey => {
    // sortable 有front_only true false 都转为字符串
    let { sortList } = this.state;
    const { header, listStore } = this.state;
    const headerCol = header[columnKey] || {};
    const sortable = headerCol.sortable && headerCol.sortable !== 'false';
    if (!sortable) return;
    const sort = sortList[columnKey];
    if (sort) {
      if (sort === SORTTYPES.ASC) {
        // delete sortList[columnKey]
        // sortList = { [columnKey]: SORTTYPES.DESC, ...sortList }
        sortList = { [columnKey]: SORTTYPES.DESC };
      } else if (sort === SORTTYPES.DESC) {
        delete sortList[columnKey];
      }
    } else {
      // 后端暂时支持对第一个筛选字段排序
      // sortList[columnKey] = SORTTYPES.ASC
      sortList = { [columnKey]: SORTTYPES.ASC };
    }
    this.setState({ selectes: this.props.defaultSelectes, rowClickSelectes: [], sortList });
    // 只能前端排序的
    if (this.isFrontSort(headerCol)) {
      this.handleSelectAll(false); // 前端排序不会刷新列表，需要去掉所有选中的内容
      let sortIndexes = listStore.getIndexMap();
      const keys = Object.keys(this._searchFilterIndexMaps);
      if (keys.length !== 0) {
        const filterTmp = this._searchFilterIndexMaps[keys.pop()] || {};
        sortIndexes = filterTmp.filteredIndexes;
      }
      this.sortFront({ listStore, sortIndexes, header, sortList, columnKey });
    } else {
      this.props.handleHeaderSortable && this.props.handleHeaderSortable(columnKey, sortList);
    }
  };
  // 获取要保存的表格样式
  getTableStyle = () => {
    const { sortList, columnWidths, hideColumn, header, stickyIndex } = this.state;
    const _header = {};
    Object.keys(header).forEach((key, index) => {
      if (header[key].sticky !== undefined && !SPECIAL_HEADER_KEYS.includes(key)) {
        !_header[key] && (_header[key] = {});
        // 此处stickyIndex的改动后，  新移动进 冻结列的非冻结字段， 保存不上 但不影响显示
        _header[key].sticky = index <= stickyIndex; // header[key].sticky
      }
      if (header[key].stickyRight !== undefined && !SPECIAL_HEADER_KEYS.includes(key)) {
        !_header[key] && (_header[key] = {});
        _header[key].sticky_right = header[key].stickyRight;
      }
    });
    // 列宽
    Object.keys(columnWidths).forEach(key => {
      if (header[key]) {
        !_header[key] && (_header[key] = {});
        _header[key].width = columnWidths[key];
      }
    });
    // 隐藏列
    hideColumn.forEach(key => {
      if (header[key]) {
        !_header[key] && (_header[key] = {});
        _header[key].display = 'hide';
      }
    });
    return { sort: sortList, header: _header };
  };
  handleSaveTableStyle = (isShowMessage = true) => {
    this.props.handleSaveTableStyle && this.props.handleSaveTableStyle(this.getTableStyle(), isShowMessage);
  };
  debounceSaveStyle = _.debounce(function () {
    if (this.props.canSaveTableStyle) {
      this.handleSaveTableStyle(false);
    }
  }, 2000);
  // TODO 后续非公用操作 移出到listpage
  commonHeaderRightMenu = (columnKey, rowIndex, colIndex) => {
    const commonOps = [
      {
        key: 'handleHideColumn',
        type: 'spliter',
        name: { icon: 'icon-hide', title: '隐藏列' },
        handler: this.handleHideColumn,
      },
      { key: 'handleShowColumn', name: { icon: 'icon-ps-show', title: '显示隐藏列' }, handler: this.handleShowColumn },
      {
        key: 'handleAutoWith',
        type: 'spliter',
        name: { icon: 'icon-auto-width', title: '宽度自适应' },
        handler: this.handleAutoWith,
      },
    ];
    if (this.props.canFreeClumn) {
      const { header } = this.state;
      const isSticky = colIndex <= this.state.stickyIndex; // isset(this.state, `header.[${this.rightClickInfo.columnKey}].sticky`)
      const stickyRight = header[columnKey]?.stickyRight;
      const isLeftSticky = this.rightClickInfo.columnKey && isSticky;
      const isRightSticky = this.rightClickInfo.columnKey && stickyRight;
      if (!isRightSticky) {
        commonOps.push({
          key: 'handleFreezeColumn',
          name: {
            icon: isLeftSticky ? 'icon-unlock-left-freeze' : 'icon-left-freeze',
            title: isLeftSticky ? '解除左侧冻结' : '左侧冻结',
          },
          handler: this.handleFreezeColumn,
          disabled: col => !isSticky && !this.isColumnInOriViewPort(col, true),
        });
      }

      if (!isLeftSticky) {
        commonOps.push({
          key: 'handleFreezeRightColumn',
          name: {
            icon: isRightSticky ? 'icon-unlock-right-freeze' : 'icon-right-freeze',
            title: isRightSticky ? '解除右侧冻结' : '右侧冻结',
          },
          handler: this.handleFreezeRightColumn,
          disabled: col => !stickyRight && !this.isColumnInOriViewPort(col, true),
        });
      }
    }
    if (this.props.canSaveTableStyle) {
      commonOps.push({
        key: 'handleSaveTableStyle',
        name: { icon: 'icon-save', title: '保存表格样式' },
        handler: this.handleSaveTableStyle,
      });
    }
    // if (typeIs(index, 'number') && index > commonOps.length) return [commonOps[index]]
    return commonOps;
  };
  commonRowRightMenu = index => {
    const commonOps = [
      // { key: 'handleRowPrint', name: { icon: 'icon-print-out', title: '打印' }, handler: this.handleRowPrint, sublist: this.getPrintSublist() }
    ];
    if (typeIs(index, 'number')) {
      return !typeIs(commonOps[index], 'undefined') ? [commonOps[index]] : [];
    }
    return commonOps;
  };
  commonHeaderQuickFilter = columnKey => {
    const commonOps = [
      { key: 'quickFilterAll', name: { icon: 'icon-menu', title: '所有' }, handler: this.quickFilterHandler },
      { key: 'quickFilterEmpty', name: { icon: 'icon-empty', title: '空值' }, handler: this.quickFilterHandler },
      {
        key: 'quickFilterNotEmpty',
        name: { icon: 'icon-nonempty', title: '非空值' },
        handler: this.quickFilterHandler,
      },
    ];
    const headerCol = isset(this.state, `header.[${columnKey}]`);
    const filterType = getFilterType(headerCol, this.state.enumerations);
    if (
      !DATE_TYPE.includes(headerCol.columnType) &&
      !(
        (headerCol.analyzer === 'not_analyzed' && headerCol.columnType === 'keyword' && filterType !== 'SelectDrop') ||
        !isEmptyObj(headerCol.associateKeys)
      )
    ) {
      commonOps.push({
        key: 'quickFilterCustomize',
        name: { icon: 'icon-screen', title: '自定义' },
        handler: this.quickFilterHandler,
      });
    }
    // if (typeIs(index, 'number') && index > commonOps.length) return [commonOps[index]]
    return commonOps;
  };
  getPrintSublist = () => {
    if (typeIs(window[COMPANY_SETTING], 'object')) {
      // let printItems = printItemswindow.company_setting.print_options.printItems || {}
      // Object.entries(printItems).map([key,val] => val)
      return [];
    }
    return [];
  };
  // 右键菜单
  headerRightMenu = (columnKey, rowIndex, colIndex) => {
    const headerRightMenu = this.props.headerRightMenu || [];
    const a = [...this.commonHeaderRightMenu(columnKey, rowIndex, colIndex), ...headerRightMenu].map(iitem =>
      typeIs(iitem.disabled, 'function') ? { ...iitem, disabled: iitem.disabled(columnKey) } : iitem,
    );
    return a;
  };
  // 快速筛选
  headerQuickFilter = (columnKey, rowIndex) => {
    const headerQuickFilter = this.props.headerQuickFilter || [];
    const { header } = this.state;
    const { quickFilterExclude } = header[columnKey];
    let ret = [...this.commonHeaderQuickFilter(columnKey, rowIndex), ...headerQuickFilter];
    if (quickFilterExclude) {
      ret = ret.filter(item => !quickFilterExclude.includes(item.key));
    }
    return ret;
  };
  rowRightMenu = (columnKey, rowIndex) => {
    const rowRightMenu = this.props.rowRightMenu || [];
    // 过滤 计算特殊属性 如： disable 暂写死
    const allList = this.getStateCache() || [];
    const rowData = allList[rowIndex] || {};
    const menuDataList = typeIs(rowRightMenu, 'function') ? rowRightMenu(rowData) : rowRightMenu;
    return [...menuDataList, ...this.commonRowRightMenu(0)].map(iitem =>
      typeIs(iitem.disabled, 'function') ? { ...iitem, disabled: iitem.disabled(columnKey, rowIndex, rowData) } : iitem,
    );
  };
  /* ***************************************************************** */
  /* *********************** 打印相关 ******************************** */
  // 返回当前state中 页面显示的字符串列表数据（修改后的）
  getShowingData = selectes => {
    const { listStore } = this.state;
    if (typeIs(selectes, 'array')) {
      return listStore.getSelectedShowRows(false, selectes);
    }
    return listStore.getAllShow();
  };
  // TODO：review
  // 返回指定key的可显示数据
  getShowVal = (...params) => {
    const { listStore } = this.state;
    if (!listStore) return [];
    return listStore.getShowVal(...params);
  };
  getAllShowData = () => this.state.listStore.getAllShow(true);
  getShowingHeader = () => this.state.header;
  // 返回某行数据的最大高度 有数据变化则shouldUpdateHeight=true
  // 指定列自适应高度时获取高度值
  getShowingHeight = (prop = this.props) => {
    const { isAutoRowHeight, adaptiveCols } = prop;
    if (this._oriTable && (isEmptyObj(this.showingHeight) || this.shouldUpdateHeight)) {
      const adaptiveColsLen = adaptiveCols && adaptiveCols.length;
      const { rowHeight = this.defaultRowHeight, listStore: _thisList } = this.state;
      if (isAutoRowHeight || adaptiveColsLen) {
        this.shouldUpdateHeight = false;
        this.showingHeight = {};
        const { columnWidths, header } = this.state;
        const fontSize = this.getFontSize();
        // multiLineHeight = 18 // MLHEIGHTMAP[fontSize] // rowHeight
        // 配置了自适应列
        let cols = {};
        const columns = isset(this, '_oriTable.state.columns', []); // 自适应宽度列，使用实际渲染后的列宽
        adaptiveColsLen
          ? columns.forEach(c => {
              if (adaptiveCols.includes(c.props.columnKey)) {
                cols[c.props.columnKey] = c.props;
              }
            })
          : (cols = header);
        for (let i = 0; i < _thisList.getSize(); i++) {
          const dataItem = _thisList.getShowObjectAt(i);
          const colHeights = [];
          Object.entries(cols).map(([columnKey, columnProp]) => {
            let colWidth = columnWidths[columnKey] || columnProp.width || TABLE_DEFAULT_WIDTH;
            // let colWidth = (adaptiveColsLen ? columnProp.width || columnWidths[columnKey] : columnWidths[columnKey] || columnProp.width) || TABLE_DEFAULT_WIDTH
            colWidth -= 8 + fontSize / 2; // 减去padding值
            colWidth = Math.max(colWidth, fontSize);
            const str = dataItem[columnKey];
            const strWidth = getStrWidth(str, fontSize) || colWidth;
            const colStrRow = Math.ceil(strWidth / colWidth);
            const height = colStrRow > 1 ? colStrRow * MULTI_LINE_HEIGHT : rowHeight;
            colHeights.push(height);
            return true;
          });
          this.showingHeight[i] = Math.max(...colHeights);
        }
      } else {
        this.showingHeight = Array(_thisList.getSize()).fill(rowHeight);
      }
    }
    return this.showingHeight;
  };
  getContentTableSize = () => {
    let tableHeight = this.props.containerHeight;
    let tableWidth = this.props.containerWidth;
    const { isAutoContentSize, isAutoContentWidth, isAutoContentHeight } = this.props;
    // table尺寸自适应内容
    if (isAutoContentSize || isAutoContentWidth || isAutoContentHeight) {
      const { columnWidths = {}, header, headerKeys } = this.state;
      if (isAutoContentWidth || isAutoContentSize) {
        tableWidth = !isEmptyObj(headerKeys)
          ? headerKeys.reduce((p, c) => {
              const colWidth = columnWidths[c] || header[c].width || TABLE_DEFAULT_WIDTH;
              return p + parseInt(colWidth, 10);
            }, 0)
          : '100%'; // columnWidths.reference || TABLE_DEFAULT_WIDTH) : '100%'
      }
      if (isAutoContentHeight || isAutoContentSize) {
        const showingHeight = this.getShowingHeight();
        const rowsCount = this.state.listStore.getSize();
        const rowArr = Object.keys(showingHeight);
        // hfHeight = this.getHeaderHeight() + this.getFooterHeight()
        const hfHeight = this.getGpHeaderHeight() + this.getHeaderHeight() + this.getFooterHeight();
        tableHeight = isEmptyObj(showingHeight)
          ? this.defaultRowHeight * rowsCount + hfHeight
          : rowArr.reduce((p, c) => p + showingHeight[c], hfHeight + 5);
      }
    }
    return { tableWidth, tableHeight };
  };
  generatePrintTable = (tableId, isShow = true, ext) => {
    // 打印时 必须 isAutoRowHeight=true,isAutoContentSize=true
    let { header } = this.state;
    const { headerHeight, columnWidths } = this.state;
    const { tableAlign } = this.props;
    header = { reference: this.specialColumn.reference, ...header };
    let total = this.state.listStore.getShowObjectAt('footerTotal');
    total = { reference: this.specialColumn.reference.footerTitleBottom, ...total };
    const showingHeight = this.getShowingHeight();
    const { tableWidth, tableHeight } = this.getContentTableSize();
    const rowArr = Object.keys(showingHeight);
    const theaderTh = {};
    const tbodyP = {};
    const tfootTh = {};
    Object.keys(header).forEach(columnKey => {
      /**
       * columnWidths - 6
       * 是因为用了table-layout:fixed 所以要减去左右各3的padding值
       */
      theaderTh[columnKey] = { width: (columnWidths[columnKey] || header[columnKey].width) - 6, padding: '2px 3px' };
      tbodyP[columnKey] = {
        textAlign: header[columnKey].align || 'center',
        width: (columnWidths[columnKey] || header[columnKey].width) - 6,
      };
      tfootTh[columnKey] = { width: (columnWidths[columnKey] || header[columnKey].width) - 6, padding: '2px 3px' };
    });
    const tableStyle = {
      table: Object.assign({ width: tableWidth }, isShow ? {} : { display: 'none' }),
      // table: Object.assign({ width: tableWidth, height: tableHeight }, (isShow ? {} : { display: 'none' })),
      // theader: { tr: { height: headerHeight }, th: theaderTh },
      theader: { tr: {}, th: theaderTh },
      // tbody: rowArr.map(i => ({ tr: { height: showingHeight[i] }, td: { padding: '2px 3px' }, p: tbodyP })),
      tbody: rowArr.map(i => ({ tr: {}, td: { padding: '2px 3px' }, p: tbodyP })),
      // tfooter: { tr: { height: headerHeight }, th: tfootTh },
      tfooter: { tr: {}, th: tfootTh },
    };
    const groupHeader = this.props.isGroupHeader
      ? [
          {
            title: '',
            titleKey: 'specialHeader',
            fixed: true,
            groupHeaders: Object.keys(this.specialColumn),
          },
          ...(this.props.groupHeader || []),
        ]
      : [];
    new PopUp(StaticTable, {
      popName: tableId,
      header,
      data: this.getShowingData(),
      total,
      tableStyle,
      groupHeader,
      isGroupHeader: this.props.isGroupHeader,
      classname: ext && ext.classname,
      isAutoRowHeight: this.props.isAutoRowHeight,
      tableAlign,
      ...ext,
    }).show();
  };
  // 返回当前选中合计行数据对象
  getSelectedTotalRow = (type = 'total') => {
    const footerSelect = this.state.listStore.getShowObjectAt('footerSelect') || {};
    const obj = {};
    Object.keys(footerSelect).forEach(key => {
      obj[key] = footerSelect[key][type];
    });
    return obj;
  };
  // 返回显示的合计行数据对象
  getTotalRow = () => this.state.listStore.getShowObjectAt('footerTotal');
  // 返回显示的合计平均值数据对象
  getAvgRow = () => this.state.listStore.getShowObjectAt('footerCalculate')?.avg;
  /* ***************************************************************** */
  /* *********************** 渲染函数 ******************************** */
  // v2_头部及筛选 -->renderFilterRow
  // 名义输入 header, listStore, selectes, selectAll, total
  // 其他影响数据的props：enableQuickFilter, entryKeyBatchSearch, isShowSort
  // 其他影响数据的state：isShowFilterRow, sortList
  // 筛选值的变化 都不直接setState, 只改变数据 或外传事件 状态值由 cell维护
  getHeaderCellRef = columnKey => r => {
    this.headerCellRef[columnKey] = r;
  };
  // v2_头部内容格
  renderHeader = (listStore, header, columnKey, columnProps, structInfo) => {
    const headerCol = header[columnKey] || {};
    const showQuickFilter = this.props.enableQuickFilter && headerCol.quickFilter && headerCol.filterable !== 'false'; // && isEmptyObj(this.props.enumerations[headerCol.refEnum])
    const isQuickFilterShow =
      showQuickFilter &&
      this.currQuickFilter[columnKey] !== undefined &&
      this.currQuickFilter[columnKey] !== 'quickFilterAll';
    const isQuickFilterActive =
      isQuickFilterShow &&
      this.contextMenu &&
      this.contextMenu.state.isShow &&
      this.contextMenu.state.clickInfo.columnKey === columnKey;
    const markFrontOnly = this.state.headerKeys.some(
      k => header[k] && header[k].filterable === 'true' && header[k].sortable === 'true',
    ); // 全部不可筛选排序就不标记 -> 有可筛选的就标记
    if (structInfo && !structInfo.isRenderHeader && !this.props.isGroupHeader) {
      return <></>;
    }
    // console.log('renderHeaderColumnProps', columnProps);
    return (
      <TableCell.HeaderCell
        ref={this.getHeaderCellRef(columnKey)}
        columnProps={columnProps}
        {...columnProps}
        columnKey={columnKey}
        header={header}
        style={this.props.headerStyle || headerCol.style}
        oriHeader={this.props.header}
        data={listStore}
        entryKeyBatchSearch={this.props.entryKeyBatchSearch}
        enableOperate={this.props.enableOperate}
        currFilter={this._filter}
        isShowSort={this.props.isShowSort}
        markFrontOnly={!this.props.filterIner && this.props.markFrontOnly && markFrontOnly}
        sortList={this.state.sortList}
        enumerations={this.state.enumerations}
        currQuickFilter={this.currQuickFilter}
        isShowQuickFilter={showQuickFilter}
        isQuickFilterShow={isQuickFilterShow}
        isQuickFilterActive={isQuickFilterActive}
        isShowFilterRow={this.state.isShowFilterRow}
        isShowContextMenu={this.props.isShowContextMenu && !this.props.isGroupHeader}
        headerContextMenu={this.headerContextMenu}
        handleHeaderClick={this.handleHeaderClick}
        handleHeaderDoubleClick={this.handleHeaderDoubleClick}
        headerQuickFilterMenu={this.headerQuickFilterMenu}
        handleFilterChangeDate={this.handleFilterChangeDate}
        handleFilterChange={this.handleFilterChange}
        handleFilterBlur={this.handleFilterBlur}
        _handleFilterChange={this._handleFilterChange}
        handleFitlerSelected={this.handleFitlerSelected}
        handleIsFilterClick={this.handleIsFilterClick}
        customizesingleFilter={this.props.customizesingleFilter}
        constructData={structInfo}
        headerRelationship={this.state.headerRelationship}
      />
    );
  };
  // v2_获取表底数据
  // 名义输入 header, listStore, selectes, selectAll, total
  // 其他影响数据的props：isShowTotalRow/isTotalRowOperate/isShowOperation/rowCountColumn
  // 其他影响数据的state：isShowTotalRow/isTotalRowOperate/isShowOperation/rowCountColumn
  // 外部数据变动 或者 筛选过 都需要更新底部数据
  // 勾选时 必须同时更新 selectes 和 selectAll
  // 获取列表底部信息 合计行、选中行的字符串对象， 单元格对象
  // TODO 可根据totol数据 减少遍历
  getFooterRowData = (props, state, updateTotal = false, realTimeTotal) => {
    const footerSelect = {};
    const footerTotal = {};
    // 用来设置计算值
    const footerCalculate = {};
    const { header, headerKeys, listStore, total = {}, selectes = [], data } = state;
    let selectCount = 0;
    let totalCount = total.count || state.data.length;
    // console.log('getFooterRowDataData', data);
    // console.log('selectes', selectes);
    if (data && Array.isArray(data) && data.length > 0) {
      selectes.forEach(i => {
        // 如果是打包单，里面会包含多个运单
        if (data[i].b_pack_batch) {
          const pack_order_num = Number(data[i].pack_order_n) || 0;
          selectCount += pack_order_num;
        } else {
          selectCount++;
        }
      });
    }
    if (!props.isShowTotalRow || isEmptyObj(header) || (!selectCount && !totalCount)) {
      return { footerSelect, footerTotal, footerCalculate };
    }
    const checkBoxTextKeys = [];
    const rowCountColumnKeys = [];
    const rowCountColumn = `${props.rowCountColumn || ''}`.split('/');
    const hasRowCountColumn = rowCountColumn[0] && rowCountColumn.length === 2;
    const rowCountColumns = `${rowCountColumn[0]}`.split(',');
    const allSelect = listStore.getSelectedRows();
    const showingSelect = listStore.getSelectedRows(false, selectes);
    // summable字段   averageable 字段处理
    headerKeys
      .filter(key => {
        hasRowCountColumn && rowCountColumns.includes(key) && rowCountColumnKeys.push(key);
        !header[key]?.summable && header[key]?.type === 'CheckBoxText' && checkBoxTextKeys.push(key);
        return header[key]?.summable || header[key]?.averageable; // 新增平均值过滤逻辑
      })
      .forEach(columnKey => {
        const headerCol = header[columnKey] || {};
        const isSumAble = headerCol.summable; // 求和
        const isAvgAble = headerCol.averageable; // 平均值
        const STATS_PREFIX_KEY = `STATS_PREFIX_${columnKey}`;
        let sum = getSumVal(showingSelect, columnKey, headerCol.type === 'CheckBoxText');
        Number.isNaN(sum) && (sum = 0);
        // 设置选中的值
        if (selectCount) {
          const formatVal = this.dataFormater(sum, headerCol.format || {}) || '';
          if (isSumAble) {
            _.set(footerSelect, [columnKey, 'total'], formatVal);
          }
          // 取公式计算
          if (total[STATS_PREFIX_KEY]?.calculate) {
            const { formula, formula_ext } = total[STATS_PREFIX_KEY].calculate;
            const { result_value_format: format } = formula_ext || {};
            const formulaValue = getFormulaVal(showingSelect, formula, format);
            _.set(footerSelect, [columnKey, 'total'], formulaValue);
          }
          // 取sum的值进行平均计算
          if (isAvgAble) {
            const format = (headerCol.format && {
              round: 2,
              ...headerCol.format,
            }) || { round: 2 };
            const avgVal = this.dataFormater(
              sum / (showingSelect?.length || 1),
              // 平均值会有除不尽的情况，默认规则为round2
              format,
            );
            _.set(footerSelect, [columnKey, 'avg'], avgVal);
          }
        }
        if (updateTotal || realTimeTotal) {
          // resetData 时更新全部合计 取全部数据计算底部合计
          const count = realTimeTotal ? listStore.getAll() : allSelect;
          const sumVal = getSumVal(
            realTimeTotal ? listStore.getAll() : allSelect,
            columnKey,
            headerCol.type === 'CheckBoxText',
          );
          if (isSumAble) {
            total[columnKey] = sumVal;
            Number.isNaN(total[columnKey]) && (total[columnKey] = 0);
          }
          const avgKey = `STATS_PREFIX_${columnKey}`;
          if (isAvgAble && total[avgKey]) {
            total[avgKey].avg =
              (!Number.isNaN(sumVal) &&
                this.dataFormater(sumVal / count, {
                  round: 2,
                })) ||
              0;
          }
        }
        if (isSumAble) {
          // TODO 兼容列表中 total不规范的数据 找出有问题的total后可去除 formatDeppProptotype中逻辑
          footerTotal[columnKey] =
            this.dataFormater(formatDeppProptotype(columnKey, total) || '', headerCol.format || {}) || '';
        }
        if (isAvgAble) {
          const format = (headerCol.format && {
            round: 2,
            ...headerCol.format,
          }) || { round: 2 };
          const avgVal = this.dataFormater(formatDeppProptotype(`STATS_PREFIX_${columnKey}.avg`, total), format) || '';
          _.set(footerCalculate, ['avg', columnKey], avgVal);
        }
      });
    if (updateTotal) {
      // resetData 时更新全部合计
      totalCount = listStore.getOriSize();
    }
    // 其他非summable字段
    checkBoxTextKeys.forEach(columnKey => {
      const headerCol = header[columnKey] || {};
      if (selectCount) {
        _.set(footerSelect, [columnKey, 'total'], '');
      }
      footerTotal[columnKey] = this.dataFormater(total[columnKey] || '', headerCol.format || {}); // 是否勾选 为该列的值，this.state.footerChecked[columnKey]
    });
    if (props.isShowOperation && props.isTotalRowOperate) {
      footerTotal.operate = isset(listStore.getObjectAt(0), 'otherProps.operate', []);
    }
    let rowCountColumnKey;
    let countStr;
    if (rowCountColumnKeys[0] && headerKeys.includes(rowCountColumnKeys[0])) {
      rowCountColumnKey = rowCountColumnKeys[0];
      countStr = rowCountColumn[1];
    } else if (headerKeys.includes('order_num')) {
      rowCountColumnKey = 'order_num';
      countStr = LIST_ROWCOUNT_UNIT.order_num;
    } else if (headerKeys.includes('car_batch')) {
      rowCountColumnKey = 'car_batch';
      countStr = LIST_ROWCOUNT_UNIT.order_num;
    }

    if (rowCountColumnKey) {
      if (props.uniqRowCount && selectCount) {
        // 选中计数项去重时 重新计算选中计数项
        selectCount = [...new Set(showingSelect.map(item => item[rowCountColumnKey]))].length;
      }
      if (selectCount) {
        _.set(footerSelect, [rowCountColumnKey, 'total'], `${selectCount}${countStr}`);
      }
      totalCount && (footerTotal[rowCountColumnKey] = `${totalCount}${countStr}`);
    }
    return { footerSelect, footerTotal, total, footerCalculate };
  };
  // v2_底部内容格
  //  showingRows={this.showingRows[rowIndex] && (this.showingRows[rowIndex][columnKey] || this.showingRows[rowIndex].allColumnKey)}
  renderFooter = (listStore, header, columnKey, columnProps) => {
    // 合计为当前页合计标志 1，有分页；2，非特殊列； 3，非后端筛选或排序 4 只加批次的
    const filSortable =
      this.props.isShowPage &&
      this.props.category === 'Batch' &&
      !Object.keys(this.specialColumn).includes(columnKey) &&
      (columnProps.filterable !== 'true' || columnProps.sortable !== 'true');
    return (
      <TableCell.FooterCell
        {...columnProps}
        columnKey={columnKey}
        header={header}
        data={listStore}
        totalTip={`${filSortable ? '当前数值为当前页已经显示出来的数据的合计值' : ''}`}
        footerSelect={this.state.footerSelect}
        footerTotal={this.state.footerTotal}
        enableOperate={this.props.enableOperate}
        selectesLength={this.state.selectes.length}
        totalChecked={this.state.footerChecked}
        isShowAvg={this.state.isShowAvg}
        handleFooterCheckBoxTextClick={this.handleFooterCheckBoxTextClick}
        handleOperateClick={this.handleOperateClick}
        columnProps={columnProps}
        style={{ ...this.props.footerStyle }}
        totalStyle={{ ...(filSortable ? { color: '#999' } : undefined) }}
      />
    );
  };
  // v2_单元格l
  renderBody = (listStore, columnProps) => (
    <TableCell.BodyCell
      style={this.props.bodyStyle}
      cellEvents={this.cellEvents}
      showingRows={this.showingRows}
      mapedIndex={this.state.listStore.getIndexMap()}
      tableKey={this.props.tableKey}
      data={listStore}
      oriHeader={this.props.header}
      isPopCopyOn={this.props.isPopCopyOn}
      isRowClickSelect={this.props.isRowClickSelect}
      columnProps={columnProps}
      enableOperate={this.props.enableOperate}
      isPureText={this.props.isPureText}
      cellContentGetter={this.props.cellContentGetter}
      cellClassGetter={this.props.cellClassGetter}
      enumerations={this.state.enumerations}
      isShowRowContextMenu={this.props.isShowRowContextMenu}
      rowContextMenu={this.rowContextMenu}
      gatherOperates={this.props.gatherOperates}
      tipsTrigger={this.props.tipsTrigger}
      isAutoRowHeight={this.props.isAutoRowHeight}
      cellStyleGetter={this.props.cellStyleGetter}
      adaptiveCols={this.props.adaptiveCols}
      rowHeight={this.state.rowHeight}
      rangeSelectable={this.props.rangeSelectable}
      onCellMouseDown={this.onCellMouseDown}
      onCellMouseOver={this.onCellMouseOver}
      onCellMouseUp={this.onCellMouseUp}
      getRangeSelectCellClass={this.getRangeSelectCellClass}
    />
  );
  // v2_渲染数据列
  renderColumns = (row, isRenderFooter = true, tableDeep = 1) => {
    const {
      listStore,
      header,
      headerKeys,
      commonProps,
      columnWidths,
      hideColumn = [],
      deleteColumn = [],
      stickyIndex,
      showColumnWidth,
    } = this.state;
    let colIndex = -1;
    const columnCreator = (iheaderKeys, uperi = 0) => {
      // console.log('iheaderKeys', iheaderKeys);
      let columnProps = null;
      let headerCol = {};
      return iheaderKeys.map((column, index) => {
        const columnKey = row ? column.id : column;
        // TODO mock行需要考虑这里的计算
        // 隐藏列、删除列
        if (
          hideColumn.includes(columnKey) ||
          deleteColumn.includes(columnKey) ||
          (header[columnKey] && header[columnKey].innerShow === 'show')
        )
          return undefined;
        colIndex++;
        columnProps = { ...commonProps, colIndex };
        headerCol = header[columnKey] || {};
        headerCol.columnKey = columnKey;
        if (SPECIAL_DEFAULT_WIDTH[headerCol.columnType]) {
          columnProps.width = SPECIAL_DEFAULT_WIDTH[headerCol.columnType];
        }
        columnProps = { ...columnProps, ...headerCol };
        columnWidths[columnKey] && (columnProps.width = columnWidths[columnKey]);
        this.props.tableAlign && (columnProps = { ...columnProps, align: this.props.tableAlign });
        // 支持根据按钮数量自适应宽度
        if (columnKey === 'operate' && !this.props.gatherOperates) {
          // 最多的opeate
          let maxOperate = [];
          listStore.getAllShow().forEach(item => {
            if (item.operate && item.operate.length > maxOperate.length) {
              maxOperate = item.operate;
            }
          });
          maxOperate = maxOperate.length ? `[${maxOperate.map(v => v.title).join('][')}]` : 0;
          // 计算宽度
          const calcWidth = maxOperate.length ? getStrWidth(maxOperate, this.getFontSize()) + 10 : 0;

          // 取计算宽度 和 原宽度的最大值
          const finalWidth = Math.max(columnProps.width, calcWidth);
          columnProps.width = finalWidth;
        }
        if (columnKey === 'blank_cell') {
          const { containerWidth } = this.props;
          const diffWidth = containerWidth > showColumnWidth ? containerWidth - showColumnWidth : 0;
          columnProps.width = diffWidth - MULTI_TABLE_FAKE_SCROLLER_WIDTH;
        }
        const flexGrow = Number(columnProps.flexGrow);
        // 每列内容
        return (
          <Column
            ref={r => (this.columnRef[columnKey] = r)}
            key={`${index}-${uperi}`}
            align="center"
            pureRendering
            isReorderable={false}
            allowCellsRecycling={this.props.onlyViewportColumn}
            fixed={colIndex <= stickyIndex}
            fixedRight={header[columnKey]?.stickyRight}
            header={this.renderHeader(listStore, header, columnKey, columnProps, row ? column : false, tableDeep)}
            cell={this.renderBody(listStore, columnProps)}
            footer={
              this.props.isShowTotalRow && isRenderFooter
                ? this.renderFooter(listStore, header, columnKey, columnProps)
                : undefined
            }
            cellClassName={`table-header-Column table-column-${columnKey}`}
            flexGrow={Number.isNaN(flexGrow) ? 0 : flexGrow}
            {...columnProps}
          />
        );
      });
    };
    if (this.props.isGroupHeader) {
      const groupHeader = [
        {
          title: '',
          titleKey: 'specialHeader',
          fixed: true,
          groupHeaders: Object.keys(this.specialColumn),
        },
        ...(this.props.groupHeader || []),
      ];
      return groupHeader.map((item, i) => (
        <ColumnGroup
          key={i}
          fixed={item.fixed}
          header={
            <Cell>
              <div className="table-filter-column" style={{ textAlign: 'center' }}>
                <p>{item.title}</p>
              </div>
              {item.topRightIcon && (
                <Icon
                  classname="top-right-icon"
                  iconType={item.topRightIcon}
                  onClick={e => this.props.handleTopRightIconClick(e, item.titleKey)}
                />
              )}
            </Cell>
          }
        >
          {columnCreator(item.groupHeaders, i)}
        </ColumnGroup>
      ));
    }
    if (row) {
      return columnCreator(row);
    }
    return columnCreator(headerKeys);
  };
  updateState = newState => {
    this.setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };
  // 获取表格最左侧到某列的宽度， toColEnd 宽度取至列尾部
  getWidthAtColumn = (columnKey, toColEnd) => {
    let width = 0;
    const { header, headerKeys, columnWidths } = this.state;
    const colIndex = headerKeys.indexOf(columnKey);
    headerKeys.slice(0, toColEnd ? colIndex + 1 : colIndex).forEach(key => {
      width += columnWidths[key] || header[key].width || TABLE_DEFAULT_WIDTH;
    });
    return width;
  };
  // TODO 某列是否在当前可视范围内 isColumnInViewPort
  // 某列是否在首屏可视范围内, toColEnd 列尾是否也在
  isColumnInOriViewPort = (columnKey, toColEnd) => {
    const { headerKeys } = this.state;
    const colIndex = headerKeys.indexOf(columnKey);
    if (colIndex < 0 || this.props.containerWidth < 1) return false;
    const colWidth = this.getWidthAtColumn(columnKey, toColEnd); // 到前一列的宽度合计
    return colWidth < this.props.containerWidth;
  };
  getGpHeaderHeight = () => +(this.props.groupHeaderHeight || TABLE_HEADER_HEIGHT);
  // 表格的高度可以从外部传入
  getHeaderHeight = (prop = this.props, isShowFilterRow = this.props.isShowFilterRow) =>
    isShowFilterRow ? TABLE_HEADER_HEIGHT_FILTERROW : prop.headerHeight ? prop.headerHeight : TABLE_HEADER_HEIGHT;
  // 不显示合计 则无选中行
  getFooterHeight = dataSize => {
    // 判断是否展示合计行
    if (this.props.isShowTotalRow) {
      // 根据是否有平均值设置合计行的高度
      const footerAvg = this.state.listStore.getShowObjectAt('footerCalculate')?.avg || {};
      // 默认为1
      let count = 1;
      if (this.state.isShowAvg && !isEmptyObj(footerAvg)) {
        count = 2;
      }
      // 设置选中后合计行的高度，如果有平均值需考虑平均值的高度
      if ((this.state.selectAll || this.state.selectes.length) && dataSize > 0) {
        count = 2;
        if (this.state.isShowAvg) {
          count = 4;
        }
      }
      return TABLE_FOOTER_NORMAL_HEIGHT * count;
    }
    return 0;
  };
  handleResizeTable = () => this.props.updateDimensions && this.props.updateDimensions();
  rowHeightGetter = i => this.getShowingHeight()[i] || this.defaultRowHeight;
  getFontSize = (prop = this.props, path = 'bodyStyle.fontSize') => getPageFontSize(prop, path);
  getRowHeight = (prop = this.props, path = 'bodyStyle.talbeHeight') => getTableRowHeight(prop, path);
  // getFontSize = (prop = this.props, type = 'bodyStyle') => {
  //   let f = 12
  //   const propsF = isset(prop, `${type}.fontSize`)
  //   if (propsF) {
  //     f = propsF // props的fontSize优先级高
  //   } else {
  //     document.body.className.replace(/fontSize-([0-9][0-9])/, (item, $1) => f = +$1)
  //   }
  //   return f
  // }
  autoContentSize = () => {
    // 自适应内容宽高： isAutoContentSize，
    // 自适应内容宽度： isAutoContentWidth,
    // 自适应内容高度： isAutoContentHeight,
    const adaptiveColsLen = this.props.adaptiveCols && this.props.adaptiveCols.length;
    if (
      this.props.setParentDimensions &&
      (this.props.isAutoContentSize || this.props.isAutoContentWidth || this.props.isAutoContentHeight)
    ) {
      const { tableWidth, tableHeight } = this.getContentTableSize();
      if (this.props.containerWidth !== tableWidth || this.props.containerHeight !== tableHeight) {
        this.props.setParentDimensions(tableWidth, tableHeight);
      }
    } else if (this.isColumnResizing && adaptiveColsLen) {
      // 自适应列宽的列，拖动到小于可自适应的宽度时，表格自动按自适应宽度显示
      // 而state.columnWidths更新为了拖动值，此处在渲染完后获取自适应宽度重新渲染
      this.shouldUpdateHeight = true;
      const { columnWidths } = this.state;
      const columns = isset(this, '_oriTable.state.columns', []);
      columns.forEach(c => {
        if (columnWidths[c.props.columnKey] && this.props.adaptiveCols.includes(c.props.columnKey)) {
          columnWidths[c.props.columnKey] = c.props.width;
        }
      });
      this.setState({ columnWidths: _.cloneDeep(columnWidths) });
      this.isColumnResizing = false;
    }
  };
  refGettrCopyer = r => {
    window.coyperStatus[this.props.tableKey] && (window.coyperStatus[this.props.tableKey].tableHideInput = r);
  };
  onPsnSettingUpdate = () => {
    const skinSet = window.psn_setting.skin_set;
    this.noThousandSep = isset(skinSet, '__meta.thousandSep', '1') === '2';
    this.setState({ skinSet, listStore: this.createStore(this.props.data) });
  };
  onTableRowHeightChange = () => {
    const rowHeight = this.getRowHeight();
    this.setState({
      rowHeight,
    });
  };
  componentDidMount() {
    window.emt && window.emt.on('psnSettingUpdated', this.onPsnSettingUpdate);
    window.emt && window.emt.on('skinSetUpdate', this.onTableRowHeightChange);
    this.autoContentSize();
    this.fixColumnResizeContainer();
  }

  componentWillUnmount() {
    window.emt && window.emt.off('psnSettingUpdated', this.onPsnSettingUpdate);
    window.emt && window.emt.off('skinSetUpdate', this.onTableRowHeightChange);
    this.resetCoyperStatus();
  }
  extractNumbersFromString(str) {
    const regex = /\d+/g;
    const numbers = str.match(regex);
    return numbers ? numbers.map(Number) : [];
  }
  fixColumnResizeContainer() {
    if (!this.state.isMultiTable) {
      return;
    }
    const domList = document.querySelectorAll('.table-header-Column');
    domList.forEach(el => {
      const resizeColumnContainer = el.querySelector('.fixedDataTableCellLayout_columnResizerContainer');
      const resizeColumn = el.querySelector('.public_fixedDataTableCell_columnResizerKnob');
      const layout = el.querySelector('.public_fixedDataTableCell_wrap1 .isShowFilterRow');
      const layoutHeight = (layout && layout?.style?.height) || '0';
      if (resizeColumn) {
        // const finalHeight = `${this.extractNumbersFromString(layoutHeight) + 27}px`;
        const finalHeight = layoutHeight;
        resizeColumn.style.height = finalHeight;
        resizeColumnContainer.style.height = finalHeight;
      }
    });
    // console.log('xxxdomList', domList);
  }
  componentDidUpdate() {
    this.autoContentSize();
    this.fixColumnResizeContainer();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let newListStore;
    const { defaultSelectes = [] } = nextProps;
    const newState = {};
    const { listStore } = this.state;
    if (nextProps.data !== this.props.data) {
      newListStore = this.createStore(nextProps.data);
      const newListStoreSize = newListStore.getSize();
      const isSelectAll = newListStoreSize !== 0 && defaultSelectes.length === newListStoreSize;

      newState.listStore = newListStore;
      newState.data = nextProps.data || [];
      newState.selectes = defaultSelectes;
      newState.originSelectes = [];
      newState.selectAll = isSelectAll;
      this.shouldUpdateHeight = true;
    }
    // 取新的筛选条件 特殊列 需要在 formatHeader 之前
    if (nextProps.filter !== this.props.filter) {
      this._filter = nextProps.filter;
      !newListStore &&
        this.props.header === nextProps.header &&
        listStore.updateShowCacheRow('headerFilter', this._filter);
    }
    if (this.props.header !== nextProps.header) {
      this.shouldUpdateHeight = true;
      newState.header = nextProps.header; // 仅用于更新数据判断 非实际赋值的state header值
      // header变了 也重置store 不具体判断 header里的哪些属性变了会需要重新生成store值
      if (!newListStore) {
        newListStore = this.createStore(nextProps.data);
        newState.listStore = newListStore;
      }
    }
    // 从外部更新total值
    if (nextProps.total !== this.props.total) {
      newState.total = nextProps.total;
      if (!newListStore) {
        const footerRow = this.getFooterRowData(nextProps, { ...this.state, ...newState });
        listStore.updateShowCacheRow('footerTotal', footerRow.footerTotal, 'show');
        listStore.updateShowCacheRow('footerCalculate', footerRow.footerCalculate, 'show');
      }
    }
    if (nextProps.fixedColumnWidth && nextProps.fixedColumnWidth !== this.props.fixedColumnWidth) {
      this.shouldUpdateHeight = true;
      // add 0929 解决恢复表格样式不生效的问题
      newState.columnWidths = nextProps.fixedColumnWidth;
    }
    if (this.props.columnWidth !== nextProps.columnWidth) {
      this.shouldUpdateHeight = true;
      newState.commonProps = { width: nextProps.columnWidth || 80 };
    }
    if (this.props.enumerations !== nextProps.enumerations) {
      newState.enumerations = nextProps.enumerations;
    }
    // this._updateFreeze = false
    if (this._updateTableFilterRow && nextProps.isShowFilterRow !== this.props.isShowFilterRow) {
      newState.isShowFilterRow = nextProps.isShowFilterRow;
      newState.headerHeight = this.getHeaderHeight(nextProps, nextProps.isShowFilterRow);
      this._updateTableFilterRow = false;
    }
    // 此处有bug, 如果仅仅从外部改变了枚举值，并不能重新渲染table
    if (this.props.sort !== nextProps.sort && nextProps.isShowSort) {
      newState.sortList = nextProps.sort;
    }
    if (this.props.rowHeight !== nextProps.rowHeight && nextProps.rowHeight) {
      this.shouldUpdateHeight = true;
      newState.rowHeight = nextProps.rowHeight;
    }
    if (this.props.bodyStyle !== nextProps.bodyStyle) {
      this.shouldUpdateHeight = true;
    }
    if (Object.keys(newState).length) {
      this.getInitData(
        nextProps,
        { ...this.state, ...newState },
        this._filter,
        undefined,
        false,
        this.props.initUpdateTotal,
        this.props.firstSort,
      );
    }
  }
  render() {
    const { listStore, headerHeight, rowHeight = this.defaultRowHeight, isLoading, multiTableDeep } = this.state;
    const { isAutoRowHeight, adaptiveCols, style, customNoDataDesc, defaultSearchTips, isPopCopyOn, containerHeight } =
      this.props;
    this.useInitData = false;
    const tableHeight = containerHeight - (multiTableDeep - 1) * TABLE_HEADER_HEIGHT;
    const copyerHideInput = isPopCopyOn ? (
      <Input ref={this.refGettrCopyer} classname="fn-table_copy_cell_hide_copyer" isFocusSelect readOnly />
    ) : undefined;
    // rowHeight={rowHeight - 2} // 通用列表 行高 26 - 2
    return (
      <div className={`${prefixCls} ${this.props.classname}`} ref={r => (this.vTableContainer = r)} style={style}>
        <ResizeComponent
          height={headerHeight}
          onResize={this.handleResize}
          anchors={this.RESIZE_COLUMN_LIST}
          wrapper={this.vTableContainer}
        />
        {((this.props.noDataTip && !listStore.getSize()) || isLoading) && (
          <div className="nodata-tip" style={{ height: this.props.containerHeight - headerHeight }}>
            <div
              className={classname('loader', 'fn-loader__loader', {
                hide: !isLoading,
              })}
            >
              <div className="loading-icon line-spin-fade-loader">
                <div className="loading-icon-inner">
                  <i className="iconfont fn-icon-loading1" />
                </div>
                <em className="loading-icon-text">加载中...</em>
              </div>
            </div>
            <div className={classname('nodata-icon', { hide: isLoading })}>
              {!defaultSearchTips && <div className="list-no-data" />}
              <div className="list-no-data-text">
                {defaultSearchTips && (
                  <React.Fragment>
                    <i className="list-no-data-tip" />
                    {defaultSearchTips}
                  </React.Fragment>
                )}
                {!defaultSearchTips && customNoDataDesc}
                {!defaultSearchTips && !customNoDataDesc && (
                  <React.Fragment>
                    <p>没有对应的数据</p>
                    <p>请检查筛选条件后重新进行数据查询</p>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        )}
        {this.props.isShowContextMenu || this.props.isShowRowContextMenu ? (
          <ContextMenu
            ref={r => (this.contextMenu = r)}
            width={TABLE_HEADER_RIGHTMENU_SIZE.width}
            height={TABLE_HEADER_RIGHTMENU_SIZE.height}
            menuList={this.headerRightMenu()}
            uniqueKey="key"
            showKey="name"
            searchPlaceHolder="快速定位列"
            handleSearchClick={this.handleSearchClick}
            handleSearchChange={this.handleSearchChange}
            onHide={this.onContextMenuHide}
          />
        ) : null}
        <TableContext.Provider value={this.cellEvents}>
          <MultiTable
            header={this.state.header}
            getTableRef={this.getTableRef}
            rowsCount={listStore.getSize()}
            containerHeight={this.props.containerHeight}
            width={this.props.containerWidth}
            rowHeight={rowHeight}
            rowKeyGetter={this.props.rowKeyGetter}
            headerHeight={headerHeight}
            groupHeaderHeight={this.props.groupHeaderHeight || TABLE_HEADER_HEIGHT}
            scrollToColumn={this.shouldScrollColunm ? this.state.scrollToColumn : undefined}
            scrollToRow={this.shouldScrollRow ? this.state.scrollToRow : undefined}
            isColumnResizing={false}
            showScrollbarX={this.props.showScrollbarX}
            showScrollbarY={this.props.showScrollbarY}
            onColumnResizeEndCallback={this.handleColumnResizeEndCallback}
            onColumnReorderEndCallback={this.onColumnReorderEndCallback}
            onRowClick={this.props.rowClickable ? this.handleRowClick : undefined}
            onRowDoubleClick={this.props.rowClickable ? this.onRowDoubleClick : undefined}
            rowClassNameGetter={this.handleRowClassNameGetter}
            rowHeightGetter={isAutoRowHeight || adaptiveCols ? this.rowHeightGetter : undefined}
            onScrollEnd={this.props.onScrollEnd}
            scrollTop={this.props.scrollTop}
            scrollLeft={this.state.scrollLeft}
            state={this.state}
            renderColumns={this.renderColumns}
            getFooterHeight={this.getFooterHeight}
            updateState={this.updateState}
            columnWidths={this.state.columnWidths}
            hideColumn={this.state.hideColumn}
          />
          <Table
            ref={r => (this._oriTable = r)}
            rowsCount={listStore.getSize()}
            height={tableHeight}
            width={this.props.containerWidth}
            rowHeight={rowHeight}
            rowKeyGetter={this.props.rowKeyGetter}
            footerHeight={this.getFooterHeight(listStore.getSize())}
            headerHeight={headerHeight}
            groupHeaderHeight={this.props.groupHeaderHeight || TABLE_HEADER_HEIGHT}
            scrollToColumn={this.shouldScrollColunm ? this.state.scrollToColumn : undefined}
            scrollToRow={this.shouldScrollRow ? this.state.scrollToRow : undefined}
            isColumnResizing={false}
            showScrollbarX={this.props.showScrollbarX}
            showScrollbarY={this.props.showScrollbarY}
            onColumnResizeEndCallback={this.handleColumnResizeEndCallback}
            onColumnReorderEndCallback={this.onColumnReorderEndCallback}
            onRowClick={this.props.rowClickable ? this.handleRowClick : undefined}
            onRowDoubleClick={this.props.rowClickable ? this.onRowDoubleClick : undefined}
            rowClassNameGetter={this.handleRowClassNameGetter}
            rowHeightGetter={isAutoRowHeight || adaptiveCols ? this.rowHeightGetter : undefined}
            onScrollEnd={this.props.onScrollEnd}
            onVerticalScroll={this.props.onVerticalScroll}
            scrollTop={this.props.scrollTop}
            scrollLeft={this.state.scrollLeft}
            onHorizontalScroll={value => this.setState({ scrollLeft: value })}
            touchScrollEnabled
          >
            {this.renderColumns()}
          </Table>
        </TableContext.Provider>
        {copyerHideInput}
      </div>
    );
  }
}
