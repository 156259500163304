import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { PureCheckbox } from 'components';
import { shallowCompareIgnoreFunc, getAllNode, getAllLeaf } from 'utils';
import { prefixCls } from './index.scss';

const SOME_CHILDREN_CHECKED = 'some-children-checked';
const NO_CHECKED = 'no-checked';
const SELF_CHECKED = 'self-checked';
const ALL_CHECKED = 'all-checked';

export const EXPAND_ALL = 'EXPAND_ALL';

export default class PrueTree extends Component {
  static defaultProps = {
    compare: 'key',
    map: 'key',
    format: 'name',
    defaultExpanded: EXPAND_ALL,
  };

  static propTypes = {
    value: PropTypes.array,
    defaultExpanded: PropTypes.any, // 非受控默认展开节点 如为 EXPAND_ALL 则展开全部
    expanded: PropTypes.any, // 受控展开 如为 EXPAND_ALL 则展开全部
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.any,
    selectable: PropTypes.bool,
    checkable: PropTypes.oneOf([true, 'leaf']), // leaf 表示实际只有叶节点可以勾选，父节点只显示状态不会传值
    data: PropTypes.object, // { key: 'aaa', name: 'xxx', children: { aaa: { key: 'aaa', name: 'xxx' }, zzzz: { key: 'aaa', name: 'xxx' } } }
    compare: PropTypes.string,
    map: PropTypes.any,
    format: PropTypes.any, // 节点文字格式化
    onChange: PropTypes.func,
    onSelectNode: PropTypes.func,
    onToggleNode: PropTypes.func,
    nodeTitleRender: PropTypes.func,
  };

  static EXPAND_ALL = EXPAND_ALL;

  constructor(props) {
    super(props);
    this.setFormatFn(props);
    this.setMapFn(props);
    this.setDisabledFn(props);
    this.setSelectableFn(props);
    this.setExpandedFn(props);
    this.state = { expanded: props.defaultExpanded };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.disabled !== nextProps.disabled) {
      this.setDisabledFn(nextProps);
    }
    if (this.props.format !== nextProps.format) {
      this.setFormatFn(nextProps);
    }
    if (this.props.map !== nextProps.map) {
      this.setMapFn(nextProps);
    }
    if (this.props.selectable !== nextProps.selectable) {
      this.setSelectableFn(nextProps);
    }
    if (this.props.expanded !== nextProps.expanded) {
      this.setExpandedFn(nextProps);
    }
  }

  shouldComponentUpdate = shallowCompareIgnoreFunc;

  setFormatFn(props) {
    const { format } = props;
    if (typeof format === 'string') {
      this.format = node => node[format];
    } else if (typeof format === 'function') {
      this.format = format;
    } else {
      this.format = () => '';
    }
  }

  setMapFn(props) {
    const { map } = props;
    const mapType = typeof map;
    if (mapType === 'string') {
      this.map = item => (typeof item === 'object' ? item[map] : item);
    } else if (mapType === 'function') {
      this.map = map;
    } else {
      this.map = item => item;
    }
  }

  setDisabledFn(props) {
    const { disabled } = props;
    if (typeof disabled === 'function') {
      this.isDisabled = disabled;
    } else {
      this.isDisabled = () => disabled;
    }
  }

  setSelectableFn(props) {
    const { selectable } = props;
    if (typeof selectable === 'function') {
      this.isSelectable = selectable;
    } else {
      this.isSelectable = () => !!selectable;
    }
  }

  setExpandedFn(props) {
    if (typeof props.expanded === 'function') {
      this.isExpanded = props.expanded;
    } else if (Array.isArray(props.expanded)) {
      this.isExpanded = node => props.expanded.some(item => this.compare(node, item));
    } else if (props.expanded === EXPAND_ALL) {
      this.isExpanded = () => true;
    } else {
      this.isExpanded = node => {
        const { expanded } = this.state;
        return expanded && (expanded === EXPAND_ALL || expanded.some(item => this.compare(node, item)));
      };
    }
  }

  compare = (node, val) =>
    val && val[this.props.compare] !== undefined
      ? val[this.props.compare] == node[this.props.compare]
      : val == node[this.props.compare]; // eslint-disable-line

  isChecked = (node, val) => val.some(v1 => this.compare(node, v1));

  check = node => {
    const { checkable } = this.props;
    const value = this.props.value || [];
    const nodeState = this.parseNode(node);
    const checkboxState = this.getCheckboxState(nodeState);
    let newValue;
    if (checkable === 'leaf') {
      // 只可选择叶节点时
      if (nodeState.isLeaf) {
        if (nodeState.checked) {
          newValue = value.filter(val => !this.compare(node, val));
        } else {
          newValue = [...value, node];
        }
      } else if (checkboxState === ALL_CHECKED) {
        const allNode = getAllNode(node);
        newValue = value.filter(val => allNode.every(n1 => !this.compare(n1, val)));
      } else {
        const notCheckedNode = getAllLeaf(node).filter(n1 => value.every(val => !this.compare(n1, val)));
        newValue = [...value, ...notCheckedNode];
      }
    } else {
      // 都可以选择时
      // eslint-disable-next-line no-lonely-if
      if (checkboxState === ALL_CHECKED) {
        const allNode = getAllNode(node);
        newValue = value.filter(val => allNode.every(n1 => !this.compare(n1, val)));
      } else if (checkboxState === SOME_CHILDREN_CHECKED || checkboxState === SELF_CHECKED) {
        const notCheckedNode = getAllNode(node).filter(n1 => value.every(val => !this.compare(n1, val)));
        newValue = [...value, ...notCheckedNode];
      } else {
        newValue = [...value, node];
      }
    }

    this.props.onChange && this.props.onChange(newValue.map(this.map));
  };

  select = node => {
    if (!this.isSelectable(node)) return;
    this.setState({ selected: node[this.props.compare] });
    this.props.onSelectNode && this.props.onSelectNode(node);
  };

  toggle = node => {
    const { compare } = this.props;
    const newExpanded = [...this.expanded];
    const index = newExpanded.indexOf(node[compare]);
    const isExpanded = index > -1;
    isExpanded ? newExpanded.splice(index, 1) : newExpanded.push(node[compare]);
    this.setState({ expanded: newExpanded });
    this.props.onToggleNode && this.props.onToggleNode(node, isExpanded, newExpanded);
  };

  getCheckboxState = nodeState => {
    if (this.props.checkable === 'leaf') {
      if (!nodeState.isLeaf && nodeState.allChildrenChecked) {
        return ALL_CHECKED;
      }
    }
    if (nodeState.checked) {
      return nodeState.allChildrenChecked || nodeState.isLeaf ? ALL_CHECKED : SELF_CHECKED;
    }
    return nodeState.someChildrenChecked ? SOME_CHILDREN_CHECKED : NO_CHECKED;
  };

  nodeTitleRender = node => <span onClick={e => this.select(node, e)}>{this.format(node)}</span>;

  parseNode(node, dom) {
    const { nodeTitleRender = this.nodeTitleRender, compare, checkable } = this.props;
    const value = this.props.value || [];
    const children = node.children && Object.values(node.children);
    const isExpanded = this.isExpanded(node, value);
    const checked = checkable && this.isChecked(node, value);
    const parsedChildren = children ? children.map(item => this.parseNode(item, isExpanded)) : [];
    const key = node[compare];
    const isLeaf = !children || !children.length;
    const disabled = this.isDisabled(node);
    const ret = { node, key, isLeaf, disabled, isExpanded, checked };

    isExpanded && this.expanded.push(key);

    if (checkable) {
      ret.checked = checked;
      ret.allChildrenChecked =
        !isLeaf &&
        parsedChildren.every(item =>
          item.isLeaf ? item.checked : (checkable === 'leaf' || item.checked) && item.allChildrenChecked,
        );
      ret.someChildrenChecked = !isLeaf && parsedChildren.some(item => item.checked || item.someChildrenChecked);
    }
    if (dom) {
      const isSelected = this.compare(node, this.state.selected);
      ret.dom = (
        <dl className={classnames({ expanded: isExpanded, leaf: isLeaf })}>
          <dt>
            <span className="toggle" onClick={e => this.toggle(node, e)} />
            <span className={classnames({ 'node-title': true, selected: isSelected })}>
              {checkable && (
                <PureCheckbox
                  className={this.getCheckboxState(ret)}
                  disabled={disabled}
                  checked={checked}
                  onClick={e => this.check(node, e)}
                />
              )}
              {nodeTitleRender(node, this, { ...ret })}
            </span>
          </dt>
          {children && isExpanded && parsedChildren.map(item => <dd key={item.key}>{item.dom}</dd>)}
        </dl>
      );
    }

    return ret;
  }

  render() {
    const { data, className, style, compare } = this.props;
    const classes = classnames({ [prefixCls]: true, [className]: className });
    const fistNode = data && (data[compare] ? data : Object.values(data)[0]);
    this.expanded = [];
    if (!fistNode) return null;
    return (
      <div className={classes} style={style}>
        {data && this.parseNode(fistNode, true).dom}
      </div>
    );
  }
}
