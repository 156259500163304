import React, { PureComponent } from 'react';
import { number, string, bool, object } from 'prop-types';
import classNames from 'classnames';
import { prefixCls } from './index.scss';

export default class Line extends PureComponent {
  static propTypes = {
    percent: number,
    trailColor: string,
    strokeColor: string,
    tip: string,
    showInfo: bool,
    style: object,
    active: bool,
  };
  handleTextFormat(percent) {
    return `${+String(percent).replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')}%`;
  }
  // 格式化父级数据
  handleGetPropsFormat() {
    const { percent, trailColor, strokeColor, ...other } = this.props;
    const chartStyle = {
      '--trailColor': trailColor,
    };
    const lineStyle = {
      '--progress': `${percent}%`,
      '--strokeColor': strokeColor,
    };
    const text = this.handleTextFormat(percent);
    return {
      chartStyle,
      lineStyle,
      text,
      ...other,
    };
  }
  render() {
    const { chartStyle, lineStyle, text, tip, showInfo, style, active } = this.handleGetPropsFormat();
    return (
      <div className={prefixCls}>
        {showInfo && <span className={`${prefixCls}-text`}>{text}</span>}
        <div className={`${prefixCls}-chart`} style={{ ...chartStyle, ...style }}>
          <div className={classNames(`${prefixCls}-chart-line`, { active })} style={lineStyle} />
        </div>
        {tip && <div className={`${prefixCls}-tip`}>{tip}</div>}
      </div>
    );
  }
}
