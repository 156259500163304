import React from 'react';
import 'react-area-linkage/dist/index.css';
import { AreaCascader } from 'react-area-linkage';
import { getAreaCascaderData } from 'utils';
import PropTypes from 'prop-types';
import { prefixCls } from './index.scss';

class G7AreaCascader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.areaCascaderData = null;
    this.areaUpadted = false;
    this.state = {
      areaCascaderData: null,
    };
  }
  static propTypes = {
    onSelect: PropTypes.func,
    handleSelected: PropTypes.func,
    defaultArea: PropTypes.array,
  };
  initData = async () => {
    const data = await getAreaCascaderData();
    this.setState({ areaCascaderData: data });
  };
  handleSelectChange = (...params) => {
    const { handleSelected } = this.props;
    handleSelected(...params);
  };
  componentDidMount() {
    this.initData();
  }
  render() {
    return (
      <div className={prefixCls}>
        {this.state.areaCascaderData && (
          <AreaCascader
            level={1}
            data={this.state.areaCascaderData}
            onChange={this.handleSelectChange}
            defaultArea={this.props.defaultArea}
          />
        )}
      </div>
    );
  }
}
export default G7AreaCascader;
