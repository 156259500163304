import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { formatDeppProptotype } from 'utils';
import { DataList } from 'components';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';
import { dataFormater } from '../helper/dataFormater';

export default class DataListCell extends TableCell {
  constructor(props) {
    super(props);
    const { unEchoEvents = [], sugheader, filter, showKey } = this.mergeProps(props);
    this.header = sugheader;
    const value = this.format(this.getValueFromProps(props) || '');
    this.state = {
      data: props.columnProps.enumData || [], // init Data
      value: typeof value === 'object' ? value[showKey] : value,
    };
    this.filter = filter || this.fetchData();
    this.events = {
      onChange: this.onChange('onChange'),
      onBlur: this.onChange('onBlur'),
      onSelect: this.onChange('onSelect'),
    };
    this.unEchoEvents = {};
    Object.values(unEchoEvents).forEach(e => (this.unEchoEvents[e] = true));
  }
  static propTypes = {
    onInputKeyDown: PropTypes.func,
  };
  // 从props取value
  getValueFromProps = props => {
    const { cellOtherProps, cellProps } = props;
    const { data } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    // let defaultValue = data.getShowObjectAt(rowIndex)[columnKey]
    const dataItem = data.getObjectAt(rowIndex);
    return formatDeppProptotype(columnKey, dataItem);
  };
  // merge header 传入的属性 和 行 otherProps 里面的属性
  mergeProps = props => {
    const { cellOtherProps, columnProps, cellProps } = props;
    const { data } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    // let defaultValue = data.getShowObjectAt(rowIndex)[columnKey]
    const dataItem = data.getObjectAt(rowIndex);
    this._props = _.merge(columnProps, _.get(dataItem, ['otherProps', columnKey], {}));
    return this._props;
  };
  format = value => {
    const { format } = this._props;
    return format ? dataFormater(value || '', format) : value;
  };
  componentDidMount() {
    if (this.unEchoEvents.onSelect) {
      // 如果不响应下拉事件，释放 keydown 事件 交给 table 处理（切换焦点之类的）
      this.datalist.onKeyDown = e => this.onInputKeyDown(e);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let nextValue = this.getValueFromProps(nextProps);
    const mp = this.mergeProps(nextProps);
    const { showKey } = mp;
    nextValue = this.format(nextValue);
    const lastVal = this.state.value;
    nextValue = _.isObject(nextValue) ? nextValue[showKey] : nextValue;
    if (!this.changing && lastVal !== nextValue) {
      // 当组件正在onChange时不允许修改值,主要是不知道为什么总是一个劲的调用UNSAFE_componentWillReceiveProps
      this.lastSelectValue = nextValue;
      this.setState({
        value: nextValue,
      });
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    // 拒绝props,只通过state跟新.
    const equal = _.isEqual(nextState, this.state);
    return !equal || nextProps.width !== this.props.width;
  }
  onInputKeyDown = e => {
    this.props.onInputKeyDown(e, this.props.rowIndex, this.props.cellOtherProps.colIndex);
  };
  /**
   * 获取远程数据
   * url: 地址
   * reqBase: 请求其他参数
   * reqPath: 输入框值映射到reqBase路径 (如该值为假,只请求一次数据,即不根据输入框内容实时请求数据)
   * resPath: 返回值路径
   */
  fetchData = () => (keyWord, dataList) => {
    if (this.fetchLock) {
      return;
    }
    const { url, reqBase = {}, reqPath, resPath = [] } = this._props.fetchProps;
    const req = reqBase;
    if (!reqPath) {
      // 当没有 reqPath 时只请求一次
      this.fetchLock = true;
    } else {
      _.set(req, reqPath, keyWord);
    }
    dataList
      .fetch(url, {
        method: 'POST',
        body: { req },
      })
      .then(res => {
        this.setState({
          data: _.get(res, resPath, []),
        });
      });
  };
  onChange = eventName => e => {
    let value;
    this.changing = eventName === 'onChange'; // 用户是否正在输入
    const { showKey } = this._props;
    if (eventName === 'onBlur' && this.unEchoEvents.onBlur && this.lastSelectValue) {
      this.setState({
        value: this.lastSelectValue || '',
      });
      return;
    }
    if (eventName === 'onSelect') {
      value = e[showKey];
      this.lastSelectValue = value;
      if (value === undefined) {
        value = this.state.value;
      }
    } else {
      value = e.target ? e.target.value : e;
    }
    if (!this.changing) {
      value = this.format(value);
    }
    this.setState({
      value,
    });
    if (this.unEchoEvents[eventName] && value) {
      // 此处的不响应事件 是指 不给table提供回掉
      return;
    }
    const { rowIndex, columnKey } = this.props.cellProps;
    this.props.onChange(rowIndex, columnKey, e.target ? e.target.value : e);
  };
  render() {
    const { cellProps, cellOtherProps, width, height, fetchProps = this._props.fetchProps } = this.props;
    const { multiple, required } = this._props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, colIndex } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;

    // let defaultValue = data.getShowObjectAt(rowIndex)[columnKey]
    const dataItem = data.getObjectAt(rowIndex);
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <DataList
          ref={ref => (this.datalist = ref)}
          data-path={`${columnKey}_${rowIndex}_${colIndex}`}
          data={this.state.data}
          multiple={multiple}
          required={required}
          filter={fetchProps ? this.filter : undefined}
          value={this.state.value}
          {...this.events}
          disabled={isInOtherPropsArray(dataItem, columnKey, 'disable')}
          header={this.header}
          menuClassName={`datalistcell_${columnKey}_menu`}
        />
      </Cell>
    );
  }
}
