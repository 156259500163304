exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-label{color:#333}.fn-label>i{padding-right:2px}.fn-label--disabled{color:#ccc;background-color:#fff}.fn-label--hover{color:#2db7f5;background-color:#2db7f5}.fn-label--required{color:red}.fn-label--required>i{display:inline}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-label",
	"fn-label": "fn-label",
	"fn-label--disabled": "fn-label--disabled",
	"fn-label--hover": "fn-label--hover",
	"fn-label--required": "fn-label--required"
};