import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Input from 'components/utilcomponents/input/BaseInput';
import Icon from 'components/utilcomponents/icon/Icon';
import { prefixCls } from './index.scss';

const tips = (
  <span>
    当设置大于0时，表示大于等于 0；
    <br />
    当设置大于非0数字时，表示大于这个非0数字。
    <br />
    精确到小数点后6位。
  </span>
);

export default class RangeInput extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.array,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    zeroTip: PropTypes.bool,
  };

  static getDisplayText = (val, label) => (Array.isArray(val) ? `${val[0] || 0}<${label}<=${val[1] || '无穷大'}` : '');

  static validate = val => !(val && ((val[0] && Number.isNaN(val[0])) || (val[1] && Number.isNaN(val[1]))));

  state = {};

  onChange = (index, val) => {
    this.value = this.value || [];
    this.value[index] = val;
    this.props.onChange(this.value);
  };

  onFocus = () => this.setState({ focused: true });

  onBlur = () => this.setState({ focused: false });

  refMin = r => (this.min = r && r._input);

  render() {
    const { className, label, disabled, zeroTip } = this.props;
    const value = this.props.value || [];
    this.value = value;
    let min = value[0];
    let max = value[1];
    if (+max === 99999999) max = '';
    if (min === '0.000000') min = '';
    if (+min === 0 && zeroTip && !this.state.focused) min = '';
    const classes = classnames({
      [className]: className,
      [`${prefixCls}--disabled`]: disabled,
      [`${prefixCls}`]: [`${prefixCls}`],
    });
    const placeholder = zeroTip ? '0(含0)' : '0';
    return (
      <div className={classes}>
        <Input
          defaultValue={min}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={e => this.onChange(0, e.target.value)}
        />
        &nbsp;&lt;&nbsp;{label}&nbsp;&lt;=&nbsp;
        <Input
          defaultValue={max}
          disabled={disabled}
          placeholder="无穷大"
          onChange={e => this.onChange(1, e.target.value)}
        />
        {zeroTip && <Icon iconType="icon-help" tips={tips} />}
      </div>
    );
  }
}
