exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-print-table{width:100%;border-collapse:collapse;border-spacing:0}.fn-print-table td,.fn-print-table th{max-width:300px;padding:2px 3px;border:1px solid #ccc;text-align:center}.fn-print-table tfoot tr,.fn-print-table thead tr{background-color:#fff}.fn-print-table tfoot tr th,.fn-print-table thead tr th{background-color:#fff;line-height:30px}.fn-print-table tbody tr{background-color:#fff}.fn-print-table tbody tr td{padding:2px 3px;text-align:left}.fn-print-table tbody tr td.text-right{text-align:right}.fn-print-table tbody tr td.text-center{text-align:center}.fn-print-table tbody tr td p{margin:0;padding:0;line-height:18px}.fn-print-table tbody tr td p.single{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.fn-table-a{margin:0 auto;background-color:#fff;width:100%;height:100%;border-collapse:collapse;border-spacing:0;table-layout:fixed}.fn-table-a td,.fn-table-a th{min-width:30px;border:1px solid #ddd;text-align:center;color:#333}.fn-table-a td{padding:4px}.fn-table-a td.text-left{text-align:left}.fn-table-a td.text-right{text-align:right}.fn-table-a thead th{height:30px;background-color:#ecf0f5;white-space:nowrap;line-height:30px}.fn-table-a tbody td{max-width:300px;word-break:break-all;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.fn-table-a tbody td:hover{text-overflow:inherit;overflow:visible}.fn-table-a tbody td.al{text-align:left}.fn-table-a tbody td.ar{text-align:right}.fn-table-a__wrap tbody td{white-space:inherit}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-print-table",
	"fn-print-table": "fn-print-table",
	"text-right": "text-right",
	"text-center": "text-center",
	"single": "single",
	"fn-table-a": "fn-table-a",
	"text-left": "text-left",
	"al": "al",
	"ar": "ar",
	"fn-table-a__wrap": "fn-table-a__wrap"
};