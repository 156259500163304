import React from 'react';

function withDragFunctions(WrappedComponent) {
  class WithDragFunctions extends React.PureComponent {
    static defaultProps = {
      drag: true,
    };
    constructor(props) {
      super(props);
      this.state = {
        isDragging: false,
        dragEnterCount: 0,
      };
    }
    handleDragEnter = event => {
      event.preventDefault();
      this.setDragEnterCount(this.state.dragEnterCount + 1);
    };
    handleDragLeave = event => {
      event.preventDefault();
      /**
       * 拖拽到子元素身上时，将在父元素身上触发 dragleave 和 dragenter
       * 记录拖拽的触发次数，dragenter 时 +1, dragleave 时 -1
       * 当 dragEnterCount等于0时，表示鼠标已经离开当前节点，则拖动状态被取消。
       * 参考：https://github.com/arco-design/arco-design/issues/210
       */
      if (this.state.dragEnterCount === 0) {
        this.setIsDragging(false);
      } else {
        this.setDragEnterCount(this.state.dragEnterCount - 1);
      }
    };

    handleDrop = (event, cb) => {
      event.preventDefault();
      if (!this.props.disabled && this.props.drag) {
        cb(event.dataTransfer.files, true);
      }
      this.setIsDragging(false);
    };

    handleDragOver = event => {
      event.preventDefault();
      if (!this.props.disabled && !this.state.isDragging) {
        this.setIsDragging(true);
      }
    };

    setDragEnterCount = n => this.setState({ dragEnterCount: n });

    setIsDragging = v => {
      const { dragElement } = this.props;
      this.setState({ isDragging: v });
      this.setDragEnterCount(0);
      if (v) {
        dragElement && dragElement.classList.add('uploadFileIsDragging');
      } else {
        dragElement && dragElement.classList.remove('uploadFileIsDragging');
      }
    };
    render() {
      const { forwardedRef, ...rest } = this.props;
      return (
        <WrappedComponent
          ref={forwardedRef}
          {...rest}
          {...this.state}
          handleDragEnter={this.handleDragEnter}
          handleDragLeave={this.handleDragLeave}
          handleDrop={this.handleDrop}
          handleDragOver={this.handleDragOver}
        />
      );
    }
  }
  return React.forwardRef((props, ref) => {
    return <WithDragFunctions {...props} forwardedRef={ref} />;
  });
}
export { withDragFunctions };
