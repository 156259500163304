import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { TABLE_FOOTER_NORMAL_HEIGHT } from 'constants';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { CheckBoxV2, Tips } from 'components';
import FooterOperater from './FooterOperater';
import { showTotalCheckbox } from '../helper/getSumVal';
import { isEmpty } from 'lodash';

export default class FooterCell extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      _flag_: false, // 用于触发组件更新
    };
    props.refGetter && props.refGetter('footer', props.columnKey, this);
  }

  static propTypes = {
    rowIndex: PropTypes.number,
    data: PropTypes.object,
    columnKey: PropTypes.string,
    operates: PropTypes.object,
    refGetter: PropTypes.func,
    handleOperateClick: PropTypes.func,
    enableOperate: PropTypes.bool,
    selectesLength: PropTypes.number, // 保持props简洁 用不到selectes 只传长度即可
  };
  forceRender = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ _flag_: !this.state._flag_ });
  };
  getShowPrefix = (bool, type) => {
    if (bool) {
      if (type === 'sum') {
        return '求和';
      }
      if (type === 'avg') {
        return '平均';
      }
    }
    return '';
  };
  render() {
    const _p = this.props;
    const {
      columnKey,
      columnProps,
      enableOperate = true,
      totalChecked = {},
      style = {},
      totalTip,
      totalStyle,
      isShowAvg,
    } = _p;
    const avgObj = _p.data.getShowObjectAt('footerCalculate')?.avg;
    const footerAvg = avgObj?.[columnKey];
    let footerTotal = _p.data.getShowObjectAt('footerTotal')[columnKey];
    const footerSelect = _p.data.getShowObjectAt('footerSelect')[columnKey];
    const height = {};
    if (isShowAvg && !isEmpty(avgObj)) {
      height.minHeight = `${TABLE_FOOTER_NORMAL_HEIGHT * 2}px`;
    }

    if (columnProps.type === 'CheckBoxText') {
      // const { showDisableCheckbox, showEmptyCheckbox } = columnProps
      const { showEmptyCheckbox } = columnProps;
      const text = footerTotal;
      const emptyText = text === '' || text === '0' || text === undefined || parseFloat(text, 10) === 0;
      const lableText = emptyText ? '' : text;
      const showCheckbox = showTotalCheckbox(_p.data.getSize(), _p.data, columnKey);
      // footerTotal = ((this.isDisabled && !showDisableCheckbox) || !enableOperate || (emptyText && !showEmptyCheckbox)) ?
      footerTotal =
        !showCheckbox || !enableOperate || (emptyText && !showEmptyCheckbox) ? (
          <span key={Math.random()}>{lableText}</span>
        ) : (
          <CheckBoxV2
            useDefaultCls={false}
            checked={totalChecked[columnKey]}
            label={lableText}
            disabled={!enableOperate}
            onClick={(e, checked) => _p.handleFooterCheckBoxTextClick(columnKey, checked)}
          />
        );
    } else if (columnKey === 'operate' && footerTotal) {
      footerTotal = <FooterOperater operates={footerTotal} onClick={_p.handleOperateClick} />;
    }
    const isShowTotalPrefix = footerTotal && !_p.footerTitleBottom && footerAvg;
    const sumTotalPrefix = this.getShowPrefix(isShowTotalPrefix, 'sum');
    const avgTotalPrefix = this.getShowPrefix(isShowTotalPrefix, 'avg');
    let total = (
      <p style={{ width: _p.width || 'auto', ...totalStyle, ...height }}>
        <span>
          {sumTotalPrefix}
          {footerTotal || _p.footerTitleBottom || ''}
        </span>
        {footerAvg ? (
          <span>
            {avgTotalPrefix}
            {footerAvg || ''}
          </span>
        ) : null}
      </p>
    );

    if (totalTip)
      total = (
        <Tips title={totalTip} className="fn-table__footer_total_tip">
          {total}
        </Tips>
      );
    const isShowAvgPrefix = footerSelect?.total && footerSelect?.avg && !_p.footerTitleTop;
    const sumSelectPrefix = this.getShowPrefix(isShowAvgPrefix, 'sum');
    const avgSelectPrefix = this.getShowPrefix(isShowAvgPrefix, 'avg');
    return (
      <Cell style={style}>
        <div className="fn-table__footer">
          {_p.selectesLength > 0 && (
            <>
              <p style={{ width: _p.width || 'auto', ...height }}>
                <span>
                  {sumSelectPrefix}
                  {footerSelect?.total || _p.footerTitleTop || ''}
                </span>
                {footerSelect?.avg ? (
                  <span>
                    {avgSelectPrefix}
                    {footerSelect?.avg || ''}
                  </span>
                ) : null}
              </p>
              {/* <p style={{ width: _p.width || 'auto' }}>{footerSelect || _p.footerTitleTop || ''}</p> */}
            </>
          )}
          {total}
        </div>
      </Cell>
    );
  }
}
