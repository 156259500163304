exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-input-pure{border:1px solid rgba(34,36,38,.2);font-size:12px;background:#fff;padding:3px 5px;outline:0;border-radius:2px;color:#000;cursor:text;width:100%;height:100%}.fn-input-pure:focus{background:#e2f7ff}.fn-input-pure:focus,.fn-input-pure:hover{border:1px solid #0097cf}.fn-input-pure.invalid,.fn-input-pure.invalid:focus{background:#fff5f5;border:1px solid #ff4040}.fn-input-pure.warn,.fn-input-pure.warn:focus{background:#fff5e3;border:1px solid #f37100}.fn-input-pure[disabled]{color:#333;background-color:#f2f9ff;border-color:#c1c1c1;cursor:no-drop}.fn-input-pure__suffix{position:absolute;right:4px;top:3px;color:#999}.fn-input-pure-wrap{width:140px;height:24px;position:relative;display:inline-block;vertical-align:middle}.fn-input-pure-wrap .input-icon{width:auto;height:auto}.fn-input-pure-wrap .fn-checkbox-pure,.fn-input-pure-wrap .input-icon{position:absolute;top:50%;margin-top:-6px;right:5px;color:#0097cf;font-size:14px;line-height:14px}.fn-input-pure-wrap .fn-checkbox-pure+.input-icon,.fn-input-pure-wrap .fn-checkbox-pure+.input-tip,.fn-input-pure-wrap .input-icon+.input-icon,.fn-input-pure-wrap .input-icon+.input-tip{right:22px;line-height:12px}.fn-input-pure-wrap .fn-checkbox-pure.toggle-icon+.input-tip,.fn-input-pure-wrap .input-icon.toggle-icon+.input-tip{right:5px}.fn-input-pure-wrap .fn-icon-add-rad{width:18px;height:18px;position:absolute;right:8px;top:7px}.input-tip{position:absolute;top:50%;margin-top:-7px;right:5px;font-size:0}.input-tip .fn-icon{width:auto;height:auto;color:#0097cf;font-size:14px;line-height:14px}.input-tip+.input-icon,.input-tip+.input-tip{right:22px}.input-tip.input-tip--invalid{margin-top:-5px;color:#f12222;font-size:12px;line-height:12px}.input-tip.input-tip--warn{margin-top:-5px;color:#f37100;font-size:12px;line-height:12px}.input-title{background:#fff;box-shadow:0 4px 3px 0 rgba(0,0,0,.11);border-radius:2px;font-size:14px;color:#333;padding:0 11px;word-break:break-all}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-input-pure",
	"fn-input-pure": "fn-input-pure",
	"invalid": "invalid",
	"warn": "warn",
	"fn-input-pure__suffix": "fn-input-pure__suffix",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"input-icon": "input-icon",
	"fn-checkbox-pure": "fn-checkbox-pure",
	"input-tip": "input-tip",
	"toggle-icon": "toggle-icon",
	"fn-icon-add-rad": "fn-icon-add-rad",
	"fn-icon": "fn-icon",
	"input-tip--invalid": "input-tip--invalid",
	"input-tip--warn": "input-tip--warn",
	"input-title": "input-title"
};