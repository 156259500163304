import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { getPageCount, getStrWidth } from 'utils';
import { Input, Select, Icon } from 'components';
import { prefixCls } from './index.scss';

export default class Page extends PureComponent {
  constructor(props) {
    super(props);
    const pageTotal = props.pageTotal || 0;
    const pageSize = props.pageSize || 0;
    const pageCount = getPageCount(pageTotal, pageSize);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isShow: false,
      pageSize, // 内部需要进行操作,
      pageCount: pageCount || 1,
      pageNum: props.pageNum, // 第几页、
      expend: !props.toggle,
    };
    this.pageSize = pageSize;
    this.isShow = false;
  }

  static defaultProps = {
    classname: '',
    pageNum: 1,
    listStyle: {},
    disabled: false,
    pages: [
      { key: 100, name: '100' },
      { key: 200, name: '200' },
      { key: 500, name: '500' },
      { key: 1000, name: '1000' },
    ],
  };
  static propTypes = {
    classname: PropTypes.string,
    disabled: PropTypes.bool, // 是否展示下拉列表
    pageNum: PropTypes.number, // 当前页码
    pageSize: PropTypes.number, // 单页条数
    pageTotal: PropTypes.number, // 总条数
    pageCount: PropTypes.number, // 总页数
    onClick: PropTypes.func, // input 点击
    prevPage: PropTypes.func, // 上一页
    nextPage: PropTypes.func, // 下一页
    listStyle: PropTypes.object, // 下拉列表的样式
    pages: PropTypes.array, // 条数配置
    toggle: PropTypes.bool, // 是否可切换折叠状态
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pageSize, pageTotal, pageNum } = nextProps;
    this.setState({
      pageSize: pageSize !== 0 ? pageSize : 100,
      pageNum,
      pageCount: getPageCount(pageTotal, pageSize !== 0 ? pageSize : 100),
    });
  }

  setPage = prop => {
    const { pageSize = this.state.pageSize, pageNum = this.state.pageNum } = prop;
    this.setState({ pageSize, pageNum });
  };
  handleSelect = value => {
    if (!value) return;
    this.setState({ pageSize: value });
    this.props.nextPage(value, 0);
  };
  _jumpToPage = pageNum => {
    this.props.nextPage && this.props.nextPage(this.state.pageSize, 'jump', pageNum);
  };
  prevPage = () => {
    const { pageNum = 1 } = this.state;
    if (pageNum - 1 >= 1 && this.props.prevPage) {
      this.props.prevPage(this.state.pageSize, -1);
    }
  };
  nextPage = () => {
    const { pageCount, pageSize } = this.state;
    if (this.props.pageNum + 1 <= pageCount && this.props.nextPage) {
      this.props.nextPage(pageSize, 1);
    }
  };
  firstPage = () => {
    const { pageNum = 1 } = this.state;
    if (pageNum > 1) {
      this._jumpToPage(1);
    }
  };
  lastPage = () => {
    if (this.state.pageNum < this.state.pageCount) {
      this._jumpToPage(this.state.pageCount);
    }
  };
  pageNumChange = e => {
    const pageNum = e.target.value;
    this.setState({ pageNum });
  };
  jumpToPage = e => {
    let pageNum = e.target.value;
    pageNum = parseInt(pageNum, 10);
    if (e.keyCode === 13) {
      if (Number.isNaN(pageNum)) pageNum = this.props.pageNum;
      if (pageNum > this.state.pageCount) pageNum = this.state.pageCount;
      if (pageNum < 1) pageNum = 1;
      if (pageNum === this.props.pageNum) {
        this.setState({ pageNum });
        return;
      }
      this._jumpToPage(pageNum);
    }
  };
  toggleExpend = () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      expend: !this.state.expend,
      expend_by_hover: false,
    });
  };
  handleMouseEnter = () => {
    this.setState({
      expend_by_hover: true,
    });
  };
  handleMouseLeave = () => {
    this.setState({
      expend_by_hover: false,
    });
  };
  // pageSize 任何时候不得为0
  render() {
    let numberStart = 0;
    let numberEnd = 0;
    const { pageNum = 1, pageTotal = 0, toggle, className } = this.props;
    let { pageSize = 100 } = this.state;

    const { pageCount = 1, expend, expend_by_hover: hover } = this.state;

    if (pageSize === 0) pageSize = 100;
    let lastCount = pageTotal % pageSize;
    lastCount = lastCount === 0 ? pageTotal : lastCount;
    // 只有一页的
    if (pageCount === 1) {
      numberStart = 0;
      numberEnd = lastCount;
    } else if (pageNum === pageCount) {
      // 尾页
      numberStart = (pageNum - 1) * pageSize;
      numberEnd = numberStart + lastCount;
      if (numberEnd > pageTotal) {
        numberEnd = pageTotal;
      }
    } else if (pageTotal === 0) {
      // 0 数据情况
      numberStart = 0;
      numberEnd = 0;
    } else {
      // 多余一页的非尾页
      numberStart = (pageNum - 1) * pageSize;
      numberEnd = numberStart + pageSize;
    }
    const selectW = getStrWidth(`${numberStart}-${numberEnd}`, 12) + 22;
    const showDetail = (toggle && expend) || !toggle || hover;
    return (
      <div className={`${prefixCls} ${className || ''} ${showDetail && 'expend'}`}>
        {showDetail && (
          <Fragment>
            <div className="control__page">
              <i onClick={this.firstPage} className={`fn-icon fn-icon-forward-l ${pageNum === 1 ? 'disabled' : ''}`} />
              <i onClick={this.prevPage} className={`fn-icon fn-icon-arrow-l ${pageNum === 1 ? 'disabled' : ''}`} />
            </div>
            <div className="jump__page">
              第
              <Input
                classname="fn-input"
                defaultValue={this.state.pageNum}
                onChange={this.pageNumChange}
                onKeyDown={this.jumpToPage}
              />
              页/共
              <span>{pageCount}</span>页
            </div>
            <div className="control__page">
              <i
                onClick={this.nextPage}
                className={`fn-icon fn-icon-arrow-r ${pageNum >= pageCount ? 'disabled' : ''}`}
              />
              <i
                onClick={this.lastPage}
                className={`fn-icon fn-icon-forward-r ${pageNum >= pageCount ? 'disabled' : ''}`}
              />
            </div>
          </Fragment>
        )}
        <Select
          style={{ width: selectW }}
          filter={false}
          value={pageSize}
          data={this.props.pages}
          format={() => `${numberStart}-${numberEnd}`}
          onChange={this.handleSelect}
        />
        {toggle && (
          <Icon
            classname="toggle"
            iconType={!expend ? 'icon-zhankai' : 'icon-shouqi'}
            onClick={this.toggleExpend}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          />
        )}
      </div>
    );
  }
}
