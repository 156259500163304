/**
 * Created by JANY on 2017/1/19.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { prefixCls, liItemWidth } from './index.scss';
// import { Step } from './Step'
// 坐标统一用逗号分隔的字符串
/**
 *  mode = 'strict' 功能还不完善， 具体需求用到的再补充
 */
export default class Steps extends Component {
  constructor(prop) {
    super(prop);
    this.state = {
      currStep: prop.currStep,
      // eslint-disable-next-line react/no-unused-state
      status: {}, //  状态： default(灰底无任何状态)、 done(完成)、 curr（当前）
      itemWidth: liItemWidth || 100,
    };
  }

  static propTypes = {
    classname: PropTypes.string, // 地址框样式
    direction: PropTypes.string, // 方向 暂时无效 // TODO
    mode: PropTypes.string, // 模式 srict(严格步骤，无回退，currStep之前都为done), select（可点选，只有default, curr 两个状态）
    setps: PropTypes.any.isRequired,
    currStep: PropTypes.number,
    handleClick: PropTypes.func,
    wrapWidth: PropTypes.number,
  };
  static defaultProps = {
    direction: 'horizontal', // 方向 horizontal vertical(竖直方向)
    mode: 'select',
    currStep: 0, // 从0 开始
    wrapWidth: 700,
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.currStep !== this.props.currStep) {
      this.setState({ currStep: nextprops.currStep });
    }
  }

  getCurrStep = () => this.state.currStep;
  setCurrStep = i => this.setState({ currStep: i });
  onClick = (i, s, e) => {
    const { mode } = this.props;
    if (mode === 'strict') return;
    this.setState({ currStep: i });
    this.props.handleClick && this.props.handleClick(i, s, e);
  };
  getStepClass = (step, currStep, mode) => {
    let c = {
      [`${prefixCls}__curr`]: currStep === step,
    };
    if (mode === 'strict') {
      c = Object.assign(c, {
        [`${prefixCls}__done`]: currStep > step,
      });
    }
    return classnames(c);
  };

  render() {
    const { currStep, itemWidth } = this.state;
    const { classname, setps, mode, wrapWidth } = this.props;
    const classes = classnames({
      [prefixCls]: true,
      classname,
    });
    const stepCount = setps.length;
    return (
      <div className={classes} style={{ width: this.props.wrapWidth }}>
        <ul className="clearfix">
          {setps.map((s, i) => {
            const width = i === stepCount - 1 ? itemWidth : (wrapWidth - itemWidth) / (stepCount - 1);
            return (
              <li
                key={i}
                style={{ width }}
                className={this.getStepClass(i, currStep, mode)}
                onClick={e => this.onClick(i, s, e)}
              >
                {s.number && <span>{s.number || 1}</span>}
                {s.title && <h6>{s.title || ''}</h6>}
                {s.desc && <p>{s.desc || ''}</p>}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
// (<Step
//   number={s.number}
//   title={s.title}
//   descr={s.desc}
//   handleClick={(...arg) => this.props.handleClick(s, ...arg)}
// />)
// export default ({ data }) => ( // eslint-disable-line
//   <Steps>
//     {data.length && data.map(({ title, description }, index) => (
//       <Step title={title} key={index} description={description} />
//     ))}
//   </Steps>
// )
// div>
//   <ul>
//     <li>
//       <span>1</span>
//       <h6>第一步</h6>
//       <p>选择模板</p>
//     </li>
//     <li>
//       <span>2</span>
//       <h6>第二步</h6>
//       <p>设计模板</p>
//     </li>
//   </ul>
// </div>
// <div>
// <ul>
// <li>
// <span>1</span>
// <h6>第一步</h6>
// <p>选择模板</p>
// </li>
// <li>
// <span>2</span>
// <h6>第二步</h6>
// <p>设计模板</p>
// </li>
// </ul>
// </div>
