import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DragList, ButtonIcon, ListItem, CheckBoxV2 as CheckBox, PopUp, PopTip } from 'components';
import { INFO } from 'constants';
import { prefixCls } from './index.scss';

const comStyle = { width: '180px', height: '237px', maxHeight: 'none' };
const titleConfig = {
  setFinanceHeader: {
    left: '非财务数据',
    right: '财务数据',
  },
  setHeader: {
    left: '隐藏列',
    right: '显示列',
  },
  setFilter: {
    left: '未选中字段',
    right: '选中字段',
  },
  setSort: {
    left: '未选中字段',
    right: '选中字段',
  },
  setComFun: {
    left: '未选功能',
    right: '已选功能',
  },
  setImportMerge: {
    left: '导入报错',
    right: '默认按第一项导入',
  },
  default: {
    left: '隐藏列',
    right: '显示列',
  },
};
export default class TypeSet extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      leftList: this.props.leftList || [],
      rightList: this.props.rightList || [],
      stickyIndex: this.props.stickyIndex,
      stickyRightIndex: this.props.stickyRightIndex,
      // eslint-disable-next-line react/no-unused-state
      isShow: false,
    };
    this.btns = [];
    this.selectLeftList = [];
    this.selectRightList = [];
    this.rightNewIn = [];
  }

  static propTypes = {
    title: PropTypes.string,
    leftList: PropTypes.array,
    rightList: PropTypes.array,
    uniqueKey: PropTypes.string,
    searchKey: PropTypes.string,
    leftListProps: PropTypes.object,
    rightListProps: PropTypes.object,
    leftItemProps: PropTypes.object,
    rightItemProps: PropTypes.object,
    titleType: PropTypes.string,
    isSort: PropTypes.bool,
    stickyIndex: PropTypes.number,
    stickyRightIndex: PropTypes.number,
    maxCountTip: PropTypes.string,
  };
  static defaultProps = {
    uniqueKey: 'key',
    searchKey: 'title',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { leftList = [], rightList = [], stickyIndex, stickyRightIndex } = nextProps;
    this.setState({ leftList, rightList, stickyIndex, stickyRightIndex });
  }

  handleAllLeftToRight = () => {
    if (!this.state.leftList.length) {
      return;
    }
    this.setState({
      leftList: [],
      // eslint-disable-next-line react/no-access-state-in-setstate
      rightList: [...this.state.rightList, ...this.state.leftList],
    });
    this.resetSelect();
  };
  handleAllRightToLeft = () => {
    this.setState({
      rightList: [],
      // eslint-disable-next-line react/no-access-state-in-setstate
      leftList: [...this.state.rightList, ...this.state.leftList],
    });
    this.resetSelect();
  };
  handleLeftSelect = selectList => {
    if (selectList === -1) return;
    const checkedAll = selectList.length === this.state.leftList.length;
    this.selectLeftList = selectList;
    this.leftCheckBox.setValue(checkedAll);
  };
  handleRightSelect = selectList => {
    if (selectList === -1) return;
    const checkedAll = selectList.length === this.state.rightList.length;
    this.selectRightList = selectList;
    this.rightCheckBox.setValue(checkedAll);
  };
  formatData = data => {
    Object.entries(data).map(([key, value]) => ({ ...value, key }));
  };

  resetSelect() {
    this.selectLeftList = [];
    this.selectRightList = [];
    this.leftListReactDom().resetSelect(); // 清空子组件选中的list
    this.rightListReactDom().resetSelect(); // 清空子组件选中的list
    this.leftCheckBox.setValue(false);
    this.rightCheckBox.setValue(false);
  }

  handleLeftToRight = (selected = this.selectLeftList) => {
    if (!selected.length) return;
    if (this.props.isSort) {
      // 如果是列表排序功能
      const stateRightList = this.state.rightList;
      const stateLeftList = this.state.leftList;
      const tmpArr = [];
      for (const type of selected) {
        if (tmpArr.includes(type.sortKey)) {
          new PopUp(PopTip, {
            type: INFO,
            content: '同一属性不能同时设置升序和降序',
          }).show();
          return;
        }
        tmpArr.push(type.sortKey);
      }
      const toRightList = [];
      const rightNewIn = [];
      const leftNewIn = [];
      selected.forEach(item => {
        const index = stateRightList.findIndex(value => value.sortKey === item.sortKey);
        if (index !== -1) {
          // 如果移动的sortKey右边存在，需要将其移到左边
          const [toLeftItem] = stateRightList.splice(index, 1, item); // 将替换的值放在原来位置
          rightNewIn.push(item);
          leftNewIn.push(toLeftItem);
          stateLeftList.push(toLeftItem);
          // itemIndex !== undefined ? stateLeftList.splice(itemIndex, 0, toLeftItem) : stateLeftList.push(toLeftItem)
        } else {
          toRightList.push(item); // 不需要替换的值放在最后添加到state.rightList最后
        }
      });
      this.rightNewIn = rightNewIn.length ? rightNewIn : toRightList;
      if (leftNewIn.length) {
        this.leftNewIn = leftNewIn;
        this.leftShouldScroll = true;
      }
      this.rightShouldScroll = true;
      setTimeout(() => (this.rightShouldScroll = false), 200);
      this.setState({
        rightList: [...stateRightList, ...toRightList],
        leftList: this.filterList(stateLeftList, selected),
      });
    } else {
      this.rightNewIn = selected;
      this.rightShouldScroll = true;
      setTimeout(() => (this.rightShouldScroll = false), 200);
      // 需要考虑当前冻结项在末尾的情况
      let { stickyRightIndex } = this.state;
      let rightList = [...this.state.rightList];
      if (stickyRightIndex !== undefined) {
        stickyRightIndex += selected?.length;
        rightList.splice(stickyRightIndex, 0, ...selected);
        // 由于stickyRightIndex用来判断拖拽范围，所以需要更新
      } else {
        rightList = [...rightList, ...selected];
      }
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        rightList,
        // eslint-disable-next-line react/no-access-state-in-setstate
        leftList: this.filterList(this.state.leftList, selected),
        stickyRightIndex,
      });
    }
    this.resetSelect();
  };
  selectAllLeftList = async (e, leftChecked) => {
    const res = await this.leftListReactDom().selectAll(leftChecked, 'left');
    if (!res) {
      this.leftCheckBox.setValue(false);
    }
    this.selectLeftList = res || [];
  };
  handleRightToLeft = (selected = this.selectRightList) => {
    if (!selected.length) return;
    let { stickyIndex, stickyRightIndex } = this.state;
    // 手动清除冻结项数据，否则会展示异常
    selected.forEach(item => {
      if (item?.ext?.sticky) {
        item.ext.sticky = false;
        stickyIndex -= 1;
      }
      if (item?.ext?.sticky_right) {
        item.ext.sticky_right = false;
        stickyRightIndex -= 1;
      }
      item.tips = '';
      item.dragAble = true;
    });
    this.leftNewIn = selected;
    this.leftShouldScroll = true;
    setTimeout(() => (this.leftShouldScroll = false), 200);
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      leftList: [...this.state.leftList, ...selected],
      // eslint-disable-next-line react/no-access-state-in-setstate
      rightList: this.filterList(this.state.rightList, selected),
      stickyIndex,
      stickyRightIndex,
    });
    this.resetSelect();
  };
  selectAllRightList = async (e, rightChecked) => {
    this.selectRightList = (await this.rightListReactDom().selectAll(rightChecked)) || [];
  };
  filterList = (source, target) => {
    if (!source || !source.length) return [];
    if (!target || !target.length) return source;
    const { uniqueKey } = this.props;
    const targetKeys = target.map(item => item[uniqueKey]);
    return source.filter(item => !targetKeys.includes(item[uniqueKey]));
  };
  handleDoubleLeftClick = (e, index, item) => this.handleLeftToRight([item], index);
  handleDoubleRightClick = (e, index, item) => this.handleRightToLeft([item], index);
  handleRightDraged = (rightList, dragIndex, dropIndex, newDragedItems) => {
    this.rightNewIn = [...this.rightNewIn, ...newDragedItems];
    this.setState({ rightList });
    // this.resetSelect()
  };
  handleItemDragAble = data => {
    const tags = data?.data?.tags || [];
    return !tags.includes('freezeImg');
  };
  leftListReactDom = () => this._leftListReactDom && this._leftListReactDom.child;
  rightListReactDom = () => this._rightListReactDom && this._rightListReactDom.child;

  render() {
    const classes = {
      typeset: prefixCls,
      btns: `${prefixCls}__btns`,
      btnsContent: `${prefixCls}__btns__content`,
      listLeft: `${prefixCls}__left`,
      listRight: `${prefixCls}__right`,
      btnClass: `${prefixCls}__btn`,
      btnIcon: `${prefixCls}__btn__icon`,
    };
    const { leftListProps, rightListProps, leftItemProps, rightItemProps, searchKey, uniqueKey } = this.props;
    const titleType = this.props.titleType || 'default';
    return (
      <div className={classes.typeset}>
        <div className={classes.listLeft}>
          <div className="hide-row-wrap">
            <div className="hide-row">
              <CheckBox ref={r => (this.leftCheckBox = r)} onClick={this.selectAllLeftList} useState />
              <span style={{ marginRight: '8px' }}>{titleConfig[titleType].left}</span>
            </div>
            <DragList
              isShowCheckColumn
              handleDoubleClick={this.handleDoubleLeftClick}
              data={this.state.leftList}
              style={comStyle}
              isMultiple
              isShowSearch
              isSearchInside
              autoColWidth={false}
              dragable={false}
              isShow
              childrenType="ListItem"
              ref={r => (this._leftListReactDom = r)}
              uniqueKey={uniqueKey}
              searchKey={searchKey}
              getScrollToIndex={() => (this.leftShouldScroll ? this.state.leftList.length - 1 : 0)}
              newInMarkRed
              newIn={this.leftNewIn || []}
              keepFilter
              {...leftListProps}
            >
              <ListItem onClick={this.handleLeftSelect} {...leftItemProps} />
            </DragList>
          </div>
          <div className="hide-row-bottom">
            <span>双击字段，可快速左右切换。</span>
          </div>
        </div>
        <div className={classes.btns}>
          <ButtonIcon
            buttonClass={classes.btnClass}
            classname={classes.btnIcon}
            iconType="icon-arrow-sright"
            type="default"
            onClick={() => this.handleLeftToRight()}
            ref={r => this.btns.push(r)}
          />
          <ButtonIcon
            buttonClass={classes.btnClass}
            classname={classes.btnIcon}
            iconType="icon-arrow-sleft"
            type="default"
            onClick={() => this.handleRightToLeft()}
            ref={r => this.btns.push(r)}
          />
        </div>
        <div className={classes.listRight}>
          <div className="show-row-wrap">
            <div className="show-row">
              <CheckBox ref={r => (this.rightCheckBox = r)} onClick={this.selectAllRightList} useState />
              <span>{titleConfig[titleType].right}</span>
              <span className="count-tip">（共{this.state.rightList.length}项）</span>
            </div>
            <DragList
              isShowCheckColumn
              data={this.state.rightList}
              defaultSelected={this.selectRightList}
              handleDoubleClick={this.handleDoubleRightClick}
              handleDraged={this.handleRightDraged}
              handleItemDragAble={this.handleItemDragAble}
              style={comStyle}
              isMultiple
              isShowSearch
              isSearchInside
              dragable
              isShow
              autoColWidth={false}
              childrenType="ListItem"
              ref={r => (this._rightListReactDom = r)}
              uniqueKey="key"
              searchKey="title"
              getScrollToIndex={() => (this.rightShouldScroll ? this.state.rightList.length - 1 : 0)}
              newInMarkRed
              newIn={this.rightNewIn || []}
              keepFilter
              isNeedRightClear
              stickyIndex={this.state.stickyIndex}
              stickyRightIndex={this.state.stickyRightIndex}
              {...rightListProps}
            >
              <ListItem onClick={this.handleRightSelect} {...rightItemProps} />
            </DragList>
          </div>
          <div className="show-row-bottom">
            <span>拖拽字段，可上下调整排序位置。</span>
            {this.props.maxCountTip ? <span style={{ color: '#FF6600' }}>* {this.props.maxCountTip}</span> : null}
          </div>
        </div>
      </div>
    );
  }
}
