import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { Input } from 'components';
import TableCell from './TableCell';
import { DatePicker, Tabs, Trigger } from '@gui/web-react';
import { isInOtherPropsArray } from './../utils';

const RangePicker = DatePicker.RangePicker;
const TabPane = Tabs.TabPane;
const INFINITE_TIME = '9999-12-31 00:00:00';

export default class DateTimeCell extends TableCell {
  static propTypes = {
    onChange: PropTypes.func,
    showTime: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      type: 'time',
      popupVisible: false,
    };
  }

  setPopupVisible(visible) {
    this.setState({ popupVisible: visible });
  }

  render() {
    const { cellProps, cellOtherProps, columnProps, onChange } = this.props;
    const { data } = cellOtherProps;
    const { rowIndex, columnKey, width, height } = cellProps;
    const { required = true, placeholder = '请选择起始时间~请选择结束时间' } = columnProps;
    const style = { width: width - 3, height: height - 2, padding: '0 1px' };
    const defaultValue = data.getShowObjectAt(rowIndex)[columnKey];
    const formattedValue = defaultValue.length
      ? defaultValue.map(item => (!item || item === INFINITE_TIME ? '下次调价时' : item)).join('~')
      : '';

    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <Trigger
          showArrow
          className="range-date-combo"
          trigger="click"
          popupVisible={this.state.popupVisible}
          popup={() => (
            <Tabs onClick={e => e.stopPropagation()}>
              <TabPane key="1" title="选择起始时间，结束时间未定">
                <DatePicker
                  showTime
                  triggerElement={null}
                  defaultValue={defaultValue[0]}
                  onSelect={startTime => onChange(rowIndex, columnKey, [startTime, INFINITE_TIME])}
                  onOk={val => {
                    onChange(rowIndex, columnKey, [val, INFINITE_TIME]);
                    this.setPopupVisible(false);
                  }}
                />
              </TabPane>
              <TabPane key="2" title="选择起始时间和结束时间">
                <RangePicker
                  showTime
                  triggerElement={null}
                  defaultValue={defaultValue}
                  onSelect={([startTime, endTime]) =>
                    startTime && endTime && onChange(rowIndex, columnKey, [startTime, endTime])
                  }
                  onOk={val => {
                    onChange(rowIndex, columnKey, val);
                    this.setPopupVisible(false);
                  }}
                />
              </TabPane>
            </Tabs>
          )}
          onClickOutside={() => {
            this.setPopupVisible(false);
          }}
        >
          <Input
            style={style}
            type="text"
            defaultValue={formattedValue}
            placeholder={placeholder}
            required={required}
            disabled={isInOtherPropsArray(data.getObjectAt(rowIndex), columnKey, 'disable')}
            readOnly
            onClick={() => this.setPopupVisible(true)}
          />
        </Trigger>
      </Cell>
    );
  }
}
