import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { PureInput as Input } from 'components';
import { showTip, hideTip } from 'utils';
import { prefixCls } from './index.scss';

export default class SegmentedInput extends PureComponent {
  static propTypes = {
    headLabel: PropTypes.string, // 头部标签 默认 '0'
    tailLabel: PropTypes.string, // 尾部标签 默认 '无穷大'
    value: PropTypes.array,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    tips: PropTypes.string,
    pattern: PropTypes.string,
    maxLength: PropTypes.number, // 最大区间个数
    strict: PropTypes.bool, // 严格模式，更加严谨的交互和校验
  };

  static defaultProps = {
    headLabel: '0',
    tailLabel: '无穷大',
    tips: '',
  };

  onChange = value => this.props.onChange(value);

  changeText = (e, index, origin) => {
    const value = [...origin];
    value[index] = e.target.value;
    this.onChange(value);
  };

  add = origin => {
    const value = [...origin];
    value.push('');
    this.onChange(value);
  };

  minus = origin => {
    const value = [...origin];
    value.pop();
    this.onChange(value);
  };

  showTip = e => {
    showTip(e.currentTarget, { content: <span>{this.props.tips}</span> });
  };

  render() {
    const {
      inputClassName,
      className,
      headLabel,
      tailLabel,
      disabled,
      value = [],
      tips,
      pattern,
      strict,
      maxLength,
    } = this.props;
    const classes = classnames({
      [className]: className,
      [`${prefixCls}--disabled`]: disabled,
      [`${prefixCls}`]: [`${prefixCls}`],
    });
    return (
      <div className={classes} onMouseEnter={tips ? this.showTip : undefined} onMouseLeave={tips ? hideTip : undefined}>
        {headLabel && <span>{headLabel}&nbsp;-&nbsp;</span>}
        {value.map((item, index) => (
          <Input
            key={index}
            type="text"
            className={inputClassName}
            value={item}
            disabled={disabled}
            readOnly={disabled}
            onChange={e => this.changeText(e, index, value)}
            customValidity={v => {
              if (!strict) return '';
              if (!v) return '必填';
              // 当输入了两个值时才需要进行校验
              if (index > -1) {
                if (+v <= +value[index - 1]) return `区间填写不正确[${value[index - 1]}-${v}]`;
              }
            }}
            pattern={pattern}
          >
            &nbsp;-&nbsp;
          </Input>
        ))}
        {tailLabel && <span>{tailLabel}</span>}
        <button disabled={disabled || (strict && value.length + 1 >= maxLength)} onClick={() => this.add(value)}>
          <i className="fn-icon-il fn-icon-add-rad" />
        </button>
        <button disabled={disabled || (strict && !value.length)} onClick={() => this.minus(value)}>
          <i className="fn-icon-il fn-icon-minus-rad" />
        </button>
      </div>
    );
  }
}
