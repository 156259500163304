import React from 'react';
import { PopUp, ModalDialog } from 'components';
import MapBox from './box';
import './index.scss';
import { poi2cytStyle } from './utils';
import { getPageKey } from 'utils';

/**
 * POI地址选择器
 * @param {object} options 配置项
 * @param {string} options.appName 接入地址库的产品标识，必传
 * @param {string|number} options.groupId 集团id，必传
 * @param {boolean} options.isCreate 新建地址模式，该模式不展示已经存在在地址库中的内容，仅新增
 * @param {object} options.defaultValue 默认poi，至少包含 poi 字段、show_val 字段，非必传
 * @param {string} options.defaultValue.poi 经纬度字符串，若存在 defaultValue，则必传
 * @param {string} options.defaultValue.show_val 地址名, 若存在 defaultValue，则必传
 * @param {function}
 * @example
 * const res = await POISelector({
 *    appName: 'cyt',
 *    groupId: '123',
 *    defaultValue: {
 *      show_val: '天府新区',
 *      poi: '104.069786,30.501692',
 *      ...other
 *    }
 * });
 * @returns {promise}
 */
const POISelector = options => {
  return new Promise(resolve => {
    let _dialog = null;

    const handleConfirm = res => {
      _dialog.handleHide();
      resolve(poi2cytStyle(res));
    };
    const content = <MapBox config={options} onConfirm={handleConfirm} key="map-box" />;
    const pageKey = getPageKey().pageConfig.key;

    new PopUp(ModalDialog, {
      ref: r => (_dialog = r),
      title: options?.title ?? '地址选择',
      content,
      isModal: false,
      popName: `AMapPOISelector_${pageKey}`,
      isShow: true,
      classname: options?.isCreate || options?.isModify ? 'pop-amap-poi-selector fullscreen' : 'pop-amap-poi-selector ',
    }).show();
  });
};

export default POISelector;
