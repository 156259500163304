exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-icon--disabled{color:#f7f7f7}.fn-icon--active{color:#2db7f5}.fn-icon_word_icon{display:inline-block;width:20px;height:20px;cursor:pointer;vertical-align:top}.ant-badge .fn-icon{width:28px;height:28px;font-size:14px;line-height:28px;text-align:center;color:#0097cf}.ant-badge .ant-badge-count{height:12px;padding:0 3px;min-width:0;top:0;font-size:10px;line-height:12px;-webkit-transform:translateX(-75%);transform:translateX(-75%);background:#ff5015}.ant-badge .ant-badge-count .current{font-size:12px;-webkit-transform:scale(.9);transform:scale(.9);position:relative;top:-1px}.tableCellorderState{position:relative}.tableCellorderState:after{display:none;position:absolute;content:\"...\";width:12px;height:18px;color:#333;top:0;right:2px}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-icon",
	"fn-icon--disabled": "fn-icon--disabled",
	"fn-icon--active": "fn-icon--active",
	"fn-icon_word_icon": "fn-icon_word_icon",
	"ant-badge": "ant-badge",
	"fn-icon": "fn-icon",
	"ant-badge-count": "ant-badge-count",
	"current": "current",
	"tableCellorderState": "tableCellorderState"
};