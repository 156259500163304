/**
 * 颜色设置的icon
 */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Icon } from 'components';
import './index.scss';

export default class ColorTips extends PureComponent {
  static propTypes = {
    // 颜色设置规则
    colorRule: PropTypes.array,
  };
  static defaultProps = {};
  /**
   * @DateTime 2017-10-24
   * @desc:    {[生成tips提示框]}
   * @return   {[type]}        [description]
   */
  renderColorRule = () => {
    const colorRule = this.props.colorRule || [];
    return !colorRule.length ? undefined : (
      <div className="color-rule-tips">
        {colorRule.map((item, index) => (
          <div className="color-rule-item" key={index}>
            <span>
              <div className="color-display" style={{ backgroundColor: item.color }}>
                &nbsp;
              </div>
              <div className="color-text">
                <p>{item.display}</p>
              </div>
            </span>
          </div>
        ))}
        <div className="introductions">
          <p className="text-red">* 说明：</p>
          <p className="text-red">如果设置的底色规则字段在列表里不显示，则该规则不生效。</p>
          <p>列表底色由管理员统一设置，如需调整请联系总部管理员。</p>
        </div>
      </div>
    );
  };

  // iconType={'icon-gaojingyanseshengyinshezhi'}
  render() {
    const colorRule = this.props.colorRule || [];
    return (
      <div className="color-rule-icon" style={{ background: colorRule[0].color }}>
        {colorRule.length && (
          <Icon
            tips={this.renderColorRule()}
            tipsCls="color_set_tips"
            tipsStyle={{ background: '#FFF5E3', border: '#F37100', color: '#000' }}
          />
        )}
      </div>
    );
  }
}
