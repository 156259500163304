import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ERROR, CHECK } from 'constants';
import { PopUp, UploadImgCamera } from 'components';
import {
  showInfo,
  viewImage,
  fetchJAVA,
  popUploadImageModal,
  asynUploadImg,
  hasPermission,
  hideTip,
  showTip,
} from 'utils';
import classnames from 'classnames';

import './index.scss';

class UploadImgList extends PureComponent {
  isValidityCom = true;
  static propTypes = {
    imgInfo: PropTypes.array,
    imgType: PropTypes.string.isRequired, // 图片类型

    labelText: PropTypes.string, // 字段标题
    maxSize: PropTypes.number, // 每张照片最大几M
    maxNum: PropTypes.number, // 最多上传几张
    imgListCls: PropTypes.string, // 组件最外层的类名
    outSideUpdate: PropTypes.bool, // 是否需要在外部控制组件状态
    popTitle: PropTypes.string, // 弹窗标题
    popTxt: PropTypes.string, // 弹窗内，文字标题
    iconTxt: PropTypes.string, // 上传图标后面显示的文字
    disabledAll: PropTypes.bool, // 查看模式
    iconRemove: PropTypes.bool, // 列表list的数字右上角删除
    iconRemoveFetch: PropTypes.bool, // 列表list的数字右上角删除 是否调用接口
    viewRemove: PropTypes.bool, // 查看图片是是否允许删除
    removeUrl: PropTypes.string, // 查看图片时删除照片的接口地址
    getDelReq: PropTypes.func, // 查看图片时删除照片的接口入参
    onChange: PropTypes.func, // 图片变化后的事件回调
    required: PropTypes.bool, // 是否必填，只用于cardForm标红
    isRequired: PropTypes.bool, // 是否必填，只用于cardForm标红
    showCamera: PropTypes.bool, // 是否显示拍照上传
    asynUrl: PropTypes.string, // // 单个图片上传时的接口
    isAsyn: PropTypes.bool, // 是否支持图片是一张一张上传的。默认为false
    asynReq: PropTypes.object, // 单个图片上传时向后端返回的参数
    noteElem: PropTypes.oneOfType([PropTypes.string, PropTypes.element]), // 提示性内容，位于弹框内标题的后方
    ellipsis: PropTypes.number, // 多少张图片后，显示省略号。 默认 0，不显示
    hideIconWhenDisable: PropTypes.bool, // 是否在禁用时隐藏图标
  };
  static defaultProps = {
    maxSize: 3,
    maxNum: 10,
    noteElem: '',
    iconTxt: '',
    popTxt: '上传图片',
    popTitle: '上传图片',
    labelText: '上传图片',
    outSideUpdate: false,
    imgListCls: '',
    disabledAll: false,
    iconRemove: false,
    viewRemove: false,
    removeUrl: '',
    isRequired: false,
    showCamera: false,
    isAsyn: true,
    asynReq: {},
    asynUrl: '/Basic/Image/uploadImages',
    imgType: 'receipt_send',
    ellipsis: 0,
    hideIconWhenDisable: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      imgInfo: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const rawImgInfo = nextProps.imgInfo;
    const nextImgInfo = rawImgInfo && 'imgInfo' in rawImgInfo ? rawImgInfo.imgInfo : rawImgInfo;
    if (this.props.outSideUpdate && nextImgInfo !== this.state.imgInfo) {
      this.setState({ imgInfo: nextImgInfo || [] });
    }
  }
  UNSAFE_componentWillMount() {
    const rawImgInfo = this.props?.imgInfo;
    if (Array.isArray(rawImgInfo)) {
      this.setState({ imgInfo: rawImgInfo || [] });
    }
  }
  imgChange = imgList => {
    const { onChange } = this.props;
    this.setState({ imgInfo: imgList }, () => {
      setTimeout(this.checkValidity, 50);
    });
    onChange && onChange({ imgInfo: imgList });
    console.log(' === imgChange === ', imgList);
  };

  handleImgShowVal = ({ /* photoImg, photoNum, */ photoProps }) => {
    const imgVal = Object.keys(photoProps).map(x => photoProps[x]);
    return imgVal;
  };

  uploadPop = async () => {
    const { imgType, maxNum, maxSize, popTitle, popTxt, noteElem, isAsyn, asynUrl } = this.props;
    const { imgInfo } = this.state;

    const restNum = maxNum - imgInfo.length;
    const proxyConf = {
      type: imgType,
      props: {
        maxNum: restNum,
        maxSize,
        isAsyn,
        asynUrl,
        cameraShow: true,
        labelText: popTxt,
        tips: noteElem || `最多上传${restNum}张`,
      },
      popTitle,
    };
    const popRes = await popUploadImageModal(proxyConf);
    console.log(' === uploadPop === ', popRes);
    const changeImgs = !isAsyn ? this.handleImgShowVal(popRes) : popRes;
    this.imgChange([...imgInfo, ...(changeImgs || [])]);
  };

  delPic = async proxy => {
    const { idx, src, imgViewRef } = proxy;
    const { imgInfo } = this.state;
    const { removeUrl, getDelReq } = this.props;

    const item = imgInfo[idx];

    const doFetch = removeUrl && src !== 'icon';
    const delReq = getDelReq && doFetch ? getDelReq(item) : { ...item };

    const res = doFetch ? await fetchJAVA(removeUrl, { method: 'POST', body: { delReq } }) : { errno: 0 };
    if (res.errno !== 0) {
      res.msg && showInfo(ERROR, res.msg);
      return;
    }

    const newImgInfo = [...imgInfo];
    newImgInfo.splice(idx, 1);
    this.imgChange(newImgInfo);
    doFetch && showInfo(CHECK, '删除成功');
    imgViewRef && imgViewRef.modal && imgViewRef.modal.handleHide();
    console.log(' === delPic === ', newImgInfo);
    return newImgInfo; // 返回一个值，用于外部调用
  };

  viewPicCallBack = () => {
    console.log(' === viewPicCallBack === ');
  };

  viewImageFunc = (item, idx) => {
    const { viewRemove, disabledAll } = this.props;
    const { imgInfo } = this.state;

    const props = {
      ...item,
      showDeleteButton: !disabledAll && viewRemove,
      extraDel: (req, imgViewRef) => this.delPic({ idx, imgViewRef }),
      callback: resData => this.viewPicCallBack(resData, item),
    };
    viewImage(props, imgInfo, {
      withDelete: !disabledAll && viewRemove,
      customDeleteFun: (_item, index) => this.delPic({ idx: index }),
    });
  };

  iconRemoveFunc = idx => {
    const { disabledAll } = this.props;
    if (disabledAll) {
      return false;
    }
    this.delPic({ src: 'icon', idx });
    console.log(' === removeImg === ', idx);
  };

  handleCamera = async rst => {
    const { maxNum, isAsyn, asynUrl, asynReq } = this.props;
    const { imgInfo } = this.state;

    const restNum = maxNum - imgInfo.length;

    if (restNum < 1) {
      return showInfo(ERROR, `最多上传${maxNum}张图片`);
    }
    const _name = rst.name;

    const _img = { src: rst.src, formdata: rst.formdata, name: _name };
    const onChangeParam = isAsyn ? await asynUploadImg({ _img, asynUrl, asynReq }) : _name;
    console.log(' ==== handleCamera  ==== ', onChangeParam);

    this.imgChange([...imgInfo, ...onChangeParam]);
  };
  handleOpenCamera = () => {
    new PopUp(UploadImgCamera, { upLoadCb: this.handleCamera, isModal: true }).show();
  };

  refWrap = r => (this.wrapRef = r);

  checkValidity = async () => {
    const { required, isRequired, customValidity } = this.props;
    const { imgInfo } = this.state;
    let tip = '';
    if ((required || isRequired) && !imgInfo?.length) {
      tip = '必填';
    } else if (customValidity) {
      tip = await customValidity(imgInfo);
    }
    this.setState({ tip });
    return tip;
  };

  focus = () => {};

  blur = () => {
    hideTip();
  };

  showTip = e =>
    this.state.tip &&
    this.wrapRef &&
    showTip(this.wrapRef, { content: <span>{this.state.tip}</span>, className: 'popover--error' });

  render() {
    const {
      labelText,
      imgListCls,
      iconTxt,
      maxNum,
      disabledAll,
      iconRemove,
      isRequired,
      showCamera,
      ellipsis,
      hideIconWhenDisable,
    } = this.props;
    const { imgInfo, tip } = this.state;

    const restNum = maxNum - imgInfo.length;

    const newImgInfo = [...imgInfo];

    const ellipsisLen = ellipsis || 3; // 自定义最多显示多少张图片，ellipsis 优先级更高

    // 超过3张 详情模式显示... ，或者超过指定的ellipsis数量，显示...
    if ((disabledAll && newImgInfo.length > ellipsisLen) || (ellipsis && newImgInfo.length > ellipsisLen)) {
      newImgInfo.length = ellipsisLen + 1;
    }

    const showCameraElem =
      showCamera && hasPermission('upload_photo') ? (
        <i className="fn-icon fn-icon-photo" onClick={!disabledAll && this.handleOpenCamera} />
      ) : null;

    const iconElem =
      +restNum !== 0 && (!hideIconWhenDisable || !disabledAll) ? (
        <div className="upload">
          <i className="fn-icon fn-icon-upload1" onClick={!disabledAll && this.uploadPop} />
          {
            // showCameraElem
          }
          <span onClick={!disabledAll && this.uploadPop}> {iconTxt} </span>
        </div>
      ) : null;

    // 超过3张 详情模式显示...
    const imgListElem = newImgInfo.map((item, index) => (
      <a className="pic-list-item" key={item.name}>
        {(ellipsis && index >= ellipsis) || (index >= ellipsisLen && disabledAll) ? (
          <span onClick={() => this.viewImageFunc(item, ellipsisLen)}>[...]</span>
        ) : (
          <span onClick={() => this.viewImageFunc(item, index)}>[{index + 1}]</span>
        )}
        {iconRemove && !disabledAll && (
          <i className="fn-icon-il fn-icon-error-o" onClick={() => this.iconRemoveFunc(index)} />
        )}
      </a>
    ));

    const labelClass = `fn-label ${isRequired ? 'fn-label--required' : ''}`;

    return (
      <div
        ref={this.refWrap}
        className={classnames(`img-list-wrap`, imgListCls, { invalid: tip, field: true })}
        onMouseEnter={this.showTip}
        onMouseLeave={hideTip}
      >
        {labelText && <div className={labelClass}>{labelText}</div>}
        <div className="imgs-list">
          {iconElem}
          {imgListElem}
        </div>
      </div>
    );
  }
}

export default UploadImgList;
