import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  TABLE_HEADER_HEIGHT,
  EMPTY_VAL_MAP,
  SORTICONCLS,
  NEWEXACTSEARCHTIPS,
  NEWWUNEXACTSEARCHTIPS,
  FRONT_ONLY_TIPS,
  ASSEMBLE_FILTER_TYPES,
  HEADER_TYPE_TO_EMPTY,
} from 'constants';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import classnames from 'classnames';
import {
  Input,
  Icon,
  RangeTimePicker,
  DateTimePicker,
  SelectDrop,
  MonthPicker,
  TextArea,
  CheckBoxV2,
  Cascader,
  Tips,
} from 'components';
import { getFilterType, typeIs } from 'utils';

export default class HeaderCell extends PureComponent {
  static propTypes = {
    rowIndex: PropTypes.number,
    colIndex: PropTypes.number,
    data: PropTypes.object,
    columnKey: PropTypes.string,
    onClick: PropTypes.func,
    operates: PropTypes.object,
    columnProps: PropTypes.object,
    header: PropTypes.object,
    oriHeader: PropTypes.object, // 全量header 筛选格式化用到表头隐藏字段
    sortList: PropTypes.object,
    currFilter: PropTypes.object,
    isShowSort: PropTypes.bool,
    sortable: PropTypes.any, // TODO 暂时排除warning
    isChecked: PropTypes.bool,
    checkable: PropTypes.bool,
    isShowFilterRow: PropTypes.bool,
    isShowQuickFilter: PropTypes.bool,
    isQuickFilterShow: PropTypes.bool,
    isQuickFilterActive: PropTypes.bool,
    entryKeyBatchSearch: PropTypes.bool,
    isShowContextMenu: PropTypes.bool,
    // tmp
    enumerations: PropTypes.object,
    handleSearchFilter: PropTypes.func,
    handleHeaderClick: PropTypes.func,
    headerContextMenu: PropTypes.func,
    handleFilterChangeDate: PropTypes.func,
    _handleFilterChange: PropTypes.func,
    handleFitlerSelected: PropTypes.func,
    customizesingleFilter: PropTypes.func,
    style: PropTypes.object,
  };
  filterRowRef = {};
  getOriFilterVal = (columnKey, _filterValues) => {
    let v = _filterValues[columnKey];
    if (_filterValues[columnKey] === undefined) {
      ASSEMBLE_FILTER_TYPES.some(k => {
        if (_filterValues[`assemble_${k}`] === columnKey) {
          v = _filterValues[`assemble_${k}_value`];
          return true;
        }
        return false;
      });
    }
    return v;
  };
  getFilterCell = (columnKey, _p) => {
    let filterCell = {};
    const constructData = _p?.constructData;
    const isMultiHeader = constructData?.isRoot;
    const upValue = isMultiHeader ? constructData?.id : _p.data.getShowObjectAt('headerTitle')[columnKey];
    const titleCol = {
      reference: upValue === '序号' ? upValue : <Icon iconType={`${upValue}`} style={{ cursor: 'pointer' }} />,
      checkbox: <CheckBoxV2 onClick={this.handleHeaderClick} checked={!!upValue} />, // 表头勾选全部 点击位置问题
      operateColumn: '操作',
      substract: <Icon iconType={`${upValue}`} />,
    };
    const isFrontOnly = _p.markFrontOnly && (_p.filterable === 'front_only' || _p.sortable === 'front_only');
    const upTips = _p.markFrontOnly
      ? FRONT_ONLY_TIPS(_p.filterable === 'front_only', _p.sortable === 'front_only')
      : '';
    const upCls = isFrontOnly ? 'front_only_title' : '';
    let up = titleCol[columnKey] || <em title={upValue}>{upValue}</em>;
    // checkbox 特殊处理
    if (_p.type === 'CheckBox' && columnKey !== 'checkbox')
      up = _p.checkable ? (
        <CheckBoxV2 label={upValue.title} onClick={this.handleHeaderClick} checked={upValue.checked} />
      ) : (
        <em title={upValue.title}>{upValue.title}</em>
      );
    if (!_p.isShowFilterRow) return { up, down: null };
    let monthClass = 'table-filter-column-input';
    const filterType = getFilterType(_p, _p.enumerations);
    const _filterValues = _p.data.getShowObjectAt('headerFilter');
    const oriFilterValue = this.getOriFilterVal(columnKey, _filterValues); // _filterValues[columnKey]
    const filterValue = EMPTY_VAL_MAP[oriFilterValue] !== undefined ? '' : oriFilterValue; // 空值 非空值筛选时 控制标记替换为空值
    const customizesingleFilter =
      (this.props.customizesingleFilter &&
        this.props.customizesingleFilter({
          filterWhere: 'header',
          key: columnKey,
          filterProps: { type: filterType, otherProps: { ..._p } },
          currFilterValues: () => _filterValues,
          header: this.props.oriHeader,
        })) ||
      {};
    delete customizesingleFilter.type;
    const filterProps = _p.filterProps || {};
    switch (filterType) {
      case 'None':
        filterCell = _p.filterTitle;
        break;
      case 'DateText':
      case 'DateTime':
      case 'DateYmdText':
      case 'RangeTimePicker':
        filterCell = (
          <RangeTimePicker
            disabled={_p.filterable === 'false'}
            onChange={value => _p.handleFilterChangeDate(value, columnKey)}
            defaultValue={filterValue}
            allowClear={_p.allowClear}
            showTime={_p.showTime}
            format={_p.type === 'DateText' ? 'YY-MM-DD HH:mm:ss' : 'YY-MM-DD'}
            forceFormat
            disableType={_p.disableType}
            style={{ width: `${_p.width - 3}px !important` }}
            {...filterProps}
            {...customizesingleFilter}
          />
        );
        break;
      case 'DateTimePicker':
        filterCell = (
          <DateTimePicker
            disabled={_p.filterable === 'false'}
            onOpenChange={value => _p.handleFilterChangeDate(value, columnKey)}
            defaultValue={typeIs(filterValue, 'array') ? filterValue[0] : filterValue}
            allowClear={_p.allowClear}
            showTime={_p.showTime}
            format={_p.type === 'DateText' ? 'YY-MM-DD HH:mm:ss' : 'YY-MM-DD'}
            forceFormat
            style={{ width: `${_p.width - 3}px !important` }}
            {...filterProps}
            {...customizesingleFilter}
          />
        );
        break;
      case 'DateYmText':
      case 'MonthPicker':
        monthClass = 'table-filter-column-input month-data';
        filterCell = (
          <MonthPicker
            isRange={_p.isRange !== undefined ? _p.isRange : true}
            disabled={_p.filterable === 'false'}
            onChange={value => _p.handleFilterChangeDate(value, columnKey)}
            defaultValue={filterValue}
            style={{ width: `${_p.width - 3}px !important` }}
            placeholer=""
            {...filterProps}
            {...customizesingleFilter}
          />
        );
        break;
      case 'TextArea': {
        // 表头批量搜索 是否精确匹配
        const extColumnKey = `${columnKey}._exact_`;
        if (columnKey.includes('order_num')) {
          filterProps.iconType = 'icon-scan';
          filterProps.iconTips = '支持输入和扫码挑单';
        }
        up = (
          <CheckBoxV2
            title={upValue}
            label={upValue}
            isSwitchStyle
            stopLabelClick
            onLabelClick={this.handleHeaderClick}
            tipsGetter={checked =>
              checked ? `当前${upValue}${NEWEXACTSEARCHTIPS}` : `当前${upValue}${NEWWUNEXACTSEARCHTIPS}`
            }
            classname="key_exact_checkbox"
            tipDirect="top"
            useState
            checked={!!_filterValues[extColumnKey]}
            onClick={(e, checked) => this.handleHeaderClick(e, checked, extColumnKey)}
            {...customizesingleFilter}
          />
        );
        filterCell = (
          <TextArea
            style={{ width: 'calc(100% - 3px)', marginLeft: '-2px' }}
            classname="table-filter-textarea"
            disabled={_p.filterable === 'false'}
            onChange={(...args) => _p.handleFilterChange(columnKey, ...args)}
            onBlur={(...args) => _p.handleFilterBlur(columnKey, ...args)}
            onKeyDown={_p.entryKeyBatchSearch ? _p._handleFilterChange(columnKey, true) : undefined}
            placeholder="支持批量搜索"
            defaultValue={filterValue}
            {...filterProps}
            {...customizesingleFilter}
            autoTrim={false}
          />
        );
        break;
      }
      case 'Input':
        filterCell = (
          <Input
            style={{ width: '100%' }}
            disabled={_p.filterable === 'false'}
            onChange={(...args) => _p.handleFilterChange(columnKey, ...args)}
            onBlur={(...args) => _p.handleFilterBlur(columnKey, ...args)}
            onKeyDown={_p._handleFilterChange(columnKey)}
            defaultValue={filterValue}
            {...filterProps}
            {...customizesingleFilter}
          />
        );
        break;
      case 'SelectDrop': {
        const _isMultiple = filterProps.isMultiple !== undefined ? filterProps.isMultiple : _p.isMultiple;
        const isMultiple = _isMultiple || _isMultiple === undefined || _isMultiple === null || _isMultiple === 'null';
        const { selectEmpty } = _p;
        const useDefEmptyValue = _p.empty_value === null || _p.empty_value === undefined;
        const emptyVal = EMPTY_VAL_MAP[HEADER_TYPE_TO_EMPTY[_p.columnType] || ''];
        // eslint-disable-next-line no-nested-ternary
        const logicEmptyVal = useDefEmptyValue ? (Array.isArray(emptyVal) ? emptyVal[0] : emptyVal) : _p.empty_value;
        filterCell = (
          <SelectDrop
            fieldName={columnKey}
            disabled={_p.filterable === 'false'}
            handleSelected={(value, ...args) => {
              let val = value;
              if (!isMultiple) {
                val = value ? [value] : [];
              }
              _p.handleFitlerSelected(columnKey)(val, ...args);
            }}
            uniqueKey={_p.uniqueKey || 'key'}
            showKey={_p.showKey || 'name'}
            map={false}
            selectOnly={isMultiple}
            blurCheck
            selectedAllIn1
            valueOfEmpty={selectEmpty ? logicEmptyVal : undefined}
            initFilterKey="sug"
            fetchApi={_p.fetchApi}
            allowClear={_p.allowClear}
            showHeader={_p.showHeader || false}
            tableHeader={_p.tableHeader || { [_p.showKey || 'name']: 'name' }}
            defaultSelected={filterValue}
            shouldFilter={_p.shouldFilter !== undefined ? _p.shouldFilter : true}
            readOnly={_p.shouldFilter !== undefined ? _p.shouldFilter : false}
            data={
              // eslint-disable-next-line no-nested-ternary
              _p.headerRefEnum
                ? _p.enumerations[_p.headerRefEnum] || []
                : _p.fetchApi
                ? []
                : _p.enumerations[_p.refEnum]
            }
            fetchOnFocus={_p.fetchOnFocus}
            {...filterProps}
            isMultiple={isMultiple}
            {...customizesingleFilter}
          />
        );
        break;
      }
      case 'Cascader': {
        filterCell = (
          <Cascader
            {..._p.columnProps}
            fieldNames={
              _p.fieldNames ?? {
                /* Custom field name for label */
                label: 'label',
                /** Custom field name for value */
                value: 'value',
                /** Custom field name for children */
                children: 'children',
                /** Custom field name for disabled  */
                disabled: 'disabled',
                /** Custom field name for isLeaf */
                isLeaf: 'isLeaf',
              }
            }
            handleSelected={(value, ...args) => _p.handleFitlerSelected(columnKey)(value, ...args)}
            options={
              // 优先级 options -> refEnum -> fetchApi -> headerRefEnum
              // eslint-disable-next-line no-nested-ternary
              _p.headerRefEnum
                ? _p.enumerations[_p.headerRefEnum] ?? _p.options ?? []
                : _p.fetchApi
                ? _p.options ?? []
                : _p.enumerations[_p.refEnum] ?? _p.options ?? []
            }
            fetchApi={_p.fetchApi}
            defaultValue={filterValue}
            size="mini"
            {...filterProps}
            {...customizesingleFilter}
          />
        );
        break;
      }
      case 'Text':
      case 'CheckBox':
      default:
        filterCell = (
          <Input
            style={{ width: '100%' }}
            disabled={_p.filterable === 'false'}
            onChange={(...args) => _p.handleFilterChange(columnKey, ...args)}
            onKeyDown={_p._handleFilterChange(columnKey)}
            defaultValue={filterValue}
            {...filterProps}
            {...customizesingleFilter}
          />
        );
        break;
    }

    return { up, down: <div className={monthClass}>{filterCell}</div>, upTips, upCls };
  };
  handleHeaderClick = (e, value, extColumnKey) => {
    const _p = this.props;
    _p.handleHeaderClick && _p.handleHeaderClick(e, extColumnKey || _p.columnKey, _p, value);
  };
  handleHeaderDoubleClick = (e, value, extColumnKey) => {
    const _p = this.props;
    _p.handleHeaderDoubleClick && _p.handleHeaderDoubleClick(e, extColumnKey || _p.columnKey, _p, value);
  };
  // 功能
  // 筛选、
  // 排序、
  // 勾选、
  // 快捷筛选、headerQuickFilterMenu
  render() {
    const _p = this.props;
    const { rowIndex = 'header', columnKey, colIndex, style } = _p;
    const isMultiTable = !!_p?.constructData;
    const validate = _p.validate || [];
    const rowSpan = _p?.constructData?.rowSpan || 1;
    const isRoot = _p?.constructData?.isRoot;
    const isBlank = _p?.columnKey === 'blank_cell';
    const headerRelationship = _p?.headerRelationship;
    const currentShip = headerRelationship?.[columnKey];
    // 表格border样式修复
    let isShowHeaderContextMenu = true;
    let minusValue = 0;
    if (currentShip?.children?.length && !currentShip?.allParents?.length) {
      minusValue = -1;
    }
    if (currentShip?.allParents?.length) {
      minusValue = 1;
    }
    if (!currentShip?.children?.length && currentShip?.allParents?.length) {
      isShowHeaderContextMenu = false;
    }

    const _style = (style && { ...style }) || {};
    if (validate.includes('required')) _style.color = 'red';
    const filterCell = this.getFilterCell(columnKey, _p);
    let up = (
      <span className="filter-header-wraper">
        {filterCell.up}
        {_p.addIcon && <Icon iconType="icon-add-rad" onClick={e => _p.headerAddIcon(e, columnKey)} />}
        {_p.tips && (
          <Icon
            isHtmlTips={_p.tipsIsDom}
            iconType="icon-help"
            tips={_p.tips}
            tipsProps={{ classname: `${columnKey}_tips header_${columnKey}_tips` }}
          />
        )}
        {_p.isShowSort && _p.sortable !== 'false' && !isRoot && (
          <Icon iconType={SORTICONCLS[_p.sortList[columnKey]] || 'icon-sort'} />
        )}
      </span>
    );
    if (filterCell.upTips)
      up = (
        <Tips title={filterCell.upTips} className="filter-header-wraper header_title_tips">
          {up}
        </Tips>
      );
    return (
      <Cell>
        <div
          ref={r => (this.filterRowRef[columnKey] = r)}
          className={classnames('table-filter-column table-filter-column-show', {
            'quick-filter-active': _p.isQuickFilterActive,
            'quick-filter-show': _p.isQuickFilterShow,
            [`t_header_${columnKey}`]: true,
          })}
          style={{ width: _p.width, textAlign: 'center', ..._style }}
        >
          <p
            className={classnames({
              align_left: _p.isShowQuickFilter,
              [filterCell.upCls]: filterCell.upCls,
              isShowFilterRow: _p.isShowFilterRow,
            })}
            onClick={this.handleHeaderClick}
            onContextMenu={
              _p.isShowContextMenu && !isRoot && isShowHeaderContextMenu
                ? e => _p.headerContextMenu(e, columnKey, rowIndex, colIndex)
                : undefined
            }
            onDoubleClick={this.handleHeaderDoubleClick}
            style={{
              height: Number(`${rowSpan * TABLE_HEADER_HEIGHT - minusValue}`),
            }}
          >
            {!isBlank && up}
            {!isBlank && _p.isShowQuickFilter && _p.quickFilter && (
              <Icon iconType="icon-sandg" onClick={e => _p.headerQuickFilterMenu(e, columnKey)} />
            )}
          </p>
          {!isRoot && !isBlank && !isMultiTable && filterCell.down}
        </div>
      </Cell>
    );
  }
}
