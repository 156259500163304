/**
 * 滚动提示
 */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import './index.scss';

export default class ColorTips extends PureComponent {
  static propTypes = {
    // 颜色设置规则
    tips: PropTypes.array,
  };
  static defaultProps = {};
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      height: props.height || 36,
      timer: props.timer || 5000,
    };
    setInterval(() => {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const current = this.state.current + 1;
      this.setState({
        current,
      });
    }, this.state.timer);
  }

  render() {
    const { current, height } = this.state;
    return (
      <div className="components-circle" style={this.props.style || {}}>
        <div className="components-circle--icon">
          <svg
            t="1624607152645"
            className="icon"
            viewBox="0 0 1170 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="18705"
            width="18"
            height="18"
          >
            <path
              d="M632.129014 4.900225a50.611278 50.611278 0 0 0-54.121887 6.874942L285.31011 257.005826 51.927757 256.055036a50.538141 50.538141 0 0 0-35.983742 14.920088 51.19638 51.19638 0 0 0-14.84695 36.130016L0 716.749316c0 13.530472 5.265913 26.622117 14.84695 36.276291 9.507899 9.654174 22.526407 15.066363 35.983741 15.066364h236.088448l291.087988 244.206731c15.212639 12.579682 36.276292 15.285776 54.121887 6.874942a51.269517 51.269517 0 0 0 29.255074-46.223017V51.19638a51.269517 51.269517 0 0 0-29.255074-46.296155z m197.325475 255.981898a50.465003 50.465003 0 0 0-67.140395 23.989161 51.708343 51.708343 0 0 0 23.257783 68.383736c3.14492 1.609029 79.354389 41.542205 79.354389 155.051893 0 121.189144-71.236105 161.414871-73.576511 162.731349a51.415793 51.415793 0 0 0-20.624828 67.652359 50.830691 50.830691 0 0 0 66.116468 23.989161c5.412189-2.706094 129.892529-67.506083 129.892529-254.446007 0-180.211256-131.794109-244.718695-137.352573-247.351652z m158.343088-150.371081a50.611278 50.611278 0 0 0-69.919627 15.285777 51.635206 51.635206 0 0 0 14.773812 70.797279c5.485326 3.656884 135.816682 93.031136 135.816682 315.442837 0 222.996802-124.992304 312.444191-130.038804 315.808524a51.415793 51.415793 0 0 0-14.042436 71.016693 50.684416 50.684416 0 0 0 70.577866 14.188711C1002.059426 908.370051 1170.202964 792.227407 1170.202964 512.036935c0-280.848711-174.945343-396.771943-182.405387-401.525893z"
              fill="#FF5015"
              p-id="18706"
            />
          </svg>
        </div>
        <div className="components-circle--content" style={{ height: `${height}px` }}>
          <div
            className="components-circle--content-item"
            style={{ top: `-${(current % this.props.tips.length) * height}px` }}
          >
            {this.props.tips.map((item, index) => (
              <p
                className={current === index ? 'active' : ''}
                style={{ height: `${height}px`, lineHeight: `${height}px` }}
              >
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
