exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".g7-area-cascader{display:inline-block}.g7-area-cascader .area-select .area-selected-trigger{padding:5px;font-size:12px}.g7-area-cascader .area-select{height:24px;border-radius:2px;border:1px solid rgba(34,36,38,.2)}.g7-area-cascader .area-select.medium{width:280px}.g7-area-cascader .cascader-menu-list .cascader-menu-option.selected{color:#532fe6}", ""]);

// exports
exports.locals = {
	"prefixCls": "g7-area-cascader",
	"g7-area-cascader": "g7-area-cascader",
	"area-select": "area-select",
	"area-selected-trigger": "area-selected-trigger",
	"medium": "medium",
	"cascader-menu-list": "cascader-menu-list",
	"cascader-menu-option": "cascader-menu-option",
	"selected": "selected"
};