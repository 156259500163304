/**
 * Created by Aceh on 2017/01/01.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { DatePicker } from 'components';
import { emptyFunction, deleteProps } from 'utils';
import { SUPPORT_DATETIME_FORMATS, NORMAL_DT_FORMAT, NORMAL_D_FORMAT } from 'constants';
import { prefixCls } from './index.scss';

export default class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    const { showTime, format = 'YYYY-MM-DD' } = props;
    let { defaultValue } = props;
    if (!moment(defaultValue).isValid()) {
      defaultValue = '';
    }
    let _format = format;
    if (showTime) {
      _format = format.split(' ');
      _format[1] = 'HH:mm:ss';
      _format = _format.join(' ');
    }
    this.state = {
      defaultValue,
      format: _format,
      showTime,
    };
    this.onOpenValue = defaultValue;
    this.currValue = defaultValue;
  }

  static propTypes = {
    showTime: PropTypes.bool,
    defaultValue: PropTypes.string,
    classname: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onOpenChange: PropTypes.func,
    format: PropTypes.string,
    forceFormat: PropTypes.bool,
    placeholder: PropTypes.string,
    allowClear: PropTypes.bool, // 是否显示日历后面的小叉号
    multiLine: PropTypes.bool, // 日期用textarea显示，可以换行
  };
  static defaultProps = {
    placeholder: '',
    allowClear: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { defaultValue } = nextProps;
    if (!moment(defaultValue).isValid()) {
      defaultValue = '';
    }
    if (defaultValue !== this.props.defaultValue || defaultValue !== this.state.defaultValue) {
      this.setState({ defaultValue });
    }
    if (nextProps.format !== this.props.format || this.props.showTime !== nextProps.showTime) {
      const { format = 'YYYY-MM-DD', showTime } = nextProps;
      let _format = format;
      if (showTime) {
        _format = format.split(' ');
        _format[1] = 'HH:mm:ss';
        _format = _format.join(' ');
      }
      this.setState({ format: _format });
    }
  }

  handleChange = moments => {
    const { onChange = emptyFunction, forceFormat } = this.props;
    const format = forceFormat ? 'YYYY-MM-DD HH:mm:ss' : this.state.format;
    const defaultValue = moments && moment(moments).format(format);
    this.currValue = defaultValue;
    this.setState({ defaultValue });
    setTimeout(() => onChange(defaultValue));
  };
  // 展开/收起
  onOpenChange = isOpen => {
    let val = this.currValue || '';
    const m = moment;
    const l = SUPPORT_DATETIME_FORMATS;
    val &&
      (val = moment(val, SUPPORT_DATETIME_FORMATS).format(this.state.showTime ? NORMAL_DT_FORMAT : NORMAL_D_FORMAT));
    if (!isOpen && this.onOpenValue !== val) {
      this.props.onOpenChange && this.props.onOpenChange(val);
    } else {
      this.onOpenValue = val;
    }
  };

  render() {
    const { classname, type, style, disabled, allowClear, placeholder, ...rest } = this.props;
    let { defaultValue } = this.state;
    const { format, showTime } = this.state;
    this.currValue = defaultValue;
    defaultValue = defaultValue ? moment(defaultValue, SUPPORT_DATETIME_FORMATS) : undefined;
    const classes = classnames({
      [classname]: classname,
      [`${prefixCls}--${type}`]: type,
      [`${prefixCls}--disabled`]: disabled,
    });
    const prop = deleteProps(rest, 'defaultValue');
    return (
      <DatePicker
        {...prop}
        style={{ width: '140px', ...style }}
        className={classes}
        format={format}
        showTime={showTime}
        onChange={this.handleChange}
        onOpenChange={this.onOpenChange}
        value={defaultValue}
        disabled={disabled}
        allowClear={allowClear}
        placeholder={placeholder}
      />
    );
  }
}
