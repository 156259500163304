/**
 * Created by Aceh on 2017/01/01.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Tips } from 'components';
import { prefixCls } from './index.scss';

export default class CheckBox extends PureComponent {
  static defaultProps = {
    classname: '',
    disabled: false,
    display: true,
    checked: false,
    useDefaultCls: true,
    stopPropagation: true,
    controlled: false,
    tipIsDom: false,
  };
  static propTypes = {
    label: PropTypes.any,
    classname: PropTypes.string,
    checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    name: PropTypes.string,
    direction: PropTypes.oneOf(['left', 'right']),
    disabled: PropTypes.bool,
    controlled: PropTypes.bool,
    useDefaultCls: PropTypes.bool,
    tipIsDom: PropTypes.bool,
    tips: PropTypes.any, // checkbox 上是否有 tips
    tipsGetter: PropTypes.func, // tips
    tipDirect: PropTypes.string, // tips  位置
    display: PropTypes.bool,
    dataPath: PropTypes.string, // 光标路径
    isSwitchStyle: PropTypes.bool, // 启用开关样式
    stopLabelClick: PropTypes.bool, // label点击是否触发checkbox
    onLabelClick: PropTypes.func, // label点击事件
    onLabelMouseDown: PropTypes.func, // label点击事件
    stopPropagation: PropTypes.bool,
    style: PropTypes.object,
  };

  constructor(prop) {
    super(prop);
    this.state = {
      checked: prop.checked,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.checked });
  }

  handleResetState = () => {
    this.setState({ checked: false });
  };
  handleChange = e => {
    !this.props.controlled && this.setState({ checked: e.target.checked });
    this.props.stopPropagation && e.stopPropagation();
    this.props.onClick && this.props.onClick(e.target.checked, e);
    this.props.onChange && this.props.onChange(e.target.checked, e);
  };
  onLabelClick = e => {
    this.props.stopPropagation && e.stopPropagation();
    if (this.props.stopLabelClick) {
      this.labelClick = true;
    }
    this.props.onLabelClick && this.props.onLabelClick(e);
  };
  onClick = e => {
    this.props.stopPropagation && e.stopPropagation();
  };
  setValue = ckd => this.setState({ checked: ckd });
  setVal = ckd => {
    this.setState({ checked: ckd });
  };

  renderContent() {
    const labelClass = classnames({
      check_selected: this.state.checked,
      label_hide: !this.props.label,
    });
    const { direction, label, dataPath, name, disabled, title } = this.props;
    const content = [
      <input
        key={0}
        name={name}
        type="checkbox"
        data-ctype="checkbox"
        disabled={disabled}
        checked={this.state.checked}
        data-path={dataPath}
        onClick={this.onClick}
        onChange={this.handleChange}
      />,
    ];
    const span = (
      <span className={labelClass} data-ctype="checkbox" title={title || label} key={10}>
        {label}
      </span>
    );
    const { classname } = this.props;
    const classes = classnames({
      [classname]: classname,
      [`${prefixCls}`]: [`${prefixCls}`],
      [`${prefixCls}__hide`]: !this.props.display,
      [`${prefixCls}__checked`]: this.state.checked,
      [`${prefixCls}__disabled`]: disabled,
      [`${prefixCls}-animbg`]: this.props.isSwitchStyle,
    });
    // if (label) {
    if (direction === 'right') {
      content.push(span);
    } else {
      content.unshift(span);
    }
    // }
    return (
      <label
        data-ctype="checkbox"
        style={{ ...this.props.style }}
        className={this.props.useDefaultCls ? classes : classname}
        onClick={this.onLabelClick}
        onMouseDown={this.props.onLabelMouseDown}
      >
        {content}
      </label>
    );
  }

  render() {
    let content = this.renderContent();
    const tips = (this.props.tipsGetter && this.props.tipsGetter(this.state.checked)) || this.props.tips;
    const tipIsDom = this.props.tipsisdom;
    if (tips !== undefined && tips !== '') {
      content = (
        <Tips isDom={tipIsDom} title={tips} className={`${this.props.classname || prefixCls}_tips`}>
          {content}
        </Tips>
      );
    }
    return content;
  }
}
