/**
 * Created by Aceh on 2017/01/01.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Tips } from 'components';
import { prefixCls } from './index.scss';
import { hideTip, showTip } from 'utils';

export default class Radio extends PureComponent {
  isValidityCom = true;
  static defaultProps = {
    classname: '',
    direction: 'right',
    disabled: false,
  };
  static propTypes = {
    classname: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    data: PropTypes.array,
    value: PropTypes.any,
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
  };

  constructor(prop) {
    super(prop);
    this.state = {
      value: this.props.value,
    };
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.value !== undefined) {
      this.setState({
        value: nextProps.value,
      });
    }
  };
  // UNSAFE_componentWillMount() {
  // }
  //
  // handleResetState = () => {
  // }
  handleClick = (value, event) => {
    this.props.onClick && this.props.onClick(value, event);
  };
  onChange = value => {
    this.setState({ value });
    this.props.onChange && this.props.onChange(value);
    setTimeout(this.checkValidity, 50);
  };
  setVal = val => {
    this.setState({ value: val });
  };

  renderContent = ([key, value]) => {
    const direction = value.direction || 'right';
    const isExist = this.state.value == value.value; // eslint-disable-line
    let content = [
      <input
        key={0}
        onClick={event => this.handleClick(value.value, event)}
        onChange={() => this.onChange(value.value)}
        type="radio"
        name={value.name || this.props.name}
        disabled={this.props.disabled || value.disabled}
        value={value.value}
        checked={isExist}
      />,
    ];
    const span = <span key={10} className={`${prefixCls}__span`} />;
    if (direction === 'right') {
      content.push(span);
      content.push(value.label);
    } else {
      content.unshift(value.label);
      content.unshift(span);
    }
    if (value.tips && value.tips !== '') {
      content = <Tips title={value.tips}>{content}</Tips>;
    }
    return (
      <label className={`${prefixCls} ${isExist ? 'active' : ''}`} key={key}>
        {content}
      </label>
    );
  };

  refWrap = r => (this.wrapRef = r);

  checkValidity = async () => {
    const { required, customValidity } = this.props;
    const { value } = this.state;
    let tip = '';
    if (required && !value && value !== 0) {
      tip = '必填';
    } else if (customValidity) {
      tip = await customValidity(value);
    }
    this.setState({ tip });
    return tip;
  };

  focus = () => {};

  blur = () => {
    hideTip();
  };

  showTip = e =>
    this.state.tip &&
    this.wrapRef &&
    showTip(this.wrapRef, { content: <span>{this.state.tip}</span>, className: 'popover--error' });

  render() {
    const data = this.props.data || [];
    const { tip } = this.state;
    return (
      <div
        ref={this.refWrap}
        className={classnames(`${prefixCls}__w`, this.props.classname, { invalid: tip, field: true })}
        onMouseEnter={this.showTip}
        onMouseLeave={hideTip}
      >
        {Object.entries(data).map(this.renderContent)}
      </div>
    );
  }
}
