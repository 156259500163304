import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { RangeInput } from 'components';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';
import { formatValue } from '../helper/format';

export default class RangeInputCell extends TableCell {
  static propTypes = {
    label: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func,
  };
  onChange = val => {
    const { rowIndex, columnKey } = this.props.cellProps;
    this.props.onChange && this.props.onChange(rowIndex, columnKey, val);
  };
  handleKeyDown = e => {
    this.props.onKeyDown(e, this.props.rowIndex, this.props.cellOtherProps.colIndex);
  };
  // input的获取焦点
  onFocus = e => {
    this.props.onFocus(e, this.props.rowIndex, this.props.cellOtherProps.colIndex);
  };

  render() {
    const { cellProps, cellOtherProps, columnProps, onBlur } = this.props;
    const { data, tableKey, colIndex } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const { label, enumData } = columnProps;
    const defaultValue = formatValue(columnKey, data.getObjectAt(rowIndex), enumData);
    // let dataItem = data.getObjectAt(rowIndex)
    // let defaultValue = formatDeppProptotype(columnKey, dataItem)
    let dataPath = `${columnKey}_${rowIndex}_${colIndex}`;
    if (tableKey) {
      dataPath = `${tableKey}_${dataPath}`;
    }
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <RangeInput
          label={label}
          data-path={dataPath}
          value={defaultValue || []}
          onChange={this.onChange}
          onBlur={e => onBlur(rowIndex, columnKey, e.target.value)}
          disabled={isInOtherPropsArray(data.getObjectAt(rowIndex), columnKey, 'disable')}
          onClick={e => e.stopPropagation()}
          onKeyDown={this.handleKeyDown}
          onFocus={this.onFocus}
        />
      </Cell>
    );
  }
}
