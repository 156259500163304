exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ant-calendar-picker .ant-calendar-range-picker.ant-input,date-time .ant-calendar-range-picker.ant-input{padding-right:16px}.range-time-picker__footer .fn-radio{margin-right:10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "date-time",
	"ant-calendar-range-picker": "ant-calendar-range-picker",
	"ant-input": "ant-input",
	"ant-calendar-picker": "ant-calendar-picker",
	"range-time-picker__footer": "range-time-picker__footer",
	"fn-radio": "fn-radio"
};