exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".components-circle{display:flex;width:100%;font-size:14px}.components-circle--icon{margin:0 5px 0 10px;display:flex;align-items:center}.components-circle--content{height:36px;overflow:hidden;position:relative;flex:1}.components-circle--content-item{position:absolute}.components-circle--content-item p{line-height:36px;height:36px}", ""]);

// exports
exports.locals = {
	"components-circle": "components-circle",
	"components-circle--icon": "components-circle--icon",
	"components-circle--content": "components-circle--content",
	"components-circle--content-item": "components-circle--content-item"
};