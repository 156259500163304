/**
 * Created by jany on 2018/07/05.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { CardForm, ModalDialog, ButtonGroup, Button } from 'components';
import { isset, typeIs, showInfo, handleAnyNum, isEmptyObj, deFormat } from 'utils';
import { NUMBER_TYPE, WARN, ERROR } from 'constants';
import { prefixCls } from './index.scss';

const TYPES = {
  number: {
    defaultValue: { op1: '', op2: '', value1: '', value2: '', logic1: 'and' },
    opsProps: {
      data: [
        { key: '==', name: '等于' },
        { key: '!=', name: '不等于' },
        { key: '>', name: '大于' },
        { key: '>=', name: '大于等于' },
        { key: '<', name: '小于' },
        { key: '<=', name: '小于等于' },
      ],
      placeholder: '请选择一个操作',
      // tips: '',
      isMultiple: false,
    },
    valueProps: {
      placeholder: '请输入数字',
    },
    validater: filters => {
      const { value1 = '', value2 = '' } = filters;
      return handleAnyNum(`${value1}`.trim(), true).isPass && handleAnyNum(`${value2}`, true).isPass;
    },
  },
  string: {
    defaultValue: { op1: '', op2: '', value1: '', value2: '', logic1: 'and' },
    opsProps: {
      data: [
        { key: 'in', name: '包含' },
        { key: 'notin', name: '不包含' },
      ],
      placeholder: '请选择一个操作',
      isMultiple: false,
    },
    valueProps: {
      placeholder: '请输入一个值',
    },
  },
  enums: {
    defaultValue: { op1: '', op2: '', value1: '', value2: '', logic1: 'and' },
    opsProps: {
      data: [
        { key: 'in', name: '包含' },
        { key: 'notin', name: '不包含' },
      ],
      placeholder: '请选择一个操作',
      isMultiple: false,
    },
    valueProps: {
      data: [],
      placeholder: '请选择值',
      isMultiple: true,
      selectOnly: true,
    },
  },
};
const LOGIC = {
  ops: [
    { label: '并且', name: 'logicOps', value: 'and' },
    { label: '或者', name: 'logicOps', value: 'or' },
  ],
};
const COLTYPEMAP = {
  keyword: 'string',
  // number: 'number',
  long: 'number',
  integer: 'number',
  short: 'number',
  double: 'number',
  float: 'number',
  currency: 'number',
  text: 'string',
  TextArea: 'string',
  date: 'string',
  date_ymd: 'string',
  date_ym: 'string',
};
export default class QuickFilterDialog extends PureComponent {
  static displayName = 'QuickFilterDialog';

  constructor(props) {
    super(props);
    const { columnProps = {}, dialogFormValue } = props;
    const { columnType = 'number', filterType = 'Input' } = columnProps;
    this.type = filterType === 'SelectDrop' ? 'enums' : COLTYPEMAP[columnType] || 'string';
    this.state = {
      dialogFormValue: { ...isset(TYPES, `${this.type}.defaultValue`), ...dialogFormValue },
    };
  }

  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    columnProps: PropTypes.object,
    dialogFormValue: PropTypes.object,
    extData: PropTypes.object,
    close: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleClearn: PropTypes.func,
    handleCancel: PropTypes.func,
    maxCount: PropTypes.number,
  };
  static defaultProps = {
    title: '自定义筛选',
    maxCount: 500,
  };
  renderContent = () => {
    const { className, extData, columnProps } = this.props;
    const { columnKey } = columnProps;
    const { customizesingleFilter, filterValues, header } = extData;
    const { dialogFormValue = {} } = this.state;
    const { op1, op2, value1, value2, logic1 = 'and' } = dialogFormValue;
    const opsProps = isset(TYPES, `${this.type}.opsProps`);
    const valueProps = isset(TYPES, `${this.type}.valueProps`);
    let customizesingleFilterProps = {};
    if (customizesingleFilter) {
      customizesingleFilterProps =
        (customizesingleFilter &&
          customizesingleFilter({
            filterWhere: 'header',
            key: columnKey,
            filterProps: { type: this.type === 'enums' ? 'SelectDrop' : 'Input' },
            currFilterValues: () => filterValues,
            header: { ...header, [columnKey]: columnProps },
          })) ||
        {};
      delete customizesingleFilterProps.type;
    }
    const commonFormData = {
      op1: {
        type: 'SelectDrop',
        eventNames: ['onClick', 'handleSelected', 'handleClick'],
        otherProps: { ...opsProps, defaultSelected: op1 },
      },
      logic1: {
        type: 'Radio',
        eventNames: ['onChange'],
        otherProps: { data: LOGIC.ops, value: logic1 },
      },
      op2: {
        type: 'SelectDrop',
        eventNames: ['onClick', 'handleSelected', 'handleClick'],
        otherProps: { ...opsProps, defaultSelected: op2 },
      },
    };
    let formData = {};
    if (this.type === 'enums') {
      const { showKey = 'name', uniqueKey = 'key', fetchApi } = columnProps;
      const selectDropProps = { showKey, uniqueKey, fetchApi };
      const op0Data = isEmptyObj(fetchApi) ? extData.enumeration : isset(dialogFormValue, 'extProps.selectedEnum', []);
      formData = {
        op1: commonFormData.op1,
        value1: {
          type: 'SelectDrop',
          eventNames: ['onClick', 'handleSelected', 'handleClick'],
          otherProps: {
            ...valueProps,
            ...selectDropProps,
            ...customizesingleFilterProps,
            data: op0Data,
            defaultSelected: value1,
          },
        },
        logic1: commonFormData.logic1,
        op2: commonFormData.op2,
        value2: {
          type: 'SelectDrop',
          eventNames: ['onClick', 'handleSelected', 'handleClick'],
          otherProps: {
            ...valueProps,
            ...selectDropProps,
            ...customizesingleFilterProps,
            data: op0Data,
            defaultSelected: value2,
          },
        },
      };
    } else {
      formData = {
        op1: commonFormData.op1,
        value1: {
          type: 'Input',
          eventNames: ['onChange'],
          otherProps: { ...valueProps, defaultValue: value1 },
        },
        logic1: commonFormData.logic1,
        op2: commonFormData.op2,
        value2: {
          type: 'Input',
          eventNames: ['onChange'],
          otherProps: { ...valueProps, defaultValue: value2 },
        },
      };
    }
    return (
      <div className={classnames(prefixCls, className)}>
        <CardForm
          ref={r => (this._cardForm = r)}
          data={formData}
          isHeader={false}
          handleEvents={{
            onClick: this.formEventHandle,
            onChange: this.formEventHandle,
            handleClick: this.formEventHandle,
            handleSelected: this.formEventHandle,
          }}
        />
      </div>
    );
  };
  renderFooterBtns = () => (
    <ButtonGroup>
      <Button type="primary" onClick={this.handleConfirm}>
        确定
      </Button>
      <Button onClick={this.handleClearn}>清空</Button>
      <Button onClick={this.handleCancel}>取消</Button>
    </ButtonGroup>
  );
  commonValidate = filters => {
    let res = true;
    const { op1 = '', op2 = '', value1 = '', value2 = '' } = filters;
    // if (!op1 && !op2 && !value1 && !value2) {
    //   showInfo(WARN, '请设置筛选条件！')
    //   res = false
    // } else
    if (op1 && !value1) {
      showInfo(WARN, '筛选1的值不能为空！');
      res = false;
    } else if (this.type !== 'enums' && op2 && !value2) {
      showInfo(WARN, '筛选2的值不能为空！');
      res = false;
    } else if (!op1 && value1) {
      showInfo(WARN, '筛选1的操作不能为空！');
      res = false;
    } else if (this.type !== 'enums' && !op2 && value2) {
      showInfo(WARN, '筛选2的操作不能为空！');
      res = false;
    }
    return res;
  };
  getFilterLine = ({ columnKey, columnType, op, value: originValue }) => {
    const { columnProps = {} } = this.props;
    const value = deFormat(originValue, columnProps.format || []);
    let filter = {};
    switch (op) {
      case '==': {
        if (value === 0 || value === '0') {
          // 包含0值，特殊处理: 取0 或 不存在的
          filter = {
            0: { 0: { id: '_EXIST_FIELD_', [columnKey]: '_EXIST_FIELD_' }, _logic: 'not' },
            1: { [columnKey]: 0 },
            _logic: 'or',
          };
        } else {
          filter[columnKey] = value;
        }
        break;
      }
      case '!=': {
        if (value === 0 || value === '0') {
          // 不包含0值，特殊处理: 不取0 且存在的
          filter = {
            0: {
              0: { _logic: 'not', [columnKey]: 0 },
              id: '_EXIST_FIELD_',
              [columnKey]: '_EXIST_FIELD_',
            },
            1: { _logic: 'not', [columnKey]: value },
            _logic: 'and',
          };
        } else {
          filter = { _logic: 'not', [columnKey]: value };
        }
        break;
      }
      case '>': {
        if (+value < 0) {
          // 包含0值，特殊处理: 取0 或 不存在的
          filter = {
            0: { 0: { id: '_EXIST_FIELD_', [columnKey]: '_EXIST_FIELD_' }, _logic: 'not' },
            1: { [columnKey]: [['>', value]] },
            _logic: 'or',
          };
        } else {
          filter[columnKey] = [['>', value]];
        }
        break;
      }
      case '>=': {
        if (+value <= 0) {
          // 包含0值，特殊处理: 取0 或 不存在的
          filter = {
            0: { 0: { id: '_EXIST_FIELD_', [columnKey]: '_EXIST_FIELD_' }, _logic: 'not' },
            1: { [columnKey]: [['>=', value]] },
            _logic: 'or',
          };
        } else {
          filter[columnKey] = [['>=', value]];
        }
        break;
      }
      case '<': {
        if (+value > 0) {
          // 包含0值，特殊处理: 取0 或 不存在的
          filter = {
            0: { 0: { id: '_EXIST_FIELD_', [columnKey]: '_EXIST_FIELD_' }, _logic: 'not' },
            1: { [columnKey]: [['<', value]] },
            _logic: 'or',
          };
        } else {
          filter[columnKey] = [['<', value]];
        }
        break;
      }
      case '<=': {
        if (+value >= 0) {
          // 包含0值，特殊处理: 取0 或 不存在的
          filter = {
            0: { 0: { id: '_EXIST_FIELD_', [columnKey]: '_EXIST_FIELD_' }, _logic: 'not' },
            1: { [columnKey]: [['<=', value]] },
            _logic: 'or',
          };
        } else {
          filter[columnKey] = [['<=', value]];
        }
        break;
      }
      case 'in': {
        if (typeIs(value, 'array') && NUMBER_TYPE.includes(columnType) && (value.includes(0) || value.includes('0'))) {
          // 枚举值中包含0值的
          filter = {
            0: {
              0: { 0: { id: '_EXIST_FIELD_', [columnKey]: '_EXIST_FIELD_' }, _logic: 'not' },
              [columnKey]: 0,
              _logic: 'or',
            },
            [columnKey]: value,
            _logic: 'or',
          };
        } else {
          filter[columnKey] = value;
        }
        break;
      }
      case 'notin': {
        if (typeIs(value, 'array') && NUMBER_TYPE.includes(columnType) && (value.includes(0) || value.includes('0'))) {
          // 枚举值中包含0值的
          filter = {
            0: {
              0: { _logic: 'not', [columnKey]: 0 },
              id: '_EXIST_FIELD_',
              [columnKey]: '_EXIST_FIELD_',
            },
            1: { _logic: 'not', [columnKey]: value },
            _logic: 'and',
          };
        } else {
          filter = { _logic: 'not', [columnKey]: value };
        }
        break;
      }
      default:
        break;
    }
    return filter;
  };
  handleConfirm = () => {
    const filters = this._cardForm.getStateDataForm();
    const { op1 = '', op2 = '', logic1 = 'and' } = filters;
    let { value1 = '', value2 = '' } = filters;
    const { maxCount, title } = this.props;
    if ((Array.isArray(value1) && value1.length > maxCount) || (Array.isArray(value2) && value2.length > maxCount)) {
      return showInfo(ERROR, `${title}不能超过${maxCount}个搜索值`);
    }
    const validater = isset(TYPES, `${this.type}.validater`);
    if ((validater && !validater({ ...filters })) || !this.commonValidate(filters)) return;
    if (this.type === 'number') {
      value1 = filters.value1 = value1.replace(',', '');
      value2 = filters.value2 = value2.replace(',', '');
    }
    const { columnProps = {} } = this.props;
    const { columnKey, columnType, fetchApi, uniqueKey = 'key' } = columnProps;
    const hasFilter1 = op1 && (typeof value1 === 'object' ? !isEmptyObj(value1) : value1 !== '');
    const hasFilter2 = op2 && (typeof value2 === 'object' ? !isEmptyObj(value2) : value2 !== '');
    const stateData = this._cardForm.getStateData();
    filters.extProps = {};
    if (this.type === 'enums' || !isEmptyObj(fetchApi)) {
      const selectValues = [
        ...isset(stateData, 'value1.otherProps.defaultSelected'),
        ...isset(stateData, 'value2.otherProps.defaultSelected'),
      ];
      const tmpArr = [];
      const valueType = typeof selectValues[0];
      const data = isset(stateData, 'value1.otherProps.data');
      filters.extProps.selectedEnum =
        valueType !== 'object'
          ? [...new Set(selectValues)]
              .map(v => {
                let item;
                data.some(d => {
                  if (d[uniqueKey] === v) {
                    item = d;
                    return true;
                  }
                  return false;
                });
                return item;
              })
              .filter(it => it)
          : selectValues.filter(item => {
              const exist = tmpArr.includes(item[uniqueKey]);
              !exist && tmpArr.push(item[uniqueKey]);
              return !exist;
            });
    }
    const customizeFilters =
      hasFilter1 || hasFilter2
        ? {
            key: columnKey,
            qfLogicType: 'customize',
            _logic: 'and',
            [columnKey]: {
              ...(hasFilter1
                ? { 0: this.getFilterLine({ columnKey, columnType, op: op1, value: value1 }) }
                : undefined),
              ...(hasFilter2
                ? { 1: this.getFilterLine({ columnKey, columnType, op: op2, value: value2 }) }
                : undefined),
              _logic: op1 || op2 ? logic1 : 'and',
            },
          }
        : {};
    this.props.handleConfirm &&
      this.props.handleConfirm({ customizeFilters: [customizeFilters], dialogFormValue: filters });
    this.filterDailog.handleHide();
  };
  handleClearn = () => {
    const defaultValue = isset(TYPES, `${this.type}.defaultValue`);
    this.setState({ dialogFormValue: { ...defaultValue } });
    this.props.handleClearn && this.props.handleClearn();
  };
  handleCancel = () => {
    this.filterDailog.handleHide();
    this.props.handleCancel && this.props.handleCancel();
  };

  render() {
    const { className, title, columnProps } = this.props;
    console.log(className);
    return (
      <ModalDialog
        style={{ width: 465 }}
        ref={r => (this.filterDailog = r)}
        title={`${title}: ${columnProps.title}`}
        isShow
        autoDestroy
        isModal
        close={this.props.close}
        handleHideCallback={this.handleCloseDialog}
        content={this.renderContent()}
        bottom={this.renderFooterBtns()}
      />
    );
  }
}
