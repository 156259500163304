/* eslint-disable no-return-assign */
import { setDeppProptotypes, fromJS, makeObjFast, range } from 'utils';
import { LISTSHOWSTORE_ADDROWS } from 'constants';
import _ from 'lodash';
// NOTICE 非showstore的取值 为了效率不判断是否LISTSHOWSTORE_ADDROWS
export default class ListStore {
  constructor({ size, oriRowObjGetter, showRowObjGetter }) {
    this.size = size || 0; // 构建后 不在变更 作为初始size用
    this._cache = [];
    this._showCache = [];
    this._cellCache = [];
    this._showCellCache = [];
    this._select = {};
    this._immutable = null;
    this._showImmutable = null;
    this.oriIndexMap = range(size);
    this._indexMap = this.oriIndexMap; // 排序筛选后的store Map 恢复时置null
    this.oriRowObjGetter = oriRowObjGetter;
    this.showRowObjGetter = showRowObjGetter;
  }

  getSize = () => this._indexMap.length;
  getOriSize = () => this.size;
  getIndexMap = () => this._indexMap;
  getOriIndexMap = () => this.oriIndexMap;
  getAllShowIndex = () => range(this._indexMap.length);
  getAllIndex = () => this.oriIndexMap;
  updateIndexMap = (indexMap = this.oriIndexMap, updateRef = true) => {
    this._indexMap = indexMap;
    if (updateRef) {
      indexMap.forEach((val, i) => {
        this.updateShowCache(i, 'reference', i + 1);
      });
    }
  };
  resetIndexMap = () => {
    this._indexMap = this.oriIndexMap;
  };
  getObjectAt = iindex => {
    const index = this._indexMap[iindex] || (this._indexMap[iindex] === 0 ? this._indexMap[iindex] : iindex);
    if (this._cache[index] === undefined) {
      return (this._cache[index] = this.oriRowObjGetter(index));
    }
    return this._cache[index];
  };
  getOriObjectAt = index => {
    if (this._cache[index] === undefined) {
      return (this._cache[index] = this.oriRowObjGetter(index));
    }
    return this._cache[index];
  };
  // NOTICE showStore 不单有 数字index 还有头部底部字母index
  getShowObjectAt = iindex => {
    // 此处不做判断 统一于returnRowObjectData
    const index = this._indexMap[iindex] || (this._indexMap[iindex] === 0 ? this._indexMap[iindex] : iindex);

    if (this._showCache[index] === undefined) {
      this._showCache[index] = this.showRowObjGetter(index, this.getOriObjectAt(index));
      return this._showCache[index];
    }
    return this._showCache[index];
  };
  getOriShowObjectAt = index => {
    if (this._showCache[index] === undefined) {
      return (this._showCache[index] = this.showRowObjGetter(index, this.getOriObjectAt(index)));
    }
    return this._showCache[index];
  };
  updateCache = (iindex, key, value) => {
    const index = this._indexMap[iindex] || (this._indexMap[iindex] === 0 ? this._indexMap[iindex] : iindex);
    this._cache[index] = setDeppProptotypes(key, this._cache[index], value);
  };
  updateShowCache = (iindex, columnKey, value) => {
    // showchache 不存在从深层数据取值问题
    // this._showCache[index] = setDeppProptotypes(columnKey, this._showCache[index], value)
    const index = this._indexMap[iindex] || (this._indexMap[iindex] === 0 ? this._indexMap[iindex] : iindex);
    this._showCache[index] && (this._showCache[index][columnKey] = value);
  };
  updateCacheRow = (iindex, value) => {
    const index = this._indexMap[iindex] || (this._indexMap[iindex] === 0 ? this._indexMap[iindex] : iindex);
    this._cache[index] = value;
  };
  updateShowCacheRow = (iindex, value) => {
    const index = this._indexMap[iindex] || (this._indexMap[iindex] === 0 ? this._indexMap[iindex] : iindex);
    this._showCache[index] = value;
  };
  // 数据多的列表慎用
  updateMultiCache = (key, indexValues) => {
    const values = Object.entries(indexValues);
    values.forEach(([index, value]) => {
      this._cache[index] = setDeppProptotypes(key, this._cache[index], value);
    });
  };
  // 数据多的列表慎用
  updateMultiShowCache = (key, indexValues) => {
    const values = Object.entries(indexValues);
    values.forEach(([index, value]) => {
      this._showCache[index] = setDeppProptotypes(key, this._showCache[index], value);
    });
  };
  _updateRowCache = index => {
    this._cache[index] = undefined;
  };
  _updateRowShowCache = index => {
    this._showCache[index] = undefined;
  };
  clearCache = () => {
    this._cache = [];
  };
  clearShowCache = () => {
    this._showCache = {};
  };
  clearSelect = () => {
    this.select = {};
  };
  showIndexToOri = i => this._indexMap[i];
  // 从maped index 获得原始数据的index
  getOriSelected = selectes => selectes.map(i => this._indexMap[i]);
  // 获取指定行数据
  getSelectedRows = (useOriIndex = true, selectes = this.oriIndexMap) =>
    selectes.map(i => this.getOriObjectAt(useOriIndex ? i : this._indexMap[i]));
  // 获取指定行数据
  getSelectedShowRows = (useOriIndex = true, selectes = this.oriIndexMap) =>
    selectes.map(i => this.getOriShowObjectAt(useOriIndex ? i : this._indexMap[i]));
  // getAll 默认 获取 可视条数的 原始结构数据的all
  getAll = (oriAll = false) => {
    let allData = [];
    if (oriAll) {
      if (this._cache.filter(x => x).length === this.size) {
        // if (this._cache.length === this.size) {
        allData = this._cache.slice();
      } else {
        // 有未缓存到的数据
        this.oriIndexMap.forEach(newIndex => allData.push(this.getOriObjectAt(newIndex)));
      }
    } else {
      this._indexMap.forEach(newIndex => allData.push(this.getOriObjectAt(newIndex)));
    }
    return allData;
  };
  /**
   * 获取显示数据的长度
   * _showCache 中，不单有数字index 还有头部底部字母index
   * _showCache 目前挂挂载了5 个方法，最初始只有4个，后面@liuchuan 添加了一个 footerCalculate
   * footerTotal、footerCalculate、footerSelect、headerFilter、headerTitle
   * getAllTableShow 中有个 this.size + 4 判断由来，就是最开始只挂载了4个方法
   * @returns 真正的显示数据的条数
   */
  getShowCacheLength() {
    const allKeys = Object.keys(this._showCache);
    // rowKeys 是对应的行的 index, 0、1、2...
    const rowKeys = allKeys.filter(key => !isNaN(Number(key)));
    return rowKeys.length;
  }
  // 全部显示数据 返回数组
  getAllShow = (oriAll = false) => {
    let allData = [];
    if (oriAll) {
      const showCacheLength = this.getShowCacheLength();
      if (showCacheLength === this.size) {
        allData = this._showCache;
      } else {
        // 有未缓存到的数据
        this.oriIndexMap.forEach(newIndex => allData.push(this.getOriShowObjectAt(newIndex)));
      }
    } else {
      this._indexMap.forEach(newIndex => allData.push(this.getOriShowObjectAt(newIndex)));
    }
    return allData;
  };
  // 全部列表结构， 返回对象
  getAllTableShow = (oriAll = false) => {
    let allData = {};
    if (oriAll) {
      LISTSHOWSTORE_ADDROWS.forEach(key => (allData[key] = this.getShowObjectAt(key)));
      const showCacheLength = this.getShowCacheLength();
      // if (Object.keys(this._showCache).length === this.size + 4) {
      if (showCacheLength === this.size) {
        allData = this._showCache;
      } else {
        // 有未缓存到的数据
        this.oriIndexMap.forEach(newIndex => (allData[newIndex] = this.getOriShowObjectAt(newIndex)));
      }
    } else {
      this._indexMap.forEach(newIndex => (allData[newIndex] = this.getOriShowObjectAt(newIndex)));
    }
    return allData;
  };
  setAll = c => {
    this._cache = c;
  };
  setAllShow = c => {
    this._showCache = c;
  };
  genImmutable = () => {
    this._immutable = fromJS(this._cache);
  };
  genShowImmutable = () => {
    this._showImmutable = fromJS(this._showCache);
  };
  fastUp = () => {
    this._cache = makeObjFast(this._cache);
  };
  fastUpShow = () => {
    this._showCache = makeObjFast(this._showCache);
  };
  getValAt = (index, columnKey, foreupdate) => {
    if (index < 0 || index > this.size) {
      return undefined;
    }
    if (this._cellCache[index] === undefined) {
      this._cellCache[index] = {};
      const rowObj = this.getObjectAt(index);
      this._cellCache[index][columnKey] = rowObj[columnKey] || '';
    } else if (
      (this._cellCache[index] !== undefined && this._cellCache[index][columnKey] === undefined) ||
      foreupdate
    ) {
      const rowObj = this.getObjectAt(index);
      if (foreupdate) {
        this._showCache[index][columnKey] = rowObj[columnKey];
      }
      this._cellCache[index][columnKey] = rowObj[columnKey] || '';
    }
    return this._cellCache[index][columnKey];
  };
  /**
   * TODO：review
   * 获取单元格可显示的值。根据index 获取单条数据中，由columnKey标识的值
   * @param {number} index - 下标
   * @param {string} columnKey - column
   * @param {boolean} useOriIndex - 传入index是否是格式化之前的index
   * @param {boolean} wrap - 是否包含当前所有的展示数据，并返回对象。Y：将返回一个键值对对象，N：仅返回给定key的value
   * @returns object || any
   */
  getShowValAt = (index, columnKey, useOriIndex, wrap) => {
    index = useOriIndex ? index : this._indexMap[index];
    if (index < 0 || index > this.size) {
      return undefined;
    }
    if (this._showCellCache[index] === undefined) {
      this._showCellCache[index] = {};
    }
    let wrapper;
    // 需要包含当前所有的显示数据，先格式化所有的显示Key
    if (wrap) {
      wrapper = this.getOriShowObjectAt(index);
    }
    if (this._showCellCache[index][columnKey] === undefined) {
      // 如果已经有缓存的格式化数据，直接获取
      if (this._showCache[index][columnKey] !== undefined) {
        this._showCellCache[index][columnKey] = this._showCache[index][columnKey];
      } else {
        // 否则单独格式化columnKey
        const rowObj = this.showRowObjGetter(index, this.getObjectAt(index), [columnKey]);
        this._showCellCache[index][columnKey] = rowObj[columnKey];
      }
    }
    if (wrap) return { ...wrapper, [columnKey]: this._showCellCache[index] };
    return this._showCellCache[index][columnKey];
  };
  /**
   * TODO：review
   * 获取单条数据中，多个column的可显示值，并返回一个对象。
   * @param {number} index
   * @param {string[]} columnKeys - 需要获取的key的数组
   * @param {boolean} useOriIndex - 传入index是否是格式化之前的index
   * @param {boolean} wrap - 是否包含当前所有的展示数据，并返回对象。Y：包含所有可展示值的对象，N：只返回给定Key组成的对象
   * @returns object
   */
  getShowColumns = (index, columnKeys, useOriIndex, wrap) => {
    index = useOriIndex ? index : this._indexMap[index];
    if (index < 0 || index > this.size) {
      return undefined;
    }
    if (!_.isArray(columnKeys)) {
      columnKeys = [columnKeys];
    }
    let wrapper = {};
    if (wrap) {
      wrapper = this.getOriShowObjectAt(index);
    }
    return columnKeys.reduce((obj, key) => ({ ...obj, [key]: this.getShowValAt(index, key, true) }), wrapper);
  };
  /**
   * TODO：review
   * 获取多条数据，columnKeys标识的多个键值对组成的对象。与getSelectedShowRows方法类似，但是可以获取隐藏列的显示值
   * @param {number[]} idxs
   * @param {string[]} columnKeys - 需要获取的key的数组
   * @param {boolean} useOriIndex - 传入index是否是格式化之前的index
   * @param {boolean} wrap - 是否包含当前所有的展示数据，并返回对象。Y：包含所有可展示值的对象，N：只返回给定Key组成的对象
   * @returns object[]
   */
  getShowVal = (idxs, columnKeys, useOriIndex, wrap) => {
    if (!_.isArray(idxs)) {
      idxs = [idxs];
    }
    return idxs.map(index => this.getShowColumns(index, columnKeys, useOriIndex, wrap));
  };
}
