import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { isEqual } from 'lodash';
import { formatDeppProptotype } from 'utils';
import { Formula } from 'components';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';

export default class InputCell extends TableCell {
  static propTypes = {
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { cellProps, cellOtherProps } = props;
    const { data } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const dataItem = data.getObjectAt(rowIndex);
    const value = formatDeppProptotype(columnKey, dataItem);
    this.state = { value };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { cellProps, cellOtherProps } = nextProps;
    const { data } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const dataItem = data.getObjectAt(rowIndex);
    const value = formatDeppProptotype(columnKey, dataItem);

    if (!isEqual(value, this.state.value)) {
      this.setState({ value });
    }
  }
  onChange = (rowIndex, columnKey, value) => {
    this.setState({ value });
    this.props.onChange && this.props.onChange(rowIndex, columnKey, value);
  };
  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    const { data, colIndex } = cellOtherProps;
    const { rowIndex, columnKey, width } = cellProps;
    const dataItem = data.getObjectAt(rowIndex);
    const style = { width: width - 3 };
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <Formula
          style={style}
          data-path={`${columnKey}_${rowIndex}_${colIndex}`}
          {...columnProps}
          value={this.state.value}
          onChange={val => this.onChange(rowIndex, columnKey, val)}
          disabled={isInOtherPropsArray(dataItem, columnKey, 'disable')}
        />
      </Cell>
    );
  }
}
