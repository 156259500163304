exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".color-rule-icon{display:inline-block;height:17px;line-height:17px;width:17px;text-align:center;background:#fa8919;color:#fff;vertical-align:middle;margin-right:5px}.color-rule-icon span .fn-icon{font-size:16px;width:16px;height:16px;line-height:12px;margin:0;padding:0}.popover span{color:inherit}.popover .popover-content{white-space:normal}.popover .color-rule-tips{width:495px;padding:5px 5px 0}.popover .color-rule-item{overflow:visible;text-overflow:clip;white-space:nowrap;width:100%;display:inline-block;margin:2px 0}.popover .color-rule-item div{display:inline-block}.popover .color-rule-item .color-display{width:16px;height:16px;margin:0 4px;line-height:16px}.popover .color-rule-item .color-text{vertical-align:top;width:calc(100% - 25px)}.popover .color-rule-item .color-text p{white-space:normal;font-size:12px;color:#333;line-height:16px}.popover .introductions{padding:5px 0;font-family:inherit;font-size:12px;color:#666}", ""]);

// exports
exports.locals = {
	"color-rule-icon": "color-rule-icon",
	"fn-icon": "fn-icon",
	"popover": "popover",
	"popover-content": "popover-content",
	"color-rule-tips": "color-rule-tips",
	"color-rule-item": "color-rule-item",
	"color-display": "color-display",
	"color-text": "color-text",
	"introductions": "introductions"
};