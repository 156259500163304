import React from 'react';
import PropTypes from 'prop-types';
import { prefixCls } from './index.scss';

const Badge = ({ count, children, className }) => (
  <span className={`${prefixCls} ${className}`}>
    {children}
    <sup className="count">{count}</sup>
  </span>
);
Badge.propTypes = {
  count: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
};
export default Badge;
