import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

/**
 * Created by JANY on 2016/12/8.
 */
import PropTypes from 'prop-types';

import Trigger from 'rc-trigger';
import { RcTree, TreeNode } from 'components';
import { loopAllChildren, flatToHierarchy, getValuePropValue, labelCompatible, reactToArray } from './../util';

const BUILT_IN_PLACEMENTS = {
  bottomLeft: {
    points: ['tl', 'bl'],
    offset: [0, 4],
    overflow: {
      adjustX: 0,
      adjustY: 1,
    },
  },
  topLeft: {
    points: ['bl', 'tl'],
    offset: [0, -4],
    overflow: {
      adjustX: 0,
      adjustY: 1,
    },
  },
};
export default class SelectTrigger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _expandedKeys: props.defaultExpandedKeys || [],
      fireOnExpand: props.fireOnExpand,
    };
  }

  static propTypes = {
    dropdownMatchSelectWidth: PropTypes.bool,
    dropdownPopupAlign: PropTypes.object,
    visible: PropTypes.bool,
    filterTreeNode: PropTypes.any,
    treeNodes: PropTypes.any,
    inputValue: PropTypes.string,
    prefixCls: PropTypes.string,
    popupClassName: PropTypes.string,
    children: PropTypes.any,
    defaultExpandedKeys: PropTypes.array,
    fireOnExpand: PropTypes.bool,
  };
  static defaultProps = {
    fireOnExpand: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inputValue && nextProps.inputValue !== this.props.inputValue) {
      // set autoExpandParent to true
      this.setState({
        _expandedKeys: nextProps.defaultExpandedKeys || [],
        fireOnExpand: nextProps.fireOnExpand,
      });
    }
  }

  /* eslint-disable */
  componentDidUpdate() {
    if (this.props.dropdownMatchSelectWidth && this.props.visible) {
      const dropdownDOMNode = this.getPopupDOMNode();
      if (dropdownDOMNode) {
        dropdownDOMNode.style.width = `${ReactDOM.findDOMNode(this).offsetWidth}px`;
      }
    }
  }

  /* eslint-enable */
  onExpand = expandedKeys => {
    // rerender
    this.setState({
      _expandedKeys: expandedKeys,
      fireOnExpand: true,
    });
  };
  getPopupEleRefs = () => this.popupEle && this.popupEle.refs;
  getPopupDOMNode = () => this.trigger.getPopupDomNode();
  getDropdownTransitionName = () => {
    const { props } = this;
    let { transitionName } = props;
    if (!transitionName && props.animation) {
      transitionName = `${this.getDropdownPrefixCls()}-${props.animation}`;
    }
    return transitionName;
  };
  getDropdownPrefixCls = () => `${this.props.prefixCls}-dropdown`;
  highlightTreeNode = treeNode => {
    const { props } = this;
    const filterVal = treeNode.props[labelCompatible(props.treeNodeFilterProp)];
    if (typeof filterVal === 'string') {
      return props.inputValue && filterVal.indexOf(props.inputValue) > -1;
    }
    return false;
  };
  filterTreeNode = (input, child) => {
    if (!input) {
      return true;
    }
    const { filterTreeNode } = this.props;
    if (!filterTreeNode) {
      return true;
    }
    if (child.props.disabled) {
      return false;
    }
    return filterTreeNode.call(this, input, child);
  };
  savePopupElement = ele => {
    this.popupEle = ele;
  };
  processTreeNode = treeNodes => {
    const filterPoss = [];
    this._expandedKeys = this.props.defaultExpandedKeys || [];
    loopAllChildren(treeNodes, (child, index, pos) => {
      if (this.filterTreeNode(this.props.inputValue, child)) {
        filterPoss.push(pos);
        this._expandedKeys.push(child.key);
      }
    });
    // Include the filtered nodes's ancestral nodes.
    const processedPoss = [];
    filterPoss.forEach(pos => {
      const arr = pos.split('-');
      arr.reduce((pre, cur) => {
        const res = `${pre}-${cur}`;
        if (processedPoss.indexOf(res) < 0) {
          processedPoss.push(res);
        }
        return res;
      });
    });
    const filterNodesPositions = [];
    loopAllChildren(treeNodes, (child, index, pos) => {
      if (processedPoss.indexOf(pos) > -1) {
        filterNodesPositions.push({ node: child, pos });
      }
    });
    const hierarchyNodes = flatToHierarchy(filterNodesPositions);
    const recursive = children =>
      children.map(child => {
        if (child.children) {
          return React.cloneElement(child.node, {}, recursive(child.children));
        }
        return child.node;
      });
    return recursive(hierarchyNodes);
  };
  renderTree = (keys, halfCheckedKeys, newTreeNodes, multiple, threeState) => {
    const { props } = this;
    const trProps = {
      multiple,
      threeState,
      prefixCls: `${props.prefixCls}-tree`,
      showIcon: props.treeIcon,
      showLine: props.treeLine,
      defaultExpandAll: props.treeDefaultExpandAll,
      filterTreeNode: this.highlightTreeNode,
    };
    if (props.treeCheckable) {
      trProps.selectable = false;
      trProps.checkable = props.treeCheckable;
      trProps.onCheck = props.onSelect;
      trProps.checkStrictly = props.treeCheckStrictly;
      if (props.inputValue) {
        // enable checkStrictly when search tree.
        trProps.checkStrictly = true;
      } else {
        trProps._treeNodesStates = props._treeNodesStates;
      }
      if (trProps.treeCheckStrictly && halfCheckedKeys.length) {
        trProps.checkedKeys = { checked: keys, halfChecked: halfCheckedKeys };
      } else {
        trProps.checkedKeys = keys;
      }
    } else {
      trProps.selectedKeys = keys;
      trProps.onSelect = props.onSelect;
    }
    // expand keys
    if (!trProps.defaultExpandAll && !props.loadData) {
      trProps.expandedKeys = keys;
    }
    trProps.autoExpandParent = true;
    trProps.onExpand = this.onExpand;
    if (this._expandedKeys && this._expandedKeys.length) {
      trProps.expandedKeys = this._expandedKeys;
    }
    if (this.state.fireOnExpand) {
      trProps.expandedKeys = this.state._expandedKeys;
      trProps.autoExpandParent = false;
    }
    // async loadData
    if (props.loadData) {
      trProps.loadData = props.loadData;
    }
    return (
      <RcTree ref={this.savePopupElement} {...trProps}>
        {newTreeNodes}
      </RcTree>
    );
  };

  render() {
    const { props } = this;
    let { visible } = props;
    const { multiple, threeState, dropdownClassName } = props;

    const dropdownPrefixCls = this.getDropdownPrefixCls();
    const popupClassName = {
      [dropdownClassName]: !!dropdownClassName,
      [`${dropdownPrefixCls}--${multiple ? 'multiple' : 'single'}`]: 1,
    };
    const search =
      multiple || props.combobox || !props.showSearch ? null : (
        <span className={`${dropdownPrefixCls}-search`}>{props.inputElement}</span>
      );
    // Note: if use `React.Children.map`, the node's key will be modified.
    const recursive = children =>
      reactToArray(children).map(child => {
        if (child && child.props.children) {
          // null or String has no Prop
          return (
            <TreeNode {...child.props} key={child.key}>
              {recursive(child.props.children)}
            </TreeNode>
          );
        }
        return <TreeNode {...child.props} key={child.key} />;
      });
    // const s = Date.now()
    let treeNodes;
    if (props._cachetreeData && this.treeNodes) {
      treeNodes = this.treeNodes;
    } else {
      treeNodes = recursive(props.treeData || props.treeNodes);
      this.treeNodes = treeNodes;
    }
    // console.log(Date.now()-s)
    if (props.inputValue) {
      treeNodes = this.processTreeNode(treeNodes);
    }
    const keys = [];
    const halfCheckedKeys = [];
    loopAllChildren(treeNodes, child => {
      if (props.value.some(item => item.value === getValuePropValue(child))) {
        keys.push(child.key);
      }
      if (props.halfCheckedValues && props.halfCheckedValues.some(item => item.value === getValuePropValue(child))) {
        halfCheckedKeys.push(child.key);
      }
    });
    let notFoundContent;
    if (!treeNodes.length) {
      if (props.notFoundContent) {
        notFoundContent = <span className={`${props.prefixCls}-not-found`}>{props.notFoundContent}</span>;
      } else if (!search) {
        visible = false;
      }
    }
    const popupElement = (
      <div>
        {search}
        {notFoundContent || this.renderTree(keys, halfCheckedKeys, treeNodes, multiple, threeState)}
      </div>
    );
    return (
      <Trigger
        action={props.disabled ? [] : ['click']}
        ref={t => (this.trigger = t)}
        popupPlacement="bottomLeft"
        builtinPlacements={BUILT_IN_PLACEMENTS}
        popupAlign={props.dropdownPopupAlign}
        prefixCls={dropdownPrefixCls}
        popupTransitionName={this.getDropdownTransitionName()}
        onPopupVisibleChange={props.onDropdownVisibleChange}
        popup={popupElement}
        popupVisible={visible}
        getPopupContainer={props.getPopupContainer}
        popupClassName={classnames(popupClassName)}
        popupStyle={props.dropdownStyle}
      >
        {this.props.children}
      </Trigger>
    );
  }
}
