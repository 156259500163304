/* eslint-disable */

import React from 'react';
import Select, { colRender } from '../Select';
import Tabs from '../Tabs';
import Amap from 'components/utilcomponents/mapAddon/Amap';
import { log, fetchApi } from 'utils';
import { getReturnData } from 'components/utilcomponents/dropdown/AddrSug/utils';
import classnames from 'classnames';
import { menuPrefixCls } from 'components/utilcomponents/Select/index.scss';
import Icon from 'components/utilcomponents/icon/Icon';
import { prefixCls } from './index.scss';

const AddrSugService = Amap.AddrSug;
const TabPane = Tabs.TabPane;
const keyboardTip = (
  <div>
    请使用键盘&quot;
    <Icon iconType="icon-arrow-mup" />
    <Icon iconType="icon-arrow-mdown" />
    &quot;键来选择
  </div>
);

export default class SelectAddr extends Select {
  state = { selectDropData: {}, activeKey: `${this.props.activeTabPane[0]}` };
  onInputState = false;
  poiDatasRet = {};

  format = value => {
    if (+value[0].node_type === 7 || +value[0].node_type === 8) {
      return value[0].node_address.show_val;
    }
    return value[0].short_name || value[0].company_name || value[0].node_name;
  };
  open = () => {
    const keyword = this.props.multiple ? '' : this.display;
    const value = this.props.multiple ? this.props.value : undefined;
    if (this.state.active) return;
    if (this.isFnFilter && (!this.props.notFilterOnOpenWhenHasData || !this.data.length)) this.filter(keyword, true);
    this.setCursor(this.props.autoActiveFirstOption && (!this.reflectValue || !this.reflectValue.length) ? 0 : -1);
    this.setState({ active: true, keyword, value }, () => this.drop.open());
    this.needAsyncSelect = false;
    this.input && delete this.input.dataset.isSelect;
    this.input && delete this.input.dataset.userInput;
    this.getLineSug(keyword);
    this.handleChange(`${this.props.value.node_type || this.props.activeTabPane[0]}`);
  };
  onInput = e => {
    this.onInputState = true;
    this.filter(e.target.value);
    this.props.onInput && this.props.onInput(e);
    this.input && (this.input.dataset.userInput = e.target.value);
    !e.target.value && !this.props.multiple && this.listenDel && this.reflectValue.length && this.remove();
    this.state.cursorIndex === -1 && this.props.autoActiveFirstOption && this.setCursor(0);
    this.getLineSug(e.target.value);
  };
  onClose = () => {
    this.input.dataset.isSelect &&
      this.props.multiple &&
      this.onChange(this.isValueOfAllSelected ? [ALL] : this.reflectValue.map(this.map));
    this.props.onClose && this.props.onClose();
    this.setState({ active: false, keyword: '', selectDropData: {}, activeKey: '6' });
  };
  onSelect(v2) {
    this.close();
    let value = {};
    const { activeKey } = this.state;
    if (+activeKey === 6) {
      value = {
        company_name: v2.short_name || v2.company_name,
        company_id: v2.id,
        state: v2.state,
        company_name_show: v2.company_name,
        nodeAddress: v2.address,
      };
    } else {
      const type = +activeKey === 7 ? 'address' : 'district';
      value = {
        node_type: activeKey,
        node_name: this.poiDatasRet[type][v2.kindex].show_val,
        node_address: this.poiDatasRet[type][v2.kindex],
      };
    }
    this.onChange(value);
    this.needAsyncSelect = false;
    this.input && (this.input.dataset.isSelect = '1');
  }
  handleSearchStr = () => {
    const { keyword } = this.state;
    const { value } = this.props;
    if (!this.onInputState && value.node_address) {
      return value.node_address.city + value.node_address.district + value.node_address.street;
    }
    return keyword;
  };
  mapResultHandle = (status, result, mode) => {
    log(status, ':', result);
    const { poiDatasRet = [], selectDropData = [] } = getReturnData(status, result, mode);
    const nodeType = mode === 'district' ? '8' : '7';
    const { selectNode } = this.props;
    const new_selectNode = selectNode.filter(t => +t.node_type === +nodeType);
    const index_selectDropData = selectDropData.map((t, i) => ({ ...t, kindex: i }));
    const new_selectDropData = index_selectDropData.filter((t, i) => {
      const { poi } = poiDatasRet[i];
      return !new_selectNode.some(r => r.node_address.poi === poi);
    });
    this.poiDatasRet[mode] = poiDatasRet;
    this.setState({
      selectDropData: {
        ...this.state.selectDropData,
        [mode]: new_selectDropData,
      },
    });
  };
  handleChange = activeKey => {
    this.setState({ activeKey });
  };
  getLineSug = async search => {
    const result = await fetchApi('Basic/Sug/companySug', {
      method: 'POST',
      body: {
        req: {
          company_id: '',
          page_num: 10000,
          need_list: true,
          is_group_all: true,
          delete_self: false,
          org_types: [3, 4, 5, 6, 7, 8, 9],
          org_fields: ['address'],
          search,
        },
      },
    });
    const new_result = result.res || [];
    const { selectNode = [], opForOther, index } = this.props;
    let organize = new_result.filter(t => {
      if (!selectNode.some(c => +c.company_id === +t.id)) {
        return t;
      }
    });
    if (!opForOther && !index) {
      organize = organize.filter(t => +t.id === +window.company_id);
    }
    this.setState({
      selectDropData: {
        ...this.state.selectDropData,
        organize,
      },
    });
  };
  renderTable = (data = [], isAddr = false) => {
    let menuClassName = 'fn-address__menu';
    let highlight = 'listVal';
    let filter = 'info';
    let header = [{ key: 'listVal', title: keyboardTip, colSpan: 2, className: 'keyboard-tip' }, { key: 'info' }];
    let new_data = [...data];
    if (!isAddr) {
      header = [
        {
          key: 'short_name',
          title: '网点简称',
        },
        {
          key: 'new_type',
          title: '类型',
        },
        {
          key: 'new_address',
          title: '地址',
        },
      ];
      filter = 'name';
      // 1总部、2职能机构、3网点、4货站、5分拨中心、6冻结网点、7车队、8仓库、9专线
      new_data = data.map(t => {
        let new_type = '总部';
        if (t.type === '2') {
          new_type = '职能机构';
        } else if (t.type === '3') {
          new_type = '网点';
        } else if (t.type === '4') {
          new_type = '货站';
        } else if (t.type === '5') {
          new_type = '分拨中心';
        } else if (t.type === '6') {
          new_type = '冻结网点';
        } else if (t.type === '7') {
          new_type = '车队';
        } else if (t.type === '8') {
          new_type = '仓库';
        } else if (t.type === '9') {
          new_type = '专线';
        }
        return {
          ...t,
          new_type,
          new_address: t.address.show_val,
        };
      });
    }
    const { silent, format } = this.props;
    const { keyword, loading } = this.state;
    const needFilter = this.isStringFilter && this.input.dataset.userInput;
    const highlightCol = (needFilter || this.isFnFilter) && (highlight || (this.isStringFilter ? filter : format));
    // const realData = this.realData = needFilter ? new_data.filter(item => item[filter] && `${item[filter]}`.includes(keyword)) : new_data
    const realData = (this.realData = new_data);
    const hasData = !!this.realData.length;
    const options = new_data.map((item, index) => {
      return (
        <tr key={index} onMouseDown={e => this.onSelect(item, e)}>
          {header.map((col, i) => {
            const key = col.key || col;
            const render = col.render || colRender;
            const str = item[key];
            const result = render(item, col, key, str, keyword, highlightCol);
            const tdTitleRender = col.titleRender || (() => str);
            const tdTitle = tdTitleRender(str);
            return (
              <td style={col.style} title={tdTitle} className={col.className} key={i}>
                {result}
              </td>
            );
          })}
        </tr>
      );
    });
    const menuClass = classnames({
      [menuClassName]: menuClassName,
      [menuPrefixCls]: menuPrefixCls,
      'has-data': hasData,
    });
    if (silent && !hasData) return <div />;
    return (
      <div className={menuClass} ref={this.refTableWrap}>
        <section>
          <table data-col={header.length}>
            {hasData && (
              <thead>
                <tr>
                  {header.map(
                    (h, i) =>
                      h.title && (
                        <th style={h.style} colSpan={h.colSpan} className={h.className} key={i}>
                          {h.title}
                        </th>
                      ),
                  )}
                </tr>
              </thead>
            )}
            <tbody>
              {!loading && !hasData && (
                <tr className="empty-tip">
                  <td>无相关数据</td>
                </tr>
              )}
              {options}
            </tbody>
          </table>
        </section>
      </div>
    );
  };
  renderMenu = () => {
    const { activeTabPane } = this.props;
    const { selectDropData, activeKey } = this.state;
    const searchStr = this.handleSearchStr();
    // const new_activeTabPane = ['6','7','8']
    const new_activeTabPane = activeTabPane.map(t => `${t}`);
    if (new_activeTabPane.length === 1) {
      let elem = this.renderTable(selectDropData.organize);
      if (new_activeTabPane[0] !== '6') {
        elem = (
          <div>
            <AddrSugService
              resultNum={10}
              mode={activeKey === '8' ? 'district' : 'address'}
              searchStr={searchStr}
              onResult={(status, result) =>
                this.mapResultHandle(status, result, activeKey === '8' ? 'district' : 'address')
              }
            />
            {this.renderTable(activeKey === '8' ? selectDropData.district : selectDropData.address, true)}
          </div>
        );
      }
      return elem;
    }
    return (
      <div className={prefixCls} onMouseDown={e => e.preventDefault()}>
        <Tabs activeKey={activeKey} onChange={this.handleChange}>
          {new_activeTabPane.includes('6') && (
            <TabPane forceRender tab="组织" key={'6'}>
              {activeKey === '6' && this.renderTable(selectDropData.organize)}
            </TabPane>
          )}
          {new_activeTabPane.includes('8') && (
            <TabPane forceRender tab="四级地址" key={'8'}>
              <div>
                <AddrSugService
                  resultNum={10}
                  mode={'district'}
                  searchStr={searchStr}
                  onResult={(status, result) => this.mapResultHandle(status, result, 'district')}
                />
                {activeKey === '8' && this.renderTable(selectDropData.district, true)}
              </div>
            </TabPane>
          )}
          {new_activeTabPane.includes('7') && (
            <TabPane forceRender tab="高德地址" key={'7'}>
              <div>
                <AddrSugService
                  resultNum={10}
                  mode={'address'}
                  searchStr={searchStr}
                  onResult={(status, result) => this.mapResultHandle(status, result, 'address')}
                />
                {activeKey === '7' && this.renderTable(selectDropData.address, true)}
              </div>
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  };
}
