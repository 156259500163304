exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-radio{position:relative;height:24px;font-size:12px;line-height:24px;cursor:pointer;display:inline-block}.fn-radio input[type=radio]{display:none}.fn-radio input[type=radio]:checked+span{border-color:#0097cf}.fn-radio input[type=radio][disabled]+span{background-color:#f8f8f8;border:1px solid #ddd}.fn-radio input[type=radio][disabled]:checked+span:after{background-color:#ddd}.fn-radio input[type=radio]:checked+span:after{background-color:#0097cf;border-radius:100%;content:\"\";display:inline-block;height:6px;margin:3px;width:6px}.fn-radio__span{font-size:12px;background-color:#fff;border:1px solid #8ab0c5;border-radius:100%;display:inline-block;height:14px;margin-right:5px;margin-top:-1px;vertical-align:middle;width:14px;line-height:1}.fn-radio__span:hover{border:1px solid #0097cf}.fn-radio__w.invalid{color:#f12222}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-radio",
	"fn-radio": "fn-radio",
	"fn-radio__span": "fn-radio__span",
	"fn-radio__w": "fn-radio__w",
	"invalid": "invalid"
};