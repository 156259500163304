// Parent Component
import React, { PureComponent } from 'react';
import { LazyRender } from 'components';
import ReactDOM from 'react-dom';
import Content from './content';

export default class FilterMoreIcon extends PureComponent {
  constructor(prop) {
    super(prop);
    this.getDomNode = this.getDomNode.bind(this);
    this.renderComponent();
  }
  propsOnClick = (selected, e) => {
    this.props.onClick && this.props.onClick(selected, e, this.isShow);
  };
  handleHide = () => {
    this.vLazyRender && this.vLazyRender.handleHide();
  };
  handleShow = () => {
    this.vLazyRender && this.vLazyRender.handleShow();
    this.vContent && this.vContent.initData();
  };
  handleSubmit = () => {
    this.props.handleSubmit && this.props.handleSubmit(this);
  };
  // 获取挂载节点位置
  getDomNode = () => {
    return this.props.getDomNode && this.props.getDomNode();
  };
  // 用来获取内容宽高做计算边界
  getContentNode = () => {
    return this.vContent?.containerRef?.current;
  };
  componentWillUnmount() {
    // 组件卸载时，从页面上移除 DOM 元素
    document.body.removeChild(this.containerElement);
  }
  renderComponent() {
    if (!this.containerElement) {
      this.containerElement = document.createElement('div'); // 创建一个 DOM 元素
      document.body.appendChild(this.containerElement); // 将元素添加到页面中
    }

    ReactDOM.render(
      <LazyRender ref={r => (this.vLazyRender = r)} getDomNode={this.getDomNode} getContentNode={this.getContentNode}>
        <div
          style={{
            position: 'fixed',
            zIndex: '999',
          }}
        >
          <Content
            ref={r => (this.vContent = r)}
            getRenderData={this.props.getRenderData}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </LazyRender>,
      this.containerElement,
    );
  }
  render() {
    return null;
  }
}
