exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-show_info{border-radius:4px;box-shadow:0 1px 6px rgba(0,0,0,.2);box-sizing:border-box}.fn-show_info .fn-icon-check-o{color:#87deb6}.fn-show_info .fn-icon-warn-o{color:#fbba7f}.fn-show_info .fn-icon-info-o{color:#93c1ea}.fn-show_info .fn-icon-error-o{color:#f0817e}.fn-show_info__content{position:relative;flex-wrap:nowrap;justify-content:flex-start;align-items:center}.fn-show_info__icon{display:inline-block}.fn-show_info__icon .fn-icon{font-size:32px;width:32px;height:32px;line-height:32px;margin:0 12px 0 18px}.fn-show_info__text{display:inline-block;font-size:12px;padding:0 5px;max-width:400px;max-height:inherit;overflow-y:auto;overflow-x:hidden;line-height:20px}.fn-show_info__text .assist-box-item{margin:5px 0 5px -15px}.fn-show_info__text .assist-box-item .assist-box-item-label{display:inline-block;width:80px;text-align:right}.fn-show_info .no_detail{font-size:14px;line-height:26px;color:#000;vertical-align:middle}.fn-show_info .no_detail_1{vertical-align:middle}.fn-show_info .refresh-notice{max-width:382px;padding:15px 5px 0 0}.fn-show_info .refresh-notice p{margin-left:18px}.fn-show_info .fn-dialog__center{padding:26px 30px 30px;overflow-y:auto}.net_err{background:#fff;box-shadow:0 4px 8px 0 rgba(0,0,0,.35);width:445px}.net_err .fn-dialog__close{color:#d0d0d0}.net_err .fn-dialog__title{background:#fff;border-bottom:1px solid #e9e9e9;color:#333;font-size:13px;font-weight:700}.net_err .fn-icon-neterr-o{color:#f23636}.net_err .fn-dialog__center{background:#fff}.net_err .fn-dialog__center .fn-show_info__content{margin-top:0}.alert-content{margin-top:30px;margin-left:19px}.alert-content .alert-red{color:red}.alert-content p{color:#ccc;max-width:400px;margin-top:10px;margin-left:30px}.delete-price--tips .fn-show_info__text_con_notice{font-size:12px;line-height:26px;color:#f80000;vertical-align:middle;margin-top:10px;padding-left:65px}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-show_info",
	"fn-show_info": "fn-show_info",
	"fn-icon-check-o": "fn-icon-check-o",
	"fn-icon-warn-o": "fn-icon-warn-o",
	"fn-icon-info-o": "fn-icon-info-o",
	"fn-icon-error-o": "fn-icon-error-o",
	"fn-show_info__content": "fn-show_info__content",
	"fn-show_info__icon": "fn-show_info__icon",
	"fn-icon": "fn-icon",
	"fn-show_info__text": "fn-show_info__text",
	"assist-box-item": "assist-box-item",
	"assist-box-item-label": "assist-box-item-label",
	"no_detail": "no_detail",
	"no_detail_1": "no_detail_1",
	"refresh-notice": "refresh-notice",
	"fn-dialog__center": "fn-dialog__center",
	"net_err": "net_err",
	"fn-dialog__close": "fn-dialog__close",
	"fn-dialog__title": "fn-dialog__title",
	"fn-icon-neterr-o": "fn-icon-neterr-o",
	"alert-content": "alert-content",
	"alert-red": "alert-red",
	"delete-price--tips": "delete-price--tips",
	"fn-show_info__text_con_notice": "fn-show_info__text_con_notice"
};