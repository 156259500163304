exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-address .fn-input-pure{padding-right:22px}.fn-address .fn-input-pure:only-child{padding-right:5px}.fn-address__menu.fn-select__menu td{border:0}.fn-address__menu.fn-select__menu th.keyboard-tip{line-height:24px;border:0;height:24px;text-align:center;color:#555;background:rgba(255,0,0,.1);padding:0;font-weight:400}.fn-address__menu.fn-select__menu th.keyboard-tip .fn-icon{color:#378cbe}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-address",
	"fn-address": "fn-address",
	"fn-input-pure": "fn-input-pure",
	"fn-address__menu": "fn-address__menu",
	"fn-select__menu": "fn-select__menu",
	"keyboard-tip": "keyboard-tip",
	"fn-icon": "fn-icon"
};