/**
 * Created by JANY on 2017/10/10.
 */
import PropTypes from 'prop-types';
import React from 'react';
// import Cell from './Cell'
import { Cell } from 'fixed-data-table-2';
import { SelectDrop } from 'components';
import { typeIs, formatDeppProptotype } from 'utils';
import TableCell from './TableCell';
import { isInOtherPropsArray } from './../utils';
import { dispatchCarrierHeader } from 'utils/sug/dispatchCarrier';

const defaultTableHeader = { name: '' };
export default class SelectDropCell extends TableCell {
  static propTypes = {
    handleSelected: PropTypes.func,
    onInputKeyDown: PropTypes.func,
    onInputFocus: PropTypes.func,
    handleSelectDropBlur: PropTypes.func,
  };
  handleSelected = data => {
    // const { columnKey, rowIndex } = this.props // body 里同时传了 cellProps的内容
    let _data = data;
    if (!typeIs(data, 'array') && !this.props.columnProps.selectedOnlyKey) {
      _data = [data];
    }
    this.props.handleSelected && this.props.handleSelected(this.props.rowIndex, this.props.columnKey, _data);
  };
  onInputFocus = e => {
    this.props.onInputFocus && this.props.onInputFocus(e, this.props.rowIndex, this.props.columnKey);
  };
  handleSelectDropBlur = val => {
    this.props.handleSelectDropBlur(this.props.rowIndex, this.props.cellProps.columnKey, val);
  };
  onInputKeyDown = e => {
    this.props.onInputKeyDown && this.props.onInputKeyDown(e, this.props.rowIndex, this.props.colIndex);
  };
  // TODO 列枚举 行枚举 都放到 cell外 取值方式统一
  // TODO 解决 selectdrop 的showing值 不能直接给到 selectddrop组件
  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    // let { data, cellclassMap, tableKey, tips, enableOperate, cellContentGetter } = cellOtherProps
    const { data, colIndex } = cellOtherProps;
    const { rowIndex, columnKey, width, height } = cellProps;
    const { uniqueKey, enumData = [], selectEmpty, ...props } = columnProps;
    const dataItem = data.getObjectAt(rowIndex);
    const defaultValue = formatDeppProptotype(columnKey, dataItem);
    const filterKey = columnProps.filterKey || uniqueKey || 'key';
    const style = { width: width - 3, height: height - 2 };
    const rowProps = dataItem.otherProps && dataItem.otherProps[columnKey];
    const tableHd =
      props.tableHeader && props.refEnum === 'dispatch_carrier_sug'
        ? dispatchCarrierHeader
        : rowProps?.tableHeader || props.tableHeader;
    const tableHdTip = props.tableHeaderTip;
    const tableEmptyTip = props.tableEmptyTip;
    return (
      <Cell
        {...this.props.cellProps}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
      >
        <SelectDrop
          placeholder="-请选择-"
          blurCheck={!props.isMultiple}
          {...props}
          style={style}
          {...rowProps}
          dataPath={`${columnKey}_${rowIndex}_${colIndex}`}
          defaultSelected={defaultValue === undefined || defaultValue === null ? [] : defaultValue}
          data={enumData}
          handleSelected={this.handleSelected}
          showHeader={!!props.showHeader}
          menuListStyle={props.menuListStyle}
          // 表格中的下拉不显示图标
          // inputIconType={'icon-arrow-sdown'}
          // tableHeader={props.tableHeader || defaultTableHeader}
          tableHeader={tableHd || defaultTableHeader}
          tableHeaderTip={tableHdTip}
          tableEmptyTip={tableEmptyTip}
          uniqueKey={uniqueKey || 'key'}
          showKey={props.showKey || 'name'}
          filterKey={filterKey}
          disabled={isInOtherPropsArray(data.getObjectAt(rowIndex), columnKey, 'disable')}
          readOnly={props.readOnly || false}
          selectOnly={props.selectOnlyTable || props.isMultiple || false}
          isMultiple={props.isMultiple || false}
          listClass={props.listClass}
          onInputKeyDown={this.onInputKeyDown}
          onInputFocus={this.onInputFocus}
          rowIndex={rowIndex}
          onBlur={this.handleSelectDropBlur}
        />
      </Cell>
    );
  }
}
