import React from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import PropTypes from 'prop-types';

class SortableList extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired, // 最好包含一个id,用来标识每个item
    onSortEnd: PropTypes.func, // 排序结束后回调
    helperClass: PropTypes.string, // 拖动中的辅助样式class
    itemRender: PropTypes.func.isRequired,
    className: PropTypes.string,
  };
  constructor(props) {
    super(props);
    if (!props.onSortEnd) {
      this.state = {
        // eslint-disable-next-line react/no-unused-state
        data: props.data,
      };
    }
  }
  SortableItem = SortableElement(({ value }) => this.props.itemRender(value));
  listRender = SortableContainer(({ items }) => {
    const { SortableItem } = this;
    return (
      <ul className={this.props.className}>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={{ ...value, index }} />
        ))}
      </ul>
    );
  });
  onSortEnd = ({ oldIndex, newIndex }) => {
    const nextData = arrayMove(this.props.data, oldIndex, newIndex);
    if (!this.props.onSortEnd) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        data: nextData,
      });
    } else {
      this.props.onSortEnd(nextData);
    }
  };
  render() {
    const ListRender = this.listRender;
    const { data, ...others } = this.props;
    return <ListRender {...others} items={data} onSortEnd={this.onSortEnd} />;
  }
}
export default SortableList;
