import React, { PureComponent } from 'react';
import { number, string, bool, object, oneOf } from 'prop-types';
import Line from './Line';
import { POGRESS_STATUS_COLOR_MAP } from './constants';

/**
 * Progress 统一入口文件
 */
export default class Progress extends PureComponent {
  static defaultProps = {
    percent: 0,
    trailColor: '#a7a7a7',
    type: 'line',
    showInfo: true,
    active: false,
  };
  static propTypes = {
    type: oneOf(['line']), // 进度条类型
    percent: number, // 百分比 必填
    strokeColor: string, // 进度条的颜色
    trailColor: string, // 进度条背景颜色
    status: oneOf(['normal', 'exception']), // 状态 优先级低于strokeColor
    tip: string, // 提示信息
    showInfo: bool, // 是否展示进度文字
    style: object, // 进度样式
    active: bool, // 是否使用动画
  };
  handlerGetFormatPercent(percent) {
    if (percent <= 0) {
      return 0;
    }
    if (percent >= 100) {
      return 100;
    }
    return percent;
  }
  render() {
    const { handlerGetFormatPercent } = this;
    const { type, status, strokeColor, percent, ...settings } = this.props;
    const custom = {
      strokeColor: strokeColor || POGRESS_STATUS_COLOR_MAP[status || 'noraml'],
      percent: handlerGetFormatPercent(percent),
    };
    let progress;
    if (type === 'line') {
      progress = <Line {...settings} {...custom} />;
    }
    return progress;
  }
}
