import { TimePicker } from 'components';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { prefixCls } from './index.scss';
/**
 * 时间段选择器
 */

class TimePeroidPicker extends React.Component {
  static propTypes = {
    value: PropTypes.array, // [0:00,12:00]
    format: PropTypes.string,
    disabled: PropTypes.bool,
    // todo
    // minTime: PropTypes.string,
    // maxTIme: PropTypes.string,
    onChange: PropTypes.func,
    clearMode: PropTypes.oneOf(['both', 'self']),
  };
  static defaultProps = {
    format: 'HH:mm',
    clearMode: 'self',
  };
  constructor(props) {
    super(props);
    this.state = {
      value: this.initValue(props.value),
    };
  }
  UNSAFE_componentWillReceiveProps(np) {
    const nv = (np.value || []).join(',');
    const v = (this.toString() || []).join(',');
    if (nv !== v) {
      this.setState({
        value: this.initValue(nv.split(',')),
      });
    }
  }
  shouldComponentUpdate(np, ns) {
    return ns.value !== this.state.value;
  }
  initValue = (val = []) => {
    const startTime = val[0] ? moment(`2008/12/12 ${val[0]}`) : undefined;
    const endTime = val[1] ? moment(`2008/12/12 ${val[1]}`) : undefined;
    return [startTime, endTime];
  };
  toString = () => {
    const { value } = this.state;
    const startTime = value[0] ? value[0].format(this.props.format) : '';
    const endTime = value[1] ? value[1].format(this.props.format) : '';
    return !startTime && !endTime ? '' : [startTime, endTime];
  };
  range = (start, end) => {
    const arr = [];
    for (let i = start; i < end; i++) {
      arr.push(i);
    }
    return arr;
  };
  disabledHours = type => () => {
    const { value } = this.state;
    const startTime = value[0];
    const endTime = value[1];
    let disableArr = [];
    if (type === 'start') {
      disableArr = endTime ? this.range(endTime.hour(), 24) : [];
    } else {
      disableArr = startTime ? this.range(0, startTime.hour()) : [];
    }
    return disableArr;
  };
  disabledMinutes = type => h => {
    let hour = h;
    const { value } = this.state;
    const startTime = value[0];
    const endTime = value[1];
    let disableArr = [];
    const checkHour = hour || hour === 0;
    if (!checkHour) {
      disableArr = this.range(0, 60);
    }
    if (checkHour && type === 'start' && endTime) {
      hour = hour > endTime.hour() ? endTime.hour() : hour;
      const sameHour = hour === endTime.hour();
      if (sameHour) {
        disableArr = this.range(endTime.minute(), 60);
      }
    }
    if (checkHour && type === 'end' && startTime) {
      hour = hour < startTime.hour() ? startTime.hour() : hour;
      const sameHour = hour === startTime.hour();
      if (sameHour) {
        disableArr = this.range(0, startTime.minute());
      }
    }
    return disableArr;
  };
  disabledSeconds = type => (h, m) => {
    let hour = h;
    let minute = m;
    const { value } = this.state;
    const startTime = value[0];
    const endTime = value[1];
    let disableArr = [];
    if (type === 'start' && endTime) {
      hour = hour > endTime.hour() ? endTime.hour() : hour;
      minute = minute > endTime.minute() ? endTime.minute() : minute;
      const sameHour = hour === endTime.hour();
      const sameMinute = minute === endTime.minute();
      if (sameHour && sameMinute) {
        disableArr = this.range(endTime.second(), 60);
      }
    }
    if (type === 'end' && startTime) {
      hour = hour < startTime.hour() ? startTime.hour() : hour;
      minute = minute < startTime.minute() ? startTime.minute() : minute;
      const sameHour = hour === startTime.hour();
      const sameMinute = minute === startTime.minute();
      if (sameHour && sameMinute) {
        disableArr = this.range(0, startTime.second());
      }
    }
    return disableArr;
  };
  onEndTimeChange = time => {
    let { value } = this.state;
    const { clearMode } = this.props;
    if (time && value[0]) {
      if (time < value[0]) {
        time.hour(value[0].hour());
      }
    }
    if (clearMode === 'both' && !time) {
      value = [null, null];
    } else {
      value = [value[0], time];
    }
    this.setState({
      value,
    });
  };
  onStartTimeChange = time => {
    let { value } = this.state;
    const { clearMode } = this.props;
    if (time && value[1]) {
      if (time > value[1]) {
        time.hour(value[1].hour());
      }
    }
    if (clearMode === 'both' && !time) {
      value = [null, null];
    } else {
      value = [time, value[1]];
    }
    this.setState({
      value,
    });
  };
  onChange = open => {
    if (!open && this.props.onChange) {
      setTimeout(() => this.props.onChange(this.toString()), 100);
    }
  };
  render() {
    const { format, className, disabled } = this.props;
    const { value } = this.state;
    const startTime = value[0];
    const endTime = value[1];
    return (
      <div className={`${prefixCls} ${className}`}>
        <TimePicker
          disabled={disabled}
          disabledHours={this.disabledHours('start')}
          disabledMinutes={this.disabledMinutes('start')}
          disabledSeconds={this.disabledSeconds('start')}
          format={format}
          value={startTime}
          onChange={this.onStartTimeChange}
          placeholder=""
          onOpenChange={this.onChange}
        />
        ~
        <TimePicker
          disabled={disabled}
          disabledHours={this.disabledHours('end')}
          disabledMinutes={this.disabledMinutes('end')}
          disabledSeconds={this.disabledSeconds('end')}
          format={format}
          value={endTime}
          onChange={this.onEndTimeChange}
          onOpenChange={this.onChange}
          placeholder=""
        />
      </div>
    );
  }
}
export default TimePeroidPicker;
