/**
 * Created by qiongqiong on 2017/01/01.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { emptyFunction, addTipsByLen, buildShowTip } from 'utils';
import Popover from 'components/utilcomponents/tips/Tooltip/popover';
import { Icon } from 'components';
import reactComposition from 'react-composition';
import { HOVER_TIPS_PARAM } from 'constants';
import { prefixCls } from './index.scss';
import _ from 'lodash';

export default class TextArea extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    readOnly: PropTypes.any,
    disabled: PropTypes.any,
    classname: PropTypes.string,
    valiType: PropTypes.string,
    isActive: PropTypes.bool,
    isUpdateComponentResetValue: PropTypes.bool,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onDoubleClick: PropTypes.func,
    resize: PropTypes.bool,
    autoTrim: PropTypes.bool, // 自动去除空格
    datapath: PropTypes.string,
    iconType: PropTypes.string, // 有值则显示icon
    iconTips: PropTypes.string, // 有值则显示icon
    showTitleTips: PropTypes.bool,
  };
  static defaultProps = {
    defaultValue: '',
    autoTrim: true,
    showTitleTips: false,
  };
  state = {};

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps !== undefined && this._textarea !== undefined) {
      this._textarea.value = nextProps.defaultValue;
    }
  }

  handleResetState = () => {
    this._textarea.value = '';
  };
  focus = () => {
    this._textarea.focus();
    this._textarea.select();
  };

  blur() {
    this._textarea.blur();
  }

  handleMouseEnter = e => {
    if (
      this.props.showTitleTips &&
      (this.props.disabled || this.props.readOnly) &&
      addTipsByLen(e.target, this.wrapDiv)
    ) {
      buildShowTip({ content: e.target.value, ...HOVER_TIPS_PARAM, tipsStyle: { minWidth: '300px' } })(e);
      this.titleTipsShowed = true;
    }
    this.props.onMouseEnter && this.props.onMouseEnter(e);
  };
  handleMouseLeave = e => {
    if (this.titleTipsShowed) {
      this.titleTipsShowed = false;
      Popover.hide();
    }
    this.props.onMouseLeave && this.props.onMouseLeave(e);
  };
  handleChange = event => {
    const { onChange = emptyFunction } = this.props;
    if (event.reactComposition.composition === true) {
      return;
    }
    // onChange(event)
    onChange(event, this.props.autoTrim ? this._textarea.value.trim() : this._textarea.value);
    // onChange(this._textarea.value, event)
  };
  // zgz 双击事件
  handleDblclick = event => {
    const { onDoubleClick = emptyFunction } = this.props;
    onDoubleClick(event, this.props.autoTrim ? this._textarea.value.trim() : this._textarea.value);
  };

  render() {
    const { classname, disabled, readOnly, valiType, isActive, datapath, iconType, iconTips, ...rest } = this.props;
    const classes = classnames({
      [prefixCls]: true,
      [classname]: classname,
      [`${prefixCls}--${valiType}`]: valiType,
      [`${prefixCls}--readonly`]: readOnly,
      [`${prefixCls}--disabled`]: disabled,
      [`${prefixCls}--active`]: isActive,
    });

    const omitProp = _.omit(rest, [
      'customValidity',
      'showTitleTips',
      'onDoubleClick',
      'autoTrim',
      'onChange',
      'getPaths',
      'isUpdateComponentResetValue',
      'children',
    ]);

    return (
      <div className={`${prefixCls}_wrap ${classname}_wrap`} ref={t => (this.wrapDiv = t)}>
        <textarea
          {...omitProp}
          {...reactComposition({ onChange: this.handleChange })}
          onDoubleClick={this.handleDblclick}
          onFocus={this.focus}
          disabled={disabled}
          readOnly={readOnly || disabled}
          ref={t => (this._textarea = t)}
          className={classes}
          data-path={datapath}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        />
        {iconType && (
          <Icon
            tipsCls={`${prefixCls}_icon_tip ${classname}_icon_tip`}
            iconType={iconType}
            tips={iconTips}
            onClick={this.iconClick}
          />
        )}
      </div>
    );
  }
}
