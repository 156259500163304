import * as React from 'react';
import * as moment from 'moment';
import MonthCalendar from 'rc-calendar/lib/MonthCalendar';
import RcDatePicker from 'rc-calendar/lib/Picker';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import { formatDate, omit, interopDefault, getDataOrAriaProps, getPrefixCls } from './utils';
import { hideTip, showTip } from 'utils';
import { validateTips } from 'constants';

moment.locale('zh-cn');

const Input = ({ multiLine, refInput, ...rest }) =>
  multiLine ? <textarea ref={refInput} {...rest} /> : <input ref={refInput} {...rest} />;

export default function createPicker(TheCalendar) {
  class CalenderWrapper extends React.Component {
    static defaultProps = {
      allowClear: true,
      showToday: true,
    };

    isValidityCom = true;
    needCheckValidity = nextProps => ['value', 'required', 'pattern'].some(key => this.props[key] !== nextProps[key]);

    UNSAFE_componentWillReceiveProps(nextProps) {
      const state = this.updateState(nextProps);
      if (Object.keys(state).length) {
        this.setState(state);
      }
      const { tip } = this.state;
      if (!this.focused && tip && !this.state.open && this.needCheckValidity(nextProps)) {
        setTimeout(this.checkValidity.bind(this), 50);
      }
    }
    updateState = (nextProps, prevState = this.state, defaultState) => {
      const state = { ...defaultState };
      let { open } = prevState;

      if ('open' in nextProps) {
        state.open = nextProps.open;
        open = nextProps.open || false;
      }
      if ('value' in nextProps) {
        state.value = nextProps.value;
        if (nextProps.value !== prevState.value || (!open && nextProps.value !== prevState.showDate)) {
          state.showDate = nextProps.value;
        }
      }
      return state;
    };

    input;

    prefixCls;

    constructor(props) {
      super(props);
      const value = props.value || props.defaultValue;
      const state = {
        value,
        showDate: value,
        open: false,
      };
      this.state = this.updateState(props, state, state);
    }

    componentDidUpdate(prevProps, prevState) {
      if (!('open' in this.props) && prevState.open && !this.state.open) {
        this.focus();
      }
    }

    renderFooter = (...args) => {
      const { renderExtraFooter } = this.props;
      const { prefixCls } = this;
      return renderExtraFooter ? <div className={`${prefixCls}-footer-extra`}>{renderExtraFooter(...args)}</div> : null;
    };

    clearSelection = e => {
      e.preventDefault();
      e.stopPropagation();
      this.handleChange(null);
    };

    handleChange = value => {
      const { props } = this;
      if (!('value' in props)) {
        this.setState({
          value,
          showDate: value,
        });
      }
      props.onChange(value, formatDate(value, props.format));
    };

    handleCalendarChange = value => {
      this.setState({ showDate: value });
    };

    handleOpenChange = open => {
      const { onOpenChange } = this.props;
      if (!('open' in this.props)) {
        this.setState({ open });
      }

      if (onOpenChange) {
        onOpenChange(open);
      }
    };

    focus() {
      this.focused = true;
      this.input.focus();
    }

    blur() {
      this.focused = false;
      this.input.blur();
      hideTip();
    }

    saveInput = node => {
      this.input = node;
    };

    checkValidity = async () => {
      if (!this.input) return '';
      const { validity } = this.input;
      let tip = '';
      if (this.props.required && !this.input.value) {
        tip = '必填';
      } else if (validity.patternMismatch) {
        tip = validateTips[this.props.pattern];
      } else if (this.props.customValidity) {
        tip = await this.props.customValidity(this.input.value);
        this.input.setCustomValidity(tip);
      }
      this.setState({ tip });
      return tip;
    };

    refTipIcon = r => (this.tipIcon = r);

    showTip = e => showTip(e.target, { content: <span>{this.state.tip}</span>, className: 'popover--error' });

    handleFocus = e => {
      this.focused = true;
      this.props.onFocus?.(e);
      setTimeout(() => this.tipIcon && this.state.open && this.showTip({ target: this.tipIcon }), 100);
    };
    handleBlur = e => {
      this.focused = false;
      this.props.onBlur?.(e);
      !this.state.open && setTimeout(this.checkValidity, 30);
      hideTip();
    };
    render() {
      const { value, showDate, open, tip } = this.state;
      const props = omit(this.props, ['onChange']);
      const { prefixCls: customizePrefixCls, locale, localeCode, suffixIcon } = props;

      const prefixCls = getPrefixCls('calendar', customizePrefixCls);
      // To support old version react.
      // Have to add prefixCls on the instance.
      // https://github.com/facebook/react/issues/12397
      this.prefixCls = prefixCls;

      const placeholder =
        // eslint-disable-next-line no-nested-ternary
        'placeholder' in props ? props.placeholder : locale && locale.lang ? locale.lang.placeholder : '请选择时间';

      const disabledTime = props.showTime ? props.disabledTime : null;

      const calendarClassName = classNames({
        [`${prefixCls}-time`]: props.showTime,
        [`${prefixCls}-month`]: MonthCalendar === TheCalendar,
      });

      if (value && localeCode) {
        value.locale(localeCode);
      }

      let pickerProps;
      let calendarProps;
      const pickerStyle = {};
      if (props.showTime) {
        calendarProps = {
          // fix https://github.com/ant-design/ant-design/issues/1902
          onSelect: this.handleChange,
        };
        // pickerStyle.minWidth = 195
      } else {
        pickerProps = {
          onChange: this.handleChange,
        };
      }
      if ('mode' in props) {
        calendarProps.mode = props.mode;
      }
      const calendar = (
        <TheCalendar
          {...calendarProps}
          disabledDate={props.disabledDate}
          disabledTime={disabledTime}
          locale={locale.lang}
          timePicker={props.timePicker}
          defaultValue={props.defaultPickerValue || interopDefault(moment)()}
          dateInputPlaceholder={placeholder}
          prefixCls={prefixCls}
          className={calendarClassName}
          onOk={props.onOk}
          dateRender={props.dateRender}
          format={props.format}
          showToday={props.showToday}
          monthCellContentRender={props.monthCellContentRender}
          renderFooter={this.renderFooter}
          onPanelChange={props.onPanelChange}
          onChange={this.handleCalendarChange}
          value={showDate}
        />
      );

      const clearIcon =
        !props.disabled && props.allowClear && value ? (
          <Icon
            iconType="icon-error-o"
            classname={`${prefixCls}-picker-clear`}
            onClick={this.clearSelection}
            theme="filled"
          />
        ) : null;

      const inputIcon = (suffixIcon &&
        (React.isValidElement(suffixIcon) ? (
          React.cloneElement(suffixIcon, {
            className: classNames({
              [suffixIcon.props && suffixIcon.props.className]: suffixIcon.props.className,
              [`${prefixCls}-picker-icon`]: true,
            }),
          })
        ) : (
          <span className={`${prefixCls}-picker-icon`}>{suffixIcon}</span>
        ))) || <Icon iconType="icon-date" classname={`${prefixCls}-picker-icon`} />;

      const tipIcon = tip && (
        <i
          className="input-tip input-tip--invalid fn-icon fn-icon-warn-o"
          ref={this.refTipIcon}
          onMouseEnter={this.showTip}
          onMouseLeave={hideTip}
        />
      );

      const dataOrAriaProps = getDataOrAriaProps(props);
      const input = ({ value: inputValue }) => (
        <div>
          <Input
            disabled={props.disabled}
            readOnly
            value={formatDate(inputValue, props.format)}
            placeholder={placeholder}
            className={props.pickerInputClass}
            tabIndex={props.tabIndex}
            name={props.name}
            multiLine={props.multiLine}
            refInput={this.saveInput}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            {...dataOrAriaProps}
          />
          {clearIcon}
          {inputIcon}
          {tipIcon}
        </div>
      );

      return (
        <span
          id={props.id}
          className={classNames(props.className, props.pickerClass, { invalid: tip, field: true })}
          style={{ ...pickerStyle, ...props.style }}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          <RcDatePicker
            {...props}
            {...pickerProps}
            calendar={calendar}
            value={value}
            prefixCls={`${prefixCls}-picker-container`}
            style={props.popupStyle}
            open={open}
            onOpenChange={this.handleOpenChange}
          >
            {input}
          </RcDatePicker>
        </span>
      );
    }
  }
  return CalenderWrapper;
}
