import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import bem from 'utils/bem';
import { Icon } from 'components';
import { prefixCls } from './index.scss';

const cls = bem(prefixCls);
class CardBox extends React.PureComponent {
  static propTypes = {
    defaultCollapsed: PropTypes.bool,
    onToggleCollapsed: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    header: PropTypes.element,
    title: PropTypes.string,
    size: PropTypes.oneOf(['normal', 'large']),
  };
  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.defaultCollapsed,
    };
  }
  UNSAFE_componentWillReceiveProps(np) {
    if (np.defaultCollapsed !== this.props.defaultCollapsed) {
      this.setState({ collapsed: np.defaultCollapsed });
    }
  }
  toggle = () => {
    const collapsed = !this.state.collapsed;
    this.setState({
      collapsed,
    });
    if (this.props.onToggleCollapsed) {
      this.props.onToggleCollapsed(collapsed);
    }
  };
  render() {
    const { className, header, children, title, size } = this.props;
    const { collapsed } = this.state;
    const showHeader = title || header;
    return (
      <div className={`${className} ${cls('', size)}`}>
        {!!showHeader && (
          <div className={cls('header')}>
            <Icon
              classname={cls('icon', { collapsed })}
              iconType={collapsed ? 'icon-carry-in' : 'icon-carry-out'}
              onClick={this.toggle}
            />
            <div className={cls('title')}>{title}</div>
            <div className={cls('header-extra')}>{header}</div>
          </div>
        )}
        <div className={cls('content', { collapsed })}>{children}</div>
      </div>
    );
  }
}
const FormItem = ({ show, required, className, children, label, justify, field, tips }) => {
  if (!show) {
    return null;
  }
  return (
    <div
      className={classnames('form-item', {
        required,
        [className]: className,
        [field]: field,
      })}
    >
      <label className={`w-${label.length} form-item__label form-item__label--${justify ? 'justify' : 'right'}`}>
        {label}
      </label>
      {children}
      {!!tips && <Icon iconType="icon-help" tips={tips} classname="form-item__tips" />}
    </div>
  );
};
FormItem.propTypes = {
  show: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  field: PropTypes.string,
  // label 是否两端对齐
  justify: PropTypes.bool,
};
FormItem.defaultProps = {
  show: true,
};
CardBox.FormItem = FormItem;
export default CardBox;
