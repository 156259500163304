import PropTypes from 'prop-types';
import React, { Component } from 'react';
import onElementResize from 'element-resize-event';
import { addMutationObserver } from 'utils';
// const _debounce = require('lodash.debounce')
// debounce = 0,
// debounceOpts = {},
// updateDimensions = debounce === 0 ? this.updateDimensionsImmediate
//   : _debounce(this.updateDimensionsImmediate, debounce, debounceOpts)
function defaultGetDimensions(element) {
  return [element.clientWidth, element.clientHeight];
}

function defaultSetDimensions(element, width, height) {
  if (element) {
    element.style.width = width; // eslint-disable-line
    element.style.height = height; // eslint-disable-line
  }
}

export const Dimensions = function ({
  getDimensions = defaultGetDimensions,
  setDimensions = defaultSetDimensions,
  elementResize = false,
} = {}) {
  return ComposedComponent =>
    class DimensionsHOC extends Component {
      constructor(props) {
        super(props);
        this.state = {
          containerWidth: props.width,
          containerHeight: props.height,
          fixedWidthHeight: props.width && props.height,
        };
      }
      static propTypes = {
        height: PropTypes.number,
        width: PropTypes.number,
      };
      getWrapperRef = r => (this.wrapper = r);
      getwrappedInstanceRef = r => (this.wrappedInstance = r);
      getWrappedInstance = () => this.wrappedInstance;
      // 同步为父节点的宽高
      updateDimensionsImmediate = () => {
        const dimensions = getDimensions(this._parent);
        if (!dimensions[0] && !dimensions[1]) return;
        if (dimensions[0] !== this.state.containerWidth || dimensions[1] !== this.state.containerHeight) {
          this.setState({
            containerWidth: dimensions[0],
            containerHeight: dimensions[1],
          });
        }
      };
      // 设置父节点的宽高
      setParentDimensions = (width, height) => {
        if (width !== this.state.containerWidth || height !== this.state.containerHeight) {
          setDimensions(this._parent, width, height);
          this.setState({
            containerWidth: width,
            containerHeight: height,
          });
        }
      };
      onResize = () => {
        if (this.rqf) return;
        this.rqf = this.getWindow().requestAnimationFrame(() => {
          this.rqf = null;
          this.updateDimensionsImmediate();
        });
      };
      getWindow = () => (this.refs.container ? this.refs.container.ownerDocument.defaultView || window : window); // eslint-disable-line
      componentDidMount() {
        if (!this.wrapper) {
          throw new Error('Cannot find wrapper div');
        }
        this._parent = this.wrapper.parentNode;
        addMutationObserver(this);
        if (!this.state.fixedWidthHeight) {
          this.updateDimensionsImmediate();
          if (elementResize) {
            onElementResize(this._parent, this.updateDimensionsImmediate);
          } else {
            this.getWindow().addEventListener('resize', this.onResize, false);
          }
        }
      }

      componentWillUnmount() {
        this.getWindow().removeEventListener('resize', this.onResize);
      }

      render() {
        const { containerWidth, containerHeight } = this.state;
        return (
          <div
            style={{
              overflow: 'visible',
              width: containerWidth,
              height: containerHeight,
            }}
            ref={this.getWrapperRef}
          >
            {!!(containerWidth || containerHeight) && (
              <ComposedComponent
                {...this.state}
                {...this.props}
                updateDimensions={this.updateDimensionsImmediate}
                setParentDimensions={this.setParentDimensions}
                ref={this.getwrappedInstanceRef}
              />
            )}
          </div>
        );
      }
    };
};
