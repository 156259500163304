/**
 * Created by liujie on 2017/09/03.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { Tips } from 'components';
import { typeIs, genImageURL } from 'utils';
import classnames from 'classnames';
import { MULTI_LINE_HEIGHT } from 'constants';
import Cell from './Cell';
import TableCell from './TableCell';
import { isInOtherPropsArray, getOtherPropsInfo, getTitle } from './../utils';

export default class LinkCell extends TableCell {
  static propTypes = {
    onClick: PropTypes.func,
  };
  handleClick = (e, linkInfo) => {
    e.stopPropagation();
    !this.clicks && (this.clicks = {});
    const cTime = Date.now();
    const lTime = this.clicks.single || 0;
    const interval = 1000; // 1500 // 防短时多次点击
    if (cTime - lTime < interval) {
      console.log('click too fast');
      return;
    }
    this.clicks.single = cTime;
    this.props.onClick &&
      this.props.onClick(this.props.rowIndex, this.props.columnKey, linkInfo || e.target.textContent);
  };
  renderImg = item => (
    <img src={genImageURL(item)} onClick={e => this.handleClick(e, item)} width="16px" height="16px" />
  );
  renderTips = () => {
    const { cellProps, cellOtherProps } = this.props;
    const { data, linkType } = cellOtherProps;
    const { rowIndex, columnKey } = cellProps;
    const dataItem = data.getShowObjectAt(rowIndex);
    const value = dataItem[columnKey] ?? '';
    const multiLink = typeIs(value, 'array');
    const disabled = isInOtherPropsArray(dataItem, columnKey, 'disable');
    const tips = getOtherPropsInfo(dataItem, columnKey, 'tips');
    // 图片类型，或者有自定义 tips 则不需要再显示扩展tips
    if (linkType === 'img' || tips) {
      return null;
    }
    if (multiLink && value?.length > 0) {
      return (
        <div className="cell-cotent-tips-wrap">
          {value?.map?.((x, i) => {
            const title = getTitle(x, i);
            const key = `${i + 1}-${title}`;
            return (
              <div
                key={key}
                className="cell-cotent-tips-wrap-item"
                onClick={e => {
                  if (!disabled) this.handleClick(e, x); // 只有非 disabled 时才触发点击事件
                }}
              >
                {title}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  render() {
    const { cellProps, cellOtherProps, columnProps } = this.props;
    const { data, isPopCopyOn, isAutoRowHeight, adaptiveCols = [], rowHeight, linkType } = cellOtherProps;
    const { rowIndex, columnKey, width, height, style, className } = cellProps;
    const { align, colClass, showKey } = columnProps;
    const dataItem = data.getShowObjectAt(rowIndex);
    let value = dataItem[columnKey] === undefined || dataItem[columnKey] === null ? '' : dataItem[columnKey];
    let title = value;
    const copyerValue = value;
    const cellclass = getOtherPropsInfo(dataItem, columnKey, 'cellclass') || colClass || '';
    const multiLink = typeIs(value, 'array');

    if (multiLink) {
      let linkStr = '';
      title = [];
      value = value.length
        ? value.map((x, i) => {
            // eslint-disable-next-line no-nested-ternary
            linkStr = typeIs(x, 'object') ? (x.show_val ? x.show_val : `[${i + 1}]`) : x;
            title.push(getTitle(x, i));
            let aStyle = { color: style.color };
            let linkColor;
            if (x && x.link_status === 'text') {
              linkColor = '#333';
              aStyle = { ...aStyle, textDecoration: 'none', pointerEvents: 'none' };
            }
            if (linkType === 'img') {
              return this.renderImg(x);
            }
            return (
              // eslint-disable-next-line no-script-url
              <a style={aStyle} href="javascript:;" onClick={undefined}>
                <span key={i} style={{ marginRight: '6px', color: linkColor }} onClick={e => this.handleClick(e, x)}>
                  {linkStr}
                </span>
              </a>
            );
          })
        : '';
      title = title.join(' ');
    }
    const disabled = isInOtherPropsArray(dataItem, columnKey, 'disable');
    const tips = getOtherPropsInfo(dataItem, columnKey, 'tips');
    const cellWrapClass = classnames({ [className]: className, [cellclass]: cellclass });
    let content;
    if (disabled || multiLink) {
      content = value;
    } else {
      content = (
        <a style={{ color: style.color }} onClick={this.handleClick}>
          {value}
        </a>
      );
    }

    const shouldWrap = !(isAutoRowHeight || adaptiveCols.includes(columnKey) || height > rowHeight);
    return (
      <Cell
        {...this.props.cellProps}
        type="link"
        copyerValue={isPopCopyOn ? copyerValue : undefined}
        popStyle={{ align, lineHeight: `${height > rowHeight ? MULTI_LINE_HEIGHT : rowHeight}px`, ...style }}
        tableKey={cellOtherProps.tableKey}
        onContextMenu={this.props.rowContextMenu ? e => this.props.rowContextMenu(e, columnKey, rowIndex) : undefined}
        onClick={disabled ? undefined : this.handleClick}
        className={cellWrapClass}
        disabled={disabled}
        shouldWrap={shouldWrap}
        renderTips={this.renderTips}
      >
        <div
          title={title}
          className={classnames({
            'fn-table__cell_nowrap': shouldWrap,
            'fn-table__cell__content': true,
            'fn-table__cell__link': true,
            [`fn-table-table__cell__${cellclass}`]: cellclass,
          })}
          style={{
            width,
            height,
            lineHeight: `${height > rowHeight ? MULTI_LINE_HEIGHT : rowHeight}px`,
            padding: '0 4px',
          }}
        >
          {tips ? (
            <Tips className={`LinkCell_tips LinkCell_tips_${columnKey}`} title={tips}>
              {content}
            </Tips>
          ) : (
            content
          )}
        </div>
      </Cell>
    );
  }
}
