import { ERROR } from 'constants';

/* eslint-disable */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import {
  createTip,
  // 系统性方法
  WsInit,
  sendMsgStartVideo,
  sendMsgCloseVideo,
  sendMsgGetResolution,
  LTPhotoClick,
  LTClosePhoto,
  LTRotateRight,
  LTLotateLeft,
  LTsizeChange,
  LTresolutionList,
  // 默认设置方法（参数写死）
  sendMsgSetCanvasOriginalSize,
  sendMsgSetCutType,
  sendMsgSetImageColorMode,
  sendMsgSetJiubianModel, // eslint-disable-line
  // 默认设置方法（参数写死）
  sendMsgSetFileType,
  sendMsgSetConntinousShotModel,
  sendMsgSetFileNameModelTime,
  sednMsgBubaiType,
  sendMsgSetAutoExposure, // eslint-disable-line
  sendMsgChangeOrientation,
  sendMsgZoom, // 设置方法
  sednMsgGetBase64,
  sendMsgCapture,
  sendMsgDeleteFile,
  sendMsgSetFilePath, // 操作方法
  getSelectSchema,
  getCardFormData,
  typeIs,
  getCheckboxSchema,
  setComVal,
  setCookie,
  getCookie,
} from 'utils';
import classname from 'classnames';
import { ModalDialog, ButtonIcon, Icon, PopUp, CardForm, SelectDrop } from 'components';
import { prefixCls } from './index.scss';
export default class UploadImgCamera extends Component {
  static propTypes = {
    upLoadCb: PropTypes.func, // 图片上传时回调
  };

  constructor(props) {
    super(props);
    this.deviceName = 'USBCamera'; // 高拍仪设备名称
    this.canvasWidth = 470;
    this.canvasHeight = 354;
    this.errorNumList = [];
    this.showPickOrder = props.showPickOrder || false; // 是否显示挑单区域
    this.state = {
      modalShow: false,
      deviceIndex: undefined, // 高拍仪在设备列表中的index
      resolutionData: [], // 分辨率可选项
      deviceResolutionWidth: 0, // 分辨率宽
      deviceResolutionHeight: 0, // 分辨率高
      isError: false, // 标志 是否出错 true关闭弹框
      currImgName: '', // 当前图片名称（时间戳）
      currImgType: '', // 当前图片类型
      currImgPath: '', // 当前图片路径
      currImgOri: 0, // 当前成像区旋转角度
      imgPathList: [], // 所有图片路径（用于删除）
      currBase64: '',
      imgLeft: 0,
      imgTop: 0,
      currTransform: '',
      setScanSizeData: [
        { id: '原始尺寸', name: '原始尺寸' },
        { id: 'A5', name: 'A5' },
        { id: '卡片', name: '卡片' },
      ],
      isLiangTian: false,
      LTDevice: [], //良田分辨率,
      currentResolution: null,
      faileLinkNum: 0,
    };
    this.isLiangTian = false;
    this.isOpen = false; // 标志 设备是否连接成功
    this.orders = props.orders || [];
    this.pickOrderData = this.initPickOrderData();
  }

  componentDidMount() {
    // this.isLiangTian = false
    this.setState({ isLiangTian: false });
    try {
      WsInit.call(this, this);
    } catch (err) {
      console.log(err);
    }

    // 连接ws
  }

  componentWillUnmount() {
    this.props.cb && this.props.cb();
    this.isOpen && sendMsgCloseVideo(); // 销毁组件前关闭成像
    !!this.state.isLiangTian && LTClosePhoto && LTClosePhoto();
    // this.destroyImgCach()
  }

  componentDidUpdate() {
    const { deviceResolutionWidth, deviceResolutionHeight, deviceIndex, isError } = this.state;
    if (deviceIndex !== undefined && deviceResolutionWidth && deviceResolutionHeight && !this.isOpen) {
      this.startVedio();
    }
  }
  getPickOrderData = () => {
    const pickInfo = this.pickOrderData;
    if (this.state.order_num) {
      pickInfo.order_num.otherProps.defaultSelected = this.state.order_num;
    } else if (this.orders.length === 1) {
      pickInfo.order_num.otherProps.defaultSelected = this.orders;
    }
    if (this.state.photo_type) {
      pickInfo.photo_type.otherProps.defaultSelected = this.state.photo_type;
    }
    return pickInfo;
  };
  initPickOrderData = () => {
    var data = {};
    data.order_num = getSelectSchema({
      children: '运单号',
      eventNames: [],
      isRequired: true,
      showColon: true,
      validate: ['require'],
      showKey: 'order_num',
      uniqueKey: 'od_link_id',
      formKey: 'od_link_id',
      silent: true,
      blurCheck: true,
      minFetchLen: 3,
    });
    data.order_num.fetchApi = {};
    data.photo_type = getSelectSchema({
      children: '图片类型',
      eventNames: [],
      isRequired: true,
      showColon: true,
      validate: ['require'],
      showKey: 'name',
      uniqueKey: 'key',
      readonly: true,
      defaultSelected: [],
      data: [
        { key: 'receipt', name: '电子回单' },
        { key: 'order', name: '运单跟踪' },
      ],
    });
    data.is_visible = getCheckboxSchema({
      label: '用户查单可见',
      display: false,
      eventNames: [],
    });
    data.order_num.otherProps.fetchApi = {
      url: 'Table/Suggest/commonSug',
      data_key: 'res,data',
      queryKey: 'query',
      key: 'order_num',
      para: {
        category: 'Order',
        tab: 'od_all',
        search: '',
        search_key: 'order_num',
        query: {},
        page_num: 0,
        page_size: 10,
        fields: {
          0: 'id',
          1: 'od_link_id',
          2: 'od_id',
          3: 'od_basic_id',
          4: 'order_num',
          5: 'receipt_num',
          6: 'billing_date',
          7: 'g_name',
          8: 'g_pkg',
          9: 'num',
        },
        filter: { order_status: 1, is_deserted: 0 },
        sort: { order_create_t: 'desc' },
      },
    };
    return data;
  };

  cameraStartErrorDialog = type => {
    const startError = (
      <div className="self-check">
        <h3 className="self-check-title">
          系统检测到高拍仪启动故障，请尝试以下方式解决，若还有问题，请联系G7易流客服400-611-5656
        </h3>
        <div className="self-check-step" style={{ marginBottom: '0px' }}>
          <div className="self-check-step-title">
            1、请检查是否已安装高拍仪驱动 &nbsp;
            <a target="_blank" href="http://faq.chemanman.com/pages/viewpage.action?pageId=6258876">
              点此下载高拍仪驱动
            </a>
          </div>
          <div className="self-check-step-content">
            <div className="self-check-step-content-text"></div>
            <div className="self-check-step-content-img-wrap"></div>
          </div>
        </div>
        <div className="self-check-step" style={{ marginBottom: '0px' }}>
          <div className="self-check-step-title">2、请检查高拍仪是否连接，若连接，请检查接头是否松动。</div>
          <div className="self-check-step-content">
            <div className="self-check-step-content-text"></div>
            <div className="self-check-step-content-img-wrap"></div>
          </div>
        </div>
        <div className="self-check-step">
          <div className="self-check-step-title">
            3、在任务管理器里找到高拍仪SDK的驱动进程，将其关闭。若找不到可跳过此步骤。
          </div>
          <div className="self-check-step-content">
            <div className="self-check-step-content-text">
              （1）宝碁 点易拍 高拍仪的驱动名称为：BaucheSDK.exe (32位)
            </div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/step2.jpg')} alt="" />
            </div>
            <div className="self-check-step-content-text">（2）良田高拍仪的驱动名称：eloam_socket.exe (32位)</div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/lt-1.png')} alt="" />
            </div>
          </div>
        </div>
        <div className="self-check-step">
          <div className="self-check-step-title">4、在开始菜单或桌面，重新启动高拍仪SDK驱动程序</div>
          <div className="self-check-step-content">
            <div className="self-check-step-content-text">（1）宝碁 点易拍 高拍仪</div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/step3.jpg')} alt="" />
            </div>
            <div className="self-check-step-content-text">（2）良田高拍仪：</div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/lt-2.png')} alt="" />
            </div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/lt-3.png')} alt="" />
            </div>
          </div>
        </div>
        <div className="self-check-step">
          <div className="self-check-step-title">5、确保没有其他程序占用高拍仪SDK驱动所用端口。</div>
          <div className="self-check-step-content">
            <div className="self-check-step-content-text">开始--运行--cmd，或者是window+R组合键，调出命令窗口。</div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/step4.jpg')} alt="" />
            </div>
          </div>
          <div className="self-check-step-content">
            <div className="self-check-step-content-text">
              查看被占用端口对应的PID，输入命令：netstat -aon|findstr 9002，(如果是良田高拍仪，则为netstat -aon|findstr
              12345)记下最后一位数字，即pid号，例如是5272。
            </div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/step5.jpg')} alt="" />
            </div>
          </div>
          <div className="self-check-step-content">
            <div className="self-check-step-content-text">
              继续输入tasklist|findstr
              7532，回车。如果应用程序不是CLodopPrint32.exe说明被其他应用程序占用了端口，需要关闭占用打印端口的应用程序。
            </div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/step6.jpg')} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
    const saveError = (
      <div className="self-check">
        <h3 className="self-check-title">
          系统检测到高拍仪拍照存储故障，请尝试以下方式解决，若还有问题，请联系G7易流客服400-611-5656。
        </h3>
        <div className="self-check-step">
          <div className="self-check-step-title">1、请检查当前电脑在如下路径是否存在Public文件夹。</div>
          <div className="self-check-step-content">
            <div className="self-check-step-content-text">打开当前电脑如下路径。</div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/error1.jpg')} alt="" />
            </div>
          </div>
          <div className="self-check-step-content">
            <div className="self-check-step-content-text">查看是否存在Public文件夹，若不存在，请创建Public文件夹。</div>
            <div className="self-check-step-content-img-wrap">
              <img src={require('images/camera/error2.jpg')} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
    setTimeout(() => {
      new PopUp(ModalDialog, {
        content: type ? startError : saveError,
        close: this.props.close, // eslint-disable-line
        title: '高拍仪报错自检步骤',
        isShow: true,
        contentStyle: { width: '810px', height: '548px', overflow: 'auto' },
        popName: 'self-check',
      }).show();
    }, 1000);
    this.closeModalDialog();
  };
  // ws断开提示
  Disconnect = (type, close) => {
    if (close) {
      this.setState({ faileLinkNum: this.state.faileLinkNum + 1 }, () => {
        if (this.state.faileLinkNum == 2) {
          this.cameraStartErrorDialog(1);
        }
      });
    }
  };
  // ws回调
  componentInfoCallback = op => {
    const errorCode = [0xa2, 0xa6, 0xa7, 0xa8];
    if (errorCode.includes(op)) {
      this.cameraStartErrorDialog(1);
    }
    if (op === 0) {
      this.setState({ modalShow: true });
    }
  };

  getCookieName = () => {
    return `camera_${window.user_id}`;
  };

  /**
   * 截获base64及路径
   * @param {Number} type 状态码
   * @param {String} str 携带信息
   */
  componentInfoTextCallback = (type, str) => {
    if (type === 0) {
      const result = str.match(/(\w+)\.([a-z]+)$/),
        currImgName = new Date(+result[1]).getTime(),
        currImgType = result[2],
        currImgPath = str,
        imgPathList = this.state.imgPathList;
      imgPathList.unshift(currImgPath);
      this.getBase64(currImgPath);
      this.setState({ currImgName, currImgType, currImgPath, imgPathList });
    } else if (type === 5) {
      this.isCapture = true; // 此时拍照成功 将标志开启
      this.setState({ currBase64: str });
      sendMsgDeleteFile(this.state.currImgPath); // 删除当前图片
    } else if (type === 8) {
      this.cameraStartErrorDialog(0);
    }
  };
  LTSetData = function (data) {
    console.log(data);
  };
  /**
   * 获取设备名称
   */
  GetDevFriendName = () => {
    let deviceIndex = window.camidMain;
    if (deviceIndex === undefined) {
      this.closeModalDialog();
      window.setTimeout(() => {
        createTip('未接入拍照设备！', ERROR).show();
      }, 100);
    }
    sendMsgGetResolution(deviceIndex); // 切换成像设备为高拍仪
    window.gpyDeviceIndex = deviceIndex;
    this.setState({ deviceIndex });
  };
  /**
   * 获取设备分辨率
   * @param {Array} resolution 设备分辨率
   */
  GetDeviceResolution = resolution => {
    const cameraCookie = getCookie(this.getCookieName());
    const cameraArr = cameraCookie ? cameraCookie.split('_') : [];

    const deviceIndex = this.state.deviceIndex || window.gpyDeviceIndex;
    if (deviceIndex === undefined) return; // 若设备未选取 设置分辨率无意义
    const deviceResolutionWidth = cameraArr[0] || 1600 || resolution[0],
      deviceResolutionHeight = cameraArr[1] || 1200 || resolution[1];

    // 获得分辨率可选列表
    const resolutionData = [];
    for (let i = 0; i < 18; i += 2) {
      const obj = {};
      obj.id = `${resolution[i]}*${resolution[i + 1]}`;
      obj.name = `${resolution[i]}*${resolution[i + 1]}`;
      resolutionData.push(obj);
    }

    this.setState({ deviceResolutionWidth, deviceResolutionHeight, deviceIndex, resolutionData });
  };
  // 开启设备
  startVedio = () => {
    const { deviceResolutionWidth, deviceResolutionHeight, deviceIndex } = this.state;
    sendMsgStartVideo(deviceIndex, deviceResolutionWidth, deviceResolutionHeight);
    this.isOpen = true;
    this.initCameraParam();
  };
  // 重置参数
  initCameraParam = () => {
    // sendMsgSetCanvasOriginalSize(this.canvasWidth, this.canvasHeight) // 设置起初主头视频画面的尺寸大小(宽度与高度最大不能超过3000,最小不能低于200)
    // sendMsgSetCutType(0) // 裁边模式 0不裁边 1自动裁边 2手动裁边
    // sendMsgSetImageColorMode(0) // 设置图片颜色格式 0彩色 1灰色 2黑色
    // sendMsgSetJiubianModel(0) // 在自动模式下,选择单图与多图捕抓 0单图 1多图
    // sendMsgSetFileType(0) // 设置文件格式 0jpg 1png 2bmp 3pdf 4gif 5tif
    sendMsgChangeOrientation(0); // 设置视屏方向 0:水平 1:90度 2:180度 3:270度
    // sendMsgSetConntinousShotModel(0) // 设置连拍模式  0关闭连拍 1智能连拍
    // sendMsgSetFileNameModelTime() // 文件命名改为时间命名
    // sednMsgBubaiType(0) // 周围的黑边补白(在自动裁边模式下) 0关闭 1启动
    // sendMsgSetAutoExposure(1) // 打开或关闭自动曝光 0关闭 1启动
    sendMsgZoom(2); // 视频画面放大缩小操作  1放大 0缩小 2合适尺寸 3实际尺寸
    sendMsgZoom(0); // 视频画面放大缩小操作  1放大 0缩小 2合适尺寸 3实际尺寸
    sendMsgSetFilePath('C:\\Users\\Public');
  };
  isLtFn = () => {
    this.setState({ isLiangTian: true });
  };
  LTSavePohot = url => {
    this.setState({ currBase64: url, currImgName: new Date().getTime() });
  };
  LTopenModel = () => {
    this.setState({ modalShow: true });
  };
  LTGetDevice = val => {
    //良田获取分辨率
    this.state.LTDevice.forEach(element => {
      if (element.id == val) {
        return;
      }
    });
    this.setState({
      LTDevice: [...this.state.LTDevice, { id: val, name: val }],
    });
  };
  // 拍照
  capture = () => {
    this.state.isLiangTian ? LTPhotoClick() : sendMsgCapture(0); // 当前裁边模式(0不裁边 1自动裁边 2手动裁边)
    // createTip('拍照成功').show()
    // this.setState({})
  };
  /**
   * 旋转成像区
   * @param {Number} type 1顺时针 0逆时针
   */
  changeOrientation = type => () => {
    if (this.state.isLiangTian) {
      type == 1 ? LTRotateRight() : LTLotateLeft();
      return;
    }
    let currImgOri = this.state.currImgOri;
    currImgOri = type ? currImgOri + 1 : currImgOri - 1;
    if (currImgOri < 0) {
      currImgOri = 3;
    } else if (currImgOri > 3) {
      currImgOri = 0;
    }
    this.setState({ currImgOri });
    sendMsgChangeOrientation(currImgOri);
  };
  /**
   * 缩放成像区
   * @param {Number} type 0放大 1缩小 2合适尺寸 3实际尺寸
   */
  changeZoom = type => () => {
    sendMsgZoom(type);
  };
  /**
   * 将拍摄图片转换为base64
   * @param {String} path 图片路径
   */
  getBase64 = path => {
    const currImgPath = path || this.state.currImgPath;
    sednMsgGetBase64(currImgPath);
  };
  // 上传图片
  uploadImg = () => {
    const { currBase64 } = this.state,
      _timestamp = Date.now(),
      rst = {
        src: currBase64, // 源文件base64
        formdata: this.convertBase64UrlToBlob(currBase64), // 文件格式
        name: _timestamp, // 图片名称（时间戳）
      };
    if (this.showPickOrder) {
      rst.pickInfo = getCardFormData(this._orderInfo.getStateData());
      if (!rst.pickInfo.order_num || (typeIs(rst.pickInfo.order_num, 'array') && rst.pickInfo.order_num.length === 0)) {
        createTip('运单号不能为空！', ERROR, () => {}, 2000, 'tops', true).show();
        return;
      }
      if (
        !rst.pickInfo.photo_type ||
        (typeIs(rst.pickInfo.photo_type, 'array') && rst.pickInfo.photo_type.length === 0)
      ) {
        createTip('图片类型不能为空！', ERROR, () => {}, 2000, 'tops', true).show();
        return;
      }
      localStorage.camerOrderChecked = rst.pickInfo.is_visible || false;
    }
    this.props.upLoadCb && this.props.upLoadCb(rst);
    this.setState({ currBase64: '', currImgName: '' });
  };
  /**
   * 将base64转为Blob对象（file）
   * @param {String}  base64 base64编码
   */
  convertBase64UrlToBlob = base64 => {
    const body = base64.split(',')[1];
    let data = body.slice(0, -1);
    if (this.state.isLiangTian) {
      data = body;
    }
    const bytes = window.atob(data); // 去掉url的头，并转换为byte
    // 处理异常,将ascii码小于0的转换为大于0
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < bytes.length; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpg' });
  };
  // 图片拖拽
  imgMoving = e => {
    const initLeft = e.clientX,
      initTop = e.clientY;
    document.onmousemove = event => {
      const currLeft = event.clientX - initLeft,
        currTop = event.clientY - initTop;
      this.setState({ imgLeft: currLeft, imgTop: currTop });
    };
    document.onmouseup = () => {
      document.onmousemove = null;
      document.onmouseup = null;
    };
  };
  defaultImgMove = e => {
    e.preventDefault();
  };
  saveCurrentImg = () => {
    const { currBase64, currImgName } = this.state;
    this.imgArrow = Object.assign(this.imgArrow, { href: currBase64, download: `${currImgName}.jpg` });
    this.imgArrow.click();
  };
  transformImg = (type, style) => () => {
    // console.log(this.imgRefDist)
    let prevTransform = this.imgRefDist.style.transform || 'rotate(0deg)scale(1)';
    if (prevTransform.indexOf('rotate') === -1) {
      prevTransform = `rotate(0deg)${prevTransform}`;
    }
    if (prevTransform.indexOf('scale') === -1) {
      prevTransform = `${prevTransform}scale(1)`;
    }
    let newTransformObj = {};
    let result;
    const test = /([a-z]+)(\(.+?\))/g;
    while ((result = test.exec(prevTransform))) {
      // eslint-disable-line
      if (result.length % 3) return;
      if (result[1] === type) {
        newTransformObj[result[1]] = result[2].replace(
          /[\d.]+/,
          word => parseFloat(word, 10) + (type === 'rotate' ? (style ? 90 : -90) : style ? 0.1 : -0.1),
        );
      } else {
        newTransformObj[result[1]] = result[2];
      }
    }
    const newTransform = Object.entries(newTransformObj)
      .map(item => item.join(''))
      .join('');
    this.setState({ currTransform: newTransform });
  };
  onOrderNumSelect = (key, val) => {
    this.setState({ [key]: val });
    if (key === 'photo_type') {
      const data = this._orderInfo.getStateData();
      data[key] = setComVal(data[key], val).toJS();
      const selVal = typeIs(val, 'array') ? val[0].key : val;
      data.is_visible.display = selVal === 'order' ? true : false;
      data.is_visible.otherProps.display = selVal === 'order' ? true : false;
      data.is_visible.otherProps.checked = localStorage.camerOrderChecked === 'false' ? false : true;
      this._orderInfo.setData(data);
    }
  };
  onTl = val => {
    LTsizeChange(val.id);
  };
  onResolutionChange = value => {
    if (this.state.isLiangTian) {
      this.setState({ currentResolution: value });

      LTresolutionList(value.id);
      return;
    }
    sendMsgCloseVideo();
    const { deviceIndex } = this.state;
    const resolArr = value.id.split('*');
    console.log('resolArr', resolArr, deviceIndex);
    const deviceResolutionWidth = resolArr[0];
    const deviceResolutionHeight = resolArr[1];

    const timeout = new Date();
    timeout.setDate(timeout.getDate() + 365);
    setCookie(this.getCookieName(), `${deviceResolutionWidth}_${deviceResolutionHeight}`, timeout, '/');

    this.setState({ deviceResolutionWidth, deviceResolutionHeight });
    sendMsgStartVideo(deviceIndex, deviceResolutionWidth, deviceResolutionHeight);
  };
  renderContent = () => {
    const {
      currBase64 = '',
      imgLeft,
      imgTop,
      currTransform,
      currImgOri,
      resolutionData,
      deviceResolutionWidth,
      deviceResolutionHeight,
    } = this.state;
    this.realOri = this.isCapture ? currImgOri % 2 : this.realOri || 0;
    this.isCapture = false;
    const defaultResolution = `${deviceResolutionWidth}*${deviceResolutionHeight}`;
    let devData = !this.state.isLiangTian ? resolutionData : this.state.LTDevice;
    let defDevice = this.state.LTDevice[4] ? this.state.LTDevice[4] : this.state.LTDevice[0];
    let current = this.state.currentResolution ? [this.state.currentResolution] : [defDevice];
    let defData = !this.state.isLiangTian ? defaultResolution : current;
    return (
      <div className={prefixCls}>
        {this.showPickOrder && (
          <div className="pickorder-area">
            <CardForm
              key="orderInfo"
              isHeader={false}
              ref={r => (this._orderInfo = r)}
              data={this.getPickOrderData()}
              handleEvents={{ handleSelected: this.onOrderNumSelect }}
            />
          </div>
        )}
        <div className="photo-area">
          <div className="left-area">
            <div className="area-title">拍摄区</div>
            {!this.state.isLiangTian ? (
              <canvas id="cameraCanvas" width={`${this.canvasWidth}px`} height={`${this.canvasHeight}px`}>
                您的浏览器不支持HTML5的canvas标签
              </canvas>
            ) : (
              <img id="bigPriDev" width={`${this.canvasWidth}`} height={`${this.canvasHeight}`} />
            )}

            <div className="left-handle-icon">
              <div className="icon-area">
                <div className="icon-rotate-area">
                  <Icon iconType="icon-zuoxuanzhuan1" onClick={this.changeOrientation(0)} />
                  <Icon iconType="icon-youxuanzhuan1" onClick={this.changeOrientation(1)} />
                </div>

                {!this.state.isLiangTian ? (
                  <div className="icon-scale-area">
                    <Icon iconType="icon-fangda" onClick={this.changeZoom(1)} />
                    <Icon iconType="icon-suoxiao" onClick={this.changeZoom(0)} />
                  </div>
                ) : (
                  <div>
                    <div className="set-size">设置尺寸</div>
                    <div className="resolution-area  size-select">
                      <SelectDrop
                        width={50}
                        data={this.state.setScanSizeData}
                        defaultSelected="原始尺寸"
                        showKey="name"
                        uniqueKey="id"
                        isMultiple={false}
                        handleSelected={this.onTl}
                      />
                    </div>
                  </div>
                )}
                <div className="resolution-area lt-resolution-area">
                  {/* {console.log(this.state.LTDevice, 'this.state.LTDevice')} */}
                  <SelectDrop
                    width={50}
                    data={devData}
                    defaultSelected={defData}
                    showKey="name"
                    uniqueKey="id"
                    isMultiple={false}
                    handleSelected={this.onResolutionChange}
                  />
                </div>
              </div>
              <div className="btn-area">
                //
                <ButtonIcon iconType="icon-photo" onClick={this.capture}>
                  拍照
                </ButtonIcon>
                {/* <ButtonIcon className="admin" iconType="icon-close" onClick={() => {sendMsgCloseVideo()}}>关闭</ButtonIcon>*/}
              </div>
            </div>
          </div>
          <div className="right-area">
            <div className="area-title">查看区</div>
            <div
              className="img-wrap"
              style={{
                width: `${this.canvasWidth}px`,
                height: `${this.canvasHeight}px`,
              }}
            >
              {currBase64 && (
                <div
                  className="img-dist"
                  onMouseDown={this.imgMoving}
                  ref={r => {
                    this.imgRefDist = r;
                  }}
                  style={{
                    width: `${this.canvasWidth}px`,
                    height: `${this.canvasHeight}px`,
                    left: `${imgLeft}px`,
                    top: `${imgTop}px`,
                    transform: currTransform,
                  }}
                >
                  <img
                    id="devPhoto"
                    width="36"
                    height="27"
                    className={classname({ rowAuto: !this.realOri, colAuto: this.realOri })}
                    onMouseDown={this.defaultImgMove}
                    ref={r => {
                      this.imgRef = r;
                    }}
                    src={currBase64}
                    role="presentation"
                  />
                </div>
              )}
            </div>
            <div className="right-handle-icon">
              <div className="icon-area">
                <div className="icon-rotate-area">
                  <Icon iconType="icon-zuoxuanzhuan1" onClick={this.transformImg('rotate', 0)} disabled={!currBase64} />
                  <Icon iconType="icon-youxuanzhuan1" onClick={this.transformImg('rotate', 1)} disabled={!currBase64} />
                </div>
                <div className="icon-scale-area">
                  <Icon iconType="icon-fangda" onClick={this.transformImg('scale', 1)} disabled={!currBase64} />
                  <Icon iconType="icon-suoxiao" onClick={this.transformImg('scale', 0)} disabled={!currBase64} />
                </div>
              </div>
              <div className="btn-area">
                <ButtonIcon iconType="icon-upload" onClick={this.uploadImg} disabled={!currBase64}>
                  上传
                </ButtonIcon>
                <ButtonIcon iconType="icon-save" onClick={this.saveCurrentImg} disabled={!currBase64}>
                  保存
                </ButtonIcon>
                <a
                  className="save-arrow"
                  ref={r => {
                    this.imgArrow = r;
                  }}
                >
                  11
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  closeModalDialog = () => {
    if (this.state.modalShow) {
      this.setState({ modalShow: false });
    } else {
      this.props.close();
    }
  };

  render() {
    return (
      <ModalDialog
        content={this.renderContent()}
        isShow={this.state.modalShow}
        close={this.props.close} // eslint-disable-line
        title="拍照"
        contentStyle={{ width: '1000px', height: '530px', overflow: 'hidden' }}
      />
    );
  }
}
