import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { Dropdown, PureInput, PureTextarea, Button, PureTree } from 'components';
import {
  throttle,
  shallowCompareIgnoreFuncExcept,
  sequentiallyFetch,
  highlightStr,
  filterTreeByKeyword,
  flatTree,
} from 'utils';
import { prefixCls, menuPrefixCls } from './index.scss';

export default class PureTreeSelect extends Component {
  static defaultProps = {
    compare: 'key',
    format: 'name',
    filter: 'name',
    map: 'key',
    placeholder: '-请选择-',
    multiple: true, // 先只支持复选
    showIcon: true,
  };

  static propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    menuClassName: PropTypes.string,
    showIcon: PropTypes.bool, // 是否显示下拉图标
    style: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    clear: PropTypes.bool,
    data: PropTypes.object, // {key: 'xxx', name: 'xxxx', children:{ aaa: { 同父级}, bbb: { 同父级} }}
    flatTreeData: PropTypes.object, // data打平，加速性能用的，一般不用传{ aaa: { key: 'aaa', name: 'xxxx' }, bbb: { key: 'bbb', name: 'xxxx'}, cccc, dddd, .... }
    compare: PropTypes.any, // 'key' || (v1, v2) => v1.key === v2.key
    map: PropTypes.any, // 'key' || reflectValue => reflectValue.map(item => item.key)
    format: PropTypes.any, // 'name' || reflectValue => reflectValue.map(item => item.name).join(',')
    filter: PropTypes.any, // 'name' || keyword => fetch(...).then(res => this.setState({ sug: res.data }))
    nodeFormat: PropTypes.any,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    customValidity: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    'data-path': PropTypes.string,
    children: PropTypes.any,
    multiLine: PropTypes.bool, // 多行显示
    showInputTitleTips: PropTypes.bool,
    nodeDisabled: PropTypes.func,
    inputWrapStyle: PropTypes.object,
  };

  static highlight = highlightStr;

  constructor(props) {
    super(props);
    this.setFormatFn(props);
    this.setFilterFn(props);
    this.state = { keyword: '', active: false, flatTreeData: {} };
    if (props.flatTreeData) {
      this.state.flatTreeData = props.flatTreeData;
    } else if (props.data) {
      const root = props.data && (props.data[props.compare] ? props.data : Object.values(props.data)[0]);
      this.state.flatTreeData = flatTree(root, props.compare);
    }
  }

  listenDel = true;

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.flatTreeData !== nextProps.flatTreeData) {
      this.setState({ flatTreeData: nextProps.flatTreeData });
    }
    if (this.props.data !== nextProps.data && !nextProps.flatTreeData) {
      const root =
        nextProps.data && (nextProps.data[nextProps.compare] ? nextProps.data : Object.values(nextProps.data)[0]);
      this.setState({ flatTreeData: flatTree(root, nextProps.compare) });
    }
    if (this.props.value !== nextProps.value && nextProps.multiple && this.state.active) {
      this.setState({ value: nextProps.value });
    }
    if (this.props.format !== nextProps.format) {
      this.setFormatFn(nextProps);
    }
    if (this.props.filter !== nextProps.filter) {
      this.setFilterFn(nextProps);
    }
  }

  shouldComponentUpdate = shallowCompareIgnoreFuncExcept({ format: 1 });

  setFormatFn(props) {
    const { format } = props;
    if (typeof format === 'string') {
      this.format = value => {
        const { flatTreeData } = this.state;
        const { compare, multiple } = this.props;
        const valueArr = multiple ? value || [] : [value];
        return valueArr
          .map(item => {
            const key = item === 'object' ? item[compare] : item;
            const node = flatTreeData[key];
            return node && node[format];
          })
          .filter(item => item)
          .join(',');
      };
    } else if (typeof format === 'function') {
      this.format = format;
    } else {
      this.format = () => '';
    }
  }

  setFilterFn(props) {
    const { filter } = props;
    const filterType = typeof filter;
    if (filterType === 'function') {
      if (this.filter && typeof this.props.filter === 'function') return;
      const filterFn = async keyword => {
        const data = await this.props.filter(keyword, this);
        this.useStateData = Array.isArray(data);
        this.useStateData && this.setState({ data });
      };
      const asyncFilter = throttle(filterFn, 150);
      this.filter = (keyword, immediate) => {
        this.setState({ keyword });
        immediate ? filterFn(keyword) : asyncFilter(keyword);
      };
      this.isFnFilter = true;
    } else if (filterType === 'string') {
      this.filter = keyword => this.setState({ keyword });
      this.isStringFilter = true;
    } else {
      this.filter = () => null;
    }
  }

  fetch = (...args) => {
    const fetch = sequentiallyFetch();
    this.fetch = (...args1) => {
      this.loading(true);
      const promise = fetch(...args1);
      promise.then(() => this.loading(false));
      return promise;
    };
    return this.fetch(...args);
  };

  // eslint-disable-next-line react/no-unused-state
  loading = status => this.setState({ loading: status });

  customValidity = async () => {
    const { value, required, multiple, customValidity } = this.props;
    if (required && (!value || (multiple && !value.length))) {
      return '必填';
    } else if (customValidity) {
      return await customValidity(value);
    }
    return '';
  };

  focus = () => this.input && this.input.focus();

  blur = () => this.input && this.input.blur();

  open = () => {
    const keyword = this.props.multiple ? '' : this.display;
    const value = this.props.multiple ? this.props.value : undefined;
    if (this.state.active) return;
    this.setState({ active: true, keyword, value }, () => this.drop.open());
    this.input && delete this.input.dataset.isSelect;
    this.input && delete this.input.dataset.userInput;
  };

  close = () => this.drop && this.drop.close();

  cancel = () => this.input && delete this.input.dataset.isSelect;

  toggle = e => {
    if (!this.state.active) {
      this.focus();
      e.preventDefault();
    }
  };

  onClose = () => {
    this.input.dataset.isSelect && this.props.multiple && this.onChange(this.state.value);
    this.props.onClose && this.props.onClose();
    this.setState({ active: false, keyword: '' });
  };

  onChange = value => {
    this.input && (this.input.dataset.isEdited = '1');
    this.props.onChange && this.props.onChange(value);
  };

  onTreeChange = value => {
    const { multiple } = this.props;
    if (multiple) {
      this.setState({ value });
    } else {
      this.close();
      this.onChange(value);
      this.needAsyncSelect = false;
    }
    this.input && (this.input.dataset.isSelect = '1');
  };

  onClear = () => this.onChange(this.props.multiple ? [] : '');

  onFocus = e => {
    this.props.onFocus && this.props.onFocus(e);
    !this.state.active && this.open();
  };

  onBlur = e => {
    this.props.onBlur && this.props.onBlur(e);
    this.state.active && this.close();
  };

  onInput = e => {
    this.filter(e.target.value);
    this.props.onInput && this.props.onInput(e);
    this.input && (this.input.dataset.userInput = e.target.value);
    !e.target.value && !this.props.multiple && this.listenDel && this.props.value && this.remove();
  };

  onKeyDown = e => {
    this.state.active && this.dealShortcuts(e);
    this.props.onKeyDown && this.props.onKeyDown(e);
  };

  onMouseDown = e => e.preventDefault();

  dealShortcuts = e => {
    const { keyword } = this.state;
    const { filter } = this.props;

    switch (e.keyCode) {
      case 27: // esc
        this.input.blur();
        break;
      case 8: // delete
        this.listenDel && (!filter || !keyword) && this.remove();
        break;
      default:
        break;
    }
  };

  remove = () => {
    const { multiple } = this.props;
    const { value } = this.state;
    multiple
      ? value.length && this.onTreeChange(value.slice(0, value.length - 1))
      : this.props.value && this.onTreeChange('');
  };

  refInput = input => (this.input = input && input.input);

  refDrop = drop => (this.drop = drop);

  nodeTitleRender = (node, tree) => {
    const { keyword } = this.state;
    const str = tree.format(node);
    return <span>{keyword ? highlightStr(str, keyword) : str}</span>;
  };

  renderToggle() {
    const {
      disabled,
      placeholder,
      filter,
      multiple,
      clear,
      showIcon,
      className,
      children,
      multiLine,
      onMouseEnter,
      onMouseLeave,
      style,
      showInputTitleTips,
      inputWrapStyle,
    } = this.props;
    const { keyword, active } = this.state;
    const value = multiple && active ? this.state.value : this.props.value;
    const { reflectValue } = this;
    const title = this.format(value);
    const InputC = multiLine ? PureTextarea : PureInput;
    const displayLine = multiLine ? (
      <p className="display-text-m">{title}</p>
    ) : (
      <span className="display-text">{title}</span>
    );
    return (
      <InputC
        ref={this.refInput}
        style={style}
        data-field-type="TreeSelect"
        data-path={this.props['data-path']}
        className={classnames({ [prefixCls]: true, active, multiple, disabled, [className]: className })}
        customValidity={this.customValidity}
        disabled={disabled}
        title={title}
        placeholder={disabled ? '' : placeholder}
        value={active && filter ? keyword : title}
        onKeyDown={this.onKeyDown}
        onFocus={this.onFocus}
        onClick={this.open}
        onBlur={this.onBlur}
        frozen={!filter || !active}
        onChange={this.onInput}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        showTitleTips={showInputTitleTips}
        wrapStyle={inputWrapStyle}
        recordEdited={false}
      >
        {multiple && active && displayLine}
        {showIcon && <i className="input-icon toggle-icon" onMouseDown={this.toggle} />}
        {clear && !!reflectValue.length && !disabled && (
          <i className="input-icon fn-icon fn-icon-error-o" onClick={this.onClear} />
        )}
        {children}
      </InputC>
    );
  }

  renderMenu = () => {
    const { multiple, filter, compare, map, menuClassName, disabled, nodeDisabled, data = {}, nodeFormat } = this.props;
    const { keyword, active } = this.state;
    const value = multiple && active ? this.state.value : this.props.value;
    const needFilter = this.isStringFilter && this.input.dataset.userInput;
    const realData = needFilter
      ? filterTreeByKeyword(data && (data[compare] ? data : Object.values(data)[0]), filter, keyword)
      : data;
    const menuClass = classnames({ [menuClassName]: menuClassName, [menuPrefixCls]: menuPrefixCls });
    const format = nodeFormat || this.isStringFilter ? filter : undefined;

    return (
      <div className={menuClass}>
        <section onMouseDown={this.onMouseDown}>
          <PureTree
            multiple={multiple}
            value={value}
            data={realData}
            map={map}
            compare={compare}
            checkable
            disabled={disabled || nodeDisabled}
            format={format}
            nodeTitleRender={this.nodeTitleRender}
            onChange={this.onTreeChange}
          />
        </section>
        <footer>
          <Button>确定</Button> <Button onMouseDown={this.cancel}>取消</Button>
        </footer>
      </div>
    );
  };

  render() {
    const { disabled, onOpen } = this.props;
    const { active } = this.state;
    this.dataSource = this.useStateData ? this.state.data : this.props.data;
    this.data = this.dataSource || {};
    return (
      <Dropdown
        ref={this.refDrop}
        menu={active ? this.renderMenu() : null}
        disabled={disabled}
        onClose={this.onClose}
        onOpen={onOpen}
      >
        {this.renderToggle()}
      </Dropdown>
    );
  }
}
