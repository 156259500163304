exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pop-amap-poi-selector{width:1200px;height:500px}.pop-amap-poi-selector.fullscreen{width:100%;height:100%}.pop-amap-poi-selector .fn-dialog__center{width:100%;height:100%;padding:0}", ""]);

// exports
exports.locals = {
	"pop-amap-poi-selector": "pop-amap-poi-selector",
	"fullscreen": "fullscreen",
	"fn-dialog__center": "fn-dialog__center"
};