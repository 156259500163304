exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".typeset{display:flex;flex-wrap:wrap;font-size:12px;color:#0097cf}.typeset .file-list-item{height:24px;margin-right:20px;text-decoration:none;color:#0097cf;position:relative}.typeset .file-list-item .fn-icon{color:red;visibility:hidden;position:absolute;top:-2px;right:-10px}.typeset .file-list-item:hover .fn-icon{visibility:visible}.typeset .upload-select{height:24px;text-decoration:none}", ""]);

// exports
exports.locals = {
	"prefixCls": "typeset",
	"typeset": "typeset",
	"file-list-item": "file-list-item",
	"fn-icon": "fn-icon",
	"upload-select": "upload-select"
};