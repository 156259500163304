/**
 * Created by JANY on 2017/1/9.
 */
import PropTypes from 'prop-types';
import { typeIs } from 'utils';
import React, { Component } from 'react';
import LoadMap from './LoadMap';

@LoadMap
export default class DriveDistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distance: 0,
    };
    this.driving = null;
    this.aMap = window.AMap;
    const { origPoi, distPoi, showDistance } = this.props;
    showDistance && this._calDistance(origPoi, distPoi);
  }

  static defaultProps = {
    showDistance: false,
  };
  static propTypes = {
    origPoi: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    distPoi: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    onResult: PropTypes.func,
    showDistance: PropTypes.bool,
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    const { origPoi: oldOriPoi, distPoi: oldDistPoi } = this.props;
    const { origPoi, distPoi } = nextprops;
    if (origPoi !== oldOriPoi || distPoi !== oldDistPoi) {
      this._calDistance(origPoi, distPoi);
    }
  }

  UNSAFE_componentWillMount() {
    this._getService();
  }

  onResult = (status, result, reslove) => {
    let distance = 0;
    if (status === 'no_data' || status === 'error') {
      if (reslove) {
        reslove({
          distance: 0,
          errmsg: status,
          errno: 1,
        });
      } else {
        this.props.onResult && this.props.onResult(0, result);
      }
    } else if (reslove) {
      reslove({
        distance: result.routes[0].distance,
        duration: result.routes[0].duration, // 持续时间 秒
        errno: 0,
      });
    } else {
      distance = result.routes[0].distance;
      this.props.showDistance && this.setState({ distance });
      this.props.onResult && this.props.onResult(distance, result);
    }
  };
  // 组件props形式调用
  _calDistance = (iorigPoi, idistPoi, city = []) => {
    let origPoi = iorigPoi;
    let distPoi = idistPoi;
    if (window.AMap && origPoi && distPoi) {
      if (!this.driving) {
        this.driving = this._getService();
      }
      if (`${origPoi}`.split(',').length === 2 && `${distPoi}`.split(',').length === 2) {
        origPoi = origPoi.split(',');
        distPoi = distPoi.split(',');
        this.driving.search(
          new window.AMap.LngLat(origPoi[0], origPoi[1]),
          new window.AMap.LngLat(distPoi[0], distPoi[1]),
          {},
          this.onResult,
        );
      } else {
        this.driving.search(
          [
            { keyword: `${origPoi}`, city: city[0] || '' },
            { keyword: `${distPoi}`, city: city[1] || '' },
          ],
          this.onResult,
        );
      }
    }
  };
  // 外部直接调用 promise
  // 支持多点（有途径点）路径计算。即 iorigPoi 支持数组格式。
  calDistance = (iorigPoi, idistPoi, city = []) => {
    const origPoi = iorigPoi;
    const distPoi = idistPoi;
    if (!this.driving) {
      this.driving = this._getService();
    }
    // if (!Array.isArray(origPoi) && !Array.isArray(distPoi)) {
    //   origPoi = origPoi.split(',')
    //   distPoi = distPoi.split(',')
    // }
    return new Promise(reslove => {
      let path;
      if (window.AMap && origPoi && origPoi !== ',' && distPoi && distPoi !== ',') {
        if (`${origPoi}`.split(',').length === 2 && `${distPoi}`.split(',').length === 2) {
          // 当开始坐标是'0,0'时，需要特殊处理，直接返回空值，否则高德计算不出来。
          if (origPoi === '0,0') {
            return this.onResult('error', undefined, reslove);
          }
          path = [{ lnglat: origPoi.split(',') }, { lnglat: distPoi.split(',') }];
          // distPoi = distPoi.split(',')
          // this.driving.search(
          //   new window.AMap.LngLat(origPoi[0], origPoi[1]),
          //   new window.AMap.LngLat(distPoi[0], distPoi[1]),
          //   (s, r) => this.onResult(s, r, reslove)
          // )
        } else {
          path = [
            { keyword: `${origPoi}`, city: city[0] || '' },
            { keyword: `${distPoi}`, city: city[1] || '' },
          ];
        }
      } else if (typeIs(iorigPoi, 'array') && iorigPoi.length > 1 && !distPoi) {
        // 计算多节点路径长度
        path = iorigPoi.map(item => ({ lnglat: item.split(',') }));
      }
      if (path) {
        if (path[0].lnglat) {
          path = path.map(item => new window.AMap.LngLat(item.lnglat[0], item.lnglat[1]));
          const start = path[0];
          const end = path.pop();
          const waypoints = path.splice(1, path.length - 1);
          this.driving.search(start, end, { waypoints }, (s, r) => this.onResult(s, r, reslove));
        } else {
          // 有些特殊地址需要处理，否则高德计算不出来
          const pathNew = path.map(item => ({ ...item, keyword: item.keyword.replace('遂宁市射洪市', '射洪市') }));
          this.driving.search(pathNew, (s, r) => this.onResult(s, r, reslove));
        }
      } else {
        reslove({
          distance: 0,
          errmsg: 'no_data',
          errno: 1,
        });
      }
    });
  };
  // 获取驾车路线查询实例
  _getService = () => {
    let driving = {};
    if (window.AMap) {
      driving = new window.AMap.Driving({
        // policy: 'LEAST_DISTANCE',
        policy: 0,
        size: 1,
        // city: 'beijing',
        // panel: 'panel'
      });
      this.driving = driving;
    } else {
      const inits = Object.assign({}, { winInit: window.init });
      window.init = () => {
        inits.winInit && inits.winInit();
        driving = new window.AMap.Driving({
          // policy: 'LEAST_DISTANCE',
          showTraffic: false,
          policy: 0,
          size: 1,
          // city: 'beijing',
          // panel: 'panel'
        });
        this.driving = driving;
      };
    }
    return driving;
  };

  render() {
    return this.props.showDistance ? <span>{this.state.distance}</span> : null;
  }
}
