/**
 * Created by Aceh on 2017/01/01.
 */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { prefixCls } from './index.scss';

// 请使用PureCheckbox 代替
export default class Switch extends PureComponent {
  static defaultProps = {
    classname: '',
    hasWrap: true,
  };
  static propTypes = {
    wrapClass: PropTypes.string,
    classname: PropTypes.string, // 不标准，不要用
    className: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    hasWrap: PropTypes.bool,
  };

  static blueSwitchClass = 'fn-switch-blue';

  constructor(prop) {
    super(prop);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      checked: false,
    };
  }

  handleResetState = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ checked: false });
  };
  handleClick = e => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ checked: e.target.checked });
    this.props.onClick && this.props.onClick(e.target.checked);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ checked: nextProps.checked });
  }

  render() {
    const { classname, className, wrapClass, disabled, hasWrap } = this.props;
    const classes = classnames({
      [classname]: classname,
      [className]: className,
      [`${prefixCls}`]: true,
      [`${prefixCls}-animbg`]: true,
    });
    let valueObj = {
      defaultChecked: this.props.checked,
    };
    if (this.props.checked !== undefined && this.props.onClick) {
      valueObj = {
        checked: this.props.checked,
      };
    }
    const input = (
      <input
        className={classes}
        onChange={this.handleClick}
        {...valueObj}
        disabled={disabled}
        type="checkbox"
        style={{ outline: 'none' }}
      />
    );
    if (!hasWrap) {
      return input;
    }
    return <div className={`${wrapClass}`}>{input}</div>;
  }
}
