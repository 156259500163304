import { NUMBER_TYPE, DATE_TYPE, SORTTYPES } from 'constants';
import React from 'react';
import * as Sentry from '@sentry/react';
import { typeIs } from 'utils';

// table下相关func 统一改到这
function diffDate(date1, date2) {
  const _arrDate = date1.split(',');
  const _tmpDate = '2017-01-01';
  const startDate = new Date(_arrDate[0] || _tmpDate);
  const endDate = new Date(_arrDate[1] || _tmpDate);
  const oDate2 = new Date(date2);
  if (oDate2.getTime() >= startDate.getTime() && oDate2.getTime() <= endDate.getTime()) {
    return true;
  }
  return false;
}

function diffNum(num1, num2) {
  const n1 = parseInt(num1, 10);
  const n2 = parseInt(num2, 10);
  if (n1 > n2) {
    return true;
  }
  return false;
}

/*
 获取Props
 */
function isInOtherPropsArray(data, col, prop) {
  const { otherProps } = data;
  if (!otherProps) {
    return false;
  }
  const propsList = otherProps[prop] || [];
  return !(propsList.indexOf(col) === -1 || propsList.length === 0);
}

function getOtherPropsInfo(data, col, prop) {
  const { otherProps } = data;
  if (!otherProps) {
    return undefined;
  }
  const subProps = otherProps[prop] || {};
  return subProps[col] || undefined;
}

function getOtherProps(data, prop) {
  const { otherProps = {} } = data;
  return otherProps[prop];
}

/*
 获取tabs
 */
function getCellTips(otherProps, col) {
  if (!otherProps) return '';
  return (otherProps.tooltips || {})[col] || '';
}

/**
 * 获取columnType
 * dataItem.dataItem.otherProps.columnType[col] 的优先级高于 header 中的columnType
 * @param {*} columnType header 中的columnType
 * @param {*} dataItem   行数据
 * @param {*} col        columnKey
 */
function getColumnType(columnType, dataItem, col) {
  const curColumnType = dataItem.otherProps && dataItem.otherProps.columnType && dataItem.otherProps.columnType[col];
  return curColumnType || columnType;
}
/*
  比较函数
 */
function feCmpFunc({ oriA, oriB, columnKey, headerCol, showA, showB, sortList }) {
  const columnType = headerCol && headerCol.columnType;
  const type = headerCol && headerCol.type;
  const percent = headerCol && headerCol.percent;
  const zero2null = headerCol && headerCol.format && headerCol.format.zero2null;
  let valueA = oriA;
  let valueB = oriB;
  let sortVal = 0;

  try {
    if (DATE_TYPE.includes(columnType)) {
      // 当时间戳为空值时，取值为0。取得的毫秒为0，便于比较
      valueA = valueA || 0;
      valueB = valueB || 0;
      if (new Date(valueA).getTime() > new Date(valueB).getTime()) {
        sortVal = 1;
      }
      if (new Date(valueA).getTime() < new Date(valueB).getTime()) {
        sortVal = -1;
      }
    } else if (
      NUMBER_TYPE.includes(columnType) &&
      headerCol.filterType !== 'SelectDrop' &&
      !Number.isNaN(Number(valueA)) &&
      !Number.isNaN(Number(valueB))
    ) {
      if (Number(valueA) > Number(valueB)) {
        sortVal = 1;
      }
      if (Number(valueA) < Number(valueB)) {
        sortVal = -1;
      }
      // 空字段在最下面
      if (zero2null) {
        if (Number(valueA) === 0) valueA = showA;
        if (Number(valueB) === 0) valueB = showB;
      }
      if (valueB === '') {
        sortVal = sortList[columnKey] === SORTTYPES.ASC ? -1 : 1;
      }
      if (valueA === '') {
        sortVal = sortList[columnKey] === SORTTYPES.ASC ? 1 : -1;
      }
    } else {
      valueA = showA;
      valueB = showB;
      // 兼容 checkboxtext 的格式 {text,checked}
      if (type === 'CheckBoxText') {
        valueA = valueA && valueA.text !== undefined ? valueA.text : valueA;
        valueB = valueB && valueB.text !== undefined ? valueB.text : valueB;
      }
      // 兼容 Progress 的格式，progress返回一个Object
      if (type === 'Progress') {
        valueA = valueA && valueA.status !== undefined ? valueA.status : valueA;
        valueB = valueB && valueB.status !== undefined ? valueB.status : valueB;
      }
      // 兼容百分数的比较，将%去调
      if (percent) {
        valueA = valueA?.toString().replace('%', '') ?? valueA;
        valueB = valueB?.toString().replace('%', '') ?? valueB;
      }
      if (!Number.isNaN(Number(valueA)) && !Number.isNaN(Number(valueB))) {
        if (Number(valueA) > Number(valueB)) {
          sortVal = 1;
        }
        if (Number(valueA) < Number(valueB)) {
          sortVal = -1;
        }
        // 空字段在最下面
        if (valueB === '') {
          sortVal = sortList[columnKey] === SORTTYPES.ASC ? -1 : 1;
        }
        if (valueA === '') {
          sortVal = sortList[columnKey] === SORTTYPES.ASC ? 1 : -1;
        }
      } else {
        sortVal = valueA?.localeCompare?.(valueB, 'zh') ?? sortVal;
      }
    }
    if (sortList[columnKey] === SORTTYPES.DESC) {
      sortVal *= -1;
    }
  } catch (error) {
    Sentry.captureException(error, scope => {
      scope.setTags({
        type: 'TableSortFunError',
        params: JSON.stringify({ columnKey }),
      });
      scope.setExtra('data', { oriA, oriB, columnKey, headerCol, showA, showB, sortList });
      scope.setLevel('error');
    });
  }

  return sortVal;
}
// 多个字段比较函数, 按照sortFields中的index来进行比较
function feFieldsCmpFunc({ indexA, indexB, sortFields, header, listStore, sortList }) {
  const objA = listStore.getOriObjectAt(indexA);
  const objB = listStore.getOriObjectAt(indexB);
  const showObjA = listStore.getOriShowObjectAt(indexA);
  const showObjB = listStore.getOriShowObjectAt(indexB);
  let sortVal = 0;
  for (let ind = 0; ind < sortFields.length; ind++) {
    const columnKey = sortFields[ind];
    const headerCol = header[columnKey];
    if (!objA[columnKey] && !objB[columnKey]) {
      sortVal = indexA > indexB ? 1 : -1;
    } else {
      sortVal = feCmpFunc({
        oriA: objA[columnKey],
        oriB: objB[columnKey],
        columnKey,
        headerCol,
        showA: showObjA[columnKey],
        showB: showObjB[columnKey],
        sortList,
      });
    }
    if (sortVal !== 0) break;
  }
  return sortVal;
}

const TableContext = React.createContext();

const getTitle = (x, i) => {
  if (typeIs(x, 'object')) {
    if (x.show_val) return x.show_val;
    if (x.title) return x.title;
    if (x.showName) return x.showName;
    if (x.showname) return x.showname;
    if (x.name) return x.name;
    return `[${i + 1}]`;
  }
  return x;
};

// 导出的方法
export {
  diffDate,
  diffNum,
  isInOtherPropsArray,
  getOtherPropsInfo,
  getCellTips,
  getColumnType,
  getOtherProps,
  feFieldsCmpFunc,
  feCmpFunc,
  TableContext,
  getTitle,
};
